/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import Penpal, {
  IConnectionObject,
  ConnectionMethods,
  AsyncMethodReturns,
} from 'penpal';

import { FeedId, MessageType } from '@mta-live-media-manager/shared';
import theme from 'theme';
import { useFeedId } from 'contexts/FeedId';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import {
  getSuggestedDisplayType,
  ScreenPreviewDisplayTypes,
} from 'utils/screen-preview';

import { isOutfront } from 'utils/feed-switches';
import HoverFillButton from './screen-preview/hover-fill-button';
import PreviewModal from './screen-preview/preview-modal';
import { FeatureFlagName } from 'generated/global-types';

// Same as in the screen app
// todo: move these to a shared location after we remove TAv1 and the API dependancies in the screen app
export interface PlannedWorkAffectedRoute {
  gtfsRouteId: string;
  longName: string | null;
  shortName: string | null;
  color: string | null;
  textColor: string | null;
}

interface PlannedWorkTemplateProps {
  feedId: FeedId;
  isLandscape: Boolean;
  messageType?: MessageType;
  isAtNight: boolean;
  headline: string;
  humanReadableDurations: string | null;
  affectedRoutes?: PlannedWorkAffectedRoute[];
  description: string;
  additionalInfo?: string;
  title?: string;
  affectsADA?: boolean;
  isDup?: boolean;
}

const fillParentCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: rgb(248, 248, 248);
`;

const { REACT_APP_SCREEN_APP_URL } = process.env;

interface ScreenPreviewMethods {
  renderPlannedWork: (value: PlannedWorkTemplateProps) => void;
}

const PlannedWorkPreviewIframeWrapper: React.FC<PlannedWorkTemplateProps> = ({
  description,
  headline,
  humanReadableDurations,
  isAtNight,
  isLandscape,
  messageType,
  additionalInfo,
  affectedRoutes,
  affectsADA,
  title,
  isDup = false,
}) => {
  let feedId = useFeedId();
  if (isOutfront(feedId)) {
    feedId = FeedId.NYCTSubway;
  }
  const [error, setError] = useState<false | string>(false);
  const [penpalChild, setPenpalChild] = useState<null | AsyncMethodReturns<
    ConnectionMethods<{}> & ScreenPreviewMethods,
    'renderPlannedWork'
  >>(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const currentIframe = containerRef.current;
    let connection: null | IConnectionObject<
      ConnectionMethods & ScreenPreviewMethods
    > = null;
    let componentMounted = true;
    if (currentIframe && REACT_APP_SCREEN_APP_URL) {
      connection = Penpal.connectToChild({
        url: REACT_APP_SCREEN_APP_URL,
        appendTo: currentIframe,
      });

      connection.promise
        .then((child) => componentMounted && setPenpalChild(child))
        .catch(
          () =>
            componentMounted &&
            setError(
              'Sorry, we encountered an issue with the screen preview. Please refresh to try again.',
            ),
        );
    }

    return () => {
      componentMounted = false;
      if (connection) {
        connection.destroy();
      }
    };
  }, [containerRef]);

  useEffect(() => {
    // Here we are calling our "renderPlannedWork" function in the screen app via postmessage
    if (penpalChild && feedId) {
      penpalChild.renderPlannedWork({
        feedId,
        description,
        headline,
        humanReadableDurations,
        isAtNight,
        isLandscape,
        messageType,
        additionalInfo,
        affectedRoutes,
        affectsADA,
        title,
        isDup,
      });
    }
  }, [
    description,
    headline,
    humanReadableDurations,
    isAtNight,
    isLandscape,
    messageType,
    additionalInfo,
    affectedRoutes,
    affectsADA,
    title,
    isDup,
    feedId,
    penpalChild,
  ]);

  return (
    <div
      css={css`
        width: 100%;
        position: relative;
        height: auto;
        background-color: #f8f8f8;
        border: 1px solid #ddd;

        &::before {
          content: ' ';
          display: block;
          padding-bottom: ${isLandscape ? 56.25 : 177.77777778}%;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 0;
          width: 100%;
          height: 100%;
        }
      `}
    >
      {error && (
        <div
          css={[
            fillParentCSS,
            css`
              background-color: #f8f8f8;
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;
              padding: 1rem;
              z-index: 2;
            `,
          ]}
        >
          <p
            css={css`
              color: ${theme.colors.dark2};
              text-align: center;
            `}
          >
            {error}
          </p>
        </div>
      )}
      <div css={fillParentCSS} ref={containerRef} />
    </div>
  );
};

const PlannedWorkTemplatePreview: React.FC<
  Omit<
    PlannedWorkTemplateProps & {
      overrideDisplayType?: ScreenPreviewDisplayTypes | null;
    },
    'isLandscape' | 'feedId'
  >
> & { children?: React.ReactNode } = ({
  overrideDisplayType,
  ...pwTemplateProps
}) => {
  const feedId = useFeedId();
  const displayType =
    overrideDisplayType === undefined
      ? getSuggestedDisplayType(feedId)
      : overrideDisplayType;

  const [modalSettings, setModalSettings] = useState({
    isOpen: false,
    isLandscape: false,
  });

  const disablePortraitScreenPreview = useFeatureFlag(
    FeatureFlagName.PLANNED_WORK_TEMPLATE_DISABLE_PORTRAIT_SCREEN_PREVIEW,
  );

  const bodyEl = (() => {
    if (displayType === ScreenPreviewDisplayTypes.DUAL) {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: ${!disablePortraitScreenPreview ? '12px' : '0'};
            background: #eaeaea;
            border: 1px solid ${theme.colors['border-dark']};
            border-radius: 4px;
          `}
        >
          <div
            css={css`
              position: relative;
              width: 33%;
              margin-bottom: 12px;
            `}
          >
            <HoverFillButton
              onClick={() =>
                setModalSettings({ isOpen: true, isLandscape: false })
              }
            />
            <PlannedWorkPreviewIframeWrapper
              feedId={feedId}
              isLandscape={false}
              {...pwTemplateProps}
            />
          </div>
          <div
            css={css`
              position: relative;
              width: ${!disablePortraitScreenPreview ? '60%' : '100%'};
            `}
          >
            <HoverFillButton
              onClick={() =>
                setModalSettings({ isOpen: true, isLandscape: true })
              }
            />
            <PlannedWorkPreviewIframeWrapper
              feedId={feedId}
              isLandscape
              {...pwTemplateProps}
            />
          </div>
          <PreviewModal
            isOpen={modalSettings.isOpen}
            onDismiss={() =>
              setModalSettings((prevSettings) => ({
                ...prevSettings,
                isOpen: false,
              }))
            }
          >
            <div
              css={css`
                width: ${modalSettings.isLandscape ? '100%' : '60%'};
                margin: ${modalSettings.isLandscape ? '120px' : '16px'}
                  ${modalSettings.isLandscape ? '16px' : '120px'};
              `}
            >
              <PlannedWorkPreviewIframeWrapper
                feedId={feedId}
                isLandscape={modalSettings.isLandscape}
                {...pwTemplateProps}
              />
            </div>
          </PreviewModal>
        </div>
      );
    }

    if (
      displayType === ScreenPreviewDisplayTypes.LANDSCAPE ||
      displayType === ScreenPreviewDisplayTypes.DUP ||
      !!pwTemplateProps.isDup
    ) {
      return (
        <PlannedWorkPreviewIframeWrapper
          feedId={feedId}
          isLandscape
          {...pwTemplateProps}
        />
      );
    }

    if (displayType === ScreenPreviewDisplayTypes.PORTRAIT) {
      return (
        <PlannedWorkPreviewIframeWrapper
          feedId={feedId}
          isLandscape={false}
          {...pwTemplateProps}
        />
      );
    }

    return null;
  })();

  return <div>{bodyEl}</div>;
};

export default PlannedWorkTemplatePreview;
