/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';

import { PLANNED_WORK_LOCAL_SAVE_KEY } from 'constants/planned-work';

import AppLink from '../../common/app-link';
import AgencySelect from './AgencySelect';
import UserInfo from './UserInfo';
import NavLinks from './NavLinks';
import MTALogo from '../../common/MTALogo';
import { useFeedId } from '../../../contexts/FeedId';
import useAuthentication from '../../../contexts/Authentication';
import useLocalForage from '../../../hooks/useLocalForage';

import { CurrentUser_currentUser_User as CurrentUser_currentUser } from '../../../generated/CurrentUser';
import { Session } from '../../../types/authentication';
import { FeedId } from '../../../types/feeds';
import { ReactComponent as CollapseIcon } from '../../../images/icon_collapse.svg';

import { ReactComponent as IconNewtab } from '../../../images/icon_newtab.svg';

import * as S from './index.styled';
import { ThemeType } from '../../../theme';

const {
  REACT_APP_HELP_URL,
  REACT_APP_SEND_FEEDBACK_URL,
  REACT_APP_REPORT_ISSUE_URL,
} = process.env;
const SIDEBAR_ID = 'app-sidebar';

const ENV_PROPS = {
  local: {
    color: '#04d9ff',
    shortName: 'DEV',
    name: 'Development',
  },
  staging: {
    color: '#FFCF00',
    shortName: 'STG',
    name: 'Staging',
  },
  uat: {
    color: '#00AA08',
    shortName: 'UAT',
    name: 'User Acceptance Testing',
  },
};

const EnvironmentLabel: React.FC<{ env: string; isOpen: boolean }> = ({
  env,
  isOpen,
}) => {
  if (env === 'local' || env === 'staging' || env === 'uat') {
    return (
      <S.Environment color={ENV_PROPS[env].color}>
        {isOpen ? ENV_PROPS[env].name : ENV_PROPS[env].shortName}
      </S.Environment>
    );
  }
  return null;
};

const AuthenticatedContent: React.FC<{
  isOpen: boolean;
  userFeeds: FeedId[];
  user?: CurrentUser_currentUser | null;
  session: Session;
  feedId: FeedId;
  logout: () => Promise<void>;
  onToggleOpen: () => void;
}> = ({ session, isOpen, onToggleOpen, logout, user, feedId, userFeeds }) => {
  const [, setPwLocalCopy] = useLocalForage<string>(
    PLANNED_WORK_LOCAL_SAVE_KEY,
  );

  if (!session.isAuthenticated) {
    return null;
  }

  const name = user?.name || session?.currentUser.name;
  const shouldShow = user?.access?.isActive && user?.feeds?.nodes?.length > 0;
  return (
    <React.Fragment>
      <S.NavToggleButton
        onClick={onToggleOpen}
        aria-controls={SIDEBAR_ID}
        aria-haspopup
      >
        <CollapseIcon />
      </S.NavToggleButton>
      {session.isAuthenticated && (
        <S.InnerContainer>
          {user && shouldShow && (
            <AgencySelect
              feeds={userFeeds}
              isOpen={isOpen}
              isAdmin={!!user?.access?.isAdmin}
            />
          )}
          {user && shouldShow ? (
            <S.Nav aria-label="Main Navigation">
              <NavLinks user={user} isOpen={isOpen} feedId={feedId} />
            </S.Nav>
          ) : (
            <S.Nav />
          )}
          <S.Spacer />
          <React.Fragment>
            <S.SendFeedback
              href={REACT_APP_HELP_URL}
              rel="noopener"
              target="_blank"
            >
              Help{' '}
              <IconNewtab
                css={({ spacing }: ThemeType) => css`
                  margin-left: ${spacing.xxsmall};
                  opacity: 0.5;
                `}
              />
            </S.SendFeedback>
            <S.SendFeedback
              href={REACT_APP_SEND_FEEDBACK_URL}
              rel="noopener"
              target="_blank"
            >
              Send Feedback{' '}
              <IconNewtab
                css={({ spacing }: ThemeType) => css`
                  margin-left: ${spacing.xxsmall};
                  opacity: 0.5;
                `}
              />
            </S.SendFeedback>
            <S.SendFeedback
              href={REACT_APP_REPORT_ISSUE_URL}
              rel="noopener"
              target="_blank"
              css={() => css`
                padding: 9px 16px 16px 16px;
              `}
            >
              Report Problem{' '}
              <IconNewtab
                css={({ spacing }: ThemeType) => css`
                  margin-left: ${spacing.xxsmall};
                  opacity: 0.5;
                `}
              />
            </S.SendFeedback>
            <UserInfo
              navIsOpen={isOpen}
              name={name}
              onClick={async () => {
                await setPwLocalCopy('{}');
                logout();
              }}
            />
          </React.Fragment>
        </S.InnerContainer>
      )}
    </React.Fragment>
  );
};

const AppBar: React.FC<{
  isOpen: boolean;
  onToggleOpen(): void;
  user?: CurrentUser_currentUser | null;
  userFeeds: FeedId[];
  env?: string;
}> = ({ isOpen, onToggleOpen, user, userFeeds, env = '' }) => {
  const { session, logout } = useAuthentication();
  const feedId = useFeedId();
  return (
    <S.Container
      aria-label={isOpen ? 'Menu expanded' : 'Menu collapsed'}
      aria-expanded={isOpen}
      id={SIDEBAR_ID}
    >
      <EnvironmentLabel isOpen={isOpen} env={env} />
      <S.LogoContainer env={env}>
        <AppLink to={`/${feedId}`}>
          <MTALogo fill="#ffffff" />
        </AppLink>
      </S.LogoContainer>
      <AuthenticatedContent
        userFeeds={userFeeds}
        feedId={feedId}
        user={user}
        isOpen={isOpen}
        session={session}
        onToggleOpen={onToggleOpen}
        logout={logout}
      />
    </S.Container>
  );
};

export default AppBar;
