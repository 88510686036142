/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import { useFeedId } from 'contexts/FeedId';
import { useLocation, useHistory } from 'react-router-dom';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';
import Button from 'components/common/Button';
import { useMutation, ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import AssetForm, {
  FormValues as AssetFormValues,
  DefaultValues as AssetFormDefaultValues,
  FormTypes,
} from '../../common/asset-form';

import * as S from './index.styled';

import {
  UpdateUpload,
  UpdateUploadVariables,
} from '../../../generated/UpdateUpload';
import {
  CreateSocialMediaUpload,
  CreateSocialMediaUploadVariables,
} from '../../../generated/CreateSocialMediaUpload';

const UpdateUploadMutation = loader('../../../graphql/UpdateUpload.gql');

const CreateSocialMediaUploadMutation = loader(
  '../../../graphql/CreateSocialMediaUpload.gql',
);

const SocialMediaAssetCompose: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const history = useHistory();
  const location = useLocation<{ defaultValues?: AssetFormDefaultValues }>();
  const feedId = useFeedId();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultValues = location.state?.defaultValues;

  const [updateUpload] = useMutation<UpdateUpload, UpdateUploadVariables>(
    UpdateUploadMutation,
    {
      onError: (error: ApolloError) => {
        throw error;
      },
    },
  );

  const [createSocialMediaUpload] = useMutation<
    CreateSocialMediaUpload,
    CreateSocialMediaUploadVariables
  >(CreateSocialMediaUploadMutation, {
    onError: (error: ApolloError) => {
      throw error;
    },
  });

  const handleSubmit = () => async (fv: AssetFormValues) => {
    setIsSubmitting(true);
    try {
      const { data: uploadData } = await updateUpload({
        variables: { ...fv, id: fv.upload?.id },
      });
      if (uploadData) {
        const { data: socialMediaUploadData } = await createSocialMediaUpload({
          variables: {
            uploadId: uploadData?.updateUpload?.upload?.id,
            folderId: fv?.folderId,
            feedId,
          },
        });
        history.push(
          `/${feedId}/assets/${socialMediaUploadData?.createSocialMediaUpload?.socialMediaUpload?.upload?.id}?type=social-media`,
        );
      }
    } catch (e) {
      Sentry.captureException(e);
    }
    setIsSubmitting(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <PageMeta title="Add Asset" />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}/assets/social-media`,
          title: 'Back to social media',
        }}
      />
      <AssetForm
        feedId={feedId}
        heading="Add Asset"
        defaultValues={defaultValues}
        formType={FormTypes.SocialMedia}
      >
        {/* @ts-ignore */}
        {({ makeSubmitHandler, form }) => {
          return (
            <React.Fragment>
              {form}

              <S.Buttons
                css={css`
                  margin: 16px 32px;
                `}
              >
                <Button
                  primary
                  type="button"
                  disabled={isSubmitting}
                  onClick={makeSubmitHandler(handleSubmit())}
                >
                  Add
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    if (
                      window.confirm(
                        `${DISCARD_CHANGES_MESSAGE} This asset will not be saved.`,
                      )
                    ) {
                      history.push(`/${feedId}/assets/social-media`);
                    }
                  }}
                >
                  Cancel
                </Button>
              </S.Buttons>
            </React.Fragment>
          );
        }}
      </AssetForm>
    </div>
  );
};

export default SocialMediaAssetCompose;
