import {
  FeedId,
  getMessageTypesByFeedAndCategory,
  getClearedMessageTypesByFeedAndCategory,
  MESSAGE_TYPE_DESCRIPTIONS,
  MessageTypeCategory,
} from '@mta-live-media-manager/shared';
import { MessageType } from '../generated/global-types';

// Get only active message types that new messages can currently use
export const getMessageTypeDescriptionsByFeed: (
  feedId: FeedId,
) => { label: string; value: MessageType }[] = (feedId) =>
  getMessageTypesByFeedAndCategory(
    feedId,
    MessageTypeCategory.SERVICE_ALERT,
  ).map((type) => ({
    label: MESSAGE_TYPE_DESCRIPTIONS[type],
    value: type,
  }));

// Get all message types for a specific feed, including archived ones
export const getClearedMessageTypeDescriptionsByFeed: (
  feedId: FeedId,
) => { label: string; value: MessageType }[] = (feedId) =>
  getClearedMessageTypesByFeedAndCategory(
    feedId,
    MessageTypeCategory.SERVICE_ALERT,
  ).map((type) => ({
    label: MESSAGE_TYPE_DESCRIPTIONS[type],
    value: type,
  }));

// Get only active message types that new messages can currently use
export const getPlannedWorkMessageTypeDescriptionsByFeed: (feedId: FeedId) => {
  label: string;
  value: MessageType;
  data: { category: string };
}[] = (feedId) =>
  getMessageTypesByFeedAndCategory(
    feedId,
    MessageTypeCategory.PLANNED_WORK,
  ).map((type) => ({
    label: MESSAGE_TYPE_DESCRIPTIONS[type],
    value: type,
    data: {
      category: type.toLowerCase().replace(/_/g, '-'),
    },
  }));

// Get all message types for a specific feed, including archived ones
export const getClearedPlannedWorkMessageTypeDescriptionsByFeed: (
  feedId: FeedId,
) => {
  label: string;
  value: MessageType;
  data: { category: string };
}[] = (feedId) =>
  getClearedMessageTypesByFeedAndCategory(
    feedId,
    MessageTypeCategory.PLANNED_WORK,
  ).map((type) => ({
    label: MESSAGE_TYPE_DESCRIPTIONS[type],
    value: type,
    data: {
      category: type.toLowerCase().replace(/_/g, '-'),
    },
  }));

type MessageTypeMap = { [key in MessageType]: MessageType };

// If a message type has been deprecated, attempt to find its replacement.
// If no replacement is found, return the original.
export const getReplacementMessageType = (
  oldMessageType: MessageType,
  context: MessageTypeCategory,
  feedId: FeedId = FeedId.NYCTSubway,
): MessageType => {
  // Bridges and TUnnels was added later and doesn't have anything old to map
  if (feedId === FeedId.BT) {
    return oldMessageType;
  }

  if (context === MessageTypeCategory.PLANNED_WORK) {
    const mapping = {
      [MessageType.NO_MIDDAY_SERVICE]: MessageType.NO_SCHEDULED_SERVICE,
      [MessageType.NO_OVERNIGHT_SERVICE]: MessageType.NO_SCHEDULED_SERVICE,
      [MessageType.NO_WEEKEND_SERVICE]: MessageType.NO_SCHEDULED_SERVICE,
      [MessageType.PLANNED_BUSES_DETOURED]: MessageType.PLANNED_DETOUR,
      [MessageType.PLANNED_WORK]: MessageType.SPECIAL_NOTICE,
      [MessageType.PLANNED_STATIONS_SKIPPED]: MessageType.PLANNED_STOPS_SKIPPED,
      [MessageType.PLANNED_BOARDING_CHANGE]: MessageType.PLANNED_REROUTE,
    } as MessageTypeMap;

    return mapping[oldMessageType] || oldMessageType;
  }

  // For alerts, some slight variations between feeds but
  // MNR, LIRR, and NYCTSubway are largely the same
  const trainMapping = {
    [MessageType.ARRIVAL_INFORMATION_OUTAGE]: MessageType.INFORMATION_OUTAGE,
    [MessageType.LOCAL_TO_EXPRESS]: MessageType.STOPS_SKIPPED,
    [MessageType.MULTIPLE_CHANGES]: MessageType.DELAYS,
    [MessageType.SERVICE_CHANGE]: MessageType.DELAYS,
    [MessageType.SHUTTLE_BUSES_DETOURED]: MessageType.REROUTE,
    [MessageType.SLOW_SPEEDS]: MessageType.DELAYS,
    [MessageType.SOME_REROUTES]: MessageType.REROUTE,
    [MessageType.STATIONS_SKIPPED]: MessageType.STOPS_SKIPPED,
    [MessageType.TRAINS_REROUTED]: MessageType.REROUTE,
  } as MessageTypeMap;

  // Mapping for alerts
  const mapping = {
    [FeedId.LIRR]: trainMapping,
    [FeedId.MNR]: trainMapping,
    [FeedId.NYCTBus]: {
      [MessageType.ARRIVAL_INFORMATION_OUTAGE]: MessageType.INFORMATION_OUTAGE,
      [MessageType.BUSES_DETOURED]: MessageType.DETOUR,
      [MessageType.MULTIPLE_CHANGES]: MessageType.DELAYS,
      [MessageType.SERVICE_CHANGE]: MessageType.DELAYS,
      [MessageType.SOME_DELAYS]: MessageType.DELAYS,
      [MessageType.SOME_REROUTES]: MessageType.DETOUR,
    } as MessageTypeMap,
    [FeedId.NYCTSubway]: {
      ...trainMapping,
      [MessageType.SOME_DELAYS]: MessageType.DELAYS,
    } as MessageTypeMap,
    [FeedId.OutfrontDEV]: {} as MessageTypeMap,
    [FeedId.OutfrontQA]: {} as MessageTypeMap,
  };

  return mapping[feedId][oldMessageType] || oldMessageType;
};
