/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import Button from 'components/common/Button';
import { flexRow } from '../styles';
import theme, { ThemeType } from '../../../theme';
import ChevronIcon from '../chevron-icon';

export const Section: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={css`
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      min-width: 0;
      background-color: ${theme.colors['background-inset']};
      border-radius: 0 0 8px 8px;
    `}
    {...props}
  />
);

export const ToggleButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = ({ ...props }) => (
  <Button
    plain
    type="button"
    onMouseDown={(e) => {
      e.preventDefault();
    }}
    css={(theme: ThemeType) => [
      flexRow,
      css`
        color: ${theme.colors.brand};
        padding: 32px 40px;
        justify-content: space-between;
        align-items: center;
        font-family: ${theme.typography.families.primary};
        cursor: 'pointer';
        text-transform: initial;
      `,
    ]}
    {...props}
  />
);

export const IndicatorArrow: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <ChevronIcon
    direction={isOpen ? 'down' : 'right'}
    css={css`
      margin-right: 12px;
      color: ${theme.colors.accent1};
    `}
  />
);

export const Spacer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      flex: 1;
    `}
    {...props}
  />
);

export const ContainerRow = css`
  display: grid;
  row-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: ${theme.spacing.medium};
  margin-left: 50px;
  margin-right: 40px;
`;

export const InputContainer = css`
  margin-left: 1rem;
  display: flex;
  flex: 0 1 0;
`;

export const NotesContainer = css`
  display: flex;
  flex: 2 1 0;
  grid-area: 2 / 1 / 2 / 5;
`;

export const LabelledInput = css`
  &:last-of-type > *:last-of-type {
    background-color: ${theme.colors.white};
  }
`;

export const TimeInputSpacer = css`
  margin: 8px;
`;

export const StatusBanner = css`
  margin: ${theme.spacing.small} 20px ${theme.spacing.medium} 66px;
`;

export const ButtonContainer = css`
  margin-top: 31px;
  display: flex;
  flex: 0;
`;
