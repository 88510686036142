/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { Collapse } from 'react-collapse';

import { ThemeType } from 'theme';
import { PlannedWorkNumbers } from 'generated/PlannedWorkNumbers';
import TagEntry from 'ui-kit/tag-entry';
import { GetPlannedWorkById_plannedWork_PlannedWork as GetPlannedWorkById_plannedWork } from 'generated/GetPlannedWorkById';
import * as styles from './styles';
import Heading from '../../common/Heading';
import { FormState } from './types';

import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

import {
  Section,
  ToggleButton,
  Spacer,
  IndicatorArrow,
} from '../../common/internal-log/internal-log.styled';
import { FeatureFlagName } from 'generated/global-types';

const PlannedWorkNumbersQuery = loader(
  '../../../graphql/PlannedWorkNumbers.gql',
);

const InternalNotes: React.FC<
  {
    servicePlanNumbers: GetPlannedWorkById_plannedWork['servicePlanNumbers'];
    generalOrderNumbers: GetPlannedWorkById_plannedWork['generalOrderNumbers'];
    internalNotes: GetPlannedWorkById_plannedWork['internalNotes'];
    updateValue: (key: keyof FormState) => (value: any) => void;
    isOpen?: boolean;
    onClick: () => void;
  } & { children?: React.ReactNode }
> = ({
  servicePlanNumbers,
  internalNotes,
  generalOrderNumbers,
  updateValue,
  isOpen = true,
  onClick,
  children,
}) => {
  const { data, loading } = useQuery<PlannedWorkNumbers>(
    PlannedWorkNumbersQuery,
    {
      skip: !isOpen,
      fetchPolicy: 'cache-and-network',
    },
  );

  const allServicePlanNumbers: string[] = (
    data?.servicePlanNumbers?.nodes ?? []
  ).map((n) => n.servicePlanNumber);
  const allGeneralOrderNumbers: string[] = (
    data?.generalOrderNumbers?.nodes ?? []
  ).map((n) => n.generalOrderNumber);

  const pwGoAndSpFlag = useFeatureFlag(FeatureFlagName.PLANNED_WORK_GO_SP);
  const collapsableIsOpen = isOpen && !loading;

  return (
    <Section>
      <ToggleButton
        css={isOpen && styles.internalNotesToggle}
        onClick={onClick}
      >
        <IndicatorArrow isOpen={collapsableIsOpen} />
        <Heading level={3}>Internal Notes</Heading>
        <Spacer />
      </ToggleButton>
      <Collapse isOpened={collapsableIsOpen}>
        <div className="internal-notes__form" css={styles.section3}>
          {pwGoAndSpFlag && (
            <React.Fragment>
              <div
                className="internal-notes__input-wrapper"
                css={styles.inputWrapper}
              >
                <label css={styles.label} htmlFor="servicePlanNumbers">
                  Service Plan #
                </label>
                <TagEntry
                  id="servicePlanNumbers"
                  onChange={updateValue('servicePlanNumbers')}
                  value={servicePlanNumbers || undefined}
                  tags={allServicePlanNumbers}
                />
              </div>
              <div
                className="internal-notes__input-wrapper"
                css={styles.inputWrapper}
              >
                <label css={styles.label} htmlFor="generalOrderNumbers">
                  GO #
                </label>
                <TagEntry
                  id="generalOrderNumbers"
                  onChange={updateValue('generalOrderNumbers')}
                  value={generalOrderNumbers || undefined}
                  tags={allGeneralOrderNumbers}
                />
              </div>
            </React.Fragment>
          )}
          <div
            className="internal-notes__input-wrapper"
            css={styles.inputWrapper}
          >
            <label css={styles.label} htmlFor="notes">
              Notes
            </label>
            <textarea
              css={(theme: ThemeType) => css`
                ${styles.textarea}
                border-radius: 4px;
                height: 74px;
                border: 1px solid ${theme.colors['border-dark']};
                outline: none;
                box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
                &:focus {
                  border-color: ${theme.colors.accent3};
                }
              `}
              placeholder="Add internal notes here"
              id="notes"
              onChange={(e) => updateValue('internalNotes')(e.target.value)}
              defaultValue={internalNotes || ''}
            />
          </div>
          {children}
        </div>
      </Collapse>
    </Section>
  );
};

export default InternalNotes;
