import { ApolloError, ServerError } from '@apollo/client';
import authenticationClient from './authentication';

export const handleUseQueryError = (
  error: ApolloError | undefined,
  data: any | undefined = undefined,
  strict: boolean = true,
) => {
  if (!data && error) {
    if (strict) {
      throw error;
    }
    const networkError = error.networkError as ServerError;
    const errors = networkError?.result;

    if (errors) {
      throw error;
    }
  }
};

export const onError = ({ networkError }: ApolloError) => {
  // The apollo undefined network error won't return a status code if the refresh token is invalid.
  if (networkError && !(networkError as ServerError).statusCode) {
    authenticationClient.unauthenticatedSession(
      `Network undefined ${networkError}`,
    );
  }
};
