import { format } from 'date-fns';
import {
  Users_sortedUsers_UsersConnection_nodes_User_access_UsersAccess as Users_sortedUsers_nodes_access,
  Users_sortedUsers_UsersConnection_nodes_User as Users_sortedUsers_nodes,
} from '../../../generated/Users';
import { UsersOrderBy } from '../../../generated/global-types';
import { DATE_FORMAT } from '../../../constants/time';

export enum TUsersTableSortingTypes {
  name = 'name',
  lastSeenAt = 'lastSeenAt',
  isAdmin = 'isAdmin',
}

export const COL_WIDTHS: { [key: string]: string } = {
  info: 'auto',
  access: '130px',
  lastSeenAt: '230px',
  feeds: '100px',
  actions: '100px',
};

export type TableStructure<T = { [key: string]: any }> = {
  info: {
    name?: string | null;
    email: string;
    isSelf: boolean;
  };
  access: {
    role?: Users_sortedUsers_nodes_access | null;
    isSelf: boolean;
    userId: number | null;
  };
  lastSeenAt: string;
  actions: {
    user: Users_sortedUsers_nodes;
    isSelf: boolean;
  };
} & T;

export const transformDataToTableStructure = (
  users: Users_sortedUsers_nodes[],
  feedGroups: string[],
  currentUser?: Users_sortedUsers_nodes | undefined | null,
): TableStructure[] => {
  const tableUsers: TableStructure[] = [];
  users.forEach((user) => {
    const tableUser: TableStructure = {
      info: {
        name: user.name,
        email: user.email,
        isSelf: user.id === currentUser?.id,
      },
      access: {
        role: user.access,
        isSelf: user.id === currentUser?.id,
        userId: user.id,
      },
      lastSeenAt: format(
        new Date(user.lastSeenAt as string),
        DATE_FORMAT,
      ).toLowerCase(),
      actions: {
        user,
        isSelf: user.id === currentUser?.id,
      },
    };

    const userFeeds = user.feeds.nodes.map(
      (feedNode) => feedNode.feedGroupName,
    );
    feedGroups.forEach((f) => {
      tableUser[f] = {
        isSelected: userFeeds.indexOf(f) > -1,
        isDisabled:
          user.id === currentUser?.id || !currentUser?.access?.isAdmin,
        user,
        userFeeds,
      };
    });

    tableUsers.push(tableUser);
  });

  return tableUsers;
};

export const getLastSeenSortIconClass = (sort: UsersOrderBy): string => {
  if (sort === UsersOrderBy.LAST_SEEN_AT_ASC) {
    return 'asc';
  }
  if (sort === UsersOrderBy.LAST_SEEN_AT_DESC) {
    return 'desc';
  }
  return '';
};

export const getIsAdminSortIconClass = (sort: UsersOrderBy): string => {
  if (sort === UsersOrderBy.IS_ADMIN_ASC) {
    return 'asc';
  }
  if (sort === UsersOrderBy.IS_ADMIN_DESC) {
    return 'desc';
  }
  return '';
};

export const getNameSortIconClass = (sort: UsersOrderBy): string => {
  if (sort === UsersOrderBy.NAME_ASC) {
    return 'asc';
  }
  if (sort === UsersOrderBy.NAME_DESC) {
    return 'desc';
  }
  return '';
};

export const getFeedSortIconClass = (
  feedParam: string,
  feed: string | null,
  order: string | null,
): string => {
  if (feed && order && feedParam === feed) {
    if (order === 'asc' || order === 'desc') {
      return order;
    }
  }
  return '';
};
