/** @jsxImportSource @emotion/react */

import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import useForm, { FormContext } from 'react-hook-form';

import theme from '../../theme';
import ConfirmModal from './confirm-modal';
import ExpirationTimePicker from './form-elements/expiration-time-picker';
import RadioButton from './RadioButton';

const CLEAR_ALERT_FORM_ID = 'clear-alert-modal-form';
const CLEAR_ALERT_LATER_NAME = 'clearAlertLater';
const CLEAR_ALERT_DURATION_NAME = 'clearAlertDuration';
const CLEAR_NOW_APPS_MESSAGE =
  'This message will immediately be removed from web and apps.';
const CLEAR_NOW_SCREENS_MESSAGE =
  'This message will immediately be removed from screens.';
const CLEAR_NOW_MESSAGE_ALL =
  'This message will immediately be removed from screens, web, and apps.';

const indentedRowCss = css`
  margin-top: 8px;
  margin-left: 28px;
`;

const radioFocusCss = css`
  &:focus-within {
    ::after {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      border-radius: 4px;
      pointer-events: none;
      border: 1px solid ${theme.colors.accent3};
    }
  }
`;

interface IClearAlertFormData {
  [CLEAR_ALERT_LATER_NAME]: boolean;
  [CLEAR_ALERT_DURATION_NAME]: Date;
}

interface IClearAlertModalProps {
  isOpen: boolean;
  onClear: () => void;
  onClearLater: (newEndAt: Date | null) => void;
  onDismiss: () => void;
  hasTargetedApps?: boolean;
  hasTargetedScreens?: boolean;
  initialEndAt?: Date;
}

const ClearAlertModal: React.FC<IClearAlertModalProps> = ({
  isOpen,
  onClear,
  onClearLater,
  onDismiss,
  initialEndAt,
  hasTargetedApps,
  hasTargetedScreens,
}) => {
  const formMethods = useForm<IClearAlertFormData>({
    mode: 'onBlur',
    defaultValues: {
      [CLEAR_ALERT_LATER_NAME]: !!initialEndAt,
      [CLEAR_ALERT_DURATION_NAME]: initialEndAt,
    },
  });

  const { setValue, handleSubmit, register, watch } = formMethods;
  const shouldClearLater = watch(CLEAR_ALERT_LATER_NAME) as boolean;

  useEffect(() => {
    register({ name: CLEAR_ALERT_LATER_NAME });
  }, [register]);

  const onSubmit = (formData: IClearAlertFormData) => {
    if (shouldClearLater) {
      const newEndAt = formData[CLEAR_ALERT_DURATION_NAME];

      if (newEndAt) {
        onClearLater(newEndAt);
      } else {
        onClearLater(null);
      }
    } else {
      onClear();
    }
  };

  const warningText = (() => {
    if (hasTargetedScreens && hasTargetedApps) {
      return CLEAR_NOW_MESSAGE_ALL;
    }

    if (hasTargetedApps) {
      return CLEAR_NOW_APPS_MESSAGE;
    }

    if (hasTargetedScreens) {
      return CLEAR_NOW_SCREENS_MESSAGE;
    }

    return '';
  })();

  return (
    <ConfirmModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      confirmText="Confirm"
      dismissText="Cancel"
      title="Clear Alert"
      form={CLEAR_ALERT_FORM_ID}
    >
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} id={CLEAR_ALERT_FORM_ID}>
          <div
            css={css`
              display: inline-flex;
              vertical-align: center;
              position: relative;

              ${radioFocusCss}
            `}
          >
            <RadioButton
              id={`${CLEAR_ALERT_LATER_NAME}-now`}
              name={CLEAR_ALERT_LATER_NAME}
              onChange={(isChecked) =>
                isChecked && setValue(CLEAR_ALERT_LATER_NAME, false)
              }
              size={20}
              checked={!shouldClearLater}
            />
            <label
              css={css`
                ${theme.typography.sizes.large};
                font-weight: ${theme.typography.weights.bold};
                line-height: 1;
                margin-left: 8px;
              `}
              htmlFor={`${CLEAR_ALERT_LATER_NAME}-now`}
            >
              Clear Now
            </label>
          </div>
          <div css={indentedRowCss}>
            {warningText && (
              <label htmlFor={`${CLEAR_ALERT_LATER_NAME}-now`}>
                {warningText}
              </label>
            )}
          </div>
          <div
            css={css`
              display: inline-flex;
              vertical-align: center;
              margin-top: 16px;
              position: relative;

              ${radioFocusCss}
            `}
          >
            <RadioButton
              id={`${CLEAR_ALERT_LATER_NAME}-later`}
              name={CLEAR_ALERT_LATER_NAME}
              onChange={(isChecked) =>
                isChecked && setValue(CLEAR_ALERT_LATER_NAME, true)
              }
              size={20}
              checked={shouldClearLater}
            />
            <label
              css={css`
                ${theme.typography.sizes.large};
                font-weight: ${theme.typography.weights.bold};
                line-height: 1;
                margin-left: 8px;
              `}
              htmlFor={`${CLEAR_ALERT_LATER_NAME}-later`}
            >
              Clear Later
            </label>
          </div>
          <ExpirationTimePicker
            css={indentedRowCss}
            id={CLEAR_ALERT_DURATION_NAME}
            name={CLEAR_ALERT_DURATION_NAME}
            shouldStack
            onChange={() => {
              setValue(CLEAR_ALERT_LATER_NAME, true);
            }}
          />
        </form>
      </FormContext>
    </ConfirmModal>
  );
};

export default ClearAlertModal;
