/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Collapse } from 'react-collapse';
import { ThemeType } from 'theme';

const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <li
    css={(theme: ThemeType) => css`
      background: ${theme.colors.white};
      border-top-width: 1px;
      border-bottom-width: 1px;
      border-right-width: 0;
      border-left-width: 0;
      border-style: solid;
      border-color: ${theme.colors.border};
      border-bottom: 0;
      flex-flow: column;
      display: flex;

      &:first-of-type {
        border-radius: 8px 8px 0 0;
        border-bottom-width: 1px;
      }
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-width: 0;
      }
    `}
    {...props}
  />
);

const ContentWrapper: React.FC<
  {
    children?: React.ReactNode;
  } & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
    `}
    {...props}
  />
);

const ContentSummary: React.FC<{ children?: React.ReactNode }> = (props) => (
  <div
    css={css`
      flex-grow: 1;
      padding: 16px;
      max-width: 67%;
    `}
    {...props}
  />
);

const PublishStatusWrapper: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      padding-left: 8px;
      padding-top: ${theme.spacing.medium};
      * > div {
        margin-bottom: 0;
      }
    `}
    {...props}
  />
);

const UpdateWrapper: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 33%;
    `}
    {...props}
  />
);

const WarningIcon: React.FC<unknown & { children?: React.ReactNode }> = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M8 0a8 8 0 100 16A8 8 0 008 0z" fill="#FFCF00" />
      <path
        d="M8 1.792c.107 0 .204.058.257.15l5.305 9.432a.293.293 0 01-.002.294.294.294 0 01-.254.146H2.696a.294.294 0 01-.258-.44l5.305-9.431A.296.296 0 018 1.793zm0 .897l-4.8 8.536H12.8l-4.8-8.536zm0 6.58c.163 0 .295.132.295.295v.59a.295.295 0 01-.59 0v-.59c0-.163.132-.295.295-.295zm0-4.126c.163 0 .295.132.295.295v2.947a.295.295 0 01-.59 0V5.438c0-.163.132-.295.295-.295z"
        stroke="#FFF"
        strokeWidth=".2"
        fill="#000"
      />
    </g>
  </svg>
);

const Type: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <span
    css={(theme: ThemeType) => css`
      font-family: ${theme.typography.families.primary};
      color: ${theme.colors.black};
      ${theme.typography.sizes.medium}
      font-weight: 600;
      vertical-align: middle;
    `}
    {...props}
  />
);

const DateTimeStamps: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      color: ${theme.colors.black};
      font-family: ${theme.typography.families.primary};
      padding-right: 20px;
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      border-right: 1px solid ${theme.colors.light1};
    `}
    {...props}
  />
);

const UpdatesBadge: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      padding: 1px 8px;
      font-weight: ${theme.typography.weights.normal};
      background-color: ${theme.colors.border};
      border-radius: 11px;
      display: inline-block;
      text-align: center;
    `}
    {...props}
  />
);

const Inputs: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      margin-top: 12px;
      span {
        margin: 0 4px 4px;
      }
      span:first-of-type {
        margin-left: 0;
      }
      span:last-child {
        margin-right: 0;
      }
    `}
    {...props}
  />
);

const IncidentWrapper: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    {...props}
    css={(theme: ThemeType) => css`
      width: 100%;
      border-top: 1px solid ${theme.colors.light1};
      padding: 12px 0 12px 16px;
    `}
  />
);

const Incident: React.FC<{ show: boolean; children?: React.ReactNode }> = ({
  show,
  children,
}) => <Collapse isOpened={show}>{children}</Collapse>;

export const Message = {
  Container,
  ContentSummary,
  ContentWrapper,
  Incident,
  IncidentWrapper,
  UpdateWrapper,
  DateTimeStamps,
  Type,
  UpdatesBadge,
  Inputs,
  PublishStatusWrapper,
  WarningIcon,
};
