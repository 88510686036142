import { FeedId } from '../types/feeds';

export const IDENTICAL_TWEET =
  'This text is identical to a recent Tweet you posted. Please edit your message to post an update.';
export const MISSING_MESSAGE_ALERT_TYPE = 'Select a status to post this alert.';
export const NO_AFFECTED_LINE_SELECTED = 'Select a line to post this alert';
export const WEB_MESSAGE_REQUIRED = 'Write a message to post this alert.';
export const TWITTER_MESSAGE_REQUIRED = 'Please enter a Twitter message.';
export const TWITTER_MESSAGE_REQUIRED_THREAD =
  'Please enter a Twitter message for all tweets in thread.';
export const TWITTER_MESSAGE_TOO_LONG = 'Tweet is too long.';
export const TWITTER_ATTACHMENTS_STILL_UPLOADING =
  'Attachments are still being uploaded.';
export const EMAIL_SUBJECT_REQUIRED = 'Please enter a valid e-mail subject.';
export const EMAIL_BODY_REQUIRED = 'Please enter a valid e-mail body.';
export const EMAIL_TYPES_REQUIRED =
  'Please select at least one distribution group.';
export const NO_MESSAGE_OR_LINE =
  'Write a message and select a line to post this alert.';
export const NO_LINE_OR_STATUS = 'Select a line and status to post this alert.';
export const NO_MESSAGE_OR_STATUS =
  'Write a message and select a status to post this alert.';
export const NO_MESSAGE_OR_LINE_OR_STATUS =
  'Write a message and select line & status to post this alert.';

export const CAMPAIGN_TITLE_REQUIRED = 'Please provide a title.';
export const CAMPAIGN_DURATIONS_REQUIRED =
  'Please set at least 1 duration for this campaign.';
export const CAMPAIGN_LAYOUTS_UPLOADS_REQUIRED =
  'Please select at least 1 layout and make sure media is uploaded.';
export const CAMPAIGN_LAYOUTS_UPLOADS_REQUIRED_NO_LAYOUT_SELECTION =
  'Please make sure media is uploaded.';
export const CAMPAIGN_STATIONS_SCREENS_REQUIRED =
  'Please select line(s) and station(s).';
export const CAMPAIGN_SHOPS_REQUIRED =
  'Please select shops of lines for targeting.';
export const TARGETING_TYPE_REQUIRED = 'Please select screen targeting.';
export const TARGETING_SCREEN_WEEK_PARAMS_REQUIRED =
  'Please select Days of Week and Time of Day.';
export const CAMPAIGN_MNR_LINES_REQUIRED =
  'Please select lines/lines groups for targeting.';
export const CAMPAIGN_DATES_EXPIRED =
  'Some dates set for this campaign have expired. Are you sure you want to continue?';
export const CAMPAIGN_UPLOAD_REQUIRED = 'Please make sure media is uploaded.';

export const PLANNED_HEADER_REQUIRED = 'Write a header for this alert.';
export const PLANNED_HEADLINE_LENGTH_LIMIT =
  'Headlines have a 160 character limit.';
export const PLANNED_MESSAGE_REQUIRED =
  'Please enter a message for this alert.';
export const PLANNED_DURATIONS_REQUIRED =
  'Please set at least one active work period.';
export const PLANNED_WORK_SCREEN_HEADLINE_REQUIRED =
  'Please enter a headline for this screen message.';
export const CAMPAIGNS_DURATIONS_REQUIRED = 'Please set at least one duration.';
export const DURATIONS_EXPIRED = 'The end date cannot be set in the past.';
export const CAMPAIGN_DURATIONS_SAME = 'Dates cannot be the same.';
export const PLANNED_DURATIONS_SAME = 'Dates cannot be the same.';
export const PLANNED_LINES_REQUIRED = 'Please add at least one affected line.';
export const PLANNED_STATUSES_REQUIRED =
  'Please set statuses for all affected lines.';
export const PLANNED_LINES_STATUSES_REQUIRED =
  'Please set all lines and statuses.';
export const PLANNED_SCREENS_REQUIRED = 'Please select screens';
export const PLANNED_SCREEN_CONTENT_REQUIRED = 'Please upload screen content';
export const PLANNED_STATION_ALT_LINE_AND_STATION_REQUIRED =
  'Please select lines and stations for all station alternatives';
export const PLANNED_MULTIPLE_SCREEN_ORIENTATIONS =
  'Select a Single Screen Orientation to Upload Screen Content';
export const PLANNED_SAVE_LIVE_AS_DRAFT =
  'Saving this Live Planned Work message as a draft will clear it from the Live Screens and Webs/Apps until it is re-published. Are you sure you want to continue?';

export const RANGE_DOES_NOT_INCLUDE_DAYS =
  'The Start and End dates must include the day of week set in the Weekly Cadence.';
export const END_BEFORE_START =
  'The end date cannot be prior to the start date.';
export const RANGES_CANNOT_OVERLAP = 'Ranges cannot overlap each other.';
export const CADENCES_CANNOT_OVERLAP = 'Cadences cannot overlap each other.';
export const SAME_DAY_CADENCE_END_BEFORE_START =
  'Same day cadences cannot have the end time prior to the start time.';
export const PLANNED_EMPTY_RANGES =
  'Please fill in the missing start & end dates, or delete any unneeded ranges for the active work period you’ve set.';
export const PLANNED_EMPTY_CADENCES =
  'Please fill in the missing day of week & time, or delete any unneeded cadences for the active work period you’ve set.';
export const CAMPAIGNS_EMPTY_RANGES =
  'Please fill in the missing start & end dates, or delete any unneeded ranges for the durations you’ve set.';
export const CAMPAIGNS_EMPTY_CADENCES =
  'Please fill in the missing day of week & time, or delete any unneeded cadences for the durations you’ve set.';

export const EXCEPTIONS_CANNOT_OVERLAP =
  'Exceptions cannot overlap each other.';
export const RANGE_DOES_NOT_INCLUDE_EXCEPTIONS =
  'The Start and End dates must include the exceptions.';
export const PLANNED_EMPTY_EXCEPTIONS =
  'Please fill in the missing start & end dates or delete any unneeded exceptions.';
export const EXCEPTION_END_BEFORE_START =
  'The end date cannot be prior to the start date.';
export const PLANNED_PARTIAL_DURATION =
  'An exception you entered will result in a partial work duration. Are you sure you want to continue?';
export const ACTIVE_SCHEDULED_ASSET_START_CHANGED_TO_PAST =
  'You cannot schedule an asset to play in the past. Please choose a date and time in the future.';

export const NO_AFFECTED_ROUTE_BY_AGENCY: { [key in FeedId]: string } = {
  [FeedId.NYCTSubway]:
    'Please tag the affected route, line, or station using an equals sign, e.g. “=5”, or select one in the box below.',
  [FeedId.NYCTBus]:
    'Please tag the affected route using an equals sign, e.g. “=B63”, or select one in the box below.',
  [FeedId.MNR]:
    'Please tag the affected route, line, or station using an equals sign, e.g. “=Hudson”, or select one in the box below.',
  [FeedId.LIRR]:
    'Please tag the affected route, line, or station using an equals sign, e.g. “=Port Washington”, or select one in the box below.',
  [FeedId.BT]:
    'Please tagged the affected bridge or tunnel using an equals sign, e.g. “=Hugh L. Carey Tunnel”, or select one in the box below.',
  [FeedId.OutfrontQA]:
    'Please tag the affected route, line, or station using an equals sign, e.g. “=5”, or select one in the box below.',
  [FeedId.OutfrontDEV]:
    'Please tag the affected route, line, or station using an equals sign, e.g. “=5”, or select one in the box below.',
};

export const POST_FAILED_EMAIL_AND_SMS = 'Alert failed to post to email & SMS.';
export const POST_FAILED_SCREENS =
  'Alert failed to post to screens. Please try again by updating the alert.';
export const POST_FAILED_TWITTER = 'Alert failed to post to Twitter.';

export const TWITTER_DUPLICATE_HTTP_STATUS_CODE = 403;
export const TWITTER_SERVICE_UNAVAILABLE_HTTP_STATUS_CODE = 503;
export const POST_FAILED_TWITTER_DUPLICATE = 'This Tweet is a duplicate.';
export const TWITTER_POST_POSSIBLE_SUCCESS =
  'Please check Twitter to see if your tweet was published successfully.';
export const TWITTER_POST_FAILED_TRY_NATIVELY =
  'Your tweet was not published successfully. Please try to post this tweet Twitter natively.';

export const DURATION_END_BEFORE_START =
  'Duration end should come after start.';
export const DURATION_INVALID_DATES =
  'Please select a start and end date for this duration.';
export const DURATION_DATE_OVERLAP = 'Durations cannot overlap.';
export const WEB_HEADLINE_LENGTH_LIMIT =
  'Headlines have a 160 character limit. You may add more via Details for web or edit Email & SMS and Twitter individually';

export const ASSET_TITLE_REQUIRED = 'Please provide a title.';
export const ASSET_DESCRIPTION_REQUIRED = 'Please provide a description.';
export const ASSET_LAYOUTS_UPLOADS_REQUIRED =
  'Please select at least 1 layout and make sure media is uploaded.';
export const ASSET_UPLOAD_REQUIRED = 'Please make sure media is uploaded.';

export const GENERIC_SUBMIT_ERROR =
  'Sorry, something went wrong while attempting to post this entry.';
export const GENERIC_SUBMIT_NETWORK_ERROR =
  'Sorry, you experienced a network error while attempting to post this entry. Please hit the "Go Back" button to return to your message and attempt to post it again.';
export const STOP_SELECTOR_MISSING_STOPS = 'Please select stops for all lines.';
