/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect, useRef } from 'react';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import { useFormContext } from 'react-hook-form';

import { useFeedId } from 'contexts/FeedId';
import { RouteMention, StopMention, TripMention, TImpact } from 'types';
import {
  WEB_MESSAGE_REQUIRED,
  NO_AFFECTED_LINE_SELECTED,
  NO_AFFECTED_ROUTE_BY_AGENCY,
  MISSING_MESSAGE_ALERT_TYPE,
  WEB_HEADLINE_LENGTH_LIMIT,
} from 'constants/error-messages';
import { LEADER_NO_OUTLETS_NAME } from 'constants/alerts';
import { useAutoScreenTargeting } from 'utils/screen-targeting';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import ConnectedRouteRangeStopSelectorGroup, {
  TAffectedStops,
} from 'components/common/connected-route-range-stop-selector-group';
import MessageHeadline, {
  HEADLINE_MAX_CHAR_LENGTH,
} from 'components/common/message-headline';
import { FeatureFlagName, MentionType } from 'generated/global-types';
import { useRoutesByFeedId } from 'contexts/Routes';

import Heading from '../Heading';
import Editor, { getMentionsByType } from '../form-elements/editor';
import ImpactSelector, {
  changeImpactRoutes,
  getAllRoutesFromImpacts,
} from '../compose/impact-selector';
import { AlertFormSectionWrapper } from './styles';
import StatusBanner from '../status-banner';
import { PlainTripsMention } from '../Trip';
import OptionalEditor from '../form-elements/editor/optional-editor';
import { isBus, isRailRoad } from '../../../utils/feed-switches';
import theme from '../../../theme';
import { EditorState } from 'remirror';

const Details = (props: any) => <details {...props} />;

interface AlertMessageLeaderProps {
  additionalMessageName: string;
  messageName: string;
  impactsName: string;
  targetedRoutesName: string;
  targetedStopsName: string;
  affectedStopsName: string;
  affectedStopsEditedName: string;
  hasNoOutlets?: boolean;
  initAdditionalMessageHtml?: string;
  initMessageHtml?: string;
  onAdditionalMessageChange?: (state: any) => void;
  onAdditionalMessageToggle?: (enabled: boolean) => void;
  onMessageCopy?: () => void;
  onMessageChange?: (state: any) => void;
  shouldAutoPopulateTargeting?: boolean;
}

export const AlertMessageLeader: React.FC<AlertMessageLeaderProps> = ({
  initAdditionalMessageHtml,
  initMessageHtml,
  additionalMessageName,
  messageName,
  impactsName,
  affectedStopsEditedName,
  targetedRoutesName,
  targetedStopsName,
  onAdditionalMessageChange,
  onAdditionalMessageToggle,
  affectedStopsName,
  onMessageChange,
  onMessageCopy,
  shouldAutoPopulateTargeting,
  hasNoOutlets,
}) => {
  const shouldShowAffectedStations = useFeatureFlag(
    FeatureFlagName.AFFECTED_STATIONS,
  );
  const [localEditorState, setLocalEditorState] = useState<
    EditorState | undefined
  >();
  const [localAdditionalEditorState, setLocalAdditionalEditorState] = useState<
    EditorState | undefined
  >();

  const feedId = useFeedId();
  const allRoutes = useRoutesByFeedId(feedId);
  const [routesInEditor, setRoutesInEditor] = useState<RouteMention[]>([]);
  const [stopsInEditor, setStopsInEditor] = useState<StopMention[]>([]);
  const [tripsInMainEditor, setTripsInMainEditor] = useState<TripMention[]>([]);
  const [tripsInOptionalEditor, setTripsInOptionalEditor] = useState<
    TripMention[]
  >([]);
  const [areImpactsCustomized, setAreImpactsCustomized] = useState(
    !shouldAutoPopulateTargeting,
  );
  const [editorKeyCount, setEditorKeyCount] = useState(0);
  const [additionalEditorKeyCount, setAdditionalEditorKeyCount] = useState(0);
  const [isAdditionalEditorOpen, setIsAdditionalEditorOpen] = useState(true);
  const [stationPickerIsOpen, setStationPickerIsOpen] = useState(false);

  const onAdditionalMessageToggleRef = useRef(onAdditionalMessageToggle);

  useEffect(() => {
    onAdditionalMessageToggleRef.current = onAdditionalMessageToggle;
  }, [onAdditionalMessageToggle]);

  const { register, setValue, errors, watch, triggerValidation } =
    useFormContext();
  const outletsError = errors[LEADER_NO_OUTLETS_NAME];
  const impacts = watch(impactsName);
  const targetedRoutes = watch(targetedRoutesName);
  const affectedStops = watch(affectedStopsName);
  const textError = errors[messageName];
  const routesError = errors[impactsName];
  const hasTextError = !!textError;
  const hasRoutesOrTextError = !!routesError || hasTextError;
  const hasImpactsRoutesError = !!routesError && areImpactsCustomized;
  const hasImpactsRoutesOrTextError =
    hasRoutesOrTextError && !areImpactsCustomized;
  const hasError =
    hasImpactsRoutesOrTextError || hasImpactsRoutesError || hasTextError;
  const isBusFeed = isBus(feedId);
  const isRailRoadFeed = isRailRoad(feedId);

  useEffect(() => {
    register({ name: affectedStopsEditedName });
  }, [register, affectedStopsEditedName]);

  useEffect(() => {
    register(
      {
        name: messageName,
      },

      {
        validate: (value) => {
          if ((value.doc.textContent.length || 0) > HEADLINE_MAX_CHAR_LENGTH) {
            return WEB_HEADLINE_LENGTH_LIMIT;
          }

          return (
            (!!value && value.doc.textContent.length > 0) ||
            WEB_MESSAGE_REQUIRED
          );
        },
      },
    );
  }, [messageName, register, isBusFeed]);

  useEffect(() => {
    register({
      name: additionalMessageName,
    });
  }, [additionalMessageName, register]);

  useEffect(() => {
    register(
      {
        name: impactsName,
      },
      {
        validate: (value) => {
          const { hasMessageTypes, hasRoutes } = value.reduce(
            (
              acc: { hasMessageTypes: boolean; hasRoutes: boolean },
              impact: TImpact,
            ) => ({
              hasMessageTypes: acc.hasMessageTypes && !!impact.messageType,
              hasRoutes: acc.hasRoutes && impact.routes.length !== 0,
            }),
            { hasMessageTypes: true, hasRoutes: true },
          );

          let message = areImpactsCustomized
            ? NO_AFFECTED_LINE_SELECTED
            : NO_AFFECTED_ROUTE_BY_AGENCY[feedId];
          if (hasRoutes && !hasMessageTypes) {
            message = MISSING_MESSAGE_ALERT_TYPE;
          }
          return (hasRoutes && hasMessageTypes) || message;
        },
      },
    );
  }, [impactsName, register, feedId, areImpactsCustomized]);

  useEffect(() => {
    register(
      { name: LEADER_NO_OUTLETS_NAME },
      {
        validate: () =>
          !hasNoOutlets ||
          'Please select at least one location to post this alert to.',
      },
    );
    triggerValidation({ name: LEADER_NO_OUTLETS_NAME });
  }, [hasNoOutlets, register, triggerValidation]);

  useEffect(() => {
    register({ name: targetedRoutesName });
  }, [targetedRoutesName, register]);

  useEffect(() => {
    register({ name: targetedStopsName });
  }, [targetedStopsName, register]);

  useEffect(() => {
    if (shouldShowAffectedStations) {
      register({ name: affectedStopsName });
    }
  }, [shouldShowAffectedStations, affectedStopsName, register]);

  useEffect(() => {
    if (initMessageHtml) {
      setEditorKeyCount((count) => count + 1);
      setLocalEditorState(undefined);
      setValue(messageName, undefined);
    }
  }, [
    initMessageHtml,
    setEditorKeyCount,
    setLocalEditorState,
    messageName,
    setValue,
  ]);

  useEffect(() => {
    if (initAdditionalMessageHtml) {
      setIsAdditionalEditorOpen(true);
      setAdditionalEditorKeyCount((count) => count + 1);
      setLocalAdditionalEditorState(undefined);
      setValue(additionalMessageName, undefined);

      if (onAdditionalMessageToggleRef.current) {
        onAdditionalMessageToggleRef.current(true);
      }
    }
  }, [
    initAdditionalMessageHtml,
    setAdditionalEditorKeyCount,
    setLocalEditorState,
    additionalMessageName,
    setValue,
  ]);
  const handleAffectedStopsChange = React.useCallback(
    (newAffectedStops: TAffectedStops) => {
      setValue(affectedStopsName, newAffectedStops);
    },
    [setValue, affectedStopsName],
  );

  useAutoScreenTargeting(
    {
      affectedStops,
      impacts,
    },
    shouldAutoPopulateTargeting,
  );

  const handleAffectedStopsTouched = React.useCallback(() => {
    setValue(affectedStopsEditedName, true);
  }, [setValue, affectedStopsEditedName]);

  const relatedGtfsIds = _uniq(
    [...routesInEditor, ...stopsInEditor].map((mention) => mention.id),
  );

  const updateWebTargetedRoutes = (
    impacts: TImpact[],
    otherRoutes: RouteMention[],
  ) => {
    const allImpactRoutes = getAllRoutesFromImpacts(impacts);
    const combinedRoutes = _uniqBy(
      [...allImpactRoutes, ...otherRoutes],
      'routeId',
    );
    setValue(targetedRoutesName, combinedRoutes);
  };

  const stationsPickerIsDisabled = targetedRoutes.length === 0;

  useEffect(() => {
    if (stationsPickerIsDisabled && stationPickerIsOpen) {
      setStationPickerIsOpen(false);
    }
  }, [stationsPickerIsDisabled, stationPickerIsOpen]);

  return (
    <AlertFormSectionWrapper
      css={css`
        padding-top: 20px;
      `}
    >
      <StatusBanner
        hasIcon
        status="error"
        text={outletsError?.message || '\u00a0'}
        isVisible={!!outletsError?.message}
        css={css`
          margin-bottom: 20px;
        `}
      />
      <Heading
        level={1}
        size="xlarge"
        css={css`
          margin-bottom: 16px;
        `}
      >
        Message
      </Heading>
      <MessageHeadline
        onMessageCopy={onMessageCopy}
        currentNumberOfChars={localEditorState?.doc?.textContent?.length || 0}
      />
      <Editor
        shouldFormatOnPaste
        key={`main-${editorKeyCount}`}
        id={messageName}
        initialContent={initMessageHtml}
        relatedGtfsIds={relatedGtfsIds}
        value={localEditorState}
        onStateChange={({ state }) => {
          setLocalEditorState(state);
        }}
        onChange={({ state }) => {
          // this is suspect.
          setLocalEditorState(state);
          const mentions = getMentionsByType(state);
          // eslint-disable-next-line prefer-const
          let { stops, trips, routes } = mentions;

          setStopsInEditor(stops);

          if (isRailRoad(feedId)) {
            // if a user mentions a LIRR train/trip,
            // add the related route to the dropdown of affected `routes`
            const routesDerivedFromTrips: RouteMention[] = trips.map((trip) => {
              const id = trip.relatedRouteIds ? trip.relatedRouteIds[0] : '';
              const route = allRoutes.find((route) => route.gtfsId === id);
              return {
                relatedRouteIds: trip.relatedRouteIds,
                id,
                routeId: id,
                isAffected: true,
                meta: null,
                type: MentionType.ROUTE,
                name: (route?.shortName ?? route?.longName) || '',
                boroughs: trip.boroughs,
              };
            });
            // prevents adding the route twice if there are 2 or more trips sharing the same route.
            routes = _uniqBy(routes.concat(routesDerivedFromTrips), 'routeId');
          }
          setRoutesInEditor(routes);

          if (isRailRoadFeed) {
            setTripsInMainEditor(trips);
          }

          updateWebTargetedRoutes(impacts, routes);
          if (impacts.length === 1 && !areImpactsCustomized) {
            setValue(
              impactsName,
              changeImpactRoutes(impacts, routes, false, 0),
              hasRoutesOrTextError,
            );
          }

          setValue(targetedStopsName, stops);
          setValue(messageName, state, hasTextError);

          if (onMessageChange) {
            onMessageChange(state);
          }
        }}
      />
      <PlainTripsMention trips={tripsInMainEditor} />
      <div
        css={css`
          margin-top: 12px;
        `}
      />
      <OptionalEditor
        showDetailMenu
        isOpen={isAdditionalEditorOpen}
        id={additionalMessageName}
        key={`add-${additionalEditorKeyCount}`}
        initialContent={
          isAdditionalEditorOpen ? initAdditionalMessageHtml : undefined
        }
        labelText="Details"
        relatedGtfsIds={relatedGtfsIds}
        value={localAdditionalEditorState}
        onStateChange={({ state }) => {
          setLocalAdditionalEditorState(state);
        }}
        onChange={({ state }) => {
          const { trips } = getMentionsByType(state);

          if (isRailRoadFeed) {
            setTripsInOptionalEditor(trips);
          }

          setValue(additionalMessageName, state);

          if (onAdditionalMessageChange) {
            onAdditionalMessageChange(state);
          }
        }}
        onToggle={(enabled) => {
          if (!enabled) {
            setLocalAdditionalEditorState(undefined);
            setValue(additionalMessageName, undefined);
            setAdditionalEditorKeyCount((count) => count + 1);
          }

          setIsAdditionalEditorOpen(enabled);

          if (onAdditionalMessageToggle) {
            onAdditionalMessageToggle(enabled);
          }
        }}
      />
      {isAdditionalEditorOpen && (
        <PlainTripsMention trips={tripsInOptionalEditor} />
      )}
      <StatusBanner
        hasIcon
        status="error"
        text={textError?.message || routesError?.message || '\u00a0'}
        isVisible={hasError}
        css={css`
          margin-top: 16px;
        `}
      />
      <div
        css={css`
          margin-top: 8px;
        `}
      />
      <ImpactSelector
        id={impactsName}
        onChangeTargetedRoutes={() => {
          setAreImpactsCustomized(true);
        }}
        onChange={(newImpacts) => {
          setValue(impactsName, newImpacts, true);
          updateWebTargetedRoutes(newImpacts, routesInEditor);
        }}
        value={impacts}
      />
      {shouldShowAffectedStations && (
        <Details
          css={css`
            ${stationsPickerIsDisabled ? `color: ${theme.colors.dark3}` : ''}
          `}
          open={stationPickerIsOpen}
        >
          <summary
            onClick={(event: any) => {
              // See issue: https://github.com/facebook/react/issues/15486
              event.preventDefault();

              if (stationsPickerIsDisabled) {
                return;
              }

              handleAffectedStopsTouched();
              setStationPickerIsOpen(!stationPickerIsOpen);
            }}
            css={css`
              outline: none;
              border-radius: 4px;
              &:focus-visible {
                box-shadow: 0 0 0 1px ${theme.colors.accent3};
              }
            `}
          >
            Edit Stations Affected
          </summary>
          <ConnectedRouteRangeStopSelectorGroup
            gtfsRouteIds={getAllRoutesFromImpacts(impacts).map(
              ({ routeId }: any) => routeId,
            )}
            value={
              affectedStops ?? {
                directionality: null,
                gtfsStopIds: [],
              }
            }
            onChange={handleAffectedStopsChange}
          />
        </Details>
      )}
    </AlertFormSectionWrapper>
  );
};
