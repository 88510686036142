import { css } from '@emotion/react';

import theme from 'theme';

export const radioWrapper = css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  border-radius: 4px;
  position: relative;

  &:focus-within {
    ::after {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      border-radius: 4px;
      pointer-events: none;
      border: 1px solid ${theme.colors.accent3};
    }
  }

  & > label {
    display: inline-block;
    cursor: pointer;
    margin-left: 8px;
  }

  & > input {
    outline: none;
  }
`;

export const flexItem = css`
  flex: 0 0 9rem;
  margin-right: 1rem;
`;

export const buttonIcon = css`
  &:enabled {
    cursor: pointer;
    svg g {
      stroke: ${theme.colors.accent1};
    }
  }

  &:enabled:hover {
    svg g {
      stroke: ${theme.colors.brand};
    }
  }
`;

export const filledButtonIcon = css`
  &:enabled {
    cursor: pointer;
    svg g {
      fill: ${theme.colors.accent1};
    }
  }

  &:enabled:hover {
    svg g {
      fill: ${theme.colors.brand};
    }
  }
`;

export const buttonAddRow = css`
  &:hover svg path {
    fill: ${theme.colors.brand};
    stroke: ${theme.colors.brand};
  }

  &:disabled svg path {
    fill: #a4a7ae;
    stroke: #a4a7ae;
  }
`;

export const parentInputHighlight = css`
  input[type='text'],
  [data-reach-listbox-button] {
    background-color: ${theme.colors['status-warning']};
    border: 1px solid #b79d45;
    color: #7a5418;
  }
`;

export const durationsPickerInput = css`
  border: 1px solid ${theme.colors['border-dark']};
  box-shadow: inset 0 0 4px ${theme.colors['border-dark']};

  &:focus {
    box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
  }
`;

export const checkBox = css`
  border: 1px solid ${theme.colors.accent1};
`;

export const dayOfWeekListboxButton = css`
  ${durationsPickerInput};
  &[data-reach-listbox-button][aria-expanded='true'] {
    box-shadow: 0 0 0 1px ${theme.colors['border-dark']} inset;
    border-bottom: none;
  }
`;
