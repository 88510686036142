/** @jsxImportSource @emotion/react */

import { format } from 'date-fns';
import React, { useEffect } from 'react';

import { FeedId } from '../../../types/feeds';

import { flexRow } from '../styles';
import * as S from './TwitterPreview.styled';

import {
  TWITTER_USERNAMES,
  TWITTER_DISPLAY_NAMES,
  PROTECTED_TWEETS,
} from '../../../constants/twitter';

type Props = {
  body: string;
  feedId: FeedId;
  twitterId?: string | null;
  publishedAt: Date;
  isDraft?: boolean;
};

const LiveTweetEmbed: React.FC<Props> = ({
  body,
  feedId,
  twitterId,
  publishedAt,
}) => {
  // Run this once on mount so that Twitter's widget renderer detects our element
  useEffect(() => {
    // @ts-ignore
    if (typeof window?.twttr?.widgets?.load === 'function') {
      // @ts-ignore
      window.twttr.widgets.load();
    }
  }, []);

  return (
    <S.LiveTweetEmbedContainer>
      <blockquote className="twitter-tweet" data-conversation="none">
        <p lang="en" dir="ltr">
          {body}
        </p>
        &mdash; {TWITTER_DISPLAY_NAMES[feedId]} (@{TWITTER_USERNAMES[feedId]}){' '}
        <a
          href={`https://twitter.com/${TWITTER_USERNAMES[feedId]}/status/${twitterId}`}
        >
          {format(new Date(publishedAt), 'MMMM do, yyyy')}
        </a>
      </blockquote>
    </S.LiveTweetEmbedContainer>
  );
};

const SimulatedTweetEmbed: React.FC<Props> = ({
  feedId,
  twitterId,
  body,
  isDraft,
}) => (
  <S.TweetLink
    href={
      !isDraft
        ? `https://twitter.com/${TWITTER_USERNAMES[feedId]}/status/${twitterId}`
        : undefined
    }
    target="_blank"
    rel="noreferrer noopener"
  >
    <div css={flexRow}>
      <S.Avatar />
      <S.TweetHeading.Container>
        <S.TweetHeading.DisplayName>
          {TWITTER_DISPLAY_NAMES[feedId]}
        </S.TweetHeading.DisplayName>
        <S.TweetHeading.UserName>{`@${TWITTER_USERNAMES[feedId]}`}</S.TweetHeading.UserName>
      </S.TweetHeading.Container>
      {isDraft && <S.NotPosted />}
    </div>
    <S.TweetContent>
      {isDraft ? (
        <React.Fragment>{body}</React.Fragment>
      ) : (
        <React.Fragment>
          <strong>Protected tweet:</strong>
          <br />
          {`https://twitter.com/${TWITTER_USERNAMES[feedId]}/status/${twitterId}`}
        </React.Fragment>
      )}
    </S.TweetContent>
  </S.TweetLink>
);

const TwitterPreview = PROTECTED_TWEETS ? SimulatedTweetEmbed : LiveTweetEmbed;

export default TwitterPreview;
