/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import theme from 'theme';
import Button from './Button';

const TargetingSelectionButton: React.FC<
  {
    onClick: () => void;
    label: string;
  } & { children?: React.ReactNode }
> = ({ onClick, label }) => (
  <Button
    primary
    onClick={() => onClick()}
    type="button"
    additionalStyles={css`
      text-transform: unset;
      ${theme.typography.weights.bold}
    `}
  >
    {label}
  </Button>
);

export default TargetingSelectionButton;
