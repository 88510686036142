/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';

const screenIconWidth = '11.25px';

const iconContainerCss = css`
  display: flex;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
`;

const filledScreenIconCss = css`
  height: 20px;
  width: ${screenIconWidth};
  background: #aaa;
  border-radius: 2px;
`;

const filledLandscapeScreenIconCss = css`
  height: ${screenIconWidth};
  width: 20px;
  background: #aaa;
  border-radius: 2px;
`;

const emptyScreenIconCss = css`
  height: 20px;
  width: ${screenIconWidth};
  border: 1px solid #aaa;
  border-radius: 2px;
`;

const middleScreenIconCss = css`
  margin: 0 2px;
`;

export const TripleScreenIcon: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className={className} css={[iconContainerCss]}>
      <div css={filledScreenIconCss} />
      <div css={[filledScreenIconCss, middleScreenIconCss]} />
      <div css={filledScreenIconCss} />
    </div>
  );
};

export const SingleScreenIcon: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div
      className={className}
      css={[
        iconContainerCss,
        css`
          width: calc(2 * ${screenIconWidth});
        `,
      ]}
    >
      <div css={filledScreenIconCss} />
    </div>
  );
};

export const SingleLandscapeScreenIcon: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div
      className={className}
      css={[
        iconContainerCss,
        css`
          width: calc(2 * ${screenIconWidth});
        `,
      ]}
    >
      <div css={filledLandscapeScreenIconCss} />
    </div>
  );
};

export const LeftScreenIcon: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className={className} css={iconContainerCss}>
      <div
        css={[
          filledScreenIconCss,
          css`
            margin-right: 2px;
          `,
        ]}
      />
      <div css={emptyScreenIconCss} />
    </div>
  );
};

export const RightScreenIcon: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className={className} css={iconContainerCss}>
      <div
        css={[
          emptyScreenIconCss,
          css`
            margin-right: 2px;
          `,
        ]}
      />
      <div css={filledScreenIconCss} />
    </div>
  );
};
