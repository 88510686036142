import { FeedId } from '../types/feeds';

export const isTrain = (feedId: FeedId): boolean =>
  feedId !== FeedId.NYCTBus && feedId !== FeedId.BT;
export const getVehicleName = (feedId: FeedId): string =>
  isTrain(feedId) ? 'Train' : 'Bus';
export const getStopType = (feedId: FeedId): string =>
  isTrain(feedId) ? 'Station' : 'Stop';
export const getRouteType = (feedId: FeedId): string =>
  isTrain(feedId) || feedId === FeedId.BT ? 'Line(s)' : 'Bus Route';
export const isOutfront = (feedId: FeedId): boolean =>
  feedId === FeedId.OutfrontQA || feedId === FeedId.OutfrontDEV;
export const isSubway = (feedId: FeedId): boolean =>
  feedId === FeedId.NYCTSubway || isOutfront(feedId);
export const isBus = (feedId: FeedId): boolean => feedId === FeedId.NYCTBus;
export const isNyct = (feedId: FeedId): boolean =>
  feedId === FeedId.NYCTSubway ||
  feedId === FeedId.NYCTBus ||
  isOutfront(feedId);
export const isRailRoad = (feedId: FeedId): boolean =>
  feedId === FeedId.LIRR || feedId === FeedId.MNR;
export const isBridgeTunnel = (feedId: FeedId): boolean => feedId === FeedId.BT;
