/** @jsxImportSource @emotion/react */

import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import { css } from '@emotion/react';

import AppLink, { IAppLinkProps } from '../../common/app-link';
import { ThemeType } from '../../../theme';

export const UserInfo: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      align-items: center;
      border-top: 1px solid #364663;
      color: ${theme.colors.white};
      font-family: ${theme.typography.families.primary};
      display: flex;
      flex-direction: row;
      width: 240px;
      height: 52px;
      padding: ${theme.spacing.small};
    `}
    {...props}
  />
);

export const UserName: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      text-transform: uppercase;
      color: ${theme.colors.light1};
      font-weight: ${theme.typography.weights.bold};
      margin: 0;
      opacity: 0.7;
      flex: 1 1 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
    {...props}
  />
);

const userLinkStyle = (theme: ThemeType) => css`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
  overflow: hidden;
  margin-left: ${theme.spacing.xxsmall};
`;

export const UserLink: React.FC<IAppLinkProps> = (props) => (
  <AppLink css={userLinkStyle} {...props} />
);

export const UserButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = (props) => (
  <button type="button" css={userLinkStyle} {...props} />
);
