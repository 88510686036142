/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactComponent as RemoveIcon } from 'images/cross-blue.svg';
import { ReactComponent as UnlinkIcon } from 'images/unlink.svg';
import RemainingCharacters from '../../form-elements/RemainingCharacters';

const Label: React.FC<
  {
    id: string;
    length: number;
    maxLength: number;
    onDismiss?: () => void;
    unThread?: () => void;
  } & { children?: React.ReactNode }
> = ({ id, length, maxLength, onDismiss, unThread }) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    `}
  >
    <label
      htmlFor={id}
      css={css`
        ::after {
          content: var(--twitter-thread-counter);
        }
      `}
    >
      Tweet
    </label>
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      <RemainingCharacters
        maxLength={{ max: maxLength, current: length }}
        htmlFor={id}
      />
      {onDismiss && (
        <button
          type="button"
          onClick={onDismiss}
          aria-label="Remove Tweet from Thread"
          css={css`
            padding: 0;
            margin: 0 0 0 12px;
            height: 100%;
          `}
        >
          <RemoveIcon
            css={css`
              width: 10px;
              height: 10px;
            `}
          />
        </button>
      )}
      {unThread && (
        <button
          type="button"
          onClick={unThread}
          aria-label="Remove Tweet from Thread"
          css={css`
            padding: 0;
            margin: 0 0 0 12px;
            height: 100%;
            display: flex;
            align-items: center;
          `}
        >
          <UnlinkIcon
            css={css`
              width: 16px;
              height: 16px;
            `}
          />
        </button>
      )}
    </div>
  </div>
);

export default Label;
