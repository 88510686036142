import { GtfsEntitySelectorInput } from '../generated/global-types';

type RouteEntity = Omit<GtfsEntitySelectorInput, 'routeId'> & {
  routeId: string;
};

function isRouteEntity(
  entity: RouteEntity | GtfsEntitySelectorInput,
): entity is RouteEntity {
  return (
    (entity as RouteEntity).routeId !== null &&
    (entity as RouteEntity).routeId !== undefined
  );
}

const getRouteEntities = (
  entitySelectors: GtfsEntitySelectorInput[],
): RouteEntity[] => entitySelectors.filter(isRouteEntity);

export default getRouteEntities;
