/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useDebounce } from 'use-debounce';
import { loader } from 'graphql.macro';
import pluralize from 'pluralize';
import { useFeedId } from 'contexts/FeedId';
import Modal from 'components/common/modal';
import { useQuery } from '@apollo/client';
import SearhInput from 'components/common/form-elements/search-input';
import { Folders, FoldersVariables } from 'generated/Folders';
import {
  SearchableSocialMediaUploads,
  SearchableSocialMediaUploadsVariables,
  SearchableSocialMediaUploads_searchSocialMediaUploads_SearchSocialMediaUploadsResult_results_Upload as SocialMediaUploadType,
} from 'generated/SearchableSocialMediaUploads';
import FoldersView from 'components/common/social-media-assets/Folders';
import AssetsView from 'components/common/social-media-assets/Assets';
import NoMessages from 'components/common/NoMessages';
import {
  WEB_NO_ASSETS_HEADING,
  WEB_NO_ASSETS_HEADING_FILTERS,
  WEB_NO_ASSETS_MESSAGE_FILTERS,
  WEB_NO_ASSETS_OR_FOLDERS_HEADING,
  WEB_NO_ASSETS_OR_FOLDERS_MESSAGE,
  WEB_NO_ASSETS_MESSAGE_IN_FOLDER,
} from 'constants/empty-states';
import Pagination from 'components/common/Pagination';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { UploadType } from 'hooks/useUpload';
import { AttachmentsType } from 'types/twitter-attachments';
import { ThemeType } from 'theme';
import { ReactComponent as IconArrowBack } from '../../../../images/icon_arrow_back.svg';

const FoldersQuery = loader('../../../../graphql/Folders.gql');

const SearchableSocialMediaUploadsQuery = loader(
  '../../../../graphql/SearchableSocialMediaUploads.gql',
);

const PAGE_SIZE = 15;

const getNoAssetsMessages = (
  areUploadsAvailable: boolean,
  areFoldersAvailable: boolean,
  showFolderResults: boolean,
  showSearchResults: boolean,
): { heading: string; message: string } => {
  let heading = '';
  let message = '';

  if (
    !areUploadsAvailable &&
    !areFoldersAvailable &&
    !showFolderResults &&
    !showSearchResults
  ) {
    heading = WEB_NO_ASSETS_OR_FOLDERS_HEADING;
    message = WEB_NO_ASSETS_OR_FOLDERS_MESSAGE;
  } else if (!areUploadsAvailable) {
    if (showSearchResults && !showFolderResults) {
      heading = WEB_NO_ASSETS_HEADING_FILTERS;
      message = WEB_NO_ASSETS_MESSAGE_FILTERS;
    } else {
      heading = WEB_NO_ASSETS_HEADING;
      message = WEB_NO_ASSETS_MESSAGE_IN_FOLDER;
    }
  }

  return {
    heading,
    message,
  };
};

const BackHeading: React.FC<{
  title: string;
  onClick: () => void;
  children?: React.ReactNode;
}> = ({ title, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    css={css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: -12px;
      width: fit-content;
    `}
  >
    <span
      css={({ typography, colors }: ThemeType) => css`
        ${typography.sizes.xsmall};
        color: ${colors.accent1};
        display: flex;
        align-items: center;
        text-transform: uppercase;

        svg {
          fill: ${colors.accent1};
        }

        &:hover {
          color: ${colors.brand};
          svg {
            fill: ${colors.brand};
          }
        }

        border-radius: 4px;

        &:focus-visible {
          box-shadow: 0 0 0 1px ${colors.accent3};
          outline: none;
        }
      `}
    >
      <span
        css={({ typography, spacing }: ThemeType) => css`
          align-items: center;
          display: flex;
          font-size: ${typography.sizes.large.fontSize};
          line-height: 26px;
          margin-right: ${spacing.xxsmall};
        `}
      >
        <IconArrowBack width={10} height={10} />
      </span>
      <span
        css={({ typography }: ThemeType) => css`
          display: inline-block;
          font-weight: ${typography.weights.bolder};
          line-height: 26px;
        `}
      >
        {title}
      </span>
    </span>
  </button>
);

const AttachAssetFromLibrary: React.FC<
  {
    isOpen: boolean;
    attachments: AttachmentsType;
    onDismiss: () => void;
    onAttachAssets: (uploads: AttachmentsType) => void;
  } & { children?: React.ReactNode }
> = ({ isOpen, onDismiss, attachments, onAttachAssets }) => {
  const feedId = useFeedId();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [searchValue] = useDebounce(search, 500);
  const offset = (page - 1) * PAGE_SIZE;

  const [selectedFolder, setSelectedFolder] = useState<{
    id: string;
    name: string;
  }>();

  const [selectedAssets, setSelectedAssets] = useState<UploadType[]>([]);

  const showSearchResults = !!searchValue;
  const showFolderResults = !!selectedFolder;

  const {
    loading: areFoldersLoading,
    data: foldersData,
    error: foldersError,
  } = useQuery<Folders, FoldersVariables>(FoldersQuery, {
    variables: { feedId },
    fetchPolicy: 'cache-and-network',
    skip: !isOpen || showFolderResults || showSearchResults,
  });

  const {
    loading: areUploadsLoading,
    data: uploadsData,
    error: uploadsError,
  } = useQuery<
    SearchableSocialMediaUploads,
    SearchableSocialMediaUploadsVariables
  >(SearchableSocialMediaUploadsQuery, {
    variables: {
      offset,
      perPage: PAGE_SIZE,
      matches: searchValue || null,
      folderId: selectedFolder?.id || null,
      feedId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });

  const loading = areFoldersLoading || areUploadsLoading;
  const error = foldersError || uploadsError;

  const folders = foldersData?.socialMediaFolders?.nodes ?? [];
  const uploads = uploadsData?.searchSocialMediaUploads?.results ?? [];

  const totalUploadsCount =
    uploadsData?.searchSocialMediaUploads?.totalCount ?? 0;

  const areFoldersAvailable = folders.length > 0;
  const areUploadsAvailable = uploads.length > 0;

  const clearFilters = () => {
    setSearch('');
    setSelectedFolder(undefined);
    setPage(1);
  };

  const isAssetSelected = (upload: UploadType) =>
    !!selectedAssets.find((asset) => asset.id === upload.id);

  const handleSelectAsset = (upload: UploadType) => {
    if (isAssetSelected(upload)) {
      setSelectedAssets(
        selectedAssets.filter((asset) => asset.id !== upload.id),
      );
      return;
    }
    if (selectedAssets.length + attachments.length >= 4) {
      return;
    }
    setSelectedAssets([...selectedAssets, upload]);
  };

  const handleAttachAssets = () => {
    onDismiss();
    onAttachAssets([...attachments, ...selectedAssets]);
    setSelectedAssets([]);
    clearFilters();
  };

  const { heading: noAssetsHeading, message: noAssetssMessage } =
    getNoAssetsMessages(
      areUploadsAvailable,
      areFoldersAvailable,
      showFolderResults,
      showSearchResults,
    );

  return (
    <Modal
      title="Attach From Library"
      onDismiss={() => {
        onDismiss();
        setSelectedAssets([]);
        clearFilters();
      }}
      isOpen={isOpen}
      maxWidth="1120px"
      minWidth="1120px"
    >
      {error && <div>{error.toString()}</div>}
      {showFolderResults ? (
        <BackHeading title="Back" onClick={clearFilters} />
      ) : (
        <form>
          <SearhInput
            type="text"
            placeholder="Search"
            value={search}
            name="query"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (page !== 1) {
                setPage(1);
              }
              setSearch(e.target.value);
            }}
            onClear={clearFilters}
            ignoreLocation
          />
        </form>
      )}
      {loading ? (
        <div
          css={(theme) => css`
            padding: ${theme.spacing.xxlarge};
          `}
        >
          <Loader loading={loading} />
        </div>
      ) : (
        <React.Fragment>
          <FoldersView
            folders={folders}
            onFolderClick={(folder) => {
              setPage(1);
              setSelectedFolder({
                id: folder.id,
                name: folder.name,
              });
            }}
          />
          <AssetsView
            title={(() => {
              if (showSearchResults && areUploadsAvailable) {
                return <h3>Search Results</h3>;
              }
              if (showFolderResults) {
                return <h3>{selectedFolder?.name}</h3>;
              }
              if (!areUploadsAvailable) {
                return null;
              }
              return <h3>Assets</h3>;
            })()}
            assets={uploads}
            onAssetClick={(asset) => handleSelectAsset(asset)}
            thumbnailWidth="196px"
            thumbnailHeight="111px"
            applyThumbnailStyles={(asset) => css`
              margin: 0 5px 9px 0;
              cursor: pointer;
              text-align: left;
              border: 2px solid
                ${isAssetSelected(asset as SocialMediaUploadType)
                  ? '#0f61a9'
                  : '#fff'};
              padding: 3px;
              width: 206px;
              &:focus {
                outline: none;
                box-shadow: none;
              }
            `}
            showFolderName={!showFolderResults}
          />
          {areUploadsAvailable && (
            <React.Fragment>
              <Pagination
                currentPage={page}
                itemsPerPage={PAGE_SIZE}
                totalItems={totalUploadsCount}
                onPrevClick={() => setPage(page - 1)}
                onNextClick={() => setPage(page + 1)}
              />
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 15px;
                `}
              >
                <Button
                  size="mediumLong"
                  primary
                  disabled={!selectedAssets.length}
                  onClick={handleAttachAssets}
                  title={`Attach ${pluralize(
                    'asset',
                    selectedAssets.length,
                    true,
                  )}`}
                >
                  Attach
                </Button>
              </div>
            </React.Fragment>
          )}
          {!areUploadsAvailable && !areFoldersAvailable && (
            <NoMessages
              css={css`
                margin-top: 45px;
              `}
              icon={showSearchResults}
              heading={noAssetsHeading}
              message={noAssetssMessage}
            />
          )}
        </React.Fragment>
      )}
    </Modal>
  );
};

export default AttachAssetFromLibrary;
