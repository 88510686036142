import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  FeedSettingsByFeed,
  FeedSettingsByFeedVariables,
  FeedSettingsByFeed_feedSetting_FeedSetting as FeedSettings,
} from '../generated/FeedSettingsByFeed';
import { useFeedId } from './FeedId';

const FeedSettingsByFeedQuery = loader('../graphql/FeedSettingsByFeed.gql');
const feedSettings: FeedSettings = {
  __typename: 'FeedSetting',
  feedId: '',
};
const FeedSettingsContext = React.createContext<FeedSettings>(feedSettings);

interface Props {
  children?: React.ReactNode;
}

export const FeedSettingsProvider: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ children }: Props) => {
  const feedId = useFeedId();
  const { data } = useQuery<FeedSettingsByFeed, FeedSettingsByFeedVariables>(
    FeedSettingsByFeedQuery,
    {
      skip: feedId === undefined,
      variables: {
        feedId,
      },
    },
  );

  const setting = (data && data.feedSetting) || feedSettings;

  return (
    <FeedSettingsContext.Provider value={setting}>
      {children}
    </FeedSettingsContext.Provider>
  );
};

export const useFeedSettings = () => useContext(FeedSettingsContext);
