/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { ThemeType } from '../theme';

const ProgressBar: React.FC<{
  max?: number;
  progress: number;
  className?: string;
  additionalStyles?: SerializedStyles;
  children?: React.ReactNode;
}> = ({ max = 100, progress, className, additionalStyles, ...props }) => (
  <progress
    className={className}
    max={max}
    value={progress}
    css={(theme: ThemeType) => css`
      -moz-appearance: none;
      -webkit-appearance: none;
      margin-left: 8px;
      appearance: none;
      border-radius: 2px;
      border: none;
      height: 4px;
      background-color: ${theme.colors.light9};

      ::-webkit-progress-bar {
        background: ${theme.colors.light9};
        border-radius: 2px;
      }

      ::-webkit-progress-value {
        background: ${theme.colors.accent1};
        border-radius: 2px;
      }

      ::-moz-progress-bar {
        background: ${theme.colors.accent1};
        border-radius: 2px;
      }

      ${additionalStyles}
    `}
    {...props}
  >
    {progress}%
  </progress>
);

export default ProgressBar;
