/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import Heading, { HeadingProps } from './Heading';
import Bullet, { BulletSize } from './Bullet';
import { useFeedId } from '../../contexts/FeedId';
import { useRoutesByFeedId } from '../../contexts/Routes';
import { isSubway } from '../../utils/feed-switches';
import AllStationsTag from './all-stations-tag';
import TripMention from './Trip';

const MessageHeading: React.FC<
  {
    children?: React.ReactNode;
    routes: string[];
    messageType: string;
    trips?: string[];
    color?: string;
    size?: HeadingProps['size'];
    eyebrowText?: string;
    title?: string;
    routesCss?: SerializedStyles;
  } & { children?: React.ReactNode }
> = ({
  color = 'black',
  routes = [],
  messageType,
  trips = [],
  size = 'xxlarge',
  children = null,
  eyebrowText,
  title,
  routesCss,
}) => {
  const feedId = useFeedId();
  const isSub = isSubway(feedId);
  const allRoutes = useRoutesByFeedId(feedId);
  const allRoutesSelected = allRoutes.every(({ gtfsId }) =>
    routes.includes(gtfsId),
  );

  const mapToBullet = (route: string): any => (
    <Bullet
      size={BulletSize.xlarge}
      key={route}
      routeId={route}
      style={{
        marginRight: '3px',
        marginBottom: '1.5px',
        borderRadius: '2px',
      }}
    />
  );

  const messageTypeEl = (
    <div
      css={css`
        margin-left: ${isSub ? '8px' : '0px'};
        margin-right: ${isSub ? '8px' : '0px'};
      `}
      title={title}
    >
      {messageType}
    </div>
  );

  const routesEl = (
    <div
      css={[
        css`
          display: flex;
          flex-wrap: wrap;
          max-width: 375px;
          margin-left: ${isSub ? '10px' : '0px'};
          margin-top: ${isSub ? '1.5px' : '8px'};
        `,
        routesCss,
      ]}
    >
      {allRoutesSelected ? <AllStationsTag /> : routes.map(mapToBullet)}
    </div>
  );

  const tripsEl = !!trips.length && (
    <div
      css={css`
        margin-top: 2px;
        display: flex;
        flex-wrap: wrap;
        max-width: 375px;
      `}
    >
      {trips.map((tripName, idx) => {
        const tripMentionKey = `trip-${tripName}-${idx}`;
        return <TripMention key={tripMentionKey} tripName={tripName} />;
      })}
    </div>
  );

  return (
    <Heading
      css={css`
        margin-right: 24px;
      `}
      color={color}
      level={2}
      size={size}
    >
      {eyebrowText && (
        <div
          css={(theme) => css`
            ${theme.typography.sizes.xsmall};
            text-transform: uppercase;
            margin-bottom: 3px;
          `}
        >
          {eyebrowText}
        </div>
      )}
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {isSub && routesEl}
        {messageTypeEl}
        {children}
      </div>
      <div>{!isSub && routesEl}</div>
      <div>{tripsEl}</div>
    </Heading>
  );
};

export default MessageHeading;
