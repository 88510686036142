/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import PublishItem from 'components/pages/alert-view/PublishItem';
import getRouteEntities from 'utils/get-route-entities';
import EditableInternalNotes from 'components/pages/planned-work/editable-internal-notes';
import {
  GetPlannedWorkById_plannedWork_PlannedWork as GetPlannedWorkById_plannedWork,
  GetPlannedWorkById_plannedWork_PlannedWork_stationAlternatives_StationAlternative as GetPlannedWorkById_plannedWork_stationAlternatives,
  GetPlannedWorkById_plannedWork_PlannedWork_plannedWorkScreenMessages_PlannedWorkScreenMessagesConnection_nodes_PlannedWorkScreenMessage as GetPlannedWorkById_plannedWork_plannedWorkScreenMessages_nodes,
  GetPlannedWorkById_plannedWork_PlannedWork_homepagePublishOffset_Interval as GetPlannedWorkById_plannedWork_homepagePublishOffset,
  GetPlannedWorkById_plannedWork_PlannedWork_durations_DatetimeRange as GetPlannedWorkById_plannedWork_durations,
} from 'generated/GetPlannedWorkById';
import {
  humanReadableDurations,
  DurationsType,
  PotentiallyUnboundedDuration,
  MESSAGE_TYPE_DESCRIPTIONS,
  DatetimeRangeInput,
  FeedId,
} from '@mta-live-media-manager/shared';
import useNamedStops from 'hooks/useNamedStops';
import HtmlPreview from 'ui-kit/html-preview/html-preview';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import {
  RECLEAR_SCREENS_FAILED_TOAST_MESSAGE,
  RECLEAR_SCREENS_TOAST_MESSAGE,
} from 'constants/generic-messages';
import { Toast, ToastLevel, useToast } from 'ui-kit/toast';
import {
  ReclearPlannedWorkScreens,
  ReclearPlannedWorkScreensVariables,
} from 'generated/ReclearPlannedWorkScreens';

import PlannedWorkActions from 'components/common/planned-work/PlannedWorkActions';
import { INDEFINITE_END_DATE_MESSAGE } from 'constants/empty-states';

import { isBridgeTunnel } from 'utils/feed-switches';
import Collapsable from '../Collapsable';
import { ThemeType } from '../../../theme';
import * as S from '../../pages/alert-view/index.styled';
import Heading from '../Heading';

import MessageHeading from '../MessageHeading';
import StatusPill, { TStatusPillTypes } from '../status-pill';
import DurationList from '../duration-list';
import {
  MessageType,
  PlannedWorkStatus,
  PublishStatus,
  DurationsSourceInputMethod,
  FeatureFlagName,
} from '../../../generated/global-types';
import MessageDisplay from '../message/MessageDisplay';

import { PLANNED_WORK_STATUSES } from '../../../constants/planned-work';
import PublishTimes from '../publish-times/PublishTimes';
import { homepageVisibilityMessage } from './utils';
import ScreenTargetingPreview from './screen-targeting-preview';

const ReclearPlannedWorkScreensMutation = loader(
  '../../../graphql/ReclearPlannedWorkScreens.gql',
);

const Durations: React.FC<{
  durations: PotentiallyUnboundedDuration[];
  exceptions?: PotentiallyUnboundedDuration[];
}> = ({ durations, exceptions }) => {
  const [isDurationsOpen, setIsDurationsOpen] = React.useState(false);
  const [isExceptionsOpen, setIsExceptionsOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Collapsable
        isOpen={isDurationsOpen}
        onClick={() => setIsDurationsOpen(!isDurationsOpen)}
        title="Durations"
        isLargeTitle
      >
        <div
          css={css`
            margin-top: 10px;
          `}
        >
          <DurationList durations={durations} />
        </div>
      </Collapsable>
      {exceptions && exceptions.length > 0 && (
        <div
          css={css`
            margin-top: -10px;
          `}
        >
          <Collapsable
            isOpen={isExceptionsOpen}
            onClick={() => setIsExceptionsOpen(!isExceptionsOpen)}
            title="Exceptions"
            isLargeTitle
          >
            <div
              css={css`
                margin-top: 10px;
              `}
            >
              <DurationList durations={exceptions} />
            </div>
          </Collapsable>
        </div>
      )}
    </React.Fragment>
  );
};

const SinglePlannedWork: React.FC<{
  plannedWorkId: number;
  entitySelectors: any[]; // (GetPlannedWorkById_plannedWork_entitySelectors | null)[];
  messageType: MessageType[];
  status: PlannedWorkStatus;
  mainHtml: string;
  addlHtml: string | null;
  durations: PotentiallyUnboundedDuration[];
  durationsWithoutExceptions: PotentiallyUnboundedDuration[];
  publishedAt: Date;
  canReclearScreens: boolean;
  baseStatus?: TStatusPillTypes;
  author?: string | null;
  clearedAt?: Date;
  createdAt: Date;
  removedAt: Date | null;
  updatedAt: Date;
  clearedBy?: string | null;
  updatedBy?: string | null;
  durationsEnd: Date | null;
  servicePlanNumbers: GetPlannedWorkById_plannedWork['servicePlanNumbers'];
  generalOrderNumbers: GetPlannedWorkById_plannedWork['generalOrderNumbers'];
  internalNotes: GetPlannedWorkById_plannedWork['internalNotes'];
  stationAlternatives?: GetPlannedWorkById_plannedWork['stationAlternatives'];
  durationsSource?: GetPlannedWorkById_plannedWork['durationsSource'];
  screenMessages?: GetPlannedWorkById_plannedWork_plannedWorkScreenMessages_nodes[];
  homepagePublishOffset: GetPlannedWorkById_plannedWork_homepagePublishOffset | null;
  onClear: () => void;
  feedId: FeedId;
}> = ({
  plannedWorkId,
  entitySelectors,
  messageType,
  status,
  baseStatus,
  canReclearScreens,
  mainHtml,
  addlHtml,
  durations,
  durationsWithoutExceptions,
  author,
  clearedBy,
  updatedBy,
  clearedAt,
  createdAt,
  removedAt,
  updatedAt,
  durationsEnd,
  durationsSource,
  stationAlternatives,
  screenMessages,
  homepagePublishOffset,
  onClear,
  feedId,
}) => {
  const [toastProperties, setToastProperties] = React.useState<{
    level: ToastLevel;
    message: string;
  }>({
    level: ToastLevel.success,
    message: '',
  });

  const { shouldShowToast, hideToast, showToast } = useToast();

  const [reclearPlannedWorkScreens] = useMutation<
    ReclearPlannedWorkScreens,
    ReclearPlannedWorkScreensVariables
  >(ReclearPlannedWorkScreensMutation, {
    variables: { plannedWorkId },
  });

  const onReclearScreens = async () => {
    try {
      await reclearPlannedWorkScreens();
      setToastProperties({
        level: ToastLevel.success,
        message: RECLEAR_SCREENS_TOAST_MESSAGE,
      });
    } catch {
      setToastProperties({
        level: ToastLevel.error,
        message: RECLEAR_SCREENS_FAILED_TOAST_MESSAGE,
      });
    }
    showToast();
  };

  const routeIds = getRouteEntities(entitySelectors).map((entitySelector) => {
    return entitySelector.routeId;
  });

  const showScreensTargeting = useFeatureFlag(
    FeatureFlagName.PLANNED_WORK_SCREEN_TARGETS,
  );

  const alternatives = stationAlternatives || [];
  const namedStops = useNamedStops(
    alternatives
      .filter(
        (a): a is GetPlannedWorkById_plannedWork_stationAlternatives =>
          a !== null,
      )
      .map((alt) => alt?.contextualSelector?.entitySelector?.stopId || ''),
  );
  const stopIdToNameMap = namedStops.reduce((acc: any, stop) => {
    if (stop.name) {
      acc[stop.id] = stop.name;
    }
    return acc;
  }, {});

  const messageTypeText =
    messageType?.length === 1
      ? MESSAGE_TYPE_DESCRIPTIONS[messageType[0]]
      : MESSAGE_TYPE_DESCRIPTIONS.MULTIPLE_CHANGES;

  const isEntirelyInPast = durations.reduce(
    (thusFar, duration) =>
      thusFar && (duration.end ? duration.end < new Date() : false),
    true,
  );

  const isCancelled = status === PlannedWorkStatus.REMOVED;
  const isCleared = status === PlannedWorkStatus.CLEARED;

  const durationsText = durations.length
    ? humanReadableDurations(
        (durationsWithoutExceptions.length
          ? durationsWithoutExceptions
          : durations
        ).map((d) => ({
          start: {
            value: d.start,
          },
          ...(d.end
            ? {
                end: {
                  value: d.end,
                },
              }
            : {}),
        })),
        {
          isOngoing: !durationsEnd,
          ongoingText:
            durationsSource?.untilFurtherNoticeMessage ??
            INDEFINITE_END_DATE_MESSAGE,
          type:
            durationsSource?.inputMethod === DurationsSourceInputMethod.CADENCE
              ? DurationsType.CADENCE
              : DurationsType.DIRECT,
          cadenceEnd: durationsSource?.cadenceRange?.end,
          overrideText: durationsSource?.humanReadableDurationsOverrideMessage,
        },
        durationsSource?.exceptions as DatetimeRangeInput[],
      )
    : '';

  const homepagePublishOffsetText = homepageVisibilityMessage(
    homepagePublishOffset ? (homepagePublishOffset?.hours ?? 0) : null,
  );

  const exceptions: PotentiallyUnboundedDuration[] =
    durationsSource?.exceptions
      ?.filter((d): d is GetPlannedWorkById_plannedWork_durations => d !== null)
      .map(({ start, end }) => ({
        start: new Date(start?.value),
        ...(end?.value ? { end: new Date(end?.value) } : {}),
      })) || [];

  return (
    <div>
      <S.Event.Container>
        <S.Message.HeaderContainer
          css={
            status === PlannedWorkStatus.DRAFT &&
            css`
              position: relative;
              &::before {
                content: ' ';
                background-color: #d3ebff;
                position: absolute;
                left: 0;
                width: 12px;
                height: 100%;
                top: 0;
                border-top-left-radius: 8px;
              }
            `
          }
        >
          <MessageHeading
            routes={routeIds}
            messageType={messageTypeText}
            title={
              messageType.length > 1
                ? messageType
                    ?.map((mt) => MESSAGE_TYPE_DESCRIPTIONS[mt])
                    .join(', ')
                : ''
            }
            size="xxlarge"
            eyebrowText="Planned Work"
            routesCss={css`
              margin-left: 0;
            `}
          >
            {baseStatus && (
              <StatusPill
                css={(theme: ThemeType) => css`
                  margin-left: 8px;
                  font-weight: ${theme.typography.weights.normal};
                `}
                status={baseStatus as TStatusPillTypes}
                size="small"
              />
            )}
            <StatusPill
              css={(theme: ThemeType) => css`
                margin-left: 8px;
                font-weight: ${theme.typography.weights.normal};
              `}
              status={
                PLANNED_WORK_STATUSES.find((opt) => opt.data.status === status)
                  ?.data.statusPill as TStatusPillTypes
              }
              size="small"
            />
          </MessageHeading>
          <PlannedWorkActions
            plannedWorkId={plannedWorkId}
            status={status}
            canBeReactivated={!isEntirelyInPast}
            durations={durations}
            onClear={onClear}
            canReclearScreens={canReclearScreens}
            onReclearScreens={onReclearScreens}
          />
        </S.Message.HeaderContainer>
        <S.Message.Contents>
          <PublishTimes
            clearedAt={clearedAt}
            createdAt={createdAt}
            updatedAt={updatedAt}
            endAt={durationsEnd}
            labelSize="medium"
            dateSize="medium"
            labelPos="horizontal"
            authorName={author || ''}
            clearedBy={clearedBy || ''}
            updatedByName={updatedBy || ''}
            showAllActionsDates
            isCancelled={isCancelled}
            isCleared={isCleared}
            verboseUpdateText
          />
        </S.Message.Contents>
        <PublishItem
          name="Web & Apps"
          publishedAtVerb="Scheduled"
          publishedAt={durations[0].start}
          status={PublishStatus.SUCCESS} // TODO: use actual status
          initIsOpen
        >
          <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            <S.Message.PreviewTitle>
              Web &amp; Apps Preview
            </S.Message.PreviewTitle>
            <div
              css={(theme: ThemeType) => css`
                background-color: #f8f8f8;
                padding: 24px;
                max-width: 624px;
                font-family: ${theme.typography.families.secondary};
              `}
            >
              <div
                css={css`
                  font-weight: bold;
                  margin-bottom: 15px;
                `}
              >
                {durationsText}
              </div>
              <MessageDisplay
                css={css`
                  background-color: #f8f8f8;
                  font-weight: bold;
                  padding: 0;
                  margin-bottom: 15px;
                `}
                innerHtml={mainHtml}
                size="xlarge"
              />
              {addlHtml && (
                <MessageDisplay
                  css={css`
                    background-color: #f8f8f8;
                    padding: 0;
                    h2 {
                      font-size: 1.25rem;
                    }
                    h3 {
                      font-size: 1.125rem;
                    }
                  `}
                  size="small"
                  innerHtml={addlHtml}
                />
              )}
              {stationAlternatives && stationAlternatives.length > 0 && (
                <div>
                  <div
                    css={css`
                      display: flex;
                      background: #f9f2b5;
                      padding: 12px 18px;
                      font-weight: bold;
                      border-radius: 4px;
                    `}
                  >
                    <span
                      css={css`
                        flex: 1;
                      `}
                    >
                      Station
                    </span>
                    <span
                      css={css`
                        flex: 1;
                      `}
                    >
                      Alternative Service
                    </span>
                  </div>
                  {stationAlternatives
                    .filter(
                      (
                        a,
                      ): a is GetPlannedWorkById_plannedWork_stationAlternatives =>
                        a !== null,
                    )
                    .map((alternative, idx) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${alternative?.contextualSelector?.entitySelector}${idx}`}
                        css={css`
                          display: flex;
                          padding: 16px 18px 0;
                        `}
                      >
                        <div
                          css={css`
                            flex: 1;
                            display: flex;
                            align-items: flex-start;
                          `}
                        >
                          {alternative?.contextualSelector?.entitySelector
                            ?.stopId && (
                            <span
                              css={css`
                                margin-top: -4px;
                                margin-right: 5px;
                                display: inline-block;
                              `}
                            >
                              {
                                stopIdToNameMap[
                                  alternative?.contextualSelector
                                    ?.entitySelector?.stopId
                                ]
                              }
                            </span>
                          )}
                        </div>
                        <HtmlPreview
                          css={css`
                            flex: 1;
                            & > div:first-of-type {
                              margin-top: -4px;
                              min-height: 100%;
                              padding: 0px 24px 0px 0;
                            }
                          `}
                          html={alternative?.notes?.html}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <Durations durations={durations} exceptions={exceptions} />
          <div>
            <S.Message.PreviewTitle>Homepage Display</S.Message.PreviewTitle>
            {homepagePublishOffsetText}
          </div>
        </PublishItem>
      </S.Event.Container>
      {!isBridgeTunnel(feedId) && (
        <S.Event.Container>
          <S.Message.HeaderContainer
            css={css`
              border-bottom: ${screenMessages?.length ? 1 : 0};
            `}
          >
            <Heading
              level={2}
              css={css`
                opacity: ${screenMessages?.length ? 1 : 0.5};
              `}
            >
              Screens
            </Heading>
          </S.Message.HeaderContainer>
          {showScreensTargeting && !!screenMessages?.length && (
            <React.Fragment>
              <div
                css={css`
                  padding: 20px 40px;
                `}
              >
                {screenMessages?.map((targeting, index) => {
                  return (
                    <ScreenTargetingPreview
                      key={targeting.id}
                      screenMessage={targeting}
                      durationsEnd={durationsEnd}
                      removedAt={removedAt}
                      index={index}
                      screensDurations={durations}
                      durationsText={durationsText}
                      messageTypes={messageType}
                      impactRouteIds={routeIds}
                      isClearing={targeting?.isClearing}
                    />
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </S.Event.Container>
      )}
      <S.Event.Container>
        <EditableInternalNotes
          initIsOpen={false}
          plannedWorkId={plannedWorkId}
        />
      </S.Event.Container>
      <Toast
        shouldShow={shouldShowToast}
        level={toastProperties.level}
        onDismiss={hideToast}
      >
        {toastProperties.message}
      </Toast>
    </div>
  );
};

export default SinglePlannedWork;
