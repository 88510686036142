import React, { createContext, useCallback, useContext, useState } from 'react';

interface PlaybackContextState {
  play: (id: string | null) => void;
  currentlyPlaying: null | string;
}

const EMPTY = { currentlyPlaying: null, play: () => {} };

const PlaybackContext = createContext<PlaybackContextState>(EMPTY);

export const PlaybackProvider: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ children }) => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState<null | string>(null);

  return (
    <PlaybackContext.Provider
      value={{
        currentlyPlaying,
        play: setCurrentlyPlaying,
      }}
    >
      {children}
    </PlaybackContext.Provider>
  );
};

export const usePlayback = (id: string) => {
  const { play, currentlyPlaying } = useContext(PlaybackContext);
  const playCurrent = useCallback(
    () => play(currentlyPlaying === id ? null : id),
    [id, play, currentlyPlaying],
  );
  return { play: playCurrent, shouldBePlaying: id === currentlyPlaying };
};
