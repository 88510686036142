/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { container } from '../../common/styles';
import { ThemeType } from '../../../theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <div css={container} {...props} />;

export const MessageListContainer: React.FC<{
  hasMessages: boolean;
  children?: React.ReactNode;
}> = ({ hasMessages, ...rest }) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      height: 100%;
      align-items: ${hasMessages ? 'flex-start' : 'center'};
      justify-content: ${hasMessages ? 'between' : 'center'};
      padding: ${theme.spacing.large};
      padding-top: 0;
    `}
    {...rest}
  />
);

export const StatusContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      position: relative;
      bottom: 4px;
      margin-left: ${theme.spacing.xsmall};
    `}
    {...props}
  />
);

export const AlertsFilters: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 24px ${theme.spacing.large};
    `}
    {...props}
  />
);

export const SelectContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={() => css`
      flex: 0 1 100%;
    `}
    {...props}
  />
);

export const clearButtonContainer = (theme: ThemeType) => css`
  flex: 0 1 64px;
  padding-left: ${theme.spacing.xsmall};
`;

export const clearButton = () => css`
  padding: 12px 8px;
  min-width: 100%;
`;

export const select = (theme: ThemeType) => css`
  background-color: ${theme.colors.white};
`;

export const leftSpace = (theme: ThemeType) => css`
  padding-left: ${theme.spacing.xsmall};
`;

export const updateTimeStampContainer = (theme: ThemeType) => css`
  flex: 1 0 120px;
  padding-left: ${theme.spacing.xsmall};
`;

export const ActiveAlertsMessage: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      color: ${theme.colors.dark3};
      font-size: ${theme.typography.sizes.small};
    `}
    {...props}
  />
);
