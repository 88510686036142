/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react';
import React from 'react';

import Heading from '../common/Heading';
import * as S from './PageHeading.styled';
import AppLink from '../common/app-link';
import Skeleton from '../common/skeletons/components/PageHeading';

import { ThemeType } from '../../theme';

interface BreadcrumbLink {
  to: string;
  label: string;
}

type Breadcrumb = BreadcrumbLink | string;

export const ButtonList: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <S.ButtonList {...props} />;

const Breadcrumbs: React.FC<{ links: Breadcrumb[] }> = ({ links = [] }) => {
  return links.length ? (
    <nav aria-label="Breadcrumb">
      <S.BCList>
        {links.map((link) => {
          if (typeof link === 'string') {
            return (
              <S.BCListItem
                css={(theme: ThemeType) => css`
                  color: ${theme.colors.accent1};
                  font-weight: ${theme.typography.weights.bolder};
                `}
                key={`bc-${link}`}
              >
                {link}
              </S.BCListItem>
            );
          }
          return (
            <S.BCListItem key={`bc-${link.to}-${link.label}`}>
              <AppLink
                containerStyles={css`
                  display: inline-block;
                `}
                to={link.to}
              >
                {link.label}
              </AppLink>
            </S.BCListItem>
          );
        })}
      </S.BCList>
    </nav>
  ) : null;
};

const PageHeading: React.FC<
  {
    breadcrumbs?: Breadcrumb[];
    title?: string;
    headingWrapperCss?: SerializedStyles;
    childrenWrapperCss?: SerializedStyles;
    titleAdjacent?: JSX.Element;
    loading?: boolean;
    subHeader?: JSX.Element;
  } & { children?: React.ReactNode }
> = ({
  children,
  breadcrumbs = [],
  title,
  titleAdjacent,
  loading,
  subHeader,
  headingWrapperCss,
  childrenWrapperCss,
  ...props
}) => {
  return (
    <S.Header
      {...props}
      css={
        subHeader &&
        css`
          flex-wrap: wrap;
        `
      }
      loading={loading}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <React.Fragment>
          <div
            css={[
              css`
                flex: 0 0 auto;
              `,
              headingWrapperCss,
            ]}
          >
            {breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
            {title && (
              <div
                css={[
                  css`
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                  `,
                  headingWrapperCss,
                ]}
              >
                <Heading level={1} size="xxlarge">
                  {title}
                </Heading>
                {titleAdjacent}
              </div>
            )}
          </div>
          <div
            css={[
              css`
                display: flex;
                flex: 0 0 auto;
                justify-content: flex-end;
              `,
              childrenWrapperCss,
            ]}
          >
            {children}
          </div>
          {subHeader}
        </React.Fragment>
      )}
    </S.Header>
  );
};

export default PageHeading;
