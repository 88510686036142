/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ThemeType } from '../../../theme';
import CommonButton from '../../common/Button';

export const Header: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <header
    css={() => css`
      display: flex;
      flex-direction: row;
    `}
    {...props}
  />
);

export const Info: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={() => css`
      flex: 1;
      display: flex;
      flex-direction: column;
    `}
    {...props}
  />
);

export const Title: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => (
  <h1
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.large};
      margin: 0 0 12px;
      display: flex;
      align-items: center;
    `}
    {...props}
  >
    {children}
  </h1>
);

export const EditedLast: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
    `}
    {...props}
  />
);

export const Buttons: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={() => css`
      display: flex;
      flex: 0;
      flex-direction: row-reverse;
    `}
    {...props}
  />
);

export const Button: React.FC<{
  primary?: boolean;
  disabled?: boolean;
  title?: string;
  onClick?: any;
  children?: React.ReactNode;
}> = ({ primary = false, ...props }) => (
  <CommonButton
    type="button"
    primary={primary}
    css={() => css`
      height: 40px;
      width: 120px;
      min-width: 0;
      margin-left: 12px;
      padding: 0 15px;
    `}
    {...props}
  />
);

export const Label: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => (
  <h2
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      margin: 0 0 8px;
    `}
    {...props}
  >
    {children}
  </h2>
);

export const Durations: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <ol
    css={() => css`
      margin: 0 0 8px;
      padding: 0;
      list-style: none;
    `}
    {...props}
  />
);

export const AllStations: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <p
    css={css`
      margin: 0px;
    `}
    {...props}
  />
);

export const Duration: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <li
    css={() => css`
      margin-bottom: 8px;
      font-size: 14px;
    `}
    {...props}
  />
);
