/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import EventStamp from '../../../common/event-stamp';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message as GetEventById_event_messages_nodes } from '../../../../generated/GetEventById';
import { ThemeType } from '../../../../theme';
import Heading from '../../../common/Heading';

const UpdateHeading: React.FC<
  GetEventById_event_messages_nodes & { children?: React.ReactNode }
> = ({ createdAt, author }) => {
  return (
    <div
      css={(theme: ThemeType) => css`
        background-color: ${theme.colors.white};
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid ${theme.colors.border};
        padding: 40px;
      `}
    >
      <Heading
        level={2}
        size="xxlarge"
        css={css`
          margin-bottom: 8px;
        `}
      >
        Update Alert
      </Heading>
      <span
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.small};
        `}
      >
        <span
          css={() => css`
            font-weight: bold;
          `}
        >
          Last Updated:{' '}
        </span>
        <EventStamp date={createdAt} name={author?.name} />
      </span>
    </div>
  );
};

export default UpdateHeading;
