/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ThemeType } from '../../../theme';
import { flexRow } from '../styles';

export const OptionText: React.FC<{
  color?: string;
  children?: React.ReactNode;
}> = ({ color = 'accent1', ...rest }) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      color: ${theme.colors[color]};
      font-family: ${theme.typography.families.primary};
    `}
    {...rest}
  />
);

export const Option: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={[
      flexRow,
      css`
        align-items: center;
        justify-content: space-between;
        & [data-bullet-route-id] {
          margin-right: 4px;
          margin-left: 4px;
        }
      `,
    ]}
    {...props}
  />
);

export const LineOption: React.FC<{
  feedId: string;
  selected: boolean;
  disabled: boolean;
  focused: boolean;
  children?: React.ReactNode;
}> = ({ selected, disabled, feedId, focused, ...rest }) => (
  <div
    css={(theme: ThemeType) => css`
      cursor: ${disabled ? 'default' : 'pointer'};
      flex: 0 0 auto;
      height: auto;
      margin: 6px 2px;
      padding: 2px;
      border-radius: ${feedId === 'nyct-subway' ? '20px' : '4px'};
      opacity: ${disabled ? 0.3 : 1};
      border: 2px solid ${selected ? theme.colors.accent3 : 'transparent'};

      position: relative;

      &:after {
        content: '';
        position: absolute;

        ${focused && selected
          ? `
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;

        border: 1px solid
          ${theme.colors.accent3};`
          : `
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;

          border: 1px solid ${
            focused || selected ? theme.colors.accent3 : 'transparent'
          };`}

        border-radius: ${feedId === 'nyct-subway' ? '20px' : '4px'};
      }
    `}
    {...rest}
  />
);
