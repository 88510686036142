import React from 'react';

import ContentLoader from 'react-content-loader';

const SkeletonLoader: React.FC<{
  height: string;
  width?: string;
  viewBox?: string;
  children?: React.ReactNode;
}> = ({ height, width = '100%', children }) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      foregroundColor="#f3f3f3"
      backgroundColor="#e1e1e1"
    >
      {children}
    </ContentLoader>
  );
};

export default SkeletonLoader;
