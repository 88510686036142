import { FeatureEnabled, FeatureFlagName } from 'generated/global-types';
import { feedsCount } from 'contexts/FeedId';
import { FeatureFlags_featureFlags_FeatureFlagsConnection_nodes_FeatureFlag as FeatureFlags_featureFlags_nodes } from '../../../generated/FeatureFlags';

export type TableStructure<T = { [key: string]: any }> = {
  info: {
    name: string | null;
    description: string | null;
  };
  actions: {
    enabled: boolean;
    name: FeatureFlagName;
    feeds: (string | null)[];
  };
} & T;

export const transformDataToTableStructure = (
  featureFlags: FeatureFlags_featureFlags_nodes[],
  feedGroups: string[],
): TableStructure[] => {
  const tableFeatureFlags: TableStructure[] = [];
  let allFeedsEnabled = false;
  featureFlags.forEach((featureFlag) => {
    const tableFeatureFlag: TableStructure = {
      info: {
        name: featureFlag.name,
        description: featureFlag.description as string | null,
      },
      actions: {
        name: featureFlag.name,
        enabled: false,
        feeds: featureFlag.feeds ?? [],
      },
    };

    feedGroups.forEach((f) => {
      let currentFeedEnabled = false;
      allFeedsEnabled = false;
      if (featureFlag.enabled === FeatureEnabled.ON) {
        currentFeedEnabled = true;
        allFeedsEnabled = true;
      } else if (featureFlag.enabled === FeatureEnabled.PARTIAL) {
        if (featureFlag.feeds) {
          currentFeedEnabled = featureFlag.feeds?.includes(f);
        } else {
          currentFeedEnabled = false;
        }
      }
      tableFeatureFlag[f] = {
        isSelected: currentFeedEnabled,
        featureFlag,
      };
    });
    tableFeatureFlag.actions.enabled = allFeedsEnabled;
    tableFeatureFlags.push(tableFeatureFlag);
  });
  return tableFeatureFlags;
};

export const currentFeedsState = (
  feedGroups: string[],
  feedGroup: string,
  isSelected: boolean,
  feeds: string[],
) => {
  return feedGroups.map((feedId: any) => {
    if (feedId === feedGroup) {
      return {
        enabled: !isSelected,
        feedId,
      };
    }
    return {
      enabled: feeds?.includes(feedId),
      feedId,
    };
  });
};

export const userCurrentEnabledFeatureFlag = (
  featureFlags: { [key: string]: { string: boolean } },
  flagName: FeatureFlagName,
  feeds: string[],
  currentFlagFeeds: (string | null)[],
) => {
  const editedFeeds: any = [];
  if (
    Object.values(featureFlags[flagName] ?? {}).filter(
      (enabled) => enabled === true,
    ).length === feedsCount()
  ) {
    feeds.forEach((feed: string) => {
      editedFeeds[flagName] = {
        ...editedFeeds[flagName],
        [feed]: currentFlagFeeds.includes(feed),
      };
    });
  } else {
    feeds.forEach((feed: string) => {
      editedFeeds[flagName] = {
        ...editedFeeds[flagName],
        [feed]: true,
      };
    });
  }
  return { ...featureFlags, ...editedFeeds };
};
