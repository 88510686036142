/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';
import pluralize from 'pluralize';
import { entitySelectorsToRouteMentions } from 'utils/route-mentions';
import { useRoutesByFeedId } from 'contexts/Routes';
import { getAllRoutesFromImpacts } from 'components/common/compose/impact-selector';
import { MESSAGE_TYPE_DESCRIPTIONS } from '@mta-live-media-manager/shared';
import AlertScreenPreview from 'ui-kit/alert-screen-preview/alert-screen-preview';
import { ScreenPreviewDisplayTypes } from 'utils/screen-preview';

import { getDimensionsFromTargetType } from 'components/pages/Screen/in-screens-preview/helpers';
import {
  GtfsEntitySelectorInput,
  MessageType,
  TargetType,
} from 'generated/global-types';

import StatusPill, { TStatusPillTypes } from 'components/common/status-pill';
import { ContentTypeLabel, WeightPropsContainer } from './shared';
import Button from '../../common/Button';
import Bullet from '../../common/Bullet';
import AppLink from '../../common/app-link';
import Modal from '../../common/modal';

import { ReactComponent as IconZoom } from '../../../images/icon_zoom.svg';

import useNamedStops from '../../../hooks/useNamedStops';
import useRoutesForStops, {
  Mode as RoutesForStopsMode,
} from '../../../hooks/useRoutesForStops';

import { Screen_screen2_Screens2_alerts_ScreenMessagesConnection_nodes_ScreenMessage as Screen_screen2_alerts_nodes } from '../../../generated/Screen';

import * as S from './CampaignsOnScreen.styled';
import { useFeedId } from 'contexts/FeedId';

interface AlertRowProps {
  alert: Screen_screen2_alerts_nodes;
  screenOrientation: ScreenPreviewDisplayTypes;
  screenTargetType: TargetType;
  weightingEnabled?: boolean;
  timeOnScreenPercentage?: number;
}
const AlertRow: React.FC<AlertRowProps> = ({
  alert,
  screenOrientation,
  screenTargetType,
  weightingEnabled = false,
  timeOnScreenPercentage = 0,
}) => {
  const [showLargePreview, setShowLargePreview] = React.useState(false);

  const alertMessage = alert.message;

  const entitySelectors =
    (alert?.targeting
      ?.map((selector) => selector?.entitySelector)
      .filter(Boolean) as GtfsEntitySelectorInput[]) || [];

  const routes = entitySelectors
    .filter((es) => !!es?.routeId && !es?.stopId)
    .map((es) => es?.routeId) as string[];
  const stops = entitySelectors
    .filter((es) => !!es?.stopId)
    .map((es) => es?.stopId) as string[];

  const routesForStops = useRoutesForStops(stops, {
    mode: RoutesForStopsMode.union,
  });
  const feedId = useFeedId();
  const namedStops = useNamedStops(stops);
  const allRoutes = useRoutesByFeedId(feedId);

  if (alertMessage && alert) {
    const screenDimensions = getDimensionsFromTargetType(screenTargetType);

    const messageImpacts = alertMessage.messageImpacts.nodes.map((impact) => ({
      messageType: impact.messageType,
      routes: entitySelectorsToRouteMentions(
        impact.entitySelectors as GtfsEntitySelectorInput[],
        allRoutes,
      ),
      isAgencyWide: impact.isAgencyWide,
    }));

    const messageType =
      messageImpacts.length > 1
        ? MessageType.MULTIPLE_CHANGES
        : messageImpacts[0].messageType;

    const allImpactedRouteMentions = getAllRoutesFromImpacts(messageImpacts);
    const screenAffectedRoutes = allRoutes.filter((route) =>
      allImpactedRouteMentions.find(
        (mention) => mention.routeId === route.gtfsId,
      ),
    );

    const screenMessageTitle =
      alert.title ?? MESSAGE_TYPE_DESCRIPTIONS[messageType];

    const statusPill = (
      <StatusPill status={TStatusPillTypes.LIVE} size="small" />
    );

    return (
      <S.Container>
        <S.Preview>
          <S.ViewDetail onClick={() => setShowLargePreview(true)}>
            <IconZoom
              css={css`
                margin-bottom: 8px;
              `}
            />
            View Detail
          </S.ViewDetail>
          <section
            css={css`
              width: calc(${screenDimensions[0]}px * ${0.08});
            `}
          >
            <AlertScreenPreview
              messageType={messageType}
              title={screenMessageTitle}
              content={alert.bodyHtml}
              additionalContent={alert.additionalInfoHtml ?? ''}
              affectedRoutes={screenAffectedRoutes}
              displayType={
                screenTargetType === TargetType.DUP
                  ? ScreenPreviewDisplayTypes.DUP
                  : screenOrientation
              }
              publishAt={alert.publishAt}
            />
          </section>
        </S.Preview>
        <S.Details>
          <S.Header>
            <S.Title>
              <ContentTypeLabel>Alert</ContentTypeLabel>
              {screenMessageTitle && (
                <span
                  css={css`
                    margin-right: 4px;
                    white-space: nowrap;
                  `}
                >
                  {screenMessageTitle} -
                </span>
              )}
              <span
                css={css`
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 350px;
                `}
              >
                {alert.body}
              </span>
            </S.Title>
          </S.Header>
          <S.Durations>Ongoing</S.Durations>

          <div
            css={css`
              display: flex;
              flex-direction: row;
            `}
          >
            <div
              css={css`
                margin-right: 6px;
              `}
            >
              {routes.map((routeId) => (
                <Bullet key={routeId} routeId={routeId} />
              ))}
              {routesForStops.map((routeId) => (
                <Bullet key={routeId} routeId={routeId} />
              ))}
            </div>
            {(() => {
              switch (namedStops.length) {
                case 0:
                  return 'All Stations';
                case 1:
                  return namedStops[0].name || namedStops[0].id;
                default:
                  return pluralize('Station', namedStops.length, true);
              }
            })()}
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              margin-top: 16px;
            `}
          >
            <Button
              as={AppLink}
              to={`/${alertMessage.feedId}/events/${alertMessage.eventId}`}
              type="button"
              size="xsmall"
              primary
            >
              View Details
            </Button>
          </div>
        </S.Details>
        {weightingEnabled ? (
          <WeightPropsContainer
            priority={alert.priority}
            weight={alert.weight}
            statusPill={statusPill}
            timeOnScreenPercentage={timeOnScreenPercentage}
          />
        ) : (
          <div>{statusPill}</div>
        )}
        {showLargePreview && (
          <Modal
            title="View Detail"
            onDismiss={() => setShowLargePreview(false)}
            restOnContent
            css={css`
              width: 1078px;
              max-width: unset;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 24px;

                & > div:first-of-type {
                  width: calc(${screenDimensions[0]}px * ${0.3});
                }
              `}
            >
              <AlertScreenPreview
                messageType={messageType}
                title={screenMessageTitle}
                content={alert.bodyHtml}
                additionalContent={alert.additionalInfoHtml ?? ''}
                affectedRoutes={screenAffectedRoutes}
                displayType={
                  screenTargetType === TargetType.DUP
                    ? ScreenPreviewDisplayTypes.DUP
                    : screenOrientation
                }
                publishAt={alert.publishAt}
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row-reverse;
              `}
            >
              <Button
                as={AppLink}
                primary
                to={`/${alertMessage.feedId}/events/${alertMessage.eventId}`}
              >
                View Details
              </Button>
              <Button
                css={css`
                  margin-right: 16px;
                `}
                type="button"
                onClick={() => setShowLargePreview(false)}
              >
                Close
              </Button>
            </div>
          </Modal>
        )}
      </S.Container>
    );
  }

  return null;
};

export default AlertRow;
