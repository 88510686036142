/** @jsxImportSource @emotion/react */

import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import ReferrerRedirect from 'components/common/ReferrerRedirect';
import Loader from '../../common/Loader';
import * as Layout from '../../common/layout';
import useAuthentication from '../../../contexts/Authentication';
import { SIGN_OUT_PATH } from '../../../constants/authentication';

const SSO: React.FC<{ children?: React.ReactNode }> = () => {
  const { pathname } = useLocation();
  const { isFetching } = useAuthentication();

  if (isFetching) {
    const isSignOut = pathname === SIGN_OUT_PATH;
    const message = isSignOut ? 'Signing You Out...' : 'Signing You In...';
    return (
      <Layout.Page>
        <Loader loading>
          <p>{message}</p>
        </Loader>
      </Layout.Page>
    );
  }

  return (
    <ReferrerRedirect>
      <Redirect to="/login" />
    </ReferrerRedirect>
  );
};

export default SSO;
