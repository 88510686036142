import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import _isEqual from 'lodash/isEqual';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { TAffectedStops } from 'components/common/connected-route-range-stop-selector-group';
import { usePrevious } from 'hooks/use-previous';
import { IUseScreenLayoutCountResult } from 'hooks/use-screen-layout-counts';
import useRoutesWithStops from 'hooks/useRoutesWithStops';
import {
  TargetingOption,
  TargetingOptionType,
} from 'utils/stop-selector-helpers';
import { groupedCICs as defaultCICGrouping } from 'constants/screens';
import { ALL_CIC } from '../components/pages/Screen/in-screens-preview/helpers';
import { getAllRoutesFromImpacts } from '../components/common/compose/impact-selector';
import { ScreenTarget, TImpact } from '../types';
import { FeatureFlagName, TargetType } from '../generated/global-types';
import { boroughOptionLabel } from './boroughs';
import {
  getVehicleName,
  getStopType,
  isTrain,
  isRailRoad,
} from './feed-switches';
import {
  SCREEN_TARGETING_NAME,
  SCREEN_TARGETING_EDITED,
  SCREENS_NAME,
} from '../constants/alerts';
import { useFeedId, FeedId } from '../contexts/FeedId';
import { modifyIndexInArray } from './compose-helpers';
import { ScreenSelection_routes_RoutesConnection } from 'generated/ScreenSelection';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message_screenMessages_ScreenMessagesConnection_nodes_ScreenMessage_targeting_ScreenSelector } from 'generated/GetEventById';
import { RoutesWithStops_routes_RoutesConnection_nodes_Route } from 'generated/RoutesWithStops';
import { COMBINED_CICS } from 'components/common/ScreenSelector/ScreenTypeSelector';

const allString = (routeShortName: string, feedId: FeedId): string =>
  `All ${routeShortName} ${getVehicleName(feedId)} ${getStopType(feedId)}s`;

export const getAlertDefaultScreenTargetTypes = (
  feedId: FeedId,
): TargetType[] => {
  if (isRailRoad(feedId)) {
    return [
      TargetType.SINGLE_PIO_LANDSCAPE,
      TargetType.SOLARI_PORTRAIT,
      ...COMBINED_CICS[feedId],
    ];
  }

  return [TargetType.NON_AD];
};

export const defaultTargetForRouteId = (
  routeId: string,
  routeShortName: string,
  feedId: FeedId,
  stops?: TargetingOption[],
  isAllStops?: boolean,
): ScreenTarget => {
  const defaultScreenTargetTypes = getAlertDefaultScreenTargetTypes(feedId);

  if (stops?.length) {
    return {
      routeId,
      options: isAllStops
        ? [
            {
              key: `${routeId}-all`,
              label: allString(routeShortName, feedId),
              bulletValue: routeId,
              routeId,
              feedId,
              optionType: TargetingOptionType.ALL_ROUTE_OPTION,
            },
            ...stops,
          ]
        : stops,
      layouts: defaultScreenTargetTypes,
      tags: {},
    };
  }

  return {
    routeId,
    options: [
      {
        key: `${routeId}-all`,
        label: allString(routeShortName, feedId),
        bulletValue: routeId,
        routeId,
        feedId,
        optionType: TargetingOptionType.ALL_ROUTE_OPTION,
      },
    ],
    layouts: defaultScreenTargetTypes,
    tags: {},
  };
};

// ESLINT_FIXME: Is it better to swap the args to preserve the default val or use the default in invocation
export const findRoute = (screenTargeting: ScreenTarget[], routeId: string) => {
  return screenTargeting.find(
    (target: ScreenTarget) => target.routeId === routeId,
  );
};

export const changeTargetRoute = (
  screenTargeting: ScreenTarget[],
  idx: number,
  newTarget: ScreenTarget,
) => {
  // change route
  return [
    ...screenTargeting.slice(0, idx),
    newTarget,
    ...screenTargeting.slice(idx + 1),
  ];
};

export const removeTargetRoute = (
  screenTargeting: ScreenTarget[],
  routeId: string,
) => {
  const targetIdx = screenTargeting.findIndex(
    (target: ScreenTarget) => target.routeId === routeId,
  );
  // route to remove doesn't exist
  if (targetIdx === -1) {
    return screenTargeting;
  }

  // remove route
  return [
    ...screenTargeting.slice(0, targetIdx),
    ...screenTargeting.slice(targetIdx + 1),
  ];
};

const shortNameForStopId = (
  screenSelectionRoutes: ScreenSelection_routes_RoutesConnection,
  routeId: string,
  stopId: string,
): string | null => {
  const route = screenSelectionRoutes?.nodes.find((r) => r.gtfsId === routeId);
  const stop = route?.orderedStopsByDirection?.nodes.find(
    (s) => s.gtfsId === stopId,
  );
  return stop?.name ?? null;
};

export const screenTargetsFromEventTargeting = (
  // targeting: GetEventById_event_messages_nodes_screenMessages_nodes_targeting[],
  targeting: GetEventById_event_Event_messages_MessagesConnection_nodes_Message_screenMessages_ScreenMessagesConnection_nodes_ScreenMessage_targeting_ScreenSelector[],
  shortNameForRouteId: (routeId: string) => string | null,
  feedId: FeedId,
  screenSelectionRoutes?: ScreenSelection_routes_RoutesConnection,
): ScreenTarget[] =>
  targeting.reduce<ScreenTarget[]>((agg, cur) => {
    const { entitySelector } = cur;
    const routeShortName = shortNameForRouteId(cur.routeId);
    if (!routeShortName) {
      return agg;
    }

    const target = agg.find((t) => t.routeId === cur.routeId) || {
      routeId: cur.routeId,
      options: [],
      layouts: cur.layouts.filter(
        (screenType): screenType is TargetType => !!screenType,
      ),
      tags: cur.tags,
    };

    const { options }: { options: TargetingOption[] } = target;
    if (entitySelector.routeId) {
      options.push({
        key: `${cur.routeId}-all`,
        label: allString(routeShortName, feedId),
        bulletValue: cur.routeId,
        routeId: cur.routeId,
        feedId,
        optionType: TargetingOptionType.ALL_ROUTE_OPTION,
      });
    } else if (entitySelector.stopId) {
      const stopOption: TargetingOption = {
        key: entitySelector.stopId,
        label:
          (screenSelectionRoutes &&
            shortNameForStopId(
              screenSelectionRoutes,
              cur.routeId,
              entitySelector.stopId,
            )) ||
          '',
        stopId: entitySelector.stopId,
        borough: cur.borough,
        feedId,
        optionType: TargetingOptionType.STOP_OPTION,
      };

      if (cur.borough) {
        const boroughOption: TargetingOption = options.find(
          (o) =>
            o.optionType === TargetingOptionType.ALL_BOROUGH_OPTION &&
            o.borough === cur.borough,
        ) as TargetingOption;

        if (!boroughOption) {
          options.push({
            key: cur.borough,
            label: boroughOptionLabel(cur.borough, feedId),
            bulletValue: cur.routeId,
            borough: cur.borough,
            feedId,
            optionType: TargetingOptionType.ALL_BOROUGH_OPTION,
          });
        }
      }

      options.push(stopOption);
    }
    return [
      ...agg.filter((t) => t.routeId !== target.routeId),
      {
        ...target,
        options,
      },
    ];
  }, []);

export const registerScreenTargeting = (register: any) => {
  register({ name: SCREEN_TARGETING_EDITED });
};

export const getScreenTargetsForAutoScreenTargeting = (
  impacts: TImpact[],
  feedId: FeedId,
  routesWithStops: RoutesWithStops_routes_RoutesConnection_nodes_Route[],
  affectedStops?: TAffectedStops,
  initializeEmpty?: boolean,
): ScreenTarget[] => {
  const allRoutesForImpacts = getAllRoutesFromImpacts(impacts);
  const defaultLayouts = getAlertDefaultScreenTargetTypes(feedId);
  if (allRoutesForImpacts.length) {
    const autoScreenTargeting = allRoutesForImpacts.map((rfi) => {
      if (initializeEmpty) {
        return {
          routeId: rfi.routeId,
          options: [],
          layouts: defaultLayouts,
        };
      }

      const routeWithStops = routesWithStops.find(
        (route) => route.gtfsId === rfi.routeId,
      );

      const stopOptions: TargetingOption[] | undefined =
        affectedStops?.gtfsStopIds
          ? (affectedStops?.gtfsStopIds
              ?.map((sid) => {
                const stop = routesWithStops
                  .find((route) => route.gtfsId === rfi.routeId)
                  ?.stops.nodes.find((stop) => stop.gtfsId === sid);

                if (!stop) {
                  return null;
                }

                return {
                  key: stop?.gtfsId,
                  label: stop?.name,
                  stopId: stop?.gtfsId,
                  borough: stop?.borough,
                  optionType: TargetingOptionType.STOP_OPTION,
                };
              })
              .filter((stopOption) => !!stopOption) as TargetingOption[])
          : undefined;

      const isAllStops =
        routeWithStops?.stops.nodes.length === stopOptions?.length;

      return defaultTargetForRouteId(
        rfi.routeId,
        rfi.name,
        feedId,
        stopOptions,
        isAllStops,
      );
    });

    return autoScreenTargeting;
  }

  return [{ routeId: '', options: [], layouts: [TargetType.NON_AD] }];
};

interface IUseAutoScreenTargetingProps {
  impacts: TImpact[];
  screenTargetingName?: string;
  affectedStops?: TAffectedStops;
}

export const useAutoScreenTargeting = (
  {
    impacts,
    affectedStops,
    screenTargetingName = SCREEN_TARGETING_NAME,
  }: IUseAutoScreenTargetingProps,
  autoTargetingEnabled: boolean = true,
) => {
  const feedId = useFeedId();
  const routesWithStops = useRoutesWithStops(feedId);
  const { setValue, watch } = useFormContext();
  const canTargetScreens = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
  );

  const prevImpacts = usePrevious(impacts);
  const prevAffectedStops = usePrevious(affectedStops || { gtfsStopIds: [] });

  const screenTargetingEdited = watch(SCREEN_TARGETING_EDITED) as
    | undefined
    | boolean;
  const screens = watch(SCREENS_NAME) as ScreenTarget[][];
  const screenTargeting = watch(screenTargetingName) as ScreenTarget[][];

  const shouldAutoTarget =
    autoTargetingEnabled &&
    isTrain(feedId) &&
    canTargetScreens &&
    !screenTargetingEdited;

  useEffect(() => {
    // We only want to check the equality of the routes/agency-wide impacts,
    // we don't care about the message types because they are not used in
    // the screen targeting form.
    const newRouteImpacts = impacts?.map(({ routes, isAgencyWide }) => ({
      routes,
      isAgencyWide,
    }));
    const prevRouteImpacts = prevImpacts?.map(({ routes, isAgencyWide }) => ({
      routes,
      isAgencyWide,
    }));
    if (
      shouldAutoTarget &&
      (!_isEqual(newRouteImpacts, prevRouteImpacts) ||
        !_isEqual(affectedStops?.gtfsStopIds, prevAffectedStops?.gtfsStopIds))
    ) {
      /*
       * Check for deep equality difference before updating to prevent
       * an infinite loop.
       */
      const autoScreenTargeting = getScreenTargetsForAutoScreenTargeting(
        impacts,
        feedId,
        routesWithStops,
        affectedStops,
      );

      setValue(
        SCREENS_NAME,
        modifyIndexInArray({
          index: 0, // Only setting autoScreenTargeting for the first screen to avoid conflicts
          value: {
            ...screens[0],
            [screenTargetingName]: autoScreenTargeting.map((st) => ({
              ...st,
              options: [],
            })),
          },
          array: screens,
        }),
      );
    }
  }, [
    shouldAutoTarget,
    impacts,
    affectedStops,
    feedId,
    routesWithStops,
    setValue,
    screenTargetingName,
    prevAffectedStops,
    prevImpacts,
    screenTargeting,
    screens,
  ]);
};

export const getGroupedCICsFromScreenLayoutCounts = (
  screenLayoutCounts: IUseScreenLayoutCountResult[],
) =>
  screenLayoutCounts
    .map(({ targetType }) => targetType)
    .filter((tt) => defaultCICGrouping.includes(tt));

export const getGroupedTargetingTypes = ({
  feedId,
  updated,
  groupedCICs = defaultCICGrouping,
}: {
  feedId: FeedId;
  updated: TargetType[];
  groupedCICs?: TargetType[];
}): TargetType[] => {
  let updatedValue = [...updated];

  if (feedId === FeedId.LIRR) {
    if (
      updatedValue.includes(TargetType.SINGLE_PIO_LANDSCAPE) &&
      !updatedValue.includes(TargetType.SOLARI_LANDSCAPE)
    ) {
      updatedValue.push(TargetType.SOLARI_LANDSCAPE);
    } else if (
      updatedValue.includes(TargetType.SOLARI_LANDSCAPE) &&
      !updatedValue.includes(TargetType.SINGLE_PIO_LANDSCAPE)
    ) {
      updatedValue = updatedValue.filter(
        (t) => t !== TargetType.SOLARI_LANDSCAPE,
      );
    }
    if (
      updatedValue.includes(TargetType.SINGLE_MKT_LANDSCAPE) &&
      !updatedValue.includes(TargetType.OTHER_MARKETING_LANDSCAPE)
    ) {
      updatedValue.push(TargetType.OTHER_MARKETING_LANDSCAPE);
    } else if (
      updatedValue.includes(TargetType.OTHER_MARKETING_LANDSCAPE) &&
      !updatedValue.includes(TargetType.SINGLE_MKT_LANDSCAPE)
    ) {
      updatedValue = updatedValue.filter(
        (t) => t !== TargetType.OTHER_MARKETING_LANDSCAPE,
      );
    }
  }

  if (updated.includes(ALL_CIC)) {
    if (groupedCICs.every((s) => updated.includes(s))) {
      updatedValue = updatedValue.filter(
        (s) => ![...groupedCICs, ALL_CIC].includes(s),
      );
    } else {
      updatedValue.push(...groupedCICs);
      updatedValue = updatedValue.filter((v) => v !== ALL_CIC);
    }
  }

  return updatedValue;
};
