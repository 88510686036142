/** @jsxImportSource @emotion/react */

import React, { useState, useEffect, useCallback } from 'react';
import { css } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

import { useFeatureFlag } from 'hooks/useFeatureFlag';

import Button from '../../common/Button';
import Modal from '../../common/modal';
import {
  TableHead,
  TableHeaderCell,
  TableRow,
} from '../../common/table-elements';
import Select from '../../common/form-elements/Select';
import { ThemeType } from '../../../theme';
import {
  UpdateScreenCustomCriteria,
  UpdateScreenCustomCriteriaVariables,
} from '../../../generated/UpdateScreenCustomCriteria';
import { Screen_screen2_Screens2 as Screen } from '../../../generated/Screen';
import {
  getSelectComponents,
  getScreenWithTagsFromScreen,
  getScreenCriteriaFromTags,
  getEditableTagsCriteria,
  getTagLabelFromValue,
} from '../../../utils/screen-tags-helpers';
import {
  getFeedTagOptions,
  SUPPORTED_TAGS_AGENCIES,
} from '../../../constants/screen-tags';
import { useFeedId, FeedId } from '../../../contexts/FeedId';

import * as S from './index.styled';
import { FeatureFlagName } from 'generated/global-types';

const UpdateScreenCustomCriteriaMutation = loader(
  '../../../graphql/UpdateScreenCustomCriteria.gql',
);

type TagType = {
  label: string;
  value: string[];
  editableTagKey?: string;
  isSingle?: boolean;
};

const getOrderedTagsFromScreen = (
  screen: Screen,
  feedId: FeedId,
  isCustomTaggingTargetingEnabled: boolean,
) => {
  const { criteria } = screen;
  const tags: TagType[] = [];

  const { tags: screenTags } = getScreenWithTagsFromScreen(screen);
  const editableTags = getEditableTagsCriteria(criteria);

  // Non-editable tags should be listed first as static values
  Object.entries(criteria).forEach(([key, value]) => {
    if (!editableTags?.includes(key)) {
      tags.push({ label: key, value: [value as string] });
    }
  });

  return [
    ...tags,
    // Adding editable tags
    ...(getFeedTagOptions(feedId) && isCustomTaggingTargetingEnabled
      ? getFeedTagOptions(feedId)
      : []
    ).map(({ label, tagName, isSingle }) => ({
      label,
      isSingle,
      editableTagKey: tagName,
      value: screenTags.find((t) => t.tagName === tagName)?.value ?? [],
    })),
  ];
};

const TagsModal: React.FC<{
  isOpen: boolean;
  screen: Screen;
  onDismiss: () => void;
  refetchScreen: () => void;
}> = ({ isOpen, screen, onDismiss, refetchScreen }) => {
  const feedId = useFeedId();

  const [tags, setTags] = useState<TagType[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const isCustomTaggingEnabled = useFeatureFlag(FeatureFlagName.CUSTOM_TAGGING);

  const [updateScreenCustomCriteria, { loading, error }] = useMutation<
    UpdateScreenCustomCriteria,
    UpdateScreenCustomCriteriaVariables
  >(UpdateScreenCustomCriteriaMutation);

  const initializeTags = useCallback(
    () =>
      setTags(getOrderedTagsFromScreen(screen, feedId, isCustomTaggingEnabled)),
    [screen, feedId, isCustomTaggingEnabled],
  );

  useEffect(() => initializeTags(), [screen, feedId, initializeTags]);

  const handleSelectTag = (
    data: { label: string; value: string }[],
    editableTagKey: string,
    isSingle: boolean,
  ) => {
    setTags(
      tags.map((t) =>
        t.editableTagKey === editableTagKey
          ? {
              ...t,
              value: data.length
                ? (isSingle
                    ? [data.slice(-1)[0].value]
                    : data.map((p) => p.value)
                  ).filter(Boolean)
                : [],
            }
          : t,
      ),
    );
  };

  const handleOnClick = async () => {
    if (!isCustomTaggingEnabled || !SUPPORTED_TAGS_AGENCIES.includes(feedId)) {
      onDismiss();
      return;
    }
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
    const screenWithTags = {
      id: screen.id,
      tags: tags
        .filter(({ editableTagKey }) => !!editableTagKey)
        .map(({ editableTagKey, value }) => ({
          tagName: editableTagKey as string,
          value,
        })),
    };
    await updateScreenCustomCriteria({
      variables: {
        input: {
          screenCriteria: getScreenCriteriaFromTags(screenWithTags, screen),
        },
      },
    });
    if (error) {
      refetchScreen();
    }
    setIsEditing(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Outfront Tags"
      onDismiss={() => {
        onDismiss();
        setIsEditing(false);
        initializeTags();
      }}
      bodyBackgroundColor="#f8f8f8"
      restOnContent
      minWidth="565px"
    >
      <S.TagsTable isEditing={isEditing}>
        <TableHead>
          <tr>
            <TableHeaderCell>Key</TableHeaderCell>
            <TableHeaderCell>Value</TableHeaderCell>
          </tr>
        </TableHead>
        <S.TagsTableBody>
          {tags.map(({ label, editableTagKey, value, isSingle }, idx) => {
            const isEditable = !!editableTagKey;
            const key = `tag-${idx}-${label}-${editableTagKey}`;
            const stringValues = (
              isEditable ? value.map((v) => getTagLabelFromValue(v)) : value
            ).join(', ');

            return (
              <TableRow key={key}>
                <S.TagsTableData
                  css={(theme: ThemeType) => css`
                    font-weight: ${theme.typography.weights.bold};
                  `}
                >
                  {label}
                </S.TagsTableData>
                <S.TagsTableData>
                  {isEditing && isEditable ? (
                    <Select
                      components={getSelectComponents()}
                      isMulti
                      isSearchable={false}
                      isClearable={false}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      controlShouldRenderValue={false}
                      placeholder={`Select ${label}`}
                      onChange={(data: { label: string; value: string }[]) =>
                        handleSelectTag(
                          data,
                          editableTagKey as string,
                          isSingle ?? false,
                        )
                      }
                      options={
                        getFeedTagOptions(feedId).find(
                          ({ tagName }) => tagName === editableTagKey,
                        )?.options ?? []
                      }
                      value={value.map((v) => ({
                        label: getTagLabelFromValue(v),
                        value: v,
                      }))}
                    />
                  ) : (
                    <React.Fragment>{stringValues}</React.Fragment>
                  )}
                </S.TagsTableData>
              </TableRow>
            );
          })}
        </S.TagsTableBody>
      </S.TagsTable>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        {isEditing && (
          <Button
            size="mediumLong"
            onClick={() => {
              setIsEditing(false);
              initializeTags();
            }}
            css={css`
              margin-right: 10px;
            `}
          >
            Cancel
          </Button>
        )}
        <Button
          primary
          size="mediumLong"
          disabled={loading}
          onClick={handleOnClick}
        >
          {(() => {
            if (
              !isCustomTaggingEnabled ||
              !SUPPORTED_TAGS_AGENCIES.includes(feedId)
            ) {
              return 'Close';
            }
            if (loading) {
              return 'Updating';
            }
            if (isEditing) {
              return 'Update';
            }
            return 'Edit';
          })()}
        </Button>
      </div>
    </Modal>
  );
};

export default TagsModal;
