/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { FeedId, useFeedId } from 'contexts/FeedId';
import theme from 'theme';

const AllStationsTag: React.FC<{
  isSmall?: boolean;
  children?: React.ReactNode;
}> = ({ isSmall = false }) => {
  const feedId = useFeedId();
  const isNYCT = feedId === FeedId.NYCTSubway || feedId === FeedId.NYCTBus;

  return (
    <span
      css={css`
        background-color: ${theme.colors.accent3};
        text-transform: uppercase;
        color: white;
        border-radius: 2px;
        font-size: ${isSmall
          ? theme.typography.sizes.xsmall.fontSize
          : theme.typography.sizes.small.fontSize};
        padding: 4px 8px;
        font-weight: ${theme.typography.weights.bold};
      `}
    >
      {isNYCT ? 'All Lines' : 'All Branches'}
    </span>
  );
};

export default AllStationsTag;
