/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import useShouldShowSkeleton from './hooks/useShouldShowSkeleton';
import TableSkeleton from './components/Table';
import SkeletonLoader from './components/SkeletonLoader';
import { LoaderComponent } from './types';

const Table: LoaderComponent = ({ loading, after, children }) => {
  const shouldShowSkeleton = useShouldShowSkeleton(loading, { after });
  return (
    <React.Fragment>
      {shouldShowSkeleton ? (
        <div
          css={css`
            width: 100%;
            border-radius: 4px;
            margin-top: 20px;
            margin-bottom: 20px;
          `}
        >
          <TableSkeleton />
          <div
            css={css`
              text-align: right;
              margin-top: 20px;
            `}
          >
            <SkeletonLoader width="170" height="40">
              <rect x="0" y="0" rx="4" ry="4" width="170" height="40" />
            </SkeletonLoader>
          </div>
        </div>
      ) : (
        children
      )}
    </React.Fragment>
  );
};

export default Table;
