import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import {
  groupedPIOs as PIOs,
  groupedMKTs as MKTs,
  groupedCICs as CICs,
} from 'constants/screens';
import { ALL_CIC } from 'components/pages/Screen/in-screens-preview/helpers';
import { FeedId, useFeedId } from '../contexts/FeedId';

import {
  MnrRollingStockLine,
  Shop,
  TargetType,
} from '../generated/global-types';
import {
  ScreenLayoutCounts,
  ScreenLayoutCountsVariables,
  ScreenLayoutCounts_screenLayoutCounts_ScreenLayoutCountsConnection_nodes_ScreenLayoutCount as ScreenLayoutCounts_screenLayoutCounts_nodes,
} from '../generated/ScreenLayoutCounts';

const ScreenLayoutCountsQuery = loader('../graphql/ScreenLayoutCounts.gql');

export interface IUseScreenLayoutCountsProps {
  routeIds?: string[];
  stopIds?: string[];
  targetTypes?: TargetType[];
}

export interface IUseScreenLayoutCountResult {
  targetType: TargetType;
  availableCount: number;
  lockedCount: number;
  totalCount: number;
}

type GroupedScreenLayouts = Omit<
  ScreenLayoutCounts_screenLayoutCounts_nodes,
  '__typename'
>;

const getGroupedScreens = (
  feedId: FeedId,
  screenLayouts: GroupedScreenLayouts[],
  isCampaign: boolean,
  shouldRemoveGroupedCICs: boolean,
): GroupedScreenLayouts[] => {
  if (!screenLayouts) {
    return [];
  }

  let filteredScreenLayouts = [...screenLayouts] as GroupedScreenLayouts[];

  if (!filteredScreenLayouts.some(({ type }) => type === ALL_CIC)) {
    filteredScreenLayouts.push({
      type: ALL_CIC,
      totalCount: CICs.reduce(
        (acc, targetType) =>
          acc +
          (screenLayouts.find(({ type }) => type === targetType)?.totalCount ??
            0),
        0,
      ),
      lockedCount: CICs.reduce(
        (acc, targetType) =>
          acc +
          (screenLayouts.find(({ type }) => type === targetType)?.lockedCount ??
            0),
        0,
      ),
    });
  }

  if (shouldRemoveGroupedCICs) {
    filteredScreenLayouts = filteredScreenLayouts.filter(
      ({ type }) => !CICs.includes(type as TargetType),
    );
  }

  if (
    feedId !== FeedId.LIRR ||
    !isCampaign ||
    !filteredScreenLayouts?.some(
      ({ type }) =>
        PIOs.includes(type as TargetType) || MKTs.includes(type as TargetType),
    )
  ) {
    return filteredScreenLayouts;
  }

  filteredScreenLayouts = filteredScreenLayouts.filter(
    ({ type }) =>
      !PIOs.includes(type as TargetType) && !MKTs.includes(type as TargetType),
  );

  return [
    ...filteredScreenLayouts,
    {
      type: TargetType.SINGLE_PIO_LANDSCAPE,
      totalCount: screenLayouts.reduce(
        (acc, { type, totalCount }) =>
          PIOs.includes(type as TargetType)
            ? acc + (totalCount as number)
            : acc,
        0,
      ) as number,
      lockedCount: screenLayouts.reduce(
        (acc, { type, lockedCount }) =>
          PIOs.includes(type as TargetType)
            ? acc + (lockedCount as number)
            : acc,
        0,
      ) as number,
    },
    {
      type: TargetType.SINGLE_MKT_LANDSCAPE,
      totalCount: screenLayouts.reduce(
        (acc, { type, totalCount }) =>
          MKTs.includes(type as TargetType)
            ? acc + (totalCount as number)
            : acc,
        0,
      ) as number,
      lockedCount: screenLayouts.reduce(
        (acc, { type, lockedCount }) =>
          MKTs.includes(type as TargetType)
            ? acc + (lockedCount as number)
            : acc,
        0,
      ) as number,
    },
  ];
};

const useScreenLayoutCounts = ({
  routeIds = [] as string[],
  stopIds = [] as string[],
  shops = [] as Shop[],
  mnrLines = [] as MnrRollingStockLine[],
  isTargetingAllLirrTrains = false,
  targetTypes = [] as TargetType[],
  excludedTargetTypes = [] as TargetType[],
  specificTargetTypes = [] as TargetType[],
  isCampaign = false,
  shouldRemoveGroupedCICs = true,
}): { screenLayoutCounts: IUseScreenLayoutCountResult[]; loading: boolean } => {
  const feedId = useFeedId();

  const { data, loading } = useQuery<
    ScreenLayoutCounts,
    ScreenLayoutCountsVariables
  >(ScreenLayoutCountsQuery, {
    skip:
      !routeIds.length &&
      !stopIds.length &&
      !shops.length &&
      !mnrLines.length &&
      !isTargetingAllLirrTrains,
    // Skips fetching stations screens when targeting train screens
    variables: {
      feedId,
      ...(shops.length || mnrLines.length || isTargetingAllLirrTrains
        ? { shops, lines: mnrLines, isTargetingAllLirrTrains }
        : { routeIds, stopIds }),
      excludedTargetTypes,
      specificTargetTypes,
    },
    fetchPolicy: 'cache-and-network',
  });
  const screenLayouts = getGroupedScreens(
    feedId,
    data?.screenLayoutCounts?.nodes as GroupedScreenLayouts[],
    isCampaign,
    shouldRemoveGroupedCICs,
  );

  if (!screenLayouts) {
    return {
      screenLayoutCounts: [] as IUseScreenLayoutCountResult[],
      loading,
    };
  }

  const counts: IUseScreenLayoutCountResult[] = screenLayouts
    .filter(Boolean)
    .map((count) => {
      const { type, totalCount, lockedCount } = count;

      return {
        lockedCount: lockedCount || 0,
        totalCount: totalCount || 0,
        availableCount: Math.max((totalCount || 0) - (lockedCount || 0), 0),
        targetType: type as TargetType,
      };
    })
    .filter(({ availableCount }) => availableCount);

  return {
    screenLayoutCounts: targetTypes.length
      ? counts.filter((count) => targetTypes.includes(count.targetType))
      : counts,
    loading,
  };
};

export default useScreenLayoutCounts;
