/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import Button from 'components/common/Button';
import TrashIcon from 'components/common/trash-icon';
import StatusPill, { TStatusPillTypes } from '../status-pill';
import * as S from './index.styled';

const ScreensAccordion: React.FC<{
  label: React.ReactNode;
  availableCount?: React.ReactNode;
  preview?: React.ReactNode;
  children: React.ReactNode;
  subtitle?: string;
  canDelete?: boolean;
  onClose?: () => void;
  onDelete?: () => void;
  additionalCss?: any;
  index?: number;
  isClearing?: boolean;
}> = ({
  children,
  label,
  availableCount,
  canDelete,
  onClose,
  onDelete,
  subtitle,
  preview,
  additionalCss,
  index,
  isClearing,
}) => {
  return (
    <S.Container css={additionalCss}>
      <S.Content>
        <S.OnlyWhenExpanded open={!isClearing}>
          <S.Header onClose={onClose} preview={preview}>
            <S.Expand data-disclosure />
            {label}
            <S.AvailableCount>
              {!!availableCount &&
                typeof availableCount !== 'number' &&
                availableCount}
              {!!availableCount &&
                typeof availableCount === 'number' &&
                availableCount !== 0 &&
                `${availableCount} Available`}
              {subtitle}
            </S.AvailableCount>
            {isClearing && (
              <StatusPill
                css={css`
                  margin-left: 16px;
                `}
                status={TStatusPillTypes.CLEARING}
                size="small"
              />
            )}
            {canDelete && index !== undefined && onDelete && !isClearing && (
              <S.Actions>
                <Button
                  plain
                  type="button"
                  aria-label="Remove"
                  onClick={onDelete}
                >
                  <TrashIcon />
                </Button>
              </S.Actions>
            )}
          </S.Header>
          <div
            css={
              isClearing &&
              css`
                opacity: 0.5;
              `
            }
          >
            {children}
          </div>
        </S.OnlyWhenExpanded>
      </S.Content>
    </S.Container>
  );
};
export default ScreensAccordion;
