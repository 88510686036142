/** @jsxImportSource @emotion/react */

import * as React from 'react';

import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import InvalidFilenameModal from 'components/common/invalid-filename-modal';
import { TransitDestination } from 'generated/global-types';
import * as S from './screen-uploader.styled';
import { ThemeType } from '../../../theme';
import {
  useUpload,
  UploadType,
  TransitSettings,
} from '../../../hooks/useUpload';
import { ReactComponent as UploadIcon } from '../../../images/icon_upload.svg';
import { ReactComponent as ZippedIcon } from '../../../images/icon_zipped.svg';
import { ReactComponent as EditIcon } from '../../../images/icon_edit_sm.svg';
import {
  validateFileDimensions,
  validateZipContent,
} from '../../../utils/validations';
import { isInvalidFilename } from '../../../utils/assets-helpers';

const MAX_IMAGE_SIZE_BYTES = 10 * 10 ** 6; // 10mb
const MAX_VIDEO_SIZE_BYTES = 50 * 10 ** 6; // 50mb

const validateFileSize = (file: File) => {
  if (file.type.startsWith('image/')) {
    return file.size <= MAX_IMAGE_SIZE_BYTES;
  }
  if (file.type.startsWith('video/')) {
    return file.size <= MAX_VIDEO_SIZE_BYTES;
  }

  return true; // No checking for zip files
};

const useValidatedDropzone = ({
  onDrop: handleDrop,
  height,
  width,
  onInvalidFilename: handleInvalidFilename,
  onlyAcceptImages = false,
}: {
  onDrop: (f: null | File) => void;
  height: number;
  width: number;
  onInvalidFilename: (filename: string) => void;
  onlyAcceptImages?: boolean;
}) => {
  const onDrop = React.useCallback(
    // eslint-disable-next-line consistent-return
    async (files) => {
      if (files[0]) {
        const filename = files[0].name;

        if (isInvalidFilename(filename)) {
          return handleInvalidFilename(filename);
        }

        if (!validateFileSize(files[0])) {
          // eslint-disable-next-line no-alert
          return window.alert('File is too large');
        }
        if (
          !(await validateFileDimensions(files[0], {
            minHeight: height,
            minWidth: width,
            maxHeight: height,
            maxWidth: width,
          }))
        ) {
          // eslint-disable-next-line no-alert
          return window.alert('File does not have the right dimensions');
        }

        if (!(await validateZipContent(files[0]))) {
          // eslint-disable-next-line no-alert
          return window.alert(
            'Invalid ZIP folder: missing template.json file or content is wrapped in a sub-folder',
          );
        }

        handleDrop(files[0]);
      }
    },
    [handleDrop, height, width, handleInvalidFilename],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: onlyAcceptImages
      ? 'image/jpeg, image/png'
      : 'image/jpeg, image/png, video/mp4, video/quicktime, application/zip, application/x-zip-compressed',
  });

  return { getRootProps, getInputProps };
};

interface AwaitingFileProps {
  id: number;
  height: number;
  width: number;
  startUpload: (f: null | File) => void;
  onInvalidFilename: (filename: string) => void;
  multiplier?: number;
  scale?: number;
  onlyAcceptImages?: boolean;
}
const AwaitingFile: React.FC<AwaitingFileProps> = ({
  id,
  startUpload,
  height,
  width,
  onInvalidFilename,
  multiplier,
  scale = 0.25,
  onlyAcceptImages = false,
}) => {
  const { getRootProps, getInputProps } = useValidatedDropzone({
    onDrop: startUpload,
    height,
    width,
    onInvalidFilename,
    onlyAcceptImages,
  });

  const fileInputId = `file__input_${id}`;
  return (
    <S.ScreenBox empty height={height} width={width} multiplier={multiplier}>
      <div
        css={(theme: ThemeType) => css`
          cursor: pointer;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: ${theme.colors['background-inset']};
        `}
        {...getRootProps()}
      >
        <input
          {...getInputProps({ id: fileInputId })}
          css={css`
            display: none;
          `}
        />
        <UploadIcon
          css={css`
            margin-bottom: calc(48px * ${scale});
          `}
          height={328 * scale}
          width={328 * scale}
        />
        <label // eslint-disable-line
          htmlFor={fileInputId}
          css={css`
            cursor: pointer;
            line-height: calc(4.6 * ${scale});
          `}
          onClick={(evt) => {
            evt.preventDefault();
          }}
        >
          <S.SmallText scale={scale} accent>
            Select file to upload
          </S.SmallText>
        </label>
      </div>
    </S.ScreenBox>
  );
};

const ProgressView: React.FC<{
  progress: number;
  height: number;
  width: number;
  multiplier?: number;
  children?: React.ReactNode;
}> = ({ progress, height, width, multiplier }) => (
  <S.ScreenBox empty height={height} width={width} multiplier={multiplier}>
    <S.SmallText
      css={(theme: ThemeType) => css`
        margin-bottom: ${theme.spacing.xsmall};
        ${theme.typography.sizes.xsmall}
      `}
    >
      Uploading...
    </S.SmallText>
    <progress
      max={100}
      value={progress}
      css={(theme: ThemeType) => css`
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 2px;
        border: none;
        height: 4px;
        background-color: ${theme.colors.light9};

        ::-webkit-progress-bar {
          background: ${theme.colors.light9};
          border-radius: 2px;
        }

        ::-webkit-progress-value {
          background: ${theme.colors.accent1};
          border-radius: 2px;
        }

        ::-moz-progress-bar {
          background: ${theme.colors.accent1};
          border-radius: 2px;
        }
      `}
    >
      {progress}%
    </progress>
  </S.ScreenBox>
);

const UploadedView: React.FC<
  {
    upload: UploadType;
    height: number;
    width: number;
    startUpload: (f: null | File) => void;
    multiplier?: number;
    scale?: number;
  } & { children?: React.ReactNode }
> = ({ upload, height, width, multiplier, scale = 0.25 }) => {
  const { mimeType } = upload;

  const contents = (() => {
    if (mimeType.startsWith('image/')) {
      return (
        <div
          role="img"
          css={css`
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(${upload.signedS3Url});
          `}
        />
      );
    }
    if (mimeType.startsWith('video/')) {
      return (
        <video
          controls
          autoPlay
          loop
          muted
          css={css`
            height: 100%;
            width: 100%;
          `}
          src={upload.signedS3Url || undefined}
        />
      );
    }

    if (mimeType === 'application/zip') {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
          `}
        >
          <S.SmallText
            css={(theme: ThemeType) => css`
              color: ${theme.colors.black};
            `}
            scale={scale}
          >
            Zipped File
          </S.SmallText>
          <ZippedIcon
            css={css`margin: calc(48px * ${scale}); 0;`}
            height={328 * scale}
            width={328 * scale}
          />
          <S.SmallText
            css={(theme: ThemeType) => css`
              color: ${theme.colors.black};
            `}
            scale={scale}
          >
            Preview Unavailable
          </S.SmallText>
        </div>
      );
    }

    return null;
  })();

  return (
    <S.ScreenBox
      height={height}
      width={width}
      multiplier={multiplier}
      css={(theme: ThemeType) => css`
        position: relative;
        ${mimeType === 'application/zip'
          ? css`
              background-color: ${theme.colors['background-inset']};
            `
          : ''}
      `}
    >
      {contents}
    </S.ScreenBox>
  );
};

interface Props {
  className?: string;
  header?: string | null;
  id: number;
  onChange: (id: number, u: UploadType | null) => void;
  height: number;
  width: number;
  defaultUpload?: UploadType;
  screenUploadsPerPage?: number;
  hide?: boolean;
  multiplier?: number;
  scale?: number;
  transitSettings?: TransitSettings;
  onlyAcceptImages?: boolean;
  isEditable?: boolean;
}

export const ScreenUploader: React.FC<Props> = ({
  className,
  header = null,
  id,
  onChange,
  defaultUpload = null,
  height,
  width,
  multiplier,
  screenUploadsPerPage = 0,
  hide = false,
  scale = 0.25,
  transitSettings = {
    shouldZip: false,
    transitDestination: TransitDestination.VLE,
  },
  onlyAcceptImages = false,
  isEditable = true,
}) => {
  const [invalidFilename, setInvalidFilename] = React.useState('');

  const { startUpload, uploading, progress, upload } = useUpload({
    defaultUpload,
    transitSettings,
  });

  React.useEffect(() => {
    onChange(id, upload);
  }, [id, onChange, upload]);

  const { getRootProps, getInputProps } = useValidatedDropzone({
    onDrop: startUpload,
    height,
    width,
    onInvalidFilename: setInvalidFilename,
    onlyAcceptImages,
  });

  const contents = (() => {
    if (uploading) {
      return (
        <ProgressView
          progress={progress}
          height={height}
          width={width}
          multiplier={multiplier}
        />
      );
    }
    if (upload) {
      return (
        <React.Fragment>
          <UploadedView
            startUpload={startUpload}
            upload={upload}
            height={height}
            width={width}
            multiplier={multiplier}
            scale={scale}
          />
          <S.ExtraInfo>
            <S.Filename>{(upload && upload.originalFilename) || ''}</S.Filename>
            {upload.mimeType === 'application/zip' && (
              <S.DownloadLink
                href={upload.signedS3Url || '#'}
                download={upload.originalFilename}
                target="_blank"
              >
                Download
              </S.DownloadLink>
            )}
          </S.ExtraInfo>
          {isEditable && (
            <button
              type="button"
              onClick={(evt) => {
                evt.stopPropagation();
                startUpload(null);
              }}
              css={css`
                text-align: left;
                margin-top: 4px;
                :focus {
                  outline: none;
                }
              `}
              {...getRootProps()}
            >
              <EditIcon
                css={(theme: ThemeType) => css`
                  color: ${theme.colors.accent1};
                `}
              />
              <S.SmallText
                css={(theme: ThemeType) => css`
                  color: ${theme.colors.accent1};
                  ${theme.typography.sizes.xsmall};
                  margin-left: 5px;
                `}
              >
                Replace File
              </S.SmallText>
            </button>
          )}
          <input
            css={css`
              display: none;
              visibility: hidden;
            `}
            {...getInputProps()}
          />
        </React.Fragment>
      );
    }
    return (
      <AwaitingFile
        id={id}
        startUpload={startUpload}
        height={height}
        width={width}
        multiplier={multiplier}
        onInvalidFilename={setInvalidFilename}
        scale={scale}
        onlyAcceptImages={onlyAcceptImages}
      />
    );
  })();

  // The `hide` styling is also part of the pagination logic where we're hiding elements instead of re-rendering it to keep previously uploaded assets.
  return (
    <S.Container
      className={className}
      css={css`
        ${id % screenUploadsPerPage !== 0 && 'margin-right:32px;'}
        ${hide && 'display: none;'}
      `}
      height={height}
      width={width}
      multiplier={multiplier}
    >
      {header !== null ? <S.Header>{header}</S.Header> : null}
      {contents}
      <InvalidFilenameModal
        isOpen={!!invalidFilename}
        onDismiss={() => setInvalidFilename('')}
        filename={invalidFilename}
      />
    </S.Container>
  );
};
