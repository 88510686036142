/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const TOP_OF_PAGE_ID = 'top-of-page';

export const TopKeyboardFocusElement = () => {
  return (
    <div
      id={TOP_OF_PAGE_ID}
      tabIndex={-1}
      aria-label="top of page"
      css={css`
        &:focus {
          outline: none;
        }
      `}
    />
  );
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    const topEl = document.getElementById(TOP_OF_PAGE_ID);

    if (topEl) {
      topEl.focus();
    }
  }, [pathname]);

  return null;
}
