import twitter from 'twitter-text';

export const TWEET_MAX_LENGTH = 280;

export const getTweetLength = (text: string): number => {
  return twitter.parseTweet(text).weightedLength;
};

export const isValidTweet = (
  text: string,
  maxLength: number = TWEET_MAX_LENGTH,
): boolean => {
  return getTweetLength(text) <= maxLength;
};
