/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ThemeType } from '../../theme';

const HR: React.FC<{ children?: React.ReactNode }> = (props) => (
  <hr
    css={(theme: ThemeType) => css`
      height: 0px;
      width: 100%;
      border: 0;
      border-top: 1px solid ${theme.colors.border};
      margin: 0;
    `}
    {...props}
  />
);

export default HR;
