/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import SkeletonLoader from './SkeletonLoader';

const TableSkeleton: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  return (
    <div
      css={(theme) => css`
        width: 100%;
        background-color: ${theme.colors.white};
        border-radius: 4px;
      `}
    >
      <SkeletonLoader height="440">
        <rect x="0" y="85" rx="0" ry="0" width="100%" height="5" />
        <rect x="0" y="175" rx="0" ry="0" width="100%" height="5" />
        <rect x="0" y="265" rx="0" ry="0" width="100%" height="5" />
        <rect x="0" y="355" rx="0" ry="0" width="100%" height="5" />
      </SkeletonLoader>
    </div>
  );
};

export default TableSkeleton;
