/** @jsxImportSource @emotion/react */

import * as React from 'react';
import useScreenLayoutCounts, {
  IUseScreenLayoutCountResult,
} from 'hooks/use-screen-layout-counts';
import StatusIcon from 'components/common/status-icon';
import { FeedId } from '@mta-live-media-manager/shared';

import { Shop, TargetType } from '../../../generated/global-types';
import {
  getLabelFromTargetType,
  getDimensionsFromTargetType,
  getIsCompatibleType,
  ALL_CIC,
} from '../../pages/Screen/in-screens-preview/helpers';
import { getGroupedCICsFromScreenLayoutCounts } from '../../../utils/screen-targeting';

import {
  buttons,
  availableCount,
  orientation,
  description as descriptionStyle,
  icon as iconStyle,
  container,
  containerSelected,
  containerDisabled,
  messageWrapper,
  loadingContainer,
} from './index.styled';

import { ReactComponent as IconSingleLandscape } from '../../../images/screens/detail/single_landscape.svg';
import { ReactComponent as IconSinglePortrait } from '../../../images/screens/detail/single_portrait.svg';

import { ReactComponent as IconSinglePortraitCic } from '../../../images/screens/detail/single_portrait_cic.svg';
import { ReactComponent as IconSingleLandscapeCic } from '../../../images/screens/detail/single_landscape_cic.svg';
import { ReactComponent as IconSinglePortraitAd } from '../../../images/screens/detail/single_portrait_ad.svg';
import { ReactComponent as IconSinglePortraitInfo } from '../../../images/screens/detail/single_portrait_info.svg';

import { ReactComponent as IconSingleLandscapeAd } from '../../../images/screens/detail/single_landscape_ad.svg';
import { ReactComponent as IconSingleLandscapeInfo } from '../../../images/screens/detail/single_landscape_info.svg';

import { ReactComponent as IconDup } from '../../../images/screens/detail/dup.svg';

import { ReactComponent as IconLeftOfDuoCic } from '../../../images/screens/detail/left_of_duo_cic.svg';
import { ReactComponent as IconRightOfDuoCic } from '../../../images/screens/detail/right_of_duo_cic.svg';

import { ReactComponent as IconTriptychAd } from '../../../images/screens/detail/triptych_ad.svg';

import { ReactComponent as IconSpectacularsAd } from '../../../images/screens/detail/spectaculars_ad.svg';
import { ReactComponent as IconSpectacularsAdSimple } from '../../../images/screens/detail/spectaculars_ad_simple.svg';
import { ReactComponent as IconSingleFultonPortrait } from '../../../images/screens/detail/single_fulton_portrait.svg';

import { ReactComponent as IconCovesOfFive } from '../../../images/screens/detail/coves_of_five.svg';
import { ReactComponent as IconCovesOfTwo } from '../../../images/screens/detail/coves_of_two.svg';
import { ReactComponent as IconSquare } from '../../../images/screens/detail/square.svg';

import ScreensAvailabilityCount from '../screens-availability-count';

export type Screens = TargetType;

type LabelAndDimensions = {
  setIndex: number;
  height: number;
  width: number;
  hide?: boolean;
};

// TODO: Remove this function and use the values from the database
export const getPositionalScreensWithDimensions = (
  t: TargetType | null,
  screenUploadsPerPage?: number,
  screenUploadsTotal?: number,
  screenUploadsPage?: number,
): LabelAndDimensions[] => {
  if (t === null) {
    return [];
  }

  const [width, height] = getDimensionsFromTargetType(t);
  switch (t) {
    case TargetType.DUP:
    case TargetType.SINGLE_MKT_LANDSCAPE:
    case TargetType.SINGLE_PIO_LANDSCAPE:
    case TargetType.SINGLE_STA_LANDSCAPE:
    case TargetType.SINGLE_AD_LANDSCAPE:
    case TargetType.SINGLE_AD_PORTRAIT:
    case TargetType.SINGLE_CIC:
    case TargetType.SINGLE_CIC_LANDSCAPE:
    case TargetType.SINGLE_INFO_LANDSCAPE:
    case TargetType.SINGLE_INFO_PORTRAIT:
    case TargetType.OTHER_MARKETING_LANDSCAPE:
    case TargetType.SOLARI_LANDSCAPE:
    case TargetType.SOLARI_MARKETING_LANDSCAPE:
    case TargetType.FULTON_PORTRAIT:
    case TargetType.SQUARE:
    case TargetType._3SM:
    case TargetType.SOLARI_PORTRAIT:
      return [
        {
          setIndex: 0,
          width,
          height,
        },
      ];

    case TargetType.DUO_CIC_LEFT:
      return [{ setIndex: 0, height, width }];
    case TargetType.DUO_CIC_RIGHT:
      return [{ setIndex: 1, height, width }];

    case TargetType.TRIPTYCH_AD: {
      const [width, height] = getDimensionsFromTargetType(t);
      return [
        { setIndex: 0, width, height },
        { setIndex: 1, width, height },
        { setIndex: 2, width, height },
      ];
    }

    case TargetType.COVES_OF_FIVE: {
      const [width, height] = getDimensionsFromTargetType(t);
      return [
        { setIndex: 0, width, height },
        { setIndex: 1, width, height },
        { setIndex: 2, width, height },
        { setIndex: 3, width, height },
        { setIndex: 4, width, height },
      ];
    }

    case TargetType.COVES_OF_TWO: {
      const [width, height] = getDimensionsFromTargetType(t);
      return [
        { setIndex: 0, width, height },
        { setIndex: 1, width, height },
      ];
    }

    case TargetType.SPECTACULAR_AD: {
      const positionalScreensResults: LabelAndDimensions[] = [];
      const screenUploadsPageNumber = Number(screenUploadsPage);
      const screenUploadsPerPageNumber = Number(screenUploadsPerPage);
      const screenUploadsTotalNumber = Number(screenUploadsTotal);

      for (let i = 1; i <= screenUploadsTotalNumber; i++) {
        /**
         * Since we don't want react to re-render screenUploads components while paginating,
         * (this would remove any previously added uploads before publishing).
         * We're building our own pagination logic by passing `hide` within the returned `positionsAndDimensions` values.
         *
         * `leftValidation` validates elements from the batch of values which are smaller than the ones in the current page
         * (this is not applicable in the first page) with respect to the pagination's postsPerPage
         *
         * `rightValidation` validates elements from the batch of values
         * which are greater than the ones in the current page with respect to the pagination's postsPerPage
         *
         */
        const leftValidation =
          i <= (screenUploadsPageNumber - 1) * screenUploadsPerPageNumber;

        const rightValidation =
          i > screenUploadsPageNumber * screenUploadsPerPageNumber;

        const hideForPagination =
          screenUploadsPageNumber === 1
            ? rightValidation
            : rightValidation || leftValidation;

        positionalScreensResults.push({
          hide: hideForPagination,
          setIndex: i,
          width,
          height,
        });
      }

      return positionalScreensResults;
    }

    default:
      return [];
  }
};

export const getIcon = (
  targetType: TargetType,
  isSimple: boolean = false,
): React.ReactNode => {
  switch (targetType) {
    case TargetType.SINGLE_CIC:
      return <IconSinglePortraitCic />;
    case TargetType.SINGLE_CIC_LANDSCAPE:
      return <IconSingleLandscapeCic />;
    case TargetType.SINGLE_AD_PORTRAIT:
      return <IconSinglePortraitAd />;
    case TargetType.SINGLE_INFO_PORTRAIT:
      return <IconSinglePortraitInfo />;
    case TargetType.SOLARI_PORTRAIT:
    case ALL_CIC:
      return <IconSinglePortrait />;

    case TargetType.SINGLE_AD_LANDSCAPE:
      return <IconSingleLandscapeAd />;
    case TargetType.SINGLE_INFO_LANDSCAPE:
      return <IconSingleLandscapeInfo />;
    case TargetType.DUP:
      return <IconDup />;
    case TargetType.SINGLE_MKT_LANDSCAPE:
    case TargetType.SINGLE_PIO_LANDSCAPE:
    case TargetType.SINGLE_STA_LANDSCAPE:
    case TargetType.OTHER_MARKETING_LANDSCAPE:
    case TargetType.SOLARI_LANDSCAPE:
    case TargetType.SOLARI_MARKETING_LANDSCAPE:
      return <IconSingleLandscape />;

    case TargetType.DUO_CIC_LEFT: {
      return isSimple ? <IconSinglePortraitCic /> : <IconLeftOfDuoCic />;
    }

    case TargetType.DUO_CIC_RIGHT:
      return isSimple ? <IconSinglePortraitCic /> : <IconRightOfDuoCic />;

    case TargetType.TRIPTYCH_AD:
      return <IconTriptychAd />;

    case TargetType.SPECTACULAR_AD:
      return isSimple ? <IconSpectacularsAdSimple /> : <IconSpectacularsAd />;

    case TargetType.FULTON_PORTRAIT:
      return <IconSingleFultonPortrait />;

    case TargetType.COVES_OF_FIVE:
      return <IconCovesOfFive />;

    case TargetType.COVES_OF_TWO:
      return <IconCovesOfTwo />;

    case TargetType.SQUARE:
      return <IconSquare />;

    default:
      return null;
  }
};

const ScreenLayoutButtonsForCurrentFeed: React.FC<
  {
    onSelect: (s: Screens, c: number, l: number) => void;
    noSelectorsEl: React.ReactNode;
    noScreensEl?: React.ReactNode;
    routeIds?: string[];
    selected?: Screens[];
    stopIds?: string[];
    feedId?: string;
    shops?: Shop[];
    excludedTargetTypes?: TargetType[];
  } & { children?: React.ReactNode }
> = ({
  onSelect,
  routeIds,
  selected,
  stopIds,
  noSelectorsEl,
  noScreensEl,
  feedId,
  shops,
  excludedTargetTypes,
}) => {
  const showNoSelectorsMessage =
    !routeIds?.length && !stopIds?.length && !shops?.length && !!noSelectorsEl;

  const { screenLayoutCounts, loading } = useScreenLayoutCounts({
    routeIds,
    stopIds,
    shops,
    excludedTargetTypes,
    isCampaign: true,
    shouldRemoveGroupedCICs: false,
  });

  const CICs = getGroupedCICsFromScreenLayoutCounts(screenLayoutCounts);

  if (loading) {
    return (
      <div css={loadingContainer}>
        <StatusIcon status="loading" size={20} />
      </div>
    );
  }

  const buttonEls = screenLayoutCounts
    .filter(({ targetType }) => !CICs.includes(targetType))
    .map((screenLayoutCount: IUseScreenLayoutCountResult) => {
      const { targetType, totalCount, lockedCount } = screenLayoutCount;
      const icon = getIcon(targetType);
      const description = getLabelFromTargetType(
        targetType,
        feedId as FeedId,
      ).replace(targetType === ALL_CIC ? ' Screens' : '', '');
      const [width, height] = getDimensionsFromTargetType(targetType);
      const isDisabled = !getIsCompatibleType(targetType, selected);
      const isSelected =
        (!isDisabled && selected?.includes(targetType)) ||
        (targetType === ALL_CIC && CICs.every((s) => selected?.includes(s)));

      const cssStyles = [container];
      if (isDisabled) {
        cssStyles.push(containerDisabled);
      } else if (isSelected) {
        cssStyles.push(containerSelected);
      }

      return (
        <button
          type="button"
          role="switch"
          aria-checked={isSelected}
          css={cssStyles}
          key={targetType}
          onClick={() => onSelect(targetType, totalCount, lockedCount)}
          disabled={isDisabled}
        >
          <div css={iconStyle}>{icon}</div>
          <span css={descriptionStyle}>{description}</span>
          <span css={orientation}>
            {width} × {height}px
          </span>
          <span css={availableCount}>
            <ScreensAvailabilityCount
              numLocked={lockedCount}
              numTargeted={totalCount}
            />
          </span>
        </button>
      );
    });

  return (
    <React.Fragment>
      <div css={buttons}>{!showNoSelectorsMessage && buttonEls}</div>
      {!showNoSelectorsMessage && !buttonEls?.length && (
        <div css={messageWrapper}>{noScreensEl}</div>
      )}
      {showNoSelectorsMessage && (
        <div css={messageWrapper}>{noSelectorsEl}</div>
      )}
    </React.Fragment>
  );
};

export default ScreenLayoutButtonsForCurrentFeed;
