/** @jsxImportSource @emotion/react */

import React from 'react';
import { ASSET_TYPES, ASSET_TYPE_KEY } from 'constants/assets';
import useQueryParams from 'hooks/useQueryParams';

import SocialMediaAssetCompose from './social-media-asset-compose';
import LockableAssetCompose from './lockable-asset-compose';

const AssetCompose: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const query = useQueryParams();
  const uploadType = query.get(ASSET_TYPE_KEY);

  if (uploadType === ASSET_TYPES.SOCIAL_MEDIA) {
    return <SocialMediaAssetCompose />;
  }

  return (
    <div style={{ width: '100%' }}>
      <LockableAssetCompose />
    </div>
  );
};

export default AssetCompose;
