/** @jsxImportSource @emotion/react */

const DuplicateIcon: React.FC<
  { width?: number; height?: number } & { children?: React.ReactNode }
> = ({ width = 17, height = 19 }) => {
  return (
    <svg width={width} height={height}>
      <g fill="#fff" stroke="#aaa" strokeWidth="1.5" fillRule="evenodd">
        <rect x="3.45" y=".75" width="12" height="14.7" rx="1.8" />
        <rect x=".75" y="3.45" width="12" height="14.7" rx="1.8" />
      </g>
    </svg>
  );
};

export default DuplicateIcon;
