import React from 'react';
import SkeletonLoader from './SkeletonLoader';

const HEIGHT = '8';

const Divider: React.FC<unknown & { children?: React.ReactNode }> = () => (
  <SkeletonLoader height={HEIGHT}>
    <rect x="0" y="0" rx="0" ry="0" width="100%" height={HEIGHT} />
  </SkeletonLoader>
);

export default Divider;
