/** @jsxImportSource @emotion/react */

import React from 'react';

import ReferrerRedirect from 'components/common/ReferrerRedirect';
import PageMeta from '../../common/PageMeta';
import useAuthentication from '../../../contexts/Authentication';
import useQueryParams from '../../../hooks/useQueryParams';

import { ReactComponent as MercuryLogo } from '../../../images/mercury_logo.svg';
import Alert, { isValidAlertType } from './Alert';
import * as S from './index.styled';

const Login: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const { login } = useAuthentication();
  const queryParams = useQueryParams();
  const alertType = queryParams.get('alertType');

  return (
    <ReferrerRedirect>
      <S.Login.Page>
        <PageMeta title="Login" baseTitle="MTA" />
        {isValidAlertType(alertType) && <Alert type={alertType} />}
        <S.Login.Box>
          <S.Login.LogoContainer>
            <MercuryLogo />
          </S.Login.LogoContainer>
          <S.Login.Welcome>Welcome to</S.Login.Welcome>
          <S.Login.Branding>Mercury</S.Login.Branding>
          <S.Login.Submit onClick={login}>Sign In</S.Login.Submit>
        </S.Login.Box>
      </S.Login.Page>
    </ReferrerRedirect>
  );
};

export default Login;
