/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import { loader } from 'graphql.macro';

import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, ApolloError } from '@apollo/client';

import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';

import { useFeedId } from 'contexts/FeedId';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { ScreenOrientation } from 'generated/global-types';
import { UploadType } from 'hooks/useUpload';
import * as Sentry from '@sentry/browser';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';
import {
  UpdateUpload,
  UpdateUploadVariables,
} from '../../../generated/UpdateUpload';
import {
  UpdateLockableAsset,
  UpdateLockableAssetVariables,
} from '../../../generated/UpdateLockableAssetLock';
import {
  GetLockableUploadById,
  GetLockableUploadByIdVariables,
} from '../../../generated/GetLockableUploadById';
import * as S from './index.styled';

import AssetForm, {
  FormValues as AssetFormValues,
  DefaultValues,
  FormTypes,
} from '../../common/asset-form';

const GetLockableUploadByIdQuery = loader(
  '../../../graphql/GetLockableUploadById.gql',
);
const UpdateLockableAssetMutation = loader(
  '../../../graphql/UpdateLockableAssetLock.gql',
);

const UpdateUploadMutation = loader('../../../graphql/UpdateUpload.gql');

const LockableAssetEdit: React.FC<
  unknown & { children?: React.ReactNode; scheduleEdit: boolean }
> = ({ scheduleEdit, children }) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const feedId = useFeedId();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { loading, data } = useQuery<
    GetLockableUploadById,
    GetLockableUploadByIdVariables
  >(GetLockableUploadByIdQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
      includeScheduled: false,
    },
  });

  const [updateUpload] = useMutation<UpdateUpload, UpdateUploadVariables>(
    UpdateUploadMutation,
    {
      onError: (error: ApolloError) => {
        throw error;
      },
    },
  );

  const [updateLockable] = useMutation<
    UpdateLockableAsset,
    UpdateLockableAssetVariables
  >(UpdateLockableAssetMutation, {
    onError: (error: ApolloError) => {
      throw error;
    },
  });

  const upload = data?.lockableUpload?.upload;
  const thumbnail = data?.lockableUpload?.thumbnail;

  const defaultValues: DefaultValues = {
    title: upload?.title ?? '',
    description: upload?.description ?? '',
    orientation: upload?.isLandscape
      ? ScreenOrientation.LANDSCAPE
      : ScreenOrientation.PORTRAIT,
    type: data?.lockableUpload?.screenType,
    upload: upload as UploadType,
    thumbnail: thumbnail as UploadType,
    width: upload?.width ?? undefined,
    height: upload?.height ?? undefined,
    screenTypes: data?.lockableUpload?.screens?.nodes || [],
    activeScreensCount: data?.lockableUpload?.activeScreensCount ?? 0,
  };

  const handleSubmit = () => async (fv: AssetFormValues) => {
    setIsSubmitting(true);
    try {
      const [{ data: uploadData }, uploadedThumbnail] = await Promise.all([
        updateUpload({
          variables: { ...fv, id: fv.upload?.id },
        }),
        fv?.thumbnail
          ? updateUpload({
              variables: {
                id: fv.thumbnail?.id,
                title: '',
                description: '',
                feedId,
              },
            })
          : null,
      ]);
      if (
        (fv.upload?.id !== upload?.id || fv.thumbnail?.id !== thumbnail?.id) &&
        fv.type
      ) {
        await updateLockable({
          variables: {
            uploadId: fv.upload?.id,
            oldUploadId: upload?.id,
            thumbnailId: uploadedThumbnail?.data?.updateUpload?.upload?.id,
            oldThumbnailId: thumbnail?.id,
            screenType: fv.type,
          },
        });
      }

      history.push(`/${feedId}/assets/${uploadData?.updateUpload?.upload?.id}`);
    } catch (e) {
      Sentry.captureException(e);
    }
    setIsSubmitting(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <PageMeta title="Edit Locked Asset" />
      <BackOnlyPageHeading
        back={{ to: `/${feedId}/assets/locked`, title: 'Back to locked' }}
      />
      <Loader loading={loading}>
        <AssetForm
          feedId={feedId}
          heading="Edit Locked Screen Asset"
          defaultValues={defaultValues}
          formType={FormTypes.Lockable}
        >
          {/* @ts-ignore */}
          {({ makeSubmitHandler, form }) => {
            return (
              <React.Fragment>
                {form}

                {!scheduleEdit && (
                  <S.Buttons
                    css={css`
                      margin: 16px 32px;
                    `}
                  >
                    <Button
                      primary
                      type="button"
                      disabled={isSubmitting}
                      onClick={makeSubmitHandler(handleSubmit())}
                    >
                      Update
                    </Button>
                    <Button
                      type="button"
                      disabled={false}
                      onClick={() => {
                        if (
                          window.confirm(
                            `${DISCARD_CHANGES_MESSAGE} This asset will not be saved.`,
                          )
                        ) {
                          history.push(`/${feedId}/assets/locked`);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </S.Buttons>
                )}
              </React.Fragment>
            );
          }}
        </AssetForm>
        {children}
        {scheduleEdit && (
          <AssetForm
            defaultValues={defaultValues}
            formType={FormTypes.Lockable}
            feedId={feedId}
            heading="Edit Scheduled Locked Screen"
          >
            {({ makeSubmitHandler, form }) => {
              return (
                <React.Fragment>
                  {form}

                  <S.Buttons
                    css={css`
                      margin: 16px 32px;
                    `}
                  >
                    <Button
                      primary
                      type="button"
                      disabled={isSubmitting}
                      onClick={makeSubmitHandler(handleSubmit())}
                    >
                      Update
                    </Button>
                    <Button
                      type="button"
                      disabled={false}
                      onClick={() => {
                        if (
                          window.confirm(
                            `${DISCARD_CHANGES_MESSAGE} This asset will not be saved.`,
                          )
                        ) {
                          history.push(`/${feedId}/assets/locked`);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </S.Buttons>
                </React.Fragment>
              );
            }}
          </AssetForm>
        )}
      </Loader>
    </div>
  );
};

export default LockableAssetEdit;
