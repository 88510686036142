/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import {
  EditorState,
  prosemirrorNodeToHtml as toHTML,
  schemaToJSON,
} from 'remirror';
import {
  formatMessageDoc,
  LONG_GTFS_TEXT_REPLACEMENTS,
} from '@mta-live-media-manager/shared';
import { FeedId } from 'types/feeds';

import Button from '../../../common/Button';
import { ReactComponent as Cross } from '../../../../images/cross-blue.svg';
import { StopSelector as StopSelectorType } from '../types';

import StopSelector from '../stop-selector';
import {
  StationAlternativeInput,
  ContextualSelectorInput,
} from '../../../../generated/global-types';
import Editor, {
  getMentionsByType,
} from '../../../common/form-elements/editor';

import * as styles from './station-alternatives-editor.styles';

const StationAlternativesEditor: React.FC<{
  value: StationAlternativeInput[];
  contextualSelector: ContextualSelectorInput | null | undefined;
  notes: any;
  index: number;
  removedCount: number;
  setRemovedCount: Function;
  onChange: Function;
}> = ({
  value,
  contextualSelector,
  notes,
  index,
  removedCount,
  setRemovedCount,
  onChange,
}) => {
  const wrapProseMirrorContent = (content: EditorState) => ({
    doc: content.doc.toJSON(),
    schema: schemaToJSON(content.schema),
    html: toHTML(content.doc),
    text: formatMessageDoc(content.doc, {
      bracketRoutes: false,
      enforceSpacesBetweenEntities: true,
      replacements: LONG_GTFS_TEXT_REPLACEMENTS,
    }),
    mentions: JSON.stringify(getMentionsByType(content)),
  });

  const [localNotes, setLocalNotes] = useState<EditorState | undefined>();

  return (
    <div
      key={`${contextualSelector}${index}${removedCount}`}
      css={styles.container}
    >
      <div className="flexSection">
        <label htmlFor={`routes${index}`}>Select Line &amp; Station</label>
        <StopSelector
          css={styles.leftField}
          value={{
            routeGtfsId: contextualSelector?.context.gtfsRouteId,
            stopGtfsId: contextualSelector?.entitySelector?.stopId,
          }}
          onChange={({
            routeGtfsId: newRouteId,
            stopGtfsId: newStopId,
          }: StopSelectorType) => {
            const newValue = {
              contextualSelector: {
                entitySelector: {
                  stopId: newStopId,
                },
                context: {
                  gtfsRouteId: newRouteId,
                },
              },
              notes,
            };

            onChange(value.map((v, i) => (i === index ? newValue : v)));
          }}
        />
      </div>
      <div className="flexSection">
        <p className="labelText">Station Alternative</p>
        <Editor
          id={`station-alternative-${index}`}
          stationAlternativeEditor
          placeholder="Type in shuttle bus or other information"
          initialContent={notes?.html}
          css={styles.editorField}
          value={localNotes}
          richEditing
          richEditingMenu={false}
          onChange={({ state }) => {
            const stateContent = state ? wrapProseMirrorContent(state) : null;
            if (stateContent) {
              const newValue = {
                contextualSelector,
                notes: stateContent,
              };
              if (value[index]?.notes?.html !== stateContent?.html) {
                onChange(value.map((v, i) => (i === index ? newValue : v)));
              }
            }
          }}
          onStateChange={({ state }) => {
            setLocalNotes(state);
          }}
          altFeedsMentionable={[
            FeedId.NYCTSubway,
            FeedId.NYCTBus,
            FeedId.LIRR,
            FeedId.MNR,
            FeedId.BT,
          ]}
        />
      </div>
      <Button
        type="button"
        plain
        onClick={() => {
          setRemovedCount(removedCount + 1);
          onChange(value.filter((_value, i) => i !== index));
        }}
        css={styles.removeBtn}
      >
        <Cross height="10" width="10" />
        {/* <VisuallyHidden>Remove</VisuallyHidden> */}
      </Button>
    </div>
  );
};

export default StationAlternativesEditor;
