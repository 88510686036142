/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { loader } from 'graphql.macro';
import ms from 'ms.macro';
import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ThemeType } from 'theme';
import Button from 'components/common/Button';
import InternalNotes from 'components/pages/planned-work/internal-notes';

import {
  GetPlannedWorkById,
  GetPlannedWorkByIdVariables,
} from 'generated/GetPlannedWorkById';
import {
  UpdatePlannedWorkNotes,
  UpdatePlannedWorkNotesVariables,
} from 'generated/UpdatePlannedWorkNotes';

const GetPlannedWorkByIdQuery = loader(
  '../../../graphql/GetPlannedWorkById.gql',
);
const UpdatePlannedWorkNotesMutation = loader(
  '../../../graphql/UpdatePlannedWorkNotes.gql',
);

const wait = (n: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise<void>((r) => window.setTimeout(() => r(), n));
const useMounted = () => {
  const mountedRef = React.useRef<boolean>(false);
  React.useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  return mountedRef;
};

type State = {
  servicePlanNumbers: string[];
  generalOrderNumbers: string[];
  internalNotes: string;
};

const useInternalNotes = ({ plannedWorkId: id }: { plannedWorkId: number }) => {
  const [dirty, setDirty] = React.useState(false);
  const [defaultsSet, setDefaultsSet] = React.useState(false);
  const { data } = useQuery<GetPlannedWorkById, GetPlannedWorkByIdVariables>(
    GetPlannedWorkByIdQuery,
    { variables: { id }, fetchPolicy: 'network-only' },
  );
  const [updatePlannedWorkNotes] = useMutation<
    UpdatePlannedWorkNotes,
    UpdatePlannedWorkNotesVariables
  >(UpdatePlannedWorkNotesMutation, {
    onCompleted: () => {
      setDirty(false);
    },
  });

  const [{ servicePlanNumbers, internalNotes, generalOrderNumbers }, dispatch] =
    React.useReducer<React.Reducer<State, any>>(
      (state, action) => ({
        ...state,
        [action.key]: action.value,
      }),
      {
        servicePlanNumbers: [],
        internalNotes: '',
        generalOrderNumbers: [],
      },
    );

  const updateValue: React.ComponentProps<typeof InternalNotes>['updateValue'] =
    React.useCallback(
      (key) => (value) => {
        setDirty(true);
        dispatch({ key, value });
      },
      [setDirty, dispatch],
    );

  React.useEffect(() => {
    if (data?.plannedWork && !defaultsSet) {
      if (data?.plannedWork?.servicePlanNumbers !== servicePlanNumbers) {
        updateValue('servicePlanNumbers')(
          data?.plannedWork?.servicePlanNumbers ?? servicePlanNumbers,
        );
      }
      if (data?.plannedWork?.generalOrderNumbers !== generalOrderNumbers) {
        updateValue('generalOrderNumbers')(
          data?.plannedWork?.generalOrderNumbers ?? generalOrderNumbers,
        );
      }
      if (data?.plannedWork?.internalNotes !== internalNotes) {
        updateValue('internalNotes')(
          data?.plannedWork?.internalNotes ?? internalNotes,
        );
      }
      setDefaultsSet(true);
      setDirty(false);
    }
  }, [
    defaultsSet,
    updateValue,
    servicePlanNumbers,
    internalNotes,
    generalOrderNumbers,
    data,
  ]);

  const save = React.useCallback(
    () =>
      updatePlannedWorkNotes({
        variables: {
          id,
          patch: {
            servicePlanNumbers,
            generalOrderNumbers,
            internalNotes,
          },
        },
      }),
    [
      id,
      servicePlanNumbers,
      internalNotes,
      generalOrderNumbers,
      updatePlannedWorkNotes,
    ],
  );

  return {
    dirty,
    generalOrderNumbers,
    internalNotes,
    save,
    servicePlanNumbers,
    updateValue,
  };
};

const EditableInternalNotes: React.FC<{
  plannedWorkId: number;
  initIsOpen: boolean;
  children?: React.ReactNode;
}> = ({ plannedWorkId, initIsOpen = true }) => {
  const mounted = useMounted();
  const [error, setError] = React.useState<null | string>(null);
  const [buttonText, setButtonText] = React.useState('Save');

  const [showInternalNotes, setShowInternalNotes] = React.useState(initIsOpen);

  const {
    dirty,
    generalOrderNumbers,
    internalNotes,
    save,
    servicePlanNumbers,
    updateValue,
  } = useInternalNotes({ plannedWorkId });

  const handleSubmit = React.useCallback(
    async (evt) => {
      evt.preventDefault();
      setButtonText('Saving...');
      try {
        await save();
        if (mounted.current) {
          setButtonText('Saved');
        }
        await wait(ms('3 seconds'));
      } catch (e) {
        if (mounted.current) {
          setError('Could not save. Please try again.');
        }
      } finally {
        if (mounted.current) {
          setButtonText('Save');
        }
      }
    },
    [save, mounted],
  );

  return (
    <React.Fragment>
      <Prompt
        when={dirty}
        message="You have unsaved changes to internal notes. Are you sure you want to leave and lose those changes?"
      />
      <form onSubmit={handleSubmit}>
        <InternalNotes
          servicePlanNumbers={servicePlanNumbers}
          internalNotes={internalNotes}
          generalOrderNumbers={generalOrderNumbers}
          updateValue={updateValue}
          onClick={() => setShowInternalNotes(!showInternalNotes)}
          isOpen={showInternalNotes}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row-reverse;
              margin-top: 16px;
            `}
          >
            <Button
              css={css`
                padding: 14px 8px;
              `}
              size="xsmall"
              type="submit"
            >
              {buttonText}
            </Button>
            {error && (
              <p
                css={(theme: ThemeType) => css`
                  color: ${theme.colors['status-error']};
                  padding: 0;
                  margin-right: 8px;
                `}
              >
                {error}
              </p>
            )}
          </div>
        </InternalNotes>
      </form>
    </React.Fragment>
  );
};

export default EditableInternalNotes;
