/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useState, useEffect, useRef } from 'react';
import addToDate from 'date-fns/add';
import TextareaAutosize from 'react-textarea-autosize';

import theme from 'theme';

import Heading from 'components/common/Heading';
import Bullet, { BulletSize } from 'components/common/Bullet';
import Button from 'components/common/Button';
import { filterInput } from 'components/common/styles';
import DonutChart from 'components/common/donut-chart';
import WeightPriorityPill from 'components/common/weight-priority-pill';
import { SchedulePriority } from 'generated/global-types';

import { useFeedId } from '../../../contexts/FeedId';
import { isSubway } from '../../../utils/feed-switches';

import { ReactComponent as IconNewtab } from '../../../images/icon_newtab_blue.svg';

// Pulling this into a hook prevents new objects from being created and causing an infinite render loop
export const useNext24hrs = () => {
  const [start] = useState(new Date().toISOString());
  const [end] = useState(addToDate(new Date(), { hours: 24 }).toISOString());

  return { start, end };
};

export const ScreenPageHeader: React.FC<{
  name: string;
  routeIds?: string[];
  stopName?: string;
  children?: React.ReactNode;
}> = ({ name, stopName, routeIds = [] }) => {
  const feedId = useFeedId();

  return (
    <div
      css={css`
        border-radius: 8px 8px 0 0;
        background-color: white;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 40px 40px 32px 40px;
        `}
      >
        <Heading
          level={1}
          css={css`
            margin-right: 16px;
          `}
        >
          {name}
        </Heading>
        <Heading
          level={2}
          css={css`
            line-height: ${isSubway(feedId) ? '1.875' : '0.75'};
            margin-right: 16px;
          `}
        >
          {stopName}
        </Heading>
        <div>
          {routeIds
            ? routeIds.map((id) => (
                <span
                  key={id}
                  css={css`
                    display: inline-block;
                    vertical-align: middle;
                    height: 20px;
                    margin-left: 4px;
                    margin-bottom: 4px;
                    transform: translate(0, -4px);
                  `}
                >
                  <Bullet routeId={id} size={BulletSize.medium} />
                </span>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export const BlueprintButton: React.FC<{ href: string }> = ({ href }) => {
  return (
    <Button
      css={css`
        width: 265px;
        padding: 5px 15px;
        margin: 24px 12px 0 0;
        display: inline-block;
      `}
      as="a"
      target="_blank"
      rel="noopener"
      href={href}
      size="medium"
    >
      Open Station Blueprint{' '}
      <IconNewtab
        css={css`
          margin-left: 8px;
          transform: scale(1.3);
        `}
      />
    </Button>
  );
};

export enum NoteSaveState {
  none = 'none',
  saving = 'saving',
  success = 'success',
  error = 'error',
}

export const InternalNotesEditor: React.FC<
  {
    onChange: (text: string) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    defaultValue?: string;
    state?: NoteSaveState;
    value?: string | null;
  } & { children?: React.ReactNode }
> = ({
  onChange,
  onSubmit,
  state = NoteSaveState.none,
  value = '',
  defaultValue,
}) => {
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (defaultValue) {
      onChangeRef.current(defaultValue);
    }
  }, [defaultValue, onChangeRef]);

  return (
    <form onSubmit={onSubmit}>
      <label htmlFor="internal-notes" style={{ fontWeight: 'bold' }}>
        Internal Notes
      </label>
      <TextareaAutosize
        id="internal-notes"
        name="notes"
        placeholder="Enter notes"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        rows={8}
        css={css`
          ${filterInput}
          margin: 12px 0;
          min-height: 244px;
          color: ${theme.colors.black};
          outline: none;
          box-shadow: inset 0 0 4px ${theme.colors['border-dark']};

          &:focus {
            border: 1px solid ${theme.colors.accent3};
            box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
          }
        `}
      />
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Button
          size="medium"
          disabled={state === NoteSaveState.saving}
          css={css`
            height: 40px;
          `}
        >
          {state === NoteSaveState.none && 'Save Notes'}
          {state === NoteSaveState.saving && 'Saving…'}
          {state === NoteSaveState.success && 'Saved!'}
          {state === NoteSaveState.error && 'Error!'}
        </Button>
      </div>
    </form>
  );
};

export const PageWrapper: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => {
  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        flex-direction: column;
      `}
      {...props}
    />
  );
};

export const ContentWrapper: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => {
  return (
    <div
      css={css`
        border-radius: 8px;
        box-shadow:
          0 1px 1px 0 rgba(0, 0, 0, 0.14),
          0 1px 3px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        margin: ${theme.spacing.large};
        margin-top: 0px;
      `}
      {...props}
    />
  );
};

export const ContentTypeLabel: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => {
  return (
    <span
      css={css`
        text-transform: uppercase;
        background: #dddddd;
        padding: 4px 8px;
        margin-right: 12px;
        font-weight: ${theme.typography.weights.bolder};
        ${theme.typography.sizes.xsmall}
      `}
      {...props}
    />
  );
};

export const WeightPropsContainer: React.FC<
  {
    statusPill: React.ReactNode;
    weight: number;
    priority: SchedulePriority;
    timeOnScreenPercentage: number;
  } & { children?: React.ReactNode }
> = ({ statusPill, weight, priority, timeOnScreenPercentage }) => {
  return (
    <div>
      <div
        css={css`
          min-width: 120px;
          display: flex;
          justify-content: center;
        `}
      >
        {statusPill}
        <WeightPriorityPill
          priority={priority}
          weight={weight}
          additionalCss={css`
            margin-left: 8px;
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 24px;
        `}
      >
        <DonutChart percentage={Math.floor(timeOnScreenPercentage)} />
      </div>
    </div>
  );
};
