/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';

import Button from '../Button';
import Modal from '../modal';

import CampaignScreenPreview from '../../pages/Screen/in-screens-preview/in-screen-preview';
import Pagination from '../Pagination';

import { TargetType } from '../../../generated/global-types';
import { UploadType } from '../../../hooks/useUpload';

export const ScreenSpectacularsFooter: React.FC<
  {
    uploads: UploadType | {};
    uploadsAvailable: boolean;
    screenUploadsTotal: number;
    screenUploadsPage: number;
    setScreenUploadsPage: Function;
    screenUploadsPerPage: number;
  } & { children?: React.ReactNode }
> = ({
  uploads,
  uploadsAvailable,
  screenUploadsTotal,
  screenUploadsPage,
  setScreenUploadsPage,
  screenUploadsPerPage,
}) => {
  const [viewAllScreensOpen, setViewAllScreensOpen] = useState<boolean>(false);

  return (
    <div
      css={css`
        margin-top: 24px;
        display: flex;
        justify-content: ${uploadsAvailable ? 'space-between' : 'flex-end'};
      `}
    >
      {uploadsAvailable && (
        <React.Fragment>
          <Button
            css={(theme: ThemeType) => css`
              ${theme.typography.sizes.small};
            `}
            type="button"
            size="medium"
            onClick={() => {
              setViewAllScreensOpen(true);
            }}
          >
            View All Screens
          </Button>
          <Modal
            isOpen={viewAllScreensOpen}
            title="View All Screens"
            onDismiss={() => setViewAllScreensOpen(false)}
            css={css`
              max-width: 1050px;
              width: ${6.73 *
              screenUploadsTotal}vw; // 6.73 was calculated using the 0.075 screen scale
              margin: 19vh auto;
            `}
            restOnContent
          >
            <div
              css={css`
                display: flex;
                justify-content: space-around;
                margin-top: 32px;
              `}
            >
              {uploads &&
                Object.values(uploads).map((asset: any, idx: number) => (
                  <div
                    key={asset?.id}
                    css={css`
                      ${idx !== screenUploadsTotal - 1 && 'margin-right: 8px'};
                    `}
                  >
                    <CampaignScreenPreview
                      key={asset?.id}
                      targetType={TargetType.SPECTACULAR_AD}
                      setIndex={idx}
                      totalScreens={screenUploadsTotal}
                      upload={asset}
                      scale={0.075}
                      simple
                      showCount
                    />
                  </div>
                ))}
            </div>
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <Button
                css={css`
                  width: 160px;
                  margin-top: 56px;
                `}
                primary
                size="medium"
                onClick={() => setViewAllScreensOpen(false)}
              >
                Close
              </Button>
            </div>
          </Modal>
        </React.Fragment>
      )}
      <Pagination
        currentPage={screenUploadsPage}
        itemsPerPage={screenUploadsPerPage}
        totalItems={screenUploadsTotal}
        onNextClick={() => setScreenUploadsPage(screenUploadsPage + 1)}
        onPrevClick={() => setScreenUploadsPage(screenUploadsPage - 1)}
      />
    </div>
  );
};
