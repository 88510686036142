/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import pluralize from 'pluralize';
import React, { useState, PropsWithChildren as PWC } from 'react';
import { TargetType } from 'generated/global-types';
import useScreenLayoutCounts from 'hooks/use-screen-layout-counts';
import { RouteMention } from 'types';
import theme from 'theme';

import { BaseSelector } from '../form-elements/multi-route-stop-selector';
import { IScreenSelector } from '../form-elements/multi-route-stop-screen-selector';
import CustomTagsSelector from '../form-elements/custom-tags-selector';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import Button from '../Button';

const Selector: React.FC<
  PWC<{
    isRemovable: boolean;
    screenSelector: IScreenSelector;
    disabledRoutes: RouteMention[];
    areLabelsHidden: boolean;
    isSingleRoute: boolean;
    onChange: (selector: IScreenSelector) => void;
    onRemove: () => void;
    enableSelectAllAction?: boolean;
    areAllSelected?: boolean;
    noLinesSelected?: boolean;
    onSelectAll?: () => void;
    onUnselectAll?: () => void;
  }>
> = ({
  isRemovable,
  screenSelector,
  disabledRoutes,
  areLabelsHidden,
  isSingleRoute,
  onChange,
  onRemove,
  enableSelectAllAction,
  areAllSelected = false,
  noLinesSelected = false,
  onSelectAll,
  onUnselectAll,
}) => {
  const { selector } = screenSelector;
  const { screenLayoutCounts } = useScreenLayoutCounts({
    stopIds: selector.stops.map((stop) => stop.stopId),
    routeIds: selector.routes.map((route) => route.routeId),
    targetTypes: [TargetType.DUP],
    specificTargetTypes: [TargetType.DUP],
  });

  return (
    <BaseSelector
      selector={selector}
      removeable={isRemovable}
      disabledRoutes={disabledRoutes}
      hideLabels={areLabelsHidden}
      onChange={(selector) => onChange({ ...screenSelector, selector })}
      onRemove={() => onRemove()}
      showScreensAvailable
      isSingleRoute={isSingleRoute}
      enableSelectAllAction={enableSelectAllAction}
      areAllSelected={areAllSelected}
      noLinesSelected={noLinesSelected}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 44px;
          padding: 0px 36px 0px 28px;
          border-top: 1px solid #dddddd;
        `}
      >
        <span
          css={css`
            display: flex;
          `}
        >
          Sidewalk
        </span>
        <span
          css={css`
            display: flex;
            ${theme.typography.sizes.small};
            color: ${theme.colors.dark3};
          `}
        >
          {screenLayoutCounts[0]?.totalCount ?? 0}{' '}
          {pluralize('Screen', screenLayoutCounts[0]?.totalCount ?? 0)}
        </span>
      </div>
      <CustomTagsSelector
        value={screenSelector.tags}
        onChange={(tags) => onChange({ ...screenSelector, tags })}
      />
    </BaseSelector>
  );
};

const SidewalkScreenSelector: React.FC<
  PWC<{
    onChange: (selectors: IScreenSelector[]) => void;
    value: IScreenSelector[];
    isSingleRoute?: boolean;
    enableSelectAllAction?: boolean;
    areAllSelected?: boolean;
    noLinesSelected?: boolean;
    onSelectAll?: () => void;
    onUnselectAll?: () => void;
  }>
> = ({
  onChange,
  value,
  isSingleRoute = false,
  enableSelectAllAction = false,
  areAllSelected = false,
  noLinesSelected = false,
  onSelectAll = () => {},
  onUnselectAll = () => {},
}) => {
  const [numRemoved, setNumRemoved] = useState(0);

  const selectorEls = value.map((screenSelector, idx) => {
    const key = `sidewalk-selector-${idx}-${numRemoved}`;
    const areLabelsHidden = idx > 0;
    const otherSelectors: IScreenSelector[] = value.filter((_, i) => i !== idx);
    const disabledRoutes: RouteMention[] = otherSelectors.flatMap(
      ({ selector: { routes } }) => routes,
    );

    otherSelectors.forEach(({ selector: { routes } }) => {
      routes.forEach((r) => {
        if (!disabledRoutes.find((dr) => dr.routeId === r.routeId)) {
          disabledRoutes.push(r);
        }
      });
    });

    return (
      <Selector
        key={key}
        screenSelector={screenSelector}
        isRemovable={value.length > 1}
        disabledRoutes={disabledRoutes}
        areLabelsHidden={areLabelsHidden}
        isSingleRoute={isSingleRoute}
        onChange={(screenSelector) =>
          onChange([
            ...value.slice(0, idx),
            screenSelector,
            ...value.slice(idx + 1),
          ])
        }
        onRemove={() => {
          setNumRemoved((prevNumRemoved) => prevNumRemoved + 1);
          onChange([...value.slice(0, idx), ...value.slice(idx + 1)]);
        }}
        enableSelectAllAction={enableSelectAllAction}
        areAllSelected={areAllSelected}
        noLinesSelected={noLinesSelected}
        onSelectAll={onSelectAll}
        onUnselectAll={onUnselectAll}
      />
    );
  });

  return (
    <div
      css={css`
        width: 62%;
      `}
    >
      {selectorEls}
      <Button
        plain
        type="button"
        onClick={() => {
          onChange([
            ...value,
            {
              selector: { routes: [], stops: [] },
              targetTypes: [TargetType.DUP],
              tags: undefined,
            },
          ]);
        }}
      >
        <PlusIcon /> Add Line
      </Button>
    </div>
  );
};

export default SidewalkScreenSelector;
