/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import * as Sentry from '@sentry/browser';
import { useFeedId } from 'contexts/FeedId';
import { useParams, useHistory } from 'react-router-dom';
import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import formatDate from 'date-fns/format';

import { useQuery, useMutation } from '@apollo/client';
import { DATE_FORMAT } from 'constants/time';
import StatusPill, { TStatusPillTypes } from 'components/common/status-pill';
import {
  FeatureFlagName,
  ScreenType,
  UpdateScheduledAssetInput,
} from 'generated/global-types';
import * as Well from '../../common/well';
import Loader from '../../common/skeletons/PageWithContent';
import { LockedAssetScreenPreview } from '../Screen/in-screens-preview/in-screen-preview';

import * as S from './index.styled';

import {
  GetLockableUploadById,
  GetLockableUploadByIdVariables,
  GetLockableUploadById_lockableUpload_LockableUpload_upload_Upload as UploadAsset,
  GetLockableUploadById_lockableUpload_LockableUpload_screens_LockableUploadsScreensConnection_nodes_LockableUploadsScreen as LockableUploadScreen,
  GetLockableUploadById_lockableUpload_LockableUpload_thumbnail_Upload as ThumbnailAsset,
} from '../../../generated/GetLockableUploadById';
import {
  DeleteLockableUpload,
  DeleteLockableUploadVariables,
} from '../../../generated/DeleteLockableUpload';
import TargetedScreens from '../../common/asset-form/targeted-screens';
import LockableAssetManagement from './lockable-asset-management';

import { UpdateScheduledAssetVariables } from 'generated/UpdateScheduledAsset';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { getAssetStatus, ScheduleDuration } from '../screen-assets/util';

const GetLockableUploadByIdQuery = loader(
  '../../../graphql/GetLockableUploadById.gql',
);

const DeleteLockableUploadMutation = loader(
  '../../../graphql/DeleteLockableUpload.gql',
);

const UpdateScheduledAsset = loader(
  '../../../graphql/UpdateScheduledAsset.gql',
);

type ILockableAssetViewContainerProps = {
  author?: string;
  screenType?: ScreenType;
  uploadAsset?: UploadAsset | null;
  loading: boolean;
  targetedScreens?: LockableUploadScreen[];
  buttons?: React.ReactNode;
  thumbnailAsset?: ThumbnailAsset | null;
  children?: React.ReactNode;
  Duration?: React.ComponentType<{}>;
  pageTitle: string;
  status?: TStatusPillTypes;
};

export const LockableAssetViewContainer: React.FC<
  unknown & ILockableAssetViewContainerProps
> = ({
  thumbnailAsset,
  buttons,
  loading,
  uploadAsset,
  screenType,
  author,
  targetedScreens,
  children,
  Duration,
  pageTitle,
  status,
}) => {
  return (
    <Loader loading={loading}>
      <Well.Section>
        <S.Header>
          <S.Info>
            <S.Title>
              {uploadAsset?.title ?? 'Asset Upload'}
              {status && !loading && (
                <StatusPill
                  css={css`
                    margin-left: 8px;
                  `}
                  status={status}
                  size="small"
                />
              )}
            </S.Title>
            <S.EditedLast>
              {uploadAsset?.updatedAt && (
                <React.Fragment>
                  <strong>Last Updated:</strong>{' '}
                  {formatDate(new Date(uploadAsset?.updatedAt), DATE_FORMAT)}
                  {author && ` by ${author}`}
                </React.Fragment>
              )}
            </S.EditedLast>
          </S.Info>
          {buttons && <S.Buttons>{buttons}</S.Buttons>}
        </S.Header>
      </Well.Section>
      <Well.Section
        css={css`
          .sched-desc {
            margin-bottom: 32px;
          }
        `}
      >
        <S.Title>{pageTitle}</S.Title>
        <S.Label>Description</S.Label>
        <S.Description className="sched-desc">
          {uploadAsset?.description}
        </S.Description>
        {Duration && <Duration />}
        {targetedScreens &&
          targetedScreens.map(
            (screen) =>
              screen.type && (
                <TargetedScreens
                  hideDetails
                  key={screen.type}
                  type={screen.type}
                />
              ),
          )}
        <div
          css={css`
            display: grid;
            grid-auto-flow: column;
            grid-gap: 12px;
          `}
        >
          {uploadAsset && (
            <LockedAssetScreenPreview
              key={uploadAsset?.id}
              upload={uploadAsset}
              thumbnail={thumbnailAsset}
              scale={screenType === ScreenType.DUP ? 0.55 : undefined}
              zipContentsScale={
                screenType === ScreenType.DUP ? 0.17 : undefined
              }
            />
          )}
        </div>
      </Well.Section>
      {children}
    </Loader>
  );
};

const LockableAssetView = () => {
  const feedId = useFeedId();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [now, setCurrentTime] = useState(new Date());
  const [activeUploadId, setActiveUploadId] = useState();
  const scheduledAssetsEnabled = useFeatureFlag(
    FeatureFlagName.SCHEDULED_ASSETS,
  );

  const [clearScheduledAsset] = useMutation<
    UpdateScheduledAssetInput,
    UpdateScheduledAssetVariables
  >(UpdateScheduledAsset, {
    onError(error) {
      Sentry.captureException(error);
    },
    onCompleted: () => {
      // forces a refetch of `GetLockableUploadByIdQuery`
      setCurrentTime(new Date());
    },
  });

  const [deleteLockableUploadMutation, { loading: isDeleteUploadLoading }] =
    useMutation<DeleteLockableUpload, DeleteLockableUploadVariables>(
      DeleteLockableUploadMutation,
      {
        variables: { uploadId: id },
      },
    );

  const { loading, data } = useQuery<
    GetLockableUploadById,
    GetLockableUploadByIdVariables
  >(GetLockableUploadByIdQuery, {
    pollInterval: 5000,
    variables: {
      includeScheduled: scheduledAssetsEnabled,
      currentDateTime: now,
      id,
    },
    onError(error) {
      Sentry.captureException(error);
    },
  });

  const lockableUpload = data?.lockableUpload;
  const uploadAsset = lockableUpload?.upload;
  const thumbnailAsset = lockableUpload?.thumbnail;
  const targetedScreens = lockableUpload?.screens?.nodes ?? [];
  const currentScheduledAsset = data?.currentScheduledAsset?.nodes[0];
  const assetHistory = data?.assetHistory?.nodes;
  const showCurrentScheduledAsset =
    scheduledAssetsEnabled &&
    currentScheduledAsset &&
    !currentScheduledAsset.clearedAt;

  const pillStates = getAssetStatus({
    hasScheduledAsset: scheduledAssetsEnabled && Boolean(currentScheduledAsset),
    activeScreensForSchedule: currentScheduledAsset?.activeScreenCount || 0,
    activeScreensForDefault: targetedScreens.length,
  });

  useEffect(() => {
    // if the status changes, we'll pass down the "active" asset id to
    // `LockableAssetManagement`, so it can fetch the correct
    // screen data.
    if (pillStates.scheduledScreen === TStatusPillTypes.LIVE) {
      setActiveUploadId(currentScheduledAsset?.scheduledUpload?.id);
    } else if (pillStates.defaultScreen === TStatusPillTypes.LIVE) {
      setActiveUploadId(lockableUpload?.upload?.id);
    }
  }, [pillStates]);

  const deleteLockableUpload = async () => {
    await deleteLockableUploadMutation();
    history.push(`/${feedId}/assets/locked`);
  };

  const buttons = (
    <React.Fragment>
      <S.Button
        primary
        onClick={() => {
          if (showCurrentScheduledAsset) {
            history.push(`/${feedId}/assets/${id}/schedule/edit`);
          } else {
            history.push(`/${feedId}/assets/${id}/edit`);
          }
        }}
      >
        Edit
      </S.Button>
      {scheduledAssetsEnabled && (
        <S.Button
          disabled={
            !lockableUpload?.activeScreensCount &&
            !currentScheduledAsset?.activeScreenCount
          }
          onClick={async () => {
            if (currentScheduledAsset && !currentScheduledAsset.clearedAt) {
              await clearScheduledAsset({
                variables: {
                  patch: {
                    clearedAt: new Date().toISOString(),
                  },
                  id: currentScheduledAsset.id,
                },
              });
              history.push(`/${feedId}/assets/${id}`);
            } else {
              history.push(`/${feedId}/assets/${id}/schedule/create`);
            }
          }}
        >
          {showCurrentScheduledAsset ? 'unschedule' : 'schedule'}
        </S.Button>
      )}
      <S.Button
        disabled={
          isDeleteUploadLoading ||
          !!targetedScreens?.length ||
          Boolean(currentScheduledAsset)
        }
        onClick={async () => {
          if (window.confirm('Are you sure you want to delete this asset?')) {
            deleteLockableUpload();
          }
        }}
      >
        Remove
      </S.Button>
    </React.Fragment>
  );

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <PageMeta title="View Locked Asset" />
      <BackOnlyPageHeading
        back={{ to: `/${feedId}/assets/locked`, title: 'Back to locked' }}
      />
      <Well.Container>
        <LockableAssetViewContainer
          pageTitle="Default Screen"
          buttons={buttons}
          loading={loading}
          thumbnailAsset={thumbnailAsset}
          uploadAsset={uploadAsset}
          author={lockableUpload?.author?.name || ''}
          targetedScreens={targetedScreens}
          status={pillStates.defaultScreen}
          Duration={() => (
            <ScheduleDuration
              startAt={currentScheduledAsset?.startAt}
              endAt={currentScheduledAsset?.endAt}
              title={uploadAsset?.title!}
              isScheduled={false}
            />
          )}
        >
          {currentScheduledAsset && showCurrentScheduledAsset && (
            <LockableAssetViewContainer
              pageTitle="Scheduled Screen"
              author={currentScheduledAsset.author?.name || ''}
              screenType={ScreenType.CIC}
              Duration={() => (
                <ScheduleDuration
                  isScheduled
                  endAt={currentScheduledAsset.endAt}
                  startAt={currentScheduledAsset.startAt}
                  title={currentScheduledAsset.scheduledUpload?.title || ''}
                />
              )}
              loading={loading}
              targetedScreens={[
                {
                  type: ScreenType.CIC,
                  totalCount: currentScheduledAsset.activeScreenCount,
                },
              ]}
              thumbnailAsset={currentScheduledAsset?.thumbnail}
              uploadAsset={currentScheduledAsset.scheduledUpload}
              status={pillStates.scheduledScreen as TStatusPillTypes}
            />
          )}
          <LockableAssetManagement
            lockableUploadId={activeUploadId}
            assetHistory={assetHistory}
            targetedScreens={
              currentScheduledAsset &&
              pillStates.scheduledScreen === TStatusPillTypes.LIVE
                ? [
                    {
                      totalCount: currentScheduledAsset.activeScreenCount || 0,
                      type: ScreenType.CIC,
                    },
                  ]
                : lockableUpload?.screens.nodes
            }
          />
        </LockableAssetViewContainer>
      </Well.Container>
    </div>
  );
};

export default LockableAssetView;
