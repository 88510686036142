/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { ThemeType } from 'theme';
import iconCheckBlueSrc from 'images/icon_check_blue.svg';
import { TinyBullet } from 'components/common/Bullet';

export const allStationsCheckbox = css`
  margin: 0 8px 0 0;
`;

export const select = css`
  flex: 1;

  /* Todo: we should consider moving these styles to our route selector's select style
  API after merging https://github.com/postlight/mta-live-media-manager/pull/1351
  as it has some fixes for style replacement regarding the "control" property*/

  /* control styles */
  & > div {
    min-height: 32px;
  }
`;

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      height: 526px;
      width: 280px;
      flex-direction: column;
      margin: 10px 0;

      background-color: ${theme.colors.white};

      border-radius: 4px;
      border: 1px solid #aaa;
      box-shadow: inset 0 0 2px 0 #aaa;
    `}
    {...props}
  />
);

export const Header: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      padding: 16px;
      background: transparent;
    `}
    {...props}
  />
);

export const Row: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
    `}
    {...props}
  />
);

export const LineAndGlobalCheck: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <Row
    css={css`
      justify-content: space-between;
      margin-bottom: 12px;
    `}
    {...props}
  />
);

export const AllStationsLabel: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  // We associate this label with a control when we render the <input /> as a child.
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium}
      font-family: ${theme.typography.families.primary};
      font-weight: ${theme.typography.weights.bold};
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    `}
    {...props}
  />
);

export const BoroughTabs: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <Row
    css={css`
      height: 40px;
      align-items: center;
      background-color: #eaeaea;
      border-top: 1px solid #aaa;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    `}
    {...props}
  />
);

export const BoroughTab: React.FC<
  {
    isActive?: boolean;
    onClick: (event: any) => void;
  } & { children?: React.ReactNode }
> = ({ isActive = false, children, onClick, ...props }) => (
  <button
    type="button"
    tabIndex={-1}
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      font-weight: ${theme.typography.weights.bold};
      font-family: ${theme.typography.families.primary};
      text-transform: uppercase;
      cursor: pointer;
      margin-left: 6px;
      padding-left: 3px;
      padding-right: 3px;
      padding-top: 10px;
      height: 100%;
      box-sizing: border-box;
      outline: unset;

      ${isActive
        ? `color: ${theme.colors.accent1};
           border-bottom: 4px solid ${theme.colors.accent1}`
        : 'border-bottom: 4px solid transparent'};
    `}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export const ScrollContainer: React.FC<any> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-width: thin;
      border-radius: 4px;
      box-shadow: inset 0 0 2px 0 #aaa;
      border-top: 1px solid #aaa;
      background-color: ${theme.colors.white};
      position: relative;
    `}
    {...props}
  />
);

export const Stops: React.FC<{
  routeColor: string;
  children?: React.ReactNode;
}> = ({ routeColor, ...props }) => (
  <div
    css={css`
      list-style: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 16px;

      --route-color: #${routeColor};
    `}
    {...props}
  />
);

export const StopName: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small}
      font-weight: ${theme.typography.weights.normal};

      input:checked ~ & {
        font-weight: ${theme.typography.weights.bold};
      }

      line-height: 1;
    `}
    {...props}
  />
);

export const MainBackground: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    data-main-bg
    css={css`
      height: 32px;
      width: 28px;
      background-color: #ddd;
      position: absolute;
      left: 0;
    `}
    {...props}
  />
);

export const SelectedBackground: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    data-selected-bg
    css={css`
      height: 32px;
      width: 28px;
      position: absolute;
      left: 0;
      background-color: transparent;

      border-color: black;
      border-style: solid;
      border-width: 0;

      input:checked ~ & {
        border-width: 0 1px 0 1px;
        background-color: var(--route-color);
      }
    `}
    {...props}
  />
);

export const CheckCircle: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      height: 18px;
      width: 18px;
      border-radius: 9px;
      position: absolute;
      left: 5px;
      top: 5px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: white;

      border: 1px solid #0f61a9;

      input:checked ~ & {
        border: none;
        background-image: url('${iconCheckBlueSrc}');
      }
    `}
    {...props}
  />
);

export const HiddenCheckboxInput: React.FC<
  {
    checked: boolean;
    onChange: (evt: any) => void;
  } & { children?: React.ReactNode }
> = (props) => (
  <input
    type="checkbox"
    css={css`
      height: 32px;
      width: 28px;
      margin: 0;
      padding: 0;
      position: absolute;
      left: 0;
      opacity: 0;
    `}
    {...props}
  />
);

export const StopCheckbox: React.FC<
  {
    checked: boolean;
    onChange: (evt: any) => void;
  } & { children?: React.ReactNode }
> = ({ checked, onChange }) => (
  <React.Fragment>
    <HiddenCheckboxInput checked={checked} onChange={onChange} />
    <MainBackground />
    <SelectedBackground />
    <CheckCircle />
  </React.Fragment>
);

export const Stop: React.FC<
  {
    checked: boolean;
    onClick: (evt: any) => void;
    startsRange: boolean;
    endsRange: boolean;
    routeIds?: string[];
  } & { children?: React.ReactNode }
> = ({
  children,
  checked,
  onClick,
  startsRange,
  endsRange,
  routeIds,
  ...props
}) => (
  <label
    data-starts-range={startsRange}
    data-ends-range={endsRange}
    css={css`
      box-sizing: border-box;
      padding-left: 40px;
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:focus-within {
        text-decoration: underline;
      }

      :first-of-type > div[data-main-bg],
      :first-of-type > div[data-selected-bg] {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }
      :last-child > div[data-main-bg],
      :last-child > div[data-selected-bg] {
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }

      &[data-starts-range='true'] {
        > input:checked ~ div[data-selected-bg] {
          border-top-width: 1px;
        }
        > div[data-selected-bg] {
          border-top-left-radius: 14px;
          border-top-right-radius: 14px;
        }
      }
      &[data-ends-range='true'] {
        > input:checked ~ div[data-selected-bg] {
          border-bottom-width: 1px;
        }
        > div[data-selected-bg] {
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
        }
      }
    `}
    {...props}
  >
    <StopCheckbox checked={checked} onChange={onClick} />
    <StopName>
      <React.Fragment>
        {children}
        <span
          css={css`
            margin-right: 4px;
          `}
        />
        {routeIds?.map((rid) => {
          return <TinyBullet key={rid} routeId={rid} />;
        })}
      </React.Fragment>
    </StopName>
  </label>
);

export const Borough: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      ${theme.typography.sizes.medium}
      font-family: ${theme.typography.families.primary};
      font-weight: ${theme.typography.weights.bold};

      padding: 40px 0 16px 40px;

      ::before {
        content: '';
        position: absolute;
        width: 28px;
        height: 80px;
        top: 0;
        left: 0;

        background-color: #ddd;
      }

      :first-of-type::before {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }

      &[data-highlight='true']::before {
        border-left: 1px solid black;
        border-right: 1px solid black;
        background-color: var(--route-color);
      }

      ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: black;
        top: 24px;
        left: 0;
      }

      &[data-is-first='true'] {
        padding: 16px 0 16px 40px;
        ::after {
          background-color: unset;
        }
      }
    `}
    {...props}
  />
);
