/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ThemeType } from '../../../theme';

const Spinner: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.xsmall}
      color: #d2d5d7;
      text-indent: -99999em;
      margin: 0 auto;
      position: relative;
      width: 10em;
      height: 10em;
      box-shadow: inset 0 0 0 1em;
      border-radius: 50%;
      transform: translateZ(0);

      &::before,
      &::after {
        border-radius: 50%;
        position: absolute;
        content: '';
      }

      &::before {
        width: 5.2em;
        height: 10.2em;
        background: #f8f8fd;
        border-radius: 10.2em 0 0 10.2em;
        top: -0.1em;
        left: -0.1em;
        transform-origin: 5.2em 5.1em;
        animation: spinner 2s infinite ease 1.5s;
      }

      &::after {
        width: 5.2em;
        height: 10.2em;
        background: #f8f8fd;
        border-radius: 0 10.2em 10.2em 0;
        top: -0.1em;
        left: 5.1em;
        transform-origin: 0px 5.1em;
        animation: spinner 2s infinite ease;
      }

      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
    {...props}
  />
);

export default Spinner;
