import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Borough } from '../../generated/global-types';

type Option = { value: string };
type OptionWithBorough = { borough?: Borough; value: string };
export type EnhancedOption = { option: any; selected: boolean };

const useRangeSelection = ({
  options,
  values,
  onChange,
}: {
  options: Option[];
  values: string[];
  onChange: (values: string[]) => void;
}): {
  enhancedOptions: EnhancedOption[];
  onClick: (option: any) => (evt: any) => void;
  selectAll: () => void;
  deselectAll: () => void;
  boroughSelectAll: (borough: Borough) => void;
  boroughDeselectAll: (borough: Borough) => void;
} => {
  const lastSelected = useRef(null);
  const enhancedOptions = useMemo(() => {
    return options.map((o) => ({
      option: o,
      selected: values.includes(o.value),
    }));
  }, [options, values]);

  const selectAll = useCallback(() => {
    const s = new Set(values);
    options.forEach((o) => s.add(o.value));
    onChange(Array.from(s));
  }, [values, options, onChange]);
  const deselectAll = useCallback(() => {
    const s = new Set(values);
    options.forEach((o) => s.delete(o.value));
    onChange(Array.from(s));
  }, [values, options, onChange]);

  const boroughSelectAll = useCallback(
    (borough: Borough) => {
      const s = new Set(values);
      options
        .filter((eo: OptionWithBorough) => eo?.borough === borough)
        .forEach((o) => s.add(o.value));
      onChange(Array.from(s));
    },
    [values, options, onChange],
  );
  const boroughDeselectAll = useCallback(
    (borough: Borough) => {
      const s = new Set(values);
      options
        .filter((eo: OptionWithBorough) => eo?.borough === borough)
        .forEach((o) => s.delete(o.value));
      onChange(Array.from(s));
    },
    [values, options, onChange],
  );

  const shiftKey = useRef(false);
  useEffect(() => {
    const listener = (evt: any) => {
      if (evt.key !== 'Shift') {
        return;
      }

      shiftKey.current = evt.type === 'keydown';
    };

    document.addEventListener('keydown', listener);
    document.addEventListener('keyup', listener);

    return () => {
      document.removeEventListener('keydown', listener);
      document.removeEventListener('keyup', listener);
    };
  }, [shiftKey]);

  const handleClick = useCallback(
    (option: any) => () => {
      if (values.includes(option.value)) {
        lastSelected.current = null;
        onChange(values.filter((s) => s !== option.value));
      } else {
        if (shiftKey.current && lastSelected.current) {
          const lastIdx = options.findIndex(
            (o) => o.value === lastSelected.current,
          );
          const currIdx = options.findIndex((o) => o.value === option.value);
          const idxRange =
            lastIdx > currIdx ? [currIdx, lastIdx + 1] : [lastIdx, currIdx + 1];
          const range = options.slice(...idxRange).map((o) => o.value);
          onChange(Array.from(new Set([...values, ...range])));
        } else {
          onChange([...values, option.value]);
        }
        lastSelected.current = option.value;
      }
    },
    [values, options, onChange, lastSelected],
  );

  return {
    onClick: handleClick,
    selectAll,
    deselectAll,
    boroughSelectAll,
    boroughDeselectAll,
    enhancedOptions,
  };
};

export default useRangeSelection;
