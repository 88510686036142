/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import theme from 'theme';
import { FeatureFlagName, TargetType } from 'generated/global-types';
import {
  OptionProps,
  ValueContainerProps,
  components,
  PlaceholderProps,
  ActionMeta,
  CSSObjectWithLabel,
} from 'react-select';
import uniq from 'lodash/uniq';
import {
  STATION_SCREEN_OPTIONS,
  NYCT_SUBWAY_SCREEN_OPTIONS,
} from 'constants/screens';
import { FeedId, useFeedId } from 'contexts/FeedId';
import { getLabelFromTargetType } from 'components/pages/Screen/in-screens-preview/helpers';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import Checkbox, { CheckboxSize } from './Checkbox';
import Select from './form-elements/Select';

enum ScreenType {
  TRAIN = 'TRAIN',
  STATION = 'STATION',
  SIDEWALK = 'SIDEWALK',
}

enum ScreenTypeOptionType {
  ALL_SCREEN_TYPE_OPTION = 'ALL_SCREEN_TYPE_OPTION',
  SCREEN_OPTION = 'SCREEN_OPTION',
}

type ScreenTypeOption = {
  label: string;
  value: string;
  optionType: ScreenTypeOptionType;
  screenType?: ScreenType;
};

type ScreenTypeGroup = {
  label: string;
  value: string;
  options: ScreenTypeOption[];
};

const ALL_SCREEN_TYPE_OPTIONS: {
  [key in ScreenType.STATION | ScreenType.TRAIN]: ScreenTypeOption;
} = {
  [ScreenType.STATION]: {
    label: 'All Station Screens',
    value: 'all-station-screens-option',
    optionType: ScreenTypeOptionType.ALL_SCREEN_TYPE_OPTION,
    screenType: ScreenType.STATION,
  },
  [ScreenType.TRAIN]: {
    label: 'All Train Screens',
    value: 'all-train-screens-option',
    optionType: ScreenTypeOptionType.ALL_SCREEN_TYPE_OPTION,
    screenType: ScreenType.TRAIN,
  },
};

const getScreenOptionsByType = (
  feedId: FeedId,
): {
  [key in ScreenType]: ScreenTypeOption[];
} => ({
  [ScreenType.STATION]: STATION_SCREEN_OPTIONS.map(
    (so) =>
      ({
        ...so,
        optionType: ScreenTypeOptionType.SCREEN_OPTION,
        screenType: ScreenType.STATION,
      }) as ScreenTypeOption,
  ),
  [ScreenType.TRAIN]:
    feedId === FeedId.NYCTSubway
      ? NYCT_SUBWAY_SCREEN_OPTIONS.map(
          (so) =>
            ({
              ...so,
              optionType: ScreenTypeOptionType.SCREEN_OPTION,
              screenType: ScreenType.TRAIN,
            }) as ScreenTypeOption,
        )
      : [
          {
            value: TargetType._3SM,
            label: getLabelFromTargetType(TargetType._3SM),
            optionType: ScreenTypeOptionType.SCREEN_OPTION,
            screenType: ScreenType.TRAIN,
          },
        ],
  [ScreenType.SIDEWALK]: [
    {
      value: TargetType.DUP,
      label: getLabelFromTargetType(TargetType.DUP),
      optionType: ScreenTypeOptionType.SCREEN_OPTION,
      screenType: ScreenType.SIDEWALK,
    },
  ],
});

const getScreenTypeGroups = (
  feedId: FeedId,
  rollingStockEnabled: boolean,
  sidewalkScreenEnabled: boolean,
): ScreenTypeGroup[] => [
  {
    label: 'Station Screens',
    value: 'station-screens-group',
    options: [
      ALL_SCREEN_TYPE_OPTIONS[ScreenType.STATION],
      ...getScreenOptionsByType(feedId)[ScreenType.STATION],
    ],
  },
  ...(rollingStockEnabled
    ? [
        {
          label: 'Train Screens',
          value: 'train-screens-group',
          options: [
            ...(feedId === FeedId.NYCTSubway
              ? [ALL_SCREEN_TYPE_OPTIONS[ScreenType.TRAIN]]
              : []),
            ...getScreenOptionsByType(feedId)[ScreenType.TRAIN],
          ],
        },
      ]
    : []),
  ...(sidewalkScreenEnabled
    ? [
        {
          label: 'Sidewalk Screens',
          value: 'sidewalk-screens-group',
          options: [...getScreenOptionsByType(feedId)[ScreenType.SIDEWALK]],
        },
      ]
    : []),
];

const getSelectableOptions = (
  feedId: FeedId,
  rollingStockEnabled: boolean,
  sidewalkScreenEnabled: boolean,
): ScreenTypeOption[] =>
  getScreenTypeGroups(
    feedId,
    rollingStockEnabled,
    sidewalkScreenEnabled,
  ).flatMap((group) => group.options);

const SELECT_COMPONENTS = {
  Option: (props: OptionProps<any>) => {
    const {
      innerProps,
      isSelected,
      data: { label },
    } = props;

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div {...innerProps} style={props.getStyles('option', props) as any}>
          <Checkbox
            css={css`
              margin-right: 8px;
            `}
            size={CheckboxSize.medium}
            checked={isSelected}
            onChange={() => {}}
          />
          <span
            css={css`
              ${theme.typography.sizes.medium};
              font-family: ${theme.typography.families.primary};
            `}
          >
            {label}
          </span>
        </div>
      </div>
    );
  },
  ValueContainer: (props: ValueContainerProps<any>) => {
    const selectedScreenTypes: string[] = uniq(
      props
        .getValue()
        .filter(
          (o: ScreenTypeOption) =>
            o.optionType === ScreenTypeOptionType.SCREEN_OPTION,
        )
        .map((o: ScreenTypeOption) => o.label)
        .sort(),
    );

    return (
      <components.ValueContainer {...props}>
        {selectedScreenTypes.length
          ? `${selectedScreenTypes[0]}${
              selectedScreenTypes.length > 1
                ? ` +${selectedScreenTypes.length - 1} more`
                : ''
            }`
          : ''}
        {props.children}
      </components.ValueContainer>
    );
  },
  Placeholder: (props: PlaceholderProps<any>) => {
    const selectedOptions = props.getValue();
    return selectedOptions.length ? null : (
      <components.Placeholder {...props} />
    );
  },
};

const getSelectedOptions = (
  value: TargetType[],
  feedId: FeedId,
  rollingStockEnabled: boolean,
  sidewalkScreenEnabled: boolean,
): ScreenTypeOption[] => {
  const selectedOptions: ScreenTypeOption[] = [];
  const selectedTargetTypes = new Set<string>(value);
  const screenOptionsByType = getScreenOptionsByType(feedId);

  Object.keys(screenOptionsByType).forEach((st) => {
    const screenType = st as ScreenType;

    if (
      (screenType === ScreenType.STATION || screenType === ScreenType.TRAIN) &&
      screenOptionsByType[screenType].every((screenTypeOption) =>
        selectedTargetTypes.has(screenTypeOption.value),
      )
    ) {
      selectedOptions.push(ALL_SCREEN_TYPE_OPTIONS[screenType]);
    }
  });

  selectedOptions.push(
    ...getSelectableOptions(
      feedId,
      rollingStockEnabled,
      sidewalkScreenEnabled,
    ).filter((o) => selectedTargetTypes.has(o.value)),
  );

  return selectedOptions;
};

const getScreenFilterSelectorOptions = (
  newSelectedOptions: ScreenTypeOption[],
  actionMeta: ActionMeta<ScreenTypeOption>,
  feedId: FeedId,
): ScreenTypeOption[] => {
  const newOptions: ScreenTypeOption[] = [];
  const { action, option } = actionMeta;
  const screenOptionsByType = getScreenOptionsByType(feedId);

  if (action === 'select-option') {
    if (option?.screenType) {
      if (
        option.optionType === ScreenTypeOptionType.ALL_SCREEN_TYPE_OPTION &&
        (option.screenType === ScreenType.STATION ||
          option.screenType === ScreenType.TRAIN)
      ) {
        newOptions.push(
          ...newSelectedOptions.filter(
            (o) => o.screenType !== option.screenType,
          ),
          ALL_SCREEN_TYPE_OPTIONS[option.screenType],
          ...screenOptionsByType[option.screenType],
        );
      } else {
        newOptions.push(...newSelectedOptions);

        // Check if the all screen type options should be selected
        if (
          option.screenType === ScreenType.STATION ||
          option.screenType === ScreenType.TRAIN
        ) {
          const screenTypeKeysByType = screenOptionsByType[
            option.screenType
          ].map((o) => o.value);
          const selectedScreenTypesByType = new Set(
            newOptions
              .filter(
                (o) =>
                  o.optionType === ScreenTypeOptionType.SCREEN_OPTION &&
                  o.screenType === option.screenType,
              )
              .map((o) => o.value),
          );

          if (
            screenTypeKeysByType.every((s) => selectedScreenTypesByType.has(s))
          ) {
            newOptions.push(ALL_SCREEN_TYPE_OPTIONS[option.screenType]);
          }
        }
      }
    }
  } else if (action === 'deselect-option') {
    // Check if all of a type's screen options should be deselected
    if (option?.optionType === ScreenTypeOptionType.ALL_SCREEN_TYPE_OPTION) {
      newOptions.push(
        ...newSelectedOptions.filter(
          (o) =>
            !(
              o.screenType === option.screenType ||
              o.optionType === ScreenTypeOptionType.ALL_SCREEN_TYPE_OPTION
            ),
        ),
      );
    } else if (option?.optionType === ScreenTypeOptionType.SCREEN_OPTION) {
      // Deselect a screen option as well as its all types option
      newOptions.push(
        ...newSelectedOptions.filter(
          (o) =>
            !(
              o.value === option.value ||
              (o.screenType &&
                o.optionType === ScreenTypeOptionType.ALL_SCREEN_TYPE_OPTION &&
                o.screenType === option.screenType)
            ),
        ),
      );
    }
  }

  return newOptions;
};

const CampaignScreenFilterSelector: React.FC<
  {
    value: TargetType[];
    onChange: (targetTypes: TargetType[]) => void;
  } & { children?: React.ReactNode }
> = ({ value, onChange }) => {
  const feedId = useFeedId();
  const rollingStockEnabled = useFeatureFlag(
    FeatureFlagName.CAMPAIGN_ROLLING_STOCK,
  );
  const sidewalkScreenEnabled = useFeatureFlag(
    FeatureFlagName.SIDEWALK_SCREEN_TARGETING,
  );
  const selectedOptions = getSelectedOptions(
    value,
    feedId,
    rollingStockEnabled,
    sidewalkScreenEnabled,
  );

  return (
    <Select
      css={css`
        flex: 1;
        margin-right: 12px;
        background-color: white;
      `}
      isMulti
      isSearchable={false}
      isClearable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      styles={{
        valueContainer: (provided: CSSObjectWithLabel) => {
          return {
            ...provided,
            display: 'flex',
          };
        },
      }}
      placeholder="Screen Type(s)"
      options={getScreenTypeGroups(
        feedId,
        rollingStockEnabled,
        sidewalkScreenEnabled,
      )}
      components={SELECT_COMPONENTS}
      value={selectedOptions}
      onChange={(
        newSelectedOptions: ScreenTypeOption[],
        actionMeta: ActionMeta<ScreenTypeOption>,
      ) => {
        onChange(
          getScreenFilterSelectorOptions(
            newSelectedOptions,
            actionMeta,
            feedId,
          ).map((o) => o.value as TargetType),
        );
      }}
    />
  );
};

export default CampaignScreenFilterSelector;
