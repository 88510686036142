import * as React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import ms from 'ms.macro';
import assertNever from 'assert-never';
import useLiveQuery from 'hooks/use-live-query';
import {
  PolledCampaignStatusPill as CampaignStatusQueryType,
  PolledCampaignStatusPillVariables as CampaignStatusVariables,
} from 'generated/PolledCampaignStatusPill';
import {
  LiveCampaignStatusPill as LiveCampaignStatusQueryType,
  LiveCampaignStatusPillVariables as LiveCampaignStatusVariables,
} from 'generated/LiveCampaignStatusPill';
import StatusPill, { TStatusPillTypes, StatusPillProps } from './status-pill';
import { CampaignStatus } from '../../generated/global-types';

const campaignStatusToPillStatus = (
  cs: CampaignStatus,
  isSimple?: boolean,
): TStatusPillTypes => {
  switch (cs) {
    case CampaignStatus.CLEARED:
    case CampaignStatus.EXPIRED:
      return TStatusPillTypes.CLEARED;
    case CampaignStatus.DRAFT:
      return TStatusPillTypes.DRAFT;
    case CampaignStatus.LIVE:
      return TStatusPillTypes.LIVE;
    case CampaignStatus.SCHEDULED:
      return TStatusPillTypes.SCHEDULED;
    case CampaignStatus.DEPLOYING:
      return TStatusPillTypes.DEPLOYING;
    case CampaignStatus.ERROR:
      return TStatusPillTypes.ERROR;
    case CampaignStatus.LIMITED:
      return TStatusPillTypes.LIMITED;
    case CampaignStatus.UNKNOWN:
      return TStatusPillTypes.UNKNOWN;
    case CampaignStatus.CLEARING:
      return isSimple
        ? TStatusPillTypes.CLEARING
        : TStatusPillTypes.SCREENS_CLEARING;
    default:
      return assertNever(cs);
  }
};

type CampaignStatusPillProps = {
  status: CampaignStatus;
  isSimple?: boolean;
} & Omit<StatusPillProps, 'status'>;
const CampaignStatusPill = ({
  status,
  isSimple,
  ...rest
}: CampaignStatusPillProps) => (
  <StatusPill status={campaignStatusToPillStatus(status, isSimple)} {...rest} />
);

const CampaignStatusQuery = loader(
  '../../graphql/PolledCampaignStatusPill.gql',
);

export const PolledCampaignStatusPill: React.FC<
  {
    campaignId: number;
    fallbackStatus?: CampaignStatus;
    polling?: boolean;
    onlyUseProvidedStatus?: boolean;
  } & Omit<CampaignStatusPillProps, 'status'>
> & { children?: React.ReactNode } = ({
  campaignId,
  fallbackStatus,
  polling = true,
  onlyUseProvidedStatus = false,
  ...rest
}) => {
  const query = useQuery<CampaignStatusQueryType, CampaignStatusVariables>(
    CampaignStatusQuery,
    {
      variables: { campaignId },
      pollInterval: polling ? ms('10 seconds') : undefined,
      skip: onlyUseProvidedStatus,
    },
  );

  if (!onlyUseProvidedStatus && query?.data?.campaign?.status) {
    return <CampaignStatusPill {...rest} status={query.data.campaign.status} />;
  }

  if (fallbackStatus) {
    return <CampaignStatusPill {...rest} status={fallbackStatus} />;
  }

  return null;
};

const LiveCampaignStatusQuery = loader(
  '../../graphql/LiveCampaignStatusPill.gql',
);

export const LiveCampaignStatusPill: React.FC<
  {
    campaignId: number;
    fallbackStatus?: CampaignStatus;
  } & Omit<CampaignStatusPillProps, 'status'>
> & { children?: React.ReactNode } = ({
  campaignId,
  fallbackStatus,
  ...rest
}) => {
  const query = useLiveQuery<
    LiveCampaignStatusQueryType,
    LiveCampaignStatusVariables
  >(LiveCampaignStatusQuery, {
    variables: { campaignId },
  });

  if (query?.data?.campaign?.status) {
    return <CampaignStatusPill {...rest} status={query.data.campaign.status} />;
  }

  if (fallbackStatus) {
    return <CampaignStatusPill {...rest} status={fallbackStatus} />;
  }

  return null;
};

export default CampaignStatusPill;
