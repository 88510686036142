/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { css } from '@emotion/react';

import format from 'date-fns/format';

import { ReactComponent as ZippedIcon } from 'images/icon_zipped.svg';
import { usePlayback } from 'contexts/Playback';

import { ThemeType, ThemeSizes } from 'theme';
import { Screen_screen2_Screens2_currentScreenLock_ScreenLock_upload_Upload } from 'generated/Screen';

const Screen: React.FC<
  { height: number; width: number; scale: number } & {
    children?: React.ReactNode;
  }
> = ({ height, width, scale, ...rest }) => (
  <main
    css={(theme: ThemeType) => css`
      box-sizing: border-box;
      height: calc(${height}px * ${scale});
      width: calc(${width}px * ${scale});
      border-radius: 4px;
      border: 1px solid ${theme.colors.border};
      overflow: hidden;
    `}
    {...rest}
  />
);

const Container: React.FC<
  { width: number; scale: number } & { children?: React.ReactNode }
> = ({ width, scale, ...rest }) => (
  <section
    css={css`
      width: calc(${width}px * ${scale});
    `}
    {...rest}
  />
);

const Info: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <footer
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `}
    {...props}
  />
);

const ImagePreview: React.FC<{ src?: string | null }> = ({ src, ...rest }) => (
  <div
    role="img"
    css={css`
      height: 100%;
      width: 100%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      ${src ? `background-image: url(${src});` : ''}
    `}
    {...rest}
  />
);

const Filename: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium}
    `}
    {...props}
  />
);

const UpdatedAt: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.xsmall}
    `}
    {...props}
  />
);

const DownloadLink: React.FC<
  {
    href: string | null | undefined;
    filename: string | null | undefined;
  } & { children?: React.ReactNode }
> = ({ filename, href, ...rest }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    download={filename ?? undefined}
    href={href ?? undefined}
    css={css`
      text-decoration: underline;
    `}
    {...rest}
  >
    Download
  </a>
);

const VideoPreview: React.FC<{ src?: string | null }> = ({ src, ...rest }) => {
  const video = React.useRef<HTMLVideoElement | null>(null);
  const { play, shouldBePlaying } = usePlayback(src ?? '');

  React.useEffect(() => {
    if (shouldBePlaying && video.current) {
      video.current?.play();
    }
    if (!shouldBePlaying && video.current) {
      video.current?.pause();
    }
  }, [video, shouldBePlaying]);

  return (
    <video
      ref={video}
      src={src ?? ''}
      controls
      muted
      loop
      title={shouldBePlaying ? 'Stop playback' : 'Start playback'}
      onClick={() => play()}
      css={css`
        height: 100%;
        width: 100%;
        cursor: pointer;
      `}
      {...rest}
    />
  );
};

const SmallText: React.FC<{ scale: number; children?: React.ReactNode }> = ({
  scale,
  ...props
}) => (
  <span
    css={(theme: ThemeType) => css`
      font-size: calc(56px * ${scale});
      font-weight: ${theme.typography.weights.bolder};
      text-align: center;
      text-transform: uppercase;
    `}
    {...props}
  />
);

const ZipContents: React.FC<{ scale: number; children?: React.ReactNode }> = ({
  scale,
  ...props
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
    {...props}
  >
    <SmallText scale={scale}>Zipped File</SmallText>
    <ZippedIcon
      height={328 * scale}
      width={328 * scale}
      css={css`margin: calc(48px * ${scale}); 0;`}
    />
    <SmallText scale={scale}>Preview Unavailable</SmallText>
  </div>
);

const ZipFilePreview: React.FC<{ scale: number }> = ({ scale, ...props }) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background-color: ${theme.colors['background-inset']};
    `}
    {...props}
  >
    <ZipContents scale={scale} />
  </div>
);

interface ScreenPreviewProps {
  simple?: boolean;
  scale?: number;
  zipContentsScale?: number;
  showDownload?: boolean;
  dimensions: { width: number; height: number };
  upload?: Screen_screen2_Screens2_currentScreenLock_ScreenLock_upload_Upload | null;
  filenameSize?: ThemeSizes;
  showUpdatedAt?: boolean;
  thumbnailUrl?: string | null;
}

const ScreenUploadPreview: React.FC<ScreenPreviewProps> = ({
  simple = false,
  scale = 0.25,
  zipContentsScale,
  showDownload = true,
  dimensions,
  upload,
  filenameSize = 'medium',
  showUpdatedAt = false,
  thumbnailUrl,
  ...rest
}) => {
  const hasThumbnail = !!thumbnailUrl;
  const shouldShowDownloadLink =
    showDownload && upload?.mimeType === 'application/zip';

  const Preview = () => {
    if (hasThumbnail) {
      return <ImagePreview src={thumbnailUrl ?? ''} />;
    }
    if (upload?.mimeType.startsWith('image/')) {
      return <ImagePreview src={upload.signedS3Url} />;
    }
    if (upload?.mimeType.startsWith('video/')) {
      return <VideoPreview src={upload.signedS3Url} />;
    }
    if (upload?.mimeType === 'application/zip') {
      return <ZipFilePreview scale={zipContentsScale ?? scale} />;
    }
    return <div />;
  };

  return (
    <Container width={dimensions.width} scale={scale} {...rest}>
      <Screen {...{ ...dimensions, scale }}>
        <Preview />
      </Screen>
      {!simple && (
        <Info
          css={
            showUpdatedAt &&
            css`
              flex-direction: column;
              align-items: flex-start;
            `
          }
        >
          <Filename
            css={(theme: ThemeType) => css`
              margin-top: 8px;
              ${theme.typography.sizes[filenameSize]};
            `}
          >
            {upload?.originalFilename}
          </Filename>
          {showUpdatedAt && upload?.updatedAt && (
            <UpdatedAt>
              Last Updated: {format(new Date(upload?.updatedAt), "P 'at' p")}
            </UpdatedAt>
          )}
          {shouldShowDownloadLink && (
            <DownloadLink
              href={upload?.signedS3Url}
              filename={upload?.originalFilename}
            />
          )}
        </Info>
      )}
    </Container>
  );
};

export default ScreenUploadPreview;
