/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';
import formatDate from 'date-fns/format';
import pluralize from 'pluralize';

import { GtfsEntitySelectorInput } from 'generated/global-types';
import { SyntheticScreen_syntheticScreen_SyntheticScreen_campaignScreenContent_CampaignScreenContentsConnection_nodes_CampaignScreenContent as SyntheticScreen_syntheticScreen_campaignScreenContent_nodes } from 'generated/SyntheticScreen';

import { PlaybackProvider } from '../../../contexts/Playback';
import Button from '../../common/Button';
import Bullet from '../../common/Bullet';
import AppLink from '../../common/app-link';
import Modal from '../../common/modal';
import CampaignStatusPill from '../../common/campaign-status-pill';
import CampaignScreenPreview from './in-screens-preview/in-screen-preview';

import { ReactComponent as IconZoom } from '../../../images/icon_zoom.svg';

import useNamedStops from '../../../hooks/useNamedStops';
import useRoutesForStops, {
  Mode as RoutesForStopsMode,
} from '../../../hooks/useRoutesForStops';

import { Screen_screen2_Screens2_campaignScreenContent_CampaignScreenContentsConnection_nodes_CampaignScreenContent as Screen_screen2_campaignScreenContent_nodes } from '../../../generated/Screen';

import * as S from './CampaignsOnScreen.styled';
import { DATE_FORMAT } from '../../../constants/time';
import { ContentTypeLabel, WeightPropsContainer } from './shared';

type CampaignScreenContent =
  | SyntheticScreen_syntheticScreen_campaignScreenContent_nodes
  | Screen_screen2_campaignScreenContent_nodes;

interface CampaignRowProps {
  campaignScreenContent: CampaignScreenContent;
  highlightScreen: number;
  includeScreenContentType?: boolean;
  viewContentLabel?: string;
  weightingEnabled?: boolean;
  timeOnScreenPercentage?: number;
}
export const CampaignRow: React.FC<CampaignRowProps> = ({
  campaignScreenContent,
  highlightScreen,
  includeScreenContentType = false,
  viewContentLabel = 'View Campaign',
  weightingEnabled = false,
  timeOnScreenPercentage = 0,
}) => {
  const [showLargePreview, setShowLargePreview] = React.useState(false);

  const { campaign, screenName } = campaignScreenContent;

  const durationsToDisplay = campaignScreenContent.campaign.durations.slice(
    0,
    2,
  );
  const overflowingDurationCount = Math.max(campaign.durations.length - 2, 0);

  const entitySelectors =
    (campaignScreenContent.screenSelectors
      ?.flatMap((s) => s?.selectors)
      ?.map((selector) => selector?.entitySelector)
      .filter(Boolean) as GtfsEntitySelectorInput[]) || [];

  const routes = entitySelectors
    .filter((es) => !!es?.routeId && !es?.stopId)
    .map((es) => es?.routeId) as string[];
  const stops = entitySelectors
    .filter((es) => !!es?.stopId)
    .map((es) => es?.stopId) as string[];

  const routesForStops = useRoutesForStops(stops, {
    mode: RoutesForStopsMode.union,
  });

  const namedStops = useNamedStops(stops);

  const statusPill = (
    <CampaignStatusPill
      status={campaignScreenContent.campaign.status}
      size="small"
    />
  );

  return (
    <S.Container>
      <S.Preview>
        <S.ViewDetail onClick={() => setShowLargePreview(true)}>
          <IconZoom
            css={css`
              margin-bottom: 8px;
            `}
          />
          View Detail
        </S.ViewDetail>

        {(campaignScreenContent.campaignScreenContentUploads?.nodes ?? []).map(
          (cscu) => (
            <CampaignScreenPreview
              key={cscu.upload?.id}
              css={css`
                opacity: ${highlightScreen === cscu.setIndex ? 1 : 0.4};
              `}
              targetType={campaignScreenContent.targetTypes[0]}
              setIndex={cscu.setIndex}
              upload={cscu.upload}
              scale={0.08}
              simple
            />
          ),
        )}
      </S.Preview>
      <S.Details>
        <S.Header>
          <S.Title>
            {includeScreenContentType && (
              <ContentTypeLabel>
                {!!screenName && 'Single Screen '}Campaign
              </ContentTypeLabel>
            )}
            {campaign.title}
          </S.Title>
        </S.Header>
        <S.Durations>
          {durationsToDisplay.map((d, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              {formatDate(new Date(d?.start?.value), DATE_FORMAT)}-{' '}
              {formatDate(new Date(d?.end?.value), DATE_FORMAT)}
            </li>
          ))}
          {overflowingDurationCount > 0 && `+${overflowingDurationCount} more`}
        </S.Durations>

        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <div
            css={css`
              margin-right: 6px;
            `}
          >
            {routes.map((routeId) => (
              <Bullet key={routeId} routeId={routeId} />
            ))}
            {routesForStops.map((routeId) => (
              <Bullet key={routeId} routeId={routeId} />
            ))}
          </div>
          {(() => {
            switch (namedStops.length) {
              case 0:
                return 'All Stations';
              case 1:
                return namedStops[0].name || namedStops[0].id;
              default:
                return pluralize('Station', namedStops.length, true);
            }
          })()}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            margin-top: 16px;
          `}
        >
          <Button
            as={AppLink}
            to={`/${campaign.feedId}/campaigns/${campaign.id}`}
            type="button"
            size="xsmall"
            primary
          >
            {viewContentLabel}
          </Button>
        </div>
      </S.Details>
      {weightingEnabled ? (
        <WeightPropsContainer
          priority={campaignScreenContent.priority}
          weight={campaignScreenContent.weight}
          statusPill={statusPill}
          timeOnScreenPercentage={timeOnScreenPercentage}
        />
      ) : (
        <div>{statusPill}</div>
      )}
      {showLargePreview && (
        <Modal
          title="View Detail"
          onDismiss={() => setShowLargePreview(false)}
          restOnContent
          css={css`
            width: 1078px;
            max-width: unset;
          `}
        >
          <PlaybackProvider>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 24px;
              `}
            >
              {(
                campaignScreenContent.campaignScreenContentUploads?.nodes ?? []
              ).map((cscu) => (
                <CampaignScreenPreview
                  key={cscu.upload?.id}
                  targetType={campaignScreenContent.targetTypes[0]}
                  setIndex={cscu.setIndex}
                  upload={cscu.upload}
                  scale={0.3}
                  simple
                />
              ))}
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row-reverse;
              `}
            >
              <Button
                as={AppLink}
                primary
                to={`/${campaign.feedId}/campaigns/${campaign.id}`}
              >
                {viewContentLabel}
              </Button>
              <Button
                css={css`
                  margin-right: 16px;
                `}
                type="button"
                onClick={() => setShowLargePreview(false)}
              >
                Close
              </Button>
            </div>
          </PlaybackProvider>
        </Modal>
      )}
    </S.Container>
  );
};

interface CampaignsOnScreenProps {
  campaignScreenContent: CampaignScreenContent[] | null;
  highlightScreen?: number;
}
const CampaignsOnScreen: React.FC<CampaignsOnScreenProps> = ({
  campaignScreenContent,
  highlightScreen = 0,
}) => {
  if (!campaignScreenContent?.length) {
    return null;
  }

  return (
    <div>
      {campaignScreenContent.map((csc) => {
        return (
          <CampaignRow
            key={csc.id}
            campaignScreenContent={csc}
            highlightScreen={highlightScreen}
          />
        );
      })}
    </div>
  );
};

export default CampaignsOnScreen;
