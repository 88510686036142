/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import {
  ListboxInput,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox';
import pluralize from 'pluralize';

import theme from 'theme';
import StandardListboxButton from 'ui-kit/standard-listbox-button';

import range from 'utils/range';
import * as styles from './styles';
import { listboxList, listboxOption } from '../../common/styles';

const INPUT_ID = 'screen_message_publish_offset';

// This position function determined from:
// https://github.com/reach/reach-ui/blob/v0.13.2/packages/popover/src/index.tsx
const popoverPosition = (
  targetRect: any,
  popoverRect: any,
): React.CSSProperties => {
  if (!targetRect || !popoverRect) {
    return {};
  }

  return {
    width: targetRect.width,
    left: targetRect.left,
    top: `${targetRect.top + targetRect.height + window.pageYOffset}px`,
  };
};

const ScreenMessagePublishOffset: React.FC<
  {
    value: number;
    onChange: (dayOffset: number) => void;
  } & { children?: React.ReactNode }
> = ({ value, onChange }) => {
  return (
    <div css={styles.inputWrapper}>
      <label
        css={css`
          margin-bottom: 8px;
        `}
        htmlFor={INPUT_ID}
      >
        Screen Duration
      </label>
      <div
        css={css`
          ${theme.typography.sizes.small};
          font-weight: ${theme.typography.weights.normal};
          margin-bottom: 12px;
        `}
      >
        Alerts will appear on your selected screens{' '}
        {pluralize('day', value, true)} before each work period and remain until
        the work period is over.
      </div>
      <ListboxInput
        id={INPUT_ID}
        value={value.toString()}
        css={css`
          ${styles.listbox}
          max-width: 200px;
          & > [data-reach-listbox-button] {
            border-radius: 4px;
            margin-bottom: 24px;
          }
        `}
        onChange={(dayOffset) => onChange(parseInt(dayOffset, 10))}
      >
        <StandardListboxButton
          css={css`
            border-radius: 4px;
          `}
        >
          {`${pluralize('day', value, true)} before`}
        </StandardListboxButton>
        <ListboxPopover position={popoverPosition}>
          <ListboxList css={listboxList}>
            {range(0, 10).map((n) => (
              <ListboxOption css={listboxOption} key={n} value={n.toString()}>
                {`${pluralize('day', n, true)} before`}
              </ListboxOption>
            ))}
          </ListboxList>
        </ListboxPopover>
      </ListboxInput>
    </div>
  );
};

export default ScreenMessagePublishOffset;
