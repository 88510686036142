/** @jsxImportSource @emotion/react */

const TrashIcon: React.FC<
  { width?: number; height?: number } & { children?: React.ReactNode }
> = ({ width = 16, height = 23 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 23">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-823.000000, -563.000000)"
          fill="#AAAAAA"
          fillRule="nonzero"
        >
          <g transform="translate(272.000000, 349.000000)">
            <g transform="translate(40.000000, 81.000000)">
              <g transform="translate(0.000000, 100.000000)">
                <g transform="translate(482.000000, 33.000000)">
                  <g transform="translate(29.000000, 0.000000)">
                    <g transform="translate(1.100000, 5.271931)">
                      <path d="M13.2,0.466840424 L13.2,14.5 C13.2,15.6045695 12.3045695,16.5 11.2,16.5 L2,16.5 C0.8954305,16.5 0,15.6045695 0,14.5 L0,0.466840424 L13.2,0.466840424 Z M11.7,1.966 L1.5,1.966 L1.5,14.5 C1.5,14.7454599 1.67687516,14.9496084 1.91012437,14.9919443 L2,15 L11.2,15 C11.4454599,15 11.6496084,14.8231248 11.6919443,14.5898756 L11.7,14.5 L11.7,1.966 Z" />
                      <path d="M3.95,3.76713677 C4.40563492,3.76713677 4.775,4.13650185 4.775,4.59213677 L4.775,12.3747037 C4.775,12.8303386 4.40563492,13.1997037 3.95,13.1997037 C3.49436508,13.1997037 3.125,12.8303386 3.125,12.3747037 L3.125,4.59213677 C3.125,4.13650185 3.49436508,3.76713677 3.95,3.76713677 Z" />
                      <path d="M9.25,3.76713677 C9.70563492,3.76713677 10.075,4.13650185 10.075,4.59213677 L10.075,12.3747037 C10.075,12.8303386 9.70563492,13.1997037 9.25,13.1997037 C8.79436508,13.1997037 8.425,12.8303386 8.425,12.3747037 L8.425,4.59213677 C8.425,4.13650185 8.79436508,3.76713677 9.25,3.76713677 Z" />
                      <path d="M6.6,3.76713677 C7.05563492,3.76713677 7.425,4.13650185 7.425,4.59213677 L7.425,12.3747037 C7.425,12.8303386 7.05563492,13.1997037 6.6,13.1997037 C6.14436508,13.1997037 5.775,12.8303386 5.775,12.3747037 L5.775,4.59213677 C5.775,4.13650185 6.14436508,3.76713677 6.6,3.76713677 Z" />
                    </g>
                    <g transform="translate(0.000000, 0.194854)">
                      <path d="M12.7738842,2.53887448 C14.0029911,2.53887448 14.999379,3.53526246 14.999379,4.76436932 L14.999379,6.48986417 C14.999379,6.76600654 14.7755214,6.98986417 14.499379,6.98986417 L0.900620964,6.98986417 C0.624478589,6.98986417 0.400620964,6.76600654 0.400620964,6.48986417 L0.400620964,4.76436932 C0.400620964,3.53526246 1.39700894,2.53887448 2.62611581,2.53887448 L12.7738842,2.53887448 Z M12.7738842,4.03887448 L2.62611581,4.03887448 C2.25882605,4.03887448 1.95528365,4.31180939 1.90724388,4.66592397 L1.90062096,4.76436932 L1.9,5.489 L13.499,5.489 L13.499379,4.76436932 C13.499379,4.39707956 13.2264441,4.09353717 12.8723295,4.04549739 L12.7738842,4.03887448 Z" />
                      <path d="M8.16395548,0.888874477 C9.02726375,0.888874477 9.73571646,1.55188991 9.807899,2.39650616 L9.81395548,2.53887448 L5.58604452,2.53887448 C5.58604452,1.62760464 6.32477468,0.888874477 7.23604452,0.888874477 L8.16395548,0.888874477 Z" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TrashIcon;
