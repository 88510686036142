/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import MessageCard from '../../common/message/message-card';
import { DashboardMessageType } from './DashboardMessageTypes';
import { GtfsEntitySelectorInput } from '../../../generated/global-types';
import { IRawImpact } from '../../common/message/impacts-display';

const DashboardMessageCard: React.FC<
  DashboardMessageType & {
    onClear: (id: number) => void;
  }
> & { children?: React.ReactNode } = ({
  id,
  eventId,
  createdAt,
  startAt,
  updatedAt,
  endAt,
  entitySelectors,
  taggedTrips,
  bodyHtml,
  onClear,
  webStatus,
  screensStatus,
  emailSmsStatus,
  tweetsStatus,
  isUpdate,
  author,
  impacts: rawImpacts,
}) => {
  const impacts = rawImpacts
    ? rawImpacts.nodes
        .map((impact) => {
          return {
            messageType: impact?.messageType,
            entitySelectors:
              impact?.entitySelectors as GtfsEntitySelectorInput[],
          };
        })
        .filter(
          (impact): impact is IRawImpact =>
            !!impact.messageType && !!impact.entitySelectors,
        )
    : undefined;

  return (
    <MessageCard
      hasUpdateLink
      css={css`
        margin-bottom: 22px;
        box-shadow:
          0 1px 1px 0 rgba(0, 0, 0, 0.14),
          0 1px 3px 0 rgba(0, 0, 0, 0.2);
      `}
      id={id || undefined}
      eventId={eventId || undefined}
      createdAt={startAt || createdAt}
      updatedAt={updatedAt}
      endAt={endAt}
      entitySelectors={entitySelectors}
      taggedTrips={taggedTrips}
      bodyHtml={bodyHtml}
      onClear={onClear}
      webStatus={webStatus}
      screensStatus={screensStatus}
      emailSmsStatus={emailSmsStatus}
      tweetsStatus={tweetsStatus}
      isUpdate={isUpdate ?? false}
      authorName={author && author.name ? author.name : undefined}
      impacts={impacts}
      impactsLimit={2}
    />
  );
};

export default DashboardMessageCard;
