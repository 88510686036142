/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';
import { centerRow } from '../styles';

export const Container: React.FC<{
  expires: boolean;
  isDraft?: boolean;
  className?: string;
  children?: React.ReactNode;
}> = ({ expires, isDraft = false, className, ...rest }) => (
  <section
    className={className}
    css={(theme: ThemeType) => css`
      background-color: ${theme.colors.white};
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      transition: border-color 0.2s;

      ${expires
        ? css`
            &::before {
              content: ' ';
              background-color: ${theme.colors.border};
              position: absolute;
              left: 0;
              width: 12px;
              height: 100%;
              top: 0;
            }
          `
        : ''}

      ${isDraft
        ? css`
            &::before {
              content: ' ';
              background-color: #d3ebff;
              position: absolute;
              left: 0;
              width: 12px;
              height: 100%;
              top: 0;
            }
          `
        : ''}
    `}
    {...rest}
  />
);

export const Content: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      padding-left: 14px;
      width: 100%;
    `}
    {...props}
  />
);

export const ContentRow: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 24px 0;
    `}
    {...props}
  />
);

export const DisplayContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      border-right: 1px solid #f8f8f8;
      display: flex;
      flex-direction: row;
      flex: 1 1 58%;
      padding: 0 ${theme.spacing.small};
    `}
    {...props}
  />
);

export const DisplayContainerInner: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 ${theme.spacing.small} 0 ${theme.spacing.xsmall};
      flex: 1;
    `}
    {...props}
  />
);

export const DisplayContentAfter: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
    `}
    {...props}
  />
);

export const Time: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={css`
      text-transform: lowercase;
    `}
    {...props}
  />
);

export const LinksContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={[
      centerRow,
      css`
        width: 100%;
        justify-content: space-between;
      `,
    ]}
    {...props}
  />
);

export const DetailLinkContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      align-self: flex-end;
      justify-self: flex-end;
    `}
    {...props}
  />
);

export const AfterMessageDisplayContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      position: relative;
      display: flex;
      align-items: flex-end;
      height: 100%;
      margin-left: 12px;
      margin-top: 8px;
    `}
    {...props}
  />
);

export const HistoryLine: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 14px;
      background-color: ${theme.colors.border};
      width: 1px;
    `}
    {...props}
  />
);

export const ViewHistoryContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
    `}
    {...props}
  />
);

export const HistoryCircle: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      position: relative;
      height: 9px;
      width: 9px;
      min-width: 9px;
      bottom: 3px;
      left: -4px;
      border-radius: 50%;
      background-color: ${theme.colors.accent1};
    `}
    {...props}
  />
);

export const PlainButton: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > & { children?: React.ReactNode }
> = (props) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      font-weight: ${theme.typography.weights.bolder};
      color: ${theme.colors.accent1};
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: ${theme.colors.accent3};
      }
    `}
    {...props}
  />
);
