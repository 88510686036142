/** @jsxImportSource @emotion/react */

import {
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
} from 'react';
import { css } from '@emotion/react';

import { container } from '../../common/styles';
import { ThemeType } from '../../../theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <div css={container} {...props} />;

export const PageContent: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={(theme: ThemeType) => css`
      display: flex;
      padding: ${theme.spacing.large};
      height: 100%;
      flex-direction: column;
    `}
    {...props}
  />
);

export const textAlignCenter = css`
  text-align: center;
`;

export const UserLink: React.FC<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> & { children?: React.ReactNode } = ({ children, ...rest }) => (
  <a
    css={(theme: ThemeType) => css`
      color: ${theme.colors.dark2};

      &:hover {
        color: ${theme.colors.accent1};
      }
    `}
    {...rest}
  >
    {children}
  </a>
);

export const SortButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = (props) => (
  <button
    type="button"
    css={css`
      width: 12px;
      height: 12px;
    `}
    {...props}
  />
);

export const ShowDeactivateButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = (props) => (
  <button
    type="button"
    css={(theme: ThemeType) => css`
      color: ${theme.colors['status-error']};
      text-transform: uppercase;
      font-weight: ${theme.typography.weights.bold};
      ${theme.typography.sizes.xsmall};

      &:disabled {
        cursor: default;
        opacity: 0.3;
      }
    `}
    {...props}
  />
);

export const Deactivated: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      color: ${theme.colors.dark2};
      text-transform: uppercase;
      font-weight: ${theme.typography.weights.bold};
      opacity: 0.5;

      ${theme.typography.sizes.xsmall};
    `}
    {...props}
  />
);
