/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import theme from 'theme';
import { Folders_socialMediaFolders_SocialMediaFoldersConnection_nodes_SocialMediaFolder as FolderType } from 'generated/Folders';
import Button from 'components/common/Button';
import { ReactComponent as FolderIcon } from '../../../images/folder-icon.svg';

const Folder: React.FC<{
  folder: FolderType;
  onClick: () => void;
  children?: React.ReactNode;
}> = ({ folder, onClick }) => (
  <Button
    onClick={onClick}
    title={folder.name}
    css={css`
      cursor: pointer;
      border: 1px solid #aaaaaa;
      border-radius: 4px;
      padding: 10px;
      width: 200px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: ${theme.colors.black};
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: none;
      text-align: left;

      &:hover {
        color: ${theme.colors.black};
        border-color: #757575;
        outline: 0;
      }
    `}
  >
    <FolderIcon />
    <span
      css={css`
        margin-left: 8px;
        font-size: 0.9rem;
        font-weight: ${theme.typography.weights.bold};
      `}
    >
      {folder.name}
    </span>
  </Button>
);

export default Folder;
