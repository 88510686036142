/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';

type AlertProps = { type: AlertType };

const alertMessages = {
  connectionError: 'A connection error has occured. Please sign in again.',
  logoutSuccess: 'You have successfully logged out.',
};

type AlertType = keyof typeof alertMessages;

export default function Alert(props: AlertProps): JSX.Element {
  const background = props.type === 'connectionError' ? '#f9f2b5' : '#e2eafd';
  const message = alertMessages[props.type];
  const styles = (theme: ThemeType) => css`
    background: ${background};
    border-radius: 4px;
    color: #4a4a4a;
    font-family: ${theme.typography.families.primary};
    min-height: 60px;
    line-height: 19px;
    margin: 0 auto 28px;
    max-width: 555px;
    padding: 21px 20px 19px;
  `;

  return <div css={styles}>{message}</div>;
}

export function isValidAlertType(input: unknown): input is AlertType {
  return typeof input === 'string' && input in alertMessages;
}
