/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';

import Theme, { ThemeType } from '../../theme';
import Heading from './Heading';

import { ReactComponent as IconMagnifier } from '../../images/icon_magnifier.svg';
import AppLink, { ApplinkStyles } from './app-link';

interface IProps {
  className?: string;
  icon?: boolean;
  heading: string;
  message: string;
  ctaLink?: string;
  ctaLabel?: string;
}

const NoMessages: React.FC<IProps> = ({
  className,
  icon,
  heading,
  message,
  ctaLink,
  ctaLabel = 'Compose Message',
  ...rest
}) => (
  <div
    css={(theme: ThemeType) => [
      css`
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 100%;
      `,
      theme.typography.sizes.large,
    ]}
    {...rest}
    className={className}
  >
    {icon && (
      <IconMagnifier
        css={() => css`
          margin-bottom: 24px;
        `}
      />
    )}
    <Heading level={3}>{heading}</Heading>
    <p
      css={(theme: ThemeType) => css`
        margin: 9px 0 ${theme.spacing.small};
      `}
    >
      {message}
    </p>
    {ctaLink && (
      <AppLink
        to={ctaLink}
        styleVariations={[
          ApplinkStyles.PRIMARY_BUTTON,
          ApplinkStyles.LARGE_BUTTON,
        ]}
        containerStyles={css`
          display: block;
          margin: ${Theme.spacing.small} 0;
        `}
      >
        {ctaLabel}
      </AppLink>
    )}
  </div>
);

export default NoMessages;
