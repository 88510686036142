/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { loader } from 'graphql.macro';

import React, { useState } from 'react';
import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import { useFeedId } from 'contexts/FeedId';
import Loader from 'components/common/Loader';

import { useMutation, ApolloError } from '@apollo/client';

import Button from 'components/common/Button';
import FallbackScreen, {
  FallbackScreenAsset,
} from 'components/common/fallback-screen';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';
import { useHistory } from 'react-router-dom';
import useScreenFallbackCounts from 'hooks/use-screen-fallback-counts';
import NoMessages from 'components/common/NoMessages';
import * as Sentry from '@sentry/browser';
import { Toast, useToast, ToastLevel } from 'ui-kit/toast';
import * as S from './index.styled';
import {
  CreateFallbackContent,
  CreateFallbackContentVariables,
} from '../../../generated/CreateFallbackContent';
import { getLabelFromTargetTypes } from '../Screen/in-screens-preview/helpers';

const CreateFallbackContentMutation = loader(
  '../../../graphql/CreateFallbackContent.gql',
);

const FallbackScreens: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const feedId = useFeedId();
  const history = useHistory();

  const [uploadedScreenAsset, setUploadedScreenAsset] = useState<
    FallbackScreenAsset[]
  >([]);
  const { shouldShowToast, showToast, hideToast } = useToast();
  const [toastText, setToastText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [createFallbackContent] = useMutation<
    CreateFallbackContent,
    CreateFallbackContentVariables
  >(CreateFallbackContentMutation, {
    onError: (error: ApolloError) => {
      throw error;
    },
  });

  const handleSubmit = () => async () => {
    setIsSubmitting(true);
    try {
      await createFallbackContent({
        variables: { feedId, fallbackScreens: uploadedScreenAsset },
      });
      setToastText(
        `Fallback assets have been uploaded to these screens: ${getLabelFromTargetTypes(
          uploadedScreenAsset.map((screenAsset) => screenAsset.type),
          feedId,
        )} successfully`,
      );
      showToast();
    } catch (e) {
      Sentry.captureException(e);
    }
    setIsSubmitting(false);
    setUploadedScreenAsset([]);
  };

  const { screenFallbackCounts, loading } = useScreenFallbackCounts();

  screenFallbackCounts.reduce(
    (acc, count) => {
      return {
        availableCount: acc.availableCount + count.availableCount,
        lockedCount: acc.lockedCount + count.lockedCount,
      };
    },
    { availableCount: 0, lockedCount: 0 },
  );

  const fallbackScreensContent = screenFallbackCounts.length ? (
    screenFallbackCounts?.map((screen) => {
      return (
        <FallbackScreen
          key={`${screen.targetType}-${screen.direction}`}
          fallbackScreen={screen}
          uploadedScreenAsset={uploadedScreenAsset}
          onUploadChange={setUploadedScreenAsset}
        />
      );
    })
  ) : (
    <NoMessages
      heading="No Available Screens!"
      message="There are no available screens to upload fallback images to."
    />
  );

  return (
    <div style={{ width: '100%' }}>
      <PageMeta title="Fallback Content" />
      <BackOnlyPageHeading
        back={{ to: `/${feedId}/screens`, title: 'Back to screens' }}
      />
      <Toast
        shouldShow={shouldShowToast}
        onDismiss={hideToast}
        level={ToastLevel.info}
      >
        {toastText}
      </Toast>
      <S.Content>
        <S.Section>
          <S.MainHeading>Fallback Content</S.MainHeading>
        </S.Section>
        <Loader loading={loading}>
          <div className="screens-holder">{fallbackScreensContent}</div>
        </Loader>
      </S.Content>
      {!!screenFallbackCounts.length && (
        <S.Buttons>
          <Button
            type="button"
            css={css`
              margin-right: 12px;
            `}
            onClick={() => {
              if (
                window.confirm(
                  `${DISCARD_CHANGES_MESSAGE} The fallback screens will not be updated.`,
                )
              ) {
                history.push(`/${feedId}/screens`);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            primary
            disabled={!uploadedScreenAsset.length || isSubmitting}
            onClick={handleSubmit()}
          >
            Update Fallback
          </Button>
        </S.Buttons>
      )}
    </div>
  );
};

export default FallbackScreens;
