import { useQuery, QueryResult } from '@apollo/client';
import { loader } from 'graphql.macro';
import { format } from 'date-fns';
import _isEqual from 'lodash/isEqual';

import { useState, useEffect } from 'react';
import { useFeedId } from '../contexts/FeedId';
import { useQueryParam } from './useQueryParams';

import {
  paramsToSort,
  queryToStatus,
  queryToMessageType,
} from '../components/common/planned-work/utils';
import { PlannedWorkStatus } from '../generated/global-types';
import { PlannedWorks, PlannedWorksVariables } from '../generated/PlannedWorks';
import { PLANNED_WORK_SORT_OPTIONS } from '../constants/planned-work';
import { TPlannedWorkTableSortingTypes } from '../components/common/planned-work/PlannedWorkTable';

const MESSAGES_PER_PAGE = 20;
const POLL_INTERVAL = 3 * 1000;

const sortOptions = PLANNED_WORK_SORT_OPTIONS;

const QUERY_PARAM_KEYS = {
  direction: 'dir',
  orderBy: 'order',
  page: 'page',
};

const PlannedWorksQuery = loader('../graphql/PlannedWorks.gql');

type IPlannedWorkQuery = QueryResult<PlannedWorks, PlannedWorksVariables>;

const formatDate = (date: string, isEnd: boolean = false) => {
  const dateObj = new Date(date);
  if (isEnd) {
    dateObj.setHours(dateObj.getHours() + 23);
    dateObj.setMinutes(dateObj.getMinutes() + 59);
  }
  return format(dateObj, 'yyyy-MM-dd HH:mm:ss');
};

export default function usePlannedWorkQuery(
  statusFilters: PlannedWorkStatus[],
) {
  const feedId = useFeedId();
  const [lineQuery] = useQueryParam('line');
  const [statusQuery] = useQueryParam('status');
  const [categoryQuery] = useQueryParam('category');
  const [liveQuery] = useQueryParam('live');
  const [searchQuery] = useQueryParam('search');
  const [startDateQuery] = useQueryParam('start_date');
  const [endDateQuery] = useQueryParam('end_date');
  const [allRoutesQuery] = useQueryParam('all_routes');

  // Pagination
  const [page, setPage] = useQueryParam(QUERY_PARAM_KEYS.page, '1');
  // Sorting
  const [orderBy, setOrderBy] = useQueryParam(QUERY_PARAM_KEYS.orderBy, 'id');
  const [direction, setDirection] = useQueryParam(
    QUERY_PARAM_KEYS.direction,
    'desc',
  );

  const pageNum = page ? parseInt(page, 10) : 1;

  const messageType = queryToMessageType(feedId, categoryQuery);

  const onHomepage = liveQuery === 'homepage' || undefined;
  const onScreens = liveQuery === 'screens' || undefined;

  const [cachedQuery, setCachedQuery] = useState<IPlannedWorkQuery | undefined>(
    undefined,
  );
  const [pollInterval, setPollInterval] = useState(0);

  const isPolling = pollInterval > 0;

  const query = useQuery<PlannedWorks, PlannedWorksVariables>(
    PlannedWorksQuery,
    {
      variables: {
        feedId,
        offset: MESSAGES_PER_PAGE * (pageNum - 1),
        perPage: MESSAGES_PER_PAGE,
        routeIds: lineQuery?.split(','),
        status: queryToStatus(statusQuery),
        messageType,
        onHomepage,
        onScreens,
        allRoutes: Boolean(allRoutesQuery),
        searchStr: searchQuery,
        filteredStatus: statusFilters,
        orderBy: paramsToSort(
          sortOptions,
          orderBy as TPlannedWorkTableSortingTypes,
          direction,
        ),
        startDate: startDateQuery ? formatDate(startDateQuery) : undefined,
        endDate: endDateQuery ? formatDate(endDateQuery, true) : undefined,
      },
      fetchPolicy: 'cache-and-network',
      pollInterval,
    },
  );

  // If any of the planned works are in the clearing state, we want to refetch
  // the data every 5 seconds, to have a near real-time view of the clearing status.
  const shouleBePolling = (query?.data?.searchPlannedWorks?.results ?? []).some(
    ({ status }) => status === PlannedWorkStatus.CLEARING,
  );

  useEffect(() => {
    if (shouleBePolling && !isPolling) {
      setPollInterval(POLL_INTERVAL);
    } else if (!shouleBePolling && isPolling) {
      setPollInterval(0);
    }
  }, [shouleBePolling, isPolling]);

  // While polling, we want to cache the query so that we can show the
  // progress of the clearing status. With that we don't want to show the
  // loading state for every refetch, so we only show the loading state when
  // the data or filters(variables) have changed.
  useEffect(() => {
    const shouldCache =
      isPolling &&
      (cachedQuery?.loading ||
        !_isEqual(
          {
            data: query.data,
            variables: query.variables,
          },
          {
            data: cachedQuery?.data,
            variables: cachedQuery?.variables,
          },
        ));
    if (shouldCache) {
      setCachedQuery(query);
    } else if (!isPolling && cachedQuery) {
      setCachedQuery(undefined);
    }
  }, [cachedQuery, query, isPolling]);

  const hasFilter =
    !!statusQuery ||
    !!categoryQuery ||
    !!lineQuery ||
    !!liveQuery ||
    !!searchQuery ||
    !!startDateQuery ||
    !!endDateQuery;

  return {
    query: cachedQuery ?? query,
    hasFilter,
    pageNum,
    perPage: MESSAGES_PER_PAGE,
    orderBy,
    direction,
    setPage,
    setOrderBy,
    setDirection,
  };
}
