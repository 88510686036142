/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';

import { ThemeType } from 'theme';
import { flexRow, flexCol } from 'components/common/styles';

export const Row: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => [
      flexRow,
      css`
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid ${theme.colors['border-dark']};
        background-color: #eaeaea;
      `,
    ]}
    {...props}
  />
);

export const SelectCol: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={[
      flexCol,
      css`
        flex: 0 1 100%;
        width: 100%;
        border-radius: 4px;
        background-color: white;
      `,
    ]}
    {...props}
  />
);

export const RemoveButtonBox: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      border-left: 1px solid ${theme.colors['border-dark']};
      color: red;
      padding: 16px 12px;
      text-align: center;
      flex: 0 0 36px;
    `}
    {...props}
  />
);

export const RemoveButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = (props) => (
  <button type="button" {...props} />
);

export const SelectContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      border-top: 1px solid ${theme.colors.border};

      .multi-route-stop-selector__value-container {
        margin-left: 12px;
      }

      .multi-route-stop-selector__group-heading {
        padding-left: 20px;
        color: #001840;
        text-transform: uppercase;
      }

      .multi-route-stop-selector__placeholder {
        padding-left: 0px;
      }

      &:first-of-type {
        border-top: none;
      }
    `}
    {...props}
  />
);

export const AddScreens: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = (props) => (
  <button
    type="button"
    css={(theme: ThemeType) => css`
      text-transform: uppercase;
      color: ${theme.colors.accent1};
      font-family: ${theme.typography.families.primary};
      ${theme.typography.sizes.xsmall};
      font-weight: ${theme.typography.weights.bolder};
      cursor: pointer;
    `}
    {...props}
  />
);
