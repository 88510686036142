/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { loader } from 'graphql.macro';
import ms from 'ms.macro';

/*
 * 1/7/2020
 * Type defintions are separately maintained, and are not current for v7.
 * Some parameters are cast to `any` until fixed.
 */
import { useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { getLabelFromTargetTypes } from 'components/pages/Screen/in-screens-preview/helpers';
import {
  INDEFINITE_END_DATE_MESSAGE,
  WEB_NO_CAMPAIGNS_MESSAGE,
  WEB_NO_MESSAGES_HEADING,
  WEB_NO_CAMPAIGNS_CTA_LABEL,
  WEB_NO_CAMPAIGNS_MESSAGE_FILTERS,
  WEB_NO_MESSAGES_HEADING_FILTERS,
  WEB_NO_CAMPAIGNS_CTA_LABEL_FILTERS,
} from 'constants/empty-states';
import { ReactComponent as EditIcon } from '../../../images/icon_edit_sm.svg';
import {
  TargetType,
  CampaignStatus,
  SearchCampaignsOrderBy,
  CampaignType,
} from '../../../generated/global-types';
import {
  Campaigns_searchCampaigns_SearchCampaignsResult_results_Campaign as CampaignTableData,
  Campaigns,
  CampaignsVariables,
} from '../../../generated/Campaigns';

import AppLink from '../../common/app-link';
import { BulletSize } from '../../common/Bullet';
import {
  BulletsCell,
  SortButton,
  TextCell,
  TableHeaderCell,
  TableData,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TablePagination,
} from '../../common/table-elements';
import { LiveCampaignStatusPill as CampaignStatusPill } from '../../common/campaign-status-pill';
import theme from '../../../theme';
import Loader from '../../common/skeletons/PageWithTable';
import { useFeedId } from '../../../contexts/FeedId';

import NoMessages from '../../common/NoMessages';

const CampaignsQuery = loader('../../../graphql/Campaigns.gql');

const DATE_FMT = 'MM/dd/yy';

const getGQLTargetTypeValue = (
  targetTypes: string[] | undefined,
): TargetType[] | null =>
  !targetTypes?.length
    ? null
    : Object.values(TargetType).filter((v) =>
        targetTypes.find((tt) => tt.toLowerCase() === v.toLowerCase()),
      );

const CampaignTable: React.FC<
  {
    onPrevClick: () => void;
    onNextClick: () => void;
    onSortClick: (oB: SearchCampaignsOrderBy) => void;
    page?: number;
    perPage?: number;
    search?: string;
    status?: CampaignStatus[];
    routes?: string[];
    targetTypes?: string[];
    type?: CampaignType;
    orderBy: SearchCampaignsOrderBy;
    filtersApplied?: boolean;
  } & { children?: React.ReactNode }
> = React.memo(
  ({
    page = 1,
    perPage = 5,
    onNextClick,
    onPrevClick,
    onSortClick,
    status = [],
    routes = [],
    targetTypes = [],
    search = null,
    type = CampaignType.REGULAR,
    orderBy,
    filtersApplied,
  }) => {
    const history = useHistory();
    const feedId = useFeedId();

    const variables = {
      first: perPage,
      offset: perPage * (page - 1),
      orderBy,
      targetTypes:
        targetTypes.length > 0 ? getGQLTargetTypeValue(targetTypes) : null,
      search: (search?.length ?? 0) > 0 ? search : null,
      routes: routes.length > 0 ? routes : null,
      status,
      feedId,
      type,
    };

    const query = useQuery<Campaigns, CampaignsVariables>(CampaignsQuery, {
      variables,
      fetchPolicy: 'cache-and-network',
    });

    const data: CampaignTableData[] = (
      query?.data?.searchCampaigns?.results ?? []
    )?.filter(
      // Filter out campaigns from the active dashboard that were in clearing
      // state, and are now cleared.
      (campaign) =>
        status.includes(CampaignStatus.CLEARED) ||
        campaign.status !== CampaignStatus.CLEARED,
    );
    const total = query.data?.searchCampaigns?.totalCount ?? 0;
    const { loading } = query;

    const columns: any[] = useMemo(() => {
      return [
        {
          accessor: 'titleAndId',
          Cell: ({ cell }: any) => {
            const { title, id, targetTypes } = cell.value;
            return (
              <TextCell
                css={css`
                  padding: 0 20px 0 16px;
                `}
                hasLeftPadding={false}
                heading={
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    `}
                  >
                    <AppLink
                      css={css`
                        color: ${theme.colors.black};
                        display: flex;
                        flex-direction: column;
                      `}
                      to={`/${feedId}/campaigns/${id}`}
                    >
                      <span>{title}</span>
                      <span
                        css={css`
                          ${theme.typography.sizes.xsmall}
                          font-weight: ${theme.typography.weights.normal};
                        `}
                      >
                        {getLabelFromTargetTypes(targetTypes, feedId)}
                      </span>
                    </AppLink>
                  </div>
                }
                headingSize="medium"
              />
            );
          },
          Header: () => {
            return (
              <SortButton
                title="Title"
                isSorted={orderBy.includes('TITLE')}
                isSortedDesc={orderBy === SearchCampaignsOrderBy.TITLE_DESC}
                onClick={() => {
                  onSortClick(
                    orderBy === SearchCampaignsOrderBy.TITLE_DESC
                      ? SearchCampaignsOrderBy.TITLE_ASC
                      : SearchCampaignsOrderBy.TITLE_DESC,
                  );
                }}
              />
            );
          },
        },
        {
          accessor: 'routeIds',
          Cell: ({ cell }: any) => {
            return (
              <BulletsCell
                textAlign="center"
                css={css`
                  max-width: 80px;
                `}
                size={BulletSize.medium}
                routeIds={cell.value}
                limit={4}
              />
            );
          },
          Header: () => {
            return 'Line(s)';
          },
        },
        {
          accessor: 'statusAndId',
          Cell: ({ cell }: any) => {
            const { id, status }: { id: number; status: CampaignStatus } =
              cell.value;
            return (
              <TextCell heading="" textAlign="center">
                <CampaignStatusPill
                  campaignId={id}
                  fallbackStatus={status}
                  size="small"
                  isSimple
                />
              </TextCell>
            );
          },
          Header: () => {
            return 'State';
          },
        },
        {
          accessor: 'start',
          Cell: ({ cell }: any) => {
            const start = cell.value;
            const dateText = format(new Date(start), DATE_FMT);
            return (
              <div
                css={css`
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                `}
              >
                <span />
                <TextCell
                  heading=""
                  textAlign="center"
                  descriptionSize="medium"
                >
                  {dateText}
                </TextCell>
                <span
                  css={css`
                    margin-left: 18px;
                  `}
                >
                  –
                </span>
              </div>
            );
          },
          Header: () => {
            return (
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <SortButton
                  css={css`
                    padding-right: 4px;
                  `}
                  title="Start"
                  isSorted={orderBy.includes('START')}
                  isSortedDesc={orderBy === SearchCampaignsOrderBy.START_DESC}
                  onClick={() => {
                    onSortClick(
                      orderBy === SearchCampaignsOrderBy.START_DESC
                        ? SearchCampaignsOrderBy.START_ASC
                        : SearchCampaignsOrderBy.START_DESC,
                    );
                  }}
                />
              </div>
            );
          },
        },
        {
          accessor: 'durationsEnd',
          Cell: ({ cell }: any) => {
            const end = cell.value;
            const dateText = end
              ? format(new Date(end), DATE_FMT)
              : INDEFINITE_END_DATE_MESSAGE;
            return (
              <TextCell heading="" textAlign="center" descriptionSize="medium">
                {dateText}
              </TextCell>
            );
          },
          Header: () => {
            return (
              <SortButton
                css={css`
                  padding-right: 4px;
                `}
                title="End"
                isSorted={orderBy.includes('END')}
                isSortedDesc={orderBy === SearchCampaignsOrderBy.END_DESC}
                onClick={() => {
                  onSortClick(
                    orderBy === SearchCampaignsOrderBy.END_DESC
                      ? SearchCampaignsOrderBy.END_ASC
                      : SearchCampaignsOrderBy.END_DESC,
                  );
                }}
              />
            );
          },
        },
        {
          accessor: 'createdAt',
          Cell: ({ cell }: any) => {
            return (
              <TextCell heading="" textAlign="center" descriptionSize="medium">
                {format(new Date(cell.value), DATE_FMT)}
              </TextCell>
            );
          },
          Header: () => {
            return (
              <SortButton
                css={css`
                  padding-right: 4px;
                `}
                title="Created"
                isSorted={orderBy.includes('CREATED_AT')}
                isSortedDesc={
                  orderBy === SearchCampaignsOrderBy.CREATED_AT_DESC
                }
                onClick={() => {
                  onSortClick(
                    orderBy === SearchCampaignsOrderBy.CREATED_AT_DESC
                      ? SearchCampaignsOrderBy.CREATED_AT_ASC
                      : SearchCampaignsOrderBy.CREATED_AT_DESC,
                  );
                }}
              />
            );
          },
        },
        {
          accessor: 'id',
          Cell: ({
            row: {
              values: { id: editId, status },
            },
          }: any) => {
            if (status === CampaignStatus.CLEARED) {
              return null;
            }

            return (
              <button
                type="button"
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: ${theme.colors.accent1};
                  border: none;
                  background: none;
                  cursor: pointer;
                  width: 100%;
                `}
                onClick={() =>
                  history.push(`/${feedId}/campaigns/${editId}/edit`)
                }
              >
                <EditIcon
                  css={css`
                    transform: scale(1.6);
                  `}
                />
              </button>
            );
          },
          Header: (): null => {
            return null;
          },
        },
      ];
    }, [orderBy, onSortClick, feedId, history]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data: (data ?? []).map((c: any) => ({
          titleAndId: {
            title: c.title,
            id: c.id,
            targetTypes: c.targetTypes,
          },
          statusAndId: {
            status: c.status,
            id: c.id,
          },
          start: c.start,
          end: c.end,
          ...c,
        })),
      });

    return (
      <div
        css={css`
          width: 100%;
        `}
      >
        <Loader
          loading={loading}
          after={data.length ? ms('3 seconds') : undefined}
        >
          {data.length ? (
            <React.Fragment>
              <Table
                css={css`
                  width: 100%;
                `}
                {...getTableProps()}
              >
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => {
                        return (
                          <TableHeaderCell {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </TableHeaderCell>
                        );
                      })}
                    </tr>
                  ))}
                </TableHead>

                <TableBody
                  {...getTableBodyProps()}
                  css={css`
                    box-shadow:
                      0 1px 1px 0 rgba(0, 0, 0, 0.14),
                      0 1px 3px 0 rgba(0, 0, 0, 0.2);
                  `}
                >
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        {...row.getRowProps()}
                        css={css`
                          height: 72px;
                        `}
                      >
                        {row.cells.map((cell) => {
                          const hasLeftBorder = ![
                            'preview',
                            'titleAndId',
                            'end',
                          ].includes(cell.column.id);

                          return (
                            <TableData
                              hasLeftBorder={hasLeftBorder}
                              width={cell.column.id === 'id' ? '72px' : 'auto'}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </TableData>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                page={page}
                perPage={perPage}
                total={total}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
              />
            </React.Fragment>
          ) : (
            <NoMessages
              css={css`
                margin-top: 45px;
              `}
              icon={filtersApplied}
              heading={
                filtersApplied
                  ? WEB_NO_MESSAGES_HEADING_FILTERS
                  : WEB_NO_MESSAGES_HEADING
              }
              message={
                filtersApplied
                  ? WEB_NO_CAMPAIGNS_MESSAGE_FILTERS
                  : WEB_NO_CAMPAIGNS_MESSAGE
              }
              ctaLabel={
                filtersApplied
                  ? WEB_NO_CAMPAIGNS_CTA_LABEL_FILTERS
                  : WEB_NO_CAMPAIGNS_CTA_LABEL
              }
              ctaLink={`/${feedId}/campaigns/compose`}
            />
          )}
        </Loader>
      </div>
    );
  },
);

export default CampaignTable;
