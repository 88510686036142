import { Tweet as TweetPatch } from 'components/common/tweets/tweet-thread-input';
import { TargetingOption } from 'utils/stop-selector-helpers';
import {
  MessageType,
  MentionType,
  TargetType,
  EmailMessageTypeCodes,
  SchedulePriority,
  CardinalDirection,
} from '../generated/global-types';
import { MentionSuggestions_mentionSuggestions_MentionsConnection_nodes_Mention as MentionSuggestions_mentionSuggestions_nodes } from '../generated/MentionSuggestions';
import {
  WEB_IMPACTS_NAME,
  WEB_BODY_NAME,
  WEB_EXPIRATION_NAME,
  WEB_TARGETED_ROUTES_NAME,
  WEB_TARGETED_STOPS_NAME,
  EMAIL_SUBJECT_NAME,
  EMAIL_BODY_NAME,
  EMAIL_TYPES_NAME,
  TWEET_CUSTOMIZED_NAME,
  TWEETS_NAME,
  SCREEN_TITLE_NAME,
  SCREEN_BODY_NAME,
  SCREEN_TARGETED_ROUTES_NAME,
  SCREEN_CUSTOMIZED_NAME,
  SCREEN_TARGETING_NAME,
  SCREEN_UPDATE_METHOD_NAME,
  INTERNAL_LOG_INCIDENT_START_AT,
  INTERNAL_LOG_INCIDENT_END_AT,
  INTERNAL_LOG_NOTIFIED_AT,
  INTERNAL_LOG_NOTES,
  WEB_BODY_ADDITIONAL_NAME,
  SCREEN_BODY_ADDITIONAL_NAME,
  AFFECTED_STOPS_NAME,
  AFFECTED_STOPS_EDITED_NAME,
  WEIGHT_FREQUENCY_NAME,
  INITIAL_SCREEN_CHOICE_NAME,
  SCREENS_NAME,
  SCREEN_TARGETING_EDITED,
  SCREEN_IS_DUPLICATE_NAME,
  SCREEN_IS_CLEARING_NAME,
  SMS_BODY_NAME,
} from '../constants/alerts';
import { EditorState } from 'remirror';

export enum ScreenUpdateMethods {
  REMOVE = 'remove',
  REPLACE = 'replace',
  KEEP = 'keep',
}

export enum AlertScreenTargeting {
  STATION_SCREEN = 'STATION_SCREEN',
  SIDEWALK_SCREEN = 'SIDE_WALK_SCREEN',
}

export type ScreenData = {
  [SCREEN_TITLE_NAME]?: string;
  [SCREEN_BODY_NAME]?: any;
  [SCREEN_BODY_ADDITIONAL_NAME]?: any;
  [SCREEN_CUSTOMIZED_NAME]: boolean;
  [SCREEN_TARGETING_NAME]: ScreenTarget[];
  [SCREEN_UPDATE_METHOD_NAME]?: ScreenUpdateMethods;
  [WEIGHT_FREQUENCY_NAME]: {
    priority: SchedulePriority;
    weight: number;
  };
  [INITIAL_SCREEN_CHOICE_NAME]: AlertScreenTargeting | undefined;
  [SCREEN_TARGETING_EDITED]: boolean;
  [SCREEN_IS_DUPLICATE_NAME]?: boolean;
  [SCREEN_IS_CLEARING_NAME]?: boolean;
};

export type ComposeFormData = {
  [WEB_IMPACTS_NAME]: TImpact[];
  [WEB_BODY_NAME]: any;
  [WEB_BODY_ADDITIONAL_NAME]?: any;
  [WEB_EXPIRATION_NAME]: Date;
  [WEB_TARGETED_ROUTES_NAME]: RouteMention[];
  [WEB_TARGETED_STOPS_NAME]: StopMention[];
  [EMAIL_SUBJECT_NAME]: string;
  [EMAIL_BODY_NAME]: string | EditorState;
  [EMAIL_TYPES_NAME]: EmailMessageTypeCodes[];
  [SMS_BODY_NAME]: string;
  [TWEETS_NAME]: TweetPatch[];
  [TWEET_CUSTOMIZED_NAME]: boolean;
  [SCREENS_NAME]: ScreenData[];
  [SCREEN_TARGETED_ROUTES_NAME]: RouteMention[];
  [INTERNAL_LOG_INCIDENT_START_AT]: string;
  [INTERNAL_LOG_INCIDENT_END_AT]: string;
  [INTERNAL_LOG_NOTIFIED_AT]: string;
  [INTERNAL_LOG_NOTES]: string;
  [AFFECTED_STOPS_NAME]?: AffectedStops;
  [AFFECTED_STOPS_EDITED_NAME]?: boolean;
};

export type AffectedStops = {
  directionality: CardinalDirection | null;
  gtfsStopIds: string[] | null;
};

export type RouteMention = Omit<
  MentionSuggestions_mentionSuggestions_nodes,
  '__typename' | 'feedId' | 'color' | 'textColor'
> & {
  routeId: string;
  isAffected?: boolean;
  autoAdded?: boolean;
  hasAllStopsUnselected?: boolean;
  type: MentionType.ROUTE;
};

export type TripMention = Omit<
  MentionSuggestions_mentionSuggestions_nodes,
  '__typename' | 'feedId' | 'color' | 'textColor'
> & {
  tripId: string;
  isAffected?: boolean;
  type: MentionType.TRIP;
};

export type StopMention = Omit<
  MentionSuggestions_mentionSuggestions_nodes,
  '__typename' | 'feedId' | 'color' | 'textColor'
> & {
  stopId: string;
  isAffected?: boolean;
  type: MentionType.STOP;
};

export type Mention = RouteMention | TripMention | StopMention;

export type EntireRouteOption = {
  key: string;
  label: string;
  bulletValue: string;
  routeId: string;
  feedId: string;
};

export type TagsSelection = { [key: string]: any };

export type ScreenTarget = {
  routeId: string;
  options: TargetingOption[];
  layouts: TargetType[];
  tags?: TagsSelection;
};

export type TImpact = {
  messageType: MessageType;
  routes: RouteMention[];
  isAgencyWide: boolean;
};

export type MaxLength = {
  max: number;
  current: number;
};
