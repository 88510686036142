/** @jsxImportSource @emotion/react */

import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import { useDebounce } from 'use-debounce';
import theme, { ThemeType } from 'theme';
import { ReactComponent as CrossBlueIcon } from 'images/cross-blue.svg';
import { listboxList } from '../components/common/styles';

const container = css`
  border: 1px solid ${theme.colors['border-dark']};
  padding: 8px;
  background: #fff;
  position: relative;
  display: flex;
  border-radius: 4px;
  box-shadow: inset 0 0 4px ${theme.colors['border-dark']};

  &:focus-within {
    border-color: ${theme.colors.accent3};
  }

  flex-wrap: wrap;
`;

const tagStyle = (theme: ThemeType) => css`
  ${theme.typography.sizes.small};
  background: #eee;
  padding: 0 8px;
  margin-right: 8px;
  flex: 0 0 auto;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 2px;
`;

const combobox = css`
  flex: 1 1 auto;
  margin: -8px;
`;

const input = css`
  padding: 12px 20px;
  background: none;
  border: none;
  outline: 0;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }
`;

const option = css`
  white-space: nowrap;

  &[data-highlighted] {
    background: #003;
    color: #fff;
  }
`;

const resultsStyle = css`
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 10;
`;

const removeButtonStyle = css`
  margin-left: 8px;
  height: 15px;
  width: 10px;
`;

function isNotNull(value: string | null): value is string {
  return value !== null;
}

const TagEntry: React.FC<
  {
    id: string;
    onChange?: (arg0: string[]) => void;
    value?: (null | string)[];
    tags?: string[];
  } & { children?: React.ReactNode }
> = ({ id, onChange = () => {}, value = [], tags = [] }) => {
  const [draftTag, setDraftTag] = useState('');
  const [draftTagValue] = useDebounce(draftTag, 100);

  const cachedTags = useMemo(() => tags, [tags]);

  const results: { value: string; label: string }[] = draftTagValue
    ? cachedTags
        .filter((t) => t.indexOf(draftTagValue) === 0 && !value.includes(t))
        .map((tag) => ({ value: tag, label: tag }))
    : [];

  if (draftTagValue && !cachedTags.includes(draftTagValue)) {
    results.push({ value: draftTagValue, label: `Create “${draftTagValue}”` });
  }
  const filteredValue: string[] = value.filter(isNotNull);

  return (
    <div css={container}>
      {filteredValue.map((tag) => (
        <span key={tag} css={tagStyle}>
          <span>{tag}</span>
          <button
            type="button"
            css={removeButtonStyle}
            onClick={() => onChange(filteredValue.filter((t) => tag !== t))}
            title={`Remove value: "${tag}"`}
          >
            <CrossBlueIcon
              css={css`
                height: 100%;
                width: 100%;
              `}
            />
          </button>
        </span>
      ))}
      <Combobox
        css={combobox}
        aria-labelledby="TODO"
        onSelect={(newValue) => {
          onChange(filteredValue.concat([newValue]));
          setDraftTag('');
        }}
      >
        <ComboboxInput
          id={id}
          css={input}
          value={draftTag}
          onChange={(e: any) => {
            setDraftTag(e.target.value);
          }}
        />
        {results && (
          <ComboboxPopover css={resultsStyle} portal={false}>
            <ComboboxList css={listboxList}>
              {results.map(({ value, label }) => (
                <ComboboxOption key={value} css={option} value={value}>
                  {label}
                </ComboboxOption>
              ))}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    </div>
  );
};

export default TagEntry;
