import React, { createContext, useContext } from 'react';
import { loader } from 'graphql.macro';
import useAuthentication from './Authentication';
import { CurrentUser } from '../generated/CurrentUser';
import { CurrentUserFragment as CurrentUserType } from 'generated/CurrentUser.fragment';

import useLiveQuery from '../hooks/use-live-query';

const CurrentUserQuery = loader('../graphql/CurrentUser.gql');

const CurrentUserContext = createContext<CurrentUserType | null>(null);

export const CurrentUserProvider: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ children }) => {
  const { session, isFetching } = useAuthentication();
  const skip = !session.isAuthenticated || isFetching;
  const query = useLiveQuery<CurrentUser>(CurrentUserQuery, {
    skip,
  });

  if (query.loading) {
    return null;
  }
  const value = query.data?.currentUser ?? null;

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);
