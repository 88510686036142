/** @jsxImportSource @emotion/react */
import React from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import { ThemeType } from 'theme';
import { useFeedId } from 'contexts/FeedId';
import { Link } from 'react-router-dom';

import StatusIcon from 'components/common/status-icon';

import * as S from './index.styled';
import { ReactComponent as DownloadIcon } from '../../../images/icon_download.svg';
import { ReactComponent as EditIcon } from '../../../images/icon_edit_sm.svg';
import { DATE_MONTH_DAY_FORMAT } from '../../../constants/time';

const GeneratedLogItem: React.FC<
  {
    fromDate: Date;
    toDate?: Date | null;
    s3Url?: string | null;
    current?: boolean;
    isBeingGenerated?: boolean;
    generateReport?: () => void;
  } & { children?: React.ReactNode }
> = ({
  fromDate,
  toDate,
  s3Url,
  current = false,
  isBeingGenerated,
  generateReport,
}) => {
  const feedId = useFeedId();

  let fromLabel = format(fromDate, DATE_MONTH_DAY_FORMAT);
  const fromYear = fromDate.getFullYear();
  const toYear = (toDate ?? new Date()).getFullYear();
  if (fromYear !== toYear) {
    fromLabel = `${fromLabel}, ${fromYear}`;
  }
  const toLabel = toDate ? format(toDate, DATE_MONTH_DAY_FORMAT) : 'Now';
  const label = `${fromLabel} - ${toLabel}`;

  return (
    <div
      css={(theme: ThemeType) => css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0px;
        padding: 18px 20px;
        font-size: ${theme.typography.sizes.small.fontSize};
        font-weight: ${theme.typography.weights.bold};
        border-top: 1px #ccc solid;
      `}
    >
      <div>
        {label}
        {current && <S.TagPill>Current</S.TagPill>}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {!toDate && (
          <Link to={`/${feedId}/alerts/cleared`}>
            <button
              type="button"
              css={css`
                border: none;
                background: none;
                cursor: pointer;
                margin-right: 20px;
              `}
            >
              <EditIcon
                css={css`
                  transform: scale(1.6);
                `}
              />
            </button>
          </Link>
        )}
        {current && !isBeingGenerated && (
          <button
            onClick={generateReport}
            type="button"
            css={css`
              border: none;
              background: none;
              cursor: pointer;
            `}
          >
            <DownloadIcon aria-hidden="true" />
          </button>
        )}
        {current && isBeingGenerated && (
          <StatusIcon status="loading" size={20} />
        )}
        {!current && (
          <a href={`${s3Url}`}>
            <DownloadIcon aria-hidden="true" />
          </a>
        )}
      </div>
    </div>
  );
};

export default GeneratedLogItem;
