import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useQueryParam } from 'hooks/useQueryParams';
import * as S from './index.styled';
import PageMeta from '../../common/PageMeta';
import PageHeading from '../../scaffolding/PageHeading';
import FeatureFlagsTable from './FeatureFlagsTable';
import {
  FeatureFlags as FeatureFlagsType,
  FeatureFlagsVariables,
} from '../../../generated/FeatureFlags';
import Loader from '../../common/Loader';
import { FeedId } from '../../../types/feeds';

const FeatureFlagsQuery = loader('../../../graphql/FeatureFlags.gql');

const FEATURE_FLAGS_PER_PAGE = 50;

const FeatureFlags: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const [page, setPage] = useQueryParam('page', '1');

  // Page number param to integer
  const pageNumber = page ? parseInt(page, 10) : 1;
  const isFirstPage = pageNumber === 1;

  const offset = isFirstPage
    ? 0
    : (pageNumber - 1) * FEATURE_FLAGS_PER_PAGE - 1;
  const perPage = isFirstPage
    ? FEATURE_FLAGS_PER_PAGE - 1
    : FEATURE_FLAGS_PER_PAGE;

  const { data: featureFlagsData, loading: featureFlagsLoading } = useQuery<
    FeatureFlagsType,
    FeatureFlagsVariables
  >(FeatureFlagsQuery, {
    variables: {
      offset,
      perPage,
    },
  });

  const nodes = featureFlagsData?.featureFlags?.nodes;
  const totalCount = featureFlagsData?.featureFlags?.totalCount || 0;
  const featureFlags = nodes || [];

  return (
    <S.Container>
      <PageMeta title="Feature Flags Management" />
      <PageHeading title="Feature Flags Management" />
      <S.PageContent>
        <Loader loading={featureFlagsLoading && totalCount <= 0}>
          <FeatureFlagsTable
            onNextClick={() => setPage(`${pageNumber + 1}`)}
            onPrevClick={() => setPage(`${pageNumber - 1}`)}
            featureFlags={featureFlags}
            feedGroups={Object.values(FeedId)}
            page={pageNumber}
            perPage={FEATURE_FLAGS_PER_PAGE}
            totalCount={totalCount}
          />
        </Loader>
      </S.PageContent>
    </S.Container>
  );
};

export default FeatureFlags;
