/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import Modal from './modal';
import Button from './Button';
import { ThemeType } from '../../theme';

export type ConfirmModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  title: React.ReactNode;
  confirmText?: string;
  dismissText?: string;
  form?: string;
  message?: string;
  onConfirm?: () => void;
};

const ConfirmModal: React.FC<
  ConfirmModalProps & { children?: React.ReactNode }
> = ({
  isOpen,
  message,
  onConfirm,
  onDismiss,
  title,
  form,
  dismissText = 'Go Back',
  confirmText = 'Confirm',
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
      {message && (
        <p
          css={(theme: ThemeType) => css`
            ${theme.typography.sizes.medium};
            font-family: ${theme.typography.families.primary};
            margin: 0px;
            color: ${theme.colors.black};
          `}
        >
          {message}
        </p>
      )}
      {children}
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        `}
      >
        <Button type="button" onClick={onDismiss}>
          {dismissText}
        </Button>
        <Button
          primary
          type={onConfirm ? 'button' : 'submit'}
          onClick={onConfirm}
          css={(theme: ThemeType) => css`
            margin-left: ${theme.spacing.xsmall};
          `}
          form={form}
        >
          {confirmText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
