/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import useShouldShowSkeleton from './hooks/useShouldShowSkeleton';
import AlertCard from './components/AlertCard';
import { LoaderComponent } from './types';

const PageWithCards: LoaderComponent = ({ loading, after, children }) => {
  const shouldShowSkeleton = useShouldShowSkeleton(loading, { after });
  return (
    <React.Fragment>
      {shouldShowSkeleton ? (
        <div
          css={(theme) => css`
            width: 100%;
            > div {
              margin-bottom: ${theme.spacing.large};
            }
          `}
        >
          <AlertCard />
          <AlertCard />
          <AlertCard />
        </div>
      ) : (
        children
      )}
    </React.Fragment>
  );
};

export default PageWithCards;
