/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import { ThemeType } from '../../theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      border-radius: 8px;
      box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      margin: ${theme.spacing.small} ${theme.spacing.large};
      &:first-of-type {
        margin-top: 0;
      }
    `}
    {...props}
  />
);

export const Section: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={(theme: ThemeType) => css`
      padding: 20px 40px 20px;
      &:not(:last-of-type) {
        border-bottom: 1px solid ${theme.colors.border};
      }
    `}
    {...props}
  />
);
