/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Fragment, useState } from 'react';

import Textarea from 'ui-kit/textarea';
import { TWEET_MAX_LENGTH, getTweetLength } from 'utils/twitter-helpers';
import { AttachmentsType } from 'types/twitter-attachments';

import Toggle from 'components/common/form-elements/Toggle';
import { ThemeType } from 'theme';
import { FeedId } from '@mta-live-media-manager/shared';
import Label from './label';
import ImageAttachments from './image-attachments';
import colors from '../../../../theme/colors';

import { TWITTER_USERNAMES } from '../../../../constants/twitter';

type Props = Omit<
  Omit<React.ComponentProps<typeof Textarea>, 'header'>,
  'footer'
> & {
  maxLength?: number;
  text: string;
  onTextChange: (newText: string) => void;
  attachments: AttachmentsType;
  onAttachmentsChange: (newAttachments: AttachmentsType) => void;
  onDismiss?: () => void;
  unThread?: () => void;
  originalTweet?: string;
  quoteOriginalTweet?: boolean;
  feedId?: FeedId;
  quoteId: string;
  onQuoteIdChange: (newQuoteId: string) => void;
  replyId: string;
  onReplyIdChange: (newReplyId: string) => void;
};

const TweetInput: React.FC<Props> = ({
  id = 'tweet-input',
  maxLength = TWEET_MAX_LENGTH,
  text,
  onTextChange,
  attachments,
  onAttachmentsChange,
  onDismiss,
  unThread,
  originalTweet,
  feedId,
  quoteId,
  onQuoteIdChange,
  replyId,
  onReplyIdChange,
  ...rest
}) => {
  const [quoteOriginalTweet, setValue] = useState(false);
  const originalTweetLink =
    feedId !== undefined
      ? `https://twitter.com/${TWITTER_USERNAMES[feedId]}/status/${originalTweet}`
      : '';
  return (
    <Textarea
      {...rest}
      value={text}
      onChange={(evt) => onTextChange(evt.target.value)}
      header={
        <Label
          id={id}
          length={getTweetLength(text)}
          maxLength={maxLength}
          onDismiss={onDismiss}
          unThread={unThread}
        />
      }
      footer={
        <Fragment>
          {originalTweet && (
            <div
              css={css`
                display: block;
                position: relative;
                margin-bottom: 8px;
              `}
            >
              <div
                css={css`
                  width: 100%;
                  display: flex;
                `}
              >
                <Toggle
                  id="toggle_quote_tweet"
                  css={css`
                    :is(input) {
                      display: flex;
                      justify-content: center;
                      margin-right: 8px;
                      width: 32px;
                      height: 16px;
                    }

                    :is(div) {
                      width: 12px;
                      height: 12px;
                      left: 2px;
                      top: 2px;
                      transform: ${quoteOriginalTweet ? 'translate(16px)' : ''};
                    }

                    svg {
                      width: 8px;
                      height: 8px;
                    }
                  `}
                  checked={quoteOriginalTweet}
                  onChange={() => {
                    setValue(!quoteOriginalTweet);
                    onQuoteIdChange(!quoteOriginalTweet ? originalTweet : '');
                  }}
                />
                <label
                  htmlFor="toggle_quote_tweet"
                  css={(theme: ThemeType) => css`
                    ${theme.typography.sizes.small}
                    padding: 0;
                    margin: auto 0;
                    text-transform: uppercase;
                    font-weight: 750;
                    line-height: 1.2;
                    font-size: 0.68rem;
                    color: ${quoteOriginalTweet
                      ? theme.colors.blue
                      : theme.colors.dark2};
                  `}
                >
                  Quote Original Tweet
                </label>
              </div>
              {quoteOriginalTweet && originalTweet && (
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    background-color: ${colors.light4};
                    padding-left: 12px;
                    margin-top: 8px;
                    margin-bottom: 12px;
                  `}
                >
                  <p
                    css={css`
                      font-weight: 600;
                    `}
                  >
                    Original Tweet
                  </p>
                  <a
                    css={css`
                      margin: auto 0;
                      padding-left: 12px;
                    `}
                    href={originalTweetLink}
                  >
                    {originalTweetLink}
                  </a>
                </div>
              )}
            </div>
          )}
          <ImageAttachments
            attachments={attachments}
            onChange={onAttachmentsChange}
          />
        </Fragment>
      }
      id={id}
    />
  );
};

export default TweetInput;
