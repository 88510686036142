/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { ChangeEvent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import theme from 'theme';
import Button from 'components/common/Button';
import StatusIcon from 'components/common/status-icon';
import RemainingCharacters from 'components/common/form-elements/RemainingCharacters';
import { input as inputCss } from 'components/common/styles';

import { MaxLength } from 'types';
import { ReactComponent as PencilEditIcon } from '../../images/icon_edit_sm.svg';
import { ReactComponent as RollbackIcon } from '../../images/icon_rollback.svg';

const Loading: React.FC<unknown & { children?: React.ReactNode }> = () => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        height: 100%;
        padding: 14px 24px;
      `}
    >
      <StatusIcon status="loading" size={20} />
    </div>
  );
};

const ActionButton: React.FC<
  {
    onClick: () => void;
    additionalStyles?: SerializedStyles;
    children: React.ReactNode;
    ariaLabel?: string;
  } & { children?: React.ReactNode }
> = ({ onClick, ariaLabel, additionalStyles, children }) => {
  return (
    <Button
      plain
      type="button"
      onClick={onClick}
      size="small"
      aria-label={ariaLabel}
      css={css`
        &:hover path {
          fill: ${theme.colors.brand};
        }
        ${additionalStyles}
      `}
    >
      {children}
    </Button>
  );
};

const EditButton: React.FC<
  {
    onUnlock: () => void;
    ariaLabel?: React.ReactNode;
    editText?: string;
    additionalStyles?: SerializedStyles;
  } & { children?: React.ReactNode }
> = ({ onUnlock, ariaLabel, editText, additionalStyles }) => {
  return (
    <ActionButton
      onClick={onUnlock}
      ariaLabel={`Edit ${ariaLabel}`}
      additionalStyles={additionalStyles}
    >
      <span
        css={css`
          padding-right: 3px;
        `}
        aria-hidden="true"
      >
        <PencilEditIcon />
      </span>
      {editText ?? 'Edit'}
    </ActionButton>
  );
};

const RevertButton: React.FC<
  {
    onRevert: () => void;
    ariaLabel?: React.ReactNode;
    revertText?: string;
    additionalStyles?: SerializedStyles;
  } & { children?: React.ReactNode }
> = ({ onRevert, ariaLabel, revertText, additionalStyles }) => {
  return (
    <ActionButton
      onClick={onRevert}
      ariaLabel={`Revert ${ariaLabel}`}
      additionalStyles={additionalStyles}
    >
      <span
        css={css`
          padding-right: 3px;
        `}
        aria-hidden="true"
      >
        <RollbackIcon />
      </span>
      {revertText ?? 'Revert to Default'}
    </ActionButton>
  );
};

type TActionPosition = 'top' | 'bottom';

interface TGetAction {
  isLocked?: boolean;
  editText?: string;
  title?: React.ReactNode;
  onUnlock?: () => void;
  onRevert?: () => void;
  maxLength?: MaxLength;
  htmlFor?: string;
}

const getAction = ({
  isLocked,
  onUnlock,
  onRevert,
  editText,
  title,
  maxLength,
  htmlFor,
}: TGetAction): React.ReactNode => {
  const showEdit = isLocked && onUnlock;
  const showRevert = !isLocked && onRevert;
  const showRemaining = maxLength && !isLocked;

  if (showEdit) {
    return (
      <div
        css={css`
          display: flex;
        `}
      >
        <EditButton
          onUnlock={onUnlock!}
          ariaLabel={title}
          editText={editText}
        />
      </div>
    );
  }

  if (showRevert) {
    return (
      <div
        css={css`
          display: flex;
          column-gap: 0.5rem;
          align-items: center;
        `}
      >
        {showRemaining && (
          <RemainingCharacters maxLength={maxLength!} htmlFor={htmlFor} />
        )}
        <RevertButton onRevert={onRevert!} ariaLabel={title} />
      </div>
    );
  }

  if (showRemaining) {
    return <RemainingCharacters maxLength={maxLength!} htmlFor={htmlFor} />;
  }

  return null;
};

interface EditableTextPreviewProps {
  className?: string;
  id?: string;
  invalid?: boolean;
  isGrayscale?: boolean;
  isLocked?: boolean;
  isCollapsible?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onUnlock?: () => void;
  title?: React.ReactNode;
  value?: string;
  actionPosition?: TActionPosition;
  editText?: string;
  helperMessage?: string;
  additionalStyles?: string;
  isLoading?: boolean;
  onRevert?: () => void;
  maxLength?: MaxLength;
}

export const EditableTextPreview: React.FC<EditableTextPreviewProps> = ({
  className,
  id,
  invalid,
  isLocked,
  onChange,
  onUnlock,
  title,
  value,
  isGrayscale,
  isCollapsible,
  actionPosition = 'top',
  editText,
  helperMessage,
  additionalStyles,
  isLoading,
  onRevert,
  maxLength,
}) => {
  const lockColor = isGrayscale ? '#f8f8f8' : '#e7eff6';
  const borderColor = isLocked ? lockColor : theme.colors['border-dark'];
  const canPerformAction = (onUnlock && isLocked) || (onRevert && !isLocked);
  const getActionParams = {
    isLocked,
    onUnlock,
    onRevert,
    editText,
    title,
    maxLength,
    htmlFor: id,
  };

  return (
    <div className={className}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: ${!!title || canPerformAction ? '8px' : '0px'};
        `}
      >
        {!!title && (
          <div>
            <div
              css={css`
                ${theme.typography.sizes.medium};
                font-weight: ${theme.typography.weights.bold};
              `}
            >
              {title}
            </div>
          </div>
        )}
        {actionPosition === 'top' && getAction(getActionParams)}
      </div>
      <div
        css={css`
          ${additionalStyles}
          background-color: ${isLocked ? lockColor : theme.colors.white};
          border-radius: 4px;
          line-height: 0;

          textarea {
            ${theme.typography.sizes.medium};
            color: ${theme.colors.black};
            font-family: ${theme.typography.families.primary};
            border: 1px solid
              ${invalid ? theme.colors['status-error'] : borderColor};
            width: 100%;
            resize: none;
            padding: 16px 24px;
            background-color: transparent;
            box-sizing: border-box;
            outline: none;
            min-height: ${isCollapsible ? '0px' : '120px'}};
            padding: 0px;
          }

        `}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <TextareaAutosize
            id={id}
            disabled={isLocked}
            value={value}
            onChange={onChange}
            css={isLocked ? '' : inputCss}
          />
        )}
      </div>
      {((canPerformAction && actionPosition === 'bottom') || helperMessage) && (
        <div
          css={css`
            margin-top: 12px;
          `}
        >
          {actionPosition === 'bottom' && getAction(getActionParams)}
          {helperMessage && (
            <div
              css={css`
                ${theme.typography.sizes.xsmall}
                margin-top:4px
              `}
            >
              {helperMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditableTextPreview;
