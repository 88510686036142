import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { useFeedId } from '../contexts/FeedId';

import { ScreenType, ScreenOrientation } from '../generated/global-types';
import {
  ScreenLayoutTypeCounts,
  ScreenLayoutTypeCountsVariables,
} from '../generated/ScreenLayoutTypeCounts';

const ScreenLayoutCountsTypeQuery = loader(
  '../graphql/ScreenLayoutTypeCounts.gql',
);

export interface IUseScreenLayoutTypeCountResult {
  type: ScreenType;
  availableCount: number;
  lockedCount: number;
  totalCount: number;
  setCount: number;
  orientation: ScreenOrientation;
}

const useScreenLayoutTypeCounts = (): {
  screenLayoutTypeCounts: IUseScreenLayoutTypeCountResult[];
  loading: boolean;
} => {
  const feedId = useFeedId();
  const variables = {
    feedId,
  };

  const { data, loading } = useQuery<
    ScreenLayoutTypeCounts,
    ScreenLayoutTypeCountsVariables
  >(ScreenLayoutCountsTypeQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  if (!data?.screenLayoutTypeCounts?.nodes) {
    return {
      screenLayoutTypeCounts: [] as IUseScreenLayoutTypeCountResult[],
      loading,
    };
  }

  const counts: IUseScreenLayoutTypeCountResult[] =
    data.screenLayoutTypeCounts.nodes.filter(Boolean).map((count) => {
      const { type, totalCount, lockedCount, setCount, orientation } = count;

      return {
        setCount: setCount || 0,
        lockedCount: lockedCount || 0,
        totalCount: totalCount || 0,
        availableCount: Math.max((totalCount || 0) - (lockedCount || 0), 0),
        type: type as ScreenType,
        orientation: orientation as ScreenOrientation,
      };
    });

  return {
    screenLayoutTypeCounts: counts,
    loading,
  };
};

export default useScreenLayoutTypeCounts;
