/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';

import { FeatureFlagName, MessageType } from 'generated/global-types';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as RoutesByFeed_routes_nodes } from 'generated/RoutesByFeed';
import theme from 'theme';
import HoverFillButton from 'components/common/screen-preview/hover-fill-button';
import PreviewModal from 'components/common/screen-preview/preview-modal';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { ScreenPreviewDisplayTypes } from 'utils/screen-preview';
import AlertScreenPreviewIframeWrapper from './alert-screen-preview-iframe-wrapper';

export enum AlertScreenPreviewDisplayTypes {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
  DUAL = 'DUAL',
  DUP = 'DUP',
}

interface AlertScreenPreviewProps {
  additionalContent?: string;
  affectedRoutes?: RoutesByFeed_routes_nodes[];
  allRoutesAffected?: boolean;
  className?: string;
  content?: string;
  displayType?: ScreenPreviewDisplayTypes;
  isUpdate?: boolean;
  messageType?: MessageType;
  publishAt?: string;
  title?: string;
}

const AlertScreenPreview: React.FC<AlertScreenPreviewProps> = ({
  className,
  displayType,
  ...rest
}) => {
  const [modalSettings, setModalSettings] = useState({
    isOpen: false,
    isLandscape: false,
  });

  const disablePortraitScreenPreview = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERT_DISABLE_PORTRAIT_SCREEN_PREVIEW,
  );

  const bodyEl =
    displayType === ScreenPreviewDisplayTypes.DUAL ? (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: ${!disablePortraitScreenPreview ? '12px' : '0'};
          background: #eaeaea;
          border: 1px solid ${theme.colors['border-dark']};
          border-radius: 4px;
        `}
      >
        {!disablePortraitScreenPreview && (
          <div
            css={css`
              position: relative;
              width: 33%;
              margin-bottom: 12px;
            `}
          >
            <HoverFillButton
              onClick={() =>
                setModalSettings({ isOpen: true, isLandscape: false })
              }
            />
            <AlertScreenPreviewIframeWrapper {...rest} />
          </div>
        )}
        <div
          css={css`
            position: relative;
            width: ${!disablePortraitScreenPreview ? '60%' : '100%'};
          `}
        >
          <HoverFillButton
            onClick={() =>
              setModalSettings({ isOpen: true, isLandscape: true })
            }
          />
          <AlertScreenPreviewIframeWrapper isLandscape {...rest} />
        </div>
        <PreviewModal
          isOpen={modalSettings.isOpen}
          onDismiss={() =>
            setModalSettings((prevSettings) => ({
              ...prevSettings,
              isOpen: false,
            }))
          }
        >
          <div
            css={css`
              width: ${modalSettings.isLandscape ? '100%' : '60%'};
              margin: ${modalSettings.isLandscape ? '120px' : '16px'}
                ${modalSettings.isLandscape ? '16px' : '120px'};
            `}
          >
            <AlertScreenPreviewIframeWrapper
              isLandscape={modalSettings.isLandscape}
              {...rest}
            />
          </div>
        </PreviewModal>
      </div>
    ) : (
      <div>
        <div
          css={css`
            position: relative;
            width: '100%';
          `}
        >
          {displayType === ScreenPreviewDisplayTypes.DUP && (
            <HoverFillButton
              onClick={() =>
                setModalSettings({ isOpen: true, isLandscape: true })
              }
            />
          )}
          <AlertScreenPreviewIframeWrapper
            isLandscape={
              displayType === ScreenPreviewDisplayTypes.LANDSCAPE ||
              displayType === ScreenPreviewDisplayTypes.DUP
            }
            isDup={displayType === ScreenPreviewDisplayTypes.DUP}
            {...rest}
          />
        </div>
        <PreviewModal
          isOpen={modalSettings.isOpen}
          onDismiss={() =>
            setModalSettings((prevSettings) => ({
              ...prevSettings,
              isOpen: false,
            }))
          }
        >
          <div
            css={css`
              width: ${modalSettings.isLandscape ? '100%' : '60%'};
              margin: ${modalSettings.isLandscape ? '120px' : '16px'}
                ${modalSettings.isLandscape ? '16px' : '120px'};
            `}
          >
            <AlertScreenPreviewIframeWrapper
              isLandscape={
                modalSettings.isLandscape ||
                displayType === ScreenPreviewDisplayTypes.DUP
              }
              isDup={displayType === ScreenPreviewDisplayTypes.DUP}
              {...rest}
            />
          </div>
        </PreviewModal>
      </div>
    );

  return <div className={className}>{bodyEl}</div>;
};

export default AlertScreenPreview;
