import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import emotionTheme from './theme';
import { FeedIdProvider } from './contexts/FeedId';
import { StaleProvider } from './contexts/Stale';
import { FeedSettingsProvider } from './contexts/FeedSettings';
import { ConnectionStatusProvider } from './contexts/GraphQL';
import ErrorBoundary from './components/pages/ErrorBoundary';
import ScrollToTop from './components/scaffolding/scroll-to-top';
import { FeedId } from './types/feeds';

const Contexts: React.FC<{
  allowedFeedIds: FeedId[];
  children?: React.ReactNode;
}> = ({ children, allowedFeedIds }) => (
  <ThemeProvider theme={emotionTheme}>
    <ErrorBoundary>
      <Router>
        <ScrollToTop />
        <HelmetProvider>
          <ConnectionStatusProvider>
            <FeedIdProvider allowedFeedIds={allowedFeedIds}>
              <FeedSettingsProvider>
                <StaleProvider>{children}</StaleProvider>
              </FeedSettingsProvider>
            </FeedIdProvider>
          </ConnectionStatusProvider>
        </HelmetProvider>
      </Router>
    </ErrorBoundary>
  </ThemeProvider>
);

export default Contexts;
