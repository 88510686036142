import { UploadType } from '../../../hooks/useUpload';
import { DurationRange, CadanceRange } from '../duration-input/types';
import {
  SingleCampaignView_campaign_Campaign_durationsSource_DurationsSource_directDurations_DatetimeRange as DirectDuration,
  SingleCampaignView_campaign_Campaign_durationsSource_DurationsSource_cadences_Cadence as Cadences,
  SingleCampaignView_campaign_Campaign_campaignScreenContents_CampaignScreenContentsConnection_nodes_CampaignScreenContent_campaignScreenContentUploads_CampaignScreenContentUploadsConnection_nodes_CampaignScreenContentUpload as CampaignUpload,
} from '../../../generated/SingleCampaignView';

export const duration_gqlToForm = (d: DirectDuration): DurationRange => ({
  start: {
    value: new Date(d.start?.value),
    inclusive: d.start?.inclusive ?? null,
  },
  end: d.end
    ? {
        value: new Date(d.end?.value),
        inclusive: d.end?.inclusive ?? null,
      }
    : null,
});

export const durations_gqlToForm = (ds: DirectDuration[]): DurationRange[] =>
  ds.map(duration_gqlToForm);

export const cadence_gqlToForm = (d: Cadences): CadanceRange => ({
  startDow: d.startDow,
  startTime: d.startTime,
  endDow: d.endDow,
  endTime: d.endTime,
});

export const cadences_gqlToForm = (ds: Cadences[]): CadanceRange[] =>
  ds.map(cadence_gqlToForm);

export const upload_gqlToForm = (cu: CampaignUpload): [number, UploadType] => [
  cu.setIndex,
  cu.upload as UploadType,
];

export const uploads_gqlToForm = (
  cus: CampaignUpload[],
): { [k: string]: UploadType } => Object.fromEntries(cus.map(upload_gqlToForm));
