/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import {
  OptionProps,
  components,
  CommonProps,
  IndicatorsContainerProps,
} from 'react-select';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import pluralize from 'pluralize';
import { TargetingOptionType } from 'utils/stop-selector-helpers';

import {
  getLabelFromTargetType,
  ALL_CIC,
} from 'components/pages/Screen/in-screens-preview/helpers';
import { FeedId } from 'contexts/FeedId';
import { groupedPIOs as PIOs, groupedMKTs as MKTs } from 'constants/screens';
import theme from '../../../theme';
import { Option } from './common';

import {
  SingleScreenIcon,
  SingleLandscapeScreenIcon,
  LeftScreenIcon,
  RightScreenIcon,
  TripleScreenIcon,
} from '../screens-icons';

import Select from '../form-elements/Select';
import { findRoute } from '../../../utils/screen-targeting';
import { ScreenTarget } from '../../../types';
import {
  ScreenCounts,
  ScreenCountsVariables,
  ScreenCounts_counts_ScreenCountsForEntitySelectorsTypesConnection_nodes_ScreenCountsForEntitySelectorsType as ScreenCounts_counts_nodes,
} from '../../../generated/ScreenCounts';
import {
  GtfsEntitySelectorInput,
  TargetType,
} from '../../../generated/global-types';

const ScreenCountsQuery = loader('../../../graphql/ScreenCounts.gql');

const ADV_TYPES = [
  TargetType.SINGLE_AD_PORTRAIT,
  TargetType.SINGLE_AD_LANDSCAPE,
  TargetType.TRIPTYCH_AD,
];

// TODO: Fix this type
const isSidewalkTargeting = (props: CommonProps<any, any, any>) =>
  props.getValue()[0]?.value === TargetType.DUP;

const ScreenTypeValueContainer: React.FC<
  { children?: React.ReactNode } & any
> = ({ children, ...props }) => {
  const options: readonly {
    label: string;
    screenCount: number;
  }[] = props.getValue();

  return (
    <components.ValueContainer {...props}>
      <div
        css={css`
          display: flex;
          width: 99%;
          justify-content: space-between;
          align-items: baseline;
          color: ${isSidewalkTargeting(props) ? 'black' : '#0f61a9'};
        `}
      >
        <div
          css={css`
            padding-left: 12px;
          `}
        >
          {options.map((o) => o.label).join(', ')}
        </div>
        <div
          css={css`
            margin-left: 16px;
            color: #707070;
            ${theme.typography.sizes.small};
          `}
        >
          {pluralize(
            'Screen',
            options.map((o) => o.screenCount).reduce((a, b) => a + b, 0),
            true,
          )}
        </div>
      </div>
      {isSidewalkTargeting(props) ? null : children}
    </components.ValueContainer>
  );
};

const ICONS: { [key: string]: any } = {
  [TargetType.SINGLE_CIC]: SingleScreenIcon,
  [ALL_CIC]: SingleScreenIcon,
  [TargetType.DUO_CIC_LEFT]: LeftScreenIcon,
  [TargetType.DUO_CIC_RIGHT]: RightScreenIcon,
  [TargetType.SINGLE_INFO_PORTRAIT]: SingleScreenIcon,
  [TargetType.FULTON_PORTRAIT]: SingleScreenIcon,
  [TargetType.SINGLE_AD_PORTRAIT]: SingleScreenIcon,
  [TargetType.SOLARI_PORTRAIT]: SingleScreenIcon,
  [TargetType.TRIPTYCH_AD]: TripleScreenIcon,

  [TargetType.SINGLE_INFO_LANDSCAPE]: SingleLandscapeScreenIcon,
  [TargetType.SINGLE_AD_LANDSCAPE]: SingleLandscapeScreenIcon,
  [TargetType.SINGLE_MKT_LANDSCAPE]: SingleLandscapeScreenIcon,
  [TargetType.SINGLE_PIO_LANDSCAPE]: SingleLandscapeScreenIcon,
  [TargetType.SINGLE_STA_LANDSCAPE]: SingleLandscapeScreenIcon,
  [TargetType.OTHER_MARKETING_LANDSCAPE]: SingleLandscapeScreenIcon,
  [TargetType.SOLARI_LANDSCAPE]: SingleLandscapeScreenIcon,
};

const selectComponents = {
  Option: (props: OptionProps<any>) => {
    const option = props.data;
    const { screenCount, value } = option;

    const Icon = ICONS[value];

    const descriptionEl = (
      <div
        css={css`
          display: flex;
        `}
      >
        {Icon && <Icon />}
        {typeof screenCount !== 'undefined' &&
          pluralize('Screen', screenCount, true)}
      </div>
    );
    return (
      // @ts-ignore
      <div {...props.innerProps}>
        <Option
          color="black"
          isSelected={props.isSelected}
          option={option}
          style={props.getStyles('option', props)}
          description={descriptionEl}
        />
      </div>
    );
  },
  ValueContainer: ScreenTypeValueContainer,
  IndicatorsContainer: (props: IndicatorsContainerProps<any>) =>
    isSidewalkTargeting(props) ? null : props.children,
};

// TODO: This could be based on non-zero screen counts, but I think we probably
// want a fixed list per agency regardless of the currently-selected route
const ORDERED_TARGET_TYPES: { [key: string]: TargetType[] } = {
  'nyct-bus': [],
  'nyct-subway': [
    TargetType.NON_AD,
    ALL_CIC,
    TargetType.FULTON_PORTRAIT,
    TargetType.SOLARI_PORTRAIT,
    TargetType.SINGLE_AD_PORTRAIT,
    TargetType.TRIPTYCH_AD,
  ],
  mnr: [
    TargetType.NON_AD,
    TargetType.SINGLE_INFO_LANDSCAPE,
    TargetType.SINGLE_INFO_PORTRAIT,
    TargetType.SINGLE_AD_LANDSCAPE,
    TargetType.SOLARI_PORTRAIT,
    TargetType.TRIPTYCH_AD,
  ],
  lirr: [
    TargetType.NON_AD,
    ALL_CIC,
    TargetType.SOLARI_PORTRAIT,
    TargetType.SINGLE_MKT_LANDSCAPE,
    TargetType.SINGLE_PIO_LANDSCAPE,
    TargetType.SINGLE_STA_LANDSCAPE,
    TargetType.SINGLE_AD_LANDSCAPE,
    TargetType.TRIPTYCH_AD,
  ],
  'bridges-tunnels': [],
  'outfront-qa': [
    TargetType.NON_AD,
    ALL_CIC,
    TargetType.FULTON_PORTRAIT,
    TargetType.SOLARI_PORTRAIT,
    TargetType.SINGLE_AD_PORTRAIT,
    TargetType.TRIPTYCH_AD,
  ],
  'outfront-dev': [
    TargetType.NON_AD,
    ALL_CIC,
    TargetType.FULTON_PORTRAIT,
    TargetType.SOLARI_PORTRAIT,
    TargetType.SINGLE_AD_PORTRAIT,
    TargetType.TRIPTYCH_AD,
  ],
};

export const COMBINED_CICS: { [key: string]: TargetType[] } = {
  'nyct-bus': [],
  'nyct-subway': [
    TargetType.SINGLE_CIC,
    TargetType.DUO_CIC_LEFT,
    TargetType.DUO_CIC_RIGHT,
  ],
  mnr: [TargetType.SINGLE_CIC],
  lirr: [TargetType.SINGLE_CIC],
  'outfront-qa': [
    TargetType.SINGLE_CIC,
    TargetType.DUO_CIC_LEFT,
    TargetType.DUO_CIC_RIGHT,
  ],
  'bridges-tunnels': [],
  'outfront-dev': [
    TargetType.SINGLE_CIC,
    TargetType.DUO_CIC_LEFT,
    TargetType.DUO_CIC_RIGHT,
  ],
};

type CombinedScreenCounts = Omit<ScreenCounts_counts_nodes, '__typename'>;

const getCombinedScreenCounts = (
  feedId: FeedId,
  screensCount: ScreenCounts_counts_nodes[],
): CombinedScreenCounts[] => {
  let filteredScreenCounts = [...screensCount] as CombinedScreenCounts[];
  const combinedCics = COMBINED_CICS[feedId];

  if (combinedCics.length) {
    filteredScreenCounts.push({
      targetType: ALL_CIC,
      amount: combinedCics.reduce(
        (acc, targetType) =>
          acc +
          (screensCount.find(
            ({ targetType: scTargetType }) => scTargetType === targetType,
          )?.amount ?? 0),
        0,
      ),
    });

    filteredScreenCounts = filteredScreenCounts.filter(
      (screenCount) => !combinedCics.includes(screenCount.targetType),
    );
  }

  if (feedId !== FeedId.LIRR) {
    return filteredScreenCounts;
  }

  filteredScreenCounts = filteredScreenCounts.filter(
    ({ targetType }) =>
      !PIOs.includes(targetType) && !MKTs.includes(targetType),
  );

  return [
    ...filteredScreenCounts,
    {
      targetType: TargetType.SINGLE_PIO_LANDSCAPE,
      amount: screensCount.reduce(
        (acc, { targetType, amount }) =>
          PIOs.includes(targetType) ? acc + amount : acc,
        0,
      ) as number,
    },
    {
      targetType: TargetType.SINGLE_MKT_LANDSCAPE,
      amount: screensCount.reduce(
        (acc, { targetType, amount }) =>
          MKTs.includes(targetType) ? acc + amount : acc,
        0,
      ) as number,
    },
  ];
};

const ScreenTypeSelector: React.FC<
  {
    screenTargeting: ScreenTarget[];
    onChange: (choice: any) => void;
    classNamePrefix?: string;
    feedId: FeedId;
    routeId?: string;
    isSidewalkLocked?: boolean;
  } & { children?: React.ReactNode }
> = ({
  feedId,
  routeId,
  screenTargeting,
  onChange,
  classNamePrefix,
  isSidewalkLocked = false,
}) => {
  const selectedRoute = findRoute(screenTargeting, routeId || '');
  const screenCountEntitySelectors: GtfsEntitySelectorInput[] = [];

  const entireRouteOption = selectedRoute?.options.find(
    (o) => o.optionType === TargetingOptionType.ALL_ROUTE_OPTION,
  );

  if (entireRouteOption) {
    screenCountEntitySelectors.push({ routeId: entireRouteOption.routeId });
  } else {
    selectedRoute?.options.forEach((option) => {
      if (option.optionType === TargetingOptionType.STOP_OPTION) {
        screenCountEntitySelectors.push({
          stopId: option.stopId,
        });
      }
    });
  }

  const { data } = useQuery<ScreenCounts, ScreenCountsVariables>(
    ScreenCountsQuery,
    {
      skip: !routeId,
      variables: {
        feedId,
        ess: screenCountEntitySelectors,
      },
    },
  );

  const combinedScreenCounts = getCombinedScreenCounts(
    feedId,
    data?.counts?.nodes ?? [],
  );

  const screenCounts = combinedScreenCounts.reduce(
    (acc, count) => {
      acc[count.targetType] = count.amount;
      return acc;
    },
    {} as { [key: string]: number },
  );

  const targets = isSidewalkLocked
    ? [TargetType.DUP]
    : ORDERED_TARGET_TYPES[feedId].filter((target) =>
        feedId === FeedId.LIRR
          ? ![
              TargetType.SOLARI_LANDSCAPE,
              TargetType.OTHER_MARKETING_LANDSCAPE,
            ].includes(target)
          : true,
      );
  const options = targets.map((targetType) => ({
    value: targetType,
    label: getLabelFromTargetType(targetType, feedId),
    screenCount:
      targetType === TargetType.NON_AD
        ? Object.keys(screenCounts).reduce((totalCount, screenType) => {
            if (!ADV_TYPES.includes(screenType as TargetType)) {
              return totalCount + screenCounts[screenType];
            }

            return totalCount;
          }, 0)
        : (screenCounts[targetType] ?? 0),
  }));

  const selectedOptions = [
    ...options.filter((option) =>
      selectedRoute?.layouts?.includes(option.value),
    ),
    ...(COMBINED_CICS[feedId].every((targetType) =>
      selectedRoute?.layouts?.includes(targetType),
    )
      ? [options.find((option) => option.value === ALL_CIC)]
      : []),
  ];

  return (
    <Select
      isMulti
      classNamePrefix={classNamePrefix}
      hasControlBorder={false}
      isSearchable={false}
      isClearable={false}
      options={options}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      defaultValue={options[0]}
      components={selectComponents}
      value={selectedOptions}
      onChange={(data: any, event: any) => {
        if (!data.length) {
          onChange([TargetType.NON_AD]);
        } else if (event.action === 'select-option') {
          if (event.option.value === TargetType.NON_AD) {
            onChange([TargetType.NON_AD]);
          } else {
            onChange(
              data
                .map((o: any) => o.value)
                .filter((v: TargetType) => v !== TargetType.NON_AD),
            );
          }
        } else {
          onChange(data.map((o: any) => o.value));
        }
      }}
      styles={{
        control: (state: { menuIsOpen: boolean }) => {
          return {
            pointerEvents: isSidewalkLocked ? 'none' : 'initial',
            color: isSidewalkLocked ? 'black' : 'initial',
            boxShadow: 'unset',
            borderRadius: `0 0 0 ${state.menuIsOpen ? '0' : '4px'}`,
          };
        },
      }}
    />
  );
};

export default ScreenTypeSelector;
