/**
 * @returns the time from a Date and returns it in HH:MM format
 */
export const getTimeString = (date: Date): string =>
  `0${date.getHours()}:0${date.getMinutes()}`.replace(/0(\d{2})/g, '$1');

export const areAnyDatesInPast = (
  dates: (Date | null | undefined)[],
): boolean => {
  if (dates.some((v) => v && new Date(v).getTime() < new Date().getTime())) {
    return true;
  }
  return false;
};
