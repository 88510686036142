/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ThemeType } from '../../../theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      flex-grow: 1;
      margin-right: 24px;
    `}
    {...props}
  />
);

export const InfoBox: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      margin-bottom: ${theme.spacing.small};
    `}
    {...props}
  />
);
