/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';
import Spinner from './Spinner';

const Loader: React.FC<{ loading: boolean; children?: React.ReactNode }> = ({
  children,
  loading,
}) => (
  <React.Fragment>
    {loading ? (
      <div
        css={css`
          display: flex;
          direction: column;
          align-items: center;
          height: 100%;
          width: 100%;
        `}
      >
        <Spinner />
      </div>
    ) : (
      children
    )}
  </React.Fragment>
);

export default Loader;
