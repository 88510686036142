/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import Modal from 'components/common/modal';
import Button from 'components/common/Button';
import theme from 'theme';

const PreviewModal: React.FC<
  {
    onDismiss: () => void;
    isOpen?: boolean;
  } & { children?: React.ReactNode }
> = ({ onDismiss, isOpen, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Screen Preview"
      onDismiss={onDismiss}
      margin="3vh auto"
      maxWidth="560px"
    >
      <div
        css={css`
          display: flex;
          background-color: #eaeaea;
          border: 1px solid ${theme.colors['border-dark']};
          border-radius: 4px;
          justify-content: center;
        `}
      >
        {children}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        `}
      >
        <Button primary type="button" onClick={onDismiss}>
          Go Back
        </Button>
      </div>
    </Modal>
  );
};

export default PreviewModal;
