/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { Folders_socialMediaFolders_SocialMediaFoldersConnection_nodes_SocialMediaFolder as FolderType } from 'generated/Folders';
import Folder from './Folder';

const FoldersView: React.FC<
  {
    folders: FolderType[];
    onFolderClick: (folder: FolderType) => void;
  } & { children?: React.ReactNode }
> = ({ folders, onFolderClick }) => {
  return folders?.length ? (
    <React.Fragment>
      <h3>Folders</h3>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {folders.map((folder) => (
          <Folder
            key={folder.id}
            folder={folder}
            onClick={() => onFolderClick(folder)}
          />
        ))}
      </div>
    </React.Fragment>
  ) : null;
};

export default FoldersView;
