/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Collapse } from 'react-collapse';

import TweetThreadInput, {
  Tweet as TweetPatch,
} from 'components/common/tweets/tweet-thread-input';
import { isUploadFileContainer as fileIsStillUploading } from 'components/common/tweets/tweet-input/image-attachments';

import { TWEET_MAX_LENGTH, isValidTweet } from 'utils/twitter-helpers';
import FormHeader from '../form-elements/FormHeader';
import StatusBanner from '../status-banner';

import * as CS from '../common-forms.styled';
import { ThemeType } from '../../../theme';
import {
  TWITTER_MESSAGE_REQUIRED,
  TWITTER_MESSAGE_REQUIRED_THREAD,
  TWITTER_ATTACHMENTS_STILL_UPLOADING,
} from '../../../constants/error-messages';
import {
  TWEET_CUSTOMIZED_NAME,
  TWEETS_NAME,
  TWEETS_JOIN_SEPERATOR,
} from '../../../constants/alerts';

import { Tweet } from '../tweets/tweet-thread-input/types';

export const constructTweet = (
  message: string,
  additionalMessage?: string,
): string =>
  additionalMessage
    ? `${message}${TWEETS_JOIN_SEPERATOR}${additionalMessage}`
    : message;

const ComposeTweet: React.FC<
  {
    show: boolean;
    onToggle: (toggle: boolean) => void;
    tweets: TweetPatch[];
    addTweet: () => void;
    removeTweet: (id: number) => void;
    setText: (id: number, newText: string) => void;
    setAttachments: (id: number, newAttachments: File[]) => void;
    setQuoteId: (id: number, newQuoteId: string) => void;
    setReplyId: (id: number, newReplyId: string) => void;
    isDuplicate?: boolean;
  } & { children?: React.ReactNode }
> = ({
  show,
  onToggle,
  tweets,
  addTweet,
  removeTweet,
  setText,
  setAttachments,
  setQuoteId,
  setReplyId,
}) => {
  const { register, setValue, clearError, errors } = useFormContext();
  const [touched, setTouched] = useState<boolean>(false);

  const error = errors[TWEETS_NAME];
  const hasRefValError =
    error?.type === 'required'
      ? !!error?.ref?.value?.some((t: Tweet) => !t.text)
      : !!error;
  const hasError = error ? hasRefValError : false; // Support maxLength error type.
  const isErrorMessageVisible = hasError && error?.type === 'required';
  const tweetMaxLength = show ? TWEET_MAX_LENGTH : Number.MAX_SAFE_INTEGER;

  const customizesTweet = useCallback(
    (fn: (...args: any[]) => any) =>
      (...args: any[]) => {
        setTouched(true);
        setValue(TWEET_CUSTOMIZED_NAME, true);
        return fn(...args);
      },
    [setValue],
  );

  useEffect(() => {
    register({ name: TWEET_CUSTOMIZED_NAME });
    register(
      { name: TWEETS_NAME },
      {
        validate: {
          required: (value: TweetPatch[]) => {
            if (show && value.some((t) => t.text.length === 0)) {
              return value.length === 1
                ? TWITTER_MESSAGE_REQUIRED
                : TWITTER_MESSAGE_REQUIRED_THREAD;
            }
            return undefined;
          },
          maxLength: (value: TweetPatch[]) =>
            show
              ? value.every((t) => isValidTweet(t.text, tweetMaxLength))
              : true,
          uploadingAttachments: (value: TweetPatch[]) => {
            if (
              show &&
              value.some((t) =>
                t.attachments.some((a) => fileIsStillUploading(a)),
              )
            ) {
              return TWITTER_ATTACHMENTS_STILL_UPLOADING;
            }
            return undefined;
          },
        },
      },
    );
  }, [register, tweetMaxLength, show]);

  useEffect(() => {
    setValue(TWEETS_NAME, tweets, touched);
  }, [setValue, tweets, touched]);

  useEffect(() => {
    if (!show) {
      clearError(TWEETS_NAME);
      setValue(TWEET_CUSTOMIZED_NAME, false);
    }
  }, [show, clearError, setValue]);

  return (
    <section>
      <FormHeader
        title="Twitter"
        enabled={show}
        onToggle={(event: React.ChangeEvent<HTMLInputElement>) =>
          onToggle(event.target.checked)
        }
        toggle
      />
      <Collapse isOpened={show}>
        <CS.Container>
          <TweetThreadInput
            id={TWEETS_NAME}
            maxLength={TWEET_MAX_LENGTH}
            error={hasError}
            tweets={tweets}
            setText={customizesTweet(setText)}
            setAttachments={customizesTweet(setAttachments)}
            setQuoteId={customizesTweet(setQuoteId)}
            setReplyId={customizesTweet(setReplyId)}
            addTweet={customizesTweet(addTweet)}
            removeTweet={customizesTweet(removeTweet)}
          />
          <StatusBanner
            as="label"
            htmlFor={TWEETS_NAME}
            status="error"
            hasIcon
            text={error?.message || '\u00a0'}
            isVisible={isErrorMessageVisible}
            css={({ spacing }: ThemeType) => css`
              margin-top: ${spacing.small};
            `}
          />
        </CS.Container>
      </Collapse>
    </section>
  );
};

export default ComposeTweet;
