/** @jsxImportSource @emotion/react */

import * as React from 'react';

import StatusIcon from 'components/common/status-icon';

import useScreenLayoutTypeCounts, {
  IUseScreenLayoutTypeCountResult,
} from 'hooks/use-screen-layout-type-counts';
import getScreenIcon from 'utils/get-screen-icon';
import { ScreenType, ScreenOrientation } from '../../../generated/global-types';
import {
  getLabelFromScreenType,
  getDimensionsFromScreenType,
} from '../../pages/Screen/in-screens-preview/helpers';

import {
  buttons,
  availableCount,
  orientation as orientationStyle,
  description as descriptionStyle,
  icon as iconStyle,
  container,
  containerSelected,
  messageWrapper,
  loadingContainer,
} from './index.styled';

import ScreensAvailabilityCount from '../screens-availability-count';
import { useFeedId } from 'contexts/FeedId';

const ScreenLayoutButtonsForCurrentFeed: React.FC<
  {
    onSelect: (
      o: ScreenOrientation,
      s: ScreenType,
      c: number,
      l: number,
    ) => void;
    noScreensEl?: React.ReactNode;
    selected?: ScreenOrientation | null;
    screenType?: ScreenType | null;
  } & { children?: React.ReactNode }
> = ({ onSelect, selected, noScreensEl, screenType }) => {
  const { screenLayoutTypeCounts, loading } = useScreenLayoutTypeCounts();
  const feedId = useFeedId();
  if (loading) {
    return (
      <div css={loadingContainer}>
        <StatusIcon status="loading" size={20} />
      </div>
    );
  }
  const buttonEls = screenLayoutTypeCounts.map(
    (screenLayoutCount: IUseScreenLayoutTypeCountResult) => {
      const { type, totalCount, lockedCount, orientation } = screenLayoutCount;

      const icon = getScreenIcon(screenLayoutCount, 50);
      const description = getLabelFromScreenType(type, feedId);
      const [width, height] = getDimensionsFromScreenType(orientation, type);
      const isSelected = selected === orientation && type === screenType;

      const cssStyles = [container];
      if (isSelected) {
        cssStyles.push(containerSelected);
      }

      return (
        <button
          type="button"
          role="switch"
          aria-checked={isSelected}
          css={cssStyles}
          key={`${orientation}-${type}`}
          onClick={() => onSelect(orientation, type, totalCount, lockedCount)}
        >
          <div css={iconStyle}>{icon}</div>
          <span css={descriptionStyle}>{description}</span>
          <span css={orientationStyle}>
            {width} × {height}px
          </span>
          <span css={availableCount}>
            <ScreensAvailabilityCount
              numLocked={lockedCount}
              numTargeted={totalCount}
            />
          </span>
        </button>
      );
    },
  );

  return (
    <React.Fragment>
      {buttonEls?.length ? (
        <div css={buttons}>{buttonEls}</div>
      ) : (
        <div css={messageWrapper}>{noScreensEl}</div>
      )}
    </React.Fragment>
  );
};

export default ScreenLayoutButtonsForCurrentFeed;
