/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';

import { getLabelFromTargetType } from 'components/pages/Screen/in-screens-preview/helpers';
import { TargetType } from 'generated/global-types';
import { Directionality } from './types';
import RouteRangeStopDisplay, { buildRange } from './route-range-stop-display';

import * as S from './route-range-stop-display-group.styled';
import { TagsSelection } from '../../types';

type Stop = { name: string };

interface RouteRangeStopDisplayGroupProps {
  gtfsRouteIds: string[];
  stopsByRoute: {
    [gtfsRouteId: string]: Stop[];
  };
  directionality: Directionality | null;
  gtfsStopIds: string[] | null;
  screens?: {
    [gtfsRouteId: string]: TargetType[];
  };
  tags?: TagsSelection | null;
  additionalStyles?: SerializedStyles;
}

const RouteRangeStopDisplayGroup: React.FC<
  RouteRangeStopDisplayGroupProps & { children?: React.ReactNode }
> = ({
  gtfsRouteIds,
  gtfsStopIds,
  directionality,
  stopsByRoute,
  screens,
  tags,
  additionalStyles,
  ...rest
}) => (
  <S.Grid {...rest}>
    {gtfsRouteIds
      .filter((gtfsRouteId) => stopsByRoute[gtfsRouteId])
      .map((gtfsRouteId) => {
        const totalCount = stopsByRoute[gtfsRouteId].length ?? 0;
        const selectedStops = (stopsByRoute[gtfsRouteId] ?? []).filter(
          (stop: any) =>
            gtfsStopIds ? gtfsStopIds.includes(stop.value) : false,
        );
        const selectedStopCount = selectedStops.length;
        const selectedStopsForRange =
          totalCount !== selectedStopCount ? selectedStops : [];
        const targetedScreensName = screens
          ? screens[gtfsRouteId]?.map((layout: TargetType) =>
              getLabelFromTargetType(layout),
            )
          : null;

        return (
          <RouteRangeStopDisplay
            key={gtfsRouteId}
            gtfsRouteId={gtfsRouteId}
            directionality={directionality}
            stopCount={selectedStopCount}
            range={buildRange(directionality, selectedStopsForRange)}
            screens={targetedScreensName}
            additionalStyles={additionalStyles}
            tags={tags}
          />
        );
      })}
  </S.Grid>
);

export default RouteRangeStopDisplayGroup;
