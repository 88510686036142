import { getClearedPlannedWorkMessageTypeDescriptionsByFeed } from 'utils/message-type-display';
import pluralize from 'pluralize';
import { FeedId } from '@mta-live-media-manager/shared';
import {
  SearchPlannedWorksOrderBy,
  MessageType,
  PlannedWorkStatus,
} from '../../../generated/global-types';
import { TPlannedWorkTableSortingTypes } from './PlannedWorkTable';

import {
  PLANNED_WORK_STATUSES,
  SortOptions,
} from '../../../constants/planned-work';

export const queryToMessageType = (
  feedId: FeedId,
  queryString?: string,
): MessageType | undefined => {
  if (!queryString || !queryString.length) {
    return;
  }

  const categories = getClearedPlannedWorkMessageTypeDescriptionsByFeed(feedId);

  // eslint-disable-next-line consistent-return
  return categories.find((cat) => cat.value === queryString)?.value;
};

export const queryToStatus = (
  queryString?: string,
): PlannedWorkStatus | undefined => {
  if (!queryString || !queryString.length) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return PLANNED_WORK_STATUSES.find((cat) => cat.value === queryString)?.data
    .status;
};

export const paramsToSort = function paramsToSort(
  sortOptions: SortOptions,
  sort?: TPlannedWorkTableSortingTypes,
  dir?: string,
): SearchPlannedWorksOrderBy {
  if (!sort || !dir) {
    return sortOptions.default[0];
  }
  const dirIndex = dir === 'desc' ? 0 : 1;
  const selection = sortOptions[sort];
  return selection ? selection[dirIndex] : sortOptions.default[0];
};

export const homepageVisibilityMessage = (value: number | null) => {
  if (value !== null) {
    return value > 0
      ? `${pluralize('hour', value, true)} before each active work period.`
      : 'Will appear on the homepage as soon as each work period becomes active.';
  }
  return 'Will not appear on the homepage.';
};
