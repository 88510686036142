import { format } from 'date-fns';

import {
  INCIDENT_START_PRIOR_POSTED,
  INCIDENT_START_PRIOR_NOTIFIED,
  NOTIFIED_PRIOR_POSTED,
} from '../constants/alerts';
import { TIME_FORMAT } from '../constants/time';

export const getFormattedTime = (time: string) =>
  time ? format(new Date(time), TIME_FORMAT) : '';

export class InvalidTimeInput extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export const insertColonToTime = (value: string): string => {
  if (
    value.includes(':') &&
    value.substring(value.indexOf(':') + 1).length !== 2
  ) {
    throw new InvalidTimeInput(`${value} is not a valid time string`);
  }

  const val = value.replace(':', '');
  if (!/^\d+$/.test(val) || val.length === 0 || val.length > 4) {
    throw new InvalidTimeInput(`${value} is not a valid time string`);
  }

  let time;
  if (val.length <= 2) {
    const valueInt = parseInt(val, 10);
    if (valueInt <= 23 && valueInt >= 0) {
      const appendZero = valueInt < 10 ? '0' : '';
      time = `${appendZero}${valueInt}:00`;
    }
  } else {
    const minsStr = val.slice(-2);
    const hours = parseInt(val.substring(0, val.length === 3 ? 1 : 2), 10);
    const mins = parseInt(minsStr, 10);
    if (hours <= 23 && hours >= 0 && mins >= 0 && mins <= 59) {
      const appendZero = hours < 10 ? '0' : '';
      time = `${appendZero}${hours}:${minsStr}`;
    }
  }

  if (!time) {
    throw new InvalidTimeInput(`${value} is not a valid time string`);
  }
  return time;
};

export const dateWithoutSeconds = (date: Date): Date => {
  if (!date) {
    return date;
  }
  const d = new Date(date);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
};

export const validateChronology = (
  incidentStartAt: Date,
  notifiedAt: Date,
  postedAt: Date,
): boolean => {
  const incidentStart = dateWithoutSeconds(incidentStartAt);
  const notified = dateWithoutSeconds(notifiedAt);
  const posted = dateWithoutSeconds(postedAt);
  if (incidentStart && incidentStart > posted) {
    throw new Error(INCIDENT_START_PRIOR_POSTED);
  }
  if (incidentStart && notified && incidentStart > notified) {
    throw new Error(INCIDENT_START_PRIOR_NOTIFIED);
  }
  if (notified && notified > posted) {
    throw new Error(NOTIFIED_PRIOR_POSTED);
  }
  return true;
};
