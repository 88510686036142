/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {
  Children,
  useMemo,
  useState,
  PropsWithChildren as PWC,
} from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import _uniqBy from 'lodash/uniqBy';
import _uniqueId from 'lodash/uniqueId';

import { RouteMention, StopMention, TagsSelection } from 'types';
import StopSelector from 'components/common/stop-selector';
import {
  GtfsEntitySelectorInput,
  ContextualSelectorInput,
} from 'generated/global-types';
import { SELECT_OVERLAY_STYLES } from 'components/common/form-elements/Select';
import { FeedId, useFeedId } from 'contexts/FeedId';

import theme from '../../../../theme';
import { ReactComponent as PlusIcon } from '../../../../images/plus.svg';
import Button from '../../Button';
import CrossIcon from '../../cross-icon';
import RouteSelector from '../route-selector';
import BusRouteSelector from '../route-selector/bus-route-selector';
import CustomTagsSelector from '../custom-tags-selector';
import { SelectUnselectAllAction } from '../../selector-actions';
import * as S from './index.styled';

export interface IEntitySelector {
  routes: RouteMention[];
  stops: StopMention[];
}

export interface ISelector {
  selector: IEntitySelector;
  tags?: TagsSelection;
}

interface IMultiRouteSelector {
  onChange: (selectors: ISelector[]) => void;
  value: ISelector[];
  addText?: string;
  className?: string;
  showScreensAvailable?: boolean;
  isCampaign?: boolean;
}

interface IGetAllSelectorsResult {
  routes: ContextualSelectorInput[];
  stops: ContextualSelectorInput[];
}
export const getContextualSelectorsByType = (
  selectors: IEntitySelector[],
): IGetAllSelectorsResult => {
  const allEntitySelectorsByType = selectors.reduce(
    (agg, selector) => {
      if (selector.stops.length && selector.routes.length) {
        const stopSelectors = selector.stops.map((stop) => ({
          context: {
            gtfsRouteId: selector.routes[0].routeId || undefined,
          },
          entitySelector: {
            stopId: stop.stopId,
          },
        }));

        return {
          routes: agg.routes,
          stops: [...agg.stops, ...stopSelectors],
        };
      }

      const routeSelectors = selector.routes.map((route) => ({
        context: {
          gtfsRouteId: route.routeId,
        },
        entitySelector: {
          routeId: route.routeId,
        },
      }));

      return {
        stops: agg.stops,
        routes: [...agg.routes, ...routeSelectors],
      };
    },
    {
      routes: [] as GtfsEntitySelectorInput[],
      stops: [] as GtfsEntitySelectorInput[],
    } as IGetAllSelectorsResult,
  );

  return {
    routes: _uniqBy(
      allEntitySelectorsByType.routes,
      (v) => v.entitySelector?.routeId,
    ),
    stops: _uniqBy(
      allEntitySelectorsByType.stops,
      (v) => v.entitySelector?.stopId,
    ),
  };
};

export const getContextualSelectors = (
  selectors: IEntitySelector[],
): ContextualSelectorInput[] => {
  const byType = getContextualSelectorsByType(selectors);
  return Object.values(byType).flat();
};

export const getAllRoutesFromSelectors = (
  selectors: IEntitySelector[],
  includeRoutesWithStops = true,
) => {
  const allRoutes = selectors.reduce((agg, selector) => {
    if (!includeRoutesWithStops && selector.stops.length) {
      return agg;
    }

    return [
      ...agg,
      ...selector.routes.filter((route) => !route.hasAllStopsUnselected),
    ];
  }, [] as RouteMention[]);

  return _uniqBy(allRoutes, 'routeId');
};

export const getAllStopsFromSelectors = (selectors: IEntitySelector[]) => {
  const allStops = selectors.reduce((agg, selector) => {
    return [...agg, ...selector.stops];
  }, [] as StopMention[]);

  return _uniqBy(allStops, 'stopId');
};

export const emptySelector = (): ISelector => ({
  selector: { routes: [], stops: [] },
  tags: undefined,
});
const addEmptySelector = (selectors: ISelector[] = []): ISelector[] => [
  ...selectors,
  emptySelector(),
];

const removeSelector = (selectors: ISelector[], idx: number) => {
  const beforeEls = selectors.slice(0, idx);
  const afterEls = selectors.slice(idx + 1);
  return [...beforeEls, ...afterEls];
};

const changeSelector = (
  selectors: ISelector[],
  updated: IEntitySelector,
  idx: number,
) => {
  const newSelectors = [...selectors];
  newSelectors[idx].selector = updated;

  if (newSelectors[idx].selector.routes.length !== 1) {
    newSelectors[idx].selector.stops = [];
  }

  return newSelectors;
};

const changeRoutes = (selector: IEntitySelector, routes: RouteMention[]) => ({
  ...selector,
  routes,
});

export const BaseSelector: React.FC<
  PWC<{
    disabledRoutes: RouteMention[];
    hideLabels: boolean;
    onRemove: () => void;
    onChange: (selector: IEntitySelector) => void;
    removeable: boolean;
    showScreensAvailable?: boolean;
    selector: IEntitySelector;
    headerText?: string;
    isSingleRoute?: boolean;
    isCampaign?: boolean;
    isEditing?: boolean;
    enableSelectAllAction?: boolean;
    areAllSelected?: boolean;
    noLinesSelected?: boolean;
    onSelectAll?: () => void;
    onUnselectAll?: () => void;
  }>
> = ({
  disabledRoutes,
  hideLabels,
  onRemove,
  onChange,
  removeable,
  showScreensAvailable,
  selector,
  headerText = 'Line(s)',
  isSingleRoute = false,
  isCampaign = false,
  isEditing = false,
  children,
  enableSelectAllAction = false,
  areAllSelected = false,
  noLinesSelected = false,
  onSelectAll = () => {},
  onUnselectAll = () => {},
}) => {
  const feedId = useFeedId();
  const routesId = useMemo(() => _uniqueId('routes-'), []);
  const stopsId = useMemo(() => _uniqueId('stops-'), []);
  const areStopsDisabled = selector.routes.length !== 1;

  const linesLabel = hideLabels ? (
    <VisuallyHidden>
      <label htmlFor={routesId}>{headerText}</label>
    </VisuallyHidden>
  ) : (
    <label
      css={css`
        ${theme.typography.sizes.medium};
        font-weight: ${theme.typography.weights.bold};
        margin-bottom: 8px;
      `}
      htmlFor={routesId}
    >
      {headerText}
      {enableSelectAllAction && (
        <SelectUnselectAllAction
          areAllSelected={areAllSelected}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
          hideAction={noLinesSelected}
        />
      )}
    </label>
  );

  const stationsLabel = (
    <VisuallyHidden>
      <label htmlFor={stopsId}>Station(s)</label>
    </VisuallyHidden>
  );

  const routeSelectorProps = {
    isMulti: !isSingleRoute,
    id: routesId,
    classNamePrefix: 'multi-route-stop-selector',
    routes: selector.routes,
    disabledRoutes,
    onChange: (routes: RouteMention[]) => {
      const newSelector = changeRoutes(selector, routes);

      if (isSingleRoute) {
        const oldRouteId = selector?.routes?.[0]?.routeId;
        const newRouteId = routes?.[0]?.routeId;

        if (oldRouteId !== newRouteId) {
          newSelector.stops = [];
        }
      }

      onChange(newSelector);
    },
    hasControlBoxShadow: false,
    placeholder: 'Select Line(s)',
    selectProps: {
      selectStyles: {
        control: (provided: {}, state: { isFocused: boolean }) => {
          return {
            boxShadow: 'unset',
            borderRadius: '4px 4px 0 0',
            ...(state.isFocused
              ? { ...SELECT_OVERLAY_STYLES.control, zIndex: '2' }
              : { borderBottom: '1px solid transparent' }),
          };
        },
      },
    },
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      {linesLabel}
      {stationsLabel}
      <S.Row
        css={css`
          display: flex;
          flex: 1;
          border: unset;
          position: relative;
        `}
      >
        <S.SelectCol
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;

            :after {
              content: '';
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              border: 1px solid ${theme.colors['border-dark']};
              position: absolute;
              z-index: 1;
              border-radius: 4px;
              pointer-events: none;
              box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
            }
          `}
        >
          <S.SelectContainer>
            {feedId === FeedId.NYCTBus ? (
              <BusRouteSelector {...routeSelectorProps} />
            ) : (
              <RouteSelector {...routeSelectorProps} />
            )}
          </S.SelectContainer>
          <S.SelectContainer
            css={css`
              border-top: unset;
            `}
          >
            <StopSelector
              classNamePrefix="multi-route-stop-selector"
              isDisabled={areStopsDisabled}
              routeId={selector.routes?.[0]?.routeId}
              value={areStopsDisabled ? [] : selector.stops}
              isCampaign={isCampaign}
              isEditing={isEditing}
              hasAllStopsUnselected={
                selector.routes?.[0]?.hasAllStopsUnselected
              }
              onChange={(stops, hasAllRoutesUnselected) => {
                onChange({
                  routes: selector.routes.reduce<RouteMention[]>(
                    (routes, route) => {
                      routes.push({
                        ...route,
                        hasAllStopsUnselected:
                          route.id === selector.routes?.[0]?.routeId
                            ? hasAllRoutesUnselected
                            : false,
                      });

                      return routes;
                    },
                    [],
                  ),
                  stops,
                });
              }}
              showScreensAvailable={showScreensAvailable}
              hasControlBoxShadow
              selectProps={{
                selectStyles: {
                  control: (
                    provided: {},
                    state: { menuIsOpen: boolean; isFocused: boolean },
                  ) => {
                    return {
                      borderRadius: '0',
                      boxShadow: 'unset',
                      ...(!state.menuIsOpen && !state.isFocused
                        ? { borderTop: '1px solid #dddddd' }
                        : {}),
                      ...(state.isFocused
                        ? {
                            zIndex: '2',
                          }
                        : {}),
                      ...(children && !state.isFocused
                        ? {
                            borderBottom: '1px solid transparent',
                          }
                        : {}),
                      // Removing bottom border in case there are any other selectors below this one
                      ...(state.menuIsOpen || children
                        ? {}
                        : {
                            borderBottomLeftRadius: '4px',
                            borderBottomRightRadius: '4px',
                          }),
                    };
                  },
                },
              }}
            />
          </S.SelectContainer>
          {Children.map(children, (child, index) => {
            const selectecContainerKey = `select-container-${index}`;

            return (
              <S.SelectContainer key={selectecContainerKey}>
                {child}
              </S.SelectContainer>
            );
          })}
        </S.SelectCol>
        {removeable && (
          <div
            css={css`
              display: flex;
              flex: 1;
              align-items: flex-start;
              justify-content: center;
              padding: 16px 0 0;
              max-width: 36px;
              background-color: #eaeaea;
              border-radius: 0 4px 4px 0;
              border-left: 1px solid ${theme.colors['border-dark']};
              z-index: 1;
            `}
          >
            <Button
              plain
              type="button"
              aria-label="Remove Line(s) and Station(s)"
              onClick={() => {
                onRemove();
              }}
            >
              <CrossIcon size="10px" />
            </Button>
          </div>
        )}
      </S.Row>
    </div>
  );
};

const MultiRouteSelector: React.FC<IMultiRouteSelector> = ({
  onChange,
  value,
  className,
  addText = 'Add Line',
  showScreensAvailable,
  isCampaign,
}) => {
  const [numRemoved, setNumRemoved] = useState(0);
  const areRemovable = value.length > 1;

  const selectorEls = value.map((screenSelector, idx) => {
    const key = `${idx}-${numRemoved}`;
    const areLabelsHidden = idx > 0;
    const otherSelectors: IEntitySelector[] = value
      .filter((_, i) => i !== idx)
      .flatMap(({ selector }) => selector);
    const disabledRoutes: RouteMention[] = otherSelectors.flatMap(
      (s) => s.routes,
    );

    otherSelectors.forEach((s) => {
      s.routes.forEach((r) => {
        if (!disabledRoutes.find((dr) => dr.routeId === r.routeId)) {
          disabledRoutes.push(r);
        }
      });
    });

    return (
      <BaseSelector
        key={key}
        selector={screenSelector.selector}
        removeable={areRemovable}
        disabledRoutes={disabledRoutes}
        hideLabels={areLabelsHidden}
        showScreensAvailable={showScreensAvailable}
        isCampaign={isCampaign}
        onChange={(selector) => onChange(changeSelector(value, selector, idx))}
        onRemove={() => {
          setNumRemoved((prevNumRemoved) => prevNumRemoved + 1);
          onChange(removeSelector(value, idx));
        }}
      >
        <CustomTagsSelector
          value={screenSelector.tags}
          onChange={(tags) =>
            onChange(value.map((v, i) => (i === idx ? { ...v, tags } : v)))
          }
        />
      </BaseSelector>
    );
  });

  return (
    <div className={className}>
      {selectorEls}
      <Button
        plain
        type="button"
        onClick={() => {
          onChange(addEmptySelector(value));
        }}
      >
        <PlusIcon /> {addText}
      </Button>
    </div>
  );
};

export default MultiRouteSelector;
