/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { OptionProps, ValueContainerProps, components } from 'react-select';

import Checkbox, { CheckboxSize } from '../../components/common/Checkbox';
import Select from '../../components/common/form-elements/Select';
import { ThemeType } from '../../theme';

export interface MultiSelectOption {
  label: React.ReactNode;
  value: any;
  description?: React.ReactNode;
  icon?: React.ReactNode;
}

const OptionText: React.FC<
  {
    color?: keyof ThemeType['colors'];
    description?: string;
  } & { children?: React.ReactNode }
> = ({ color = 'black', description, ...props }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    `}
  >
    <span
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.medium};
        color: ${theme.colors[color]};
        font-family: ${theme.typography.families.primary};
      `}
      {...props}
    />

    {description && (
      <div
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.small};
          color: #707070;
        `}
      >
        {description}
      </div>
    )}
  </div>
);

const Option: React.FC<OptionProps<any> & { children?: React.ReactNode }> = (
  props,
) => {
  const { data: option } = props;

  return (
    <div
      {...props.innerProps}
      style={props.getStyles('option', props) as any}
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      `}
    >
      {/* Left side */}
      <div
        css={css`
          display: flex;
          width: 100%;
        `}
      >
        <Checkbox
          css={css`
            margin-right: 8px;
          `}
          size={CheckboxSize.medium}
          checked={props.isSelected}
          onChange={() => {}}
        />
        <OptionText>{props.label}</OptionText>
      </div>

      {/* Right side */}
      {option.description && (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {option.icon && <div>{option.icon}</div>}

          <div
            css={(theme: ThemeType) => css`
              ${theme.typography.sizes.small};
              color: ${theme.colors.dark3};
              min-width: 80px;
              text-align: right;
            `}
          >
            {option.description}
          </div>
        </div>
      )}
    </div>
  );
};

const ValueContainer: React.FC<
  ValueContainerProps & { children?: React.ReactNode }
> = ({ children, ...props }) => {
  const content = (props.selectProps as any).renderValueContainer(
    props.getValue() ?? [],
    { OptionText },
  );

  return (
    <components.ValueContainer
      css={css`
        margin-left: 4px;
        padding: 2px 4px 2px 8px;
      `}
      {...props}
    >
      <div
        style={props.getStyles('valueContainer', props as any) as any}
        css={css`
          display: flex;
        `}
      >
        {content}
      </div>
      {children}
    </components.ValueContainer>
  );
};

interface MultiSelectProps {
  placeholder?: string;
  renderValueContainer?: (
    options: MultiSelectOption[],
    components: {
      OptionText: typeof OptionText;
    },
  ) => React.ReactNode;
  options: MultiSelectOption[];
  onChange: (options: MultiSelectOption['value'][]) => void;
  value: MultiSelectOption['value'][];
  disabled?: boolean;
  styles?: any;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  renderValueContainer = (os: MultiSelectOption[]) => (
    <OptionText>{os.map((o) => o.label).join(', ')}</OptionText>
  ),
  options,
  placeholder,
  onChange,
  disabled = false,
  styles = {},
  ...props
}) => {
  const value = props.value
    .map((v) => options.find((o) => o.value === v))
    .filter(Boolean);

  return (
    <Select
      isMulti
      hasControlBorder={false}
      isSearchable={false}
      isClearable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      isDisabled={disabled}
      components={{ Option, ValueContainer }}
      getOptionLabel={(o: MultiSelectOption) => o.label}
      getOptionValue={(o: MultiSelectOption) => o.value}
      options={options}
      value={value}
      placeholder={value.length <= 0 ? placeholder : ''}
      onChange={(updated: MultiSelectOption[]) => {
        onChange((updated ?? []).map((o) => o.value));
      }}
      // Used by ValueContainer through props.selectProps
      renderValueContainer={renderValueContainer}
      hasControlBoxShadow
      styles={styles}
    />
  );
};

export default MultiSelect;
