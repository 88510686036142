/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import { loader } from 'graphql.macro';

import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, ApolloError } from '@apollo/client';

import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';

import { useFeedId } from 'contexts/FeedId';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { UploadType } from 'hooks/useUpload';
import * as Sentry from '@sentry/browser';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';
import {
  UpdateUpload,
  UpdateUploadVariables,
} from '../../../generated/UpdateUpload';
import {
  GetSocialMediaUploadById,
  GetSocialMediaUploadByIdVariables,
} from '../../../generated/GetSocialMediaUploadById';
import {
  UpdateSocialMediaUpload,
  UpdateSocialMediaUploadVariables,
} from '../../../generated/UpdateSocialMediaUpload';
import * as S from './index.styled';

import AssetForm, {
  FormValues as AssetFormValues,
  DefaultValues,
  FormTypes,
} from '../../common/asset-form';

const GetSocialMediaUploadByIdQuery = loader(
  '../../../graphql/GetSocialMediaUploadById.gql',
);

const UpdateSocialMediaUploadMutation = loader(
  '../../../graphql/UpdateSocialMediaUpload.gql',
);

const UpdateUploadMutation = loader('../../../graphql/UpdateUpload.gql');

const SocialMediaAssetEdit: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const feedId = useFeedId();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { loading, data } = useQuery<
    GetSocialMediaUploadById,
    GetSocialMediaUploadByIdVariables
  >(GetSocialMediaUploadByIdQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const [updateUpload] = useMutation<UpdateUpload, UpdateUploadVariables>(
    UpdateUploadMutation,
    {
      onError: (error: ApolloError) => {
        throw error;
      },
    },
  );

  const [updateSocialMediaUpload] = useMutation<
    UpdateSocialMediaUpload,
    UpdateSocialMediaUploadVariables
  >(UpdateSocialMediaUploadMutation, {
    onError: (error: ApolloError) => {
      throw error;
    },
  });

  const upload = data?.socialMediaUpload?.upload;
  const uploadFolder = data?.socialMediaUpload?.folder;

  const defaultValues: DefaultValues = {
    title: upload?.title ?? '',
    description: upload?.description ?? '',
    folder: uploadFolder
      ? { label: uploadFolder?.name, value: uploadFolder?.id }
      : undefined,
    upload: upload as UploadType,
    width: upload?.width || undefined,
    height: upload?.height || undefined,
    screenTypes: [],
  };

  const handleSubmit = () => async (fv: AssetFormValues) => {
    setIsSubmitting(true);
    try {
      const { data: uploadData } = await updateUpload({
        variables: { ...fv, id: fv.upload?.id },
      });
      await updateSocialMediaUpload({
        variables: {
          uploadId: fv.upload?.id,
          oldUploadId: upload?.id,
          folderId: fv?.folderId ?? null,
        },
      });

      history.push(
        `/${feedId}/assets/${uploadData?.updateUpload?.upload?.id}?type=social-media`,
      );
    } catch (e) {
      Sentry.captureException(e);
    }
    setIsSubmitting(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <PageMeta title="Edit Asset" />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}/assets/social-media`,
          title: 'Back to social media',
        }}
      />
      <Loader loading={loading}>
        <AssetForm
          feedId={feedId}
          heading="Edit Asset"
          defaultValues={defaultValues}
          formType={FormTypes.SocialMedia}
        >
          {/* @ts-ignore */}
          {({ makeSubmitHandler, form }) => {
            return (
              <React.Fragment>
                {form}

                <S.Buttons
                  css={css`
                    margin: 16px 32px;
                  `}
                >
                  <Button
                    primary
                    type="button"
                    disabled={isSubmitting}
                    onClick={makeSubmitHandler(handleSubmit())}
                  >
                    Update
                  </Button>
                  <Button
                    type="button"
                    disabled={false}
                    onClick={() => {
                      if (
                        window.confirm(
                          `${DISCARD_CHANGES_MESSAGE} This asset will not be saved.`,
                        )
                      ) {
                        history.push(`/${feedId}/assets/social-media`);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </S.Buttons>
              </React.Fragment>
            );
          }}
        </AssetForm>
      </Loader>
    </div>
  );
};

export default SocialMediaAssetEdit;
