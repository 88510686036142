/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import ms from 'ms.macro';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import {
  WEB_NO_MESSAGES_CTA_LABEL,
  WEB_NO_MESSAGES_CTA_LABEL_FILTERS,
  WEB_NO_MESSAGES_HEADING,
  WEB_NO_MESSAGES_HEADING_FILTERS,
  WEB_NO_MESSAGES_MESSAGE,
  WEB_NO_MESSAGES_MESSAGE_FILTERS,
} from 'constants/empty-states';
import Pagination from '../../common/Pagination';
import NoMessages from '../../common/NoMessages';
import DeleteDraftAlertModal from '../../common/delete-draft-alert-modal';
import { useFeedId, FeedId } from '../../../contexts/FeedId';
import PageMeta from '../../common/PageMeta';
import AppLink, { ApplinkStyles } from '../../common/app-link';
import Button from '../../common/Button';
import PageHeading, { ButtonList } from '../../scaffolding/PageHeading';
import DraftMessage from './draft-message';
import { TStatusPillTypes } from '../../common/status-pill';
import useQueryParams from '../../../hooks/useQueryParams';
import { useRoutesByFeedId } from '../../../contexts/Routes';
import RouteSelector from '../../common/form-elements/route-selector';
import Loader from '../../common/skeletons/PageWithCards';
import BusRouteSelector from '../../common/form-elements/route-selector/bus-route-selector';
import { RouteMention } from '../../../types';
import { routeToRouteMention } from '../../../utils/route-mentions';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as TRoute } from '../../../generated/RoutesByFeed';
import { getClearedMessageTypeDescriptionsByFeed } from '../../../utils/message-type-display';
import Select from '../../common/form-elements/Select';
import {
  DraftMessages as DraftMessagesType,
  DraftMessagesVariables,
} from '../../../generated/DraftMessages';
import {
  DeleteDraftMessage,
  DeleteDraftMessageVariables,
} from '../../../generated/DeleteDraftMessage';
import {
  MessageType,
  DraftMessageFilter,
} from '../../../generated/global-types';
import * as S from './index.styled';

const DraftMessagesQuery = loader('../../../graphql/DraftMessages.gql');
const DeleteDraftMessageMutation = loader(
  '../../../graphql/DeleteDraftMessage.gql',
);
const GetEventByIdQuery = loader('../../../graphql/GetEventById.gql');

const MESSAGES_PER_PAGE = 10;

const DraftMessages: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const feedId = useFeedId();

  const queryParams = useQueryParams();
  const allRoutes = useRoutesByFeedId(feedId);
  const history = useHistory();

  const CATEGORY_OPTIONS = getClearedMessageTypeDescriptionsByFeed(feedId);

  const QUERY_PARAM_KEYS = {
    search: 'search',
    routes: 'lines',
    category: 'category',
    page: 'page',
  };

  const updateQueryParam = (name: string, value: string) => {
    queryParams.set(name, encodeURIComponent(value));
    history.push(`/${feedId}/alerts/draft?${queryParams.toString()}`);
  };

  const routes: string[] = decodeURIComponent(
    queryParams.get(QUERY_PARAM_KEYS.routes) ?? '',
  )
    .split(',')
    .filter(Boolean);
  const routeMentions: RouteMention[] = (
    routes
      .map((gtfsId: string) => allRoutes.find((r) => r.gtfsId === gtfsId))
      .filter(Boolean) as TRoute[]
  ).map((r) => routeToRouteMention({ route: r }));
  const category = queryParams.get(QUERY_PARAM_KEYS.category);
  const page = parseInt(queryParams.get(QUERY_PARAM_KEYS.page) ?? '1', 10);
  const [rawSearch, setSearch] = useState(
    queryParams.get(QUERY_PARAM_KEYS.search) ?? '',
  );

  const [search] = useDebounce(rawSearch, ms('500ms'));
  const [debouncedCallback] = useDebouncedCallback((value: string) => {
    updateQueryParam(QUERY_PARAM_KEYS.search, value);
  }, 500);

  const variables = {
    offset: (page - 1) * MESSAGES_PER_PAGE,
    perPage: MESSAGES_PER_PAGE,
    filter: {
      and: [
        { feedId: { equalTo: feedId } },
        {
          or: [
            { bodyText: { includesInsensitive: search } },
            { additionalInfoText: { includesInsensitive: search } },
            { notes: { includesInsensitive: search } },
          ],
        },
        category && {
          messageTypes: { anyEqualTo: category as MessageType },
        },
        routes.length > 0 && { routeIds: { overlaps: routes } },
      ].filter(Boolean) as DraftMessageFilter[],
    },
  };

  const [deleteDraftAlertModalProps, setDeleteDraftAlertModalProps] = useState<{
    isOpen: boolean;
    messageId: number;
    eventId: number;
  }>();

  const { data, loading } = useQuery<DraftMessagesType, DraftMessagesVariables>(
    DraftMessagesQuery,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    },
  );

  const [deleteDraftMessage] = useMutation<
    DeleteDraftMessage,
    DeleteDraftMessageVariables
  >(DeleteDraftMessageMutation, {
    refetchQueries: [
      'DraftMessages',
      {
        query: GetEventByIdQuery,
        variables: {
          id: deleteDraftAlertModalProps?.eventId,
          includeClearingScreenMessages: false,
        },
      },
    ],
  });

  const draftMessages = data?.draftMessages?.nodes ?? [];
  const totalCount = data?.draftMessages?.totalCount ?? 0;

  const filtersApplied = routes.length || category || rawSearch || page > 1;

  const updateSearch = (value: string) => {
    setSearch(value);
    debouncedCallback(value);
  };

  const clearAlertsFilters = () => {
    updateQueryParam(QUERY_PARAM_KEYS.routes, '');
    updateQueryParam(QUERY_PARAM_KEYS.category, '');
    updateQueryParam(QUERY_PARAM_KEYS.page, '1');
    updateSearch('');
  };

  const routeSelectorProps = {
    css: S.select,
    isMulti: true,
    routes: routeMentions,
    placeholder: 'Line(s)',
    onChange: (selRoutes: RouteMention[]) =>
      updateQueryParam(
        QUERY_PARAM_KEYS.routes,
        selRoutes.map((r) => r.routeId).toString(),
      ),
  };

  const noMessagesProps = {
    icon: false,
    message: WEB_NO_MESSAGES_MESSAGE,
    heading: WEB_NO_MESSAGES_HEADING,
    ctaLabel: WEB_NO_MESSAGES_CTA_LABEL,
  };

  if (filtersApplied) {
    noMessagesProps.icon = true;
    noMessagesProps.message = WEB_NO_MESSAGES_MESSAGE_FILTERS;
    noMessagesProps.heading = WEB_NO_MESSAGES_HEADING_FILTERS;
    noMessagesProps.ctaLabel = WEB_NO_MESSAGES_CTA_LABEL_FILTERS;
  }

  return (
    <S.Container>
      <PageMeta title="Draft Alerts" />
      <PageHeading
        breadcrumbs={[
          'Messages',
          {
            label: 'Alerts',
            to: `/${feedId}`,
          },
        ]}
        title="Drafts"
      >
        <ButtonList>
          <AppLink
            to={`/${feedId}/compose`}
            styleVariations={[
              ApplinkStyles.PRIMARY_BUTTON,
              ApplinkStyles.LARGE_BUTTON,
            ]}
          >
            Compose
          </AppLink>
        </ButtonList>
      </PageHeading>
      <S.Container>
        <S.SearchContainer>
          <S.SearchInput
            type="text"
            placeholder="Search header, message and internal notes"
            value={rawSearch}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateSearch(e.target.value)
            }
            onClear={() => updateSearch('')}
          />
        </S.SearchContainer>
        <S.AlertsFilters>
          <S.SelectContainer>
            {feedId === FeedId.NYCTBus ? (
              <BusRouteSelector {...routeSelectorProps} />
            ) : (
              <RouteSelector {...routeSelectorProps} />
            )}
          </S.SelectContainer>
          <S.SelectContainer css={S.leftSpace}>
            <Select
              css={S.select}
              options={CATEGORY_OPTIONS}
              value={
                CATEGORY_OPTIONS.find((option) => option.value === category) ??
                ''
              }
              placeholder="Alert Status"
              isSearchable={false}
              onChange={(update: any) => {
                if (update) {
                  const { value }: { value: TStatusPillTypes } = update;
                  updateQueryParam(QUERY_PARAM_KEYS.category, value);
                } else {
                  updateQueryParam(QUERY_PARAM_KEYS.category, '');
                }
              }}
            />
          </S.SelectContainer>
          <S.SelectContainer css={S.clearButtonContainer}>
            <Button
              size="small"
              aria-label="Clear Filters"
              css={S.clearButton}
              type="button"
              disabled={!filtersApplied}
              onClick={clearAlertsFilters}
            >
              Clear
            </Button>
          </S.SelectContainer>
        </S.AlertsFilters>
      </S.Container>
      <S.MessageListContainer hasMessages={!!draftMessages.length}>
        <Loader loading={loading}>
          {draftMessages.length ? (
            <div
              css={css`
                width: 100%;
              `}
            >
              {draftMessages.map((message) => (
                <DraftMessage
                  key={`message-${message.id}`}
                  {...message}
                  onDelete={() =>
                    setDeleteDraftAlertModalProps({
                      isOpen: true,
                      messageId: message.id,
                      eventId: message.eventId,
                    })
                  }
                />
              ))}
              <div
                css={css`
                  text-align: right;
                `}
              >
                <Pagination
                  currentPage={page}
                  itemsPerPage={MESSAGES_PER_PAGE}
                  totalItems={totalCount}
                  onPrevClick={() =>
                    updateQueryParam(QUERY_PARAM_KEYS.page, `${page - 1}`)
                  }
                  onNextClick={() =>
                    updateQueryParam(QUERY_PARAM_KEYS.page, `${page + 1}`)
                  }
                />
              </div>
            </div>
          ) : (
            <NoMessages
              icon={noMessagesProps.icon}
              heading={noMessagesProps.heading}
              message={noMessagesProps.message}
              ctaLabel={noMessagesProps.ctaLabel}
              ctaLink={`/${feedId}/compose`}
            />
          )}
        </Loader>
        <DeleteDraftAlertModal
          isOpen={deleteDraftAlertModalProps?.isOpen ?? false}
          onDismiss={() => setDeleteDraftAlertModalProps(undefined)}
          onConfirm={async () => {
            if (!deleteDraftAlertModalProps) {
              return;
            }
            await deleteDraftMessage({
              variables: { messageId: deleteDraftAlertModalProps.messageId },
            });
            setDeleteDraftAlertModalProps(undefined);
          }}
        />
      </S.MessageListContainer>
    </S.Container>
  );
};

export default DraftMessages;
