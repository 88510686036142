/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import { loader } from 'graphql.macro';

import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, ApolloError } from '@apollo/client';

import {
  CampaignInputRecordInput,
  CampaignStatus,
  DurationsSourceInput,
  TrainTargetingInput,
} from 'generated/global-types';

import removeTypenames from 'utils/remove-typenames';

import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';
import ErrorModal from 'components/common/error-modal';
import {
  GENERIC_SUBMIT_NETWORK_ERROR,
  GENERIC_SUBMIT_ERROR,
} from 'constants/error-messages';
import * as Sentry from '@sentry/browser';
import { FeedId } from 'types/feeds';
import { useRoutesByFeedId } from 'contexts/Routes';
import { getValidCampaignScreenContentSelectors } from 'utils/campaign-helpers';

import BackOnlyPageHeading from '../../scaffolding/back-only-page.heading';
import PageMeta from '../../common/PageMeta';
import Button from '../../common/Button';
import Loader from '../../common/skeletons/PageWithContent';

import CampaignForm, {
  DefaultValues as CampaignFormDefaultValues,
} from '../../common/campaign-form';
import { uploads_gqlToForm } from '../../common/campaign-form/serializers';

import {
  SingleCampaignView,
  SingleCampaignViewVariables,
} from '../../../generated/SingleCampaignView';
import {
  UpdateCampaign,
  UpdateCampaignVariables,
} from '../../../generated/UpdateCampaign';

const SingleCampaignViewQuery = loader(
  '../../../graphql/SingleCampaignView.gql',
);
const UpdateCampaignMutation = loader('../../../graphql/UpdateCampaign.gql');

const CampaignEdit: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const history = useHistory();
  const { feedId, campaignId } = useParams<{
    feedId: FeedId;
    campaignId: string;
  }>();
  const allRoutes = useRoutesByFeedId(feedId);
  const query = useQuery<SingleCampaignView, SingleCampaignViewVariables>(
    SingleCampaignViewQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        campaignId: parseFloat(campaignId as string),
        includeClearingScreenContent: false,
      },
    },
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorModalSettings, setErrorModalSettings] = useState({
    isOpen: false,
    isNetworkError: false,
  });

  const [updateCampaign] = useMutation<UpdateCampaign, UpdateCampaignVariables>(
    UpdateCampaignMutation,
    {
      onError: (error: ApolloError) => {
        if (error) {
          setErrorModalSettings({
            isOpen: true,
            isNetworkError: !!error.networkError?.name,
          });
        }
        throw error;
      },
    },
  );

  const handleSave = async ({ ...restFV }: CampaignInputRecordInput) => {
    setIsSubmitting(true);

    try {
      const { data } = await updateCampaign({
        variables: {
          id: parseInt(campaignId as string, 10),
          updateCampaignInput: {
            ...restFV,
          },
        },
      });
      setIsSubmitting(false);

      const locationState = data?.updateCampaign?.campaign ?? {};
      history.push(`/${feedId}/campaigns/${campaignId}`, locationState);
      return;
    } catch (e) {
      Sentry.captureException(e);
    }

    setIsSubmitting(false);
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <PageMeta title="Edit Campaign" />
      <Loader loading={query.loading}>
        <BackOnlyPageHeading
          back={{
            to: `/${feedId}/campaigns`,
            title: 'Back to Campaigns',
          }}
        />

        {(() => {
          if (!query?.data?.campaign) {
            return null;
          }

          const {
            campaign,
            campaign: { title, campaignScreenContents },
          } = query.data;

          const durationsSource = removeTypenames(
            campaign.durationsSource,
          ) as DurationsSourceInput;

          const defaultValues = {
            title,
            durationsSource,
            targeting: getValidCampaignScreenContentSelectors(
              campaignScreenContents.nodes,
              allRoutes,
            )?.map((n) => {
              return {
                id: n.id,
                layouts: n.targetTypes,
                screenSelectors: n.trainTargeting ? [] : n.screenSelectors,
                weightFrequency: {
                  priority: n.priority,
                  weight: n.weight,
                },
                trainTargeting: removeTypenames(
                  n.trainTargeting as any,
                ) as TrainTargetingInput,
                uploads: uploads_gqlToForm(
                  n.campaignScreenContentUploads.nodes,
                ),
                screenName: n.screenName,
              };
            }),
          } as CampaignFormDefaultValues;

          return (
            <CampaignForm
              feedId={feedId as FeedId}
              heading="Edit Campaign"
              defaultValues={defaultValues}
            >
              {/* @ts-ignore */}
              {({ makeSubmitHandler, form }) => (
                <React.Fragment>
                  {form}

                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      margin: 16px 32px;
                    `}
                  >
                    <Button
                      type="button"
                      css={css`
                        margin-right: 12px;
                      `}
                      onClick={() => {
                        if (
                          window.confirm(
                            `${DISCARD_CHANGES_MESSAGE} This campaign will not be updated.`,
                          )
                        ) {
                          history.push(`/${feedId}/campaigns`);
                        }
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      primary
                      disabled={
                        campaign.status === CampaignStatus.CLEARED ||
                        isSubmitting
                      }
                      onClick={makeSubmitHandler(handleSave)}
                    >
                      Update
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </CampaignForm>
          );
        })()}
      </Loader>
      <ErrorModal
        isOpen={errorModalSettings.isOpen}
        title="Campaign Failed to Post"
        onDismiss={() =>
          setErrorModalSettings({
            isOpen: false,
            isNetworkError: false,
          })
        }
        message={
          errorModalSettings.isNetworkError
            ? GENERIC_SUBMIT_NETWORK_ERROR
            : GENERIC_SUBMIT_ERROR
        }
        includeAssistanceMessage
      />
    </div>
  );
};

export default CampaignEdit;
