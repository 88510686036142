/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { DetailedHTMLProps, HTMLAttributes, ButtonHTMLAttributes } from 'react';

import { ThemeType } from '../../../theme';

export const Nav: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
> & { children?: React.ReactNode } = (props) => (
  <nav
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};

      align-items: center;
      justify-content: flex-end;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      font-family: ${theme.typography.families.primary};
      min-width: 0;
    `}
    {...props}
  />
);

export const CurrentPage: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      color: ${theme.colors.dark2};
      display: flex;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      flex: 0 0 auto;
      margin-right: 12px;
    `}
    {...props}
  />
);

export const ButtonList: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <ul
    css={css`
      display: flex;
      flex-direction: row;
      flex: 0 0 74px;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      list-style: none;
    `}
    {...props}
  />
);

export const ListItem: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <li
    css={css`
      display: block;
      flex: 0 0 32px;
      width: 32px;
      height: 32px;
    `}
    {...props}
  />
);

export const PageButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = ({ disabled, ...props }) => (
  <button
    type="button"
    disabled={disabled}
    css={(theme: ThemeType) => css`
      height: 32px;
      width: 32px;
      border-radius: 4px;
      border: ${disabled
        ? `2px solid ${theme.colors.light2}`
        : `2px solid ${theme.colors.accent1}`};
      color: ${disabled ? theme.colors.light2 : theme.colors.accent1};
      font-weight: ${theme.typography.weights.bold};
      background-color: ${theme.colors.white};
      cursor: ${disabled ? 'default' : 'pointer'};

      path {
        fill: ${disabled ? theme.colors.light2 : theme.colors.accent1};
      }
      display: inline-flex;
      align-items: center;
      justify-content: center;

      outline: none;
      ${disabled
        ? ''
        : `:hover {
          border-color: ${theme.colors.blue};
          color: ${theme.colors.blue};
        }`}
    `}
    {...props}
  />
);
