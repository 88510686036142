/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';

import { ScreenType } from 'generated/global-types';

import { GetLockableUploadById_lockableUpload_LockableUpload_screens_LockableUploadsScreensConnection_nodes_LockableUploadsScreen as GetLockableUploadById_lockableUpload_screens_nodes } from 'generated/GetLockableUploadById';
import { getLabelFromScreenType } from 'components/pages/Screen/in-screens-preview/helpers';
import TargetedScreens from './targeted-screens';
import { ReactComponent as CloseIcon } from '../../../images/cross-blue.svg';
import { FeedId } from '@mta-live-media-manager/shared';

const AssetScreenInfo: React.FC<
  {
    targetedSceensTypes?: GetLockableUploadById_lockableUpload_screens_nodes[];
    feedId: FeedId;
    type?: ScreenType | null;
    onClick?: () => void;
  } & { children?: React.ReactNode }
> = ({ targetedSceensTypes, onClick, feedId, type }) => (
  <div
    css={css`
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
    `}
  >
    <div>
      {targetedSceensTypes &&
        targetedSceensTypes.map(
          (screen) =>
            screen.type &&
            screen.totalCount && (
              <TargetedScreens
                key={screen.type}
                type={screen.type}
                totalCount={screen.totalCount}
              />
            ),
        )}
      {type && !targetedSceensTypes && getLabelFromScreenType(type, feedId)}
    </div>
    {onClick && (
      <button type="button" onClick={() => onClick()}>
        <CloseIcon />
      </button>
    )}
  </div>
);

export default AssetScreenInfo;
