/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import Theme from '../../../../theme';

export const TargetSection: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      margin-bottom: 24px;
      ${Theme.typography.sizes.medium};
    `}
    {...props}
  />
);

export const TargetList: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      ${Theme.typography.sizes.small};
      margin-top: 4px;
      margin-bottom: 8px;
    `}
    {...props}
  />
);

export const TargetLabel: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={css`
      display: block;
      font-size: ${Theme.typography.sizes.medium.fontSize};
      line-height: ${Theme.typography.sizes.small.lineHeight};
    `}
    {...props}
  />
);

export const TargetScreens: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <span
    css={css`
      display: block;
      margin-top: 4px;
      ${Theme.typography.sizes.small}
    `}
    {...props}
  />
);

export const Target = {
  Section: TargetSection,
  List: TargetList,
  Label: TargetLabel,
  Screens: TargetScreens,
};
