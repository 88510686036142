import { useState, useEffect } from 'react';
import { ActionMeta } from 'react-select';
import { BoroughGroup, TargetingOption } from 'utils/stop-selector-helpers';

const getBulkStopSelectOptions = (
  startOption: TargetingOption,
  endOption: TargetingOption,
  options: TargetingOption[],
) => {
  const optionsToBulkSelect: TargetingOption[] = [];

  const selectedOptionIndex = options.findIndex(
    ({ key }) => key === endOption.key,
  );
  const newSelectedOptionIndex = options.findIndex(
    ({ key }) => key === startOption.key,
  );

  const isForward = newSelectedOptionIndex > selectedOptionIndex;

  if (selectedOptionIndex !== newSelectedOptionIndex) {
    for (
      let i = isForward ? selectedOptionIndex + 1 : selectedOptionIndex - 1;
      isForward ? i <= newSelectedOptionIndex : i >= newSelectedOptionIndex;
      isForward ? (i += 1) : (i -= 1)
    ) {
      optionsToBulkSelect.push(options[i]);
    }
  }

  return optionsToBulkSelect;
};

const useBulkSelect = ({
  options,
}: {
  options: (TargetingOption | BoroughGroup)[];
}) => {
  const [bulkSelectStartOption, setBulkSelectStartOption] = useState<
    TargetingOption | undefined
  >();
  const [isBulkSelecting, setIsBulkSelecting] = useState(false);

  const resetBulkSelect = () => {
    setBulkSelectStartOption(undefined);
    setIsBulkSelecting(false);
  };

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        resetBulkSelect();
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => document.removeEventListener('keyup', handleKeyUp);
  }, []);

  const handleBulkSelect = ({
    selectedOptions,
    event,
  }: {
    selectedOptions: TargetingOption[];
    event: ActionMeta<TargetingOption>;
  }) => {
    if (!isBulkSelecting || event.action !== 'select-option') {
      return [];
    }

    const selectedOption = selectedOptions[selectedOptions.length - 1];

    if (bulkSelectStartOption) {
      const optionsToBulkSelect = getBulkStopSelectOptions(
        bulkSelectStartOption,
        selectedOption,
        options.flatMap((o) => (o as BoroughGroup).options ?? o),
      );

      resetBulkSelect();

      return optionsToBulkSelect;
    }

    setBulkSelectStartOption(selectedOption);

    return [];
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.shiftKey) {
      setIsBulkSelecting(true);
    }
  };

  return {
    handleKeyDown,
    handleBulkSelect,
  };
};

export default useBulkSelect;
