/** @jsxImportSource @emotion/react */

import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import {
  ListboxInput,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox';

import { listboxList, listboxOption } from 'components/common/styles';
import { Z_INDEX as MODAL_Z_INDEX } from 'components/common/modal';
import StandardListboxButton from 'ui-kit/standard-listbox-button';

const StandardListbox: React.FC<
  {
    id?: string;
    value?: string | undefined;
    options: [string, React.ReactNode][];
    listboxButtonCss?: SerializedStyles;
    onChange?: (newValue: string | undefined) => void;
    children?: React.ReactNode;
  } & { children?: React.ReactNode }
> = ({ id, value, options, listboxButtonCss, onChange, children }) => {
  const labels: { [key: string]: React.ReactNode } = options.reduce(
    (o, [k, v]) => ({ ...o, [k]: v }),
    {},
  );

  return (
    <ListboxInput
      id={id}
      value={value}
      css={css`
        & > [data-reach-listbox-button] {
          border-radius: 4px;
        }
      `}
      onChange={onChange}
    >
      <StandardListboxButton
        css={css`
          border-radius: 4px;
          ${listboxButtonCss}
        `}
      >
        {children || (value ? labels[value] : null)}
      </StandardListboxButton>
      <ListboxPopover
        css={css`
          z-index: ${MODAL_Z_INDEX + 1};
        `}
      >
        <ListboxList css={listboxList}>
          {options.map(([optionValue, optionLabel]) => (
            <ListboxOption
              css={listboxOption}
              key={optionValue}
              value={optionValue}
            >
              {optionLabel}
            </ListboxOption>
          ))}
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  );
};

export default StandardListbox;
