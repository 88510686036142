import React, { useContext, useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import {
  ApolloProvider,
  ServerError,
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  HttpLink,
  ApolloLink,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import authenticationClient from '../utils/authentication';

const {
  REACT_APP_API_URL,
  REACT_APP_API_WEBSOCKET_URL,
  REACT_APP_DEPLOY_ENV,
  REACT_APP_GIT_RELEASE,
} = process.env;

export const REMIRROR_ERRORS_TO_SILENCE: (string | RegExp)[] = [
  'RangeError: Empty text nodes are not allowed',
  'RangeError: Applying a mismatched transaction',
  /RangeError: Position [0-9]+ out of range/,
  "Cannot read properties of undefined (reading 'content')",
];

/**
 * Init Sentry
 */
Sentry.init({
  dsn: 'https://ac5893bce7134e2287a7affcb96ddf1e@sentry.io/1804170',
  environment: REACT_APP_DEPLOY_ENV,
  release: REACT_APP_GIT_RELEASE,
  ignoreErrors: [
    ...REMIRROR_ERRORS_TO_SILENCE,
    /Invariant Violation: [0-9]+ \(see https:\/\/github\.com\/apollographql\/invariant-packages\)/,
  ],
  beforeSend(event) {
    const currentUser = authenticationClient.session?.currentUser;
    return {
      ...event,
      user: {
        email: currentUser?.email,
        username: currentUser?.name,
      },
    };
  },
});

// Send Apollo Errors to Sentry
// https://www.apollographql.com/docs/react/data/error-handling/
const errorLink = onError(
  ({ operation, graphQLErrors, networkError, response, forward }) => {
    Sentry.withScope((scope) => {
      scope.setExtras({
        operation,
        graphQLErrors,
        networkError,
        response,
        forward,
      });
    });
  },
);

/**
 * Init Apollo
 */

if (!REACT_APP_API_WEBSOCKET_URL) {
  throw new Error('REACT_APP_API_WEBSOCKET_URL is not set.');
}

let websocketIdentityToken: string | null;

// Create a WebSocket link
// We do the first because we're going to do some really hacky stuff,
// which I'm commenting on here to make myself feel less terrible about.
//
// First, we're going to depend on the `identityToken` variable above.
// This is because:
//     1. WebSockets don't authenticate with headers like normal HTTP requests.
//     2. The Apollo/Postgraphile convention is to send a Bearer clause with the
//        initial connection, which can only be done in apollo-link-ws when you
//        actually initialize the link.
//     3. The Apollo WebSocket link can't access Apollo contexts.
// Next, we're going to reach into this link and force a reconnect whenever the
// identity token changes. It's not ideal, but it gets us there.
const wsLink = new WebSocketLink({
  uri: REACT_APP_API_WEBSOCKET_URL,
  options: {
    reconnect: true,
    connectionParams: () =>
      websocketIdentityToken
        ? {
            Authorization: `Bearer ${websocketIdentityToken}`,
          }
        : {},
  },
});

const withIdentityToken = setContext(() => {
  return authenticationClient
    .getSessionWithRefresh()
    .then((freshIdentityToken) => {
      if (websocketIdentityToken !== freshIdentityToken) {
        websocketIdentityToken = freshIdentityToken;
        // Force the websocket client to close and reconnect to grab the new token.
        // @ts-ignore
        wsLink.subscriptionClient.close(false);
      }

      if (freshIdentityToken) {
        return { headers: { authorization: `Bearer ${freshIdentityToken}` } };
      }
      return {};
    });
});

const resetIdentityToken = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    (networkError as ServerError).statusCode === 401
  ) {
    // remove cached websocket token on 401 from the server
    websocketIdentityToken = null;

    // @ts-ignore
    wsLink.subscriptionClient.close(false);
  }
});

const authFlowLink = withIdentityToken.concat(resetIdentityToken);

// from https://www.apollographql.com/docs/react/data/subscriptions/

// Create an http link:
const httpLink = new HttpLink({
  uri: REACT_APP_API_URL,
  credentials: 'include',
});

const requestsLink = authFlowLink.concat(
  split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  ),
);

interface IConnectionStatus {
  subscriptionClientConnected: boolean;
}
const ConnectionStatusContext = React.createContext<IConnectionStatus>({
  subscriptionClientConnected: true,
});

interface IProps {
  children?: React.ReactNode;
}

export const ConnectionStatusProvider: React.FC<IProps> = ({ children }) => {
  const [subscriptionClientConnected, setSubscriptionClientConnected] =
    useState(true);

  useEffect(() => {
    // @ts-ignore
    const removeConnectedListener = wsLink.subscriptionClient.onConnected(() =>
      setSubscriptionClientConnected(true),
    );
    // @ts-ignore
    const removeDisconnectedListener = wsLink.subscriptionClient.onDisconnected(
      () => setSubscriptionClientConnected(false),
    );
    // @ts-ignore
    const removeReconnectedListener = wsLink.subscriptionClient.onReconnected(
      () => setSubscriptionClientConnected(true),
    );

    return () => {
      removeConnectedListener();
      removeDisconnectedListener();
      removeReconnectedListener();
    };
  });

  return (
    <ConnectionStatusContext.Provider
      value={{
        subscriptionClientConnected,
      }}
    >
      {children}
    </ConnectionStatusContext.Provider>
  );
};

export const useConnectionStatus = () => useContext(ConnectionStatusContext);

const link = ApolloLink.from([errorLink, requestsLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    dataIdFromObject: (obj: any) => {
      /**
       * Our Mentions weren't guaranteed to have unique IDs (stops and routes
       * sometimes had the same ID), which was causing issues in our query
       * related to the cache. This switch statement handles that case, but
       * we need to make sure that there are no other similar cases or
       * weirdness ensues.
       */
      switch (
        obj.__typename // eslint-disable-line no-underscore-dangle
      ) {
        case 'Mention':
          return `${obj.id}:${obj.type}`;
        default:
          return defaultDataIdFromObject(obj);
      }
    },
  }),
});

const GraphQLProvider: React.FC<any> = (props) => (
  <ApolloProvider client={client}>{props.children}</ApolloProvider>
);

export default GraphQLProvider;
