/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFeedId } from 'contexts/FeedId';
import { ThemeType } from 'theme';
import Button from 'components/common/Button';
import { Screen as Screens2 } from '../../../generated/Screen';
import { SyntheticScreen } from '../../../generated/SyntheticScreen';
import { ScreenDeployTypes } from 'generated/global-types';

export interface BackPageState {
  backTitle: string;
  backPath: string;
}

type ScreenType = Screens2['screen2'] | SyntheticScreen['syntheticScreen'];

const getScreenType = (screen: ScreenType) =>
  screen?.__typename === 'Screens2'
    ? ScreenDeployTypes.OUTFRONT
    : ScreenDeployTypes.SYNTHETIC;

const SingleScreenCampaign: React.FC<{
  screen: ScreenType;
  disabled?: boolean;
  children?: React.ReactNode;
}> = ({ screen, disabled = false }) => {
  const feedId = useFeedId();
  const history = useHistory();

  const screenId = screen?.id;
  const screenName = screen?.name;
  const screenType = getScreenType(screen).toLowerCase();

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <label
        htmlFor="add-single-screen-campaign"
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.large}
          font-weight: ${theme.typography.weights.bold};
        `}
      >
        Single Screen Campaigns
      </label>
      <Button
        id="add-single-screen-campaign"
        primary
        size="mediumLong"
        type="button"
        disabled={disabled}
        onClick={() =>
          history.push(`/${feedId}/campaigns/compose`, {
            backTitle: 'Back to Screen',
            backPath: `/${feedId}/screens/${screenId}?t=${screenType}`,
            defaultValues: {
              targeting: [{ screenName }],
            },
          })
        }
        css={css`
          padding: 0 25px;
        `}
      >
        Add Campaign
      </Button>
    </div>
  );
};

export default SingleScreenCampaign;
