/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ComponentProps } from 'react';

import Button from '../../common/Button';
import Heading from '../../common/Heading';
import { container, whiteBorderBox } from '../../common/styles';
import { ThemeType } from '../../../theme';

type ButtonProps = Omit<ComponentProps<typeof Button>, 'primary'>;
type HeadingProps = Omit<
  ComponentProps<typeof Heading>,
  'font' | 'level' | 'size' | 'weight'
>;

const ContinueAs: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => [
      css`
        margin: ${theme.spacing.xsmall} 0 0 0;
      `,
    ]}
    {...props}
  />
);

const Box: React.FC<{ children?: React.ReactNode }> = (props) => (
  <div
    css={(theme: ThemeType) => [
      whiteBorderBox,
      css`
        align-items: center;
        background-color: #ffffff;
        border-radius: 3px;
        border: 1px solid #dddddd;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        font-family: ${theme.typography.families.primary};
        height: 257px;
        justify-content: center;
        width: 90%;
        max-width: 555px;
        padding: ${theme.spacing.medium};
        position: relative;
        text-align: center;

        * ~ & {
          margin-top: 0;
        }
      `,
    ]}
    {...props}
  />
);

const Branding: React.FC<HeadingProps> = (props) => (
  <Heading
    css={(theme: ThemeType) => css`
      margin: 0 0 36px;
      color: ${theme.colors.dark2};
    `}
    font="secondary"
    level={2}
    size="xxlarge"
    {...props}
  />
);

const Page: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <section
    css={css`
      ${container};
      display: flex;
      justify-content: center;
      align-items: center;
    `}
    {...props}
  />
);

const Submit: React.FC<ButtonProps> = (props) => (
  <Button
    css={css`
      height: 47px;
      padding: 0;
      width: 240px;
    `}
    primary
    {...props}
  />
);

const Welcome: React.FC<HeadingProps> = (props) => (
  <Heading
    css={(theme: ThemeType) => css`
      color: ${theme.colors.dark2};
    `}
    level={3}
    size="large"
    weight="normal"
    {...props}
  />
);

const LogoContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      background: ${theme.colors.white};
      position: absolute;
      top: -42px;
      width: 71px;
      height: 71px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid ${theme.colors.border};
    `}
    {...props}
  />
);

export const Login = {
  Box,
  Branding,
  ContinueAs,
  Page,
  Submit,
  Welcome,
  LogoContainer,
};
