/** @jsxImportSource @emotion/react */

import React, { useReducer, useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import { css } from '@emotion/react';
import { EditorState } from 'remirror';
import {
  useMutation,
  useQuery,
  useApolloClient,
  ApolloError,
} from '@apollo/client';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { loader } from 'graphql.macro';
import {
  humanReadableDurations,
  DurationsType,
  DatetimeRangeInput as DatetimeRange,
  MessageTypeCategory,
} from '@mta-live-media-manager/shared';
import { getReplacementMessageType } from 'utils/message-type-display';
import removeTypenames from 'utils/remove-typenames';
import {
  getScreenTargetingFieldKey,
  getValidPlannedWorksScreenMessages,
  scrollToErrors,
} from 'utils/compose-helpers';
import {
  validate as validateDurationsSource,
  sanitize as sanitizeDurationsSource,
  Context,
  exceptionsCreatePartialDurations,
} from 'utils/durations-source';
import { PLANNED_WORK_LOCAL_SAVE_KEY } from 'constants/planned-work';
import {
  PLANNED_LINES_REQUIRED,
  PLANNED_STATUSES_REQUIRED,
  PLANNED_HEADER_REQUIRED,
  PLANNED_HEADLINE_LENGTH_LIMIT,
  PLANNED_SCREENS_REQUIRED,
  PLANNED_SCREEN_CONTENT_REQUIRED,
  PLANNED_LINES_STATUSES_REQUIRED,
  PLANNED_STATION_ALT_LINE_AND_STATION_REQUIRED,
  GENERIC_SUBMIT_NETWORK_ERROR,
  GENERIC_SUBMIT_ERROR,
  STOP_SELECTOR_MISSING_STOPS,
  TARGETING_TYPE_REQUIRED,
  PLANNED_WORK_SCREEN_HEADLINE_REQUIRED,
  TARGETING_SCREEN_WEEK_PARAMS_REQUIRED,
} from 'constants/error-messages';
import { ThemeType } from 'theme';
import Toggle from 'components/common/form-elements/Toggle';
import ComplexDurationsSelector from 'components/common/complex-durations-selector';
import { getContextualSelectors } from 'components/common/form-elements/multi-route-stop-selector';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as RoutesByFeed_routes_nodes } from 'generated/RoutesByFeed';
import { routeToRouteMention } from 'utils/route-mentions';
import { useRoutesByFeedId } from 'contexts/Routes';
import { useCurrentUser } from 'contexts/CurrentUser';
import { getScreenSelectorsFromContextualSelectors } from 'components/common/form-elements/multi-route-stop-screen-selector/hooks';
import {
  IScreenSelector,
  pwDefaultTargetTypes,
} from 'components/common/form-elements/multi-route-stop-screen-selector';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import {
  GetDurationsFromDurationsSource,
  GetDurationsFromDurationsSourceVariables,
  GetDurationsFromDurationsSource_calculateDurationsFromDurationsSource_DatetimeRange as DurationType,
} from 'generated/GetDurationsFromDurationsSource';
import ErrorModal from 'components/common/error-modal';
import { INDEFINITE_END_DATE_MESSAGE } from 'constants/empty-states';
import { useDebouncedCallback } from 'use-debounce';
import MessageHeadline, {
  HEADLINE_MAX_CHAR_LENGTH,
} from 'components/common/message-headline';
import HumanReadableDurationsOverride from './human-readable-durations-override';
import Editor, { getMentionsByType } from '../../common/form-elements/editor';
import useQueryParams from '../../../hooks/useQueryParams';
import useLocalForage from '../../../hooks/useLocalForage';
import {
  CadenceInput,
  DateRangeInput,
  DatetimeRangeInput,
  DurationsSourceInput,
  DurationsSourceInputMethod,
  MessageType,
  ContextualSelectorInput,
  TargetType,
  PlannedWorkStatus,
  SchedulePriority,
  FeatureFlagName,
} from '../../../generated/global-types';
import {
  CreatePlannedWork,
  CreatePlannedWorkVariables,
} from '../../../generated/CreatePlannedWork';
import {
  UpdatePlannedWork,
  UpdatePlannedWorkVariables,
} from '../../../generated/UpdatePlannedWork';
import {
  PlannedWork,
  PlannedWorkVariables,
  PlannedWork_plannedWork_PlannedWork_screenMessages_PlannedWorkScreenMessagesConnection_nodes_PlannedWorkScreenMessage as PlannedWork_plannedWork_screenMessages_nodes,
} from '../../../generated/PlannedWork';
import Loader from '../../common/skeletons/PageWithContent';
import BackOnlyPageHeading from '../../scaffolding/back-only-page.heading';
import PageMeta from '../../common/PageMeta';
import Button from '../../common/Button';
import { useFeedId, FeedId } from '../../../contexts/FeedId';
import CommonHeading from '../../common/Heading';

import HomepagePublishedAt from './homepage-published-at';
import * as styles from './styles';
import {
  Status,
  FormState,
  ILocalStorageCopy,
  ScreenTargeting,
  IPlannedWorkError,
  IScreenTargetingError,
  IPlannedWorkErrorKey,
  IScreenTargetingErrorKey,
} from './types';
import { TagsSelection } from '../../../types';
import Statuses from './statuses';
import StationAlternatives from './StationAlternatvies/station-alternatives';

import StatusBanner from '../../common/status-banner';
import InternalNotes from './internal-notes';
import PlannedWorkScreenCompose, {
  wrapProseMirrorContent,
} from './screen-targeting-form';
import { PlannedWorkErrorKeys } from './constants';
import ConfirmModal, { Confirmation } from './confirm-modal';

const CreatePlannedWorkMutation = loader(
  '../../../graphql/CreatePlannedWork.gql',
);

const UpdatePlannedWorkMutation = loader(
  '../../../graphql/UpdatePlannedWork.gql',
);

const PlannedWorkQuery = loader('../../../graphql/PlannedWork.gql');

const GetDurationsFromDurationsSourceQuery = loader(
  '../../../graphql/GetDurationsFromDurationsSource.gql',
);

const DEBOUNCE_ON_CHANGE_MS = 300;

const defaultTargeting: ScreenTargeting = {
  screens: [],
  uploadId: null,
  upload: null,
  weightFrequency: {
    priority: SchedulePriority.NORMAL,
    weight: 4,
  },
  beforePeriodWeightFrequency: {
    priority: SchedulePriority.NORMAL,
    weight: 4,
  },
  screenWeekParams: {
    daysOfWeek: undefined,
    timeOfDay: undefined,
    day: [],
  },
  messageTitle: undefined,
  wrappedMessageHeadline: undefined,
  messageHeadline: undefined,
  wrappedAdditionalInformation: undefined,
  messageAdditionalInformation: undefined,
  isAda: false,
  isAsset: false,
  displayDuringActivePeriod: true,
  displayBeforeActivePeriod: true,
  screenPublishOffset: 3,
  durationForTheWeekMessage: '',
  isDurationMessageLocked: true,
  hrdOverrideMessage: null,
  isHumanReadableLocked: true,
  showForm: false,
  updated: false,
};

const defaultValues: FormState = {
  stationAlternatives: [],
  durationsSource: {
    inputMethod: DurationsSourceInputMethod.DIRECT,
    directDurations: [{}] as DatetimeRangeInput[],
    cadenceRange: {} as DateRangeInput,
    cadences: [{}] as CadenceInput[],
    exceptions: [] as DateRangeInput[],
  } as DurationsSourceInput,
  impacts: [{} as Status],
  header: undefined,
  body: undefined,
  servicePlanNumbers: [],
  generalOrderNumbers: [],
  internalNotes: '',
  useScreens: false,
  homepagePublishOffset: 1,
  hrdOverrideMessage: '',
  generatedHRDText: '',
  targeting: [defaultTargeting],
};

const getScreenSelectors = (
  feedId: FeedId,
  routeIds: string[],
  routes: RoutesByFeed_routes_nodes[],
): IScreenSelector[] => {
  return routeIds.map((rid) => {
    return {
      selector: {
        routes: [
          routeToRouteMention({
            route: routes.find(
              (r) => r.gtfsId === rid,
            ) as RoutesByFeed_routes_nodes,
            hasAllStopsUnselected: true,
          }),
        ],
        stops: [],
      },
      targetTypes: pwDefaultTargetTypes(feedId),
      tags: {},
    } as IScreenSelector;
  });
};

const screenTargeting = (screenSelectors: IScreenSelector[]) => {
  return screenSelectors.map((screenSelector) => {
    return {
      targetTypes: screenSelector.targetTypes,
      selectors: getContextualSelectors([screenSelector.selector]),
      tags: screenSelector.tags,
    };
  });
};

const PlannedWorkCompose: React.FC = () => {
  const feedId = useFeedId();
  const routes = useRoutesByFeedId(feedId);
  const { id: paramId } = useParams<{ id?: string }>();
  const editId: number | null = paramId ? parseInt(paramId, 10) : null;
  const history = useHistory();
  const queryParams = useQueryParams();
  const showDebug = queryParams.get('debug') !== null;
  const duplicate = queryParams.get('duplicate');
  const duplicateId: number | null = duplicate ? parseInt(duplicate, 10) : null;
  const isNew = editId === null && duplicateId === null;
  const id = duplicate ? duplicateId : editId;
  const shouldRunQuery = id && id > 0;
  const currentUser = useCurrentUser();
  const showHRDOverride = useFeatureFlag(
    FeatureFlagName.HUMAN_READABLE_DURATIONS_OVERRIDE,
  );
  const shouldNotShowPublishButton = useFeatureFlag(
    FeatureFlagName.PLANNED_WORK_DISABLE_PUBLISH_BUTTON,
  );
  const screensTargetable = useFeatureFlag(
    FeatureFlagName.PLANNED_WORK_SCREEN_TARGETS,
  );
  const weightingEnabled = useFeatureFlag(FeatureFlagName.WEIGHTING_FREQUENCY);
  const sidewalkScreenEnabled = useFeatureFlag(
    FeatureFlagName.SIDEWALK_SCREEN_TARGETING,
  );
  const [pwLocalCopy, setPwLocalCopy, { initialLoad }] = useLocalForage<string>(
    PLANNED_WORK_LOCAL_SAVE_KEY,
  );

  const apolloClient = useApolloClient();

  const pwCopyRef = useRef<ILocalStorageCopy>(
    JSON.parse(pwLocalCopy ?? '{}') as ILocalStorageCopy,
  );
  const initialPathnameRef = useRef<string>(history.location.pathname);

  // Remirror's initial content in this page is being set by passing it the 'initialContent'
  // property and that initial content can only be set when the component mounts, re-rendering the
  // component to set the initial content is causing the library to trigger an error, so we show
  // the loading component initially if there is a local save in order to not render the editor
  const [loadingLocalCopy, setLoadingLocalCopy] = useState(true);

  const [restoreLocalPWModalIsOpen, setRestoreLocalPWModalIsOpen] =
    useState(false);
  const [numRemoved, setNumRemoved] = useState(0);

  const clearLocalPWSave = React.useCallback(() => {
    setPwLocalCopy('{}');
  }, [setPwLocalCopy]);

  useEffect(() => {
    const fetchLocal = () => {
      if (initialLoad) {
        return;
      }

      if (!Object.keys(pwCopyRef.current).length && pwLocalCopy) {
        pwCopyRef.current = JSON.parse(
          pwLocalCopy ?? '{}',
        ) as ILocalStorageCopy;
      }

      const pwCopy = pwCopyRef.current;
      if (
        loadingLocalCopy &&
        currentUser &&
        pwCopy.lastEditedBy === currentUser.id &&
        pwCopy.editId === editId &&
        pwCopy.feedId === feedId
      ) {
        setRestoreLocalPWModalIsOpen(true);
      } else {
        setRestoreLocalPWModalIsOpen(false);
        setLoadingLocalCopy(false);
      }
    };

    fetchLocal();
  }, [currentUser, editId, loadingLocalCopy, feedId, pwLocalCopy, initialLoad]);

  useEffect(() => {
    const initialPathname = initialPathnameRef.current;
    return () => {
      // The local PW copy gets cleared in case the user explicitly navigates away from the page
      // either by creating/updating the PW, exiting the page or by clicking the logout button.
      // This condition is to keep the local copy in case the user is logged out from a session expiration
      if (history.location.pathname !== initialPathname) {
        clearLocalPWSave();
      }
    };
  }, [history.location.pathname, clearLocalPWSave]);

  const { loading, data: plannedWorkData } = useQuery<
    PlannedWork,
    PlannedWorkVariables
  >(PlannedWorkQuery, {
    skip: !shouldRunQuery,
    fetchPolicy: 'network-only',
    // `id` can be null, which is not a valid value for this variable, but if
    // `id` is null then `skip` (above) will be `true`, meaning the query will
    // not be sent and the variables are irrelevant. To satisfy TypeScript, a
    // null id here will be replaced with `-1` to ensure this variable is always
    // a number.
    variables: { id: id ?? -1 },
  });

  const pwFeedId = plannedWorkData?.plannedWork?.feedId;

  useEffect(() => {
    if (pwFeedId && pwFeedId !== feedId) {
      const newPathname = history.location.pathname.replace(feedId, pwFeedId);
      initialPathnameRef.current = newPathname;
      history.replace(newPathname);
    }
  }, [pwFeedId, feedId, history]);

  const [
    {
      stationAlternatives,
      durationsSource,
      impacts,
      header,
      body,
      servicePlanNumbers,
      generalOrderNumbers,
      internalNotes,
      useScreens,
      homepagePublishOffset,
      hrdOverrideMessage,
      generatedHRDText,
      targeting,
    },
    reducerUpdate,
  ] = useReducer(
    (
      current: FormState,
      { key, value }: { key: keyof FormState; value: any },
    ): FormState => ({ ...current, [key]: value }),
    defaultValues,
  );

  const [saveLocally] = useDebouncedCallback(() => {
    const wrappedHeaderProseMirrorContent = header?.doc
      ? wrapProseMirrorContent(header)
      : null;
    const wrappedBodyProseMirrorContent = body?.doc
      ? wrapProseMirrorContent(body)
      : null;

    const pwCopy: ILocalStorageCopy = {
      editId,
      stationAlternatives,
      durationsSource,
      impacts,
      servicePlanNumbers,
      generalOrderNumbers,
      internalNotes,
      useScreens,
      homepagePublishOffset,
      hrdOverrideMessage,
      lastEditedBy: currentUser?.id,
      wrappedHeaderProseMirrorContent,
      wrappedBodyProseMirrorContent,
      upload: undefined,
      feedId,
      targeting,
    };
    setPwLocalCopy(JSON.stringify(pwCopy));
  }, 3000);

  const update = React.useCallback(
    (
      { key, value }: { key: keyof FormState; value: any },
      shouldSaveLocally = false,
    ) => {
      reducerUpdate({ key, value });
      if (shouldSaveLocally) {
        saveLocally();
      }
    },
    [saveLocally],
  );

  useEffect(() => {
    if (!screensTargetable && useScreens) {
      update({
        key: 'useScreens',
        value: false,
      });
    }
  }, [screensTargetable, useScreens, update]);

  const [showInternalNotes, setShowInternalNotes] = useState(true);
  const [initializedValues, setInitializedValues] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [localHeader, setLocalHeader] = useState<EditorState | undefined>();
  const [localBody, setLocalBody] = useState<EditorState | undefined>();
  const [localHeaderHTML, setLocalHeaderHTML] = useState<string>('');
  const [localBodyHTML, setLocalBodyHTML] = useState<string>('');
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    isNetworkError: false,
  });

  const [hrdOverrideIsOpened, setHRDOverrideIsOpened] = useState(false);
  const [hrdIsOverridden, setHRDIsOverridden] = useState(false);
  const [hrdOverrideIsLoading, setHRDOverrideIsLoading] = useState(false);
  const [showAddScreen, setShowAddScreen] = useState(
    false || !sidewalkScreenEnabled,
  );

  const [erroneousDurationIndices, setErroneousDurationIndices] = useState<
    number[]
  >([]);
  const [erroneousCadenceIndices, setErroneousCadenceIndices] = useState<
    number[]
  >([]);
  const [erroneousExceptionIndices, setErroneousExceptionIndices] = useState<
    number[]
  >([]);

  type MessageError = {
    plannedWork: IPlannedWorkError;
    screens: IScreenTargetingError[];
  };

  const [errors, setErrors] = useState<MessageError>({
    plannedWork: {},
    screens: [],
  });

  const [durations, setDurations] = useState<
    { end: Date; start: Date }[] | null
  >();

  const removeError = React.useCallback(
    (keyToRemove: IPlannedWorkErrorKey): void => {
      if (!errors.plannedWork[keyToRemove]) {
        return;
      }

      setErrors({
        plannedWork: Object.fromEntries(
          Object.entries(errors.plannedWork).filter(
            ([key]) => key !== keyToRemove,
          ),
        ),
        screens: errors.screens,
      });
    },
    [errors, setErrors],
  );

  // Accepts an attribute from FormState and returns a curried function with one
  // argument for setting the value of the specified form attribute.
  const updateValue =
    (key: keyof FormState) =>
    (value: any): void => {
      removeError(key as IPlannedWorkErrorKey);
      update({ key, value }, true);
    };

  const getScreensSelectors = (selectors: any) => {
    return selectors.map(
      (m: {
        selectors: ContextualSelectorInput[];
        targetTypes: TargetType[];
        tags: TagsSelection;
      }) => {
        const newScreenSelectors = getScreenSelectorsFromContextualSelectors(
          [
            {
              contextualSelectors: m.selectors as ContextualSelectorInput[],
              targetTypes: m.targetTypes as TargetType[],
            },
          ],
          routes,
        );

        return {
          selector: newScreenSelectors[0]?.selector,
          targetTypes: m.targetTypes,
          tags: m.tags,
        };
      },
    );
  };

  const refreshHRDText = async () => {
    if (validateDurationsSource(durationsSource, Context.plannedWork)) {
      return;
    }

    const { data } = await apolloClient.query<
      GetDurationsFromDurationsSource,
      GetDurationsFromDurationsSourceVariables
    >({
      query: GetDurationsFromDurationsSourceQuery,
      variables: {
        durationsSource: sanitizeDurationsSource({
          ...durationsSource,
          exceptions: undefined,
        }),
      },
    });

    const hrdText = humanReadableDurations(
      data.calculateDurationsFromDurationsSource?.map((d) => ({
        start: {
          value: d?.start ? new Date(d?.start?.value) : new Date(d?.end?.value),
        },
        ...(d?.end
          ? {
              end: {
                value: new Date(d.end?.value),
              },
            }
          : {}),
      })) ?? [],
      {
        isOngoing:
          durationsSource?.inputMethod === DurationsSourceInputMethod.CADENCE
            ? !durationsSource.cadenceRange?.end
            : !!durationsSource.directDurations?.length &&
              !durationsSource.directDurations[
                durationsSource.directDurations.length - 1
              ].end,
        ongoingText:
          durationsSource?.untilFurtherNoticeMessage ??
          INDEFINITE_END_DATE_MESSAGE,
        type:
          durationsSource?.inputMethod === DurationsSourceInputMethod.CADENCE
            ? DurationsType.CADENCE
            : DurationsType.DIRECT,
        cadenceEnd: durationsSource?.cadenceRange?.end,
      },
      durationsSource?.exceptions as DatetimeRange[],
    );

    setDurations(
      data.calculateDurationsFromDurationsSource?.map((duration) => ({
        end: new Date(duration?.end?.value),
        start: new Date(duration?.start?.value),
      })),
    );

    updateValue('generatedHRDText')(hrdText);
  };

  const [debouncedRefreshHRDText] = useDebouncedCallback(() => {
    refreshHRDText();
  }, DEBOUNCE_ON_CHANGE_MS);

  const record = plannedWorkData?.plannedWork;

  if (
    shouldRunQuery &&
    !loading &&
    routes &&
    routes.length > 0 &&
    !initializedValues &&
    record
  ) {
    setInitializedValues(true);
    setShowAddScreen(true);
    update({
      key: 'impacts',
      value: record.impacts.nodes.map((i) => ({
        routeIds:
          i.entitySelectors?.map((s) => s?.routeId).filter(Boolean) ?? [],
        status: duplicate
          ? getReplacementMessageType(
              i.messageType,
              MessageTypeCategory.PLANNED_WORK,
              feedId,
            )
          : (i.messageType as MessageType),
        isAgencyWide: i.isAgencyWide,
      })),
    });
    update({
      key: 'durationsSource',
      value: removeTypenames(record.durationsSource),
    });
    debouncedRefreshHRDText();
    setHRDIsOverridden(
      !!record.durationsSource?.humanReadableDurationsOverrideMessage,
    );
    setHRDOverrideIsOpened(
      !!record.durationsSource?.humanReadableDurationsOverrideMessage,
    );
    update({
      key: 'hrdOverrideMessage',
      value: record.durationsSource?.humanReadableDurationsOverrideMessage,
    });
    update({ key: 'header', value: record.header.doc });
    setLocalHeaderHTML(record.header.html);
    update({ key: 'body', value: record.body.doc });
    setLocalBodyHTML(record.body.html);
    update({
      key: 'stationAlternatives',
      value: record.stationAlternatives.map((sa) => ({
        contextualSelector: {
          entitySelector: {
            stopId: sa?.contextualSelector?.entitySelector.stopId,
          },
          context: {
            gtfsRouteId: sa.contextualSelector?.context.gtfsRouteId,
          },
        },
        notes: sa.notes,
      })),
    });
    // The number of hours is being returned as null when the homepage publish offset is 0 in the db
    const homepagePublishOffsetValue = (() => {
      if (duplicateId) {
        return homepagePublishOffset;
      }
      if (record.homepagePublishOffset) {
        return record.homepagePublishOffset.hours ?? 0;
      }
      return null;
    })();
    update({
      key: 'homepagePublishOffset',
      value: homepagePublishOffsetValue,
    });
    update({
      key: 'useScreens',
      value: record.screenMessages.nodes.length > 0,
    });

    update({
      key: 'servicePlanNumbers',
      value: (record?.servicePlanNumbers?.filter(Boolean) as string[]) ?? [],
    });
    update({
      key: 'generalOrderNumbers',
      value: (record?.generalOrderNumbers?.filter(Boolean) as string[]) ?? [],
    });
    update({ key: 'internalNotes', value: record.internalNotes ?? '' });

    const validScreenMessages = getValidPlannedWorksScreenMessages(
      record.screenMessages?.nodes,
      routes,
    );

    update({
      key: 'targeting',
      value: validScreenMessages?.map(
        (s: PlannedWork_plannedWork_screenMessages_nodes) => {
          return {
            screens: getScreensSelectors(s.screenTargeting),
            uploadId: s.uploadId,
            weightFrequency: {
              priority: s.priority,
              weight: s.weight,
            },
            beforePeriodWeightFrequency: {
              priority: s.beforeActivePriority,
              weight: s.beforeActiveWeight,
            },
            screenWeekParams: {
              daysOfWeek: s.durationsSettings?.daysOfWeek,
              timeOfDay: s.durationsSettings?.timeOfDay,
              day: s.durationsSettings?.days,
            },
            messageTitle: s.title,
            messageHeadline: s.body,
            wrappedMessageHeadline: s.body,
            messageAdditionalInformation: s.additionalInfo,
            wrappedAdditionalInformation: s.additionalInfo,
            isAda: s.isAda,
            isAsset: !s.body,
            screenPublishOffset: s.screenPublishOffset?.days,
            displayBeforeActivePeriod: !!s.screenPublishOffset?.days,
            displayDuringActivePeriod: s.isActiveDuringPeriod,
            durationForTheWeekMessage:
              s.durationsSettings?.durationForTheWeekMessage,
            upload: s.upload,
            isDurationMessageLocked: true,
            hrdOverrideMessage:
              s.durationsSettings?.humanReadableDurationsOverrideMessage,
            isHumanReadableLocked: true,
            showForm: true,
            updated: true,
          };
        },
      ),
    });
  }

  const removeErrorTargeting = React.useCallback(
    (keyToRemove: IScreenTargetingErrorKey, index: number): void => {
      if (!errors.screens[index]?.[keyToRemove]) {
        return;
      }

      setErrors({
        plannedWork: errors.plannedWork,
        screens: errors.screens.map((m) => {
          return Object.fromEntries(
            Object.entries(m).filter(([key]) => key !== keyToRemove),
          );
        }),
      });
    },
    [errors, setErrors],
  );

  const addEmptyTargeting = () => {
    const routeIds = impacts
      .map((impact) => impact.routeIds || '')
      .flat()
      .filter((value, index, self) => value && self.indexOf(value) === index);
    targeting?.push({
      ...defaultTargeting,
      wrappedMessageHeadline: header
        ? wrapProseMirrorContent(header)
        : undefined,
      wrappedAdditionalInformation: body
        ? wrapProseMirrorContent(body)
        : undefined,
      screens: impacts ? getScreenSelectors(feedId, routeIds, routes) : [],
    });
    update({
      key: 'targeting',
      value: targeting,
    });
  };

  const removeTargeting = (index: number) => {
    targeting?.splice(index, 1);
    if (!targeting.length) {
      if (sidewalkScreenEnabled) {
        setShowAddScreen(false);
      }
      addEmptyTargeting();
    }
    setNumRemoved((prevNumRemoved) => prevNumRemoved + 1);
    updateValue('targeting')(targeting);
  };

  const updateTargeting = (
    index: number,
    key: keyof ScreenTargeting,
    value: any,
  ) => {
    const currentTargeting = targeting;
    const updatedTarget = {
      ...targeting[index],
      [key]: value,
    };
    if (key === 'isAsset') {
      removeErrorTargeting('uploadId', index);
    } else {
      removeErrorTargeting(key as IScreenTargetingErrorKey, index);
    }
    currentTargeting[index] = updatedTarget;
    updateValue('targeting')(currentTargeting);
  };

  const duplicateTargeting = () => {
    updateValue('targeting')([
      ...targeting,
      { ...targeting[targeting.length - 1] },
    ]);
  };

  const updateTargetingScreenLayout = (index: number, isDup = false) => {
    if (isDup) {
      const screenSelector = [
        {
          selector: {
            routes: [],
            stops: [],
          },
          targetTypes: [TargetType.DUP],
        },
      ];
      updateTargeting(index, 'screens', screenSelector);
    }
    updateTargeting(index, 'showForm', true);
    setShowAddScreen(true);
  };

  const generateValues = (key: keyof ScreenTargeting, value: any) => {
    const targetingUpdate = targeting.map((target) => {
      if ((Object.keys(value).length || value.length) && !target.updated) {
        const updatedTarget = {
          ...target,
          [key]: value,
        };
        return updatedTarget;
      }
      return target;
    });
    updateValue('targeting')(targetingUpdate);
  };

  // Mutations rethrow errors so they can be caught by sentry
  const [createPlannedWork] = useMutation<
    CreatePlannedWork,
    CreatePlannedWorkVariables
  >(CreatePlannedWorkMutation, {
    onError: (error: ApolloError) => {
      setErrorModalOptions({
        isOpen: true,
        isNetworkError: !!error?.networkError?.name,
      });
      throw error;
    },
  });

  const [updatePlannedWork] = useMutation<
    UpdatePlannedWork,
    UpdatePlannedWorkVariables
  >(UpdatePlannedWorkMutation, {
    onError: (error: ApolloError) => {
      setErrorModalOptions({
        isOpen: true,
        isNetworkError: !!error?.networkError?.name,
      });
      throw error;
    },
  });

  const validateStationAlternatives = (): boolean => {
    return stationAlternatives.every(
      (elt) =>
        elt?.contextualSelector?.context.gtfsRouteId &&
        elt?.contextualSelector?.entitySelector?.stopId,
    );
  };

  const headerNumberOfChars = localHeader?.doc?.textContent?.length ?? 0;

  const [confirmationModalOptions, setConfirmationModalOptions] =
    React.useState<{
      draft: boolean;
      isOpen: boolean;
      confirmation?: Confirmation;
    }>({
      draft: false,
      isOpen: false,
    });

  const handleSubmit =
    ({
      draft,
      confirmPartialDurations,
      confirmSaveLivePwAsDraft,
    }: {
      draft?: boolean;
      confirmPartialDurations?: boolean;
      confirmSaveLivePwAsDraft?: boolean;
    } = {}) =>
    async () => {
      const newErrors: IPlannedWorkError = {};
      const targetingErrors: IScreenTargetingError[] = [];

      setIsSubmitting(true);

      const routeIdsLength = impacts.filter((i) => i.routeIds?.length).length;
      const statusesLength = impacts.filter((i) => i.status).length;
      const hasBlankRouteOrStatus =
        routeIdsLength !== impacts.length || statusesLength !== impacts.length;

      if (!routeIdsLength) {
        newErrors.impacts = PLANNED_LINES_REQUIRED;
      } else if (!statusesLength) {
        newErrors.impacts = PLANNED_STATUSES_REQUIRED;
      } else if (hasBlankRouteOrStatus) {
        newErrors.impacts = PLANNED_LINES_STATUSES_REQUIRED;
      }

      const durationsSourceError = validateDurationsSource(
        durationsSource,
        Context.plannedWork,
      );

      if (durationsSourceError) {
        newErrors.durationsSource = durationsSourceError?.message;
        const { indices, isExceptionsError } = durationsSourceError;

        setErroneousDurationIndices(
          indices?.[DurationsSourceInputMethod.DIRECT] || [],
        );

        setErroneousCadenceIndices(
          !isExceptionsError
            ? indices?.[DurationsSourceInputMethod.CADENCE] || []
            : [],
        );

        setErroneousExceptionIndices(
          isExceptionsError
            ? indices?.[DurationsSourceInputMethod.CADENCE] || []
            : [],
        );
      }

      if (!header?.doc?.textContent?.length) {
        newErrors.header = PLANNED_HEADER_REQUIRED;
      }

      if (headerNumberOfChars > HEADLINE_MAX_CHAR_LENGTH) {
        newErrors.header = PLANNED_HEADLINE_LENGTH_LIMIT;
      }

      if (useScreens) {
        targeting.forEach((t, index) => {
          targetingErrors[index] = {};

          if (!t.showForm && sidewalkScreenEnabled) {
            targetingErrors[index].targetingTypeSelection =
              TARGETING_TYPE_REQUIRED;
          }

          if (
            (!t.screenWeekParams.daysOfWeek || !t.screenWeekParams.timeOfDay) &&
            !t.isAsset &&
            !t.screens.some((s) => s.targetTypes.includes(TargetType.DUP))
          ) {
            targetingErrors[index].screenWeekParams =
              TARGETING_SCREEN_WEEK_PARAMS_REQUIRED;
          }

          if (!t.messageHeadline?.doc?.textContent?.length && !t.isAsset) {
            targetingErrors[index].messageHeadline =
              PLANNED_WORK_SCREEN_HEADLINE_REQUIRED;
          }

          if (
            !t.screens.length ||
            t.screens.some(
              (s) => s.targetTypes.length <= 0 || s.selector.routes.length <= 0,
            )
          ) {
            targetingErrors[index].screens = PLANNED_SCREENS_REQUIRED;
          } else if (
            t.screens.some((s) =>
              s.selector.routes?.some((r) => r.hasAllStopsUnselected),
            )
          ) {
            targetingErrors[index].screens = STOP_SELECTOR_MISSING_STOPS;
          }

          if (
            (!t.uploadId || t.upload?.mimeType === 'application/zip') &&
            t.isAsset
          ) {
            targetingErrors[index].uploadId = PLANNED_SCREEN_CONTENT_REQUIRED;
          }
        });
      }

      if (stationAlternatives.length > 0 && !validateStationAlternatives()) {
        newErrors.stationAlternatives =
          PLANNED_STATION_ALT_LINE_AND_STATION_REQUIRED;
      }

      const allErrors = (targetingErrors ?? []).reduce((acc, cur, idx) => {
        const screenAtIdxErrors: { [key: string]: string } = {};

        Object.keys(cur).forEach((key) => {
          const errorValue = cur[key as IScreenTargetingErrorKey];

          if (errorValue) {
            screenAtIdxErrors[getScreenTargetingFieldKey(key, idx)] =
              errorValue;
          }
        });

        return { ...acc, ...screenAtIdxErrors };
      }, newErrors ?? {}) as { [key: string]: string };

      if (Object.keys(allErrors).length) {
        setErrors({
          plannedWork: newErrors,
          screens: targetingErrors,
        });
        setIsSubmitting(false);
        scrollToErrors(allErrors);
        return;
      }

      setErrors({
        plannedWork: {},
        screens: [],
      });

      const { data } = await apolloClient.query<
        GetDurationsFromDurationsSource,
        GetDurationsFromDurationsSourceVariables
      >({
        query: GetDurationsFromDurationsSourceQuery,
        variables: {
          durationsSource: sanitizeDurationsSource({
            ...durationsSource,
            exceptions: undefined,
          }),
        },
      });

      const exceptionsCreatePartialDuration = exceptionsCreatePartialDurations(
        durationsSource,
        (data.calculateDurationsFromDurationsSource as DurationType[]) ?? [],
      );

      let result: any = null;

      if (exceptionsCreatePartialDuration && !confirmPartialDurations) {
        setIsSubmitting(false);
        setConfirmationModalOptions({
          draft: draft ?? false,
          confirmation: Confirmation.PARTIAL_DURATIONS,
          isOpen: true,
        });
        return;
      }

      const isPwActive =
        !duplicateId &&
        record?.status &&
        [PlannedWorkStatus.LIVE, PlannedWorkStatus.PUBLISHED].includes(
          record?.status,
        );

      if (draft && isPwActive && !confirmSaveLivePwAsDraft) {
        setIsSubmitting(false);
        setConfirmationModalOptions({
          draft: draft ?? false,
          confirmation: Confirmation.SAVE_LIVE_PW_AS_DRAFT,
          isOpen: true,
        });
        return;
      }

      try {
        if (!isNew && id && !duplicateId) {
          const patch = {
            impacts: impacts.map(({ status, routeIds, isAgencyWide }) => ({
              messageType: status,
              entitySelectors: routeIds?.map((routeId) => ({ routeId })) ?? [],
              isAgencyWide: isAgencyWide ?? false,
            })),
            header: header ? wrapProseMirrorContent(header) : null,
            body: body ? wrapProseMirrorContent(body) : null,
            durationsSource: sanitizeDurationsSource(
              durationsSource,
              hrdIsOverridden ? hrdOverrideMessage : null,
            ),
            stationAlternatives,
            homepagePublishOffset:
              homepagePublishOffset !== null
                ? { hours: homepagePublishOffset }
                : null,
            servicePlanNumbers,
            generalOrderNumbers,
            internalNotes,
            entitySelectors: getMentionsByType(header)?.stops.map((stop) => ({
              stopId: stop.stopId,
            })),
            screenMessages: useScreens
              ? targeting.map((t) => {
                  const isDup = t.screens.some((s) =>
                    s.targetTypes.includes(TargetType.DUP),
                  );
                  const humanReadableDurationsOverrideMessage = t
                    .hrdOverrideMessage?.length
                    ? t.hrdOverrideMessage
                    : undefined;

                  return {
                    uploadId: t.isAsset ? t.uploadId : null,
                    weight: !isDup ? t.weightFrequency.weight : 1,
                    priority: t.weightFrequency.priority,
                    title: !t.isAsset ? t.messageTitle : null,
                    body:
                      t.messageHeadline && !t.isAsset
                        ? wrapProseMirrorContent(t.messageHeadline)
                        : null,
                    additionalInfo:
                      t.messageAdditionalInformation && !t.isAsset
                        ? wrapProseMirrorContent(t.messageAdditionalInformation)
                        : null,
                    beforeActivePriority:
                      t.beforePeriodWeightFrequency.priority,
                    beforeActiveWeight: !isDup
                      ? t.beforePeriodWeightFrequency.weight
                      : 1,
                    durationsSettings: {
                      daysOfWeek: t.screenWeekParams.daysOfWeek,
                      timeOfDay: t.screenWeekParams.timeOfDay,
                      days: t.screenWeekParams.day,
                      durationForTheWeekMessage: t.durationForTheWeekMessage,
                      humanReadableDurationsOverrideMessage,
                    },
                    screenTargeting: screenTargeting(t.screens),
                    screenPublishOffset:
                      useScreens &&
                      (t.displayBeforeActivePeriod || !!t.screenPublishOffset)
                        ? { days: t.screenPublishOffset }
                        : null,
                    isAda: t.isAda,
                    isActiveDuringPeriod: t.displayDuringActivePeriod,
                  };
                })
              : [],
            draft,
          };

          result = await updatePlannedWork({ variables: { id, patch } });

          if (result?.data?.updatePlannedWork?.plannedWork?.id) {
            history.push(
              `/${feedId}/planned-work/${result.data.updatePlannedWork.plannedWork.id}`,
            );
            setIsSubmitting(false);
            clearLocalPWSave();
            return;
          }
        } else {
          result = await createPlannedWork({
            variables: {
              feedId,
              cloneId:
                record?.status !== PlannedWorkStatus.DRAFT ? duplicateId : null,
              impacts: impacts.map(({ status, routeIds, isAgencyWide }) => ({
                messageType: status,
                entitySelectors:
                  routeIds?.map((routeId) => ({ routeId })) ?? [],
                isAgencyWide: isAgencyWide ?? false,
              })),
              header: header ? wrapProseMirrorContent(header) : null,
              body: body ? wrapProseMirrorContent(body) : null,
              durationsSource: sanitizeDurationsSource(
                durationsSource,
                hrdIsOverridden ? hrdOverrideMessage : null,
              ),
              stationAlternatives,
              homepagePublishOffset:
                homepagePublishOffset !== null
                  ? { hours: homepagePublishOffset }
                  : null,
              servicePlanNumbers,
              generalOrderNumbers,
              internalNotes,
              entitySelectors: getMentionsByType(header)?.stops.map((stop) => ({
                stopId: stop.stopId,
              })),
              screenMessages: useScreens
                ? targeting.map((t) => {
                    const isDup = t.screens.some((s) =>
                      s.targetTypes.includes(TargetType.DUP),
                    );
                    const humanReadableDurationsOverrideMessage = t
                      .hrdOverrideMessage?.length
                      ? t.hrdOverrideMessage
                      : undefined;

                    return {
                      targetTypes: t.screens.flatMap((m) => m.targetTypes),
                      selectors: getContextualSelectors(
                        t.screens.flatMap((m) => m.selector),
                      ),
                      uploadId: t.isAsset ? t.uploadId : null,
                      weight: !isDup ? t.weightFrequency.weight : 1,
                      priority: t.weightFrequency.priority,
                      title: t.messageTitle,
                      body:
                        !t.isAsset && t.messageHeadline
                          ? wrapProseMirrorContent(t.messageHeadline)
                          : null,
                      additionalInfo:
                        !t.isAsset && t.messageAdditionalInformation
                          ? wrapProseMirrorContent(
                              t.messageAdditionalInformation,
                            )
                          : null,
                      beforeActivePriority:
                        t.beforePeriodWeightFrequency.priority,
                      beforeActiveWeight: !isDup
                        ? t.beforePeriodWeightFrequency.weight
                        : 1,
                      durationsSettings: {
                        daysOfWeek: t.screenWeekParams.daysOfWeek,
                        timeOfDay: t.screenWeekParams.timeOfDay,
                        days: t.screenWeekParams.day,
                        durationForTheWeekMessage: t.durationForTheWeekMessage,
                        humanReadableDurationsOverrideMessage,
                      },
                      screenTargeting: screenTargeting(t.screens),
                      screenPublishOffset:
                        useScreens && t.displayBeforeActivePeriod
                          ? { days: t.screenPublishOffset }
                          : null,
                      isAda: t.isAda,
                      isActiveDuringPeriod: t.displayDuringActivePeriod,
                    };
                  })
                : [],
              draft,
            },
          });

          if (result?.data?.createPlannedWork?.plannedWork?.id) {
            history.push(
              `/${feedId}/planned-work/${result.data.createPlannedWork.plannedWork.id}`,
            );
            setIsSubmitting(false);
            clearLocalPWSave();
            return;
          }
        }
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('result', JSON.stringify(result ?? {}));
          Sentry.captureException(e);
        });
      }

      setIsSubmitting(false);
      setErrors({
        plannedWork: { general: 'Your data could not be saved' },
        screens: [],
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

  if ((shouldRunQuery && loading) || loadingLocalCopy) {
    return (
      <React.Fragment>
        <Loader loading />
        <ConfirmModal
          isOpen={restoreLocalPWModalIsOpen}
          confirmation={Confirmation.RESTORE_CHANGES}
          onDismiss={() => {
            setRestoreLocalPWModalIsOpen(false);
            setLoadingLocalCopy(false);
            clearLocalPWSave();
          }}
          onConfirm={() => {
            const pwCopy = pwCopyRef.current;
            if (pwCopy) {
              update({
                key: 'impacts',
                value: pwCopy.impacts,
              });
              update({
                key: 'durationsSource',
                value: pwCopy.durationsSource,
              });
              debouncedRefreshHRDText();
              setHRDIsOverridden(
                !!pwCopy.durationsSource?.humanReadableDurationsOverrideMessage,
              );
              setHRDOverrideIsOpened(
                !!pwCopy.durationsSource?.humanReadableDurationsOverrideMessage,
              );
              update({
                key: 'hrdOverrideMessage',
                value:
                  pwCopy.durationsSource?.humanReadableDurationsOverrideMessage,
              });
              update({
                key: 'header',
                value: pwCopy.wrappedHeaderProseMirrorContent?.doc,
              });
              setLocalHeaderHTML(
                pwCopy.wrappedHeaderProseMirrorContent?.html ?? '',
              );
              update({
                key: 'body',
                value: pwCopy.wrappedBodyProseMirrorContent?.doc,
              });
              setLocalBodyHTML(
                pwCopy.wrappedBodyProseMirrorContent?.html ?? '',
              );
              update({
                key: 'stationAlternatives',
                value: pwCopy.stationAlternatives,
              });
              update({
                key: 'homepagePublishOffset',
                value: pwCopy.homepagePublishOffset,
              });
              update({
                key: 'useScreens',
                value: pwCopy.useScreens,
              });

              update({
                key: 'servicePlanNumbers',
                value: pwCopy.servicePlanNumbers,
              });
              update({
                key: 'generalOrderNumbers',
                value: pwCopy.generalOrderNumbers,
              });
              update({
                key: 'internalNotes',
                value: pwCopy.internalNotes,
              });
            }

            update({
              key: 'targeting',
              value: pwCopy.targeting,
            });
            setShowAddScreen(
              pwCopy.targeting && !!pwCopy.targeting[0]?.screens.length,
            );
            setRestoreLocalPWModalIsOpen(false);
            setLoadingLocalCopy(false);
          }}
        />
      </React.Fragment>
    );
  }

  const pageTitle =
    isNew || duplicateId ? 'Compose Planned Work' : 'Update Planned Work';

  const hrdOverrideActionHasError = (): boolean => {
    const newErrors: IPlannedWorkError = {};

    const durationsSourceError = validateDurationsSource(
      durationsSource,
      Context.plannedWork,
    );

    if (durationsSourceError) {
      newErrors.durationsSource = durationsSourceError.message;

      setErroneousDurationIndices(
        durationsSourceError?.indices?.[DurationsSourceInputMethod.DIRECT] ||
          [],
      );

      setErroneousCadenceIndices(
        durationsSourceError?.indices?.[DurationsSourceInputMethod.CADENCE] ||
          [],
      );

      setErrors({ plannedWork: newErrors, screens: [] });

      return true;
    }

    return false;
  };

  const handleHRDOverrideAction = async () => {
    setHRDOverrideIsOpened(true);
    setHRDOverrideIsLoading(true);
    refreshHRDText();
    setHRDOverrideIsLoading(false);
  };

  const editorRelatedGtfsIds = impacts
    .flatMap((s) => s.routeIds ?? [])
    .map((s) => s as string);

  return (
    <div style={{ width: '100%' }}>
      <ConfirmModal
        isOpen={confirmationModalOptions.isOpen}
        confirmation={confirmationModalOptions.confirmation}
        onConfirm={() => {
          const { draft, confirmation } = confirmationModalOptions;
          handleSubmit({
            draft,
            confirmPartialDurations: true,
            confirmSaveLivePwAsDraft:
              confirmation === Confirmation.SAVE_LIVE_PW_AS_DRAFT,
          })();
          setConfirmationModalOptions({
            ...confirmationModalOptions,
            draft,
            isOpen: false,
          });
        }}
        onDismiss={() => {
          setConfirmationModalOptions({
            draft: false,
            isOpen: false,
          });
        }}
      />
      <Prompt
        when={!isSubmitting}
        // @ts-ignore
        // react-router-dom types still haven't been updated to the latest api
        // see the `message` prop api in https://reactrouter.com/core/api/Prompt
        message={(_, action) => {
          if (action === 'REPLACE') {
            return true;
          }
          return DISCARD_CHANGES_MESSAGE;
        }}
      />
      <PageMeta title={pageTitle} />
      <BackOnlyPageHeading
        back={{ to: `/${feedId}/planned-work`, title: 'Back to Planned Work' }}
      />
      <form css={styles.form}>
        <div css={styles.section}>
          <section css={styles.section2}>
            <CommonHeading level={1}>{pageTitle}</CommonHeading>
            <StatusBanner
              hasIcon
              as="label"
              status="error"
              text={errors.plannedWork?.general || '\u00a0'}
              isVisible={!!errors.plannedWork?.general?.length}
              css={({ spacing }: ThemeType) => css`
                margin-top: ${errors.plannedWork?.general?.length
                  ? spacing.small
                  : '0'};
              `}
            />
          </section>
          <section id={PlannedWorkErrorKeys.impacts} css={styles.section2}>
            <Statuses
              value={impacts}
              onChange={(impacts) => {
                updateValue('impacts')(impacts);

                if (targeting.filter((m) => !m.updated).length) {
                  const routeIds = impacts
                    .map((impact) => impact.routeIds || '')
                    .flat()
                    .filter(
                      (value, index, self) =>
                        value && self.indexOf(value) === index,
                    );
                  generateValues(
                    'screens',
                    getScreenSelectors(feedId, routeIds, routes),
                  );
                }
              }}
            />
            <StatusBanner
              hasIcon
              as="label"
              status="error"
              text={errors.plannedWork?.impacts || '\u00a0'}
              isVisible={!!errors.plannedWork?.impacts?.length}
              css={({ spacing }: ThemeType) => css`
                margin-top: ${errors.plannedWork?.impacts?.length
                  ? spacing.small
                  : '0'};
              `}
            />
          </section>
          <section css={styles.section2}>
            <CommonHeading
              level={2}
              css={css`
                margin-bottom: 16px;
              `}
            >
              Web &amp; Apps
            </CommonHeading>
            <div
              id={PlannedWorkErrorKeys.durationsSource}
              css={styles.inputWrapper}
            >
              <CommonHeading
                level={4}
                css={css`
                  margin-bottom: 12px;
                `}
              >
                Active Work Period
              </CommonHeading>
              <ComplexDurationsSelector
                value={durationsSource}
                onChange={(value) => {
                  updateValue('durationsSource')(value);
                  debouncedRefreshHRDText();
                }}
                erroneousDurationIndices={erroneousDurationIndices}
                erroneousCadenceIndices={erroneousCadenceIndices}
                erroneousExceptionIndices={erroneousExceptionIndices}
                enableExceptions
                enableAdditionalRange
              />
              <StatusBanner
                hasIcon
                as="label"
                status="error"
                text={errors.plannedWork?.durationsSource || '\u00a0'}
                isVisible={!!errors.plannedWork?.durationsSource?.length}
                css={({ spacing }: ThemeType) => css`
                  margin-top: ${spacing.small};
                  margin-bottom: ${spacing.small};
                `}
              />
              {showHRDOverride && (
                <HumanReadableDurationsOverride
                  isOpen={hrdOverrideIsOpened}
                  onToggle={async (isOpening) => {
                    if (hrdIsOverridden) {
                      setHRDOverrideIsOpened(isOpening);
                    } else if (isOpening && !hrdOverrideActionHasError()) {
                      handleHRDOverrideAction();
                    } else {
                      setHRDOverrideIsOpened(false);
                    }
                  }}
                  isLoading={hrdOverrideIsLoading}
                  onUnlock={() => setHRDIsOverridden(true)}
                  isLocked={!hrdIsOverridden}
                  value={(hrdOverrideMessage || generatedHRDText) ?? ''}
                  onChange={(hrdText) =>
                    updateValue('hrdOverrideMessage')(hrdText)
                  }
                  onRevert={() => {
                    if (!hrdOverrideActionHasError()) {
                      handleHRDOverrideAction();
                      setHRDIsOverridden(false);
                      updateValue('hrdOverrideMessage')(null);
                    }
                  }}
                />
              )}
              <CommonHeading
                level={4}
                css={css`
                  margin-bottom: 12px;
                `}
              >
                Homepage Display
              </CommonHeading>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin-top: 8px;
                  margin-bottom: 12px;
                `}
              >
                <HomepagePublishedAt
                  value={homepagePublishOffset}
                  onChange={updateValue('homepagePublishOffset')}
                />
              </div>
            </div>
            <div id={PlannedWorkErrorKeys.header} css={styles.inputWrapper}>
              <MessageHeadline currentNumberOfChars={headerNumberOfChars} />
              <Editor
                id="editor-header"
                shouldFormatOnPaste
                initialContent={localHeaderHTML}
                value={localHeader}
                relatedGtfsIds={editorRelatedGtfsIds}
                onChange={({ state }) => {
                  updateValue('header')(state);
                }}
                onStateChange={({ state }) => {
                  setLocalHeader(state);
                }}
                richEditing
                richEditingMenu
                showLinkButton={false}
                enableLinkExtension={false}
                showListButton={false}
                showSelectionControls={false}
              />
              <StatusBanner
                as="label"
                status="error"
                text={errors.plannedWork?.header || '\u00a0'}
                isVisible={!!errors.plannedWork?.header?.length}
                css={({ spacing }: ThemeType) => css`
                  margin-top: ${spacing.small};
                `}
              />
            </div>
            <div
              css={css`
                ${styles.inputWrapper}
              `}
            >
              <Editor
                showDetailMenu
                id="editor-body"
                className="optional-editor"
                labelText="Details"
                initialContent={localBodyHTML}
                value={localBody}
                relatedGtfsIds={editorRelatedGtfsIds}
                richEditing
                richEditingMenu
                onChange={({ state }) => {
                  updateValue('body')(state);
                }}
                onStateChange={({ state }) => {
                  setLocalBody(state);
                }}
                shouldFormatOnPaste
              />
            </div>
            <div id={PlannedWorkErrorKeys.stationAlternatives}>
              <StationAlternatives
                value={stationAlternatives}
                onChange={updateValue('stationAlternatives')}
              />
              <StatusBanner
                as="label"
                status="error"
                text={errors.plannedWork?.stationAlternatives || '\u00a0'}
                isVisible={!!errors.plannedWork?.stationAlternatives?.length}
                css={({ spacing }: ThemeType) => css`
                  margin-top: ${spacing.small};
                `}
              />
            </div>
          </section>
          {screensTargetable && (
            <section css={styles.section2}>
              <div
                css={css`
                  display: inline-flex;
                  align-items: center;
                  position: relative;
                `}
              >
                <Toggle
                  id="use_screens"
                  aria-labelledby="use_screens_heading"
                  checked={useScreens}
                  onChange={(e) => {
                    updateValue('useScreens')(e.target.checked);
                    if (!targeting.length) {
                      addEmptyTargeting();
                    }
                    if (!sidewalkScreenEnabled && !showAddScreen) {
                      setShowAddScreen(true);
                    }
                  }}
                  css={css`
                    display: flex;
                    margin-right: 12px;
                  `}
                />
                <CommonHeading level={2} id="use_screens_heading">
                  Screens
                </CommonHeading>
              </div>

              {useScreens && (
                <div
                  css={css`
                    margin-top: 1rem;
                  `}
                >
                  {targeting.map((m, index) => {
                    const key = `screen-message-${index}-${numRemoved}`;
                    return (
                      <PlannedWorkScreenCompose
                        key={key}
                        webHeadline={header}
                        webBody={body}
                        updateTargeting={(
                          index: number,
                          key: keyof ScreenTargeting,
                          value: any,
                        ) => {
                          updateTargeting(index, key, value);
                        }}
                        updateTargetingScreenLayout={(
                          index: number,
                          isDup?: boolean,
                        ) => {
                          updateTargetingScreenLayout(index, isDup);
                        }}
                        removeTargeting={(index: number) => {
                          removeTargeting(index);
                        }}
                        canDelete={
                          targeting.length > 1 ||
                          m.showForm ||
                          !sidewalkScreenEnabled
                        }
                        targeting={m}
                        index={index}
                        durations={durations ?? null}
                        weightingEnabled={weightingEnabled}
                        sidewalkScreenEnabled={sidewalkScreenEnabled}
                        hrdOverrideMessage={
                          hrdOverrideMessage || generatedHRDText
                        }
                        impacts={impacts}
                        error={errors.screens[index]}
                      />
                    );
                  })}
                </div>
              )}
            </section>
          )}
          {showAddScreen && useScreens && (
            <section css={styles.section4}>
              <Button
                primary
                type="button"
                onClick={() => {
                  addEmptyTargeting();
                }}
                additionalStyles={css`
                  margin-right: 12px;
                `}
                size="small"
              >
                Add Screen
              </Button>
              {!!targeting.length && (
                <Button
                  primary
                  type="button"
                  onClick={() => {
                    duplicateTargeting();
                  }}
                  size="small"
                >
                  Duplicate Screen
                </Button>
              )}
            </section>
          )}
          <InternalNotes
            servicePlanNumbers={servicePlanNumbers}
            internalNotes={internalNotes}
            generalOrderNumbers={generalOrderNumbers}
            updateValue={updateValue}
            onClick={() => setShowInternalNotes(!showInternalNotes)}
            isOpen={showInternalNotes}
          />
        </div>
        <section
          css={css`
            margin: 16px 32px;
            display: flex;
            flex-direction: row-reverse;

            & > * {
              margin-left: 12px;
            }
          `}
        >
          <Button
            primary
            type="button"
            disabled={isSubmitting || shouldNotShowPublishButton}
            onClick={handleSubmit({ draft: false })}
          >
            Publish
          </Button>
          <Button
            type="button"
            disabled={isSubmitting}
            onClick={handleSubmit({ draft: true })}
          >
            Save as draft
          </Button>
          <Button
            type="button"
            disabled={isSubmitting}
            onClick={() => {
              history.push(`/${feedId}/planned-work`);
            }}
          >
            Cancel
          </Button>
        </section>
        {showDebug && (
          <pre
            css={css`
              background: #000;
              color: #fff;
              font: 1rem/1.2 monospace;
              padding: 1rem;
              margin: 1rem 2rem;
            `}
          >
            {JSON.stringify(
              {
                impacts,
                durationsSource,
                header,
                body,
                useScreens,
                servicePlanNumbers,
                generalOrderNumbers,
                internalNotes,
                stationAlternatives,
                targeting,
              },
              null,
              2,
            )}
          </pre>
        )}
      </form>
      <ErrorModal
        isOpen={errorModalOptions.isOpen}
        title="Message Failed to Post"
        onDismiss={() =>
          setErrorModalOptions({
            isOpen: false,
            isNetworkError: false,
          })
        }
        message={
          errorModalOptions.isNetworkError
            ? GENERIC_SUBMIT_NETWORK_ERROR
            : GENERIC_SUBMIT_ERROR
        }
        includeAssistanceMessage
      />
    </div>
  );
};

export default PlannedWorkCompose;
