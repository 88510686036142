import { useState, useEffect } from 'react';
import localForage from 'localforage';

const store = localForage.createInstance({
  name: 'mercury',
});

// TODO: this hook is causing the "Can't perform a React state update on an unmounted component"
// no-op error to show up in the browser console on dev, the error can be seen by refreshing the
// browser. Creating a `isMounted` variable here does not seem to work, we should for another fix
export default function useLocalForage<T>(
  key: string,
  defaultValue?: T,
): [
  T | undefined,
  (value: T | undefined, cacheInState?: boolean) => Promise<T | undefined>,
  {
    initialLoad: boolean;
    updating: boolean;
    error: Error | null;
  },
] {
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T | undefined>(defaultValue);
  const [initialLoad, setInitialLoad] = useState(true);
  const [updating, setUpdating] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const setInitialStoredValue = async (def: any) => {
      setInitialLoad(true);
      setUpdating(true);
      try {
        const val = await store.getItem(key);
        setStoredValue(val ?? def);
      } catch (err) {
        setError(err as any);
      } finally {
        setInitialLoad(false);
        setUpdating(false);
      }
    };
    setInitialStoredValue(defaultValue);
  }, [key, defaultValue]);

  const setValue = async (value: any, cacheInState: boolean = true) => {
    function doStateSet<V>(setter: (value: V) => void, value: V): void {
      if (cacheInState) {
        setter(value);
      }
    }
    doStateSet(setUpdating, true);
    try {
      doStateSet(setStoredValue, value);
      await store.setItem(key, value);
    } catch (err) {
      doStateSet(setError, err as any);
    } finally {
      doStateSet(setUpdating, false);
    }
    return value;
  };

  return [
    storedValue,
    setValue,
    {
      initialLoad,
      updating,
      error,
    },
  ];
}
