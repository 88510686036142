/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { LockableAssetViewContainer } from '../asset-view/lockable-asset';
import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import * as Well from '../../common/well';
import * as P from '../../common/asset-form/index.styled';
import * as Sentry from '@sentry/browser';
import {
  GetLockableUploadById,
  GetLockableUploadByIdVariables,
} from 'generated/GetLockableUploadById';
import { loader } from 'graphql.macro';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as A from '../asset-view/index.styled';
import AssetForm, { FormTypes, FormValues } from 'components/common/asset-form';
import {
  ScreenOrientation,
  ScreenType,
  UpdateScheduledAssetInput,
} from 'generated/global-types';
import * as S from './index.styled';
import Button from 'components/common/Button';
import { UpdateScheduledAsset } from 'generated/UpdateScheduledAsset';
import { UploadType } from 'hooks/useUpload';
import { useFeedId } from 'contexts/FeedId';
import {
  UpdateUpload as UpdateUploadMutation,
  UpdateUploadVariables,
} from 'generated/UpdateUpload';
import Loader from 'components/common/Loader';
import { css } from '@emotion/react';
import ConfirmModal from 'components/common/confirm-modal';
import StatusBanner from 'components/common/status-banner';
import {
  getAssetStatus,
  ScheduleDuration,
  ScheduleModalState,
  WarningInfo,
} from '../screen-assets/util';
import { TStatusPillTypes } from 'components/common/status-pill';
import { title } from 'components/common/live-content/live-content-table.styles';

const GetLockableUploadByIdQuery = loader(
  '../../../graphql/GetLockableUploadById.gql',
);

const UpdateScheduledAssetMutation = loader(
  '../../../graphql/UpdateScheduledAsset.gql',
);

const UpdateUpload = loader('../../../graphql/UpdateUpload.gql');

const ScheduleEdit = () => {
  const history = useHistory();
  const { id: defaultUploadId } = useParams<{
    id: string;
  }>();
  const feedId = useFeedId();
  const [modalState, setModalState] = useState(ScheduleModalState.EDIT);
  const [showModal, toggleModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [now] = useState(new Date());
  const [warningInfo, setWarningInfo] = useState<WarningInfo>();
  const { data: defaultLockableAsset, loading } = useQuery<
    GetLockableUploadById,
    GetLockableUploadByIdVariables
  >(GetLockableUploadByIdQuery, {
    variables: {
      id: defaultUploadId,
      includeScheduled: true,
      currentDateTime: now,
    },
    onError(error) {
      Sentry.captureException(error);
    },
  });

  const scheduledAsset = defaultLockableAsset?.currentScheduledAsset?.nodes[0];
  const upload = scheduledAsset?.scheduledUpload;
  const thumbnail = scheduledAsset?.thumbnail;
  const scheduledStart = scheduledAsset?.startAt;
  const scheduledEnd = scheduledAsset?.endAt;

  const screenCount = scheduledAsset?.activeScreenCount || 0;
  const [updateScheduledAsset] = useMutation<
    UpdateScheduledAsset,
    UpdateScheduledAssetInput
  >(UpdateScheduledAssetMutation, {
    onError(error) {
      Sentry.captureException(error);
    },
  });

  const [updateUpload] = useMutation<
    UpdateUploadMutation,
    UpdateUploadVariables
  >(UpdateUpload, {
    onError(error) {
      Sentry.captureException(error);
    },
  });

  const assetStatus = getAssetStatus({
    hasScheduledAsset: true,
    activeScreensForSchedule: scheduledAsset?.activeScreenCount || 0,
    activeScreensForDefault:
      defaultLockableAsset?.lockableUpload?.activeScreensCount || 0,
  });
  const screenCountText =
    assetStatus.scheduledScreen === TStatusPillTypes.LIVE
      ? `On ${screenCount} CIC Screen(s)`
      : `Will be on ${defaultLockableAsset?.lockableUpload?.activeScreensCount} CIC Screen(s)`;

  const handleSubmit = () => async (fv: FormValues) => {
    if (!showModal) {
      toggleModal(true);
      setWarningInfo({
        endAt: fv.endAt?.toISOString(),
        startAt: fv.startAt?.toISOString(),
        title: fv.title,
      });

      return;
    }
    setIsSubmitting(true);
    const scheduleId = scheduledAsset?.id;

    await updateUpload({
      variables: {
        title: fv.title,
        description: fv.description,
        id:
          scheduledAsset?.scheduledUpload?.id !== fv?.upload?.id
            ? fv?.upload?.id
            : scheduledAsset?.scheduledUpload?.id,
      },
    });

    if (scheduleId) {
      await updateScheduledAsset({
        variables: {
          id: scheduleId,
          patch: {
            endAt: fv.endAt?.toISOString(),
            startAt: fv.startAt?.toISOString(),
            scheduledUploadId: fv.upload?.id,
            thumbnailId: fv.thumbnail?.id,
          },
        },
      });
    }
    setIsSubmitting(false);

    history.push(`/${feedId}/assets/${defaultUploadId}`);
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexFlow: 'column' }}>
      <PageMeta title="Edit Scheduled Locked Screen" />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}/assets/${defaultUploadId}`,
          title: 'Back to Locked Asset',
        }}
      />
      <Well.Container>
        <Well.Section>
          <A.Title>Edit Scheduled Locked Screen</A.Title>
        </Well.Section>
        <LockableAssetViewContainer
          pageTitle=""
          targetedScreens={[
            {
              type: ScreenType.CIC,
              totalCount: scheduledAsset?.activeScreenCount || 0,
            },
          ]}
          author={scheduledAsset?.author?.name || ''}
          screenType={ScreenType.CIC}
          loading={loading}
          Duration={() => (
            <ScheduleDuration
              startAt={scheduledStart}
              endAt={scheduledEnd}
              title={upload?.title!}
              isScheduled
            />
          )}
          uploadAsset={defaultLockableAsset?.lockableUpload?.upload}
          thumbnailAsset={defaultLockableAsset?.lockableUpload?.thumbnail}
          status={assetStatus.defaultScreen}
        />
      </Well.Container>
      <Loader loading={loading}>
        <AssetForm
          screenCount={screenCountText}
          formType={FormTypes.ScheduledAsset}
          feedId={feedId}
          heading="Edit Scheduled Locked Screen"
          defaultValues={{
            title: upload?.title as string,
            description: upload?.description as string,
            orientation: ScreenOrientation.PORTRAIT,
            thumbnail: thumbnail as UploadType,
            duration: {
              startAt: scheduledStart ? new Date(scheduledStart) : new Date(),
              endAt: scheduledEnd ? new Date(scheduledEnd) : new Date(),
            },
            upload: upload as UploadType,
            screenTypes: [], // not sure about this one
          }}
        >
          {({ makeSubmitHandler, form }) => {
            return (
              <>
                <P.Content>{form}</P.Content>
                <ConfirmModal
                  title={`Confirm Schedule ${ScheduleModalState.CANCEL === modalState ? 'Cancellation' : 'Change'}`}
                  onConfirm={makeSubmitHandler(handleSubmit())}
                  onDismiss={() => {
                    toggleModal(false);
                  }}
                  message="Please confirm the edit of the following Scheduled Locked Screen"
                  isOpen={showModal}
                >
                  <StatusBanner
                    status="warning"
                    isVisible
                    css={css`
                      padding-top: 20px;

                      .sched-desc {
                        margin-bottom: 0;
                      }
                    `}
                  >
                    {warningInfo && (
                      <ScheduleDuration
                        {...warningInfo}
                        isScheduled
                        isWarning
                      />
                    )}
                  </StatusBanner>
                </ConfirmModal>
                {/* maybe revisit this when restructuring components? extra div feels avoidable */}
                <div
                  css={css`
                    margin: 16px 32px;
                  `}
                >
                  <S.Buttons>
                    <Button
                      primary
                      type="button"
                      disabled={isSubmitting}
                      onClick={makeSubmitHandler(handleSubmit())}
                    >
                      schedule
                    </Button>
                    <Button
                      onClick={async () => {
                        toggleModal(true);
                        setWarningInfo({
                          title: scheduledAsset?.scheduledUpload?.title || '',
                          startAt: scheduledAsset?.startAt,
                          endAt: scheduledAsset?.endAt,
                          unscheduled: true,
                        });
                        setModalState(ScheduleModalState.CANCEL);
                      }}
                      type="button"
                    >
                      unschedule
                    </Button>
                    <Button
                      onClick={() => {
                        history.push(`/${feedId}/assets/${defaultUploadId}`);
                      }}
                    >
                      cancel
                    </Button>
                  </S.Buttons>
                </div>
              </>
            );
          }}
        </AssetForm>
      </Loader>
    </div>
  );
};

export default ScheduleEdit;
