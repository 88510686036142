/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ThemeType } from '../../theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={() => css`
      padding: 0px 40px 32px 40px;
    `}
    {...props}
  />
);

export const NoHeadingContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      padding: ${theme.spacing.medium} ${theme.spacing.large};
    `}
    {...props}
  />
);

export const MainFieldContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      margin-bottom: 24px;
    `}
    {...props}
  />
);

export const Section: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={css`
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      max-width: 100%;
    `}
    {...props}
  />
);

export const InnerFormTitle: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      height: 16px;
      width: 163px;
      color: ${theme.colors.dark5};
      font-family: ${theme.typography.families.primary};
    `}
    {...props}
  />
);
