/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';

import { ThemeType } from 'theme';

const Container: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      font-family: ${theme.typography.families.primary};
      background-color: rgb(233, 233, 233);
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid ${theme.colors['border-dark']};
      overflow: hidden;
      width: 100%;
    `}
    {...props}
  />
);

const Control: React.FC<TextareaAutosizeProps> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      textarea {
        ${theme.typography.sizes.medium};
        font-family: inherit;
        border: none;
        width: 100%;
        resize: none;
        padding: 12px 20px;
        background-color: transparent;
        box-sizing: border-box;
        outline: none;
      }
    `}
  >
    {/*
        // @ts-ignore */}
    <TextareaAutosize {...props} />
  </div>
);

const Header: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      padding: 12px 20px;
      display: flex;
      flex-direction: row;

      ${theme.typography.sizes.medium};
      font-weight: ${theme.typography.weights.bold};
    `}
    {...props}
  />
);

const Footer: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      font-family: inherit;
      padding: 12px 0;
      margin: 0 20px;
      background-color: transparent;
      border-top: 1px dashed ${theme.colors['border-dark']};
    `}
    {...props}
  />
);

const InsetShadow: React.FC<{ error: boolean; children?: React.ReactNode }> = ({
  error,
  ...rest
}) => (
  <div
    css={(theme: ThemeType) => css`
      background: white;
      overflow: hidden;
      border: none;
      border-radius: 3px;
      position: relative;

      :not(:focus-within) {
        ${error
          ? `box-shadow: inset 0 0 0 1px ${theme.colors['status-error']}`
          : `box-shadow: inset 0 0 0 1px ${theme.colors['border-dark']}`};
      }
      :focus-within {
        box-shadow: inset 0 0 0 1px ${theme.colors.accent3};
      }

      ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: inset 0 0 6px ${theme.colors['border-dark']};
        border-radius: 3px;
        pointer-events: none;
      }
    `}
    {...rest}
  />
);

type Props = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  error?: boolean;
} & TextareaAutosizeProps;

const Textarea: React.FC<Props> = ({
  error = false,
  header,
  footer,
  className,
  ...rest
}) => (
  <Container className={className}>
    {header && <Header>{header}</Header>}
    <InsetShadow error={error}>
      <Control {...rest} />
      {footer && <Footer>{footer}</Footer>}
    </InsetShadow>
  </Container>
);

export default Textarea;
