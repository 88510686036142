/** @jsxImportSource @emotion/react */

import * as React from 'react';
import Heading from 'components/common/Heading';
import pluralize from 'pluralize';

import * as S from './InScreenHeading.styled';

interface InScreenHeadingProps {
  campaignsCount: number;
  plannedWorksCount: number;
  plannedWorkEnabled: boolean;
  alertsCount: number;
  showContentCount: boolean;
}

const InScreenHeading: React.FC<InScreenHeadingProps> = ({
  campaignsCount,
  plannedWorksCount,
  plannedWorkEnabled,
  alertsCount,
  showContentCount,
}) => {
  return (
    <React.Fragment>
      <Heading level={3}>
        {showContentCount ? (
          <React.Fragment>
            Next 24 hours: {pluralize('Campaign', campaignsCount, true)}
            {plannedWorkEnabled && (
              <span>
                , {pluralize('Planned Work', plannedWorksCount, true)}
              </span>
            )}
          </React.Fragment>
        ) : (
          'Active Content'
        )}
      </Heading>
      {campaignsCount === 0 && plannedWorksCount === 0 && alertsCount === 0 && (
        <S.Warning>There is no content scheduled for this screen.</S.Warning>
      )}
    </React.Fragment>
  );
};

export default InScreenHeading;
