import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { CurrentUserFragment as CurrentUserType } from 'generated/CurrentUser.fragment';
import { isFeatureEnabled } from 'hooks/useFeatureFlag';

import { FeedId } from '../../../types/feeds';
import * as S from './NavLinks.styled';

import { SETTINGS_PAGE_URL } from '../../../constants/page-urls';
import { FeatureFlagName } from 'generated/global-types';

const { REACT_APP_DEPLOY_ENV } = process.env;

interface LMMNavLink {
  label: string;
  url: string;
}

const SecondaryNav: React.FC<{
  links: LMMNavLink[];
  show: boolean;
  navIsOpen: boolean;
  children?: React.ReactNode;
}> = ({ links = [], show, navIsOpen }) => (
  <S.SecondaryList show={show}>
    {links.map(({ label, url }) => (
      <S.ListItem key={url}>
        <S.NavLinkL2
          exact
          to={url}
          tabIndex={show && navIsOpen ? undefined : -1}
        >
          {label}
        </S.NavLinkL2>
      </S.ListItem>
    ))}
  </S.SecondaryList>
);

const NavLinks: React.FC<{
  user: CurrentUserType;
  isOpen: boolean;
  feedId: FeedId;
  children?: React.ReactNode;
}> = ({ user, feedId, isOpen }) => {
  const matchSettingsHome = useRouteMatch({
    path: `/${SETTINGS_PAGE_URL}`,
    exact: true,
  });

  const matchAlertsHome = useRouteMatch({ path: `/${feedId}`, exact: true });
  const matchClearedAlerts = useRouteMatch({
    path: `/${feedId}/alerts/cleared`,
    exact: true,
  });
  const matchDraftAlerts = useRouteMatch({
    path: `/${feedId}/alerts/draft`,
    exact: true,
  });
  const matchAlerts = !!(
    matchAlertsHome ||
    matchClearedAlerts ||
    matchDraftAlerts
  );
  const matchCampaigns = useRouteMatch({ path: `/${feedId}/campaigns` });
  const matchPlannedWork = useRouteMatch({ path: `/${feedId}/planned-work` });
  const matchScreens = useRouteMatch({ path: `/${feedId}/screens` });

  const serviceAlertedEnabled = isFeatureEnabled(
    user,
    FeatureFlagName.SERVICE_ALERTS,
    feedId,
  );
  const plannedWorkEnabled = isFeatureEnabled(
    user,
    FeatureFlagName.PLANNED_WORK,
    feedId,
  );

  const liveContentEnabled = isFeatureEnabled(
    user,
    FeatureFlagName.LIVE_CONTENT,
    feedId,
  );

  const screensEnabled = isFeatureEnabled(
    user,
    FeatureFlagName.SCREENS_UI,
    feedId,
  );
  const assetsEnabled = isFeatureEnabled(user, FeatureFlagName.ASSETS, feedId);
  const campaignsEnabled =
    screensEnabled && isFeatureEnabled(user, FeatureFlagName.CAMPAIGNS, feedId);

  if (matchSettingsHome) {
    return (
      <S.NavList>
        <S.ListItem>
          <S.NavLinkL1 exact to={`/${SETTINGS_PAGE_URL}`}>
            User Management
          </S.NavLinkL1>
        </S.ListItem>
      </S.NavList>
    );
  }

  return (
    <S.NavList>
      <S.ListItem>
        <S.Heading>Messages</S.Heading>
        <S.NavList>
          {serviceAlertedEnabled && (
            <S.ListItem>
              <S.NavLinkL1
                exact
                to={`/${feedId}`}
                otherMatches={[`/${feedId}/compose`, `/${feedId}/events`]}
                tabIndex={!isOpen ? -1 : undefined}
              >
                Alerts
              </S.NavLinkL1>
              <SecondaryNav
                links={[{ label: 'Cleared', url: `/${feedId}/alerts/cleared` }]}
                show={matchAlerts}
                navIsOpen={isOpen}
              />
              <SecondaryNav
                links={[{ label: 'Drafts', url: `/${feedId}/alerts/draft` }]}
                show={matchAlerts}
                navIsOpen={isOpen}
              />
            </S.ListItem>
          )}
          {plannedWorkEnabled && (
            <S.ListItem>
              <S.NavLinkL1
                exact
                to={`/${feedId}/planned-work`}
                otherMatches={[
                  `/${feedId}/planned-work/compose`,
                  `/${feedId}/planned-work/:id`,
                ]}
                tabIndex={!isOpen ? -1 : undefined}
              >
                Planned Work
              </S.NavLinkL1>
              <SecondaryNav
                links={[
                  {
                    label: 'Cleared & Cancelled',
                    url: `/${feedId}/planned-work/cleared`,
                  },
                ]}
                show={!!matchPlannedWork}
                navIsOpen={isOpen}
              />
              <SecondaryNav
                links={[
                  {
                    label: 'Drafts',
                    url: `/${feedId}/planned-work/draft`,
                  },
                ]}
                show={!!matchPlannedWork}
                navIsOpen={isOpen}
              />
            </S.ListItem>
          )}
          {campaignsEnabled && (
            <S.ListItem>
              <S.NavLinkL1
                exact
                to={`/${feedId}/campaigns`}
                otherMatches={[`/${feedId}/campaigns/compose`]}
                tabIndex={!isOpen ? -1 : undefined}
              >
                Campaigns
              </S.NavLinkL1>
              <SecondaryNav
                links={[
                  {
                    label: 'Cleared',
                    url: `/${feedId}/campaigns/cleared`,
                  },
                ]}
                show={!!matchCampaigns}
                navIsOpen={isOpen}
              />
            </S.ListItem>
          )}
        </S.NavList>
      </S.ListItem>
      {!screensEnabled && REACT_APP_DEPLOY_ENV === 'local' && (
        <S.LocalScreensWarning>
          Enable the <code>SCREENS_UI</code> feature flag to access Mercury
          content
        </S.LocalScreensWarning>
      )}
      {screensEnabled && (
        <S.ListItem>
          <S.Heading>Screens</S.Heading>
          <S.SecondaryList show>
            <S.ListItem>
              <S.NavLinkL1
                exact
                to={`/${feedId}/screens`}
                tabIndex={!isOpen ? -1 : undefined}
              >
                By Station
              </S.NavLinkL1>
              <SecondaryNav
                links={[
                  {
                    label: 'Fallback Content',
                    url: `/${feedId}/screens/fallback`,
                  },
                ]}
                show={!!matchScreens}
                navIsOpen={isOpen}
              />
            </S.ListItem>
          </S.SecondaryList>
          {liveContentEnabled && (
            <S.SecondaryList show>
              <S.ListItem>
                <S.NavLinkL1
                  to={`/${feedId}/screens/live`}
                  tabIndex={!isOpen ? -1 : undefined}
                >
                  Live Content
                </S.NavLinkL1>
              </S.ListItem>
            </S.SecondaryList>
          )}
        </S.ListItem>
      )}
      {assetsEnabled && (
        <S.ListItem>
          <S.Heading>Assets</S.Heading>
          <S.NavLinkL1
            exact
            to={`/${feedId}/assets/locked`}
            tabIndex={!isOpen ? -1 : undefined}
          >
            Locked Screens
          </S.NavLinkL1>
          <S.NavLinkL1
            exact
            to={`/${feedId}/assets/social-media`}
            tabIndex={!isOpen ? -1 : undefined}
          >
            Social Media
          </S.NavLinkL1>
        </S.ListItem>
      )}
    </S.NavList>
  );
};

export default NavLinks;
