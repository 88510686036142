/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Fragment } from 'react';
import { SchedulePriority } from 'generated/global-types';
import theme from 'theme';
import WeightPriorityPill from '../weight-priority-pill';

const DurationFrequency: React.FC<
  {
    weight: number | null;
    priority: SchedulePriority;
    label: string;
  } & { children?: React.ReactNode }
> = ({ weight, priority, label }) => {
  return (
    <div
      css={css`
        margin-bottom: 8px;
      `}
    >
      <div
        css={css`
          margin-bottom: 5px;
        `}
      >
        {label}
      </div>
      {weight && (
        <Fragment>
          <WeightPriorityPill priority={priority} weight={weight} />
          <span
            css={css`
              font-size: ${theme.typography.sizes.small.fontSize};
              font-weight: ${theme.typography.weights.normal};
              padding-left: 5px;
            `}
          >
            Frequency
          </span>
        </Fragment>
      )}
    </div>
  );
};

export default DurationFrequency;
