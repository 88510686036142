/** @jsxImportSource @emotion/react */

import React, { useMemo, useState } from 'react';
import { useTable, Column } from 'react-table';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { css } from '@emotion/react';
import Checkbox from 'components/common/Checkbox';
import Pagination from 'components/common/Pagination';
import {
  FeatureFlagName,
  FlagFeedInput,
  FeatureEnabled,
} from 'generated/global-types';
import { useToast, Toast, ToastLevel } from 'ui-kit/toast';
import * as S from './index.styled';
import {
  TableStructure,
  transformDataToTableStructure,
  currentFeedsState,
} from './helpers';

import {
  Table,
  TextCell,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableData,
} from '../../common/table-elements';
import { FeatureFlags_featureFlags_FeatureFlagsConnection_nodes_FeatureFlag as FeatureFlags_featureFlags_nodes } from '../../../generated/FeatureFlags';
import { ThemeType } from '../../../theme';
import {
  UpdateFlag,
  UpdateFlagVariables,
} from '../../../generated/UpdateFeatureFlag';
import UserManagementModal from './UsersManagementModal';
import { FeedId } from '../../../types/feeds';
import { FEEDS } from '../../../contexts/FeedId';

const { REACT_APP_DEPLOY_ENV } = process.env;
const OUTFRONT_FEEDS_PREFIX = 'outfront-';

const UpdateFeatureFlagMutation = loader(
  '../../../graphql/UpdateFeatureFlag.gql',
);

const getFeedLabel = (feedId: FeedId): string => {
  return FEEDS[feedId].name;
};

const FeatureFlagsTable: React.FC<
  {
    onPrevClick?: () => void;
    onNextClick?: () => void;
    page?: number;
    perPage?: number;
    totalCount: number;
    feedGroups?: string[];
    featureFlags: FeatureFlags_featureFlags_nodes[];
  } & { children?: React.ReactNode }
> = ({
  page = 1,
  perPage = 8,
  totalCount,
  onNextClick,
  onPrevClick,
  featureFlags,
  feedGroups = [],
}) => {
  const { shouldShowToast, showToast, hideToast } = useToast();
  const [toastText, setToastText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [currentFlagFeeds, setCurrentFlagFeeds] = useState<(string | null)[]>(
    [],
  );
  const [openedFeatureFlag, setOpenedFeatureFlag] = useState<FeatureFlagName>(
    FeatureFlagName.CAMPAIGNS,
  );
  const [updateFeatureFlag] = useMutation<UpdateFlag, UpdateFlagVariables>(
    UpdateFeatureFlagMutation,
    {
      onCompleted: (data) => {
        const feed = featureFlags.find(
          (feature) => feature.name === data.updateFlag?.featureFlag?.name,
        );
        if (feed) {
          setToastText(
            `${feed.name.replace(/_/g, ' ')} is now ${feed.enabled}`,
          );
          showToast();
        }
      },
    },
  );

  const disableOutfrontAgencies = REACT_APP_DEPLOY_ENV !== 'uat';

  const data = useMemo(
    () => transformDataToTableStructure(featureFlags, feedGroups),
    [featureFlags, feedGroups],
  );

  const columns = useMemo(() => {
    const onChangeFlagStatus = (
      {
        id,
        enabled,
        name,
      }: { id: string; enabled: FeatureEnabled; name: FeatureFlagName },
      feeds: FlagFeedInput[],
    ) => {
      updateFeatureFlag({
        variables: {
          flagName: name,
          feeds,
        },
        optimisticResponse: {
          updateFlag: {
            __typename: 'UpdateFlagPayload',
            featureFlag: {
              __typename: 'FeatureFlag',
              enabled,
              name,
              id,
              feeds: feeds.filter((m) => m.enabled).map((m) => m.feedId ?? ''),
            },
          },
        },
      });
    };
    const columnArray: Column<TableStructure>[] = [
      {
        accessor: 'info',
        Cell: ({
          cell: { value },
        }: {
          cell: {
            value: TableStructure['info'];
          };
        }) => {
          const info = <div>{value.name?.replace(/_/g, ' ')} </div>;
          return (
            <TextCell heading={info}>
              <span
                css={({ typography }: ThemeType) => css`
                  ${typography.sizes.small};
                `}
              >
                {value.description}
              </span>
            </TextCell>
          );
        },
        Header: () => {
          return <div>Name</div>;
        },
      },
    ];

    feedGroups.forEach((feedGroup) => {
      // We're removing Outfront agencies usage for environments other than uat
      if (
        feedGroup.startsWith(OUTFRONT_FEEDS_PREFIX) &&
        disableOutfrontAgencies
      ) {
        return;
      }
      columnArray.push({
        accessor: feedGroup,
        Cell: ({ cell: { value } }: { cell: { value: any } }) => {
          return (
            <div css={S.textAlignCenter}>
              <Checkbox
                checked={value.isSelected}
                onChange={() => {
                  const currentSelectedFeeds = currentFeedsState(
                    feedGroups,
                    feedGroup,
                    value.isSelected,
                    value.featureFlag.feeds,
                  );
                  return onChangeFlagStatus(
                    value.featureFlag,
                    currentSelectedFeeds,
                  );
                }}
              />
            </div>
          );
        },
        Header: () => {
          const feed: FeedId = feedGroup as FeedId;
          return <div css={S.textAlignCenter}>{getFeedLabel(feed)}</div>;
        },
      });
    });

    columnArray.push({
      accessor: 'actions',
      Cell: ({
        cell: { value },
      }: {
        cell: {
          value: TableStructure['actions'];
        };
      }) => {
        return (
          <div css={S.textAlignCenter}>
            {!value.enabled && (
              <S.ActiveButton
                onClick={() => {
                  setModalVisible(true);
                  setOpenedFeatureFlag(value.name);
                  setCurrentFlagFeeds(value.feeds);
                }}
              >
                Enable Flag For Users
              </S.ActiveButton>
            )}
            {value.enabled && (
              <S.DeactivatedButton>
                Already Enabled For All Users
              </S.DeactivatedButton>
            )}
          </div>
        );
      },
      Header: () => {
        return <div css={S.textAlignCenter}>User Management</div>;
      },
    });

    return columnArray;
  }, [feedGroups, updateFeatureFlag, disableOutfrontAgencies]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <div
      css={css`
        max-width: 1000px;
      `}
    >
      <Toast
        shouldShow={shouldShowToast}
        onDismiss={hideToast}
        level={ToastLevel.info}
      >
        {toastText}
      </Toast>
      <UserManagementModal
        modalVisible={modalVisible}
        onDismiss={() => setModalVisible(false)}
        featureFlagName={openedFeatureFlag}
        currentFlagFeeds={currentFlagFeeds}
      />
      <Table
        css={css`
          width: 100%;
          margin-bottom: 1rem;
        `}
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <TableHeaderCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableHeaderCell>
                );
              })}
            </tr>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const hasLeftBorder = !['info'].includes(cell.column.id);

                  return (
                    <TableData
                      hasLeftBorder={hasLeftBorder}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        currentPage={page}
        itemsPerPage={perPage}
        totalItems={totalCount}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
      />
    </div>
  );
};

export default FeatureFlagsTable;
