/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import theme from 'theme';

import LabelledInput from 'components/common/form-elements/input-label';

import ConfirmModal from '../../common/confirm-modal';

const NotesModal: React.FC<
  {
    onSave: () => void;
    onDismiss: () => void;
    isOpen: boolean;
    onChange: (e: any) => void;
    value: string;
  } & { children?: React.ReactNode }
> = ({ onDismiss, isOpen, onSave, onChange, value }) => {
  return (
    <ConfirmModal
      onConfirm={onSave}
      isOpen={isOpen}
      onDismiss={onDismiss}
      title="Add Incident Notes"
      dismissText="Cancel"
      confirmText="Save"
    >
      <LabelledInput htmlFor="notes-modal-textarea" height={125} label="Notes">
        <TextareaAutosize
          css={css`
            resize: none;
            border: none;
            outline: none;
            width: 100%;
            height: 100%;
            padding: 16px 24px;
            border-radius: 4px;
            box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
          `}
          id="notes-modal-textarea"
          value={value}
          onChange={onChange}
        />
      </LabelledInput>
    </ConfirmModal>
  );
};

export default NotesModal;
