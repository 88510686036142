/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState, Dispatch, useEffect } from 'react';
import { Collapse } from 'react-collapse';
import { useFormContext } from 'react-hook-form';
import { setHours, setMinutes, setDayOfYear, getDayOfYear } from 'date-fns';

import Heading from '../Heading';
import * as S from './internal-log.styled';
import LabelledInput from '../form-elements/input-label';
import StatusBanner from '../status-banner';
import {
  INTERNAL_LOG_NOTES,
  INTERNAL_LOG_NOTIFIED_AT,
  INTERNAL_LOG_INCIDENT_START_AT,
  TIME_PATTERN_24_HRS,
  MUST_USE_24_HOUR_FORMAT,
  INTERNAL_LOG_INCIDENT_END_AT,
  INTERNAL_LOG_NEEDED_BY,
} from '../../../constants/alerts';
import { getFormattedTime, insertColonToTime } from '../../../utils/alert-log';
import { input as inputCSS } from '../styles';
import theme from '../../../theme';
import Button from '../Button';
import DateTimePicker from '../form-elements/datetime-picker';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { FeatureFlagName } from 'generated/global-types';

const InternalLog: React.FC<
  {
    incidentStartAt?: any;
    incidentEndAt?: any;
    notifiedAt?: any;
    notes?: string;
    neededBy?: any;
    createdAt?: any;
    onClick: () => void;
    isOpen: boolean;
    singleEvent?: boolean;
  } & { children?: React.ReactNode }
> = ({
  incidentStartAt,
  incidentEndAt,
  notifiedAt,
  neededBy,
  notes = '',
  createdAt,
  onClick,
  isOpen,
  singleEvent = false,
}) => {
  const { setValue, register, clearError, errors, triggerValidation } =
    useFormContext();
  const enableNeededByField = useFeatureFlag(
    FeatureFlagName.MESSAGED_NEEDED_IN_CLEARED_LOGS,
  );
  const [logNotes, setLogNotes] = useState('');
  const [showInitialNote, setShowInitialNote] = useState(true);

  const [incidentStart, setIncidentStart] = useState(incidentStartAt);
  const [incidentStartTime, setIncidentStartTime] = useState('');
  const [incidentEnd, setIncidentEnd] = useState(incidentEndAt);
  const [incidentEndTime, setIncidentEndTime] = useState('');
  const [notified, setNotified] = useState(notifiedAt);
  const [notifiedTime, setNotifiedTime] = useState('');
  const [needed, setNeeded] = useState(neededBy);
  const [neededByTime, setNeededByTime] = useState('');

  const createdAtValue = createdAt || new Date().toISOString();

  const initialIncidentStartDateValue = incidentStartAt ?? createdAtValue;
  const [incidentStartDateValue, setIncidentStartDateValue] = useState('');
  const initialIncidentEndDateValue = incidentEndAt ?? createdAtValue;
  const [incidentEndDateValue, setIncidentEndDateValue] = useState('');
  const initialNotifiedDateValue = notifiedAt ?? createdAtValue;
  const [notifiedDateValue, setNotifiedDateValue] = useState('');
  const initialNeededByDateValue = neededBy ?? createdAtValue;
  const [neededByDateValue, setNeededByDateValue] = useState('');

  const timeInputStyles = {
    labelSize: 'medium',
    width: '165px',
    height: '49px',
    backgroundColor: theme.colors.white,
    timeInputPaddingLeft: theme.spacing.small,
  };

  const keyToVars: {
    [key: string]: {
      date: string;
      dateChangeFn: Dispatch<any>;
      timeChangeFn: Dispatch<any>;
    };
  } = {
    notifiedAt: {
      date: notifiedDateValue || initialNotifiedDateValue,
      dateChangeFn: setNotified,
      timeChangeFn: setNotifiedTime,
    },
    incidentStartAt: {
      date: incidentStartDateValue || initialIncidentStartDateValue,
      dateChangeFn: setIncidentStart,
      timeChangeFn: setIncidentStartTime,
    },
    incidentEndAt: {
      date: incidentEndDateValue || initialIncidentEndDateValue,
      dateChangeFn: setIncidentEnd,
      timeChangeFn: setIncidentEndTime,
    },
    neededBy: {
      date: neededByDateValue || initialNeededByDateValue,
      dateChangeFn: setNeeded,
      timeChangeFn: setNeededByTime,
    },
  };

  const timePatternTest = (value: any) => {
    if (typeof value === 'object') {
      return TIME_PATTERN_24_HRS.test(
        value
          .toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23',
          })
          .split(' ')[0],
      );
    }
    return TIME_PATTERN_24_HRS.test(value);
  };

  useEffect(() => {
    register(
      { name: INTERNAL_LOG_NEEDED_BY },
      {
        validate: (value) => {
          return !value || timePatternTest(value);
        },
      },
    );
    register(
      { name: INTERNAL_LOG_INCIDENT_START_AT },
      {
        validate: (value) => {
          return !value || timePatternTest(value);
        },
      },
    );
    register(
      { name: INTERNAL_LOG_NOTIFIED_AT },
      {
        validate: (value) => {
          return !value || timePatternTest(value);
        },
      },
    );
    register(
      { name: INTERNAL_LOG_INCIDENT_END_AT },
      {
        validate: (value) => {
          return !value || timePatternTest(value);
        },
      },
    );
    register({ name: INTERNAL_LOG_NOTES });
  }, [register]);

  const incidentStartError = errors[INTERNAL_LOG_INCIDENT_START_AT];
  const notifiedError = errors[INTERNAL_LOG_NOTIFIED_AT];
  const incidentEndError = errors[INTERNAL_LOG_INCIDENT_END_AT];
  const neededByError = errors[INTERNAL_LOG_NEEDED_BY];

  const hasErrors =
    !!incidentStartError ||
    !!notifiedError ||
    !!incidentEndError ||
    !!neededByError;

  const setTimeValue = (value: string, key: string) => {
    const { timeChangeFn, date, dateChangeFn } = keyToVars[key];
    timeChangeFn(value);
    if (TIME_PATTERN_24_HRS.test(value)) {
      clearError(key);
      const hoursAndMinutes = value.split(':');
      const withNewHours = setHours(new Date(date), Number(hoursAndMinutes[0]));
      const withNewMinutes = setMinutes(
        withNewHours,
        Number(hoursAndMinutes[1]),
      );
      dateChangeFn(withNewMinutes);
      setValue(key, withNewMinutes);
    } else {
      setValue(key, value || null);
      if (!value) {
        clearError(key);
      }
    }
  };

  const handleTimeBlur = (e: React.ChangeEvent, key: string) => {
    const { value } = e.target as HTMLInputElement;
    if (!TIME_PATTERN_24_HRS.test(value)) {
      try {
        const newValue = insertColonToTime(value);
        setTimeValue(newValue, key);
      } catch (error) {
        triggerValidation({ name: key });
      }
    } else {
      setTimeValue(value, key);
    }
  };

  return (
    <S.Section>
      <S.ToggleButton onClick={onClick}>
        <S.IndicatorArrow isOpen={isOpen} />
        <Heading level={3}>Internal Log</Heading>
        <S.Spacer />
      </S.ToggleButton>
      <Collapse isOpened={isOpen || hasErrors}>
        <div
          css={css`
            ${S.ContainerRow}
          `}
        >
          <DateTimePicker
            label="Incident Start"
            timeValue={incidentStartTime || getFormattedTime(incidentStartAt)}
            error={!!errors[INTERNAL_LOG_INCIDENT_START_AT]}
            dateValue={incidentStartDateValue || initialIncidentStartDateValue}
            onChangeTime={(value) =>
              setTimeValue(value, INTERNAL_LOG_INCIDENT_START_AT)
            }
            onChangeDate={(value) => {
              const newDate = getDayOfYear(new Date(value));
              const newDateAndTime = incidentStart
                ? setDayOfYear(new Date(incidentStart), newDate)
                : value;
              setIncidentStartDateValue(newDateAndTime.toISOString());
              setIncidentStart(newDateAndTime);
              setValue(INTERNAL_LOG_INCIDENT_START_AT, newDateAndTime);
            }}
            onBlur={(e) => handleTimeBlur(e, INTERNAL_LOG_INCIDENT_START_AT)}
            width={timeInputStyles.width}
            height={timeInputStyles.height}
            backgroundColor={timeInputStyles.backgroundColor}
            labelSize={timeInputStyles.labelSize}
            timeInputPaddingLeft={timeInputStyles.timeInputPaddingLeft}
          />
          <DateTimePicker
            label="Notified"
            timeValue={notifiedTime || getFormattedTime(notifiedAt)}
            error={!!errors[INTERNAL_LOG_NOTIFIED_AT]}
            dateValue={notifiedDateValue || initialNotifiedDateValue}
            onChangeTime={(value) =>
              setTimeValue(value, INTERNAL_LOG_NOTIFIED_AT)
            }
            onChangeDate={(value) => {
              const newDate = getDayOfYear(new Date(value));
              const newDateAndTime = notified
                ? setDayOfYear(new Date(notified), newDate)
                : value;
              setNotifiedDateValue(newDateAndTime.toISOString());
              setNotified(newDateAndTime);
              setValue(INTERNAL_LOG_NOTIFIED_AT, newDateAndTime);
            }}
            onBlur={(e) => handleTimeBlur(e, INTERNAL_LOG_NOTIFIED_AT)}
            width={timeInputStyles.width}
            height={timeInputStyles.height}
            backgroundColor={timeInputStyles.backgroundColor}
            labelSize={timeInputStyles.labelSize}
            timeInputPaddingLeft={timeInputStyles.timeInputPaddingLeft}
          />
          {enableNeededByField && (
            <>
              <DateTimePicker
                label="Message Needed"
                timeValue={neededByTime || getFormattedTime(neededBy)}
                error={!!errors[INTERNAL_LOG_NEEDED_BY]}
                dateValue={neededByDateValue || initialNeededByDateValue}
                onChangeTime={(value) =>
                  setTimeValue(value, INTERNAL_LOG_NEEDED_BY)
                }
                onChangeDate={(value) => {
                  const newDate = getDayOfYear(new Date(value));
                  const newDateAndTime = needed
                    ? setDayOfYear(new Date(needed), newDate)
                    : value;
                  setNeededByDateValue(newDateAndTime.toISOString());
                  setNeeded(newDateAndTime);
                  setValue(INTERNAL_LOG_NEEDED_BY, newDateAndTime);
                }}
                onBlur={(e) => handleTimeBlur(e, INTERNAL_LOG_NEEDED_BY)}
                width={timeInputStyles.width}
                height={timeInputStyles.height}
                backgroundColor={timeInputStyles.backgroundColor}
                labelSize={timeInputStyles.labelSize}
                timeInputPaddingLeft={timeInputStyles.timeInputPaddingLeft}
              />
            </>
          )}
          <DateTimePicker
            label="Incident End"
            timeValue={incidentEndTime || getFormattedTime(incidentEndAt)}
            error={!!errors[INTERNAL_LOG_INCIDENT_END_AT]}
            dateValue={incidentEndDateValue || initialIncidentEndDateValue}
            onChangeTime={(value) =>
              setTimeValue(value, INTERNAL_LOG_INCIDENT_END_AT)
            }
            onChangeDate={(value) => {
              const newDate = getDayOfYear(new Date(value));
              const newDateAndTime = incidentEnd
                ? setDayOfYear(new Date(incidentEnd), newDate)
                : value;
              setIncidentEndDateValue(newDateAndTime.toISOString());
              setIncidentEnd(newDateAndTime);
              setValue(INTERNAL_LOG_INCIDENT_END_AT, newDateAndTime);
            }}
            onBlur={(e) => handleTimeBlur(e, INTERNAL_LOG_INCIDENT_END_AT)}
            width={timeInputStyles.width}
            height={timeInputStyles.height}
            backgroundColor={timeInputStyles.backgroundColor}
            labelSize={timeInputStyles.labelSize}
            timeInputPaddingLeft={timeInputStyles.timeInputPaddingLeft}
          />
          <div css={S.NotesContainer}>
            <LabelledInput
              label="Notes"
              css={() => css`
                ${S.LabelledInput}
              `}
            >
              <input
                id={INTERNAL_LOG_NOTES}
                name={INTERNAL_LOG_NOTES}
                type="text"
                value={showInitialNote ? notes : logNotes}
                onChange={(e) => {
                  if (showInitialNote) {
                    setShowInitialNote(false);
                  }
                  setLogNotes(e.target.value);
                  setValue(INTERNAL_LOG_NOTES, e.target.value);
                }}
                autoComplete="off"
                css={() => css`
                  ${inputCSS}
                `}
              />
            </LabelledInput>
          </div>
          {singleEvent && (
            <React.Fragment>
              <div css={S.ButtonContainer}>
                <Button
                  secondary="true"
                  type="submit"
                  disabled={!!hasErrors}
                  css={() => css`
                    min-width: 80px;
                    height: 49px;
                    font-size: 12px;
                  `}
                >
                  Save
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
        <StatusBanner
          as="label"
          status="error"
          isVisible={hasErrors}
          text={MUST_USE_24_HOUR_FORMAT}
          htmlFor={`${
            incidentStartError?.ref.name ||
            notifiedError?.ref.name ||
            incidentEndError?.ref.name
          }`}
          css={() => css`
            ${S.StatusBanner}
          `}
        />
      </Collapse>
    </S.Section>
  );
};

export default InternalLog;
