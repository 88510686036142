import { useReducer, useCallback } from 'react';
import idGen from 'utils/id-gen';
import { Tweet as NonThreadedTweet } from 'components/common/tweets/tweet-input';
import { UploadFileContainer } from 'types/twitter-attachments';
import { Tweet } from './types';

type State = Tweet[];

enum ActionType {
  tweetAdded = 'TWEET_ADDED',
  tweetRemoved = 'TWEET_REMOVED',
  textChanged = 'TEXT_CHANGED',
  attachmentsChanged = 'ATTACHMENTS_CHANGED',
  quoteIdChanged = 'QUOTE_ID_CHANGED',
  replyIdChanged = 'REPLY_ID_CHANGED',
}

type Action =
  | { type: ActionType.tweetAdded }
  | { type: ActionType.tweetRemoved; payload: { id: number } }
  | { type: ActionType.textChanged; payload: { id: number; newText: string } }
  | {
      type: ActionType.attachmentsChanged;
      payload: { id: number; newAttachments: UploadFileContainer[] };
    }
  | {
      type: ActionType.quoteIdChanged;
      payload: { id: number; newQuoteId: string };
    }
  | { type: ActionType.replyIdChanged; payload: { newReplyId: string } };

const makeTweet = (
  defaultValue: NonThreadedTweet = {
    text: '',
    attachments: [],
    quoteId: '',
    replyId: '',
  },
): Tweet => ({
  id: idGen('tweet-patch-'),
  ...defaultValue,
});

const reducer = (tweets: State, action: Action) => {
  switch (action.type) {
    case ActionType.tweetAdded:
      return [...tweets, makeTweet()];
    case ActionType.tweetRemoved: {
      const {
        payload: { id },
      } = action;
      return tweets.filter((t, i) => i !== id);
    }
    case ActionType.textChanged: {
      const {
        payload: { id, newText },
      } = action;
      return tweets.map((t, i) => (i === id ? { ...t, text: newText } : t));
    }
    case ActionType.attachmentsChanged: {
      const {
        payload: { id, newAttachments },
      } = action;
      return tweets.map((t, i) =>
        i === id ? { ...t, attachments: newAttachments } : t,
      );
    }
    case ActionType.quoteIdChanged: {
      const {
        payload: { id, newQuoteId },
      } = action;
      return tweets.map((t, i) =>
        i === id ? { ...t, quoteId: newQuoteId } : t,
      );
    }
    case ActionType.replyIdChanged: {
      const {
        payload: { newReplyId },
      } = action;
      return tweets.map((t) => ({ ...t, replyId: newReplyId }));
    }

    default:
      return tweets;
  }
};

export const useTweetThreadState = (defaultValues: NonThreadedTweet[] = []) => {
  const defaultTweets =
    defaultValues.length > 0
      ? defaultValues.map((ntt) => makeTweet(ntt))
      : [makeTweet()];

  const [tweets, dispatch] = useReducer(reducer, defaultTweets);

  const setText = useCallback(
    (id, newText) =>
      dispatch({
        type: ActionType.textChanged,
        payload: { id, newText },
      }),
    [dispatch],
  );

  const setAttachments = useCallback(
    (id, newAttachments) =>
      dispatch({
        type: ActionType.attachmentsChanged,
        payload: { id, newAttachments },
      }),
    [dispatch],
  );

  const setQuoteId = useCallback(
    (id, newQuoteId) =>
      dispatch({
        type: ActionType.quoteIdChanged,
        payload: { id, newQuoteId },
      }),
    [dispatch],
  );

  const setReplyId = useCallback(
    (newReplyId) =>
      dispatch({
        type: ActionType.replyIdChanged,
        payload: { newReplyId },
      }),
    [dispatch],
  );

  const addTweet = useCallback(
    () => dispatch({ type: ActionType.tweetAdded }),
    [dispatch],
  );
  const removeTweet = useCallback(
    (id: number) =>
      dispatch({ type: ActionType.tweetRemoved, payload: { id } }),
    [dispatch],
  );

  return {
    tweets,
    addTweet,
    removeTweet,
    setText,
    setAttachments,
    setQuoteId,
    setReplyId,
  };
};
