/** @jsxImportSource @emotion/react */

import React from 'react';

import ConfirmModal from './confirm-modal';

const DeleteDraftAlertModal: React.FC<
  {
    isOpen: boolean;
    onDismiss: () => void;
    onConfirm: () => void;
  } & { children?: React.ReactNode }
> = ({ isOpen, onDismiss, onConfirm }) => {
  return (
    <ConfirmModal
      isOpen={isOpen}
      dismissText="Go Back"
      confirmText="Confirm"
      title="Delete Drafts"
      message="You are about to delele an alerts draft. This action cannot be undone. Are you sure you want to continue?"
      onDismiss={onDismiss}
      onConfirm={onConfirm}
    />
  );
};

export default DeleteDraftAlertModal;
