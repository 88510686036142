import React from 'react';
import { ReactComponent as IconLogout } from '../../../images/icon_logout.svg';

import * as S from './UserInfo.styled';

const nameToLastInitial = (fullName: string): string => {
  const nameArray = fullName.split(/\s+/);
  const { length } = nameArray;
  return length > 1
    ? `${nameArray[0]} ${nameArray[length - 1].charAt(0)}.`
    : nameArray[0];
};

const UserInfo: React.FC<
  {
    onClick: () => void;
    name: string;
    navIsOpen: boolean;
  } & { children?: React.ReactNode }
> = ({ onClick, name = '', navIsOpen }) => (
  <S.UserInfo>
    {!!name && <S.UserName>{nameToLastInitial(name)}</S.UserName>}
    <S.UserButton tabIndex={!navIsOpen ? -1 : undefined} onClick={onClick}>
      <IconLogout width={20} height={20} />
    </S.UserButton>
  </S.UserInfo>
);

export default UserInfo;
