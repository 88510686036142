/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import alertIconSrc from 'images/icon_alert.svg';
import { ThemeType } from '../../../theme';

export const Warning: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <p
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px 12px;
      background-color: ${theme.colors['status-warning']};
      border-radius: 4px;

      ::before {
        content: '';
        background-image: url('${alertIconSrc}');
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: 3.4px 3px;
        background-color: #ffcf00;
        height: 16px;
        width: 16px;
        border-radius: 8px;
        margin-right: 8px;
      }
    `}
    {...props}
  />
);
