/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import theme, { ThemeSizes } from 'theme';

import MessageDisplay from 'components/common/message/MessageDisplay';
import Heading from 'components/common/Heading';
import { flexRow } from 'components/common/styles';
import EventStamp from 'components/common/event-stamp';

interface PreviousScreenMessageProps {
  authorName?: string;
  className?: string;
  title?: string;
  html?: string;
  headerLevel?: number;
  headerSize?: ThemeSizes;
  headerText?: string;
  publishAt?: string;
}

const PreviousScreenMessage: React.FC<PreviousScreenMessageProps> = ({
  title,
  html,
  headerText,
  headerLevel = 1,
  publishAt,
  authorName,
  className,
}) => {
  if (!html) {
    return null;
  }

  return (
    <div className={className}>
      <div
        css={[
          flexRow,
          css`
            align-items: center;
            margin-bottom: 12px;
          `,
        ]}
      >
        {headerText && (
          <Heading level={headerLevel} size="medium">
            {headerText}
          </Heading>
        )}
      </div>
      <div
        css={css`
          padding: 24px;
          border-radius: 4px;
          background-color: #f8f8f8;
        `}
      >
        <MessageDisplay
          css={css`
            background-color: #f8f8f8;
            padding: 0px;
          `}
          title={title}
          innerHtml={html}
        />
        {publishAt && (
          <div
            css={css`
              ${theme.typography.sizes.xsmall};
              margin-top: 8px;
            `}
          >
            <EventStamp date={new Date(publishAt)} name={authorName} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviousScreenMessage;
