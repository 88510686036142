/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import Button from 'components/common/Button';
import { Folders, FoldersVariables } from 'generated/Folders';
import {
  SearchableSocialMediaUploads,
  SearchableSocialMediaUploadsVariables,
} from 'generated/SearchableSocialMediaUploads';
import { CreateFolder, CreateFolderVariables } from 'generated/CreateFolder';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import * as Sentry from '@sentry/browser';

import { Toast, useToast, ToastLevel } from 'ui-kit/toast';
import AppLink, { ApplinkStyles } from 'components/common/app-link';
import { useFeedId } from 'contexts/FeedId';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import useQueryParams from '../../../hooks/useQueryParams';

import * as Layout from '../../common/layout';
import Loader from '../../common/skeletons/PageWithTable';
import Pagination from '../../common/Pagination';
import { ThemeType } from '../../../theme';
import Select from '../../common/form-elements/Select';
import { filterInput } from '../../common/styles';
import SearchInput from '../../common/form-elements/search-input';
import Modal from '../../common/modal';
import PageMeta from '../../common/PageMeta';
import PageHeading, { ButtonList } from '../../scaffolding/PageHeading';
import NoMessages from '../../common/NoMessages';
import FoldersView from '../../common/social-media-assets/Folders';
import AssetsView from '../../common/social-media-assets/Assets';

import {
  WEB_NO_ASSETS_OR_FOLDERS_MESSAGE,
  WEB_NO_ASSETS_OR_FOLDERS_HEADING,
  WEB_NO_ASSETS_MESSAGE_FILTERS,
  WEB_NO_ASSETS_HEADING_FILTERS,
} from '../../../constants/empty-states';

import * as S from './index.styled';
import { FeatureFlagName } from 'generated/global-types';

const FoldersQuery = loader('../../../graphql/Folders.gql');

const SearchableSocialMediaUploadsQuery = loader(
  '../../../graphql/SearchableSocialMediaUploads.gql',
);

const CreateFolderMutation = loader('../../../graphql/CreateFolder.gql');

const QUERY_PARAM_KEYS = {
  search: 'search',
  mime: 'mime',
  dimensionsRatio: 'dimensions_ratio',
};

const AVAILABLE_MIME_TYPES = [
  { value: '', label: 'All File Types' },
  { value: 'image/', label: 'Images' },
  { value: 'video/', label: 'Videos' },
];

const AVAILABLE_RESOLUTIONS = [
  { value: '', label: 'All Resolutions' },
  { value: '16:9', label: '16:9' },
  { value: '4:3', label: '4:3' },
  { value: '2:1', label: '2:1' },
  { value: '1:1', label: '1:1' },
];

const setQueryParams = (
  history: any,
  params: { [key: string]: string | number },
  scrollToTop = true,
) => {
  const query = new URLSearchParams(history.location.search);
  Object.entries(params).forEach(([name, value]) => {
    query.set(name, value as string);
  });
  history.push({ ...history.location, search: query.toString() });
  if (scrollToTop) {
    window.scrollTo(0, 0);
  }
};

const PAGE_SIZE = 12;

const SocialMediaAssets: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const queryParams = useQueryParams();
  const feedId = useFeedId();
  const pageParam = queryParams.get('page') || '';
  const page = Number.isNaN(parseInt(pageParam, 10))
    ? 1
    : parseInt(pageParam, 10);
  const offset = (page - 1) * PAGE_SIZE;
  const search = queryParams.get(QUERY_PARAM_KEYS.search) || '';
  const [searchValue, setSearchValue] = useState(search || '');
  const mime = queryParams.get(QUERY_PARAM_KEYS.mime) || '';
  const dimensionsRatio =
    queryParams.get(QUERY_PARAM_KEYS.dimensionsRatio) || '';
  const history = useHistory();

  const { shouldShowToast, showToast, hideToast } = useToast();
  const [toastText, setToastText] = useState('');

  const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);
  const [folderName, setFolderName] = useState('');

  const enableAssetsLibrary = useFeatureFlag(FeatureFlagName.ASSETS_CRUD);

  const [debouncedCallback] = useDebouncedCallback((value: string) => {
    setQueryParams(history, {
      [QUERY_PARAM_KEYS.search]: value,
      page: 1,
    });
  }, 500);

  const filtersApplied = !!(
    search?.length ||
    mime?.length ||
    dimensionsRatio?.length
  );

  const {
    loading: areFoldersLoading,
    error: foldersError,
    data: foldersData,
  } = useQuery<Folders, FoldersVariables>(FoldersQuery, {
    variables: { feedId },
    fetchPolicy: 'cache-and-network',
    skip: filtersApplied,
  });

  const {
    loading: areUploadsLoading,
    error: uploadsError,
    data: uploadsData,
  } = useQuery<
    SearchableSocialMediaUploads,
    SearchableSocialMediaUploadsVariables
  >(SearchableSocialMediaUploadsQuery, {
    variables: {
      offset,
      perPage: PAGE_SIZE,
      dimensionsRatio: dimensionsRatio || null,
      matches: search || null,
      mimeType: mime || null,
      feedId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [createFolder, { loading: isCreateFolderLoading }] = useMutation<
    CreateFolder,
    CreateFolderVariables
  >(CreateFolderMutation, {
    refetchQueries: ['Folders'],
    onError: (error: ApolloError) => {
      throw error;
    },
  });

  const loading = areFoldersLoading || areUploadsLoading;
  const error = foldersError || uploadsError;

  if (error) {
    return <div>{error.toString()}</div>;
  }

  const folders = foldersData?.socialMediaFolders?.nodes ?? [];
  const uploads = uploadsData?.searchSocialMediaUploads?.results ?? [];

  const addFolder = async () => {
    try {
      if (!folderName.length) {
        return;
      }
      await createFolder({ variables: { name: folderName, feedId } });
      setFolderName('');
      setIsAddFolderModalOpen(false);
      setToastText('Folder created successfully');
      showToast();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <Layout.Page>
      <PageMeta title="Social Media Assets" />
      <PageHeading title="Social Media" breadcrumbs={['Assets']}>
        <ButtonList>
          {enableAssetsLibrary && (
            <React.Fragment>
              <Button
                css={css`
                  margin-right: 15px;
                `}
                onClick={() => setIsAddFolderModalOpen(true)}
              >
                Add Folder
              </Button>
              <AppLink
                to={`/${feedId}/assets/compose?type=social-media`}
                styleVariations={[
                  ApplinkStyles.PRIMARY_BUTTON,
                  ApplinkStyles.LARGE_BUTTON,
                ]}
              >
                Add Asset
              </AppLink>
            </React.Fragment>
          )}
        </ButtonList>
      </PageHeading>
      <Toast
        shouldShow={shouldShowToast}
        onDismiss={hideToast}
        level={ToastLevel.success}
      >
        {toastText}
      </Toast>
      {loading && !(uploadsData || foldersData) ? (
        <div
          css={(theme) => css`
            padding: ${theme.spacing.small} ${theme.spacing.large};
          `}
        >
          <Loader loading={loading} />
        </div>
      ) : (
        <Layout.ContentWrapper
          css={(theme: ThemeType) => css`
            margin: ${theme.spacing.small} 0 0;
          `}
        >
          <form>
            <div
              css={css`
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
              `}
            >
              <SearchInput
                type="text"
                placeholder="Search social media assets"
                value={searchValue}
                name="query"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchValue(e.target.value);
                  debouncedCallback(e.target.value);
                }}
                onClear={() => {
                  setSearchValue('');
                  debouncedCallback('');
                }}
                extraStyles={filterInput}
              />
              <Select
                css={(theme: ThemeType) => css`
                  flex: 1;
                  background-color: ${theme.colors.white};
                `}
                onChange={({ value }: { value: string }) => {
                  setQueryParams(history, {
                    page: 1,
                    [QUERY_PARAM_KEYS.mime]: value,
                  });
                }}
                options={AVAILABLE_MIME_TYPES}
                value={AVAILABLE_MIME_TYPES.find(({ value }) => value === mime)}
                hasControlBoxShadow
              />
              <Select
                css={(theme: ThemeType) => css`
                  flex: 1;
                  background-color: ${theme.colors.white};
                  margin-left: 10px;
                `}
                onChange={({ value }: { value: string }) => {
                  setQueryParams(history, {
                    page: 1,
                    [QUERY_PARAM_KEYS.dimensionsRatio]: value,
                  });
                }}
                options={AVAILABLE_RESOLUTIONS}
                value={AVAILABLE_RESOLUTIONS.find(
                  ({ value }) => value === dimensionsRatio,
                )}
                hasControlBoxShadow
              />
              <Button
                type="button"
                size="xxsmall"
                css={css`
                  margin-left: 10px;
                `}
                disabled={!filtersApplied}
                onClick={() => {
                  setSearchValue('');
                  setQueryParams(history, {
                    page: 1,
                    [QUERY_PARAM_KEYS.search]: '',
                    [QUERY_PARAM_KEYS.mime]: '',
                    [QUERY_PARAM_KEYS.dimensionsRatio]: '',
                  });
                }}
              >
                Clear
              </Button>
            </div>
          </form>
          {uploads.length || folders.length ? (
            <React.Fragment>
              <S.Container>
                <FoldersView
                  folders={folders}
                  onFolderClick={(folder) =>
                    history.push(`/${feedId}/folders/${folder.id}`)
                  }
                />
                <AssetsView
                  title={
                    !!uploads.length && (
                      <h3>{filtersApplied ? 'Results' : 'Assets'}</h3>
                    )
                  }
                  assets={uploads}
                  onAssetClick={(asset) => {
                    if (enableAssetsLibrary) {
                      history.push(
                        `/${feedId}/assets/${asset?.id}?type=social-media`,
                      );
                    }
                  }}
                />
              </S.Container>
              {!!uploads.length && (
                <Pagination
                  currentPage={page}
                  itemsPerPage={PAGE_SIZE}
                  totalItems={
                    uploadsData?.searchSocialMediaUploads?.totalCount ?? 0
                  }
                  onPrevClick={() =>
                    setQueryParams(history, { page: page - 1 })
                  }
                  onNextClick={() =>
                    setQueryParams(history, { page: page + 1 })
                  }
                />
              )}
            </React.Fragment>
          ) : (
            <NoMessages
              css={css`
                margin-top: 45px;
              `}
              icon={filtersApplied}
              heading={
                filtersApplied
                  ? WEB_NO_ASSETS_HEADING_FILTERS
                  : WEB_NO_ASSETS_OR_FOLDERS_HEADING
              }
              message={
                filtersApplied
                  ? WEB_NO_ASSETS_MESSAGE_FILTERS
                  : WEB_NO_ASSETS_OR_FOLDERS_MESSAGE
              }
            />
          )}
        </Layout.ContentWrapper>
      )}
      <Modal
        isOpen={isAddFolderModalOpen}
        title="Add Folder"
        onDismiss={() => setIsAddFolderModalOpen(false)}
      >
        <form>
          <S.Input
            id="folder-name"
            name="folder-name"
            type="text"
            placeholder="Folder name"
            value={folderName}
            onChange={(evt) => setFolderName(evt.target.value)}
            required
            autoFocus
          />
          <div
            css={css`
              margin-top: 25px;
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button
              css={css`
                width: 160px;
                margin-right: 15px;
              `}
              size="medium"
              onClick={() => setIsAddFolderModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              css={css`
                width: 160px;
              `}
              primary
              size="medium"
              disabled={isCreateFolderLoading}
              onClick={async (e) => {
                e.preventDefault();
                addFolder();
              }}
            >
              Create
            </Button>
          </div>
        </form>
      </Modal>
    </Layout.Page>
  );
};

export default SocialMediaAssets;
