/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import {
  components,
  ValueContainerProps,
  OptionProps,
  PlaceholderProps,
} from 'react-select';
import theme from 'theme';
import {
  MessageDaysOfWeek,
  MessageTimeOfDay,
  Days,
} from 'generated/global-types';
import InputLabel from './input-label';
import Select from './Select';
import Checkbox, { CheckboxSize } from '../Checkbox';

export type DispSelectOption = {
  value: any;
  label: string;
};

const daysComponents = {
  Option: (props: OptionProps<any>) => {
    const {
      innerProps,
      isSelected,
      data: { label },
    } = props;
    return (
      <div {...innerProps} style={props.getStyles('option', props) as any}>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <Checkbox
            css={css`
              margin-right: 8px;
            `}
            size={CheckboxSize.medium}
            checked={isSelected}
            onChange={() => {}}
          />
          {label && (
            <span
              css={css`
                ${theme.typography.sizes.medium};
                font-family: ${theme.typography.families.primary};
                font-size: ${theme.typography.sizes.medium.fontSize};
              `}
            >
              {label}
            </span>
          )}
        </div>
      </div>
    );
  },
  ValueContainer: ({ children, ...rest }: ValueContainerProps<any>) => {
    const selectedDaysLabels = (rest.getValue() as any[])
      .map((o) => o.label)
      .sort();

    return (
      <components.ValueContainer {...rest}>
        <span
          css={css`
            ${theme.typography.sizes.medium};
            font-family: ${theme.typography.families.primary};
            color: #0f61a9;
            font-size: ${theme.typography.sizes.medium.fontSize};
          `}
        >
          {selectedDaysLabels.length
            ? `${selectedDaysLabels[0]}${
                selectedDaysLabels.length > 1
                  ? ` +${selectedDaysLabels.length - 1} more`
                  : ''
              }`
            : ''}
        </span>
        {children}
      </components.ValueContainer>
    );
  },
  Placeholder: (props: PlaceholderProps<any>) => {
    const selectedOptions = props.getValue();
    return selectedOptions.length ? null : (
      <components.Placeholder {...props} />
    );
  },
};

const DISP_WEEK = [
  {
    value: MessageDaysOfWeek.WEEKDAYS,
    label: 'Weekdays',
  },
  {
    value: MessageDaysOfWeek.WEEKEND,
    label: 'Weekend',
  },
  {
    value: MessageDaysOfWeek.INDIVIDUAL_DAYS,
    label: 'Individual Days',
  },
];

const DISP_DAY_PERIOD = [
  {
    value: MessageTimeOfDay.DAYS,
    label: 'Days',
  },
  {
    value: MessageTimeOfDay.NIGHTS,
    label: 'Nights',
  },
];

const DISP_DAYS = [
  {
    value: Days.MONDAY,
    label: 'Monday',
  },
  {
    value: Days.TUESDAY,
    label: 'Tuesday',
  },
  {
    value: Days.WEDNESDAY,
    label: 'Wednesday',
  },
  {
    value: Days.THURSDAY,
    label: 'Thursday',
  },
  {
    value: Days.FRIDAY,
    label: 'Friday',
  },
  {
    value: Days.SATURDAY,
    label: 'Saturday',
  },
  {
    value: Days.SUNDAY,
    label: 'Sunday',
  },
];

export const getDispWeekOption = ({
  daysOfWeek,
  timeOfDay,
  day,
}: {
  daysOfWeek: MessageDaysOfWeek | undefined;
  timeOfDay: MessageTimeOfDay | undefined;
  day: string[];
}) => {
  return {
    daysOfWeek: DISP_WEEK.find((o) => o.value === daysOfWeek),
    timeOfDay: DISP_DAY_PERIOD.find((o) => o.value === timeOfDay),
    day: DISP_DAYS.filter((item) => day?.includes(item.value)),
  };
};

const MainContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <div {...props} />;

const DisplayDaysOfWeekSelector: React.FC<
  {
    id?: string;
    onChange: (
      type: string,
      option: DispSelectOption | DispSelectOption[],
      resetDays?: boolean,
    ) => void;
    weekParams: {
      daysOfWeek?: DispSelectOption;
      timeOfDay?: DispSelectOption;
      day?: DispSelectOption[];
    };
  } & { children?: React.ReactNode }
> = ({ id = 'display-frequency', onChange, weekParams }) => {
  return (
    <MainContainer>
      <InputLabel
        htmlFor={id}
        label="Days of Week"
        css={css`
          display: inline-flex;
          width: 50%;
        `}
      >
        <Select
          id={id}
          css={css`
            > div {
              border-radius: 4px 0px 0px 4px;
            }
          `}
          styles={{
            singleValue: (
              provided: {},
              state: {
                selectProps: {
                  menuIsOpen: boolean;
                };
              },
            ) => {
              return {
                ...provided,
                color: '#0F61A9',
                fontSize: theme.typography.sizes.medium.fontSize,
                lineHeight: theme.typography.sizes.medium.lineHeight,
                fontFamily: theme.typography.families.primary,
                fontWeight: state.selectProps.menuIsOpen
                  ? theme.typography.weights.bold
                  : theme.typography.weights.normal,
              };
            },
          }}
          options={DISP_WEEK}
          isSearchable={false}
          onChange={(option: DispSelectOption) => {
            onChange(
              'daysOfWeek',
              option,
              option.value !== MessageDaysOfWeek.INDIVIDUAL_DAYS,
            );
          }}
          value={weekParams.daysOfWeek}
        />
      </InputLabel>
      <InputLabel
        htmlFor={id}
        label="Time of Day"
        css={css`
          display: inline-flex;
          width: 50%;
        `}
      >
        <Select
          id={id}
          css={css`
            > div {
              border-radius: 0px 4px 4px 0px;
              border-left: 0;
            }
          `}
          styles={{
            singleValue: (
              provided: {},
              state: {
                selectProps: {
                  menuIsOpen: boolean;
                };
              },
            ) => {
              return {
                ...provided,
                color: '#0F61A9',
                fontSize: theme.typography.sizes.medium.fontSize,
                lineHeight: theme.typography.sizes.medium.lineHeight,
                fontFamily: theme.typography.families.primary,
                fontWeight: state.selectProps.menuIsOpen
                  ? theme.typography.weights.bold
                  : theme.typography.weights.normal,
              };
            },
          }}
          options={DISP_DAY_PERIOD}
          isSearchable={false}
          onChange={(option: DispSelectOption) => {
            onChange('timeOfDay', option);
          }}
          value={weekParams.timeOfDay}
        />
      </InputLabel>

      {weekParams.daysOfWeek?.value === MessageDaysOfWeek.INDIVIDUAL_DAYS && (
        <Select
          css={css`
            margin-top: 8px;
          `}
          id="email-type-select"
          placeholder="Select Individual Days(s)"
          isMulti
          options={DISP_DAYS}
          value={weekParams.day}
          isSearchable={false}
          isClearable={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          onChange={(newOptions: any[]) => {
            onChange('day', newOptions);
          }}
          components={daysComponents}
        />
      )}
    </MainContainer>
  );
};

export default DisplayDaysOfWeekSelector;
