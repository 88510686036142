import ms from 'ms.macro';
import { useState, useEffect } from 'react';

const useShouldShowSkeleton = (
  loading: boolean,
  { after = ms('200ms') }: { after?: number } = {},
) => {
  const [afterTimeElapsed, setAfterTimeElapsed] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (loading) {
      const pid = window.setTimeout(() => {
        setAfterTimeElapsed(true);
      }, after);
      return () => window.clearTimeout(pid);
    }
  }, [loading, after]);

  return loading && afterTimeElapsed;
};

export default useShouldShowSkeleton;
