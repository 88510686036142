/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import {
  ListboxInput,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox';
import StandardListboxButton from './standard-listbox-button';
import { useRoutesByFeedId } from '../contexts/Routes';
import Bullet, { BulletSize } from '../components/common/Bullet';
import { listboxList } from '../components/common/styles';
import theme from '../theme';
import { useFeedId } from 'contexts/FeedId';

const RouteSelector: React.FC<
  {
    id?: string;
    value?: string | null;
    placeholder?: string;
    onChange?: (arg0: string) => void;
  } & { children?: React.ReactNode }
> = ({ id, value, placeholder, onChange = () => {}, ...rest }) => {
  const feedId = useFeedId();
  const routes = useRoutesByFeedId(feedId);

  return (
    <ListboxInput
      id={id}
      css={css`
        min-width: 200px;

        [data-reach-listbox-button] {
          box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
        }

        &[data-state='expanded'],
        &:focus-within {
          [data-reach-listbox-button] {
            box-shadow:
              0 0 0 1px ${theme.colors.accent3},
              inset 0 0 4px ${theme.colors['border-dark']};
            z-index: 1;
            div {
              color: ${theme.colors.accent1};
            }
          }
        }

        &[data-state='expanded'] {
          [data-reach-listbox-button] {
            border-bottom: 0;
          }
        }
      `}
      value={value || ''}
      onChange={onChange}
      {...rest}
    >
      <StandardListboxButton>
        {(value && (
          <Bullet
            css={css`
              margin: 1.5px 0 1.5px;
            `}
            routeId={value}
          />
        )) ||
          (placeholder && (
            <div
              css={css`
                color: #707070;
              `}
            >
              {placeholder}
            </div>
          ))}
      </StandardListboxButton>
      <ListboxPopover>
        <ListboxList
          css={css`
            ${listboxList}
            border-top: 1px solid ${theme.colors.light9};
            padding: 5px;
            padding-right: 40px;
            padding-bottom: 15px;
            margin: -1px;
            [data-reach-listbox-option] {
              display: inline-block;
              margin: 7px -10px -4px 2px;
            }
          `}
        >
          {routes.map((route) => (
            <ListboxOption key={route.gtfsId} value={route.gtfsId}>
              <Bullet size={BulletSize.large} routeId={route.gtfsId} />
            </ListboxOption>
          ))}
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  );
};

export default RouteSelector;
