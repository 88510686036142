/** @jsxImportSource @emotion/react */

import { css, jsx } from '@emotion/react';

import {
  ThemeType,
  ThemeSizes,
  ThemeFonts,
  ThemeFontWeights,
} from '../../theme';

export enum headingLevel {
  'l1' = 1,
  'l2' = 2,
  'l3' = 3,
  'l4' = 4,
  'l5' = 5,
  'l6' = 6,
}

const levelMap: { [key in headingLevel]: ThemeSizes } = {
  1: 'xxlarge',
  2: 'xlarge',
  3: 'large',
  4: 'medium',
  5: 'small',
  6: 'xsmall',
};

export interface HeadingProps {
  level: headingLevel;
  id?: string;
  size?: ThemeSizes;
  color?: string;
  font?: ThemeFonts;
  weight?: ThemeFontWeights;
}

const Heading: React.FC<HeadingProps & { children?: React.ReactNode }> = ({
  level,
  color = 'black',
  children,
  font = 'primary',
  weight = 'bold',
  size = levelMap[level],
  ...rest
}) =>
  jsx(
    `h${level}`,
    {
      css: (theme: ThemeType) => [
        css`
          margin: 0;
          color: ${theme.colors[color] ? theme.colors[color] : color};
          font-family: ${theme.typography.families[font]};
          font-weight: ${theme.typography.weights[weight]};
        `,
        theme.typography.sizes[size],
      ],
      ...rest,
    },
    children,
  );

export default Heading;
