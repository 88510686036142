/** @jsxImportSource @emotion/react */

import React from 'react';

import { PlannedWorkStatus } from '../../../../generated/global-types';
import PlannedWorkPage from '../../../common/planned-work/planned-work-page';

const PlannedWork: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const useQueryVars = [PlannedWorkStatus.CLEARED, PlannedWorkStatus.REMOVED];

  return (
    <PlannedWorkPage
      statuses={useQueryVars}
      page="cleared"
      pageTitle="Cleared & Cancelled"
    />
  );
};

export default PlannedWork;
