/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import Heading from 'components/common/Heading';
import ScreenUploadPreview from 'ui-kit/screen-upload-preview';
import { Screen_screen2_Screens2_currentScreenLock_ScreenLock_upload_Upload as ScreenLock } from 'generated/Screen';

const LockedContent: React.FC<
  {
    screenLock?: ScreenLock | null;
    height: number;
    width: number;
    scale?: number;
    zipContentsScale?: number;
  } & { children?: React.ReactNode }
> = ({ screenLock, height, width, scale, zipContentsScale }) => {
  return (
    <React.Fragment>
      <Heading level={3}>Screen Locked</Heading>
      <div
        css={css`
          margin-top: 16px;
        `}
      >
        <ScreenUploadPreview
          upload={screenLock}
          dimensions={{ height, width }}
          filenameSize="xsmall"
          scale={scale}
          zipContentsScale={zipContentsScale}
          showUpdatedAt
          thumbnailUrl={
            screenLock?.lockableUploadByUploadId?.thumbnail?.signedS3Url
          }
        />
      </div>
    </React.Fragment>
  );
};

export default LockedContent;
