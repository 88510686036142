/** @jsxImportSource @emotion/react */

import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import theme from 'theme';
import { SchedulePriority } from 'generated/global-types';

const weightPriorityPillStyles = css`
  ${theme.typography.sizes.small};
  font-weight: ${theme.typography.weights.normal};
  border-radius: 12px;
  padding: 4px 8px;
  background: #f1f1f1;
`;

const weightSpecificStyles: { [key: number]: SerializedStyles } = {
  1: css`
    background: #f1f1f1;
  `,
  2: css`
    background: #eaeaea;
  `,
  3: css`
    background: #dddddd;
  `,
  4: css`
    background: #cccccc;
  `,
  5: css`
    background: #aaaaaa;
  `,
};

const priorityLabels = {
  [SchedulePriority.TAKEOVER]: 'Takeover',
};

const WeightPriorityPill: React.FC<{
  priority: SchedulePriority;
  weight: number;
  additionalCss?: SerializedStyles;
  children?: React.ReactNode;
}> = ({ priority, weight, additionalCss = css`` }) => {
  if (priority === SchedulePriority.TAKEOVER) {
    return (
      <span
        css={[
          weightPriorityPillStyles,
          css`
            background: #ffcf00;
          `,
          additionalCss,
        ]}
      >
        {priorityLabels[priority]}
      </span>
    );
  }

  return (
    <span
      css={[
        weightPriorityPillStyles,
        weightSpecificStyles[weight],
        additionalCss,
      ]}
    >
      {weight}x
    </span>
  );
};

export default WeightPriorityPill;
