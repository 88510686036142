import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useFormContext } from 'react-hook-form';
import { toSentence } from 'underscore.string';
import {
  prosemirrorNodeToHtml as toHTML,
  schemaToJSON,
  EditorState,
  prosemirrorNodeToDom,
  SchemaJSON,
  prosemirrorNodeToHtml,
} from 'remirror';
import {
  formatMessageDoc,
  getGtfsTextReplacement,
  BulletType,
  GTFS_RT_TEXT_REPLACEMENTS,
  LONG_GTFS_TEXT_REPLACEMENTS,
  MESSAGE_TYPE_DESCRIPTIONS,
} from '@mta-live-media-manager/shared';
import uniqBy from 'lodash/uniqBy';
import * as Sentry from '@sentry/browser';

import { Tweet } from 'components/common/tweets/tweet-thread-input';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { sanitizeHTML } from 'utils/compose-helpers';
import { isUploadFileContainer } from 'components/common/tweets/tweet-input/image-attachments';
import { TargetingOptionType } from 'utils/stop-selector-helpers';

import {
  ComposeFormData,
  Mention,
  TImpact,
  RouteMention,
  ScreenUpdateMethods,
  ScreenTarget,
  ScreenData,
} from '../types';
import {
  MessagePatchRecordInput,
  ScreenMessagePatchRecordInput,
  ScreenSelectorInput,
  GtfsEntitySelectorInput,
  MessageType,
  EventPatchRecordInput,
  TweetPatchRecordInput,
  EmailMessageTypeCodes,
  SchedulePriority,
  Borough,
  TargetType,
  MessagesOrderBy,
  FeatureFlagName,
} from '../generated/global-types';
import { useFeedId } from '../contexts/FeedId';
import { GetEventById_event_Event as GetEventById_event } from '../generated/GetEventById';
import { BOROUGH_ABBREVIATIONS } from '../utils/boroughs';

import { isTrain } from '../utils/feed-switches';
import { CreateEvent, CreateEventVariables } from '../generated/CreateEvent';
import {
  AddUpdateToEvent,
  AddUpdateToEventVariables,
} from '../generated/AddUpdateToEvent';
import { getAllRoutesFromImpacts } from '../components/common/compose/impact-selector';
import { FeedId } from '../types/feeds';
import {
  BT_ROUTE_TYPE,
  BT_ROUTE_TYPES,
  MNR_ROUTE_TYPE,
  MNR_ROUTE_TYPES,
} from '../constants/routes';
import { getMentionsByType } from '../components/common/form-elements/editor';
import {
  WEB_EXPIRATION_NAME,
  WEB_IMPACTS_NAME,
  WEB_TARGETED_STOPS_NAME,
  SCREEN_BODY_NAME,
  SCREEN_TARGETING_NAME,
  WEB_BODY_NAME,
  EMAIL_SUBJECT_NAME,
  TWEETS_NAME,
  INTERNAL_LOG_INCIDENT_START_AT,
  INTERNAL_LOG_INCIDENT_END_AT,
  INTERNAL_LOG_NOTIFIED_AT,
  INTERNAL_LOG_NOTES,
  SCREEN_UPDATE_METHOD_NAME,
  EMAIL_BODY_NAME,
  WEB_BODY_ADDITIONAL_NAME,
  SCREEN_BODY_ADDITIONAL_NAME,
  AFFECTED_STOPS_NAME,
  SCREEN_TITLE_NAME,
  AFFECTED_STOPS_EDITED_NAME,
  WEIGHT_FREQUENCY_NAME,
  SCREENS_NAME,
  IGNORED_TRIPS_ROUTES,
  SMS_BODY_NAME,
  INTERNAL_LOG_NEEDED_BY,
} from '../constants/alerts';
import {
  constructPlainTextEmailBody,
  constructSMS,
} from 'utils/message-creation-utils';

const CreateEventMutation = loader('../graphql/CreateEvent.gql');
const AddUpdateToEventMutation = loader('../graphql/AddUpdateToEvent.gql');
const GetEventByIdQuery = loader('../graphql/GetEventById.gql');

type UseMessageCreationOptions = {
  updatingEvent?: GetEventById_event;
  onComplete?: (
    feedId: string | undefined,
    eventId: number | undefined,
  ) => void;
  enabledOutlets: {
    twitter: boolean;
    screens: boolean;
    email: boolean;
    apps: boolean;
  };
};

const getVehicleName = (feedId: FeedId): string => {
  if (feedId === 'nyct-bus') {
    return 'Bus';
  }
  if (feedId === 'lirr' || feedId === 'mnr') {
    return 'Branch';
  }
  return 'Train';
};

type EmailBodyMeta = {
  doc: any;
  schema: SchemaJSON<string, string>;
  html: string;
  text: string;
  mentions: string;
};

/**
 * Converts a ProseMirror message to an HTML string, replacing entities
 * with bracketed routes or bolded text.
 *
 * @param message - The body of an email message
 * @returns HTML string of the message
 */
export const formatMessageForEmail = (
  message: EditorState,
  feedId: string,
): string => {
  const emailDOM = prosemirrorNodeToDom(message.doc);
  const bullets = emailDOM.querySelectorAll('span.mention');

  bullets.forEach((bullet) => {
    const replacements = GTFS_RT_TEXT_REPLACEMENTS;
    const bulletType = bullet.getAttribute('data-bullet-type');
    const bulletTitle = bullet.getAttribute('data-bullet-title');
    const isRoute = bulletType === BulletType.Route;
    const isCustomIcon =
      bulletType === BulletType.ADA ||
      bulletType === BulletType.Airplane ||
      bulletType === BulletType.ShuttleBus;
    const isSubway = feedId === FeedId.NYCTSubway;

    let id;
    if (bulletType === BulletType.Route) {
      id = bullet.getAttribute('data-bullet-route-id') || undefined;
    }

    if (bulletType === BulletType.Trip) {
      id = bullet.getAttribute('data-bullet-trip-id') || undefined;
    }

    if (bulletType === BulletType.Stop) {
      id = bullet.getAttribute('data-bullet-stop-id') || undefined;
    }

    if (isCustomIcon) {
      id = bullet.getAttribute('data-bullet-display') || undefined;
    }

    const text = (id && replacements?.[id]) || bulletTitle || '';

    // if this data attribute exists, it means it is a cross-agency mention
    const agency = bullet.getAttribute('data-bullet-agency');
    const isNYCTCrossAgency = agency === FeedId.NYCTSubway;
    const subwayMention = id?.toLowerCase().startsWith('mtasbwy');

    const element = subwayMention && isRoute ? 'span' : 'strong';
    const newElement = document.createElement(element);
    // brackets should only apply to cross-agency SUBWAY tags and non-cross-agency SUBWAY tags
    const needsBracket =
      isRoute && (isNYCTCrossAgency || (isSubway && !agency));
    // add brackets to custom icons for emails.
    if (isCustomIcon || needsBracket) {
      if (isCustomIcon) {
        const iconName =
          text === 'ADA'
            ? 'accessibility'
            : text.toLowerCase().replace(/_/, ' ');
        newElement.innerHTML = `[${iconName} icon]`;
      } else {
        newElement.innerHTML = agency === FeedId.NYCTBus ? text : `[${text}]`;
      }
    } else {
      newElement.innerHTML = text;
    }

    bullet.parentNode?.replaceChild(newElement, bullet);
  });

  const div = document.createElement('div');
  div.appendChild(emailDOM);
  return div.innerHTML;
};

export function useMessageCreation<FormDataType extends ComposeFormData>({
  updatingEvent,
  onComplete,
  enabledOutlets,
}: UseMessageCreationOptions) {
  const feedId = useFeedId();
  const [error, setError] = useState<Error | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [createEvent] = useMutation<CreateEvent, CreateEventVariables>(
    CreateEventMutation,
  );
  const [addUpdateToEvent] = useMutation<
    AddUpdateToEvent,
    AddUpdateToEventVariables
  >(AddUpdateToEventMutation);
  const canTargetScreens = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
  );
  const isEditableSmsEnabled = useFeatureFlag(FeatureFlagName.EDITABLE_SMS);

  const {
    twitter: showTwitter,
    screens: showScreens,
    email: showEmail,
    apps: showApps,
  } = enabledOutlets;
  const previousMessage = updatingEvent && updatingEvent.messages.nodes[0];
  const previousScreenMessages = previousMessage?.screenMessages.nodes;
  const previouslyTargetedApps = previousMessage?.includeApps || false;

  const onSubmit = async (formData: FormDataType, isDraft: boolean = false) => {
    try {
      setIsSubmitting(true);
      setIsDraft(isDraft);

      // There has to be a cleaner way to do this, right?
      // react-hook-form seems to really like empty strings.
      const optionalString = (input: string): string | null =>
        input && input.length > 0 ? input : null;

      const screens: ScreenMessagePatchRecordInput[] = [];

      const webExpiration: any = formData[WEB_EXPIRATION_NAME];

      formData[SCREENS_NAME]?.filter((s) => !s?.screenIsClearing)?.forEach(
        (screen: ScreenData, index: number) => {
          const screenExpiration =
            screen[SCREEN_UPDATE_METHOD_NAME] !== ScreenUpdateMethods.REMOVE
              ? webExpiration
              : null;

          const screenBody = screen[SCREEN_BODY_NAME];
          const screenAdditionalBody = screen[SCREEN_BODY_ADDITIONAL_NAME];

          /**
           * Three possible ways for the screen targeting/message to be updated:
           * 1. Screens section is toggled on and the secondary toggle set to update.
           *    We'll use the new message and targeting for the new screen message.
           * 2. Screens section is toggled on and the secondary toggle is set to remove.
           *    We'll send up an empty targeting array to remove the screen message.
           * 3. Screens section is toggled off, but this is an update with a previous screen message.
           *    Grab the previous screen targeting and send it up.
           */
          if (
            canTargetScreens &&
            isTrain(feedId) &&
            (previousScreenMessages?.[index] || showScreens)
          ) {
            const {
              screenTitle,
              screenBodyText,
              screenBodyHtml,
              priority,
              weight,
            } = (() => {
              if (
                (previousScreenMessages?.[index] && !showScreens) ||
                screen[SCREEN_UPDATE_METHOD_NAME] === ScreenUpdateMethods.KEEP
              ) {
                return {
                  screenTitle: previousScreenMessages?.[index].title,
                  screenBodyText: previousScreenMessages?.[index].body,
                  screenBodyHtml: previousScreenMessages?.[index].bodyHtml,
                  priority: previousScreenMessages?.[index].priority,
                  weight: previousScreenMessages?.[index].weight,
                };
              }

              return {
                screenTitle: screen[SCREEN_TITLE_NAME],
                // Removing zwnj characters as they are being incorrectly parsed to '‚Äå' in Excel.
                screenBodyText: screenBody
                  ? screenBody.doc.textContent.replace(/\u200c/g, '')
                  : previousScreenMessages?.[index].body,
                screenBodyHtml: screenBody
                  ? sanitizeHTML(toHTML(screenBody.doc), {
                      addHeightToEmptyParagraph: false,
                    })
                  : previousScreenMessages?.[index].bodyHtml,
                priority:
                  screen[WEIGHT_FREQUENCY_NAME]?.priority ??
                  SchedulePriority.NORMAL,
                weight: !screen[SCREEN_TARGETING_NAME].some((st) =>
                  st.layouts.includes(TargetType.DUP),
                )
                  ? screen[WEIGHT_FREQUENCY_NAME]?.weight
                  : 1,
              };
            })();

            const { screenAdditionalInfoText, screenAdditionalInfoHtml } =
              (() => {
                if (
                  (previousScreenMessages?.[index] && !showScreens) ||
                  screen[SCREEN_UPDATE_METHOD_NAME] === ScreenUpdateMethods.KEEP
                ) {
                  return {
                    screenAdditionalInfoText:
                      previousScreenMessages?.[index].additionalInfo,
                    screenAdditionalInfoHtml:
                      previousScreenMessages?.[index].additionalInfoHtml,
                  };
                }
                if (
                  screenAdditionalBody &&
                  screenAdditionalBody?.doc.textContent.length
                ) {
                  return {
                    screenAdditionalInfoText: formatMessageDoc(
                      screenAdditionalBody.doc,
                      {
                        bracketRoutes: false,
                        bracketCustomIcons: true,
                        enforceSpacesBetweenEntities: true,
                        replacements: LONG_GTFS_TEXT_REPLACEMENTS,
                      },
                    ),
                    screenAdditionalInfoHtml: sanitizeHTML(
                      toHTML(screenAdditionalBody.doc),
                      {
                        addHeightToEmptyParagraph: false,
                      },
                    ),
                  };
                }

                return {
                  screenAdditionalInfoText: undefined,
                  screenAdditionalInfoHtml: undefined,
                };
              })();

            const screenTargeting = screen[
              SCREEN_TARGETING_NAME
            ] as ScreenTarget[];
            const targeting: Array<ScreenSelectorInput> = (() => {
              if (
                showScreens &&
                screenTargeting &&
                screenTargeting.length > 0 &&
                screen[SCREEN_UPDATE_METHOD_NAME] !== ScreenUpdateMethods.KEEP
              ) {
                return screenTargeting
                  .filter((t) => t.options)
                  .reduce((agg: Array<ScreenSelectorInput>, target) => {
                    const newTargets: Array<ScreenSelectorInput> = [];
                    const entireRouteOption = target.options.find(
                      (o) =>
                        o.optionType === TargetingOptionType.ALL_ROUTE_OPTION,
                    );
                    const entireBoroughsSelected = new Set(
                      target.options.reduce<string[]>(
                        (selectedBoroughs, option) => {
                          if (
                            option.optionType ===
                            TargetingOptionType.ALL_BOROUGH_OPTION
                          ) {
                            selectedBoroughs.push(option.key);
                          }

                          return selectedBoroughs;
                        },
                        [],
                      ),
                    );

                    if (entireRouteOption) {
                      newTargets.push({
                        entitySelector: { routeId: entireRouteOption.routeId },
                        layouts: target.layouts,
                        routeId: target.routeId,
                        tags: target.tags,
                      });
                    } else {
                      target.options.forEach((option) => {
                        if (
                          option.optionType === TargetingOptionType.STOP_OPTION
                        ) {
                          const newTarget = {
                            entitySelector: { stopId: option.stopId },
                            layouts: target.layouts,
                            routeId: target.routeId,
                            tags: target.tags,
                          } as ScreenSelectorInput;

                          if (
                            entireBoroughsSelected.has(option.borough ?? '')
                          ) {
                            newTarget.borough = option.borough as Borough;
                          }

                          newTargets.push(newTarget);
                        }
                      });
                    }
                    return [...agg, ...newTargets];
                  }, []);
              }
              if (previousScreenMessages && previousScreenMessages[index]) {
                return previousScreenMessages[index].targeting.flatMap((t) => ({
                  entitySelector: {
                    routeId: t.entitySelector.routeId,
                    stopId: t.entitySelector.stopId,
                  },
                  layouts: t.layouts,
                  routeId: t.routeId,
                  borough: t.borough,
                  tags: t.tags,
                }));
              }

              return [];
            })();

            const data = {
              targeting,
              title: screenTitle || null,
              body: screenBodyText,
              bodyHtml: screenBodyHtml,
              additionalInfo: screenAdditionalInfoText,
              additionalInfoHtml: screenAdditionalInfoHtml,
              endAt: screenExpiration,
              priority,
              weight,
            };

            if (
              isTrain(feedId) &&
              targeting.length &&
              screen[SCREEN_UPDATE_METHOD_NAME] !== ScreenUpdateMethods.REMOVE
            ) {
              screens.push(data);
            }
          }
        },
      );

      /**
       * Like screen messages, we want to carry on GTFS alerts when toggled off on update.
       * Three situations:
       * 1. New alert -> Use current message body & related metadata
       * 2. Alert update & apps toggled off -> Use previous message body (keep apps on) & previous metadata
       * 3. Alert update & apps toggled on -> Use current message body & new metadata
       */
      const {
        affectedStations,
        body,
        additionalInfo,
        impacts,
        entitySelectors,
        includeApps,
      } = (() => {
        if (!showApps && previouslyTargetedApps && previousMessage) {
          return {
            body: {
              doc: previousMessage.body.doc,
              schema: previousMessage.body.schema,
              html: sanitizeHTML(previousMessage.body.html),
              text: previousMessage.body.text,
              mentions: previousMessage.body.mentions,
            },
            additionalInfo: previousMessage?.additionalInfo
              ? {
                  doc: previousMessage.additionalInfo.doc,
                  schema: previousMessage.additionalInfo.schema,
                  html: sanitizeHTML(previousMessage.additionalInfo.html),
                  text: previousMessage.additionalInfo.text,
                }
              : undefined,
            affectedStations: previousMessage.affectedStations?.map((af) => ({
              direction: af.direction,
              entitySelector: {
                stopId: af.entitySelector?.stopId,
              },
            })),
            impacts: previousMessage.messageImpacts.nodes.map((impact) => ({
              messageType: impact.messageType,
              entitySelectors: impact.entitySelectors.map((es) => ({
                routeId: es?.routeId,
                stopId: es?.stopId,
              })),
              isAgencyWide: impact.isAgencyWide,
            })),
            entitySelectors: previousMessage.entitySelectors.map((es) => ({
              routeId: es.routeId,
              stopId: es.stopId,
            })),
            includeApps: !isDraft,
          };
        }

        const newBody = formData[WEB_BODY_NAME];
        const newAdditionalBody = formData[WEB_BODY_ADDITIONAL_NAME];
        const newImpacts = formData[WEB_IMPACTS_NAME];

        const affectedStations: MessagePatchRecordInput['affectedStations'] =
          (() => {
            const formAffectedStops = formData[AFFECTED_STOPS_NAME];
            const affectedStationsEdited = formData[AFFECTED_STOPS_EDITED_NAME];
            if (!affectedStationsEdited || !formAffectedStops) {
              return null;
            }

            return formAffectedStops.gtfsStopIds
              ? formAffectedStops.gtfsStopIds.map((stopId) => ({
                  direction: formAffectedStops.directionality,
                  entitySelector: { stopId },
                }))
              : [];
          })();

        const newAdditionalInfo =
          newAdditionalBody && newAdditionalBody?.doc.textContent.length
            ? {
                doc: newAdditionalBody.doc.toJSON(),
                schema: schemaToJSON(newAdditionalBody.schema),
                html: sanitizeHTML(toHTML(newAdditionalBody.doc)),
                text: formatMessageDoc(newAdditionalBody.doc, {
                  bracketRoutes: false,
                  bracketCustomIcons: true,
                  enforceSpacesBetweenEntities: true,
                  replacements: LONG_GTFS_TEXT_REPLACEMENTS,
                }),
              }
            : undefined;

        /**
         * The entity selectors attached to a given message are sourced from:
         * - impacts, either manually entered or generated by mentioning
         * - targeted stops, mentioned
         * - trips, mentioned
         */
        const headlineMentions = getMentionsByType(newBody);
        const detailsMentions = getMentionsByType(newAdditionalBody);
        const entitySelectors: Array<GtfsEntitySelectorInput> = (() => [
          ...getAllRoutesFromImpacts(newImpacts).map((route) => ({
            routeId: route.routeId,
          })),
          ...formData[WEB_TARGETED_STOPS_NAME].map((stop) => ({
            stopId: stop.id,
          })),
          ...uniqBy(
            [...headlineMentions.trips, ...detailsMentions.trips],
            'tripId',
          )
            .filter(
              (trip) =>
                !trip.relatedRouteIds?.find((r: string) =>
                  IGNORED_TRIPS_ROUTES.has(r),
                ),
            )
            .map((trip) => ({
              trip: { tripId: trip.id },
            })),
        ])();

        return {
          affectedStations,
          entitySelectors,
          body: {
            doc: newBody.doc.toJSON(),
            schema: schemaToJSON(newBody.schema),
            html: sanitizeHTML(sanitizeHTML(toHTML(newBody.doc))),
            text: formatMessageDoc(newBody.doc, {
              bracketRoutes: false,
              enforceSpacesBetweenEntities: true,
              replacements: LONG_GTFS_TEXT_REPLACEMENTS,
            }),
            mentions: JSON.stringify(headlineMentions),
          },
          additionalInfo: newAdditionalInfo,
          impacts: newImpacts
            .filter(
              (impact) => !!impact.messageType && impact.routes.length > 0,
            )
            .map((impact) => ({
              messageType: impact.messageType,
              entitySelectors: impact.routes.map((route) => ({
                routeId: route.routeId,
              })),
              isAgencyWide: impact.isAgencyWide,
            })),
          includeApps: showApps,
        };
      })();

      const tweets: TweetPatchRecordInput[] = await Promise.all(
        (formData[TWEETS_NAME] ?? [])
          .filter((t: Tweet) => !!t?.text.trim().length)
          .map((tweet: Tweet) => {
            return {
              body: tweet.text,
              attachments: tweet.attachments?.reduce(
                (uploads: string[], attachment) => {
                  if (!isUploadFileContainer(attachment)) {
                    uploads.push(attachment.id);
                  }
                  return uploads;
                },
                [],
              ),
              quoteId: tweet.quoteId,
              replyId: tweet.replyId,
            };
          }),
      );

      let emailHTML = '';
      let emailBodyMeta: null | EmailBodyMeta = null;
      let emailBodyPlain = '';
      let smsBody: string | null = null;
      const emailBody = formData[EMAIL_BODY_NAME] as EditorState;
      const headline = formData[WEB_BODY_NAME] as EditorState;

      if (showEmail && emailBody) {
        emailHTML = formatMessageForEmail(emailBody, feedId);
        emailBodyMeta = {
          doc: emailBody.doc.toJSON(),
          schema: schemaToJSON(emailBody.schema),
          html: sanitizeHTML(prosemirrorNodeToHtml(emailBody.doc), {
            addHeightToEmptyParagraph: false,
          }),
          text: formatMessageDoc(emailBody.doc, {
            bracketRoutes: false,
            bracketCustomIcons: true,
            enforceSpacesBetweenEntities: true,
            replacements: LONG_GTFS_TEXT_REPLACEMENTS,
          }),
          mentions: JSON.stringify(getMentionsByType(emailBody)),
        };
        emailBodyPlain = constructPlainTextEmailBody(feedId, emailBody);
        smsBody = isEditableSmsEnabled
          ? formData[SMS_BODY_NAME]?.replace(/[\r\n\v]/g, '\\n') || null
          : constructSMS(feedId, headline);
      }

      const messagePatch: MessagePatchRecordInput = {
        additionalInfo,
        body,
        impacts,
        entitySelectors,
        screens,
        includeApps,
        endAt: webExpiration,
        subject: optionalString(showEmail ? formData[EMAIL_SUBJECT_NAME] : ''),
        emailBody: optionalString(emailHTML),
        emailBodyMeta,
        emailBodyPlain: optionalString(emailBodyPlain),
        emailTypes: [EmailMessageTypeCodes.RTSCH],
        smsBody,
        tweets: showTwitter ? tweets : [],
        affectedStations,
      };

      const eventPatch: EventPatchRecordInput = {
        incidentStartAt: formData[INTERNAL_LOG_INCIDENT_START_AT],
        notifiedAt: formData[INTERNAL_LOG_NOTIFIED_AT],
        incidentEndAt: formData[INTERNAL_LOG_INCIDENT_END_AT],
        notes: formData[INTERNAL_LOG_NOTES],
        neededBy: formData[INTERNAL_LOG_NEEDED_BY],
      };

      let eventId = updatingEvent?.id;
      const refetchQueries = () =>
        updatingEvent
          ? [
              {
                query: GetEventByIdQuery,
                variables: {
                  id: updatingEvent.id,
                  includeClearingScreenMessages: false,
                  orderBy: [MessagesOrderBy.CREATED_AT_ASC],
                  last: 1,
                },
              },
            ]
          : [];

      if (!updatingEvent) {
        const result = await createEvent({
          variables: {
            feedId,
            initialMessage: messagePatch,
            patch: eventPatch,
            isDraft,
          },
          refetchQueries,
        });

        eventId = result.data?.createEvent?.event?.id;
      } else {
        await addUpdateToEvent({
          variables: {
            eventId: updatingEvent.id,
            updateMessage: messagePatch,
            patch: eventPatch,
            isDraft,
          },
          refetchQueries,
        });
      }

      if (onComplete) {
        onComplete(feedId, eventId);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e); // eslint-disable-line no-console
      setError(e as Error);
      setIsSubmitting(false);
    }
  };

  return {
    error,
    clearError: () => {
      if (error) {
        setError(null);
        setIsSubmitting(false);
      }
    },
    isSubmitting,
    isDraft,
    onSubmit,
  };
}

export function useGeneratedEmailSubject(prefix?: string): string {
  const { watch } = useFormContext();
  const feedId = useFeedId();
  const impacts = watch('webImpacts') as TImpact[];
  const headlineMessage = watch(WEB_BODY_NAME);
  const routesFromImpacts = getAllRoutesFromImpacts(impacts).map((route) => {
    return {
      ...route,
      name:
        getGtfsTextReplacement({ id: route.id, isShort: true }) || route.name,
    };
  });
  const stops: Mention[] = watch('webTargetedStops') || [];

  // Check impacts for unique message types
  const uniqueImpacts = uniqBy(
    impacts.filter((impact) => !!impact && !!impact.messageType),
    'messageType',
  );

  // If there's only one unique impact, set that as our alert type. If more than one, set alert type as Multiple Changes.
  let alertType: string[] = [];

  if (uniqueImpacts.length === 1) {
    alertType = [MESSAGE_TYPE_DESCRIPTIONS[uniqueImpacts[0].messageType]];
  } else if (uniqueImpacts.length >= 2) {
    alertType = [MESSAGE_TYPE_DESCRIPTIONS[MessageType.MULTIPLE_CHANGES]];
  }

  // Loop through the stops and get the borough names
  const boroughs = Array.from(
    new Set(stops.map((stop) => stop.boroughs || []).flat()),
  )
    .sort()
    .map((borough) => `${BOROUGH_ABBREVIATIONS[borough]},`);

  const numRoutes = routesFromImpacts.length;

  const routeNamesToSentence = (
    routeNames: string[],
    label: string,
    finalDelimeter?: string,
  ): string => {
    const del = finalDelimeter || (routeNames.length > 2 ? ', and ' : ' and ');
    return `${toSentence(routeNames, ', ', del)} ${label}`;
  };

  // Takes route names and a vehicle name to return a sentence
  const generateRoutes = (routeNames: string[], vehicle: string): string => {
    if (!routeNames.length) {
      return '';
    }
    if (feedId === FeedId.NYCTBus || feedId === FeedId.NYCTSubway) {
      routeNames.sort((a: string, b: string) => a.localeCompare(b));
    }
    const pluralVehicle =
      vehicle === 'Train' ||
      vehicle === 'Line' ||
      vehicle === 'Bridge' ||
      vehicle === 'Tunnel'
        ? `${vehicle}s`
        : `${vehicle}es`;
    const sentence =
      numRoutes > 6 && vehicle === 'Bus'
        ? 'Various Local Buses'
        : routeNamesToSentence(
            // To avoid having something like "Babylon Branch Branch"
            routeNames.map((name) => name.replace(` ${vehicle}`, '')),
            routeNames.length > 1 ? pluralVehicle : vehicle,
          );

    if (alertType.length && numRoutes && feedId !== FeedId.MNR) {
      return `${sentence}`;
    }
    return sentence;
  };

  const generateMNRRoutes = (routes: RouteMention[]): string => {
    // Handle special case for mnr with lines and branches
    const lines = routes
      .filter(
        (r) =>
          MNR_ROUTE_TYPES[r.id] === MNR_ROUTE_TYPE.LINE ||
          !MNR_ROUTE_TYPES[r.id],
      )
      .map((l) => l.name);
    const branches = routes
      .filter((r) => MNR_ROUTE_TYPES[r.id] === MNR_ROUTE_TYPE.BRANCH)
      .map((b) => b.name);
    if (lines.length && !branches.length) {
      // If only lines
      return generateRoutes(lines, MNR_ROUTE_TYPE.LINE);
    }
    if (branches.length && !lines.length) {
      // If only branches
      return generateRoutes(branches, MNR_ROUTE_TYPE.BRANCH);
    }
    if (branches.length && lines.length) {
      // If both lines and branches
      const branchLabel = branches.length > 1 ? 'Branches' : 'Branch';
      const lineLabel = lines.length > 1 ? 'Lines' : 'Line';
      return `${routeNamesToSentence(
        lines,
        lineLabel,
        ', ',
      )} and ${routeNamesToSentence(branches, branchLabel, ', ')}`;
    }
    return '';
  };

  // Combines boroughs, generated routes and alert type into one string
  const generateDefaultSubject = (): string =>
    [
      ...boroughs,
      `${generateRoutes(
        routesFromImpacts.map((r) => r.name),
        getVehicleName(feedId),
      )},`,
      ...alertType,
    ].join(' ');

  const generateDefaultRailRoadSubject = (): string => {
    const branchesSentence =
      feedId === FeedId.MNR
        ? generateMNRRoutes(routesFromImpacts)
        : generateRoutes(
            routesFromImpacts.map(({ name }) => name),
            getVehicleName(feedId),
          );
    const statusSentence = toSentence(alertType, ', ');
    return `${branchesSentence ?? ''}${
      !!branchesSentence && !!statusSentence ? ': ' : ''
    }${statusSentence ?? ''}`;
  };

  const generateDefaultLIRRSubject = (): string => {
    const trips = headlineMessage
      ? getMentionsByType(headlineMessage).trips
      : [];
    const direction = (() => {
      if (trips.length) {
        const tripMeta = trips[0].meta;
        const tripDirection = tripMeta.direction;

        if (tripDirection && typeof tripDirection === 'string') {
          return ` ${tripDirection
            .charAt(0)
            .toUpperCase()}${tripDirection.slice(1)}`;
        }
      }
      return '';
    })();

    return `${generateDefaultRailRoadSubject()}${direction}`;
  };

  const generateDefaultBridgeTunnelSubject = (): string => {
    const bridges = routesFromImpacts
      .filter((r) => BT_ROUTE_TYPES[r.id] === BT_ROUTE_TYPE.BRIDGE)
      .map((b) => b.name.replace(' Bridge', ''));
    const tunnels = routesFromImpacts
      .filter((r) => BT_ROUTE_TYPES[r.id] === BT_ROUTE_TYPE.TUNNEL)
      .map((t) => t.name.replace(' Tunnel', ''));

    let routesSentence = generateRoutes(bridges, 'Bridge');

    if (tunnels.length > 0) {
      routesSentence += bridges.length > 0 ? ' and ' : '';
      routesSentence += generateRoutes(tunnels, 'Tunnel');
    }

    return `${routesSentence}${
      alertType.length > 0 ? `, ${toSentence(alertType, ', ' ?? '')}` : ''
    }`;
  };

  const defaultSubject = (() => {
    if (feedId === FeedId.MNR) {
      return generateDefaultRailRoadSubject();
    }
    if (feedId === FeedId.BT) {
      return generateDefaultBridgeTunnelSubject();
    }
    if (feedId === FeedId.LIRR) {
      return generateDefaultLIRRSubject();
    }
    return generateDefaultSubject();
  })();

  return defaultSubject.length === 0 ||
    !prefix ||
    defaultSubject.startsWith(prefix)
    ? defaultSubject
    : `${prefix}${defaultSubject}`;
}
