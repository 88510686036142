import { css } from '@emotion/react';
import theme from '../../../theme';

export const heading = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const titleContentType = css`
  display: flex;
  align-items: center;
`;

export const title = css`
  overflow: hidden;
  max-width: 372px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const headingHolder = css`
  padding: 0 20px 0 16px;
`;

export const contentType = css`
  ${theme.typography.sizes.xsmall}
  font-weight: ${theme.typography.weights.bold};
  padding: 8px 4px;
  background-color: #dddddd;
  color: ${theme.colors.accent3};
  text-transform: uppercase;
  margin-right: 8px;
  display: inline-block;
`;

export const alignCenter = css`
  text-align: center;
`;

export const appLink = css`
  color: ${theme.colors.black};
  display: flex;
  flex-direction: column;
`;

export const durations = css`
  width: 100%;
  display: flex;
  flex-direction: flex-row;
  justify-content: space-evenly;
`;

export const sortButton = css`
  padding-left: 4px;
`;

export const editLink = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: ${theme.colors.dark2};

  &:hover {
    color: ${theme.colors.brand};
  }
`;

export const editIcon = css`
  transform: scale(1.6);
`;

export const screens = css`
  ${theme.typography.sizes.xsmall}
  font-weight: ${theme.typography.weights.normal};
`;

export const table = css`
  width: 100%;
  margin-bottom: 1rem;
`;

export const tableRow = css`
  height: 73px;
`;

export const tableHolder = css`
  width: 100%;
`;
