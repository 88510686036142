export enum MNR_ROUTE_TYPE {
  BRANCH = 'Branch',
  LINE = 'Line',
}

export const MNR_ROUTE_TYPES: { [key: string]: MNR_ROUTE_TYPE } = {
  'MNR:1': MNR_ROUTE_TYPE.LINE,
  'MNR:2': MNR_ROUTE_TYPE.LINE,
  'MNR:3': MNR_ROUTE_TYPE.LINE,
  'MNR:4': MNR_ROUTE_TYPE.BRANCH,
  'MNR:5': MNR_ROUTE_TYPE.BRANCH,
  'MNR:6': MNR_ROUTE_TYPE.BRANCH,
  'MNR:wassaic': MNR_ROUTE_TYPE.BRANCH,
};

export enum BT_ROUTE_TYPE {
  BRIDGE = 'Bridge',
  TUNNEL = 'Tunnel',
}

export const BT_ROUTE_TYPES: { [key: string]: BT_ROUTE_TYPE } = {
  'BT:1': BT_ROUTE_TYPE.TUNNEL,
  'BT:2': BT_ROUTE_TYPE.BRIDGE,
  'BT:3': BT_ROUTE_TYPE.BRIDGE,
  'BT:4': BT_ROUTE_TYPE.BRIDGE,
  'BT:5': BT_ROUTE_TYPE.BRIDGE,
  'BT:6': BT_ROUTE_TYPE.TUNNEL,
  'BT:7': BT_ROUTE_TYPE.BRIDGE,
  'BT:8': BT_ROUTE_TYPE.BRIDGE,
  'BT:9': BT_ROUTE_TYPE.BRIDGE,
};

export default { MNR_ROUTE_TYPES, BT_ROUTE_TYPES };
