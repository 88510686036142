import { getWeek, differenceInCalendarDays, eachDayOfInterval, differenceInCalendarMonths, startOfWeek, addDays, } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { uniq, uniqWith, isEqual, groupBy } from 'lodash';
import { toSentence } from 'underscore.string';
import { Schedule } from './rschedule';
var Weekdays;
(function (Weekdays) {
    Weekdays["Mon"] = "MO";
    Weekdays["Tue"] = "TU";
    Weekdays["Wed"] = "WE";
    Weekdays["Thu"] = "TH";
    Weekdays["Fri"] = "FR";
    Weekdays["Sat"] = "SA";
    Weekdays["Sun"] = "SU";
})(Weekdays || (Weekdays = {}));
var WeekdayNumbers;
(function (WeekdayNumbers) {
    WeekdayNumbers[WeekdayNumbers["Mon"] = 0] = "Mon";
    WeekdayNumbers[WeekdayNumbers["Tue"] = 1] = "Tue";
    WeekdayNumbers[WeekdayNumbers["Wed"] = 2] = "Wed";
    WeekdayNumbers[WeekdayNumbers["Thu"] = 3] = "Thu";
    WeekdayNumbers[WeekdayNumbers["Fri"] = 4] = "Fri";
    WeekdayNumbers[WeekdayNumbers["Sat"] = 5] = "Sat";
    WeekdayNumbers[WeekdayNumbers["Sun"] = 6] = "Sun";
})(WeekdayNumbers || (WeekdayNumbers = {}));
const ORDERED_WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const EXCEPTION_COMPARE_DATE_FORMAT = 'MMM d yyyy';
const EXCEPTION_DISPLAY_DATE_FORMAT = 'MMM d';
export const formatDate = (duration, pattern) => {
    const timeZone = 'America/New_York';
    return format(utcToZonedTime(duration, timeZone), pattern, { timeZone });
};
export const formatWeekdaysToSentence = (weekdayNumbers, shouldSort) => {
    const hasAllWeekdays = (() => {
        for (let i = WeekdayNumbers.Mon; i <= WeekdayNumbers.Fri; i++) {
            if (!weekdayNumbers.includes(i)) {
                return false;
            }
        }
        return true;
    })();
    const filteredWeekdayNumbers = hasAllWeekdays
        ? weekdayNumbers.filter(weekdayNumber => weekdayNumber > WeekdayNumbers.Fri)
        : weekdayNumbers;
    if (shouldSort) {
        filteredWeekdayNumbers.sort((a, b) => a - b);
    }
    const allDays = filteredWeekdayNumbers.map(weekdayNumber => {
        return ORDERED_WEEKDAYS[weekdayNumber];
    });
    return formatToSentence(hasAllWeekdays ? ['Weekdays', ...allDays] : allDays);
};
export const formatToSentence = (listToFormat) => {
    return toSentence(listToFormat, ', ', listToFormat.length > 2 ? ', and ' : ' and ');
};
const getDateDividedByWeek = (durations) => {
    const weeks = [];
    let counter = 0;
    durations.forEach((d, i) => {
        const prev = durations[i - 1];
        if (!weeks.length) {
            weeks[counter] = [];
        }
        if (i !== 0 &&
            getWeek(d.start, { weekStartsOn: 1 }) !==
                getWeek(prev.start, { weekStartsOn: 1 })) {
            counter++;
            weeks[counter] = [];
        }
        weeks[counter].push(differenceInCalendarDays(d.start, startOfWeek(d.start, { weekStartsOn: 1 })));
    });
    return weeks;
};
const getFullRangeDividedByWeek = (durations) => {
    const weeks = [];
    let counter = 0;
    durations.forEach((d, i) => {
        const prev = durations[i - 1];
        if (!weeks.length) {
            weeks[counter] = [];
        }
        if (i !== 0 &&
            getWeek(d.start, { weekStartsOn: 1 }) !==
                getWeek(prev.start, { weekStartsOn: 1 })) {
            counter++;
            weeks[counter] = [];
        }
        weeks[counter].push(differenceInCalendarDays(d.start, startOfWeek(d.start, { weekStartsOn: 1 })));
        if (d.end && d.start.getTime() < d.end.getTime()) {
            if (Math.abs(differenceInCalendarDays(d.start, new Date(d.end))) > 1) {
                let durationRangeFirstDate = new Date(d.start);
                while (Math.abs(differenceInCalendarDays(durationRangeFirstDate, new Date(d.end))) !== 0) {
                    durationRangeFirstDate = addDays(durationRangeFirstDate, 1);
                    weeks[counter].push(differenceInCalendarDays(durationRangeFirstDate, startOfWeek(durationRangeFirstDate, { weekStartsOn: 1 })));
                }
            }
            else {
                weeks[counter].push(differenceInCalendarDays(d.end, startOfWeek(d.end, { weekStartsOn: 1 })));
            }
        }
    });
    return weeks;
};
const getDaysPerWeek = (durations) => {
    const weeks = getDateDividedByWeek(durations);
    let result = [];
    const weekWithoutWeekend = Object.values(Weekdays).slice(0, Object.values(Weekdays).length - 2);
    weeks.forEach(e => {
        if (e.includes(WeekdayNumbers.Sat) || e.includes(WeekdayNumbers.Sun)) {
            if (!(ORDERED_WEEKDAYS.length - e.length > 2)) {
                result = Object.values(Weekdays);
            }
        }
        if (!(weekWithoutWeekend.length - e.length > 1) &&
            e.includes(WeekdayNumbers.Fri)) {
            result = weekWithoutWeekend;
        }
    });
    return result;
};
const excludedDaysFromRanges = (durations) => {
    let excludedDaysDates = [];
    let excludedDaysNumbers = [];
    if (durations.length) {
        excludedDaysDates = [
            ...eachDayOfInterval({
                start: durations[0]?.start,
                end: durations[durations.length - 1]?.start ??
                    durations[durations.length - 1]?.start,
            }),
        ];
    }
    excludedDaysNumbers = excludedDaysDates.map(date => differenceInCalendarDays(date, startOfWeek(date, { weekStartsOn: 1 })));
    return {
        excludedDaysNumbers,
        excludedDaysDates,
    };
};
const getExceptionsBetweenIntervalsRanges = (durations) => {
    let generatedWeeks = [];
    const daysShouldNotBeExcepted = [];
    const { excludedDaysNumbers, excludedDaysDates } = excludedDaysFromRanges(durations);
    if (excludedDaysDates.length) {
        const datesByWeeks = getFullRangeDividedByWeek(durations);
        generatedWeeks = datesByWeeks.reduce((r, weekNumber) => r.concat(weekNumber), []);
        excludedDaysNumbers.forEach((weekNumber, i) => {
            if (generatedWeeks.includes(weekNumber)) {
                daysShouldNotBeExcepted.push(excludedDaysDates[i]);
            }
        });
        daysShouldNotBeExcepted.forEach(date => {
            const index = excludedDaysDates.indexOf(date);
            excludedDaysDates.splice(index, 1);
        });
        return excludedDaysDates.map(x => formatDate(x, EXCEPTION_COMPARE_DATE_FORMAT));
    }
    return [];
};
const getDurationsCoupledByWeeks = (durations) => {
    let durationsCoupledByRange = [];
    let durationsCoupledInWeeks = [];
    const durationInWeeksAccumulated = [];
    durations.forEach(d => {
        if (d.endWeekday && d.endWeekday != d.startWeekday) {
            durationsCoupledByRange.push([d.startWeekday, d.endWeekday]);
        }
    });
    durationsCoupledByRange = uniqWith(durationsCoupledByRange, isEqual);
    durationsCoupledInWeeks = durationsCoupledByRange.reduce((weekAcc, weekName) => weekAcc.concat(weekName), []);
    durationsCoupledInWeeks.forEach((week, i) => {
        if (i !== 0 &&
            i % 2 === 0 &&
            (Object(WeekdayNumbers)[week] ===
                Object(WeekdayNumbers)[durationsCoupledInWeeks[i - 1]] ||
                (Object(WeekdayNumbers)[week] ===
                    Object(WeekdayNumbers)[durationsCoupledInWeeks[i - 1]] + 1 &&
                    durationsCoupledInWeeks[0] !==
                        durationsCoupledInWeeks[durationsCoupledInWeeks.length - 1]))) {
            durationInWeeksAccumulated.pop();
        }
        else {
            durationInWeeksAccumulated.push(week);
        }
    });
    return durationInWeeksAccumulated;
};
/**
 * Generates a string of the date, time and holiday exceptions for an event
 */
export const generateExceptions = (event) => {
    const allEventWeekdays = [];
    const allDates = [];
    const eventStart = event[0].start;
    let eventEnd = event[event.length - 1].end;
    // If our last event is unbounded, find the latest ending for any event and
    // generate exceptions for that range instead.
    if (!eventEnd) {
        event.forEach(e => {
            if (e.end && (!eventEnd || e.end > eventEnd)) {
                eventEnd = e.end;
            }
        });
    }
    // If we still have an unbounded range, return without generating exceptions
    if (!eventEnd) {
        return '';
    }
    const daysDividedPerWeek = getDaysPerWeek(event);
    const exceptionsBetweenIntervalsRanges = getExceptionsBetweenIntervalsRanges(event);
    event.forEach(e => {
        const startWeekdayNumber = Object(WeekdayNumbers)[e.startWeekday];
        const endWeekdayNumber = Object(WeekdayNumbers)[e.endWeekday ?? ''];
        if (endWeekdayNumber === undefined ||
            startWeekdayNumber === endWeekdayNumber) {
            allEventWeekdays.push(ORDERED_WEEKDAYS[startWeekdayNumber]);
        }
        else if (startWeekdayNumber < endWeekdayNumber) {
            allEventWeekdays.push(...ORDERED_WEEKDAYS.slice(startWeekdayNumber, endWeekdayNumber + 1));
        }
        else if (startWeekdayNumber > endWeekdayNumber) {
            allEventWeekdays.push(...ORDERED_WEEKDAYS.slice(startWeekdayNumber), ...ORDERED_WEEKDAYS.slice(0, endWeekdayNumber + 1));
        }
        const startDate = formatDate(e.start, EXCEPTION_COMPARE_DATE_FORMAT);
        const endDate = e.end
            ? formatDate(e.end, EXCEPTION_COMPARE_DATE_FORMAT)
            : '';
        if (!e.end || startDate === endDate) {
            allDates.push(startDate);
        }
        else {
            const daysInBetween = eachDayOfInterval({
                start: e.start,
                end: e.end,
            });
            daysInBetween.forEach(d => {
                allDates.push(formatDate(d, EXCEPTION_COMPARE_DATE_FORMAT));
            });
        }
    });
    const exceptions = [];
    const timeExceptions = event
        .filter(d => d.timeException)
        .map(d => d.timeException);
    const holidays = uniq(event.filter(d => d.holidays.length).map(d => d.holidays[0]));
    if (timeExceptions.length) {
        exceptions.push(`Service changes ${formatToSentence(timeExceptions)}.`);
    }
    if (holidays.length) {
        exceptions.push(`Includes ${formatToSentence(holidays)}.`);
    }
    const schedule = new Schedule({
        rrules: [
            {
                frequency: 'WEEKLY',
                byDayOfWeek: !daysDividedPerWeek.length
                    ? uniq(allEventWeekdays).map((x) => Object(Weekdays)[x])
                    : daysDividedPerWeek,
                start: eventStart,
                ...(eventEnd ? { end: eventEnd } : {}),
            },
        ],
    });
    const allEventDates = schedule
        .occurrences()
        .toArray()
        .map(({ date }) => formatDate(date, EXCEPTION_COMPARE_DATE_FORMAT));
    const eventDateExceptions = allEventDates.filter(x => !allDates.includes(x) && !exceptionsBetweenIntervalsRanges.includes(x));
    if (eventDateExceptions.length) {
        const exceptionStrings = [];
        let previousDate = new Date();
        for (let i = 0; i < eventDateExceptions.length; i++) {
            const currentDate = new Date(eventDateExceptions[i]);
            if (i === 0) {
                exceptionStrings.push(formatDate(currentDate, EXCEPTION_DISPLAY_DATE_FORMAT));
            }
            else {
                const dateStringArray = exceptionStrings[exceptionStrings.length - 1].split(' ');
                if (differenceInCalendarDays(currentDate, previousDate) === 1) {
                    if (differenceInCalendarMonths(currentDate, previousDate) === 1) {
                        const currentDateMonthDay = formatDate(currentDate, EXCEPTION_DISPLAY_DATE_FORMAT);
                        if (dateStringArray.length == 2) {
                            dateStringArray.push('-', currentDateMonthDay);
                        }
                        else {
                            dateStringArray[dateStringArray.length - 1] = currentDateMonthDay;
                        }
                        exceptionStrings[exceptionStrings.length - 1] = dateStringArray.join(' ');
                    }
                    else {
                        const currentDateDay = formatDate(currentDate, 'd');
                        if (dateStringArray.length == 2) {
                            dateStringArray.push('-', currentDateDay);
                        }
                        else {
                            dateStringArray[dateStringArray.length - 1] = currentDateDay;
                        }
                        exceptionStrings[exceptionStrings.length - 1] = dateStringArray.join(' ');
                    }
                }
                else {
                    exceptionStrings.push(formatDate(currentDate, EXCEPTION_DISPLAY_DATE_FORMAT));
                }
            }
            previousDate = currentDate;
        }
        exceptions.push(`Except ${formatToSentence(exceptionStrings)}.`);
    }
    return exceptions.join(' ');
};
/**
 * Builds a string using input exceptions
 */
export const buildExceptionsStringFromInput = (mappedExceptions, events = []) => {
    const firstEvent = events[0];
    const midnightTime = '12:00 AM';
    const groupedExceptions = groupBy(mappedExceptions, (date) => `${date.start.getHours()}:${date.start.getMinutes()} - ${date.end?.getHours()}:${date.end?.getMinutes()} - ${date.end ? differenceInCalendarDays(date.end, date.start) : '0'}`);
    const eventsHaveSameTime = events.every((e, idx) => e.startTime === firstEvent.startTime &&
        (e.endTime === firstEvent.endTime ||
            // We might have a cadence range that ends before the day of week the cadence ends on
            (idx === events.length - 1 && e.endTime === 'Midnight')));
    const exceptionsEvents = [];
    let lastPassedMonth = '';
    Object.entries(groupedExceptions).forEach(([groupKey, exceptions]) => {
        const daysDifference = parseInt(groupKey.split(' - ')[2], 10);
        const firstExceptionInGrp = exceptions[0];
        const exceptionsHaveSameTime = exceptions.every(e => e.startTime === firstExceptionInGrp.startTime &&
            e.endTime === firstExceptionInGrp.endTime);
        exceptions.forEach((e, idx) => {
            const isLastInGroup = idx === exceptions.length - 1;
            const shouldHideTime = (exceptionsHaveSameTime &&
                eventsHaveSameTime &&
                firstExceptionInGrp?.startTime === firstEvent?.startTime &&
                firstExceptionInGrp?.endTime === firstEvent?.endTime) ||
                (e.startTime === midnightTime && e.endTime === midnightTime);
            let exceptionString = `${formatDate(e.start, e.startMonth === e.endMonth && e.startMonth === lastPassedMonth
                ? 'd'
                : EXCEPTION_DISPLAY_DATE_FORMAT)}`;
            if (daysDifference === 1) {
                exceptionString += `, ${e.endDate}`;
            }
            else if (daysDifference > 1) {
                exceptionString += ` - ${e.startMonth === e.endMonth
                    ? e.endDate
                    : formatDate(e.end, EXCEPTION_DISPLAY_DATE_FORMAT)}`;
            }
            if (isLastInGroup && !shouldHideTime) {
                exceptionString += ` ${e.startTime} to ${e.endTime}`;
            }
            exceptionsEvents.push(exceptionString);
            lastPassedMonth = e.startMonth === e.endMonth ? e.startMonth : '';
        });
    });
    return `except ${formatToSentence(exceptionsEvents)}`;
};
/**
 * Generates an object with the dates and weekdays strings for a list of durations
 */
export const generateDates = (durations, notSameDays, sortWeekdays) => {
    let dates = '';
    let weekdays = '';
    let multipleRangesCoupledByWeek = '';
    let nonConsecutiveDates = false;
    const firstDuration = durations[0];
    const lastDuration = durations[durations.length - 1];
    const allDates = [];
    const allDays = [];
    const daysDividedPerWeek = getDaysPerWeek(durations);
    const durationsCoupledByWeeks = getDurationsCoupledByWeeks(durations);
    if (!notSameDays) {
        durations.forEach((d, i) => {
            const prev = durations[i - 1];
            if (i !== 0 &&
                differenceInCalendarDays(d.start, prev.start) > 1 &&
                getWeek(d.start, { weekStartsOn: 1 }) ===
                    getWeek(prev.start, { weekStartsOn: 1 })) {
                nonConsecutiveDates = true;
            }
            if (!allDays.includes(`${d.startWeekday}`)) {
                allDays.push(`${d.startWeekday}`);
            }
            if (!allDays.includes(`${d.endWeekday}`)) {
                allDays.push(`${d.endWeekday}`);
            }
            if (i === 0 || prev.startMonth !== d.startMonth) {
                allDates.push(`${d.startMonth} ${d.startDate}`);
            }
            else {
                allDates.push(`${d.startDate}`);
            }
        });
    }
    const allWeekdayNumbers = allDays.map((x) => Object(WeekdayNumbers)[x]);
    let hasGapsInAllDays = false;
    allWeekdayNumbers.forEach((dow, i) => {
        if (i !== 0) {
            let prevDow = allWeekdayNumbers[i - 1];
            let currentDow = dow < prevDow ? dow + 7 : dow;
            if (currentDow - prevDow > 1) {
                hasGapsInAllDays = true;
            }
        }
    });
    if (durations.length === 1 && firstDuration.sameDay) {
        dates = `${firstDuration.startMonth} ${firstDuration.startDate}`;
        weekdays = `${firstDuration.startWeekday}`;
    }
    else if (nonConsecutiveDates && !daysDividedPerWeek.length) {
        dates = formatToSentence(allDates);
        weekdays = formatWeekdaysToSentence(allWeekdayNumbers, sortWeekdays);
    }
    else {
        if (hasGapsInAllDays && allDates.length && !daysDividedPerWeek.length) {
            dates = formatToSentence(allDates);
        }
        else {
            dates =
                firstDuration.startMonth === lastDuration.endMonth
                    ? `${firstDuration.startMonth} ${firstDuration.startDate} - ${lastDuration.endDate}`
                    : `${firstDuration.startMonth} ${firstDuration.startDate} - ${lastDuration.endMonth} ${lastDuration.endDate}`;
        }
        if (hasGapsInAllDays) {
            weekdays = formatWeekdaysToSentence(allWeekdayNumbers, sortWeekdays);
        }
        else {
            // Logic is: we have to know the earliest start week day
            // as the end date can be behind it, eg. start: Wed, end: Mon
            // then we search for the latest end date
            // this works since there are no gaps between days
            const earliestStartDay = durations.reduce((acc, d) => {
                if (!acc) {
                    return d.startWeekday;
                }
                if (Object(WeekdayNumbers)[d.startWeekday] < Object(WeekdayNumbers)[acc]) {
                    return d.startWeekday;
                }
                return acc;
            }, '');
            const latestEndDay = durations.reduce((acc, d) => {
                if (d.endWeekday) {
                    if (!acc) {
                        return d.endWeekday;
                    }
                    if (Object(WeekdayNumbers)[d.endWeekday] <
                        Object(WeekdayNumbers)[earliestStartDay]) {
                        if (Object(WeekdayNumbers)[d.endWeekday] >
                            Object(WeekdayNumbers)[acc] ||
                            Object(WeekdayNumbers)[acc] > Object(WeekdayNumbers)[d.endWeekday]) {
                            return d.endWeekday;
                        }
                        return acc;
                    }
                    if (Object(WeekdayNumbers)[d.endWeekday] >
                        Object(WeekdayNumbers)[earliestStartDay] &&
                        Object(WeekdayNumbers)[d.endWeekday] > Object(WeekdayNumbers)[acc]) {
                        if (Object(WeekdayNumbers)[d.endWeekday] > Object(WeekdayNumbers)[acc]) {
                            return d.endWeekday;
                        }
                        return acc;
                    }
                }
                return acc;
            }, '');
            let days = earliestStartDay;
            if (latestEndDay && earliestStartDay !== latestEndDay) {
                const differenceInDays = Object(WeekdayNumbers)[latestEndDay] >
                    Object(WeekdayNumbers)[earliestStartDay]
                    ? Object(WeekdayNumbers)[latestEndDay] -
                        Object(WeekdayNumbers)[earliestStartDay]
                    : Object(WeekdayNumbers)[latestEndDay] +
                        7 -
                        Object(WeekdayNumbers)[earliestStartDay];
                days += ` ${differenceInDays === 1 && !notSameDays ? 'and' : 'to'} ${latestEndDay}`;
            }
            if (durationsCoupledByWeeks.length % 2 === 0 &&
                durationsCoupledByWeeks.length > 2) {
                let counter = 0;
                durationsCoupledByWeeks.forEach((e, i) => {
                    counter++;
                    if (counter === 2) {
                        multipleRangesCoupledByWeek += `${durationsCoupledByWeeks[i - 1]} - ${e}`;
                        if (i !== durationsCoupledByWeeks.length - 1) {
                            multipleRangesCoupledByWeek += ', ';
                        }
                        counter = 0;
                    }
                });
            }
            weekdays = multipleRangesCoupledByWeek || days;
        }
    }
    return { dates: dates, weekdays: weekdays, multipleRangesCoupledByWeek };
};
export const generateSameDayDurationsString = (durations) => {
    if (durations.length === 1) {
        return ` and ${durations[0]}`;
    }
    else if (durations.length > 1) {
        return `, ${formatToSentence(durations)}`;
    }
    return '';
};
