/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

import { GtfsEntitySelectorInput } from 'generated/global-types';
import { ThemeType } from 'theme';
import StatusBanner from 'components/common/status-banner';
import { IEntitySelector } from 'components/common/form-elements/multi-route-stop-selector';
import {
  TargetedStaticOnlyScreens,
  TargetedStaticOnlyScreensVariables,
} from 'generated/TargetedStaticOnlyScreens';

const TargetedStaticOnlyScreensQuery = loader(
  '../../graphql/TargetedStaticOnlyScreens.gql',
);

const MESSAGE =
  'Screens in the Grand Central Terminal rotunda are not allowed to play this or any video.';

const selectorsToEntitySelectors = (
  selectors: IEntitySelector[],
): GtfsEntitySelectorInput[] =>
  selectors
    .map(({ routes, stops }) => [
      ...routes
        .filter(({ routeId }) => !!routeId)
        .map(({ routeId }) => ({ routeId })),
      ...stops
        .filter(({ stopId }) => !!stopId)
        .map(({ stopId }) => ({ stopId })),
    ])
    .reduce((arr, a) => arr.concat(a), []);

const MotionWarning: React.FC<
  {
    uploads: { [key: string]: any };
    selectors: IEntitySelector[];
  } & { children?: React.ReactNode }
> = ({ uploads, selectors }) => {
  const hasMotion: boolean = Object.values(uploads).some(
    (u: any) => u?.hasMotion,
  );

  const query = useQuery<
    TargetedStaticOnlyScreens,
    TargetedStaticOnlyScreensVariables
  >(TargetedStaticOnlyScreensQuery, {
    skip: !hasMotion,
    variables: { selectors: selectorsToEntitySelectors(selectors) },
  });

  const isVisible =
    hasMotion && (query?.data?.targetedStaticOnlyScreens ?? false);

  return (
    <StatusBanner
      status="warning"
      hasIcon
      text={MESSAGE}
      isVisible={isVisible}
      css={({ spacing }: ThemeType) => css`
        margin-top: ${spacing.small};
      `}
    />
  );
};

export default MotionWarning;
