/** @jsxImportSource @emotion/react */

import React from 'react';

import { PlannedWorkStatus } from '../../../../generated/global-types';
import PlannedWorkPage from '../../../common/planned-work/planned-work-page';

const PlannedWork: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const useQueryVars = [PlannedWorkStatus.DRAFT];

  return (
    <PlannedWorkPage statuses={useQueryVars} page="draft" pageTitle="Drafts" />
  );
};

export default PlannedWork;
