/** @jsxImportSource @emotion/react */
import * as React from 'react';

import { css } from '@emotion/react';
import { ReactComponent as CrocImage } from '../../../../images/croc.svg';
import Heading from '../../../common/Heading';
import { ThemeType } from '../../../../theme';
import PageMeta from '../../../common/PageMeta';
import { Container, Text } from '../index.styled';

const GenericError: React.FC<
  {
    title: string;
    description: string;
    cta?: {
      link: string | null;
      text: string;
    } | null;
  } & { children?: React.ReactNode }
> = ({ title, description, cta }) => (
  <Container
    css={css`
      margin-top: 10vh;
    `}
  >
    <PageMeta title={title} baseTitle="Mercury" />
    <CrocImage width={96} height={92} />
    <Heading
      css={(theme: ThemeType) => css`
        margin-top: ${theme.spacing.xsmall};
        letter-spacing: 0.39px;
      `}
      level={1}
      size="xxlarge"
    >
      {title}
    </Heading>
    <Text
      css={css`
        text-align: center;
        margin-bottom: 6px;
      `}
    >
      {description}
    </Text>
    {cta?.link && (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={cta.link}
        css={css`
          text-decoration: underline;
        `}
      >
        {cta.text}
      </a>
    )}
  </Container>
);

export default GenericError;
