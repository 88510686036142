/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import AssetForm, { FormTypes, FormValues } from 'components/common/asset-form';
import { LockableAssetViewContainer } from '../asset-view/lockable-asset';
import * as S from './index.styled';
import * as A from '../asset-view/index.styled';
import * as Sentry from '@sentry/browser';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import * as Well from '../../common/well';
import { ScreenOrientation, ScreenType } from 'generated/global-types';
import { useHistory, useParams } from 'react-router-dom';
import {
  CreateScheduledAsset as CreateScheduledAssetMutation,
  CreateScheduledAssetVariables,
} from 'generated/CreateScheduledAsset';
import Button from 'components/common/Button';
import {
  UpdateUpload as UpdateUploadMutation,
  UpdateUploadVariables,
} from 'generated/UpdateUpload';
import { useFeedId } from 'contexts/FeedId';
import {
  GetLockableUploadById,
  GetLockableUploadByIdVariables,
} from 'generated/GetLockableUploadById';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import PageMeta from 'components/common/PageMeta';
import ConfirmModal from 'components/common/confirm-modal';
import StatusBanner from 'components/common/status-banner';
import { css } from '@emotion/react';
import { TStatusPillTypes } from 'components/common/status-pill';
import { ScheduleDuration, WarningInfo } from '../screen-assets/util';

const CreateScheduledAsset = loader(
  '../../../graphql/CreateScheduledAsset.gql',
);

const UpdateUpload = loader('../../../graphql/UpdateUpload.gql');
const GetLockableUploadByIdQuery = loader(
  '../../../graphql/GetLockableUploadById.gql',
);

const AssetScheduleForm = () => {
  const { id: defaultUploadId } = useParams<{
    id: string;
  }>();
  const feedId = useFeedId();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [now] = useState(new Date());
  const [warningInfo, setWarningInfo] = useState<WarningInfo>();
  const [showModal, toggleModal] = useState(false);

  const { data: existingLockedAssetData, loading } = useQuery<
    GetLockableUploadById,
    GetLockableUploadByIdVariables
  >(GetLockableUploadByIdQuery, {
    variables: {
      id: defaultUploadId,
      includeScheduled: true,
      currentDateTime: now,
    },
    onError(error) {
      Sentry.captureException(error);
    },
  });

  const [createScheduledAsset] = useMutation<
    CreateScheduledAssetMutation,
    CreateScheduledAssetVariables
  >(CreateScheduledAsset, {
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const [updateUpload] = useMutation<
    UpdateUploadMutation,
    UpdateUploadVariables
  >(UpdateUpload, {
    onError(error) {
      Sentry.captureException(error);
    },
  });

  const screenCount =
    existingLockedAssetData?.lockableUpload?.activeScreensCount || 0;

  const handleSubmit = () => async (fv: FormValues) => {
    if (!showModal) {
      toggleModal(true);
      setWarningInfo({
        endAt: fv.endAt?.toISOString(),
        startAt: fv.startAt?.toISOString(),
        title: fv.title,
      });
      return;
    }

    setIsSubmitting(true);

    const { data } = await createScheduledAsset({
      variables: {
        endAt: fv.endAt,
        startAt: fv.startAt,
        defaultUploadId,
        scheduledUploadId: fv.upload?.id,
        thumbnailId: fv.thumbnail?.id,
      },
    });

    if (data?.createScheduledAsset?.scheduledAsset) {
      // this might actually be an unneccesary update???
      await updateUpload({
        variables: {
          title: fv.title,
          description: fv.description,
          id: data.createScheduledAsset.scheduledAsset.scheduledUploadId,
        },
      });
    }
    toggleModal(false);
    setIsSubmitting(false);
    history.push(`/${feedId}/assets/${defaultUploadId}`);
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexFlow: 'column' }}>
      <PageMeta title="View Locked Asset" />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}/assets/${defaultUploadId}`,
          title: 'Back to Locked Asset',
        }}
      />
      <Well.Container>
        <Well.Section>
          <A.Title>Schedule Locked Screen</A.Title>
        </Well.Section>
        <LockableAssetViewContainer
          pageTitle=""
          author={existingLockedAssetData?.lockableUpload?.author?.name || ''}
          screenType={
            existingLockedAssetData?.lockableUpload?.screenType ||
            ScreenType.CIC
          }
          loading={loading}
          uploadAsset={existingLockedAssetData?.lockableUpload?.upload}
          thumbnailAsset={existingLockedAssetData?.lockableUpload?.thumbnail}
          status={TStatusPillTypes.LIVE}
        />
      </Well.Container>
      <AssetForm
        screenCount={`On ${screenCount} CIC screens`}
        formType={FormTypes.ScheduledAsset}
        feedId={feedId}
        heading="New Scheduled Screen"
        defaultValues={{
          orientation: ScreenOrientation.PORTRAIT,
          screenTypes: [],
          activeScreensCount: screenCount,
          duration: {
            startAt: new Date(),
            endAt: new Date(),
          },
        }}
      >
        {({ makeSubmitHandler, form }) => {
          return (
            <>
              <Well.Container>{form}</Well.Container>
              <ConfirmModal
                title="Confirm Schedule"
                onConfirm={makeSubmitHandler(handleSubmit())}
                onDismiss={() => {
                  toggleModal(false);
                }}
                message="Please confirm the creation of the following scheduled Locked Screen"
                isOpen={showModal}
              >
                <StatusBanner
                  status="warning"
                  isVisible
                  css={css`
                    padding-top: 20px;
                    .sched-desc {
                      margin-bottom: 0;
                    }
                  `}
                >
                  {warningInfo && (
                    <ScheduleDuration {...warningInfo} isScheduled isWarning />
                  )}
                </StatusBanner>
              </ConfirmModal>
              <S.Buttons
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  flex-direction: row;
                  gap: 16px;
                  margin: 16px 32px;
                `}
              >
                <Button
                  type="button"
                  onClick={() =>
                    history.push(`/${feedId}/assets/${defaultUploadId}`)
                  }
                >
                  cancel
                </Button>
                <Button
                  primary
                  type="button"
                  disabled={isSubmitting}
                  onClick={makeSubmitHandler(handleSubmit())}
                >
                  submit
                </Button>
              </S.Buttons>
            </>
          );
        }}
      </AssetForm>
    </div>
  );
};

export default AssetScheduleForm;
