/** @jsxImportSource @emotion/react */

import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import theme from 'theme';

// We use each `Segment` to represent 25% of the donut chart
// We use `skew` to make that segment represent a smaller percentage
const Segment: React.FC<{
  additionalStyles?: SerializedStyles;
  children?: React.ReactNode;
}> = ({ additionalStyles, ...rest }) => {
  return (
    <div
      css={[
        css`
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100vw;
          height: 100vw;
          background: ${theme.colors.accent1};
          transform-origin: 0 0;
        `,
        additionalStyles,
      ]}
      {...rest}
    />
  );
};

const mainCircleStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #dddddd;
  overflow: hidden;
`;

const innerCircleStyles = css`
  position: absolute;
  border-radius: 50%;
  height: 60%;
  width: 60%;
  background: ${theme.colors.light1};
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 *
 * @param percentage should be less than 25% (The percentage of each segment)
 * @returns The skew degree for each segment (Each segment repesenting 25% of the total percentage)
 */
const getSkewDeg = (percentage: number) => `${(percentage * -90) / 25 + 90}deg`;

const DonutChart: React.FC<{ percentage: number }> = ({ percentage }) => {
  return (
    <div css={mainCircleStyles}>
      {percentage > 0 && (
        <Segment
          additionalStyles={css`
            transform: rotate(180deg)
              ${percentage < 25 ? `skew(0deg, ${getSkewDeg(percentage)})` : ''};
          `}
        />
      )}
      {percentage > 25 && (
        <Segment
          additionalStyles={css`
            transform: rotate(90deg)
              ${percentage < 50
                ? `skew(0deg, ${getSkewDeg(percentage - 25)})`
                : ''};
          `}
        />
      )}
      {percentage > 50 && (
        <Segment
          additionalStyles={css`
            transform: ${percentage < 75
              ? `skew(0deg, ${getSkewDeg(percentage - 50)})`
              : ''};
          `}
        />
      )}
      {percentage > 75 && (
        <Segment
          additionalStyles={css`
            transform: rotate(270deg)
              ${percentage < 100
                ? `skew(0deg, ${getSkewDeg(percentage - 75)})`
                : ''};
          `}
        />
      )}
      <div css={innerCircleStyles}>{percentage}%</div>
    </div>
  );
};

export default DonutChart;
