/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import theme from 'theme';

const HoverFillButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <React.Fragment>
      <div
        css={css`
          position: absolute;
          height: 100%;
          width: 100%;
          & + button:focus,
          & + button:hover {
            background-color: rgba(0, 0, 0, 0.3);
          }
        `}
      />
      <button
        css={css`
          opacity: 0;
          position: absolute;
          z-index: 1;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover,
          &:focus {
            opacity: 1;
          }
        `}
        type="button"
        onClick={onClick}
      >
        <span
          css={css`
            ${theme.typography.sizes.xsmall};
            font-weight: ${theme.typography.weights.bold};
            display: block;
            max-width: 100px;
            margin: 8px;
            padding: 8px;
            border-radius: 4px;
            text-transform: uppercase;
            background-color: ${theme.colors.accent1};
            color: ${theme.colors.white};
          `}
        >
          Quick View
        </span>
      </button>
    </React.Fragment>
  );
};

export default HoverFillButton;
