/* eslint-disable jsx-a11y/control-has-associated-label */
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import theme from 'theme';
import { ReactComponent as CaretIcon } from '../../../images/caret_down.svg';
import { ReactComponent as CloseIcon } from '../../../images/cross-blue.svg';
import TrashIcon from '../trash-icon';

export const Container: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
    `}
    {...props}
  />
);

export const Expand: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <CaretIcon
    css={css`
      position: absolute;
      transition: transform 100ms ease;
      transform: rotate(-90deg);
      left: -30px;
      top: 9px;
    `}
    {...props}
  />
);

export const Content: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      flex-grow: 1;
    `}
    {...props}
  />
);

export const Header: React.FC<
  {
    children: React.ReactNode;
    onClose?: () => void;
    onDelete?: () => void;
    preview?: React.ReactNode;
  } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
> & { children?: React.ReactNode } = ({
  children,
  onClose,
  onDelete,
  preview,
  ...props
}) => (
  <summary
    css={css`
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: 56px;
      align-items: center;
    `}
    {...props}
  >
    <h3
      css={css`
        margin: 0;
        position: relative;
        display: flex;
        flex: 0 0 ${onClose || onDelete ? 95 : 100}%;
        justify-content: space-between;
      `}
    >
      {children}
    </h3>
    <div
      css={css`
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      {preview && (
        <div
          data-collapsed-detail
          css={css`
            display: flex;
            > section {
              margin-right: 5px;
            }
          `}
        >
          {preview}
        </div>
      )}
      {onClose && (
        <button
          type="button"
          onClick={() => onClose()}
          css={css`
            margin-left: 24px;
          `}
        >
          <CloseIcon />
        </button>
      )}
      {onDelete && (
        <button
          type="button"
          onClick={() => onDelete()}
          css={css`
            margin-left: 24px;

            &:enabled {
              svg g {
                fill: ${theme.colors.accent1};
              }
            }

            &:enabled:hover {
              svg g {
                fill: ${theme.colors.brand};
              }
            }
          `}
        >
          <TrashIcon />
        </button>
      )}
    </div>
  </summary>
);

export const OnlyWhenExpanded: React.FC<
  React.DetailsHTMLAttributes<HTMLDetailsElement> & {
    children?: React.ReactNode;
  }
> = (props) => (
  <details
    css={css`
      display: flex;
      flex-direction: column;
      margin-left: 32px;
      > summary::-webkit-details-marker {
        display: none;
      }

      &[open] > summary [data-disclosure] {
        transform: rotate(0deg);
      }
      &[open] > summary [data-collapsed-detail] {
        display: none;
      }

      & > div {
        box-sizing: border-box;
      }
    `}
    {...props}
  />
);

export const Subtitle: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={css`
      color: ${theme.colors.dark3};
      ${theme.typography.sizes.medium};
      font-weight: ${theme.typography.weights.normal};
      margin-left: ${theme.spacing.xsmall};
    `}
    {...props}
  />
);
