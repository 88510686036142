/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 20vh;
      width: 100%;
    `}
    {...props}
  />
);
export const Text: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <p
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      font-family: ${theme.typography.families.secondary};
      letter-spacing: 0.25px;
      color: ${theme.colors.dark2};
      margin-top: ${theme.spacing.xxsmall};
      margin-bottom: ${theme.spacing.medium};
    `}
    {...props}
  />
);
