import {
  ProsemirrorNode,
  findTextNodes,
  findBlockNodes,
  EditorState,
  RemirrorEventListenerProps,
} from 'remirror';
import { TextSelection } from 'prosemirror-state'; // eslint-disable-line import/no-extraneous-dependencies
import _uniqBy from 'lodash/uniqBy';
import { getAllMentions } from '@mta-live-media-manager/shared';
import { MentionType } from '../../../../generated/global-types';

export type TTextMatcherReplace = {
  text: string;
  from: number;
  end: number;
  to: number;
};

/*
 * Navigate through all blocks and text nodes looking
 * for items that match our regex. Ignore any in list provided.
 *
 * Save needed metadata for replacment and return.
 *
 * Items may sometimes be spread across two text nodes
 * if separated in HTML via span or other inline element.
 */
const matcherRegexColons = /:([a-zA-z0-9-]*?):/g;
const matcherRegexBrackets = /\[([a-zA-z0-9-]*?)\]/g;
export const getAllTextMatchers = ({
  doc,
  matchesToIgnore = [],
}: {
  doc: ProsemirrorNode;
  matchesToIgnore?: string[];
}): TTextMatcherReplace[] => {
  const blockNodes = findBlockNodes({ node: doc });
  let foundMatchers: TTextMatcherReplace[] = [];

  blockNodes.forEach((blockNode) => {
    const textNodes = findTextNodes(blockNode);
    textNodes.forEach((curNode, idx) => {
      const nextNode = textNodes[idx + 1];
      const nextText = nextNode?.node?.textContent || '';
      const combinedText = curNode.node.textContent + nextText;

      const handleMatch = (text: string) => {
        const colonMatches = text.match(matcherRegexColons) || [];
        const bracketMatches = text.match(matcherRegexBrackets) || [];
        const matches = [...colonMatches, ...bracketMatches];

        const filteredMatches = matches?.filter(
          (match) => !matchesToIgnore.includes(match),
        );

        filteredMatches?.forEach((match) => {
          const startIndex = text.indexOf(match);
          const fromPos = blockNode.pos + curNode.pos + startIndex + 1;
          const endPos =
            blockNode.pos + curNode.pos + startIndex + match.length + 1;

          foundMatchers.push({
            text: match.toLowerCase(),
            from: fromPos,
            to: endPos,
            end: endPos,
          });

          foundMatchers = _uniqBy(foundMatchers, 'from');
        });
      };

      handleMatch(combinedText);
    });
  });

  return foundMatchers;
};

export const getNearMentions = ({
  doc,
  from,
  to,
  isInclusive = false,
}: {
  doc: ProsemirrorNode;
  from: number;
  to: number;
  isInclusive?: boolean;
}) => {
  const textNodesWithMentions = getAllMentions({ doc });

  const matchedMentions = textNodesWithMentions.filter((mention) => {
    const length = mention.node.text?.length || 0;
    const leftBound = isInclusive ? mention.pos + 1 : mention.pos;
    const rightBound = isInclusive
      ? mention.pos + length - 1
      : mention.pos + length;

    return [from, to].some((loc) => loc >= leftBound && loc <= rightBound);
  });

  return matchedMentions;
};

export const getNextAdjacentMentions = ({
  doc,
  currentPosition,
}: {
  doc: ProsemirrorNode;
  currentPosition: number;
}) => {
  const textNodesWithMentions = getAllMentions({ doc });

  const matchedMentions = textNodesWithMentions.filter(
    ({ pos: mentionPos }) => currentPosition === mentionPos + 1,
  );

  return matchedMentions;
};

export const handleMentionDelete = (
  params: RemirrorEventListenerProps<Remirror.Extensions>,
): RemirrorEventListenerProps<Remirror.Extensions> => {
  let { state } = params;

  const newStep = params.tr?.steps[0]?.toJSON();

  const newNearMentions = getNearMentions({
    doc: params.state.doc,
    from: params.state.tr.selection.from,
    to: params.state.tr.selection.to,
  });

  const isShorter =
    params.state.doc.textContent.length <
    params.previousState.doc.textContent.length;

  if (
    isShorter &&
    newStep &&
    newNearMentions.length &&
    newStep.stepType === 'replace' &&
    params.tr
  ) {
    newNearMentions.forEach((mention) => {
      const mentionMarkAttributes = mention.node.marks[0].attrs;
      const mentionMarkText = mention.node.text;

      /*
        If the saved label of the mention no longer matches the rendered text,
        we know the user deleted part of it, and that we should remove the rest.
      */
      if (mentionMarkAttributes.label !== mentionMarkText) {
        const deleteTransaction = params.state.tr.delete(
          mention.pos,
          mention.pos + mention.node.textContent.length,
        );

        const { state: newState } =
          params.state.applyTransaction(deleteTransaction);
        state = newState;
      }
    });
  }

  return {
    ...params,
    state,
  };
};

export const moveCursor = (state: EditorState, position: number) => {
  const newSelection = TextSelection.create(state.doc, position, position);
  const moveCursorTransaction = state.tr.setSelection(newSelection);
  const { state: newState } = state.applyTransaction(moveCursorTransaction);
  return newState;
};

export const handleCursorInMention = (
  params: RemirrorEventListenerProps<Remirror.Extensions>,
): RemirrorEventListenerProps<Remirror.Extensions> => {
  let { state } = params;
  const isNewNotSelection =
    params.state.tr.selection.from === params.state.tr.selection.to;

  if (!isNewNotSelection || !!params.tr?.steps[0]?.toJSON()) {
    return params;
  }

  const oldCursorPos = params.previousState.tr.selection.from;
  const newCursorPos = params.state.tr.selection.from;

  const newNearMentions = getNearMentions({
    doc: params.state.doc,
    from: params.state.tr.selection.from,
    to: params.state.tr.selection.to,
    isInclusive: true,
  });

  if (newNearMentions[0]) {
    const mentionLength = newNearMentions[0].node.text?.length || 0;
    const mentionStart = newNearMentions[0].pos;
    const mentionEnd = mentionStart + mentionLength;

    let newLoc = oldCursorPos;
    if (oldCursorPos === mentionEnd && newCursorPos === mentionEnd - 1) {
      // arrow in from end of mention
      newLoc = mentionStart;
    } else if (
      oldCursorPos === mentionStart &&
      newCursorPos === mentionStart + 1
    ) {
      // arrow in from start of mention
      newLoc = mentionEnd;
    } else if (oldCursorPos > mentionStart && oldCursorPos <= mentionEnd) {
      // handle edge case where a selection ends/begins and users attempts arrow in a mention
      newLoc = mentionStart;
    }

    state = moveCursor(params.state, newLoc);
  }

  return {
    ...params,
    state,
  };
};

/*
 * When copying a previous message with mentions, Chrome has a bug where it will copy over all style attributes
 * applied by the browser and not just attributes we have set. This causes rendering issues in both the editor
 * and in the screen preview.
 *
 * Set list of allowed style attributes here and filter out the rest.
 */
const ALLOWED_STYLE_ATTRS = ['color', 'background-color'];
export const handleSanitization = (
  params: RemirrorEventListenerProps<Remirror.Extensions>,
): RemirrorEventListenerProps<Remirror.Extensions> => {
  const { state } = params;

  const textNodesWithMention = getAllMentions({ doc: state.doc });

  textNodesWithMention.forEach((mention) => {
    const mentionMarkAttributes = mention.node.marks[0].attrs;
    const styleString: string | undefined = mentionMarkAttributes?.style;

    if (!styleString) {
      return;
    }

    const isRoute =
      mentionMarkAttributes?.['data-bullet-type'] === MentionType.ROUTE;
    const isSubway =
      mentionMarkAttributes?.['data-bullet-route-id']?.startsWith('mtasbwy');

    if (isRoute && isSubway) {
      // Subway routes use images. No manual styles should be applied.
      // @ts-ignore
      mentionMarkAttributes.style = undefined;
      return;
    }

    const styles = styleString.split(';'); // 'color: #000; background-color: #fff;'
    const filteredStyles = styles.filter((style) => {
      const propName = style.split(':')[0].trim();
      return ALLOWED_STYLE_ATTRS.includes(propName);
    });

    // @ts-ignore
    mentionMarkAttributes.style = filteredStyles.length
      ? filteredStyles.join(';')
      : undefined;
  });

  return {
    ...params,
    state,
  };
};

export const handleSanitizationAndChange = (
  params: RemirrorEventListenerProps<Remirror.Extensions>,
): RemirrorEventListenerProps<Remirror.Extensions> => {
  let newParams = handleMentionDelete(params);
  newParams = handleCursorInMention(newParams);
  newParams = handleSanitization(newParams);
  const mostRecentStep = params.tr?.steps[0]?.toJSON();

  // There is no recent step if the change is just a cursor move
  if (!mostRecentStep) {
    return newParams;
  }

  const currentPosition = params.state.tr.selection.from;
  const adjMentions = getNextAdjacentMentions({
    doc: params.state.doc,
    currentPosition,
  });
  const hasAdjMentions = !!adjMentions.length;
  const isTyping = mostRecentStep?.from >= mostRecentStep?.to;

  /**
   * When inserting a mention or textbefore another at the beginning of the line,
   * the cursor should be moved back by 1. This is to prevent the cursor from being
   * placed in the middle of the mention. This is automatically handled for mentions
   * that aren't at the beginning of the line.
   */
  if (
    hasAdjMentions &&
    // eslint-disable-next-line no-unsafe-optional-chaining
    adjMentions[0]?.pos + 1 === currentPosition &&
    isTyping
  ) {
    newParams.state = moveCursor(params.state, currentPosition - 1);
  }

  return newParams;
};
