/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, Fragment } from 'react';
import pluralize from 'pluralize';
import { FeedId } from '@mta-live-media-manager/shared';
import { useFeedId } from 'contexts/FeedId';
import {
  FeatureFlagName,
  SchedulePriority,
  TargetType,
  TrainTargetingInput as TrainTargeting,
  TransitDestination,
} from 'generated/global-types';
import { CommonUploadFragment } from 'generated/CommonUpload.fragment';
import useScreenLayoutCounts from 'hooks/use-screen-layout-counts';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { UploadType } from 'hooks/useUpload';
import {
  CampaignTargetingFormStages,
  getInitialPropsForTrainTargeting,
} from 'utils/campaign-helpers';
import {
  getGroupedCICsFromScreenLayoutCounts,
  getGroupedTargetingTypes,
} from 'utils/screen-targeting';
import { getScreenTargetingFieldKey } from 'utils/compose-helpers';
import { CampaignTargeting } from 'components/pages/campaign-view/campaign-targeting';
import theme, { ThemeType } from 'theme';
import { TagsSelection } from 'types';

import MultiRouteSelector, {
  getAllRoutesFromSelectors,
  getAllStopsFromSelectors,
  getContextualSelectorsByType,
  ISelector,
} from '../form-elements/multi-route-stop-selector';
import {
  getLabelFromTargetTypes,
  getDimensionsFromTargetType as getLayoutDimensions,
  getCoveMultiplier,
  COVES_TARGET_TYPES,
} from '../../pages/Screen/in-screens-preview/helpers';
import StatusBanner from '../status-banner';
import Button from '../Button';
import DisplayFrequencySelector, {
  DispFreqMessageType,
  getDispFreqOption,
} from '../form-elements/display-frequency-selector';
import * as S from './index.styled';
import ScreenLayoutButtons, {
  getPositionalScreensWithDimensions,
} from '../screen-layout-buttons';
import ScreensAvailabilityCount from '../screens-availability-count';
import Accordion from '../accordion';
import { ScreenUploader } from './screen-uploader';
import MotionWarning from '../MotionWarning';
import { ScreenSpectacularsFooter } from './screen-spectaculars';
import ShopSelector from '../form-elements/shop-selector';
import TrainTargetingDisplayGroup from '../train-targeting-display-group';
import { ReactComponent as Pencil } from '../../../images/icon_edit_sm.svg';
import MnrRollingStockLineSelector from '../form-elements/mnr-rolling-stock-line-selector';
import TargetingAccordionLabel from '../screen-targeting-accordion-label';
import Heading from '../Heading';
import SidewalkScreenSelector from './sidewalk-screens-multiroute-selector';
import TargetingSelectionButton from '../screen-targeting-selection-button';
import { IScreenTargetingError, ScreenTargetingErrorKeys } from './errors';

const SCREEN_UPLOADS_PAGE = 3;

export interface ScreenSelector {
  selector: ISelector;
  tags?: TagsSelection;
}

interface Targeting {
  activeStage: CampaignTargetingFormStages;
  screenSelectors: ISelector[];
  layouts: TargetType[];
  weightFrequency: {
    priority: SchedulePriority;
    weight: number;
  };
  trainTargeting?: TrainTargeting | null;
  uploads?: { [k: string]: UploadType };
}

interface CampaignScreenTargetingProps {
  targetingIndex: number;
  targeting: Targeting;
  onTargetingChange: (targeting: Targeting) => void;
  onTargetingDelete: (index: number) => void;
  forceSurfaceErrors?: () => void;
  canDelete: boolean;
  errors: IScreenTargetingError;
}

const CampaignScreenTargeting: React.FC<CampaignScreenTargetingProps> = ({
  targetingIndex,
  targeting,
  onTargetingChange,
  onTargetingDelete,
  forceSurfaceErrors,
  canDelete,
  errors,
}) => {
  const {
    activeStage,
    layouts,
    screenSelectors,
    weightFrequency,
    trainTargeting,
    uploads = {},
  } = targeting;

  const feedId = useFeedId();

  const selectors = screenSelectors.flatMap(({ selector }) => selector);
  const tags = screenSelectors.reduce(
    (agg, cur) => ({ ...agg, ...cur.tags }),
    {},
  );

  const weightingEnabled = useFeatureFlag(FeatureFlagName.WEIGHTING_FREQUENCY);
  const rollingStockEnabled = useFeatureFlag(
    FeatureFlagName.CAMPAIGN_ROLLING_STOCK,
  );
  const sidewalkTargetingEnabled = useFeatureFlag(
    FeatureFlagName.SIDEWALK_SCREEN_TARGETING,
  );

  const shops = trainTargeting?.shops ?? [];
  const mnrRollingStockLines = trainTargeting?.lines ?? [];
  const routes = getAllRoutesFromSelectors(selectors, false);
  const stops = getAllStopsFromSelectors(selectors);

  const isTargetingSideWalkScreen =
    activeStage ===
    CampaignTargetingFormStages.SIDEWALK_UPLOAD_CONTENT_SELECTION;

  const uploadContentStages = [
    CampaignTargetingFormStages.SIDEWALK_UPLOAD_CONTENT_SELECTION,
    CampaignTargetingFormStages.STATION_UPLOAD_CONTENT_SELECTION,
    CampaignTargetingFormStages.TRAIN_UPLOAD_CONTENT_SELECTION,
  ];

  const { screenLayoutCounts } = useScreenLayoutCounts({
    stopIds: stops.map((stop) => stop.stopId),
    routeIds: routes.map((route) => route.routeId),
    shops,
    mnrLines: mnrRollingStockLines,
    // We only care about targetTypes here if we are uploading content, to
    // show the total number of screens available for the selected layouts
    targetTypes: uploadContentStages.includes(activeStage) ? layouts : [],
    isTargetingAllLirrTrains:
      feedId === FeedId.LIRR &&
      activeStage ===
        CampaignTargetingFormStages.TRAIN_UPLOAD_CONTENT_SELECTION,
    excludedTargetTypes: isTargetingSideWalkScreen ? [] : [TargetType.DUP],
    specificTargetTypes: isTargetingSideWalkScreen ? [TargetType.DUP] : [],
    isCampaign: true,
    shouldRemoveGroupedCICs: false,
  });

  const groupedCICs = getGroupedCICsFromScreenLayoutCounts(screenLayoutCounts);

  const updateTargeting = (updatedValue: {
    [key in keyof Targeting]?: Targeting[key];
  }) =>
    onTargetingChange({
      ...targeting,
      ...updatedValue,
      layouts: getGroupedTargetingTypes({
        updated: updatedValue.layouts ?? layouts,
        feedId,
        groupedCICs,
      }),
    });

  const { availableCount, lockedCount } = screenLayoutCounts.reduce(
    (acc, count) => {
      return {
        availableCount: acc.availableCount + count.availableCount,
        lockedCount: acc.lockedCount + count.lockedCount,
      };
    },
    { availableCount: 0, lockedCount: 0 },
  );

  const spectacularScreensLayout = !!layouts?.includes(
    TargetType.SPECTACULAR_AD,
  );

  const screenUploadsTotal = availableCount ?? 0;
  const [screenUploadsPage, setScreenUploadsPage] = useState<number>(1);
  // We're passing optional pagination values to handle hiding elements when paginating while `positionsAndDimensions` is being mapped.
  const positionsAndDimensions = getPositionalScreensWithDimensions(
    // Sort the layouts to ensure the same order of screens is used, without depending on the order of the layouts chosen.
    (layouts?.length ? [...layouts].sort()[0] : null) ?? null,
    SCREEN_UPLOADS_PAGE,
    screenUploadsTotal,
    screenUploadsPage,
  );

  const availableSpectaculars = spectacularScreensLayout
    ? `, ${availableCount} ${pluralize(
        'Screens',
        availableCount,
      )} Per Spectacular`
    : '';

  const dimensions: string = (() => {
    if (!layouts) {
      return '';
    }
    const [width, height] = getLayoutDimensions(layouts[0]);
    return `${width} x ${height}px`;
  })();

  const uploadsAvailable = positionsAndDimensions.every(
    ({ setIndex }) => !!uploads[setIndex], // all uploads set
  );

  const allContextualTargetsByType = getContextualSelectorsByType(selectors);

  const hasSelectorsWithNoStops = selectors.some((s) =>
    s.routes.some((r) => r.hasAllStopsUnselected),
  );

  const selectorsKey = getScreenTargetingFieldKey(
    ScreenTargetingErrorKeys.selectors,
    targetingIndex,
  );
  const uploadsLayoutsKey = getScreenTargetingFieldKey(
    ScreenTargetingErrorKeys.uploadsAndLayouts,
    targetingIndex,
  );
  const targetingSelectionTypeKey = getScreenTargetingFieldKey(
    ScreenTargetingErrorKeys.targetingTypeSelection,
    targetingIndex,
  );

  const commonComponents = {
    routeSelector: (
      <div id={selectorsKey}>
        <MultiRouteSelector
          css={css`
            margin-top: 24px;
            width: 62%;
          `}
          onChange={(screenSelectors) => {
            updateTargeting({
              layouts: [],
              screenSelectors,
            });
          }}
          value={screenSelectors}
          showScreensAvailable
          isCampaign
        />
        <StatusBanner
          status="error"
          isVisible={!!errors?.selectors?.length}
          text={errors?.selectors}
          css={({ spacing }: ThemeType) => css`
            margin-top: ${spacing.small};
            margin-bottom: ${spacing.small};
          `}
        />
      </div>
    ),
    nextButton: (
      <Button
        size="small"
        type="button"
        disabled={!layouts || !layouts.length}
        onClick={() => {
          if (hasSelectorsWithNoStops && forceSurfaceErrors) {
            forceSurfaceErrors();
            return;
          }

          let nextActiveStage = activeStage;
          const resetedUploads: {
            [key: string]: CommonUploadFragment;
          } = {};

          positionsAndDimensions.forEach(({ setIndex }) => {
            const uploadKeys = Object.keys(uploads);

            if (uploads[setIndex]) {
              resetedUploads[setIndex] = uploads[setIndex];
            } else if (
              // If there is only one upload, we can assume that it is the one that should be used for all screens.
              uploadKeys.length === 1 &&
              positionsAndDimensions.length === 1
            ) {
              resetedUploads[setIndex] = uploads[uploadKeys[0]];
            }
          });

          if (
            activeStage === CampaignTargetingFormStages.STATION_SCREEN_SELECTION
          ) {
            nextActiveStage =
              CampaignTargetingFormStages.STATION_UPLOAD_CONTENT_SELECTION;
          } else if (
            activeStage === CampaignTargetingFormStages.TRAIN_SCREEN_SELECTION
          ) {
            nextActiveStage =
              CampaignTargetingFormStages.TRAIN_UPLOAD_CONTENT_SELECTION;
          }

          // We reset the uploads when moving to the next stage (i.e. after choosing screen types), to avoid
          // having uploads with a `setIndex` that doesn't match the newly selected screen type(s).

          updateTargeting({
            activeStage: nextActiveStage,
            uploads: resetedUploads,
          });
        }}
      >
        Next
      </Button>
    ),
    uploadRequiredBanner: (
      <StatusBanner
        status="error"
        isVisible={!!errors?.uploadsAndLayouts?.length}
        text={errors?.uploadsAndLayouts}
        css={({ spacing }: ThemeType) => css`
          margin-top: ${spacing.small};
        `}
      />
    ),
    displayFrequencySelector: (
      <DisplayFrequencySelector
        messageType={DispFreqMessageType.campaign}
        onChange={(option) => {
          updateTargeting({
            weightFrequency: {
              priority: option.priority,
              weight: option.weight,
            },
          });
        }}
        value={getDispFreqOption({
          messageType: DispFreqMessageType.campaign,
          priority: weightFrequency.priority,
          weight: weightFrequency.weight,
        })}
        competingContentParams={{
          screenTypes: layouts,
          lines: routes.map((route) => route?.routeId).filter(Boolean),
        }}
      />
    ),
  };

  const { selectedRouteIds, selectedStopIds } = selectors.reduce<{
    selectedRouteIds: string[];
    selectedStopIds: string[];
  }>(
    (selections, selector) => {
      selections.selectedRouteIds.push(...selector.routes.map((r) => r.id));
      selections.selectedStopIds.push(...selector.stops.map((r) => r.id));
      return selections;
    },
    {
      selectedRouteIds: [],
      selectedStopIds: [],
    },
  );

  const commonProps = {
    layoutButtons: {
      noSelectorsEl: <S.NoRoutesMessage />,
      noScreensEl: <S.NoScreensAvailable />,
      onSelect: (s: TargetType) => {
        const screens = [s];

        if (!layouts) {
          updateTargeting({ layouts: screens });
        } else if (!layouts.includes(s)) {
          updateTargeting({ layouts: [...layouts, ...screens] });
        } else {
          updateTargeting({
            layouts: layouts.filter((type) => !screens.includes(type)),
          });
        }
      },
      selected: layouts ?? [],
    },
    accordion: {
      label: (
        <TargetingAccordionLabel
          routeIds={selectedRouteIds}
          stopIds={selectedStopIds}
          trainTargeting={
            selectedRouteIds.length
              ? null
              : {
                  shops,
                  isTargetingAllLirrTrains:
                    trainTargeting?.isTargetingAllLirrTrains ?? false,
                  lines: mnrRollingStockLines,
                }
          }
          screenNumber={targetingIndex + 1}
        />
      ),
      subtitle: getLabelFromTargetTypes(layouts, feedId),
      onDelete: canDelete
        ? () => {
            onTargetingDelete(targetingIndex);
          }
        : undefined,
    },
  };

  const getScreenUploaderCommonProps = ({
    height,
    width,
    hide,
    setIndex,
  }: {
    height: number;
    width: number;
    hide: boolean | undefined;
    setIndex: number;
  }) => ({
    key: setIndex,
    id: setIndex,
    onChange: (id: number, u: CommonUploadFragment | null) => {
      if (u && uploads[id]?.id !== u?.id) {
        updateTargeting({
          uploads: {
            ...targeting.uploads,
            [id]: u,
          },
        });
      }
    },
    height,
    width,
    hide,
    defaultUpload: uploads[setIndex],
  });

  return (
    <S.Section>
      {activeStage === CampaignTargetingFormStages.TARGETING_TYPE_SELECTION && (
        <Accordion label={`Screen ${targetingIndex + 1}`}>
          <div id={targetingSelectionTypeKey}>
            <S.TargetingType>
              <TargetingSelectionButton
                label="Station Screen"
                onClick={() => {
                  updateTargeting({
                    trainTargeting: null,
                    activeStage:
                      CampaignTargetingFormStages.STATION_SCREEN_SELECTION,
                  });
                }}
              />
              {sidewalkTargetingEnabled && (
                <TargetingSelectionButton
                  label="Sidewalk Screen"
                  onClick={() => {
                    updateTargeting({
                      trainTargeting: null,
                      activeStage:
                        CampaignTargetingFormStages.SIDEWALK_UPLOAD_CONTENT_SELECTION,
                      layouts: [TargetType.DUP],
                    });
                  }}
                />
              )}
              {rollingStockEnabled && (
                <TargetingSelectionButton
                  label="Train Screen"
                  onClick={() => {
                    updateTargeting(getInitialPropsForTrainTargeting(feedId));
                  }}
                />
              )}
            </S.TargetingType>
            <StatusBanner
              status="error"
              isVisible={!!errors?.targetingTypeSelection?.length}
              text={errors?.targetingTypeSelection}
              css={({ spacing }: ThemeType) => css`
                margin-top: ${spacing.small};
              `}
            />
          </div>
        </Accordion>
      )}
      {activeStage === CampaignTargetingFormStages.STATION_SCREEN_SELECTION && (
        <Accordion {...commonProps.accordion}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            {commonComponents.routeSelector}
          </div>
          <div id={uploadsLayoutsKey}>
            <ScreenLayoutButtons
              {...commonProps.layoutButtons}
              routeIds={routes.map((route) => route.routeId)}
              stopIds={stops.map((stop) => stop.stopId)}
              excludedTargetTypes={[TargetType.DUP]}
            />
          </div>
          <div
            css={css`
              margin-top: 24px;
              display: flex;
            `}
          >
            {commonComponents.nextButton}
          </div>
          {commonComponents.uploadRequiredBanner}
        </Accordion>
      )}
      {activeStage ===
        CampaignTargetingFormStages.STATION_UPLOAD_CONTENT_SELECTION && (
        <Accordion
          {...{
            ...commonProps.accordion,
            subtitle: `${commonProps.accordion.subtitle} ${availableSpectaculars}`,
          }}
        >
          <Heading level={4}>Will Display At</Heading>
          <CampaignTargeting
            selectors={[
              ...allContextualTargetsByType.routes,
              ...allContextualTargetsByType.stops,
            ]}
            tags={tags}
          />
          <ScreensAvailabilityCount
            numTargeted={availableCount}
            numLocked={lockedCount}
            css={css`
              font-size: ${theme.typography.sizes.small.fontSize};
              margin-bottom: 8px;
            `}
          />
          <Button
            plain
            type="button"
            onClick={() => {
              updateTargeting({
                activeStage:
                  CampaignTargetingFormStages.STATION_SCREEN_SELECTION,
              });
            }}
            size="small"
          >
            <Pencil /> Edit Lines or Stations
          </Button>
          <S.SupportedDimentions dimensions={dimensions} />
          <div
            id={uploadsLayoutsKey}
            css={css`
              display: flex;
              flex-direction: row;
            `}
          >
            {positionsAndDimensions.map(({ hide, setIndex, height, width }) => (
              <ScreenUploader
                {...getScreenUploaderCommonProps({
                  height,
                  width,
                  hide,
                  setIndex,
                })}
                transitSettings={{
                  shouldZip: false,
                  transitDestination: TransitDestination.VLE,
                }}
                header={spectacularScreensLayout ? `Screen ${setIndex}` : ''}
                screenUploadsPerPage={SCREEN_UPLOADS_PAGE}
              />
            ))}
          </div>
          <MotionWarning uploads={uploads} selectors={selectors} />
          {spectacularScreensLayout && (
            <ScreenSpectacularsFooter
              uploads={uploads}
              uploadsAvailable={uploadsAvailable}
              screenUploadsTotal={screenUploadsTotal}
              screenUploadsPage={screenUploadsPage}
              setScreenUploadsPage={setScreenUploadsPage}
              screenUploadsPerPage={SCREEN_UPLOADS_PAGE}
            />
          )}
          {weightingEnabled && commonComponents.displayFrequencySelector}
          {commonComponents.uploadRequiredBanner}
        </Accordion>
      )}
      {activeStage === CampaignTargetingFormStages.TRAIN_SCREEN_SELECTION && (
        <Accordion {...commonProps.accordion}>
          <div id={selectorsKey}>
            <ShopSelector
              value={shops}
              onChange={(shops) => {
                updateTargeting({
                  trainTargeting: {
                    shops,
                  },
                });
              }}
            />
            <StatusBanner
              status="error"
              isVisible={!!errors?.selectors?.length}
              text={errors?.selectors}
              css={({ spacing }: ThemeType) => css`
                margin-top: ${spacing.small};
                margin-bottom: ${spacing.small};
              `}
            />
          </div>
          <div id={uploadsLayoutsKey}>
            <ScreenLayoutButtons {...commonProps.layoutButtons} shops={shops} />
          </div>
          <div
            css={css`
              margin-top: 16px;
              display: flex;
            `}
          >
            {commonComponents.nextButton}
          </div>
          {commonComponents.uploadRequiredBanner}
        </Accordion>
      )}
      {activeStage ===
        CampaignTargetingFormStages.TRAIN_UPLOAD_CONTENT_SELECTION && (
        <Accordion {...commonProps.accordion}>
          {(() => {
            if (feedId === FeedId.NYCTSubway) {
              return (
                <Fragment>
                  <TrainTargetingDisplayGroup
                    trainTargeting={{
                      shops,
                    }}
                    headerText="Will Display On"
                  />
                  <ScreensAvailabilityCount
                    numTargeted={availableCount}
                    numLocked={lockedCount}
                    isCove={COVES_TARGET_TYPES.has(layouts[0])}
                    coveMultiplier={getCoveMultiplier(layouts[0])}
                    css={css`
                      font-size: ${theme.typography.sizes.small.fontSize};
                      margin-bottom: 8px;
                    `}
                  />
                  <Button
                    plain
                    type="button"
                    onClick={() => {
                      updateTargeting({
                        activeStage:
                          CampaignTargetingFormStages.TRAIN_SCREEN_SELECTION,
                      });
                    }}
                    size="small"
                  >
                    <Pencil /> Edit Lines
                  </Button>
                </Fragment>
              );
            }

            if (feedId === FeedId.MNR) {
              return (
                <Fragment>
                  <MnrRollingStockLineSelector
                    onChange={(lines) => {
                      updateTargeting({
                        trainTargeting: {
                          lines,
                        },
                      });
                    }}
                    value={mnrRollingStockLines}
                  />
                  <StatusBanner
                    status="error"
                    isVisible={!!errors?.selectors?.length}
                    text={errors?.selectors}
                    css={({ spacing }: ThemeType) => css`
                      margin-top: ${spacing.small};
                      margin-bottom: ${spacing.small};
                    `}
                  />
                </Fragment>
              );
            }

            if (feedId === FeedId.LIRR) {
              return (
                <TrainTargetingDisplayGroup
                  trainTargeting={{
                    isTargetingAllLirrTrains: true,
                  }}
                  headerText="Will Display On"
                />
              );
            }

            return null;
          })()}
          {!!availableCount && (
            <S.SupportedDimentions dimensions={dimensions} joinFileFormats />
          )}
          <div
            id={uploadsLayoutsKey}
            css={css`
              display: flex;
              flex-wrap: wrap;

              & > div {
                margin-bottom: 8px;
              }
            `}
          >
            {feedId === FeedId.MNR && !availableCount && (
              <div
                css={css`
                  display: flex;
                  flex: 1;
                `}
              >
                {mnrRollingStockLines?.length ? (
                  <S.NoScreensAvailable />
                ) : (
                  <S.NoRoutesMessage />
                )}
              </div>
            )}
            {feedId === FeedId.LIRR && !availableCount && (
              <div
                css={css`
                  display: flex;
                  flex: 1;
                `}
              >
                <S.NoScreensAvailable />
              </div>
            )}
            {!!availableCount &&
              positionsAndDimensions.map(
                ({ hide, setIndex, height, width }) => (
                  <ScreenUploader
                    {...getScreenUploaderCommonProps({
                      height,
                      width,
                      hide,
                      setIndex,
                    })}
                    header={
                      COVES_TARGET_TYPES.has(layouts[0])
                        ? `Screen ${setIndex + 1}`
                        : ''
                    }
                    transitSettings={{
                      transitDestination: TransitDestination.BROADSIGN,
                    }}
                    scale={
                      COVES_TARGET_TYPES.has(layouts[0]) ? 0.16 : undefined
                    }
                    multiplier={
                      COVES_TARGET_TYPES.has(layouts[0]) ? 0.25 : undefined
                    }
                  />
                ),
              )}
          </div>
          {weightingEnabled && commonComponents.displayFrequencySelector}
          {commonComponents.uploadRequiredBanner}
        </Accordion>
      )}
      {activeStage ===
        CampaignTargetingFormStages.SIDEWALK_UPLOAD_CONTENT_SELECTION && (
        <Accordion {...commonProps.accordion}>
          <div
            id={selectorsKey}
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <SidewalkScreenSelector
              onChange={(screenSelectors) =>
                updateTargeting({
                  screenSelectors,
                })
              }
              value={screenSelectors.map(({ selector, tags }) => ({
                targetTypes: [],
                selector,
                tags,
              }))}
            />
            <StatusBanner
              status="error"
              isVisible={!!errors?.selectors?.length}
              text={errors?.selectors}
              css={({ spacing }: ThemeType) => css`
                margin-top: ${spacing.small};
              `}
            />
          </div>
          {!!availableCount && (
            <S.SupportedDimentions dimensions={dimensions} joinFileFormats />
          )}
          <div
            id={uploadsLayoutsKey}
            css={css`
              display: flex;
              flex-wrap: wrap;

              & > div {
                margin-bottom: 8px;
              }
            `}
          >
            {!availableCount && (
              <div
                css={css`
                  display: flex;
                  flex: 1;
                `}
              >
                {selectors.some((s) => s.routes.length || s.stops.length) ? (
                  <S.NoScreensAvailable />
                ) : (
                  <S.NoRoutesMessage />
                )}
              </div>
            )}
            {!!availableCount &&
              positionsAndDimensions.map(
                ({ hide, setIndex, height, width }) => (
                  <ScreenUploader
                    {...getScreenUploaderCommonProps({
                      height,
                      width,
                      hide,
                      setIndex,
                    })}
                    scale={0.17}
                    multiplier={0.5}
                    transitSettings={{
                      shouldZip:
                        uploads[setIndex]?.mimeType !== 'application/zip',
                      transitDestination: TransitDestination.VLE,
                    }}
                    screenUploadsPerPage={SCREEN_UPLOADS_PAGE}
                  />
                ),
              )}
          </div>
          {commonComponents.uploadRequiredBanner}
        </Accordion>
      )}
    </S.Section>
  );
};

export default CampaignScreenTargeting;
