/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import Theme from '../../theme';

type TDirections = 'up' | 'right' | 'down' | 'left';

const ChevronIcon: React.FC<{
  className?: string;
  color?: string;
  direction?: TDirections;
  size?: string;
  children?: React.ReactNode;
}> = ({
  className,
  color = Theme.colors.accent1,
  direction = 'down',
  size = '16px',
}) => {
  let rotateDeg = '0deg';

  if (direction === 'up') {
    rotateDeg = '180deg';
  } else if (direction === 'right') {
    rotateDeg = '-90deg';
  } else if (direction === 'left') {
    rotateDeg = '90deg';
  }

  return (
    <div
      className={className}
      css={css`
        transform: rotate(${rotateDeg});
        transition: transform 0.2s ease-in-out;
      `}
    >
      <svg width={size} height={size} viewBox="0 0 17 16">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(0.608696, 0.000000)"
            fill={color}
            fillRule="nonzero"
          >
            <path d="M12.7137101,5.22034955 L7.99526002,9.93256514 L3.27724751,5.22034955 C2.98307952,4.92655015 2.50564736,4.92655015 2.21147937,5.22034955 L2.14932806,5.29271639 C1.9407747,5.57547044 1.94583079,6.03754342 2.19509164,6.27701245 L2.23153335,6.30649312 L7.46230301,11.5301921 C7.75711771,11.8231296 8.23325646,11.8231296 8.52723044,11.5310296 L13.7794783,6.28536503 C14.0735072,5.99020069 14.0735072,5.51401007 13.7794783,5.22034955 C13.4853103,4.92655015 13.0078781,4.92655015 12.7137101,5.22034955 Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default ChevronIcon;
