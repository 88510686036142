/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import theme from 'theme';
import Datepicker from 'components/common/form-elements/datepicker';
import LabelledInput from './input-label';

const separatorColorForState = (
  isFocused: boolean,
  isDisabled: boolean,
): string => {
  if (isFocused) {
    return theme.colors.accent1;
  }
  if (isDisabled) {
    return theme.colors.light6;
  }
  return theme.colors.light1;
};

const DateTimePicker: React.FC<
  {
    label: string;
    timeValue: string;
    dateValue: string;
    onChangeTime: (time: string) => void;
    onChangeDate: (date: Date) => void;
    onBlur: (event: React.ChangeEvent) => void;
    error?: boolean;
    disabled?: boolean;
    labelSize?: any;
    width?: string;
    height?: string;
    backgroundColor?: string;
    separatorColor?: string;
    timeInputPaddingLeft?: string;
  } & { children?: React.ReactNode }
> = ({
  label,
  timeValue,
  dateValue,
  onChangeTime,
  onChangeDate,
  onBlur,
  error = false,
  disabled = false,
  labelSize = 'small',
  height = '39px',
  width = '130px',
  backgroundColor,
  separatorColor,
  timeInputPaddingLeft,
}) => {
  const [isTimeInputFocused, setIsTimeInputFocused] = useState(false);
  const [isDateInputFocused, setIsDateInputFocused] = useState(false);

  const isFocused = isTimeInputFocused || isDateInputFocused;
  const sepColor = error
    ? theme.colors['status-error']
    : separatorColor || separatorColorForState(isFocused, disabled);

  const initialDateValue = new Date(dateValue);
  const dateStrValue = `${
    initialDateValue.getMonth() + 1
  }/${initialDateValue.getDate()}`;

  return (
    <span>
      <LabelledInput label={label} invalid={error} labelSize={labelSize}>
        <div
          css={css`
            position: relative;
            display: inline-flex;
            width: ${width};
            height: ${height};
            padding-top: 1px;
          `}
        >
          <div
            css={() => css`
              flex-basis: 50%;
            `}
          >
            <input
              value={timeValue}
              disabled={disabled}
              onChange={(e) => onChangeTime(e.target.value)}
              maxLength={5}
              onFocus={() => setIsTimeInputFocused(true)}
              onBlur={(e) => {
                setIsTimeInputFocused(false);
                onBlur(e);
              }}
              autoComplete="off"
              css={(theme) => css`
                color: ${theme.colors.dark2};
                ${theme.typography.sizes.small};
                border: 0;
                width: 100%;
                border-right: 1px solid ${sepColor};
                padding-left: ${timeInputPaddingLeft || theme.spacing.xsmall};
                position: relative;
                font-size: ${theme.typography.sizes.medium.fontSize};
                background-color: ${backgroundColor ?? 'transparent'};
                border-radius: 4px 0 0 4px;
                box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
                &::placeholder {
                  color: ${theme.colors.dark2};
                  font-size: ${theme.typography.sizes.medium.fontSize};
                }
                :not(:focus-within) {
                  border-right: 1px solid ${theme.colors.light1};
                }
              `}
              placeholder="hh:mm"
            />
          </div>
          <Datepicker
            onFocus={() => setIsDateInputFocused(true)}
            onBlur={() => setIsDateInputFocused(false)}
            value={dateStrValue}
            selected={initialDateValue}
            disabled={disabled}
            separateLabel
            css={css`
              flex-basis: 50%;
              input {
                text-align: center;
                max-height: calc(${height} - 1px);
                border-radius: 0 4px 4px 0;
                ${theme.typography.sizes.medium};
                color: ${theme.colors.dark2};
                background-color: ${backgroundColor ?? 'transparent'};
                margin-left: -0.6px;
                padding-right: 10px;
                padding-left: 10px;
                :not(:focus-within) {
                  border-left: unset;
                }
                &:disabled {
                  color: #aaa;
                }
              }
            `}
            onChange={onChangeDate}
            placeholderText="mm/dd"
            dateFormat="MM/dd"
            inputCss={css`
              box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
              &:focus {
                box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
              }
            `}
          />
        </div>
      </LabelledInput>
    </span>
  );
};

export default DateTimePicker;
