/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ThemeType } from 'theme';
import { input as inputCSS } from '../../common/styles';

export const Input: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { children?: React.ReactNode }
> = (props) => (
  <input
    css={(theme: ThemeType) => css`
      ${inputCSS}
      box-sizing: border-box;
      padding: ${theme.spacing.xsmall} ${theme.spacing.small};
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors['border-dark']};
      border-radius: 4px;
      box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
    `}
    {...props}
  />
);

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      margin-top: 1rem;
      margin-bottom: 1rem;
      background-color: ${theme.colors.white};
      border-radius: 8px;
      padding: 5px 20px 40px 40px;
      box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    `}
    {...props}
  />
);
