/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const Grid: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: max-content;
      grid-gap: 4px;
    `}
    {...props}
  />
);
