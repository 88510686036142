/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import SkeletonLoader from './SkeletonLoader';

const AlertCardSkeleton: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  return (
    <div
      css={(theme) => css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 40px;
        background-color: ${theme.colors.white};
        border-radius: 4px;
      `}
    >
      <SkeletonLoader height="220">
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="20" />
        <rect
          x="0"
          y="50"
          rx="0"
          ry="0"
          width="calc(100% - 50px)"
          height="20"
        />
        <rect x="0" y="100" rx="0" ry="0" width="100%" height="20" />
        <rect
          x="0"
          y="150"
          rx="0"
          ry="0"
          width="calc(100% - 50px)"
          height="20"
        />
        <rect x="0" y="200" rx="0" ry="0" width="100%" height="20" />
      </SkeletonLoader>
    </div>
  );
};

export default AlertCardSkeleton;
