/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

export const Buttons: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={() => css`
      display: flex;
      flex-direction: row-reverse;

      & > * {
        margin-left: 12px;
      }
    `}
    {...props}
  />
);
