export const getFlattenedGtfsKey = (id) => {
    return id.toLowerCase().replace(/:+/g, '-');
};
export const SHORT_GTFS_TEXT_REPLACEMENTS = {
    'mtasbwy-gs': '42 St S',
    'mtasbwy-h': 'Rockaway Park S',
    'mtasbwy-fs': 'Franklin Av S', // SF
};
export const LONG_GTFS_TEXT_REPLACEMENTS = {
    'mtasbwy-gs': '42 St Shuttle',
    'mtasbwy-h': 'Rockaway Park Shuttle',
    'mtasbwy-fs': 'Franklin Av Shuttle', // SF
};
export const GTFS_RT_TEXT_REPLACEMENTS = {
    'mtasbwy-gs': 'S',
    'mtasbwy-h': 'H',
    'mtasbwy-fs': 'FS', // SF
};
export const SHUTTLE_TRAINS = ['S', 'H', 'FS'];
export const getGtfsTextReplacement = ({ id, isShort, }) => {
    const flattenedKey = getFlattenedGtfsKey(id);
    if (isShort) {
        return SHORT_GTFS_TEXT_REPLACEMENTS[flattenedKey];
    }
    return LONG_GTFS_TEXT_REPLACEMENTS[flattenedKey];
};
