/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ThemeType } from '../../theme';

export const Header: React.FC<{
  loading?: boolean;
  children?: React.ReactNode;
}> = ({ loading, ...props }) => (
  <header
    css={(theme: ThemeType) => css`
      background-color: ${theme.colors.white};
      ${loading
        ? `border-bottom: 5px solid ${theme.colors.border};`
        : 'box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.2);'}
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: ${theme.spacing.large};
      width: 100%;
    `}
    {...props}
  />
);

export const BCList: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <ul
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.xsmall};
      display: flex;
      flex-direction: row;
      font-family: ${theme.typography.families.primary};
      list-style: none;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
      font-weight: ${theme.typography.weights.bold};
      color: ${theme.colors.dark2};
      margin-bottom: ${theme.spacing.xxsmall};
    `}
    {...props}
  />
);

export const BCListItem: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <li
    css={(theme: ThemeType) => css`
      &::before {
        content: ' › ';
        display: inline-block;
        padding: 0 ${theme.spacing.xxxsmall};
      }

      &:first-of-type {
        &::before {
          display: none;
        }
      }

      a {
        color: ${theme.colors.accent1};

        &:hover {
          color: ${theme.colors.brand};
        }
      }
    `}
    {...props}
  />
);

export const HeaderChildContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      flex: 1 1 100%;
    `}
    {...props}
  />
);

export const ButtonList: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: row;
      flex: 0 0 auto;
      align-items: center;

      > button,
      > a {
        margin-left: ${theme.spacing.xsmall};
      }
    `}
    {...props}
  />
);
