/** @jsxImportSource @emotion/react */

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  PropsWithChildren,
} from 'react';
import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { prosemirrorNodeToHtml as toHTML, EditorState } from 'remirror';
import _uniq from 'lodash/uniq';

import InputLabel from 'components/common/form-elements/input-label';
import { input as inputCss, flexRow } from 'components/common/styles';
import AlertScreenPreview from 'ui-kit/alert-screen-preview/alert-screen-preview';
import { MESSAGE_TYPE_DESCRIPTIONS } from '@mta-live-media-manager/shared';
import {
  getSuggestedDisplayType,
  ScreenPreviewDisplayTypes,
} from 'utils/screen-preview';

import {
  FeatureFlagName,
  MessageType,
  TargetType,
} from 'generated/global-types';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useDebouncedCallback } from 'use-debounce';

import HR from 'components/common/HR';
import { modifyIndexInArray } from 'utils/compose-helpers';
import { getScreenSubtitle } from 'components/pages/Screen/in-screens-preview/helpers';
import ScreensAccordion from 'components/common/screens-accordion';
import { TARGETING_TYPE_REQUIRED } from 'constants/error-messages';
import TargetingAccordionLabel from 'components/common/screen-targeting-accordion-label';
import Editor, {
  getMentionsByType,
  getInitialContent,
} from '../../../common/form-elements/editor';
import DurationPicker from '../../../common/form-elements/expiration-time-picker';

import * as CS from '../../../common/common-forms.styled';
import Heading from '../../../common/Heading';
import { ThemeType } from '../../../../theme';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message_screenMessages_ScreenMessagesConnection_nodes_ScreenMessage as GetEventById_event_messages_nodes_screenMessages_nodes } from '../../../../generated/GetEventById';
import RadioButton from '../../../common/RadioButton';
import Button from '../../../common/Button';

import * as Layout from '../../../common/layout';
import { registerScreenTargeting } from '../../../../utils/screen-targeting';
import {
  AlertScreenTargeting,
  RouteMention,
  ScreenData,
  ScreenTarget,
  ScreenUpdateMethods,
  StopMention,
  TImpact,
} from '../../../../types';
import ScreenTargetingContainer from '../../../common/screen-targeting-container';
import ScreenSelector from '../../../common/ScreenSelector';
import {
  SCREEN_TARGETING_NAME,
  SCREEN_BODY_NAME,
  SCREEN_BODY_ADDITIONAL_NAME,
  WEB_BODY_NAME,
  WEB_BODY_ADDITIONAL_NAME,
  WEB_EXPIRATION_NAME,
  SCREEN_TARGETED_ROUTES_NAME,
  SCREEN_CUSTOMIZED_NAME,
  SCREEN_UPDATE_METHOD_NAME,
  WEB_IMPACTS_NAME,
  SCREEN_TITLE_NAME,
  INITIAL_SCREEN_CHOICE_NAME,
  SCREENS_NAME,
  SCREEN_IS_DUPLICATE_NAME,
} from '../../../../constants/alerts';
import {
  getMessageType,
  getAllRoutesFromImpacts,
} from '../../../common/compose/impact-selector';

import StatusBanner from '../../../common/status-banner';
import { useRoutesByFeedId } from '../../../../contexts/Routes';
import { useFeedId } from '../../../../contexts/FeedId';
import PreviousScreenMessage from './previous-screen-message';
import * as S from '../../../common/compose/ComposeScreens/index.styled';

const DEBOUNCE_ON_CHANGE_MS = 300;

const ScreensContainer: React.FC<PropsWithChildren> = (props) => (
  <div
    css={[
      flexRow,
      css`
        justify-content: space-between;
        margin-top: 12px;
      `,
    ]}
    {...props}
  />
);
const ScreensContentContainer: React.FC<PropsWithChildren> = (props) => (
  <div css={{ flex: 1 }} {...props} />
);
const ScreensPreviewContainer: React.FC<PropsWithChildren> = (props) => (
  <div
    css={css`
      margin-left: 32px;
      flex: 0 0 300px;
    `}
    {...props}
  />
);

interface UpdateScreensProps {
  show: boolean;
  previousScreenTargeting: ScreenTarget[];
  previousMessageType: string;
  previousAuthorName?: string;
  previousMessageImpacts?: TImpact[];
  previousScreenMessage?: GetEventById_event_messages_nodes_screenMessages_nodes;
  previousTitle?: string;
  index: number;
  onDelete: () => void;
  isClearing: boolean;
}

const UpdateScreens: React.FC<UpdateScreensProps> = ({
  show,
  previousScreenTargeting,
  previousAuthorName,
  previousMessageType,
  previousMessageImpacts = [],
  previousScreenMessage,
  index,
  onDelete,
  isClearing,
}) => {
  const {
    register,
    unregister,
    watch,
    setValue,
    setError,
    clearError,
    errors,
    triggerValidation,
  } = useFormContext();

  const hasScreenBodyError = errors
    ? errors[`${SCREEN_BODY_NAME}_${index}`]?.type ===
      `${SCREEN_BODY_NAME}_${index}`
    : false;

  const hasInitialScreenError = errors
    ? errors[`${INITIAL_SCREEN_CHOICE_NAME}_${index}`]?.type ===
      `${INITIAL_SCREEN_CHOICE_NAME}_${index}`
    : false;

  const showScreenTitle = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERTS_SCREEN_TITLE,
  );

  const feedId = useFeedId();
  const allRoutes = useRoutesByFeedId(feedId);

  const webExpiration = watch(WEB_EXPIRATION_NAME) as Date;
  const webBody = watch(WEB_BODY_NAME) as EditorState | undefined;
  const webAdditionalBody = watch(WEB_BODY_ADDITIONAL_NAME) as
    | EditorState
    | undefined;
  const webImpacts = watch(WEB_IMPACTS_NAME) as TImpact[];
  const allImpactedRouteMentions = getAllRoutesFromImpacts(webImpacts);
  const allAffectedRoutes = allRoutes.filter((route) =>
    allImpactedRouteMentions.find(
      (mention) => mention.routeId === route.gtfsId,
    ),
  );
  const allRoutesAffected = allRoutes.length === allAffectedRoutes.length;
  const prevAllImpactedRouteMentions = getAllRoutesFromImpacts(
    previousMessageImpacts,
  );
  const prevAllAffectedRoutes = allRoutes.filter((route) =>
    prevAllImpactedRouteMentions.find(
      (mention) => mention.routeId === route.gtfsId,
    ),
  );
  const prevAllRoutesAffected =
    allRoutes.length === prevAllAffectedRoutes.length;
  const screens = watch(SCREENS_NAME) as ScreenData[];
  const screenBody = screens[index][SCREEN_BODY_NAME] as
    | EditorState
    | undefined;

  const screenAdditionalBody = screens[index][SCREEN_BODY_ADDITIONAL_NAME] as
    | EditorState
    | undefined;
  const screenCustomized = screens[index][SCREEN_CUSTOMIZED_NAME] as boolean;
  const screenTitle = screens[index][SCREEN_TITLE_NAME] as string;

  const descriptionStateRef = useRef<EditorState>();
  const additionalInfoStateRef = useRef<EditorState>();

  const [localScreenTitle, setLocalScreenTitle] = useState<string>(
    screenTitle ?? '',
  );
  const [isScreenTitleCustomized, setIsScreenTitleCustomized] =
    useState(!!screenTitle);
  const [localEditorState, setLocalEditorState] = useState<EditorState>();
  const [localAdditionalEditorState, setLocalAdditionalEditorState] =
    useState<EditorState>();
  const [additionalEditorKeyCount, setAdditionalEditorKeyCount] = useState(0);
  const [copyFromPreviousMessageCount, setCopyFromPreviousMessageCount] =
    useState(0);
  const [routesInEditor, setRoutesInEditor] = useState<RouteMention[]>([]);
  const [stopsInEditor, setStopsInEditor] = useState<StopMention[]>([]);
  const [usePreviousMessage, setUsePreviousMessage] = useState(
    screens[index][SCREEN_IS_DUPLICATE_NAME] ?? false,
  );

  const screenRoutes: RouteMention[] = watch(SCREEN_TARGETED_ROUTES_NAME) || [];
  const relatedGtfsIds = _uniq(
    [...screenRoutes, ...routesInEditor, ...stopsInEditor].map(
      (mention) => mention.id,
    ),
  );
  const initialScreenChoice = screens[index][INITIAL_SCREEN_CHOICE_NAME] as
    | AlertScreenTargeting
    | undefined;
  const selectedRoutes = screens[index][
    SCREEN_TARGETING_NAME
  ] as ScreenTarget[];

  const screenUpdateMethod = screens[index][
    SCREEN_UPDATE_METHOD_NAME
  ] as ScreenUpdateMethods;
  const messageType = getMessageType(webImpacts);
  const messageTypeDescription = messageType
    ? MESSAGE_TYPE_DESCRIPTIONS[messageType]
    : '';

  const sidewalkScreenEnabled = useFeatureFlag(
    FeatureFlagName.SIDEWALK_SCREEN_TARGETING,
  );

  useEffect(() => {
    if (showScreenTitle) {
      register({ name: SCREEN_TITLE_NAME });
    }
  }, [register, showScreenTitle]);

  useEffect(() => {
    register({
      name: SCREENS_NAME,
    });
    register({ name: SCREEN_TARGETED_ROUTES_NAME });
    registerScreenTargeting(register);

    // Data is being saved in SCREENS_NAME, the rest of these register functions are being used for validations
    register(
      {
        name: `${INITIAL_SCREEN_CHOICE_NAME}_${index}`,
      },
      {
        validate: {
          [`${INITIAL_SCREEN_CHOICE_NAME}_${index}`]: () =>
            show &&
            sidewalkScreenEnabled &&
            !screens[index][SCREEN_UPDATE_METHOD_NAME]
              ? !!screens[index][INITIAL_SCREEN_CHOICE_NAME]
              : true,
        },
      },
    );
    register(
      {
        name: `${SCREEN_BODY_NAME}_${index}`,
      },
      {
        validate: {
          [`${SCREEN_BODY_NAME}_${index}`]: () =>
            show
              ? screens[index][SCREEN_BODY_NAME]?.doc.textContent.length !== 0
              : true,
        },
      },
    );
    register(
      {
        name: `${SCREEN_TARGETING_NAME}_${index}`,
      },
      {
        validate: {
          [`${SCREEN_TARGETING_NAME}_${index}`]: () =>
            show
              ? screens[index][SCREEN_TARGETING_NAME].every(
                  (t) => t.options && t.options.length,
                )
              : true,
        },
      },
    );
  }, [
    show,
    screens,
    screenUpdateMethod,
    register,
    setError,
    clearError,
    index,
    screenBody,
    sidewalkScreenEnabled,
  ]);

  const bodyHtml = useMemo(() => {
    return screenBody ? toHTML(screenBody.doc) : '';
  }, [screenBody]);

  const additionalBodyHtml = useMemo(() => {
    return screenAdditionalBody ? toHTML(screenAdditionalBody.doc) : '';
  }, [screenAdditionalBody]);

  useEffect(() => {
    if (!webAdditionalBody) {
      setAdditionalEditorKeyCount((count) => count + 1);
      setLocalAdditionalEditorState(undefined);
      if (screenAdditionalBody !== undefined) {
        setValue(
          SCREENS_NAME,
          modifyIndexInArray({
            index,
            array: screens,
            value: {
              ...screens[index],
              [SCREEN_BODY_ADDITIONAL_NAME]: undefined,
            },
          }),
        );
      }
    }
  }, [webAdditionalBody, screenAdditionalBody, setValue, index, screens]);

  useEffect(() => {
    if (!show) {
      clearError(`${SCREEN_BODY_NAME}_${index}`);
      clearError(SCREEN_TARGETING_NAME);
    }
  }, [show, clearError, index]);

  useEffect(() => {
    if (!localScreenTitle && !isScreenTitleCustomized) {
      setLocalScreenTitle(screenTitle);
      setIsScreenTitleCustomized(screenCustomized);
    }
  }, [
    isScreenTitleCustomized,
    screenTitle,
    localScreenTitle,
    screenCustomized,
  ]);

  const [debouncedScreenTitleUpdate] = useDebouncedCallback((value: string) => {
    setValue(
      SCREENS_NAME,
      modifyIndexInArray({
        index,
        array: screens,
        value: {
          ...screens[index],
          [SCREEN_TITLE_NAME]: value,
        },
      }),
    );
  }, DEBOUNCE_ON_CHANGE_MS);

  const copyFromPreviousMessage = () => {
    setUsePreviousMessage(true);
    setCopyFromPreviousMessageCount(copyFromPreviousMessageCount + 1);

    /*
      Clear old state to avoid issues.
    */

    setValue(
      SCREENS_NAME,
      modifyIndexInArray({
        index,
        array: screens,
        value: {
          ...screens[index],
          [SCREEN_BODY_NAME]: undefined,
          [SCREEN_BODY_ADDITIONAL_NAME]: undefined,
          [SCREEN_CUSTOMIZED_NAME]: true,
          ...(previousScreenMessage?.title
            ? {
                [SCREEN_TITLE_NAME]: previousScreenMessage.title,
              }
            : {}),
        },
      }),
    );

    setLocalEditorState(undefined);
    setLocalAdditionalEditorState(undefined);

    clearError(SCREEN_BODY_NAME);

    if (previousScreenMessage?.title) {
      setLocalScreenTitle(previousScreenMessage?.title);
      setIsScreenTitleCustomized(true);
    }
  };

  const screenPreviewDisplayType = getSuggestedDisplayType(feedId);

  const isSidewalkScreen =
    initialScreenChoice === AlertScreenTargeting.SIDEWALK_SCREEN ||
    selectedRoutes[0]?.layouts[0] === TargetType.DUP;

  const { previousScreenTargetingRouteIds, previousScreenTargetingStopIds } =
    previousScreenTargeting.reduce<{
      previousScreenTargetingRouteIds: string[];
      previousScreenTargetingStopIds: string[];
    }>(
      (selections, selector) => {
        selections.previousScreenTargetingRouteIds.push(selector.routeId);
        selector.options.forEach((o) => {
          if (o.stopId) {
            selections.previousScreenTargetingStopIds.push(o.stopId);
          }
        });
        return selections;
      },
      {
        previousScreenTargetingRouteIds: [],
        previousScreenTargetingStopIds: [],
      },
    );

  const { selectedRoutesRouteIds, selectedRoutesStopIds } =
    previousScreenTargeting.reduce<{
      selectedRoutesRouteIds: string[];
      selectedRoutesStopIds: string[];
    }>(
      (selections, selector) => {
        selections.selectedRoutesRouteIds.push(selector.routeId);
        selector.options.forEach((o) => {
          if (o.stopId) {
            selections.selectedRoutesStopIds.push(o.stopId);
          }
        });
        return selections;
      },
      {
        selectedRoutesRouteIds: [],
        selectedRoutesStopIds: [],
      },
    );

  return (
    <React.Fragment>
      <HR />
      <ScreensAccordion
        label={
          <TargetingAccordionLabel
            routeIds={
              screenUpdateMethod === ScreenUpdateMethods.KEEP
                ? previousScreenTargetingRouteIds
                : selectedRoutesRouteIds
            }
            stopIds={
              screenUpdateMethod === ScreenUpdateMethods.KEEP
                ? previousScreenTargetingStopIds
                : selectedRoutesStopIds
            }
            screenNumber={index}
          />
        }
        subtitle={
          initialScreenChoice || screenUpdateMethod
            ? getScreenSubtitle({
                targetTypes: selectedRoutes.flatMap((v) => v.layouts),
              })
            : undefined
        }
        canDelete={!screenUpdateMethod && screens?.length > 1}
        onDelete={() => {
          unregister(`${INITIAL_SCREEN_CHOICE_NAME}_${screens.length - 1}`);
          unregister(`${SCREEN_BODY_NAME}_${screens.length - 1}`);
          unregister(`${SCREEN_TARGETING_NAME}_${screens.length - 1}`);
          onDelete();
        }}
        additionalCss={css`
          margin: 0 38px;
        `}
        index={index}
        isClearing={isClearing}
      >
        {screenUpdateMethod && (
          <Layout.Row>
            <div
              css={css`
                flex: 1;
                min-width: 0;
              `}
            >
              <ScreensContainer>
                <ScreensContentContainer>
                  <PreviousScreenMessage
                    css={css`
                      margin-bottom: 24px;
                    `}
                    headerText="Previous Message"
                    headerLevel={4}
                    title={previousScreenMessage?.title ?? undefined}
                    html={previousScreenMessage?.bodyHtml}
                    publishAt={previousScreenMessage?.publishAt}
                    authorName={previousAuthorName}
                  />
                  <ScreenTargetingContainer
                    headerText="Displays At"
                    targeting={
                      previousScreenMessage && previousScreenMessage.targeting
                    }
                  />
                </ScreensContentContainer>
                <ScreensPreviewContainer
                  css={css`
                    flex-basis: ${screenPreviewDisplayType ===
                    ScreenPreviewDisplayTypes.PORTRAIT
                      ? 300
                      : 375}px;
                  `}
                >
                  {previousScreenMessage && (
                    <div
                      css={css`
                        width: ${screenPreviewDisplayType ===
                        ScreenPreviewDisplayTypes.PORTRAIT
                          ? 225
                          : 300}px;
                      `}
                    >
                      <Heading
                        css={css`
                          margin-bottom: 8px;
                        `}
                        level={5}
                        size="medium"
                      >
                        Previous Screen
                      </Heading>
                      <AlertScreenPreview
                        messageType={previousMessageType as MessageType}
                        title={previousScreenMessage.title ?? undefined}
                        content={previousScreenMessage.bodyHtml}
                        additionalContent={
                          previousScreenMessage?.additionalInfoHtml || ''
                        }
                        affectedRoutes={prevAllAffectedRoutes}
                        allRoutesAffected={prevAllRoutesAffected}
                        publishAt={previousScreenMessage.publishAt}
                        displayType={
                          isSidewalkScreen
                            ? ScreenPreviewDisplayTypes.DUP
                            : screenPreviewDisplayType
                        }
                      />
                    </div>
                  )}
                </ScreensPreviewContainer>
              </ScreensContainer>
            </div>
          </Layout.Row>
        )}
        {previousScreenMessage && screenUpdateMethod && !isClearing && (
          <React.Fragment>
            <Layout.Row>
              <div
                css={css`
                  flex: 1;
                  min-width: 0;
                  flex-wrap: nowrap;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-bottom: 20px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <RadioButton
                    id={`updateMethod-keep[${index}]`}
                    name={`updateMethod[${index}]`}
                    onChange={(isChecked) =>
                      isChecked &&
                      setValue(
                        SCREENS_NAME,
                        modifyIndexInArray({
                          index,
                          array: screens,
                          value: {
                            ...screens[index],
                            [SCREEN_UPDATE_METHOD_NAME]:
                              ScreenUpdateMethods.KEEP,
                          },
                        }),
                      )
                    }
                    size={20}
                    checked={screenUpdateMethod === ScreenUpdateMethods.KEEP}
                  />
                  <label
                    css={(theme: ThemeType) => css`
                      margin-left: 8px;
                      font-weight: ${theme.typography.weights.bold};
                    `}
                    htmlFor={`updateMethod-keep[${index}]`}
                  >
                    Keep Active
                  </label>
                </div>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-left: 24px;
                  `}
                >
                  <RadioButton
                    id={`updateMethod-replace[${index}]`}
                    name={`updateMethod[${index}]`}
                    onChange={(isChecked) =>
                      isChecked &&
                      setValue(
                        SCREENS_NAME,
                        modifyIndexInArray({
                          index,
                          array: screens,
                          value: {
                            ...screens[index],
                            [SCREEN_UPDATE_METHOD_NAME]:
                              ScreenUpdateMethods.REPLACE,
                          },
                        }),
                      )
                    }
                    size={20}
                    checked={screenUpdateMethod === ScreenUpdateMethods.REPLACE}
                  />
                  <label
                    css={(theme: ThemeType) => css`
                      margin-left: 8px;
                      font-weight: ${theme.typography.weights.bold};
                    `}
                    htmlFor={`updateMethod-replace[${index}]`}
                  >
                    Replace with update
                  </label>
                </div>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-left: 24px;
                  `}
                >
                  <RadioButton
                    id={`updateMethod-remove[${index}]`}
                    name={`updateMethod[${index}]`}
                    onChange={(isChecked) =>
                      isChecked &&
                      setValue(
                        SCREENS_NAME,
                        modifyIndexInArray({
                          index,
                          array: screens,
                          value: {
                            ...screens[index],
                            [SCREEN_UPDATE_METHOD_NAME]:
                              ScreenUpdateMethods.REMOVE,
                          },
                        }),
                      )
                    }
                    size={20}
                    checked={screenUpdateMethod === ScreenUpdateMethods.REMOVE}
                  />
                  <label
                    css={(theme: ThemeType) => css`
                      margin-left: 8px;
                      font-weight: ${theme.typography.weights.bold};
                    `}
                    htmlFor={`updateMethod-remove[${index}]`}
                  >
                    Remove
                  </label>
                </div>
              </div>
            </Layout.Row>
            {screenUpdateMethod !== ScreenUpdateMethods.KEEP && <HR />}
          </React.Fragment>
        )}
        {!initialScreenChoice &&
          !screenUpdateMethod &&
          sidewalkScreenEnabled && (
            <React.Fragment>
              <Layout.Row>
                <S.Screens.Section
                  css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin: 40px 0;
                  `}
                >
                  <Button
                    primary
                    type="button"
                    onClick={() => {
                      setValue(
                        SCREENS_NAME,
                        modifyIndexInArray({
                          index,
                          array: screens,
                          value: {
                            ...screens[index],
                            [INITIAL_SCREEN_CHOICE_NAME]:
                              AlertScreenTargeting.STATION_SCREEN,
                          },
                        }),
                      );
                    }}
                    additionalStyles={css`
                      margin-right: 12px;
                      min-width: 48%;
                      min-height: 40px;
                    `}
                    size="large"
                  >
                    Station Screen
                  </Button>
                  <Button
                    primary
                    type="button"
                    onClick={() => {
                      setValue(
                        SCREENS_NAME,
                        modifyIndexInArray({
                          index,
                          array: screens,
                          value: {
                            ...screens[index],
                            [INITIAL_SCREEN_CHOICE_NAME]:
                              AlertScreenTargeting.SIDEWALK_SCREEN,
                            [SCREEN_BODY_ADDITIONAL_NAME]: undefined,
                            [SCREEN_TARGETING_NAME]: [
                              {
                                routeId: '',
                                options: [],
                                layouts: [TargetType.DUP],
                              },
                            ],
                          },
                        }),
                      );
                    }}
                    additionalStyles={css`
                      margin-right: 12px;
                      min-width: 48%;
                      min-height: 40px;
                    `}
                    size="large"
                  >
                    Sidewalk Screen
                  </Button>
                </S.Screens.Section>
              </Layout.Row>
              <StatusBanner
                as="label"
                htmlFor="initial-screen-error"
                status="error"
                hasIcon
                text={TARGETING_TYPE_REQUIRED}
                isVisible={hasInitialScreenError}
                css={css`
                  margin-top: 12px;
                  margin-bottom: 12px;
                `}
              />
            </React.Fragment>
          )}
        <Layout.Row>
          <div
            css={css`
              flex: 1;
              min-width: 0;
            `}
          >
            {(screenUpdateMethod === ScreenUpdateMethods.REPLACE ||
              (!screenUpdateMethod &&
                initialScreenChoice &&
                sidewalkScreenEnabled) ||
              (!screenUpdateMethod && !sidewalkScreenEnabled)) && (
              <ScreensContainer>
                <ScreensContentContainer
                  css={css`
                    margin-bottom: 24px;
                  `}
                >
                  <CS.MainFieldContainer>
                    {showScreenTitle && !isSidewalkScreen && (
                      <div
                        css={css`
                          margin-bottom: 24px;
                        `}
                      >
                        <InputLabel htmlFor="screen-title" label="Title">
                          <input
                            id="screen-title"
                            disabled={!show}
                            css={inputCss}
                            value={
                              isScreenTitleCustomized
                                ? localScreenTitle
                                : messageTypeDescription
                            }
                            onChange={(e) => {
                              setIsScreenTitleCustomized(true);
                              setLocalScreenTitle(e.target.value);
                              debouncedScreenTitleUpdate(e.target.value);
                            }}
                          />
                        </InputLabel>
                      </div>
                    )}
                    <Editor
                      richEditing
                      richEditingMenu
                      id={`${SCREEN_BODY_NAME}_${index}`}
                      key={`main-${copyFromPreviousMessageCount}-${index}`}
                      initialContent={getInitialContent(
                        usePreviousMessage
                          ? previousScreenMessage?.bodyHtml
                          : undefined,
                        screenBody,
                      )}
                      labelText="Description"
                      invalid={!!hasScreenBodyError}
                      shouldMirror={!screenCustomized}
                      mirrorState={webBody ? [webBody] : undefined}
                      value={localEditorState}
                      relatedGtfsIds={relatedGtfsIds}
                      showSelectionControls={false}
                      showLinkButton={false}
                      showListButton={false}
                      enableLinkExtension={false}
                      onBlur={() => {
                        triggerValidation({ name: SCREEN_BODY_NAME });
                      }}
                      onFocus={() =>
                        setValue(
                          SCREENS_NAME,
                          modifyIndexInArray({
                            index,
                            array: screens,
                            value: {
                              ...screens[index],
                              [SCREEN_CUSTOMIZED_NAME]: true,
                            },
                          }),
                        )
                      }
                      onStateChange={({ state }) => {
                        setLocalEditorState(state);
                      }}
                      onChange={({ state }) => {
                        const { routes, stops } = getMentionsByType(state);

                        descriptionStateRef.current = state;

                        setRoutesInEditor(routes);
                        setStopsInEditor(stops);
                        setValue(
                          SCREENS_NAME,
                          modifyIndexInArray({
                            index,
                            array: screens,
                            value: {
                              ...screens[index],
                              [SCREEN_BODY_NAME]: state,
                              [SCREEN_BODY_ADDITIONAL_NAME]:
                                additionalInfoStateRef.current,
                            },
                          }),
                        );
                      }}
                      shouldFormatOnPaste
                    />
                    {webAdditionalBody && !isSidewalkScreen && (
                      <Editor
                        className="optional-editor"
                        showDetailMenu
                        richEditing
                        richEditingMenu
                        key={`add-${additionalEditorKeyCount}-${copyFromPreviousMessageCount}-${index}`}
                        initialContent={getInitialContent(
                          usePreviousMessage
                            ? previousScreenMessage?.additionalInfoHtml
                            : undefined,
                          screenAdditionalBody,
                        )}
                        labelText="Additional Information"
                        id={`${SCREEN_BODY_ADDITIONAL_NAME}_${index}`}
                        shouldMirror={!screenCustomized}
                        mirrorState={
                          webAdditionalBody ? [webAdditionalBody] : undefined
                        }
                        relatedGtfsIds={relatedGtfsIds}
                        value={localAdditionalEditorState}
                        showLinkButton={false}
                        enableLinkExtension={false}
                        onFocus={() =>
                          setValue(
                            SCREENS_NAME,
                            modifyIndexInArray({
                              index,
                              array: screens,
                              value: {
                                ...screens[index],
                                [SCREEN_CUSTOMIZED_NAME]: true,
                              },
                            }),
                          )
                        }
                        onStateChange={({ state }) => {
                          setLocalAdditionalEditorState(state);
                        }}
                        onChange={({ state }) => {
                          additionalInfoStateRef.current = state;

                          setValue(
                            SCREENS_NAME,
                            modifyIndexInArray({
                              index,
                              array: screens,
                              value: {
                                ...screens[index],
                                [SCREEN_BODY_NAME]: descriptionStateRef.current,
                                [SCREEN_BODY_ADDITIONAL_NAME]: state,
                              },
                            }),
                          );
                        }}
                        shouldFormatOnPaste
                      />
                    )}
                    {previousScreenMessage?.bodyHtml && (
                      <Button
                        plain
                        type="button"
                        css={css`
                          margin-top: 12px;
                        `}
                        onClick={(e) => {
                          e.preventDefault();

                          copyFromPreviousMessage();
                        }}
                      >
                        copy from previous message
                      </Button>
                    )}
                  </CS.MainFieldContainer>
                  <DurationPicker
                    id="screenDuration"
                    value={webExpiration}
                    disabled
                    css={css`
                      margin-bottom: 24px;
                    `}
                  />
                  <div id={`${SCREEN_TARGETING_NAME}${index}`}>
                    <ScreenSelector
                      index={index}
                      isSidewalkLocked={
                        initialScreenChoice ===
                          AlertScreenTargeting.SIDEWALK_SCREEN ||
                        screens[index][SCREEN_TARGETING_NAME][0]?.layouts[0] ===
                          TargetType.DUP
                      }
                      isUpdating
                    />
                  </div>
                </ScreensContentContainer>
                <ScreensPreviewContainer
                  css={css`
                    flex-basis: ${screenPreviewDisplayType ===
                    ScreenPreviewDisplayTypes.PORTRAIT
                      ? 300
                      : 375}px;
                  `}
                >
                  <div>
                    <Heading
                      css={css`
                        margin-bottom: 8px;
                      `}
                      level={5}
                      size="medium"
                    >
                      Screen Preview
                    </Heading>
                    <AlertScreenPreview
                      isUpdate
                      messageType={messageType}
                      title={screenTitle}
                      content={bodyHtml}
                      additionalContent={additionalBodyHtml}
                      affectedRoutes={allAffectedRoutes}
                      allRoutesAffected={allRoutesAffected}
                      displayType={
                        isSidewalkScreen
                          ? ScreenPreviewDisplayTypes.DUP
                          : screenPreviewDisplayType
                      }
                    />
                  </div>
                </ScreensPreviewContainer>
              </ScreensContainer>
            )}
            {screenUpdateMethod === ScreenUpdateMethods.REMOVE && (
              <ScreensContainer
                css={css`
                  margin-top: 20px;
                  margin-bottom: 20px;
                `}
              >
                <StatusBanner
                  status="warning"
                  text="Once removed, this message will not appear on any screens."
                  transition={false}
                  hasIcon
                />
              </ScreensContainer>
            )}
          </div>
        </Layout.Row>
      </ScreensAccordion>
    </React.Fragment>
  );
};

export default UpdateScreens;
