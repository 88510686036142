/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { container } from '../../common/styles';
import { ThemeType } from '../../../theme';

export const PreviousMessageContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ ...rest }) => (
  <section
    css={() => css`
      margin: 0px 32px 20px 32px;
      border-radius: 4px;
      box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    `}
    {...rest}
  />
);

export const UpdateContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <section
    css={css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `}
    {...props}
  />
);

const FormWrapper: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={() => [
      container,
      css`
        padding: 0px 32px;
      `,
    ]}
    {...props}
  />
);

const FormContent: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      background-color: ${theme.colors.white};
      border-radius: 8px;
      box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 20px;
    `}
    {...props}
  />
);

const FormRow: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      max-width: 100%;
    `}
    {...props}
  />
);

const FormFieldsColumn: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      min-width: 0;
    `}
    {...props}
  />
);

const FormSideBar: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      background-color: ${theme.colors.light6};
      flex: 0 0 300px;
      position: relative;
    `}
    {...props}
  />
);

const FormSideBarSection: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      padding: ${theme.spacing.medium};
    `}
    {...props}
  />
);

const ButtonContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 20px 0;

      button {
        margin-left: ${theme.spacing.small};
      }
    `}
    {...props}
  />
);

export const Form = {
  Wrapper: FormWrapper,
  Content: FormContent,
  Row: FormRow,
  FieldsColumn: FormFieldsColumn,
  SideBar: FormSideBar,
  SideBarSection: FormSideBarSection,
  ButtonContainer,
};
