/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';

export const ComposeContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <section
    css={css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `}
    {...props}
  />
);

export const FormElementsWrapper: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => [
      css`
        border-radius: 8px;
        box-shadow:
          0 1px 1px 0 rgba(0, 0, 0, 0.14),
          0 1px 3px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        margin: ${theme.spacing.large};
        margin-top: 0px;
      `,
    ]}
    {...props}
  />
);

export const ButtonContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 20px 40px 48px 40px;

      button {
        margin-left: 12px;
      }
    `}
    {...props}
  />
);
