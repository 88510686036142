/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import theme from 'theme';

import Heading from '../Heading';
import HR from '../HR';

export const AlertFormFieldSpacer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      margin-top: 24px;
    `}
    {...props}
  />
);

export const AlertFormHeading: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div>
    <Heading
      level={1}
      {...props}
      css={css`
        padding: 40px 40px 32px 40px;
      `}
    />
    <HR />
  </div>
);

interface AlertFormSectionWrapperProps {
  children?: React.ReactNode;
  isGrayscale?: boolean;
  flex?: boolean;
}

export const AlertFormSectionWrapper: React.FC<
  AlertFormSectionWrapperProps & React.HTMLProps<HTMLDivElement>
> = ({ isGrayscale, flex, ...rest }) => {
  return (
    <div
      css={css`
        ${flex ? 'display: flex;' : ''}
        flex: auto;
        padding: 32px 40px;
        background-color: ${isGrayscale ? theme.colors.white : '#e7eff6'};
      `}
      {...rest}
    />
  );
};

export const AlertFormSideBySide: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        max-width: 100%;
      `}
      {...props}
    />
  );
};

export const AlertFormSideBySideLeft: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        min-width: 0px;
      `}
      {...props}
    />
  );
};

export const AlertFormSideBySideRight: React.FC<{
  width?: number;
  children?: React.ReactNode;
}> = ({ width = 300, ...rest }) => {
  return (
    <div
      css={css`
        flex: 0 0 ${width}px;
        margin-left: 32px;
      `}
      {...rest}
    />
  );
};
