const colors: {
  [key: string]: string;
} = {
  brand: '#002D72',
  accent1: '#0F61A9',
  accent2: '#F2A902',
  accent3: '#001840',
  accent4: '#246EB9',
  accent5: '#2196F3',
  accent6: '#01347E',
  dark1: '#1F2025',
  dark2: '#4A4A4A',
  dark3: '#707070',
  dark4: '#94949E',
  dark5: '#6E6E6E',
  dark6: '#545454',
  dark7: '#AFB0AF',
  dark8: '#E6E6E6',
  light1: '#F8F8F8',
  light2: '#A4A7AE',
  light3: '#757575',
  light4: '#E1E1EA',
  light5: '#E0ECF6',
  light6: '#E9E9F3',
  light7: '#AEB0B7',
  light8: '#A7A9AC',
  light9: '#CCC',
  light10: '#D3EBFF',
  white: '#fff',
  black: '#000',
  border: '#ddd',
  blue: '#00427C',
  'background-inset': '#f1f1f1',
  'border-dark': '#aaa',
  'border-warning': '#EBD99D',
  'status-ok': '#00C781',
  'status-error': '#BE2A25',
  'status-error-background': '#F1D1D1',
  'status-warning': '#F9F2B5',
  'status-alert': '#FF3838',
  'status-success-background': '#b3e6b5',
  'status-success-foreground': '#000',
  'select-option-hover': '#E7EFF6',
  'label-background': '#eaeaea',
};

export default colors;
