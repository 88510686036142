/** @jsxImportSource @emotion/react */

import { css as cssEmotion, SerializedStyles } from '@emotion/react';
import React from 'react';
import classnames from 'classnames';
import {
  Link,
  LinkProps,
  matchPath,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  baseStyle as buttonBaseStyle,
  primaryStyle as buttonPrimaryStyle,
  secondaryStyle as buttonSecondaryStyle,
  styles as buttonSizeStyles,
  plainStyle as buttonPlainStyle,
} from 'components/common/Button';
import theme, { ThemeType } from 'theme';
import { useStale } from '../../contexts/Stale';

export enum ApplinkStyles {
  PRIMARY_BUTTON = 'PRIMARY_BUTTON',
  SECONDARY_BUTTON = 'SECONDARY_BUTTON',
  PLAIN_BUTTON = 'PLAIN_BUTTON',
  LARGE_BUTTON = 'LARGE_BUTTON',
  SMALL_BUTTON = 'SMALL_BUTTON',
}

export interface IAppLinkProps extends LinkProps {
  to: any;
  className?: string;
  exact?: boolean;
  isNavLink?: boolean;
  otherMatches?: string[];
  styleVariations?: ApplinkStyles[];
  css?:
    | SerializedStyles
    | SerializedStyles[]
    | ((theme: ThemeType) => SerializedStyles);
  containerStyles?: SerializedStyles;
}

const highlightStyles = cssEmotion`
  &:focus-visible {
    outline: none;
    box-shadow: unset;

    :after {
      content: '';
      top: -2px;
      bottom: -2px;
      right: -2px;
      left: -2px;
      border: 1px solid ${theme.colors.accent3};
      border-radius: 4px;
      position: absolute;
    }
  }
`;

const APPLINK_STYLES: {
  [key in ApplinkStyles]: any[];
} = {
  [ApplinkStyles.PRIMARY_BUTTON]: [
    buttonBaseStyle,
    buttonPrimaryStyle,
    highlightStyles,
  ],
  [ApplinkStyles.SECONDARY_BUTTON]: [
    buttonBaseStyle,
    buttonSecondaryStyle,
    highlightStyles,
  ],
  [ApplinkStyles.PLAIN_BUTTON]: [
    theme.typography.sizes.xsmall,
    buttonPlainStyle,
    highlightStyles,
  ],
  [ApplinkStyles.LARGE_BUTTON]: [buttonSizeStyles.large],
  [ApplinkStyles.SMALL_BUTTON]: [buttonSizeStyles.small],
};

/*
  AppLink helps users stay on the latest version of the app.
  It hooks into the Stale context, which is periodically checking to see if
    the app is running the latest version.
  If the app is detected to be out-of-date, AppLink will fallback and render an anchor,
    which will force the user to get the new version.
  If you need a link for moving between pages, and it doesn't require custom onClick behavior,
    use AppLink to get this fallback behavior.
*/
const AppLink: React.FC<IAppLinkProps> = ({
  children,
  to,
  isNavLink,
  exact,
  className,
  otherMatches,
  styleVariations = [],
  css,
  containerStyles,
  ...rest
}) => {
  const { isStale } = useStale();
  const location = useLocation();
  const history = useHistory();

  const matchPathResult = matchPath(location.pathname, to);
  const isActive = exact ? matchPathResult?.isExact : !!matchPathResult;
  const hasOtherMatch = !!otherMatches?.some((path) => {
    const otherMatchResult = matchPath(location.pathname, path);
    return !!otherMatchResult;
  });

  const activeClassName = classnames(className, {
    active: isNavLink && (isActive || hasOtherMatch),
  });

  const linkStyles = [
    css,
    ...styleVariations.flatMap((v) => APPLINK_STYLES[v]),
  ];

  if (isStale) {
    return (
      <div
        css={cssEmotion`
          position: relative;
          ${containerStyles};
        `}
      >
        <a
          href={to}
          className={activeClassName}
          css={linkStyles}
          onKeyDown={(event: any) => {
            if (event.key === ' ') {
              window.location.href = to;
            }
          }}
        >
          {children}
        </a>
      </div>
    );
  }

  return (
    <div
      css={cssEmotion`
        position: relative;
        ${containerStyles};
      `}
    >
      <Link
        to={to}
        className={activeClassName}
        css={linkStyles}
        {...rest}
        onKeyDown={(event: any) => {
          if (event.key === ' ') {
            history.push(to);
          }
        }}
      >
        {children}
      </Link>
    </div>
  );
};

export default AppLink;
