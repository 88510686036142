import colors from './colors';

export type ThemeSizes =
  | 'xxxsmall'
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';

export type ThemeFonts = 'primary' | 'secondary';

export type ThemeFontWeights = 'normal' | 'bold' | 'bolder';

export interface ThemeType {
  colors: {
    [key: string]: string;
  };
  typography: {
    families: {
      [fontType in ThemeFonts]: string;
    };
    sizes: {
      [sizeName in ThemeSizes]: {
        lineHeight: number;
        fontSize: string;
      };
    };
    weights: {
      [fontWeight in ThemeFontWeights]: number;
    };
  };
  spacing: {
    [sizeName in ThemeSizes]: string;
  };
}

const theme: ThemeType = {
  colors,
  typography: {
    families: {
      primary: '"Proxima Nova", Verdana, Geneva, sans-serif',
      secondary: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    sizes: {
      xxxsmall: {
        fontSize: '0.5rem', // 8px
        lineHeight: 1.25,
      },
      xxsmall: {
        fontSize: '0.625rem', // 10px
        lineHeight: 1.25,
      },
      xsmall: {
        fontSize: '0.75rem', // 12px
        lineHeight: 1.3,
      },
      small: {
        fontSize: '0.875rem', // 14px
        lineHeight: 1.14,
      },
      medium: {
        fontSize: '1rem', // 16px
        lineHeight: 1.4375,
      },
      large: {
        fontSize: '1.25rem', // 20px
        lineHeight: 1.45,
      },
      xlarge: {
        fontSize: '1.5rem', // 24px
        lineHeight: 1.16,
      },
      xxlarge: {
        fontSize: '2rem', // 32px
        lineHeight: 1.25,
      },
      xxxlarge: {
        fontSize: '3rem', // 48px
        lineHeight: 1.25,
      },
      xxxxlarge: {
        fontSize: '4rem', // 64px
        lineHeight: 1.25,
      },
    },
    weights: {
      bold: 700,
      bolder: 800,
      normal: 400,
    },
  },
  spacing: {
    xxxxlarge: '108px',
    xxxlarge: '96px',
    xxlarge: '82px',
    xlarge: '60px',
    large: '32px',
    medium: '22px',
    small: '16px',
    xsmall: '12px',
    xxsmall: '6px',
    xxxsmall: '3px',
  },
};

export default theme;
