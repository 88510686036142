/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { ThemeType } from 'theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: max-content;
      grid-gap: 2px;
    `}
    {...props}
  />
);

export const Direction: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      font-weight: ${theme.typography.weights.bold};
      align-items: center;
      height: 20px;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      grid-gap: 4px;
    `}
    {...props}
  />
);

export const TerminalStation: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <span
    css={(theme: ThemeType) => css`
      font-weight: ${theme.typography.weights.normal};
      ::before {
        content: '(';
      }
      ::after {
        content: ')';
      }
    `}
    {...props}
  />
);

export const Stations: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      grid-gap: 12px;
    `}
    {...props}
  />
);

export const Screens: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.xsmall};
    `}
    {...props}
  />
);
