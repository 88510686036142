import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { useFeedId } from '../contexts/FeedId';

import { Direction, TargetType } from '../generated/global-types';
import {
  ScreenLayoutFallbackCounts,
  ScreenLayoutFallbackCountsVariables,
} from 'generated/FallbackScreenLayout';

const LayoutFallbackCountsQuery = loader('../graphql/FallbackScreenLayout.gql');

export interface IUseScreenFallbackCountResult {
  targetType: TargetType;
  availableCount: number;
  lockedCount: number;
  totalCount: number;
  upload: any;
  direction: Direction | null;
}

const useScreenFallbackCounts = (): {
  screenFallbackCounts: IUseScreenFallbackCountResult[];
  loading: boolean;
} => {
  const feedId = useFeedId();
  const variables = {
    feedId,
  };

  const { data, loading } = useQuery<
    ScreenLayoutFallbackCounts,
    ScreenLayoutFallbackCountsVariables
  >(LayoutFallbackCountsQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  if (!data?.screenLayoutFallbackCounts?.nodes) {
    return {
      screenFallbackCounts: [] as IUseScreenFallbackCountResult[],
      loading,
    };
  }

  const counts: IUseScreenFallbackCountResult[] =
    data.screenLayoutFallbackCounts.nodes.filter(Boolean).map((count) => {
      const { type, totalCount, lockedCount, upload, direction } = count;

      return {
        lockedCount: lockedCount || 0,
        totalCount: totalCount || 0,
        availableCount: Math.max((totalCount || 0) - (lockedCount || 0), 0),
        targetType: type as TargetType,
        upload,
        direction: direction || null,
      };
    });

  return {
    screenFallbackCounts: counts,
    loading,
  };
};

export default useScreenFallbackCounts;
