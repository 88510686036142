/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';

// Styled components
import Heading from '../../common/Heading';
import { ThemeType } from '../../../theme';

const EmailPreview: React.FC<{
  subject: string | null;
  body: string;
}> = ({ subject, body }) => {
  const hasHtml = (string: string) => {
    return /<\/?[a-z][\s\S]*>/i.test(string);
  };

  return (
    <div
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.medium};
        font-family: ${theme.typography.families.secondary};
        background-color: ${theme.colors.light1};
        border-radius: 4px;
        padding: 32px;
        width: 100%;
        max-width: 624px;
        margin-bottom: 32px;
      `}
    >
      <Heading
        level={3}
        size="medium"
        css={(theme: ThemeType) => css`
          font-family: ${theme.typography.families.secondary};
          margin-bottom: 16px;
        `}
      >
        {subject}
      </Heading>
      {hasHtml(body) ? (
        <div dangerouslySetInnerHTML={{ __html: body }} /> // eslint-disable-line react/no-danger
      ) : (
        <p
          css={css`
            margin: 0px;
            white-space: pre-wrap;
          `}
        >
          {body}
        </p>
      )}
    </div>
  );
};

export default EmailPreview;
