/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import { MaxLength } from 'types';
import Theme, { ThemeSizes } from '../../../theme';
import RemainingCharacters, { remainingIsInvalid } from './RemainingCharacters';

const LabelledInput: React.FC<{
  label: string;
  className?: string;
  height?: number;
  htmlFor?: string;
  invalid?: boolean;
  labelSize?: ThemeSizes;
  maxLength?: MaxLength;
  inputIsMultiLine?: boolean;
  subLabel?: string;
  children?: React.ReactNode;
}> = ({
  className,
  htmlFor,
  label,
  maxLength,
  invalid = false,
  height,
  labelSize = 'medium',
  children,
  inputIsMultiLine = false,
  subLabel,
}) => {
  const isRemainingInvalid = maxLength && remainingIsInvalid(maxLength);
  const hasError = invalid || isRemainingInvalid;

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      `}
    >
      {label && (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
          `}
        >
          <label
            htmlFor={htmlFor}
            css={css`
              ${Theme.typography.sizes[labelSize]};
              color: ${Theme.colors.black};
              font-weight: ${Theme.typography.weights.bold};
              margin-right: 12px;
            `}
          >
            {label}
          </label>
          {maxLength && (
            <RemainingCharacters maxLength={maxLength} htmlFor={htmlFor} />
          )}
        </div>
      )}
      {subLabel && (
        <div
          css={css`
            margin-bottom: 12px;
          `}
        >
          <label
            htmlFor={htmlFor}
            css={css`
              font-size: ${Theme.typography.sizes.small.fontSize};
            `}
          >
            {subLabel}
          </label>
        </div>
      )}
      <div
        css={css`
          flex: 1;
          width: 100%;
          ${inputIsMultiLine ? '' : 'height: 0px;'}
          ${height ? `min-height: ${height}px;` : 'height: 100%;'}
          border-radius: 4px;

          > input,
          textarea {
            background: transparent;
          }

          & textarea {
            ${height ? `min-height: ${height}px;` : 'height: 100%;'}

            border: 1px solid
            ${hasError
              ? Theme.colors['status-error']
              : Theme.colors['border-dark']};

            &:focus {
              border: 1px solid ${Theme.colors.accent3};
            }
          }

          & input {
            ${height ? `min-height: ${height}px;` : 'height: 100%;'}

            border: 1px solid
            ${hasError
              ? Theme.colors['status-error']
              : Theme.colors['border-dark']};

            &:focus-within {
              border: 1px solid ${Theme.colors.accent3};
              outline: none;
            }
          }
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default LabelledInput;
