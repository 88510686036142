/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import {
  TargetingOption,
  TargetingOptionType,
} from 'utils/stop-selector-helpers';

import * as CS from './common.styled';
import Checkbox, { CheckboxSize } from '../Checkbox';
import Bullet, { BulletSize } from '../Bullet';
import { BOROUGH_INLINE_LABELS } from '../../../utils/boroughs';
import { getStopType, getVehicleName } from '../../../utils/feed-switches';
import { useFeedId } from '../../../contexts/FeedId';
import theme from '../../../theme';

interface OptionProps {
  option: TargetingOption;
  description?: React.ReactNode;
  [key: string]: any;
}

export const Option: React.FC<OptionProps> = ({
  option,
  isSelected,
  isValue,
  color = 'accent1',
  screenCounts,
  description,
  ...props
}) => {
  const feedId = useFeedId();
  const vehicleName = getVehicleName(feedId);
  const stopOrStationText = getStopType(feedId);

  const typeOrVehicleText = (() => {
    if (
      option.optionType === TargetingOptionType.ALL_BOROUGH_OPTION &&
      option.borough
    ) {
      return `${BOROUGH_INLINE_LABELS[option.borough]} `;
    }

    if (isValue) {
      return '';
    }

    return `${vehicleName} `;
  })();

  return (
    <CS.Option {...props}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {!isValue && (
          <Checkbox
            css={css`
              margin-right: 8px;
            `}
            size={CheckboxSize.medium}
            checked={isSelected}
            onChange={() => {}}
          />
        )}
        {new Set([
          TargetingOptionType.ALL_BOROUGH_OPTION,
          TargetingOptionType.ALL_ROUTE_OPTION,
        ]).has(option.optionType) ? (
          <div>
            <CS.OptionText color={color}>All </CS.OptionText>
            {!isValue && (
              <Bullet
                size={BulletSize.xsmall}
                routeId={option.bulletValue ?? ''}
                style={{ margin: '4px 0px' }}
              />
            )}{' '}
            <CS.OptionText color={color}>{typeOrVehicleText}</CS.OptionText>
            <CS.OptionText
              color={color}
            >{`${stopOrStationText}s`}</CS.OptionText>
          </div>
        ) : (
          <CS.OptionText color={color}>{option.label}</CS.OptionText>
        )}
      </div>
      <div
        css={css`
          ${theme.typography.sizes.small};
          color: #707070;
        `}
      >
        {description}
      </div>
    </CS.Option>
  );
};
