import { FieldErrors } from 'react-hook-form/dist/types';
import { LEADER_NO_OUTLETS_NAME } from 'constants/alerts';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as RoutesByFeed_routes_nodes } from 'generated/RoutesByFeed';
import { ComposeFormData } from '../types';

const Y_OFFSET = -64;
const LINE_BREAK_HEIGHT = 10;

export const scrollToErrors = (
  formErrors: FieldErrors<ComposeFormData> | { [key: string]: string },
) => {
  const formErrorKeys = Object.keys(formErrors);
  if (formErrorKeys.length) {
    for (const id of formErrorKeys) {
      const el = document.getElementById(id);

      if (el) {
        const newY =
          el.getBoundingClientRect().top + window.pageYOffset + Y_OFFSET;
        window.scrollTo({ top: newY, behavior: 'smooth' });
        break;
      } else if (id === LEADER_NO_OUTLETS_NAME) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        break;
      }
    }
  }
};

export const getScreenTargetingFieldKey = (
  key: string,
  index: number,
): string => `screenTargeting_${index}_${key}`;

interface SanitizeHTMLConfigs {
  removeNonBreakingSpaces?: boolean;
  addHeightToEmptyParagraph?: boolean;
}

export const sanitizeHTML = (
  html: string,
  {
    removeNonBreakingSpaces = true,
    addHeightToEmptyParagraph = true,
  }: SanitizeHTMLConfigs = {},
) => {
  let sanitizedHTML = html;

  // Remirror's HTML parser replaces some spaces with nbsps, reverting
  // them to normal spaces as they cause tagged mentions to overflow
  // outside their container on the mta website
  if (removeNonBreakingSpaces) {
    sanitizedHTML = sanitizedHTML.replace(/&nbsp;/g, ' ');
  }

  // Remirror adds line breaks as empty paragraph tags, which don't reflect any height on the mta
  // website but do inside remirror and in our screen app, we add height to those empty `<p>` tags
  // to reflect that height on the mta website
  // Skips adding height if it's a single paragraph node
  // TODO: check if we can replace `<p></p>` with `<br />` or `<p><br /></p>` and verify everything
  // is still working as expected (re-editing the message in remirror, displays correctly on the
  // mta website and in our screen app)
  if (
    addHeightToEmptyParagraph &&
    (sanitizedHTML.match(/<p><\/p>/gi ?? [])?.length ?? 0) !== 1
  ) {
    sanitizedHTML = sanitizedHTML.replace(
      /<p><\/p>/gi,
      `<p style="min-height:${LINE_BREAK_HEIGHT}px"></p>`,
    );
  }

  return sanitizedHTML;
};

export const modifyIndexInArray = ({
  index,
  value,
  array,
}: {
  index: number;
  value: any;
  array: any[];
}) => {
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
};

// We filter out selectors targeting routes that have been removed from the CS OTP feed
// TODO: add more valid typing here
export const getValidPlannedWorksScreenMessages = (
  allPlannedWorkScreenMessages: any[],
  allRoutes: RoutesByFeed_routes_nodes[],
): any => {
  const allRouteIds = new Set(allRoutes?.map((r) => r.gtfsId));

  const plannedWorkScreenMessages = allPlannedWorkScreenMessages?.reduce(
    (allScreenMessages, sm) => {
      const validContent: any = {
        ...sm,
        screenTargeting: sm.screenTargeting
          .map((st: any) => ({
            ...st,
            selectors: st?.selectors?.filter((s: any) =>
              allRouteIds.has(s?.context.gtfsRouteId),
            ),
          }))
          .filter((st: any) => st.selectors?.length) as any,
      };

      if (validContent.screenTargeting.length) {
        allScreenMessages.push(validContent);
      }

      return allScreenMessages;
    },
    [],
  ) as any;

  return plannedWorkScreenMessages;
};
