/** @jsxImportSource @emotion/react */

import React from 'react';

import Button from '../Button';

interface SubmitEvent extends Event {
  submitter: HTMLInputElement;
}

const SAVE_AS_DRAFT_NAME = 'save-as-draft';

export const checkIfDraft = (event: React.FormEvent<HTMLFormElement>) =>
  (event.nativeEvent as SubmitEvent).submitter?.name === SAVE_AS_DRAFT_NAME;

export const AlertFormButtons: React.FC<
  {
    isSubmittingDraftAlert: boolean;
    isSubmittingActiveAlert: boolean;
    onCancel: () => void;
    onSubmit: () => void;
  } & { children?: React.ReactNode }
> = ({
  isSubmittingActiveAlert,
  isSubmittingDraftAlert,
  onCancel,
  onSubmit,
}) => {
  const isSubmitting = isSubmittingDraftAlert || isSubmittingActiveAlert;

  return (
    <React.Fragment>
      <Button type="button" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        name={SAVE_AS_DRAFT_NAME}
        type="submit"
        disabled={isSubmitting}
        onClick={onSubmit}
      >
        {isSubmittingDraftAlert ? 'Saving draft...' : 'Save as draft'}
      </Button>
      <Button primary type="submit" disabled={isSubmitting} onClick={onSubmit}>
        {isSubmittingActiveAlert ? 'Posting...' : 'Post Alert'}
      </Button>
    </React.Fragment>
  );
};
