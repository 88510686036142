/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import {
  ListboxInput,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox';
import pluralize from 'pluralize';
import theme, { ThemeType } from 'theme';
import RadioButton from 'components/common/RadioButton';
import range from 'utils/range';
import StandardListboxButton from '../../../ui-kit/standard-listbox-button';
import StatusBanner from '../../common/status-banner';
import { listboxList, listboxOption } from '../../common/styles';
import * as styles from './styles';

const HomepagePublishedAt: React.FC<
  {
    value: number | null;
    onChange: Function;
  } & { children?: React.ReactNode }
> = ({ value, onChange }) => {
  const [draftValue, setDraftValue] = useState<number | null>(value);

  const handleChange = (value: number | null) => {
    setDraftValue(value);
    onChange(value);
  };

  return (
    <div>
      <div
        css={css`
          ${styles.inputWrapper}
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 12px;
          position: relative;

          & > label {
            font-weight: normal;
            cursor: pointer;
            margin-left: 8px;
          }

          &:focus-within {
            ::after {
              content: '';
              position: absolute;
              top: -2px;
              right: -2px;
              bottom: -2px;
              left: -2px;
              border-radius: 4px;
              pointer-events: none;
              border: 1px solid ${theme.colors.accent3};
            }
          }
        `}
      >
        <RadioButton
          id="hompage_display_false"
          name="hompage_display"
          checked={draftValue === null}
          onChange={(checked) => {
            if (checked) {
              handleChange(null);
            }
          }}
        />
        <label htmlFor="hompage_display_false">Never Display on Homepage</label>
      </div>
      <br />
      <div
        css={css`
          ${styles.inputWrapper}
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 12px;
          position: relative;

          & > label {
            font-weight: normal;
            cursor: pointer;
            margin-left: 8px;
          }

          &:focus-within {
            ::after {
              content: '';
              position: absolute;
              top: -2px;
              right: -2px;
              bottom: -2px;
              left: -2px;
              border-radius: 4px;
              pointer-events: none;
              border: 1px solid ${theme.colors.accent3};
            }
          }
        `}
      >
        <RadioButton
          id="hompage_display_true"
          name="hompage_display"
          checked={draftValue !== null}
          onChange={(checked) => {
            if (checked) {
              handleChange(1);
            }
          }}
        />
        <label htmlFor="hompage_display_true">Display on Homepage</label>
      </div>
      {draftValue !== null ? (
        <div css={styles.inputWrapper}>
          <ListboxInput
            id="hompage_display_time"
            value={(draftValue || 1).toString()}
            onChange={(newValue) => {
              handleChange(parseInt(newValue, 10));
            }}
            css={css`
              ${styles.listbox}
              & > [data-reach-listbox-button] {
                border-radius: 4px;
              }
            `}
          >
            <StandardListboxButton
              css={css`
                border-radius: 4px;
              `}
            >
              {draftValue !== null &&
                `${pluralize('hour', draftValue, true)} before`}
            </StandardListboxButton>
            <ListboxPopover>
              <ListboxList css={listboxList}>
                {range(0, 24).map((hour) => (
                  <ListboxOption
                    key={hour}
                    value={hour.toString()}
                    css={listboxOption}
                  >
                    {pluralize('hour', hour, true)} before
                  </ListboxOption>
                ))}
              </ListboxList>
            </ListboxPopover>
          </ListboxInput>
        </div>
      ) : (
        <StatusBanner
          status="warning"
          text="This alert will not appear on the Homepage."
          transition={false}
          hasIcon
          css={(theme: ThemeType) => css`
            margin-bottom: ${theme.spacing.xsmall};
          `}
        />
      )}
    </div>
  );
};

export default HomepagePublishedAt;
