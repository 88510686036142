import { getLabelFromTargetType } from 'components/pages/Screen/in-screens-preview/helpers';
import { TargetType } from 'generated/global-types';

export const NYCT_SUBWAY_SCREEN_OPTIONS = [
  {
    label: getLabelFromTargetType(TargetType.COVES_OF_FIVE),
    value: TargetType.COVES_OF_FIVE,
  },
  {
    label: getLabelFromTargetType(TargetType.COVES_OF_TWO),
    value: TargetType.COVES_OF_TWO,
  },
  {
    label: getLabelFromTargetType(TargetType.SQUARE),
    value: TargetType.SQUARE,
  },
];

export const STATION_SCREEN_OPTIONS = [
  {
    label: getLabelFromTargetType(TargetType.SINGLE_CIC),
    value: TargetType.SINGLE_CIC,
  },
  {
    label: getLabelFromTargetType(TargetType.DUO_CIC_LEFT),
    value: TargetType.DUO_CIC_LEFT,
  },
  {
    label: getLabelFromTargetType(TargetType.DUO_CIC_RIGHT),
    value: TargetType.DUO_CIC_RIGHT,
  },
  {
    label: `${getLabelFromTargetType(
      TargetType.SINGLE_AD_PORTRAIT,
    )} (Portrait)`,
    value: TargetType.SINGLE_AD_PORTRAIT,
  },
  {
    label: `${getLabelFromTargetType(
      TargetType.SINGLE_AD_LANDSCAPE,
    )} (Landscape)`,
    value: TargetType.SINGLE_AD_LANDSCAPE,
  },
  {
    label: getLabelFromTargetType(TargetType.TRIPTYCH_AD),
    value: TargetType.TRIPTYCH_AD,
  },
  {
    label: `${getLabelFromTargetType(
      TargetType.SINGLE_INFO_PORTRAIT,
    )} (Portrait)`,
    value: TargetType.SINGLE_INFO_PORTRAIT,
  },
  {
    label: `${getLabelFromTargetType(
      TargetType.SINGLE_INFO_LANDSCAPE,
    )} (Landscape)`,
    value: TargetType.SINGLE_INFO_LANDSCAPE,
  },
  {
    label: getLabelFromTargetType(TargetType.SINGLE_MKT_LANDSCAPE),
    value: TargetType.SINGLE_MKT_LANDSCAPE,
  },
  {
    label: getLabelFromTargetType(TargetType.SINGLE_PIO_LANDSCAPE),
    value: TargetType.SINGLE_PIO_LANDSCAPE,
  },
  {
    label: getLabelFromTargetType(TargetType.SINGLE_STA_LANDSCAPE),
    value: TargetType.SINGLE_STA_LANDSCAPE,
  },
  {
    label: getLabelFromTargetType(TargetType.SPECTACULAR_AD),
    value: TargetType.SPECTACULAR_AD,
  },
  {
    label: getLabelFromTargetType(TargetType.SOLARI_LANDSCAPE),
    value: TargetType.SOLARI_LANDSCAPE,
  },
  {
    label: getLabelFromTargetType(TargetType.OTHER_MARKETING_LANDSCAPE),
    value: TargetType.OTHER_MARKETING_LANDSCAPE,
  },
  {
    label: getLabelFromTargetType(TargetType.SOLARI_PORTRAIT),
    value: TargetType.SOLARI_PORTRAIT,
  },
];

export const NON_AD_SCREEN_OPTIONS: { [key: string]: TargetType[] } = {
  'nyct-bus': [],
  'nyct-subway': [
    TargetType.SINGLE_CIC,
    TargetType.DUO_CIC_LEFT,
    TargetType.DUO_CIC_RIGHT,
    TargetType.FULTON_PORTRAIT,
  ],
  mnr: [TargetType.SINGLE_INFO_LANDSCAPE, TargetType.SINGLE_INFO_PORTRAIT],
  lirr: [
    TargetType.SINGLE_CIC,
    TargetType.SINGLE_MKT_LANDSCAPE,
    TargetType.SINGLE_PIO_LANDSCAPE,
    TargetType.SINGLE_STA_LANDSCAPE,
    TargetType.SOLARI_LANDSCAPE,
    TargetType.OTHER_MARKETING_LANDSCAPE,
  ],
  'bridges-tunnels': [],
};

export const groupedPIOs = [
  TargetType.SINGLE_PIO_LANDSCAPE,
  TargetType.SOLARI_LANDSCAPE,
];

export const groupedMKTs = [
  TargetType.OTHER_MARKETING_LANDSCAPE,
  TargetType.SINGLE_MKT_LANDSCAPE,
];

export const groupedCICs = [
  TargetType.SINGLE_CIC,
  TargetType.DUO_CIC_LEFT,
  TargetType.DUO_CIC_RIGHT,
];
