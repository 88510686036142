/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { EditorState, prosemirrorNodeToHtml } from 'remirror';
import ToggleSection from 'ui-kit/toggle-section/toggle-section';
import HtmlPreview from 'ui-kit/html-preview/html-preview';

import {
  AlertFormSectionWrapper,
  AlertFormSideBySide,
  AlertFormSideBySideLeft,
  AlertFormSideBySideRight,
} from './styles';
import ExpirationTimePicker from '../form-elements/expiration-time-picker';

interface AlertWebAndAppsProps {
  additionalMessageName: string;
  expirationName: string;
  messageName: string;
  onToggle: (enabled: boolean) => void;
  helpText?: string;
  show?: boolean;
  title?: string;
}

export const AlertWebAndApps: React.FC<AlertWebAndAppsProps> = ({
  title = 'Web & Apps',
  helpText,
  show,
  onToggle,
  additionalMessageName,
  messageName,
  expirationName,
}) => {
  const { watch, setValue, clearError } = useFormContext();
  const expiryDate = watch(expirationName) as Date | null;
  const expiryDateRef = useRef<Date | null>();

  const messageBody = watch(messageName) as EditorState | undefined;
  const additionalBody = watch(additionalMessageName) as
    | EditorState
    | undefined;

  const messageHtml = useMemo(() => {
    return messageBody ? prosemirrorNodeToHtml(messageBody?.doc) : '<p></p>';
  }, [messageBody]);

  const additionalHtml = useMemo(() => {
    return additionalBody ? prosemirrorNodeToHtml(additionalBody?.doc) : '';
  }, [additionalBody]);

  return (
    <AlertFormSectionWrapper isGrayscale>
      <ToggleSection
        id="web-and-apps"
        enabled={show}
        title={title}
        helpText={helpText}
        onToggle={(enabled) => {
          // Clear the alert's expiration time when Web & Apps is toggled off,
          // so that the service alert can run indefinitely until cleared
          if (expirationName) {
            if (!enabled) {
              expiryDateRef.current = expiryDate;
              setValue(expirationName, null);
              clearError(expirationName);
            } else {
              setValue(expirationName, expiryDateRef.current);
              clearError(expirationName);
            }
          }

          onToggle(enabled);
        }}
      >
        <AlertFormSideBySide
          css={css`
            padding-top: 32px;
          `}
        >
          <AlertFormSideBySideLeft>
            <HtmlPreview
              hasColor
              className="html-preview"
              title="Headline"
              html={messageHtml}
            />
          </AlertFormSideBySideLeft>
          <AlertFormSideBySideRight>
            <ExpirationTimePicker
              shouldStack
              id={expirationName}
              name={expirationName}
            />
          </AlertFormSideBySideRight>
        </AlertFormSideBySide>
        <AlertFormSideBySide>
          <AlertFormSideBySideLeft>
            <HtmlPreview
              css={css`
                padding-top: 16px;
              `}
              hasColor
              title="Details"
              html={additionalHtml}
            />
          </AlertFormSideBySideLeft>
          <AlertFormSideBySideRight />
        </AlertFormSideBySide>
      </ToggleSection>
    </AlertFormSectionWrapper>
  );
};
