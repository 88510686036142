import React, { createContext, useContext, useState, useEffect } from 'react';
import ms from 'ms.macro';

export const NowContext = createContext(Date.now());

export const NowProvider = ({ children }) => {
  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    const pid = window.setInterval(() => {
      setNow(Date.now());
    }, ms('10 seconds'));
    return () => window.clearInterval(pid);
  }, [setNow]);

  return <NowContext.Provider value={now}>{children}</NowContext.Provider>;
};

export const NowConsumer = NowContext.Consumer;

export const useNow = () => useContext(NowContext);

export const useDate = () => {
  const now = useContext(NowContext);
  const date = new Date(now);
  return date;
};
