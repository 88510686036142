/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { StylesConfig } from 'react-select';
import theme from 'theme';

export const richEditingMenuCss = css`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #eaeaea;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px #aaa solid;
  border-bottom: none;
  padding-bottom: 3px;
`;

export const richEditingMenuButtonCss = css`
  height: 32px;
  width: 32px;
  border-radius: 5px;
  padding-top: 3px;
  margin-left: 2px;
  margin-right: 2px;
  :hover {
    background-color: #f8f8f8 !important;
  }

  outline: none;
  &:focus {
    box-shadow: 0 0 0 1px ${theme.colors.accent3};
  }
`;

export const separatorCss = css`
  background-color: #cccccc;
  margin-left: 10px;
  margin-right: 10px;
  height: 66%;
  width: 1px;
`;

export const customSelectStyles: StylesConfig = {
  valueContainer(base) {
    return {
      ...base,
      padding: '2px 8px 2px 16px',
    };
  },
  container: (provided, props) => {
    const focusedStyles = props.selectProps.menuIsOpen
      ? {
          backgroundColor: 'white',
          border: `1px solid ${theme.colors['border-dark']}`,
          borderBottom: '0',
          borderLeft: '0',
          marginTop: '-2px',
          paddingTop: '1px',
          borderRadius: '6px 6px 0px 0px',
        }
      : {};
    return {
      ...provided,
      order: '1',
      width: '140px',
      ...focusedStyles,
    };
  },
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 'normal',
    textOverflow: 'inherit',
  }),
  menu: (provided: {}) => {
    return {
      ...provided,
      overflow: 'hidden',
      width: '139px',
      paddingTop: '10px',
      marginTop: '1px',
      borderRadius: '0 0 6px 6px',
      boxShadow: 'none',
      border: `1px solid ${theme.colors.accent3}`,
      borderTop: 'unset',
    };
  },
};
