import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import GenericError from '../components/pages/errors/GenericError';
import { browserSupported, isMobileBrowser } from './supported-browsers';

const { REACT_APP_MAINTENANCE_MODE } = process.env;
const isMobile = isMobileBrowser();
const browserInfo = browserSupported();

const isMaintenanceMode = REACT_APP_MAINTENANCE_MODE === 'true';

export const displayMainApp =
  !isMobile && browserInfo.supported && !isMaintenanceMode;

export const AppError: React.FC<
  { isUnderMaintenance?: boolean } & { children?: React.ReactNode }
> = ({ isUnderMaintenance = false }) => {
  let path: string;
  let title: string;
  let description: string;
  if (isUnderMaintenance || isMaintenanceMode) {
    path = '/under-maintenance';
    title = 'Under Maintenance';
    description = 'Application is currently under maintenance.';
  } else {
    path = isMobile ? '/unsupported-device' : '/unsupported-browser';
    title = isMobile ? 'Unsupported Device' : 'Unsupported Browser';
    description = isMobile
      ? 'Please open Mercury on your desktop.'
      : 'Please download latest version of your current browser';
  }

  return (
    <React.Fragment>
      <Route
        path={path}
        render={() => (
          <GenericError
            title={title}
            description={description}
            cta={
              !browserInfo.supported
                ? {
                    link: browserInfo.downloadLink,
                    text: 'Download',
                  }
                : null
            }
          />
        )}
      />
      <Redirect to={path} />
    </React.Fragment>
  );
};
