/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { PropsWithChildren as PWC } from 'react';
import StatusPill, { TStatusPillTypes } from '../status-pill';
import * as S from './index.styled';

interface IAccordionProps {
  label: React.ReactNode;
  preview?: React.ReactNode;
  subtitle?: string;
  helperItem?: React.ReactNode;
  onClose?: () => void;
  onDelete?: () => void;
  isInitiallyOpen?: boolean;
  blurContent?: boolean;
  addClearingStatusPill?: boolean;
}

const Accordion: React.FC<PWC<IAccordionProps>> = ({
  children,
  label,
  onClose,
  onDelete,
  subtitle,
  preview,
  isInitiallyOpen = true,
  helperItem,
  blurContent,
  addClearingStatusPill,
}) => (
  <S.Container>
    <S.Content>
      <S.OnlyWhenExpanded open={isInitiallyOpen}>
        <S.Header onClose={onClose} onDelete={onDelete} preview={preview}>
          <S.Expand data-disclosure />
          <span
            css={css`
              display: flex;
            `}
          >
            {label}
            {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
            {addClearingStatusPill && (
              <StatusPill
                css={css`
                  margin-left: 16px;
                  max-height: 18px;
                `}
                status={TStatusPillTypes.CLEARING}
                size="small"
              />
            )}
          </span>
          {helperItem}
        </S.Header>
        <div
          css={
            blurContent &&
            css`
              opacity: 0.5;
            `
          }
        >
          {children}
        </div>
      </S.OnlyWhenExpanded>
    </S.Content>
  </S.Container>
);

export default Accordion;
