import bowser from 'bowser';

const {
  REACT_APP_LATEST_CHROME_SUPPORTED_VERSION,
  REACT_APP_LATEST_FIREFOX_SUPPORTED_VERSION,
  REACT_APP_LATEST_SAFARI_SUPPORTED_VERSION,
  REACT_APP_LATEST_EDGE_SUPPORTED_VERSION,
  REACT_APP_LATEST_CHROME_LINK,
  REACT_APP_LATEST_FIREFOX_LINK,
  REACT_APP_LATEST_SAFARI_LINK,
  REACT_APP_LATEST_EDGE_LINK,
} = process.env;

type SupportedBrowserInfo = {
  version: string;
  downloadLink: string;
};

type CurrentBrowserInfo = {
  supported: boolean;
  downloadLink: string | null;
};

const supportBrowserInfo: { [key: string]: SupportedBrowserInfo } = {
  Chrome: {
    version: REACT_APP_LATEST_CHROME_SUPPORTED_VERSION ?? '',
    downloadLink: REACT_APP_LATEST_CHROME_LINK ?? '',
  },
  Firefox: {
    version: REACT_APP_LATEST_FIREFOX_SUPPORTED_VERSION ?? '',
    downloadLink: REACT_APP_LATEST_FIREFOX_LINK ?? '',
  },
  Safari: {
    version: REACT_APP_LATEST_SAFARI_SUPPORTED_VERSION ?? '',
    downloadLink: REACT_APP_LATEST_SAFARI_LINK ?? '',
  },
  'Microsoft Edge': {
    version: REACT_APP_LATEST_EDGE_SUPPORTED_VERSION ?? '',
    downloadLink: REACT_APP_LATEST_EDGE_LINK ?? '',
  },
};

const browser = bowser.getParser(window.navigator.userAgent);

export const browserSupported = (): CurrentBrowserInfo => {
  const browserVersion = parseInt(browser.getBrowserVersion(), 10);
  const browserName = browser.getBrowserName();

  let supported = true;
  let downloadLink: string | undefined | null = null;

  if (browserVersion && browserName) {
    if (browserName in supportBrowserInfo) {
      const currentBrowser = supportBrowserInfo[browserName];
      supported = browserVersion > parseInt(currentBrowser.version, 10);
      downloadLink = currentBrowser.downloadLink;
    } else {
      supported = false;
      downloadLink = REACT_APP_LATEST_CHROME_LINK ?? null;
    }
  }

  return { supported, downloadLink };
};

export const isMobileBrowser = () => {
  return browser.is('mobile');
};
