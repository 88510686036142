/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import Modal from 'components/common/modal';
import { css } from '@emotion/react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import Checkbox from 'components/common/Checkbox';
import Loader from 'components/common/Loader';
import { FeatureFlagName, UsersOrderBy } from 'generated/global-types';
import { Toast, ToastLevel, useToast } from 'ui-kit/toast';
import Button from 'components/common/Button';
import Pagination from 'components/common/Pagination';
import { feedsCount } from 'contexts/FeedId';
import {
  UsersWithFlags as UsersType,
  UsersWithFlagsVariables,
} from '../../../generated/UsersWithFlags';
import {
  GrantUserFeatureFlag,
  GrantUserFeatureFlagVariables,
} from '../../../generated/GrantUserFeatureFlag';
import { FeedId } from '../../../types/feeds';
import { userCurrentEnabledFeatureFlag } from './helpers';

const UsersQuery = loader('../../../graphql/UsersWithFlags.gql');

const UpdateFeatureFlagMutation = loader(
  '../../../graphql/GrantUserFeatureFlag.gql',
);

const USERS_PER_PAGE = 10;

const UserManagementModal: React.FC<
  {
    modalVisible: boolean;
    onDismiss: () => void;
    featureFlagName?: FeatureFlagName;
    currentFlagFeeds: (string | null)[];
  } & { children?: React.ReactNode }
> = ({ modalVisible, onDismiss, featureFlagName, currentFlagFeeds }) => {
  const { shouldShowToast, showToast, hideToast } = useToast();
  const [toastText, setToastText] = useState('');
  const [page, setPage] = useState(1);
  const isFirstPage = page === 1;

  const offset = isFirstPage ? 0 : (page - 1) * USERS_PER_PAGE;
  const perPage = USERS_PER_PAGE;

  // TODO: handle errors
  const { data: usersData, loading: usersLoading } = useQuery<
    UsersType,
    UsersWithFlagsVariables
  >(UsersQuery, {
    pollInterval: 5000,
    variables: {
      offset,
      perPage,
      orderBy: [UsersOrderBy.NAME_ASC],
    },
  });
  const totalCount = usersData?.sortedUsers?.totalCount || 0;

  const [grantUserFeatureFlag] = useMutation<
    GrantUserFeatureFlag,
    GrantUserFeatureFlagVariables
  >(UpdateFeatureFlagMutation, {
    onCompleted: (data) => {
      const user = usersData?.sortedUsers?.nodes.find(
        (user) => user.id === data.grantUserFeatureFlag?.user?.id,
      );

      if (user && featureFlagName) {
        let toastText = '';
        if (
          Object.values(user?.featureFlags[featureFlagName] ?? {}).filter(
            (enabled) => enabled === true,
          ).length === feedsCount()
        ) {
          toastText = `${featureFlagName
            .replace(/_/g, ' ')
            .toLowerCase()} is now enabled for: ${user.name} (${user.email})`;
        } else {
          toastText = `${featureFlagName
            ?.replace(/_/g, ' ')
            .toLowerCase()} is now disabled for: ${user.name} (${user.email})`;
        }
        setToastText(toastText);
        showToast();
      }
    },
  });

  const onChangeUserStatus = (
    {
      id,
      email,
      name,
      featureFlags,
    }: { id: string; name: string | null; email: string; featureFlags: any },
    flagName: FeatureFlagName,
    currentFlagFeeds: (string | null)[],
  ) => {
    const selectedUserFeeds = userCurrentEnabledFeatureFlag(
      featureFlags,
      flagName,
      Object.values(FeedId),
      currentFlagFeeds,
    );
    grantUserFeatureFlag({
      variables: {
        flagName,
        userId: id,
      },
      optimisticResponse: {
        grantUserFeatureFlag: {
          __typename: 'GrantUserFeatureFlagPayload',
          user: {
            __typename: 'User',
            featureFlags: selectedUserFeeds,
            id,
            email,
            name,
          },
        },
      },
    });
  };
  const usersList =
    featureFlagName &&
    usersData &&
    usersData.sortedUsers?.nodes.map((user) => (
      <div
        key={user.id}
        css={css`
          display: flex;
          align-items: center;
          padding-bottom: 10px;
        `}
      >
        <Checkbox
          checked={
            Object.values(user.featureFlags[featureFlagName] ?? {}).filter(
              Boolean,
            ).length === feedsCount()
          }
          onChange={() => {
            onChangeUserStatus(user, featureFlagName, currentFlagFeeds);
          }}
        />
        <span
          css={css`
            padding-left: 5px;
          `}
        >
          {user.name} ({user.email})
        </span>
      </div>
    ));

  return (
    <div>
      <Toast
        shouldShow={shouldShowToast}
        onDismiss={hideToast}
        level={ToastLevel.info}
      >
        {toastText}
      </Toast>
      {modalVisible && (
        <Modal
          title="Enable users for the selected feature flag"
          onDismiss={onDismiss}
          closeOnClickOutside
          headerBottomBorder
        >
          <Loader loading={usersLoading}>
            <div
              css={css`
                align-items: center;
                margin-bottom: 12px;
                position: relative;

                & > label {
                  cursor: pointer;
                  margin-left: 8px;
                }

                &:focus-within {
                  ::after {
                    content: '';
                    position: absolute;
                    top: -2px;
                    right: -2px;
                    bottom: -2px;
                    left: -2px;
                    border-radius: 4px;
                    pointer-events: none;
                  }
                }
              `}
            >
              {usersList}
              <Pagination
                currentPage={page}
                itemsPerPage={perPage}
                totalItems={totalCount}
                onNextClick={() => setPage(page + 1)}
                onPrevClick={() => setPage(page - 1)}
              />
            </div>
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
                margin-top: 24px;
              `}
            >
              <Button primary type="button" onClick={onDismiss}>
                Done
              </Button>
            </div>
          </Loader>
        </Modal>
      )}
    </div>
  );
};

export default UserManagementModal;
