import React, { useContext, useEffect, useState } from 'react';

const { REACT_APP_GIT_RELEASE } = process.env;
const GIT_RELEASE_LOCATION = `${process.env.PUBLIC_URL}/git-release.txt`;
const THREE_MINUTES_MS = 1000 * 60 * 3;
const ONE_HOUR_MS = 1000 * 60 * 60;

interface IStaleValue {
  isStale: boolean;
  showIsStale: boolean;
}
const StaleContext = React.createContext<IStaleValue>({
  isStale: false,
  showIsStale: false,
});

interface IProps {
  children?: React.ReactNode;
}

export const StaleProvider: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ children }: IProps) => {
  const [isStale, setIsStale] = useState(false);
  const [showIsStale, setShowIsStale] = useState(false);

  useEffect(() => {
    const checkIfStale = () => {
      setTimeout(async () => {
        try {
          const response = await fetch(GIT_RELEASE_LOCATION);
          const result = await response.text();
          if (!REACT_APP_GIT_RELEASE) {
            return;
          }

          if (result.trim() !== REACT_APP_GIT_RELEASE.trim()) {
            setIsStale(true);
          } else {
            // check again after waiting
            checkIfStale();
          }
        } catch (error) {
          // if something goes wrong, try again later
          checkIfStale();
        }
      }, THREE_MINUTES_MS);
    };

    checkIfStale();
  }, [setIsStale]);

  useEffect(() => {
    if (isStale) {
      setTimeout(() => {
        setShowIsStale(true);
      }, ONE_HOUR_MS);
    }
  }, [isStale]);

  return (
    <StaleContext.Provider
      value={{
        isStale,
        showIsStale,
      }}
    >
      {children}
    </StaleContext.Provider>
  );
};

export const useStale = () => useContext(StaleContext);
