/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as Sentry from '@sentry/browser';

import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import addToDate from 'date-fns/add';

import { useCurrentUser } from 'contexts/CurrentUser';
import { SUPPORTED_TAGS_AGENCIES } from 'constants/screen-tags';
import { isFeatureEnabled, useFeatureFlag } from 'hooks/useFeatureFlag';
import { FeedId } from 'types/feeds';
import * as Layout from '../../common/layout';
import Loader from '../../common/skeletons/PageWithTable';
import { ThemeType } from '../../../theme';

import PageHeading from '../../scaffolding/PageHeading';
import PageMeta from '../../common/PageMeta';
import Bullet, { BulletSize } from '../../common/Bullet';

import getScreenIcon from '../../../utils/get-screen-icon';

import {
  table,
  tableHeader,
  tableBody,
  tableHeaderCell,
  tableCell,
  tableRow,
} from '../../common/table-classes';
import Button from '../../common/Button';

import {
  FeatureFlagName,
  ScreensNormalizedsOrderBy,
} from '../../../generated/global-types';
import {
  StopScreens as StopScreensType,
  StopScreensVariables,
} from '../../../generated/StopScreens';
import {
  UpdateStopBlueprint,
  UpdateStopBlueprintVariables,
} from '../../../generated/UpdateStopBlueprint';

import { BlueprintUploader } from '../../common/screen-stops/blueprint';

import useQueryParams from '../../../hooks/useQueryParams';
import { SortButton } from '../../common/table-elements';
import Pagination from '../../common/Pagination';
import EditTagsModal from './edit-tags-modal';

const StopScreensQuery = loader('../../../graphql/StopScreens.gql');

const UpdateStopBlueprintMutation = loader(
  '../../../graphql/UpdateStopBlueprint.gql',
);

const PAGE_SIZE = 50;

const QUERY_PARAM_KEYS = {
  orderBy: 'order',
  page: 'page',
};

const useNext24hrs = () => {
  const now = new Date();

  const [start] = useState(now.toISOString());
  const [end] = useState(addToDate(now, { hours: 24 }).toISOString());

  return { start, end };
};

const Screens: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const { feedId, stopId } = useParams<{ feedId: FeedId; stopId: string }>();
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useCurrentUser();

  const isCustomTaggingEnabled = useFeatureFlag(FeatureFlagName.CUSTOM_TAGGING);

  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);

  const [orderBy, setOrderBy] = useState<ScreensNormalizedsOrderBy>(
    ScreensNormalizedsOrderBy[
      queryParams.get(QUERY_PARAM_KEYS.orderBy) as ScreensNormalizedsOrderBy
    ] || ScreensNormalizedsOrderBy.NAME_ASC,
  );

  const plannedWorkEnabled =
    currentUser && feedId
      ? isFeatureEnabled(
          currentUser,
          FeatureFlagName.PLANNED_WORK,
          feedId as FeedId,
        ) &&
        isFeatureEnabled(
          currentUser,
          FeatureFlagName.PLANNED_WORK_SCREEN_TARGETS,
          feedId as FeedId,
        )
      : false;

  const alertsEnabled =
    currentUser && feedId
      ? isFeatureEnabled(
          currentUser,
          FeatureFlagName.SERVICE_ALERTS,
          feedId as FeedId,
        ) &&
        isFeatureEnabled(
          currentUser,
          FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
          feedId as FeedId,
        )
      : false;

  const { start, end } = useNext24hrs();

  const pageParam = queryParams.get('page') || '';
  const page = Number.isNaN(parseInt(pageParam, 10))
    ? 1
    : parseInt(pageParam, 10);
  const offset = (page - 1) * PAGE_SIZE;

  const { loading, error, data } = useQuery<
    StopScreensType,
    StopScreensVariables
  >(StopScreensQuery, {
    variables: {
      feedId: feedId as FeedId,
      first: PAGE_SIZE,
      offset,
      gtfsId: stopId || '',
      orderBy: [orderBy],
      timeframe: {
        start: { value: start, inclusive: true },
        end: {
          value: end,
          inclusive: true,
        },
      },
      plannedWorkEnabled,
      alertsEnabled,
    },
    fetchPolicy: 'cache-and-network',
  });

  const existingBlueprint = data?.stop?.blueprintUpload?.signedS3Url || null;

  const [updateStopBlueprint] = useMutation<
    UpdateStopBlueprint,
    UpdateStopBlueprintVariables
  >(UpdateStopBlueprintMutation);

  useEffect(() => {
    queryParams.set(QUERY_PARAM_KEYS.orderBy, orderBy.toString());
    queryParams.set(QUERY_PARAM_KEYS.page, page.toString());
    history.replace(`${location.pathname}?${queryParams.toString()}`);
  }, [history, queryParams, location.pathname, orderBy, page]);

  if (error) {
    return <div>Error {error.message}</div>;
  }

  const updateBlueprint = (uploadData?: any) => {
    if (data?.stop?.gtfsId) {
      try {
        updateStopBlueprint({
          variables: {
            gtfsId: data?.stop?.gtfsId,
            blueprintUploadId: uploadData?.id ? uploadData?.id : null,
          },
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  const handleClick = (newPage: number) => {
    queryParams.set('page', encodeURIComponent(newPage));
    history.push(`${location.pathname}?${queryParams.toString()}`);
  };

  const screensFound =
    data &&
    data.stop &&
    data.stop.screens &&
    data.stop.screens.nodes.length > 0;

  const stopActionsEl = (
    <div
      css={(theme: ThemeType) => css`
        border-top: 1px dashed ${theme.colors['border-dark']};
        padding: ${theme.spacing.small} 0 ${theme.spacing.xxxsmall};
        display: flex;
        width: 100%;
        margin-top: 16px;
      `}
    >
      {isCustomTaggingEnabled &&
        SUPPORTED_TAGS_AGENCIES.includes(feedId as FeedId) && (
          <Button
            css={css`
              margin-right: 15px;
              min-height: 32px;
              ${!existingBlueprint &&
              css`
                padding-bottom: 5px;
              `}
            `}
            size="xsmall"
            plain={!existingBlueprint}
            onClick={() => setIsTagsModalOpen(true)}
          >
            Edit Tags
          </Button>
        )}
      <BlueprintUploader
        existingBlueprint={existingBlueprint}
        onChange={updateBlueprint}
      />
    </div>
  );

  return (
    <Layout.Page>
      <PageMeta title={data?.stop?.name || 'Stop'} />
      <PageHeading
        css={css`
          padding: 32px 32px 14px;
        `}
        headingWrapperCss={css`
          margin-right: 16px;
        `}
        childrenWrapperCss={css`
          align-items: center;
          flex: 1;
        `}
        loading={loading && !data}
        breadcrumbs={[
          'Screens',
          { label: 'By Station', to: `/${feedId}/screens` },
        ]}
        title={data?.stop?.name || ''}
        subHeader={stopActionsEl}
      >
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
          `}
        >
          {data?.stop?.routes.nodes.map((n) => (
            <div
              key={n.gtfsId}
              css={css`
                margin-right: 4px;
                margin-bottom: 4px;
              `}
            >
              <Bullet routeId={n.gtfsId} size={BulletSize.xlarge} />
            </div>
          ))}
        </div>
      </PageHeading>
      <div
        css={(theme: ThemeType) => css`
          margin: ${theme.spacing.small} ${theme.spacing.large};
        `}
      >
        <Loader loading={loading && !data}>
          <div css={table}>
            <div css={tableHeader}>
              <div
                css={[
                  tableHeaderCell,
                  css`
                    /* Adds extra width to the screen names column to avoid names wrapping as much as possible */
                    width: 30%;
                  `,
                ]}
              >
                <SortButton
                  css={css`
                    padding-right: 4px;
                  `}
                  title="Screen Name"
                  isSorted={orderBy.includes('NAME')}
                  isSortedDesc={orderBy.includes(
                    ScreensNormalizedsOrderBy.NAME_DESC,
                  )}
                  onClick={() => {
                    setOrderBy(
                      orderBy.includes(ScreensNormalizedsOrderBy.NAME_ASC)
                        ? ScreensNormalizedsOrderBy.NAME_DESC
                        : ScreensNormalizedsOrderBy.NAME_ASC,
                    );
                  }}
                />
              </div>

              <div
                css={[
                  tableHeaderCell,
                  css`
                    width: 19%;
                  `,
                ]}
              >
                Line(s)
              </div>
              <div css={tableHeaderCell}>Type</div>
              <div css={tableHeaderCell}>Campaigns</div>
              {plannedWorkEnabled && (
                <div css={tableHeaderCell}>Planned Works</div>
              )}
              {alertsEnabled && <div css={tableHeaderCell}>Alerts</div>}
            </div>
            {screensFound ? (
              <div css={tableBody}>
                {data?.stop?.screens.nodes.map((screen, idx) => (
                  <Link
                    key={screen.id || idx}
                    to={`/${feedId}/screens/${screen.id}${
                      screen.deployType
                        ? `?t=${screen.deployType.toLowerCase()}`
                        : ''
                    }`}
                    css={css`
                      ${tableRow};
                      color: inherit;
                    `}
                  >
                    <span
                      css={css`
                        ${tableCell};
                        font-weight: bold;
                      `}
                    >
                      {screen.name}
                    </span>
                    <span css={tableCell}>
                      {data.stop &&
                        data.stop.routes &&
                        data.stop.routes.nodes.map((n) => (
                          <React.Fragment key={n.gtfsId}>
                            <Bullet
                              routeId={n.gtfsId}
                              size={BulletSize.xsmall}
                              css={css`
                                font-size: 0.9em;
                              `}
                            />{' '}
                          </React.Fragment>
                        ))}
                    </span>
                    <span css={tableCell}>
                      {getScreenIcon(screen, 42, feedId)}
                    </span>
                    <span css={tableCell}>
                      {screen.campaignRelevantCount ?? 0}
                    </span>
                    {plannedWorkEnabled && (
                      <span css={tableCell}>
                        {screen.plannedWorkRelevantCount ?? 0}
                      </span>
                    )}
                    {alertsEnabled && (
                      <span css={tableCell}>
                        {screen.alertRelevantCount ?? 0}
                      </span>
                    )}
                  </Link>
                ))}
              </div>
            ) : (
              <div>No screens found</div>
            )}
          </div>

          {screensFound && (
            <div
              css={css`
                margin-top: 1rem;
                width: 100%;
              `}
            >
              <Pagination
                currentPage={page}
                itemsPerPage={PAGE_SIZE}
                totalItems={data?.stop?.screens?.totalCount || 0}
                onNextClick={() => handleClick(page + 1)}
                onPrevClick={() => handleClick(page - 1)}
              />
            </div>
          )}
        </Loader>
      </div>
      <EditTagsModal
        isOpen={isTagsModalOpen}
        stopId={stopId as string}
        onDismiss={() => setIsTagsModalOpen(false)}
      />
    </Layout.Page>
  );
};

export default Screens;
