/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { Fragment } from 'react';
import {
  OptionProps,
  ValueContainerProps,
  components,
  PlaceholderProps,
  ActionMeta,
} from 'react-select';
import uniq from 'lodash/uniq';
import theme from 'theme';
import { MnrRollingStockLine } from 'generated/global-types';

import Bullet, { BulletSize } from '../Bullet';
import Checkbox, { CheckboxSize } from '../Checkbox';
import Select from './Select';

enum LineOptionType {
  ALL_LINES_OPTION = 'ALL_LINES_OPTION',
  LINE_OPTION = 'LINE_OPTION',
}

type LineOption = {
  value: string;
  label?: string;
  optionType: LineOptionType;
  lines?: string[];
};

// We hardcode the line options here along with their route ids, because we own
// that info from Outfront, it's not fetched from any GTFS specific data source
export const MNR_ROLLING_STOCK_LINES: {
  [key in MnrRollingStockLine]: string[];
} = {
  [MnrRollingStockLine.HARLEM_HUDSON]: ['MNR:1', 'MNR:2'],
  [MnrRollingStockLine.NEW_HAVEN]: ['MNR:3'],
};

const ALL_LINES_OPTION = {
  label: 'All Lines',
  value: 'all-lines-option',
  optionType: LineOptionType.ALL_LINES_OPTION,
};

const LINE_OPTIONS = [
  {
    value: MnrRollingStockLine.HARLEM_HUDSON,
    optionType: LineOptionType.LINE_OPTION,
    lines: MNR_ROLLING_STOCK_LINES[MnrRollingStockLine.HARLEM_HUDSON],
  },
  {
    value: MnrRollingStockLine.NEW_HAVEN,
    optionType: LineOptionType.LINE_OPTION,
    lines: MNR_ROLLING_STOCK_LINES[MnrRollingStockLine.NEW_HAVEN],
  },
];

const OPTIONS: LineOption[] = [ALL_LINES_OPTION, ...LINE_OPTIONS];
const LINE_IDs = LINE_OPTIONS.map((l) => l.value);

const SELECT_COMPONENTS = {
  Option: (props: OptionProps<any>) => {
    const {
      innerProps,
      isSelected,
      data: { label, lines },
    } = props;

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div {...innerProps} style={props.getStyles('option', props) as any}>
          <Checkbox
            css={css`
              margin-right: 8px;
            `}
            size={CheckboxSize.medium}
            checked={isSelected}
            onChange={() => {}}
          />
          {label && (
            <span
              css={css`
                ${theme.typography.sizes.medium};
                font-family: ${theme.typography.families.primary};
              `}
            >
              {label}
            </span>
          )}
          {lines?.map((routeId: string) => (
            <Bullet
              key={routeId}
              size={BulletSize.xsmall}
              routeId={routeId}
              style={{ marginRight: '4px' }}
            />
          ))}
        </div>
      </div>
    );
  },
  ValueContainer: (props: ValueContainerProps<any>) => {
    const targetedLines: string[] = uniq(
      props
        .getValue()
        .filter((o: LineOption) => o.optionType === LineOptionType.LINE_OPTION)
        .flatMap((o: LineOption) => o.lines || [])
        .sort(),
    );
    return (
      <components.ValueContainer {...props}>
        {targetedLines.map((routeId) => (
          <Bullet
            key={routeId}
            size={BulletSize.xsmall}
            routeId={routeId}
            style={{ marginRight: '4px' }}
          />
        ))}
        {props.children}
      </components.ValueContainer>
    );
  },
  Placeholder: (props: PlaceholderProps<any>) => {
    const selectedOptions = props.getValue();
    return selectedOptions.length ? null : (
      <components.Placeholder {...props} />
    );
  },
};

const getSelectedOptions = (value: MnrRollingStockLine[]): LineOption[] => {
  const selectedOptions: LineOption[] = [];
  const selectedLineIds = new Set<string>(value);

  if (LINE_IDs.every((id) => selectedLineIds.has(id))) {
    selectedOptions.push(ALL_LINES_OPTION);
  }

  selectedOptions.push(
    ...LINE_OPTIONS.filter((o) => selectedLineIds.has(o.value)),
  );

  return selectedOptions;
};

const MnrRollingStockLineSelector: React.FC<
  {
    value: MnrRollingStockLine[];
    onChange: (lines: MnrRollingStockLine[]) => void;
  } & { children?: React.ReactNode }
> = ({ value, onChange }) => {
  const selectedOptions = getSelectedOptions(value);

  return (
    <Fragment>
      <label
        css={css`
          ${theme.typography.sizes.small};
          font-weight: ${theme.typography.weights.bold};
        `}
        htmlFor="mnr-rolling-stock-line-selector"
      >
        Line(s)
      </label>
      <Select
        id="mnr-rolling-stock-line-selector"
        css={css`
          margin-bottom: 16px;
          margin-top: 8px;
          width: 62%;
        `}
        isMulti
        isSearchable={false}
        isClearable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}
        placeholder="Select Lines(s)"
        options={OPTIONS}
        components={SELECT_COMPONENTS}
        value={selectedOptions}
        onChange={(
          newSelectedOptions: LineOption[],
          { option, action }: ActionMeta<LineOption>,
        ) => {
          if (option?.optionType === LineOptionType.ALL_LINES_OPTION) {
            if (action === 'select-option') {
              return onChange(
                LINE_OPTIONS.map((o) => o.value as MnrRollingStockLine),
              );
            }

            if (action === 'deselect-option') {
              return onChange([]);
            }
          }

          return onChange(
            newSelectedOptions
              .filter((o) => o.optionType === LineOptionType.LINE_OPTION)
              .map((o) => o.value as MnrRollingStockLine),
          );
        }}
      />
    </Fragment>
  );
};

export default MnrRollingStockLineSelector;
