export const WEB_BODY_NAME = 'webBody';
export const WEB_BODY_ADDITIONAL_NAME = 'webBodyAdditional';
export const WEB_TARGETED_ROUTES_NAME = 'webTargetedRoutes';
export const WEB_TARGETED_STOPS_NAME = 'webTargetedStops';
export const WEB_IMPACTS_NAME = 'webImpacts';
export const WEB_EXPIRATION_NAME = 'webExpiration';
export const AFFECTED_STOPS_NAME = 'affectedStops';
export const AFFECTED_STOPS_EDITED_NAME = 'affectedStopsEdited';

export const EMAIL_SUBJECT_NAME = 'emailSubject';
export const EMAIL_BODY_NAME = 'emailBody';
export const EMAIL_TYPES_NAME = 'emailTypes';

export const SMS_BODY_NAME = 'smsBody';

export const TWEETS_NAME = 'tweets';
export const TWEET_CUSTOMIZED_NAME = 'tweetCustomized';
export const TWEET_ATTACHMENTS_NAME = 'tweetAttachments';

export const SCREENS_NAME = 'screensName';
export const SCREEN_TITLE_NAME = 'screenTitle';
export const SCREEN_TITLE_CUSTOMIZED_NAME = 'screenTitleCustomized';
export const SCREEN_BODY_NAME = 'screenBody';
export const SCREEN_BODY_ADDITIONAL_NAME = 'screenBodyAdditional';
export const SCREEN_TARGETED_ROUTES_NAME = 'screenTargetedRoutes';
export const SCREEN_CUSTOMIZED_NAME = 'screenCustomized';
export const SCREEN_TARGETING_NAME = 'screenTargeting';
export const SCREEN_UPDATE_METHOD_NAME = 'screenUpdateMethod';
export const SCREEN_TARGETING_EDITED = 'screenTargetingEdited';
export const SCREEN_IS_DUPLICATE_NAME = 'screenIsDuplicate';
export const SCREEN_IS_CLEARING_NAME = 'screenIsClearing';
export const WEIGHT_FREQUENCY_NAME = 'weightFrequency';
export const INITIAL_SCREEN_CHOICE_NAME = 'initialScreenChoice';

export const INTERNAL_LOG_INCIDENT_START_AT = 'incidentStartAt';
export const INTERNAL_LOG_INCIDENT_END_AT = 'incidentEndAt';
export const INTERNAL_LOG_NOTIFIED_AT = 'notifiedAt';
export const INTERNAL_LOG_NOTES = 'notes';
export const INTERNAL_LOG_NEEDED_BY = 'neededBy';

export const LEADER_NO_OUTLETS_NAME = 'noOutlets';

// matches strings in valid 24-hour time, e.g. 00:20, 23:59, 12:00
export const TIME_PATTERN_24_HRS = /^(?:[01]\d|2[0-3]):(?:[0-5]\d)$/;
export const MUST_USE_24_HOUR_FORMAT =
  'Please enter time in a valid 24 hr format.';
export const EXPIRY_CANNOT_BE_IN_THE_PAST =
  'Expiry date cannot be set in the past.';

export const INCIDENT_START_PRIOR_POSTED =
  'The time entered for Incident Start should be prior to Posted.';
export const INCIDENT_START_PRIOR_NOTIFIED =
  'The time entered for Incident Start should be prior to Notified.';
export const NOTIFIED_PRIOR_POSTED =
  'The time entered for Notified should be prior to Posted.';

export const SCREEN_STATIC_NOTICE = 'This screen is not allowed to play video.';

export const TWEETS_JOIN_SEPERATOR = '\n\n';

export const CONFLICT_MESSAGE =
  'This message is set to appear on a screen that’s targeted by another message in this alert. Select which screen(s) should receive this message:';

export const SAVE_LIVE_ALERT_AS_DRAFT_MESSAGE =
  'Saving this Live Alert as a draft will clear it from the Live Screens and Webs/Apps until it is re-published. Are you sure you want to continue?';

export const IGNORED_TRIPS_ROUTES = new Set([
  'MNR:50',
  'MNR:51',
  'NYW:10212',
  'NYW:10213',
]);

export const EMAIL_ICON_WARNING_MESSAGE =
  'The accessibility and shuttle bus icons will not currently convert in the email frontend. Please remove them from your Email & SMS message.';
