/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { ThemeType } from '../../theme';

import { ReactComponent as CloseIcon } from '../../images/cross-blue.svg';

import Button from './Button';
import Heading from './Heading';

const TITLE_ID = 'modal__title';

export const Z_INDEX = 1000;

const Modal: React.FC<
  {
    onDismiss: () => void;
    title: React.ReactNode;
    bodyBackgroundColor?: string;
    className?: string;
    closeOnClickOutside?: boolean;
    headerBottomBorder?: boolean;
    isOpen?: boolean;
    margin?: string;
    maxWidth?: string;
    minWidth?: string;
    restOnContent?: boolean;
    footer?: React.ReactNode;
  } & { children?: React.ReactNode }
> = ({
  children,
  closeOnClickOutside = true,
  headerBottomBorder = true,
  isOpen,
  onDismiss,
  title,
  restOnContent = false,
  bodyBackgroundColor = 'transparent',
  margin = '25vh auto',
  maxWidth = '520px',
  minWidth = '0px',
  footer,
  ...rest
}) => {
  return (
    <DialogOverlay
      css={css`
        position: fixed;
        z-index: ${Z_INDEX};
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        overflow: auto;
        background: rgba(0, 0, 0, 0.6);
      `}
      onDismiss={closeOnClickOutside ? onDismiss : undefined}
      isOpen={isOpen}
      {...(restOnContent ? {} : rest)}
    >
      <DialogContent
        aria-labelledby={TITLE_ID}
        css={(theme: ThemeType) => css`
          width: 50vw;
          max-width: ${maxWidth};
          min-width: ${minWidth};
          margin: ${margin};
          background: ${theme.colors.white};
          border-radius: 4px;
          box-shadow:
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12),
            0 1px 3px 0 rgba(0, 0, 0, 0.2);
          outline: none;
        `}
        {...(restOnContent ? rest : {})}
      >
        <div
          css={(theme: ThemeType) => css`
            display: flex;
            justify-content: space-between;
            padding: 24px 32px;
            ${headerBottomBorder
              ? `border-bottom: 1px solid ${theme.colors.border};`
              : ''}
          `}
        >
          <Heading id={TITLE_ID} level={2} size="large">
            {title}
          </Heading>
          <Button
            plain
            title="Close"
            type="button"
            onClick={onDismiss}
            css={css`
              &:hover {
                opacity: 0.5;
              }
            `}
          >
            <CloseIcon aria-hidden="true" />
          </Button>
        </div>
        <div
          css={css`
            padding: 24px 32px 32px 32px;
            background: ${bodyBackgroundColor};
          `}
        >
          {children}
        </div>
        {footer}
      </DialogContent>
    </DialogOverlay>
  );
};

export default Modal;
