/** @jsxImportSource @emotion/react */

import React from 'react';
import useQueryParams from 'hooks/useQueryParams';
import { ASSET_TYPES, ASSET_TYPE_KEY } from 'constants/assets';

import SocialMediaAssetEdit from './social-media-asset-edit';
import LockableAssetEdit from './lockable-asset-edit';

const AssetEdit: React.FC<
  unknown & { children?: React.ReactNode; scheduleEdit?: boolean }
> = ({ scheduleEdit, children }) => {
  const query = useQueryParams();
  const uploadType = query.get(ASSET_TYPE_KEY);

  if (uploadType === ASSET_TYPES.SOCIAL_MEDIA) {
    return <SocialMediaAssetEdit />;
  }

  return (
    <LockableAssetEdit scheduleEdit={!!scheduleEdit}>
      {children}
    </LockableAssetEdit>
  );
};

export default AssetEdit;
