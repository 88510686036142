/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';

import theme from 'theme';
import { ReactComponent as InfoIcon } from 'images/info_icon.svg';
import { VisuallyHidden } from '@reach/visually-hidden';
import pluralize from 'pluralize';

interface ScreensAvailabilityCountProps {
  className?: string;
  numTargeted?: number;
  numLocked?: number;
  isCove?: boolean;
  coveMultiplier?: number;
  screensLabel?: string;
}

const screenAvailabilityTooltip = css`
  margin-left: 5px;
  position: relative;
  transition: 0.3s all ease;
  &:hover .availability-tooltip__tooltip-message {
    display: block;
  }
`;

const screenAvailabilityTooltipIcon = css`
  position: relative;
  top: 3px;
  margin-right: 4px;
`;

const screenAvailabilityTooltipMessage = css`
  background: ${theme.colors.white};
  color: ${theme.colors.dark2};
  border: none;
  border-radius: 2px;
  box-shadow:
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  ${theme.typography.sizes.small};
  font-family: ${theme.typography.families.primary};
  font-weight: ${theme.typography.weights.normal};
  text-align: left;
  width: 170px;
  top: 10px;
  left: 10px;
  padding: ${theme.spacing.small};
  white-space: pre-wrap;
  position: absolute;
  display: none;
`;

const INFO_TEXT = 'Screens not available are locked';

const ScreensAvailabilityCount: React.FC<
  ScreensAvailabilityCountProps & { children?: React.ReactNode }
> = ({
  className,
  numTargeted = 0,
  numLocked = 0,
  isCove,
  coveMultiplier,
  screensLabel,
}) => {
  if (numLocked === 0) {
    return (
      <span className={className}>
        {isCove
          ? `${numTargeted} ${pluralize('Cove', numTargeted)}${
              coveMultiplier ? `, ${coveMultiplier * numTargeted} Each` : ''
            }`
          : `${numTargeted} Available ${screensLabel ?? ''} `}
      </span>
    );
  }

  return (
    <span className={className}>
      {numTargeted - numLocked}/{numTargeted} Available
      <VisuallyHidden>{INFO_TEXT}</VisuallyHidden>
      <span aria-hidden css={screenAvailabilityTooltip}>
        <span>
          <InfoIcon
            className="availability-tooltip__icon"
            css={screenAvailabilityTooltipIcon}
          />
          <p
            className="availability-tooltip__tooltip-message"
            css={screenAvailabilityTooltipMessage}
          >
            {INFO_TEXT}
          </p>
        </span>
      </span>
    </span>
  );
};

export default ScreensAvailabilityCount;
