/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react';
import React, { ReactNode } from 'react';
import { SearchableSocialMediaUploads_searchSocialMediaUploads_SearchSocialMediaUploadsResult_results_Upload as SocialMediaUploadType } from 'generated/SearchableSocialMediaUploads';
import { Thumbnail } from 'ui-kit/media-library';

const AssetsView: React.FC<
  {
    title: ReactNode;
    assets: SocialMediaUploadType[];
    onAssetClick: (asset: SocialMediaUploadType) => void;
    thumbnailWidth?: string;
    thumbnailHeight?: string;
    applyThumbnailStyles?: (asset?: SocialMediaUploadType) => SerializedStyles;
    showFolderName?: boolean;
  } & { children?: React.ReactNode }
> = ({
  title,
  assets,
  onAssetClick,
  thumbnailWidth = '238px',
  thumbnailHeight = '135px',
  applyThumbnailStyles,
  showFolderName = true,
}) => {
  return (
    <React.Fragment>
      {title}
      <div>
        {assets.map((asset) => (
          <Thumbnail
            css={css`
              margin: 0 24px 24px 0;
              cursor: pointer;
              text-align: left;
              width: ${thumbnailWidth};
              ${(() => applyThumbnailStyles && applyThumbnailStyles(asset))()}
            `}
            key={asset.id}
            // @ts-ignore
            upload={asset}
            hideDate
            width={thumbnailWidth}
            height={thumbnailHeight}
            fontWeight="normal"
            previewBackgroundColor="#fafafa"
            folderName={
              showFolderName
                ? asset?.socialMediaUploadByUploadId?.folder?.name
                : ''
            }
            onClick={() => onAssetClick(asset)}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default AssetsView;
