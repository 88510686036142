/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';

import { ThemeType } from 'theme';
import TweetInput from 'components/common/tweets/tweet-input';
import { AttachmentsType } from 'types/twitter-attachments';
import Button from 'components/common/Button';
import { FeedId } from '@mta-live-media-manager/shared';
import { useTweetThreadState } from './use-tweet-thread-state';
import { Tweet } from './types';

interface Props {
  id?: string;
  error?: boolean;
  maxLength?: number;
  tweets: Tweet[];
  addTweet: () => void;
  removeTweet: (id: number) => void;
  setText: (id: number, newText: string) => void;
  setAttachments: (id: number, newAttachments: AttachmentsType) => void;
  setQuoteId: (id: number, newQuoteId: string) => void;
  setReplyId: (newReplyId: string) => void;
  previousTweet?: string;
  originalTweet?: string;
  replyPreviousTweet?: boolean;
  feedId?: FeedId;
}

const TweetThreadInput: React.FC<Props> = ({
  id,
  error = false,
  maxLength,
  tweets,
  addTweet,
  removeTweet,
  setText,
  setAttachments,
  setQuoteId,
  setReplyId,
  previousTweet,
  originalTweet,
  feedId,
}) => {
  const [replyPreviousTweet, setValue] = useState(false);
  return (
    <React.Fragment>
      <ol
        id={id}
        css={(theme: ThemeType) => css`
        padding: 0;
        margin: 0;

        list-style: none;
        counter-reset: twitter-thread;
        --twitter-thread-counter: ${
          tweets.length > 1
            ? `' ' counter(twitter-thread) ' of ${tweets.length}'`
            : ''
        };
        li {
          counter-increment: twitter-thread;
          margin-bottom: 12px;
          position: relative;

          :not(:last-of-type) {
            ::after {
              position: absolute;
              content: ' ';
              width: 1px;
              height: 12px;
              left: 25px;
              background-color: ${theme.colors['border-dark']};
            }
          }

        ${
          replyPreviousTweet
            ? `:first-of-type {
              ::after {
                position: absolute;
                content: ' ';
                width: 1px;
                height: 26px;
                left: 19px;
                top: -25px;
                background-color: ${theme.colors['border-dark']};
              }
            }`
            : ''
        }
      `}
      >
        {tweets.map((tweet, id) => (
          <li key={tweet.id}>
            <TweetInput
              id={`thread_input__tweet_input__${id}`}
              error={error}
              maxLength={id === 0 ? maxLength : undefined}
              text={tweet.text}
              attachments={tweet.attachments}
              onTextChange={(newText) => setText(id, newText)}
              onAttachmentsChange={(newAttachments) =>
                setAttachments(id, newAttachments)
              }
              onDismiss={tweets.length > 1 ? () => removeTweet(id) : undefined}
              unThread={
                replyPreviousTweet
                  ? () => {
                      setValue(!replyPreviousTweet);
                      setReplyId(
                        previousTweet && !replyPreviousTweet
                          ? previousTweet
                          : '',
                      );
                    }
                  : undefined
              }
              originalTweet={originalTweet}
              feedId={feedId || undefined}
              quoteId={originalTweet || ''}
              replyId={previousTweet || ''}
              onQuoteIdChange={(newQuoteId) => setQuoteId(id, newQuoteId)}
              onReplyIdChange={(newReplyId) => setReplyId(newReplyId)}
            />
          </li>
        ))}
      </ol>
      {previousTweet && !replyPreviousTweet && (
        <Button
          type="button"
          onClick={() => {
            setValue(!replyPreviousTweet);
            setReplyId(!replyPreviousTweet ? previousTweet : '');
          }}
          css={css`
            padding-left: 18px;
          `}
          plain
        >
          + Thread with Previous Message
        </Button>
      )}
      <Button
        type="button"
        onClick={addTweet}
        plain
        css={css`
          padding-left: 18px;
        `}
      >
        {tweets.length <= 1 && !replyPreviousTweet
          ? '+ Create Thread'
          : '+ Add Tweet to Thread'}
      </Button>
    </React.Fragment>
  );
};

export const ConnectedTweetThreadInput = () => {
  const props = useTweetThreadState();
  return <TweetThreadInput {...props} />;
};

export default TweetThreadInput;
