/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import assertNever from 'assert-never';
import ms from 'ms.macro';

import { useTheme } from 'contexts/Theme';

import Button from 'components/common/Button';

import { ReactComponent as CloseIcon } from 'images/cross.svg';
import { ReactComponent as InfoIcon } from 'images/info_icon.svg';
import { ReactComponent as SuccessIcon } from 'images/icon_success.svg';

export enum ToastLevel {
  info = 'info',
  success = 'success',
  error = 'error',
}

const DEFAULT_TOAST_MS = ms('5 seconds');
export const useToast = (startShown: boolean = false, durationMs?: number) => {
  const [shouldShowToast, setShouldShowToast] = useState(startShown);

  useEffect(() => {
    let timeoutId: number;
    if (shouldShowToast) {
      // for some reason, `web` is treating browser API with Node types.
      // @ts-ignore
      timeoutId = setTimeout(() => {
        setShouldShowToast(false);
      }, durationMs || DEFAULT_TOAST_MS);
    }

    return () => window.clearTimeout(timeoutId);
  }, [shouldShowToast, durationMs]);

  const showToast = () => {
    setShouldShowToast(true);
  };

  const hideToast = () => {
    setShouldShowToast(false);
  };

  return {
    shouldShowToast,
    showToast,
    hideToast,
  };
};

const TOAST_TRANSITION_MS = ms('0.5s');

interface ToastProps {
  onDismiss: () => void;
  level?: ToastLevel;
  shouldShow?: boolean;
  positionChange?: boolean;
}

export const Toast: React.FC<ToastProps & { children?: React.ReactNode }> = ({
  children,
  onDismiss,
  level = ToastLevel.info,
  positionChange = true,
  shouldShow = false,
}) => {
  const opacity = shouldShow ? '1' : '0';
  const theme = useTheme();

  const { icon, foregroundColor, backgroundColor } = (() => {
    switch (level) {
      case ToastLevel.info:
        return {
          icon: <InfoIcon />,
          foregroundColor: 'black',
          backgroundColor: '#d3ebff',
        };
      case ToastLevel.success:
        return {
          icon: <SuccessIcon />,
          foregroundColor: theme.colors['status-success-foreground'],
          backgroundColor: theme.colors['status-success-background'],
        };
      case ToastLevel.error:
        return {
          icon: undefined,
          foregroundColor: theme.colors['status-error-foreground'],
          backgroundColor: theme.colors['status-error-background'],
        };

      default: {
        return assertNever(level);
      }
    }
  })();

  return (
    <div
      aria-hidden={!shouldShow}
      role="status"
      aria-live="polite"
      aria-atomic="true"
      css={css`
        ${theme.typography.sizes.small};
        pointer-events: ${shouldShow ? 'auto' : 'none'};
        position: ${positionChange ? 'fixed' : 'initial'};
        z-index: 10000;
        top: 0px;
        right: 0px;
        width: 400px;
        display: flex;
        justify-content: space-between;
        margin-top: ${positionChange ? '16px' : '0'};
        margin-right: ${positionChange ? '32px' : '0'};
        padding: 12px;
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        opacity: ${opacity};
        transition: ${TOAST_TRANSITION_MS}ms opacity;

        color: ${foregroundColor};
        background-color: ${backgroundColor};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin-right: 8px;
          `}
        >
          {icon}
        </div>
        {children}
      </div>
      <div
        css={css`
          display: flex;
        `}
      >
        <Button
          plain
          disabled={!shouldShow}
          title="Close"
          type="button"
          onClick={onDismiss}
          css={css`
            margin-left: 8px;
            &:hover {
              opacity: 0.5;
            }
          `}
        >
          <CloseIcon stroke={foregroundColor} aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
};
