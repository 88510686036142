/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ThemeType, ThemeSizes } from '../../../theme';

const bulletSize = (size: ThemeSizes): number => {
  // Setting bullet size to pixel eqivalent of line-height
  switch (size) {
    case 'xxxsmall':
      return 10;
    case 'xxsmall':
      return 12.4;
    case 'xsmall':
      return 15.6;
    case 'small':
      return 15.96;
    case 'medium':
      return 23;
    case 'large':
      return 29;
    case 'xlarge':
      return 27.84;
    case 'xxlarge':
      return 45;
    case 'xxxlarge':
      return 60;
    case 'xxxxlarge':
      return 80;
    default:
      return 20;
  }
};

const messageCSS =
  (size: ThemeSizes = 'medium') =>
  (theme: ThemeType) => {
    const bSize = bulletSize(size);
    const bMargin = (0.1363636364 * bSize) / 2;
    return css`
      p {
        ${theme.typography.sizes[size]};
        font-family: ${theme.typography.families.secondary};
        color: ${theme.colors.black};
        margin: 0 0 8px;
        overflow-wrap: break-word;

        [data-bullet-route-id^='mtasbwy-'] {
          width: ${bSize}px;
          height: ${bSize}px;

          & + [data-bullet-route-id^='mtasbwy-'] {
            margin-left: ${bMargin}px;
            margin-right: ${bMargin}px;
          }
        }

        [data-bullet-route-id^='li'],
        [data-bullet-route-id^='mta-'],
        [data-bullet-route-id^='njt'],
        [data-bullet-route-id^='mnr'],
        [data-bullet-route-id^='bt'] {
          font-size: 85%;
        }

        [data-bullet-display^='generic'] {
          ${theme.typography.sizes.medium};
          padding: 0px;
          background: unset;

          & + [data-bullet-display^='generic'] {
            margin-left: ${bMargin}px;
            margin-right: ${bMargin}px;
          }
        }
      }
    `;
  };

export const PlainMessageDisplay: React.FC<{
  innerHtml: string;
  size: ThemeSizes;
  children?: React.ReactNode;
}> = ({ innerHtml, size = 'medium', ...props }) => (
  <div
    css={messageCSS(size)}
    dangerouslySetInnerHTML={{ __html: innerHtml }} // eslint-disable-line react/no-danger
    {...props}
  />
);

const MessageDisplay: React.FC<{
  innerHtml: string;
  additionalHtml?: string;
  className?: string;
  size?: ThemeSizes;
  title?: string;
  children?: React.ReactNode;
}> = ({
  className,
  innerHtml,
  additionalHtml = '',
  children,
  size = 'medium',
  title,
  ...rest
}) => {
  return (
    <div
      className={className}
      css={(theme: ThemeType) => css`
        border-radius: 4px;
        padding: 17px ${theme.spacing.small} ${theme.spacing.small};
        background-color: #fff;
        white-space: pre-line;
      `}
    >
      {title && (
        <div
          css={(theme: ThemeType) => css`
            ${theme.typography.sizes[size]};
            font-weight: ${theme.typography.weights.bold};
            margin-bottom: 12px;
          `}
        >
          {title}
        </div>
      )}
      <PlainMessageDisplay size={size} innerHtml={innerHtml} {...rest} />
      <PlainMessageDisplay size={size} innerHtml={additionalHtml} {...rest} />
      {children}
    </div>
  );
};

export default MessageDisplay;
