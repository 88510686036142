/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import forOwn from 'lodash/forOwn';
import groupBy from 'lodash/groupBy';
import RouteRangeStopSelector, {
  Directionality,
  RouteRangeStopDisplayGroup,
  useRouteRangeSelectorRouteStopsQuery,
  useStopsByRoute,
} from 'ui-kit/route-range-stop-selector';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message_affectedStations_AffectedStation } from 'generated/GetEventById';
import { RouteIdsByStopId } from 'ui-kit/route-range-stop-selector/route-range-stop-selector';
import { routeGTFSIdsWithDupeNamedStops } from 'ui-kit/route-range-stop-selector/constants';
import {
  RouteRangeSelectorRouteStops,
  RouteRangeSelectorRouteStops_routesConnection_RoutesConnection_routes_Route_stopsConnection_OrderedStopsConnection_stops_OrderedStop,
} from 'generated/RouteRangeSelectorRouteStops';

export const getDefaultAffectedStations = (
  affectedStations?:
    | GetEventById_event_Event_messages_MessagesConnection_nodes_Message_affectedStations_AffectedStation[]
    | null,
): TAffectedStops => {
  if (!affectedStations) {
    return {
      directionality: null,
      gtfsStopIds: affectedStations !== null ? [] : null,
    };
  }

  /*
   * Currently, the same direction is saved for all affected stations for all routes.
   * Grab the first affected station and use that for directionality.
   */
  return {
    directionality:
      affectedStations && affectedStations[0]?.direction
        ? affectedStations[0].direction
        : null,
    gtfsStopIds: affectedStations
      ? affectedStations.map(
          (affectedStation) =>
            affectedStation?.entitySelector?.stopId as string,
        )
      : [],
  };
};

export type TAffectedStops = {
  directionality: Directionality | null;
  gtfsStopIds: string[] | null;
};

const useRouteIdsByStopId = (data: any): RouteIdsByStopId => {
  return useMemo(() => {
    const routeIdsByStopIds: RouteIdsByStopId = {};

    if (!data || !data.routesConnection) {
      return routeIdsByStopIds;
    }

    data.routesConnection.routes.forEach((r: any) => {
      r.stopsConnection.stops.forEach((s: any) => {
        if (routeIdsByStopIds[s.id]) {
          routeIdsByStopIds[s.id].add(r.id);
        } else {
          routeIdsByStopIds[s.id] = new Set<string>().add(r.id);
        }
      });
    });

    return routeIdsByStopIds;
  }, [data]);
};

interface ConnectedRouteRangeStopSelectorGroupProps {
  gtfsRouteIds: string[];
  value: TAffectedStops;
  onChange: (affectedStops: TAffectedStops) => void;
}

const getDupeNamedStopsGTFSIds = (
  stopsToCheckForDupeNames: string[] = [],
  data?: RouteRangeSelectorRouteStops,
): {
  dupeNamedStopsGTFSIdsToAdd: string[];
  dupeNamedStopsGTFSIdsToRemove: string[];
} => {
  const dupeNamedStopsGTFSIdsToAdd: string[] = [];
  const dupeNamedStopsGTFSIdsToRemove: string[] = [];

  const stopsFromRoutesByLabel = groupBy(
    uniqBy(
      data?.routesConnection?.routes.reduce(
        (
          stops: RouteRangeSelectorRouteStops_routesConnection_RoutesConnection_routes_Route_stopsConnection_OrderedStopsConnection_stops_OrderedStop[],
          r,
        ) => {
          if (routeGTFSIdsWithDupeNamedStops.includes(r.gtfsId)) {
            stops.push(...r.stopsConnection.stops);
          }
          return stops;
        },
        [],
      ) ?? [],
      'gtfsStopId',
    ),
    'label',
  );

  forOwn(stopsFromRoutesByLabel, (stops) => {
    const numberOfStopsUsedForLabel = stops.filter((s) =>
      stopsToCheckForDupeNames.includes(s.gtfsStopId),
    ).length;

    if (
      numberOfStopsUsedForLabel === 1 ||
      numberOfStopsUsedForLabel === stops.length
    ) {
      dupeNamedStopsGTFSIdsToAdd.push(...stops.map((s) => s.gtfsStopId));
    } else {
      dupeNamedStopsGTFSIdsToRemove.push(...stops.map((s) => s.gtfsStopId));
    }
  });

  return {
    dupeNamedStopsGTFSIdsToAdd,
    dupeNamedStopsGTFSIdsToRemove,
  };
};

const ConnectedRouteRangeStopSelectorGroup: React.FC<
  ConnectedRouteRangeStopSelectorGroupProps & { children?: React.ReactNode }
> = ({ gtfsRouteIds, value, onChange }) => {
  const isInitialLoad = useRef<boolean>(true);
  const isEditing = value.gtfsStopIds ? !!value.gtfsStopIds.length : false;
  const routesLoaded = useRef<Set<string>>(new Set([]));
  const { data, error } = useRouteRangeSelectorRouteStopsQuery(gtfsRouteIds);
  const stopsByRoute = useStopsByRoute(data);
  const gtfsDefaultStopIds =
    value.gtfsStopIds === null
      ? Object.keys(stopsByRoute).reduce(
          (gtfsStopIds: string[], value: string) => {
            return [
              ...gtfsStopIds,
              ...stopsByRoute[value].map((stop: { gtfsStopId: string }) => {
                return stop.gtfsStopId;
              }),
            ];
          },
          [],
        )
      : null;

  const addStops = useCallback(
    (newStopIds: string[]) => {
      const s = gtfsDefaultStopIds
        ? new Set(gtfsDefaultStopIds)
        : new Set(value.gtfsStopIds);
      newStopIds.forEach((id) => {
        s.add(id);
      });
      const gtfsStopIds = Array.from(s);
      const { dupeNamedStopsGTFSIdsToAdd, dupeNamedStopsGTFSIdsToRemove } =
        getDupeNamedStopsGTFSIds(gtfsStopIds, data);
      onChange({
        ...value,
        gtfsStopIds: uniq([
          ...gtfsStopIds.filter(
            (gtfsId) => !dupeNamedStopsGTFSIdsToRemove.includes(gtfsId),
          ),
          ...dupeNamedStopsGTFSIdsToAdd,
        ]),
      });
    },
    [value, onChange, data, gtfsDefaultStopIds],
  );
  const removeStops = useCallback(
    (stopIdsToRemove: string[]) => {
      const s = new Set(value.gtfsStopIds);
      stopIdsToRemove.forEach((id) => {
        s.delete(id);
      });
      const gtfsStopIds = Array.from(s);
      const { dupeNamedStopsGTFSIdsToAdd, dupeNamedStopsGTFSIdsToRemove } =
        getDupeNamedStopsGTFSIds(gtfsStopIds, data);
      onChange({
        ...value,
        gtfsStopIds: uniq([
          ...gtfsStopIds.filter(
            (gtfsId) => !dupeNamedStopsGTFSIdsToRemove.includes(gtfsId),
          ),
          ...dupeNamedStopsGTFSIdsToAdd,
        ]),
      });
    },
    [value, onChange, data],
  );

  useEffect(() => {
    if (data && data.routesConnection) {
      const currentlyLoadedRoutes = data.routesConnection.routes;
      const currentlyLoadedRouteIds = currentlyLoadedRoutes.map(
        (r: any) => r.id,
      );

      currentlyLoadedRoutes.forEach((route: any) => {
        if (!routesLoaded.current.has(route.id)) {
          // Loaded: ${route.id}
          if (!isInitialLoad.current || !isEditing) {
            addStops(route.stopsConnection.stops.map((s: any) => s.value));
          }
          routesLoaded.current.add(route.id);
        }
      });

      isInitialLoad.current = false;

      let anyRouteRemoved = false;
      routesLoaded.current.forEach((routeId: string) => {
        if (!currentlyLoadedRouteIds.includes(routeId)) {
          // Unloaded: ${routeId}
          anyRouteRemoved = true;
          routesLoaded.current.delete(routeId);
        }
      });

      if (anyRouteRemoved) {
        const validStops = new Set<string>(
          currentlyLoadedRoutes.flatMap((route: any) =>
            route.stopsConnection.stops.flatMap((s: any) => s.value),
          ),
        );
        if (value.gtfsStopIds) {
          const toRemove = new Set<string>(
            value.gtfsStopIds.filter((sid) => !validStops.has(sid)),
          );
          removeStops(Array.from(toRemove));
        }
      }
    }
  }, [data, routesLoaded, addStops, removeStops, value, isEditing]);

  const handleDirectionalityChange = useCallback(
    (directionality) => {
      onChange({ ...value, directionality });
    },
    [value, onChange],
  );
  const routeIdsByStopId = useRouteIdsByStopId(data);
  const handleSelectedStopsChange = useCallback(
    (newStops) => {
      const { dupeNamedStopsGTFSIdsToAdd, dupeNamedStopsGTFSIdsToRemove } =
        getDupeNamedStopsGTFSIds(newStops, data);
      onChange({
        ...value,
        gtfsStopIds: uniq([
          ...newStops.filter(
            (gtfsId: string) => !dupeNamedStopsGTFSIdsToRemove.includes(gtfsId),
          ),
          ...dupeNamedStopsGTFSIdsToAdd,
        ]),
      });
    },
    [value, onChange, data],
  );

  if (error) {
    throw error;
  }

  useEffect(() => {
    const listener = (evt: any) => {
      let direction = 0;

      if (evt.key === 'ArrowLeft') {
        direction = -1;
      } else if (evt.key === 'ArrowRight') {
        direction = 1;
      }

      if (!direction) {
        return;
      }

      const selectors = Array.from(
        document.querySelectorAll('[data-route-range-stop-selector]'),
      );
      const focusedSelector = selectors.find((s) => s.querySelector(':focus'));

      if (!focusedSelector) {
        return;
      }

      const index = selectors.indexOf(focusedSelector);
      const nextIndex = index + direction;

      // The Element type doesn't have a Focus event, but Element nodes
      // definitely have a function called `focus`. So I just re-cast it here,
      // then add a check JUST IN CASE
      const element = selectors[nextIndex]?.querySelector(
        'input[type="checkbox"]',
      ) as { focus: any } | null;

      if (typeof element?.focus === 'function') {
        element.focus();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <React.Fragment>
      <RouteRangeStopDisplayGroup
        css={css`
          margin: 12px 0;
        `}
        gtfsRouteIds={gtfsRouteIds}
        stopsByRoute={stopsByRoute}
        gtfsStopIds={value.gtfsStopIds}
        directionality={value.directionality}
      />
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        `}
      >
        {gtfsRouteIds
          .filter((gtfsRouteId) => stopsByRoute[gtfsRouteId])
          .map((gtfsRouteId) => (
            <RouteRangeStopSelector
              key={gtfsRouteId}
              gtfsRouteId={gtfsRouteId}
              options={stopsByRoute[gtfsRouteId] ?? []}
              routeIdsByStopId={routeIdsByStopId}
              directionality={value.directionality}
              onDirectionalityChange={handleDirectionalityChange}
              selectedStops={value.gtfsStopIds}
              onSelectedStopsChange={handleSelectedStopsChange}
            />
          ))}
      </div>
    </React.Fragment>
  );
};

export default ConnectedRouteRangeStopSelectorGroup;
