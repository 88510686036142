/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';
import { loader } from 'graphql.macro';
import { useFeedId } from 'contexts/FeedId';
import { useParams, useHistory } from 'react-router-dom';
import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import formatDate from 'date-fns/format';

import { useQuery, useMutation } from '@apollo/client';
import { DATE_FORMAT } from 'constants/time';
import { getAssetPreviewScale } from 'utils/assets-helpers';
import * as Well from '../../common/well';
import Loader from '../../common/skeletons/PageWithContent';
import { LockedAssetScreenPreview as SocialMediaAssetPreview } from '../Screen/in-screens-preview/in-screen-preview';

import * as S from './index.styled';

import {
  GetSocialMediaUploadById,
  GetSocialMediaUploadByIdVariables,
} from '../../../generated/GetSocialMediaUploadById';
import {
  DeleteSocialMediaUpload,
  DeleteSocialMediaUploadVariables,
} from '../../../generated/DeleteSocialMediaUpload';

const GetSocialMediaUploadByIdQuery = loader(
  '../../../graphql/GetSocialMediaUploadById.gql',
);

const DeleteSocialMediaUploadMutation = loader(
  '../../../graphql/DeleteSocialMediaUpload.gql',
);

const SocialMediaAssetView: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const feedId = useFeedId();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [deleteSocialMediaUploadMutation, { loading: isDeleteUploadLoading }] =
    useMutation<DeleteSocialMediaUpload, DeleteSocialMediaUploadVariables>(
      DeleteSocialMediaUploadMutation,
      {
        variables: { uploadId: id },
      },
    );

  const { loading, data } = useQuery<
    GetSocialMediaUploadById,
    GetSocialMediaUploadByIdVariables
  >(GetSocialMediaUploadByIdQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const upload = data?.socialMediaUpload;
  const uploadAsset = upload?.upload;
  const folder = upload?.folder;

  const deleteSocialMediaUpload = async () => {
    await deleteSocialMediaUploadMutation();
    history.push(`/${feedId}/assets/social-media`);
  };

  const buttons = (() => {
    return (
      <React.Fragment>
        <S.Button
          primary
          onClick={() => {
            history.push(`/${feedId}/assets/${id}/edit?type=social-media`);
          }}
        >
          Edit
        </S.Button>
        <S.Button
          disabled={isDeleteUploadLoading}
          onClick={async () => {
            if (window.confirm('Are you sure you want to delete this asset?')) {
              deleteSocialMediaUpload();
            }
          }}
        >
          Remove
        </S.Button>
      </React.Fragment>
    );
  })();

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <PageMeta title="View Social Media Asset" />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}/assets/social-media`,
          title: 'Back to social media',
        }}
      />
      <Loader loading={loading}>
        <Well.Container>
          <Well.Section>
            <S.Header>
              <S.Info>
                <S.Title>{uploadAsset?.title ?? 'Asset Upload'}</S.Title>
                <S.EditedLast>
                  {uploadAsset?.updatedAt && (
                    <React.Fragment>
                      <strong>Last Updated:</strong>{' '}
                      {formatDate(
                        new Date(uploadAsset?.updatedAt),
                        DATE_FORMAT,
                      )}
                      {upload?.author && ` by ${upload?.author?.name}`}
                    </React.Fragment>
                  )}
                </S.EditedLast>
              </S.Info>
              <S.Buttons>{buttons}</S.Buttons>
            </S.Header>
          </Well.Section>
          <Well.Section>
            <S.Label>Description</S.Label>
            <S.Description>{uploadAsset?.description}</S.Description>
            {folder && (
              <div
                css={css`
                  margin-top: 20px;
                `}
              >
                <S.Label>Folder</S.Label>
                <S.Description>{folder?.name}</S.Description>
              </div>
            )}
          </Well.Section>
        </Well.Container>
        <Well.Container>
          <Well.Section>
            <S.Header>
              <S.Info>
                <S.Title
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  Upload
                </S.Title>
              </S.Info>
            </S.Header>
          </Well.Section>
          <Well.Section>
            {uploadAsset && (
              <SocialMediaAssetPreview
                key={uploadAsset?.id}
                upload={uploadAsset}
                scale={getAssetPreviewScale(
                  uploadAsset?.width ?? 0,
                  uploadAsset?.height ?? 0,
                )}
                css={css`
                  margin-top: 20px;
                `}
              />
            )}
          </Well.Section>
        </Well.Container>
      </Loader>
    </div>
  );
};

export default SocialMediaAssetView;
