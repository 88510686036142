import { FeedId } from '@mta-live-media-manager/shared';
import { uniq } from 'lodash';
import {
  TargetType,
  ScreenType,
  ScreenOrientation,
} from '../../../../generated/global-types';
import { isRailRoad } from 'utils/feed-switches';

export const COVES_TARGET_TYPES = new Set([
  TargetType.COVES_OF_FIVE,
  TargetType.COVES_OF_TWO,
]);

export const TRAIN_TARGET_TYPES = new Set([
  TargetType.COVES_OF_FIVE,
  TargetType.COVES_OF_TWO,
  TargetType.SQUARE,
  TargetType._3SM,
]);

export const getCoveMultiplier = (targetType?: TargetType | null): number => {
  switch (targetType) {
    case TargetType.COVES_OF_FIVE:
      return 5;
    case TargetType.COVES_OF_TWO:
      return 2;
    default:
      return 1;
  }
};

// A dummy TargetType that will be the grouped option for CIC screens
export const ALL_CIC = 'ALL_CIC_SCREENS' as TargetType;

export const getLabelFromTargetType = (
  targetType: TargetType,
  feedId?: FeedId,
): string =>
  ({
    [TargetType.NON_AD]: 'All Non-Ad Screens',
    [ALL_CIC]: 'Customer Information Screens',
    [TargetType.DUP]: 'Sidewalk',
    [TargetType.SINGLE_MKT_LANDSCAPE]: 'MKT',
    [TargetType.SINGLE_PIO_LANDSCAPE]: isRailRoad(feedId as FeedId)
      ? 'SIO'
      : 'PIO',
    [TargetType.SINGLE_STA_LANDSCAPE]: 'STA',
    [TargetType.DUO_CIC_LEFT]: 'Left CIC of Duo',
    [TargetType.DUO_CIC_RIGHT]: 'Right CIC of Duo',
    [TargetType.SINGLE_AD_LANDSCAPE]: 'Single ADV',
    [TargetType.SINGLE_AD_PORTRAIT]: 'Single ADV',
    [TargetType.SINGLE_CIC]: 'Single CIC',
    [TargetType.SINGLE_CIC_LANDSCAPE]: 'Single CIC',
    [TargetType.SINGLE_INFO_LANDSCAPE]: 'Single Info',
    [TargetType.SINGLE_INFO_PORTRAIT]: 'Single Info',
    [TargetType.TRIPTYCH_AD]: 'Triptych',
    [TargetType.SPECTACULAR_AD]: 'Spectaculars',
    [TargetType.OTHER_MARKETING_LANDSCAPE]: 'Marketing',
    [TargetType.SOLARI_LANDSCAPE]: `${
      feedId === FeedId.MNR ? 'Station Kiosks' : 'Solari'
    }`,
    [TargetType.SOLARI_PORTRAIT]: 'CIC-S',
    [TargetType.SOLARI_MARKETING_LANDSCAPE]: 'Solari Marketing',
    [TargetType.FULTON_PORTRAIT]: 'Fulton',
    [TargetType.COVES_OF_FIVE]: 'Coves of 5',
    [TargetType.COVES_OF_TWO]: 'Coves of 2',
    [TargetType._3SM]: '3SM',
    [TargetType.SQUARE]: 'Square',
  })[targetType];

export const getLabelFromScreenType = (
  screenType: ScreenType,
  feedId: FeedId,
): string =>
  ({
    [ScreenType.AD]: 'AD',
    [ScreenType.CIC]: 'CIC',
    [ScreenType.COVE]: 'COVE',
    [ScreenType.DUP]: 'DUP',
    [ScreenType.MKT]: 'MKT',
    [ScreenType.PIO]: isRailRoad(feedId) ? 'SIO' : 'PIO',
    [ScreenType.STA]: 'STA',
    [ScreenType.FULTON]: 'FULTON',
    [ScreenType.INFO]: 'INFO',
    [ScreenType.MAP]: 'MAP',
    [ScreenType.OTHER_MARKETING]: 'OTHER MARKETING',
    [ScreenType.SQUARE]: 'SQUARE',
    [ScreenType.SOLARI]: 'SOLARI',
    [ScreenType.SOLARI_MARKETING]: 'SOLARI MARKETING',
    [ScreenType.ALL]: 'STA',
    [ScreenType._3SM]: '3SM',
  })[screenType];

const STATION_SCREEN_TARGET_TYPES = [
  TargetType.SINGLE_CIC,
  TargetType.DUO_CIC_LEFT,
  TargetType.DUO_CIC_RIGHT,
  TargetType.SINGLE_AD_PORTRAIT,
  TargetType.SINGLE_AD_LANDSCAPE,
  TargetType.TRIPTYCH_AD,
  TargetType.SINGLE_INFO_PORTRAIT,
  TargetType.SINGLE_INFO_LANDSCAPE,
  TargetType.SINGLE_MKT_LANDSCAPE,
  TargetType.SINGLE_PIO_LANDSCAPE,
  TargetType.SINGLE_STA_LANDSCAPE,
  TargetType.SPECTACULAR_AD,
  TargetType.SOLARI_LANDSCAPE,
  TargetType.SOLARI_PORTRAIT,
  TargetType.OTHER_MARKETING_LANDSCAPE,
];

export const getLabelFromTargetTypes = (
  targetTypes: TargetType[],
  feedId?: FeedId,
): string => {
  const targetTypeLabels: string[] = [];
  const includesAllStationScreens = STATION_SCREEN_TARGET_TYPES.every((s) =>
    targetTypes.includes(s),
  );

  if (includesAllStationScreens) {
    targetTypeLabels.push('All Station Screens');
    targetTypeLabels.push(
      ...targetTypes
        .filter((tt) => !STATION_SCREEN_TARGET_TYPES.includes(tt))
        .map((type) => getLabelFromTargetType(type, feedId)),
    );
  } else {
    targetTypeLabels.push(
      ...targetTypes.map((type) => getLabelFromTargetType(type, feedId)),
    );
  }

  return targetTypeLabels.join(', ');
};

/**
 *
 * @returns An array containing [width, height]
 */
export const getDimensionsFromTargetType = (
  targetType: TargetType,
): [number, number] => {
  switch (targetType) {
    case TargetType.DUO_CIC_LEFT:
    case TargetType.DUO_CIC_RIGHT:
    case TargetType.SINGLE_AD_PORTRAIT:
    case TargetType.SINGLE_CIC:
    case TargetType.SINGLE_INFO_PORTRAIT:
    case TargetType.TRIPTYCH_AD:
    case TargetType._3SM:
    case TargetType.SOLARI_PORTRAIT:
    case ALL_CIC:
      return [1080, 1920];

    case TargetType.SINGLE_MKT_LANDSCAPE:
    case TargetType.SINGLE_PIO_LANDSCAPE:
    case TargetType.SINGLE_STA_LANDSCAPE:
    case TargetType.SINGLE_AD_LANDSCAPE:
    case TargetType.SINGLE_INFO_LANDSCAPE:
    case TargetType.OTHER_MARKETING_LANDSCAPE:
    case TargetType.SOLARI_LANDSCAPE:
    case TargetType.SINGLE_CIC_LANDSCAPE:
    case TargetType.SOLARI_MARKETING_LANDSCAPE:
      return [1920, 1080];

    case TargetType.SPECTACULAR_AD:
      return [1080, 1920];

    case TargetType.FULTON_PORTRAIT:
      return [668, 1536];

    // Only the bottom 20% of DUP screens are usable
    case TargetType.DUP:
      return [1920, 216];

    case TargetType.COVES_OF_FIVE:
    case TargetType.COVES_OF_TWO:
      return [1920, 360];

    case TargetType.SQUARE:
      return [1080, 1080];

    default:
      return [0, 0];
  }
};

export const getOrientationFromTargetType = (
  targetType: TargetType,
): ScreenOrientation => {
  const [width, height] = getDimensionsFromTargetType(targetType);

  return width > height
    ? ScreenOrientation.LANDSCAPE
    : ScreenOrientation.PORTRAIT;
};

export const getDimensionsFromScreenType = (
  orientationType: ScreenOrientation | null,
  screenType: ScreenType | null,
): [number, number] => {
  switch (screenType) {
    case ScreenType.CIC:
    case ScreenType.INFO:
    case ScreenType.AD:
    case ScreenType.MAP:
    case ScreenType.MKT:
    case ScreenType.OTHER_MARKETING:
    case ScreenType.PIO:
    case ScreenType.SOLARI:
    case ScreenType.SOLARI_MARKETING:
    case ScreenType.STA:
      return orientationType === ScreenOrientation.PORTRAIT
        ? [1080, 1920]
        : [1920, 1080];
    case ScreenType.FULTON:
      return [668, 1536];
    case ScreenType.DUP:
      return [1920, 216];
    default:
      return [0, 0];
  }
};

export const getIsCompatibleType = (
  targetType: TargetType,
  selectedTargetTypes?: TargetType[],
): boolean => {
  if (!selectedTargetTypes?.length) {
    return true;
  }

  // Only one type of train screen can be selected at a time
  if (
    TRAIN_TARGET_TYPES.has(targetType) &&
    !selectedTargetTypes.find((t) => t === targetType)
  ) {
    return false;
  }

  if (
    (selectedTargetTypes.includes(TargetType.TRIPTYCH_AD) &&
      targetType !== TargetType.TRIPTYCH_AD) ||
    (selectedTargetTypes.includes(TargetType.SPECTACULAR_AD) &&
      targetType !== TargetType.SPECTACULAR_AD)
  ) {
    return false;
  }

  if (
    (selectedTargetTypes.find((type) => type !== TargetType.TRIPTYCH_AD) &&
      targetType === TargetType.TRIPTYCH_AD) ||
    (selectedTargetTypes.find((type) => type !== TargetType.SPECTACULAR_AD) &&
      targetType === TargetType.SPECTACULAR_AD)
  ) {
    return false;
  }

  const targetTypeDimensions = getDimensionsFromTargetType(targetType);
  return selectedTargetTypes.every((selectedType) => {
    const selectedTypeDimensions = getDimensionsFromTargetType(selectedType);
    return (
      targetTypeDimensions[0] === selectedTypeDimensions[0] &&
      targetTypeDimensions[1] === selectedTypeDimensions[1]
    );
  });
};

export const getHeader = (index: number, count: number): string => {
  if (count === 1) {
    return '';
  }

  if (count > 3) {
    return `${index + 1}/${count}`;
  }

  if (index === 0) {
    return 'Left';
  }

  if (index === count - 1) {
    return 'Right';
  }

  return 'Middle';
};

export const areStopsSequenced = (
  orderedStops: string[],
  orderedTargetedStops: string[],
): boolean => {
  const initialStopIndex = orderedStops.findIndex(
    (s) => s === orderedTargetedStops[0],
  );
  const possibleStopSequence = orderedStops.slice(
    initialStopIndex,
    initialStopIndex + orderedTargetedStops.length,
  );

  if (
    possibleStopSequence.length === orderedTargetedStops.length &&
    possibleStopSequence.every((s, i) => s === orderedTargetedStops[i])
  ) {
    return true;
  }

  return false;
};

export const getScreenSubtitle = ({
  targetTypes,
}: {
  targetTypes: TargetType[];
}) => {
  const layouts: any = uniq(targetTypes);
  return layouts.reduce(
    (prevV: TargetType, currV: TargetType, currI: number) => {
      return `${prevV}${
        currI !== 0 ? `, ${getLabelFromTargetType(currV)}` : ''
      }`;
    },
    getLabelFromTargetType(layouts[0]),
  );
};
