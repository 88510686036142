/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import AppLink from 'components/common/app-link';
import theme from 'theme';
import { useFeedId } from 'contexts/FeedId';
import Pagination from 'components/common/Pagination';
import Accordion from 'components/common/accordion';
import TargetedScreens from 'components/common/asset-form/targeted-screens';
import Loader from '../../common/skeletons/PageWithTable';
import * as Well from '../../common/well';
import { ReactComponent as IconNewtab } from '../../../images/icon_newtab_blue.svg';

import * as S from './index.styled';

import {
  GetSingleScreenCampaignsByLockableUploadId,
  GetSingleScreenCampaignsByLockableUploadIdVariables,
} from '../../../generated/GetSingleScreenCampaignsByLockableUploadId';
import { FeatureFlagName } from 'generated/global-types';
import {
  GetLockedScreensByLockableUploadId,
  GetLockedScreensByLockableUploadIdVariables,
} from 'generated/GetLockedScreensByLockableUploadId';
import {
  GetLockableUploadById_assetHistory_ScheduledAssetsConnection_nodes_ScheduledAsset as AssetHistory,
  GetLockableUploadById_lockableUpload_LockableUpload,
  GetLockableUploadById_lockableUpload_LockableUpload_screens_LockableUploadsScreensConnection_nodes_LockableUploadsScreen as ITargetedScreen,
} from 'generated/GetLockableUploadById';
import { Thumbnail } from 'ui-kit/media-library';
import { format } from 'date-fns';
import { uniqueId } from 'lodash';

const GetLockedScreensByLockableUploadIdQuery = loader(
  '../../../graphql/GetLockedScreensByLockableUploadId.gql',
);
const GetSingleScreenCampaignsByLockableUploadIdQuery = loader(
  '../../../graphql/GetSingleScreenCampaignsByLockableUploadId.gql',
);

const PAGE_SIZE = 48;

const ScreenLinkItem: React.FC<{
  label: string;
  href: string;
  shouldOpenNewTab?: boolean;
  children?: React.ReactNode;
}> = ({ label, href, shouldOpenNewTab = false }) => (
  <li
    css={css`
      font-size: 16px;
      line-height: 18px;
    `}
  >
    <AppLink
      to={href}
      target={shouldOpenNewTab ? '_blank' : undefined}
      css={css`
        color: ${theme.colors.accent1};
        &:hover {
          opacity: 0.7;
        }
      `}
    >
      <span
        css={css`
          text-decoration: underline;
        `}
      >
        {label}
      </span>{' '}
      {shouldOpenNewTab && (
        <IconNewtab
          css={css`
            margin-left: 8px;
            transform: scale(1.45);
          `}
        />
      )}
    </AppLink>
  </li>
);

const NoScreensAvailable: React.FC<{ message: string }> = ({ message }) => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      margin-bottom: 24px;
    `}
  >
    {message}
  </div>
);

const LockableAssetManagement: React.FC<
  {
    lockableUpload?: GetLockableUploadById_lockableUpload_LockableUpload | null;
    assetHistory?: AssetHistory[];
    targetedScreens?: ITargetedScreen[];
    lockableUploadId?: string;
  } & { children?: React.ReactNode }
> = ({ assetHistory = [], targetedScreens, lockableUploadId }) => {
  const feedId = useFeedId();
  const [lockedScreensPage, setLockedScreensPage] = useState(1);
  const [singleScreenCampaignsPage, setSingleScreenCampaignsPage] = useState(1);
  const singleScreenCampaignsEnabled = useFeatureFlag(
    FeatureFlagName.ENABLE_SINGLE_SCREEN_CAMPAIGNS,
  );

  const { data: lockedScreensData, loading: lockedScreensLoading } = useQuery<
    GetLockedScreensByLockableUploadId,
    GetLockedScreensByLockableUploadIdVariables
  >(GetLockedScreensByLockableUploadIdQuery, {
    variables: {
      feedId,
      lockableUploadId,
      perPage: PAGE_SIZE,
      offset: (lockedScreensPage - 1) * PAGE_SIZE,
    },
    skip: !lockableUploadId,
    fetchPolicy: 'cache-and-network',
  });

  const lockedScreens =
    lockedScreensData?.lockableUploadLockedScreens?.nodes ?? [];
  const lockedSreensTotalCount =
    lockedScreensData?.lockableUploadLockedScreens?.totalCount ?? 0;
  const lockedScreensCount = lockedScreens.length;

  const {
    data: singleScreenCampaignsData,
    loading: singleScreenCampaignsLoading,
  } = useQuery<
    GetSingleScreenCampaignsByLockableUploadId,
    GetSingleScreenCampaignsByLockableUploadIdVariables
  >(GetSingleScreenCampaignsByLockableUploadIdQuery, {
    variables: {
      feedId,
      lockableUploadId,
      perPage: PAGE_SIZE,
      offset: (singleScreenCampaignsPage - 1) * PAGE_SIZE,
    },
    skip: !singleScreenCampaignsEnabled && !lockableUploadId,
    fetchPolicy: 'cache-and-network',
  });

  const singleScreenCampaigns =
    singleScreenCampaignsData?.lockableUploadSingleScreenCampaigns?.nodes ?? [];
  const singleScreenCampaignsTotalCount =
    singleScreenCampaignsData?.lockableUploadSingleScreenCampaigns
      ?.totalCount ?? 0;
  const singleScreenCampaignsCount = singleScreenCampaigns.length;

  return (
    <React.Fragment>
      {!!targetedScreens?.length && (
        <Well.Section
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <S.AccordionLabel
            css={css`
              display: inline-block;
              margin-bottom: 9px;
            `}
          >
            Screens
          </S.AccordionLabel>
          {targetedScreens.map(
            (screen) =>
              screen.type &&
              screen.totalCount && (
                <TargetedScreens
                  key={screen.type}
                  type={screen.type}
                  totalCount={screen.totalCount}
                  hideScreenTypeHeader
                />
              ),
          )}
        </Well.Section>
      )}
      <Well.Section>
        <Accordion
          label={<S.AccordionLabel>Locked Screens</S.AccordionLabel>}
          isInitiallyOpen={false}
        >
          <Loader loading={lockedScreensLoading}>
            {lockedScreensCount ? (
              <React.Fragment>
                <S.ScreenItemsContainer listItemsCount={lockedScreensCount}>
                  {lockedScreens.map(({ screen: { id, name, deployType } }) => {
                    const screenKey = `screen-${id}`;
                    return (
                      <ScreenLinkItem
                        key={screenKey}
                        label={name as string}
                        href={`/${feedId}/screens/${id}?t=${deployType?.toLowerCase()}`}
                      />
                    );
                  })}
                </S.ScreenItemsContainer>
                <Pagination
                  currentPage={lockedScreensPage}
                  itemsPerPage={PAGE_SIZE}
                  totalItems={lockedSreensTotalCount}
                  onNextClick={() =>
                    setLockedScreensPage(lockedScreensPage + 1)
                  }
                  onPrevClick={() =>
                    setLockedScreensPage(lockedScreensPage - 1)
                  }
                />
              </React.Fragment>
            ) : (
              <NoScreensAvailable message="No screens are locked to this asset." />
            )}
          </Loader>
        </Accordion>
      </Well.Section>

      {singleScreenCampaignsEnabled && (
        <Well.Section>
          <Accordion
            label={<S.AccordionLabel>Single Screen Campaigns</S.AccordionLabel>}
            isInitiallyOpen={false}
          >
            <Loader loading={singleScreenCampaignsLoading}>
              {singleScreenCampaignsCount ? (
                <React.Fragment>
                  <S.ScreenItemsContainer
                    listItemsCount={singleScreenCampaignsCount}
                  >
                    {singleScreenCampaigns.map(
                      ({ id, campaignScreenContents }) => {
                        const screenName = campaignScreenContents?.nodes?.[0]
                          ?.screenName as string;
                        return (
                          <ScreenLinkItem
                            key={id}
                            label={screenName}
                            href={`/${feedId}/campaigns/${id}`}
                            shouldOpenNewTab
                          />
                        );
                      },
                    )}
                  </S.ScreenItemsContainer>
                  <Pagination
                    currentPage={singleScreenCampaignsPage}
                    itemsPerPage={PAGE_SIZE}
                    totalItems={singleScreenCampaignsTotalCount}
                    onNextClick={() =>
                      setSingleScreenCampaignsPage(
                        singleScreenCampaignsPage + 1,
                      )
                    }
                    onPrevClick={() =>
                      setSingleScreenCampaignsPage(
                        singleScreenCampaignsPage - 1,
                      )
                    }
                  />
                </React.Fragment>
              ) : (
                <NoScreensAvailable message="No single screen campaigns are using this asset." />
              )}
            </Loader>
          </Accordion>
        </Well.Section>
      )}
      <Well.Section>
        <Accordion
          isInitiallyOpen={false}
          label={<S.AccordionLabel>Asset History</S.AccordionLabel>}
        >
          <S.ScreenItemsContainer
            css={css`
              grid-template-columns: 1fr;
            `}
            listItemsCount={assetHistory.length}
          >
            {assetHistory?.map((asset) => {
              return (
                <li key={uniqueId(asset.scheduledUpload?.title ?? '')}>
                  <Thumbnail
                    height="96px"
                    css={css`
                      width: 100%;
                      figure {
                        display: grid;
                        grid-auto-flow: column;
                        grid-column-gap: 1em;
                        grid-template-columns: min-content 50% repeat(2, 1fr);
                        text-align: left;
                        grid-template-rows: repeat(3, 1fr);

                        div:first-of-type {
                          grid-row-start: span 3;
                        }

                        .attr span {
                          font-weight: 700;
                        }

                        h5 {
                          margin-top: 0;
                        }

                        .end {
                          grid-area: 2;
                        }
                      }
                    `}
                    width="96px"
                    upload={asset.scheduledUpload as any}
                    thumbnailSrc={asset.scheduledUpload?.signedS3Url}
                  >
                    <>
                      <div className="attr">
                        <span>Title: </span>
                        {asset.scheduledUpload?.title}
                      </div>
                      <div className="attr">
                        <span>Description: </span>
                        {asset.scheduledUpload?.description}
                      </div>
                      <div className="attr">
                        <span>File Name: </span>
                        {asset.scheduledUpload?.originalFilename}
                      </div>
                      <h5 className="attr">Duration</h5>
                      <div className="start">
                        {format(new Date(asset.startAt), 'MM/dd/yyyy')} at{' '}
                        {format(new Date(asset.startAt), 'HH:mm')}
                      </div>
                      <div className="end">
                        {format(new Date(asset.endAt), 'MM/dd/yyyy')} at{' '}
                        {format(new Date(asset.endAt), 'HH:mm')}
                      </div>
                    </>
                  </Thumbnail>
                </li>
              );
            })}
          </S.ScreenItemsContainer>
        </Accordion>
      </Well.Section>
    </React.Fragment>
  );
};

export default LockableAssetManagement;
