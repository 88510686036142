import { RouteMention, StopMention, TagsSelection } from 'types';
import {
  TargetType,
  MentionType,
  ContextualSelectorInput,
} from 'generated/global-types';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as RoutesByFeed_routes_nodes } from 'generated/RoutesByFeed';

import { IScreenSelector } from './index';

export const getScreenSelectorsFromContextualSelectors = (
  selectorsWithTargetTypes: {
    contextualSelectors: ContextualSelectorInput[];
    targetTypes: TargetType[];
    tags?: TagsSelection;
  }[],
  routes: RoutesByFeed_routes_nodes[],
): IScreenSelector[] => {
  const screenSelectors = selectorsWithTargetTypes.flatMap(
    ({ contextualSelectors: selectors, targetTypes, tags }) => {
      // Entity selectors that target an entire route will be consolidated into one selector.
      const wholeRoutesSelector: IScreenSelector = {
        targetTypes,
        selector: {
          routes: selectors
            .filter((s) => s.entitySelector.routeId)
            .map((s) => {
              const newRoute = routes.find(
                (route) => route.gtfsId === s.entitySelector.routeId,
              );

              if (!newRoute) {
                return undefined;
              }

              const newRouteMention: RouteMention = {
                id: newRoute.gtfsId,
                name:
                  newRoute.shortName || newRoute.longName || newRoute.gtfsId,
                routeId: newRoute.gtfsId,
                isAffected: false,
                meta: null,
                boroughs: null,
                relatedRouteIds: null,
                type: MentionType.ROUTE,
              };

              return newRouteMention;
            })
            .filter(Boolean) as RouteMention[],
          stops: [],
        },
        tags: undefined,
      };

      const stopMentionsByRoute: { [key: string]: StopMention[] } = {};
      selectors
        .filter((s) => s.entitySelector.stopId && s.context.gtfsRouteId)
        .forEach((s) => {
          if (!s.context.gtfsRouteId) {
            return;
          }

          if (!stopMentionsByRoute[s.context.gtfsRouteId]) {
            stopMentionsByRoute[s.context.gtfsRouteId] = [];
          }

          /*
        The StopSelector will transform these largely empty stop mentions
        using the data it fetches for targeted stops.
      */
          const stopId = s.entitySelector.stopId || '';
          const newStopMention: StopMention = {
            stopId,
            isAffected: false,
            id: stopId,
            name: stopId,
            boroughs: null,
            relatedRouteIds: null,
            meta: null,
            type: MentionType.STOP,
          };

          stopMentionsByRoute[s.context.gtfsRouteId] = [
            ...stopMentionsByRoute[s.context.gtfsRouteId],
            newStopMention,
          ];
        });

      const stopSelectors = Object.keys(stopMentionsByRoute).map((key) => {
        const newRoute = routes.find(
          (route) => route.gtfsId === key,
        ) as RoutesByFeed_routes_nodes;

        const newRouteMention: RouteMention = {
          id: newRoute.gtfsId,
          name: newRoute.shortName || newRoute.longName || newRoute.gtfsId,
          routeId: newRoute.gtfsId,
          isAffected: false,
          meta: null,
          boroughs: null,
          relatedRouteIds: null,
          type: MentionType.ROUTE,
        };

        const newStopMentions = stopMentionsByRoute[key];

        return {
          targetTypes,
          selector: { routes: [newRouteMention], stops: newStopMentions },
          tags,
        };
      });

      return wholeRoutesSelector.selector.routes.length
        ? [wholeRoutesSelector, ...stopSelectors]
        : stopSelectors;
    },
  );

  return screenSelectors;
};
