import { useEffect } from 'react';
import { icons as subwayIcons, customIcons } from './global';
import { isSubway } from '../utils/feed-switches';
import { useFeedId } from '../contexts/FeedId';

const PreloadEditorIcons: React.FC<{}> = () => {
  const feedId = useFeedId();

  useEffect(() => {
    if (feedId) {
      // Preload custom icons.
      Object.keys(customIcons).forEach((key) => {
        const preloadedCustomIcons = new Image();
        preloadedCustomIcons.src = customIcons[key];
        preloadedCustomIcons.alt = key;
      });

      // Preload Subway icons.
      if (isSubway(feedId)) {
        Object.keys(subwayIcons).forEach((key) => {
          const preloadedSubwayIcons = new Image();
          preloadedSubwayIcons.src = subwayIcons[key];
          preloadedSubwayIcons.alt = key;
        });
      }
    }
  }, [feedId]);

  return null;
};

export default PreloadEditorIcons;
