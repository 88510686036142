/** @jsxImportSource @emotion/react */

import React from 'react';
import Theme from 'theme';
import { css } from '@emotion/react';

interface IEditorLabelProps {
  id?: string;
  labelText?: string;
  maxLength?: {
    max: number;
    current: number;
  };
}

const EditorLabel: React.FC<IEditorLabelProps> = ({
  id,
  labelText,
  maxLength,
}: IEditorLabelProps) => {
  const remaining =
    maxLength && maxLength.max > 0
      ? maxLength.max - maxLength.current
      : undefined;
  const isRemainingInvalid = remaining ? remaining < 0 : false;

  const characterText =
    typeof remaining === 'number' && Math.abs(remaining) === 1
      ? 'character'
      : 'characters';
  const remainingText = `${Math.abs(remaining || 0)} ${characterText}${
    isRemainingInvalid ? ' over' : ''
  }`;

  const showHeading = labelText || (maxLength && remainingText);

  return showHeading ? (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
      `}
    >
      <label
        htmlFor={id}
        css={css`
          ${Theme.typography.sizes.medium};
          color: ${Theme.colors.black};
          font-weight: ${Theme.typography.weights.bold};
          margin-right: 12px;
        `}
      >
        {labelText}
      </label>
      {maxLength && (
        <label
          htmlFor={id}
          css={css`
            ${Theme.typography.sizes.xsmall};
            color: ${isRemainingInvalid
              ? Theme.colors['status-error']
              : Theme.colors.black};
          `}
        >
          {remainingText}
        </label>
      )}
    </div>
  ) : null;
};

export default EditorLabel;
