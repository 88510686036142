import { useRoutesByFeedId } from '../contexts/Routes';
import { FeedId } from '../contexts/FeedId';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as Route } from '../generated/RoutesByFeed';
import { isBus } from '../utils/feed-switches';

export type Bullet = {
  gtfsId: string;
  name: string;
  textColor: string;
  color: string;
};

export const useBulletGenerator = (
  feedId: FeedId,
): ((routeIds: Array<string>) => Array<Bullet>) => {
  const routes = useRoutesByFeedId(feedId);
  return (routeIds: Array<string>) => {
    return routeIds.map((id) => {
      const route = routes.find((r: Route) => r.gtfsId === id);
      const color = isBus(feedId)
        ? 'CCCCCC'
        : (route && route.color) || 'FFFFFF';
      const name = (route && (route.shortName || route.longName)) || '';
      const textColor =
        (isBus(feedId) ? '001840' : route && route.textColor) || 'FFFFFF';
      return { name, color, textColor, gtfsId: id };
    });
  };
};
