/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import PageHeading from 'components/scaffolding/PageHeading';
import useLiveContentQuery from 'hooks/use-live-content-query';
import { useFeedId } from 'contexts/FeedId';
import { TLiveContentTableSortingTypes } from 'constants/live-content';
import * as commonStyles from '../styles';
import PageMeta from '../PageMeta';
import LiveContentTable from './live-content-table';
import LiveContentFilters from './live-content-filters';
import Loader from '../Loader';
import NoMessages from '../NoMessages';

const LiveContent: React.FC<{
  pageTitle: string;
  children?: React.ReactNode;
}> = ({ pageTitle }) => {
  const feedId = useFeedId();

  const {
    query: { loading, data },
    setPage,
    setOrderBy,
    setDirection,
    pageNum,
    perPage,
    orderBy,
    direction,
    hasFilter,
  } = useLiveContentQuery();

  const liveContents = data?.filteredLiveContent?.nodes || [];
  const totalCount = data?.filteredLiveContent?.totalCount ?? 0;

  return (
    <section css={commonStyles.container}>
      <PageMeta title={pageTitle} />
      <PageHeading breadcrumbs={['Screens']} title={pageTitle} />
      <div css={commonStyles.pageContent}>
        <div
          css={[
            commonStyles.flexCol,
            css`
              width: 100%;
            `,
          ]}
        >
          <LiveContentFilters feedId={feedId} disabled={loading} />
          <Loader loading={loading && !liveContents.length}>
            {liveContents.length ? (
              <LiveContentTable
                onNextClick={() => setPage(`${pageNum + 1}`)}
                onPrevClick={() => setPage(`${pageNum - 1}`)}
                onSortClick={({ orderBy: newOrderBy, isDesc: newIsDesc }) => {
                  setOrderBy(newOrderBy);
                  setDirection(newIsDesc ? 'desc' : 'asc');
                  setPage('1');
                }}
                feedId={feedId}
                page={pageNum}
                perPage={perPage}
                orderBy={orderBy as TLiveContentTableSortingTypes}
                isDesc={direction === 'desc'}
                liveContents={liveContents}
                totalCount={totalCount}
              />
            ) : (
              <div
                css={css`
                  flex: 1 1 100%;
                  display: flex;
                `}
              >
                {hasFilter ? (
                  <NoMessages
                    heading="No Live Content"
                    message="No live content match provided filters."
                  />
                ) : (
                  <NoMessages
                    heading="All Clear!"
                    message="There is no active Live Content."
                  />
                )}
              </div>
            )}
          </Loader>
        </div>
      </div>
    </section>
  );
};

export default LiveContent;
