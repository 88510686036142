import {
  ADDED_TAG_VALUE,
  DEFAULT_TAG_VALUE,
  getFeedTagOptions,
  TagNames,
  TagNamesToSeparatePrefix,
} from 'constants/screen-tags';
import {
  getTagLabelFromValue,
  getTagNamesToSeparate,
  tagValueAndCriteriaFactory,
} from 'utils/screen-tags-helpers';

const EXECLUDED_TAG_CATEROGIES: string[] = [TagNames.ADA];

export const ALL_OPTIONS_PREFIX = 'all-options-';

export type TargetingOption = {
  label: string;
  value: string;
  tagName: string;
};

// Tag names that we don't have full info about so we target those
// tags with the input value + the default/unspecified value("unknown")
export const TagNamesShouldTargetedByDefault: string[] = [
  TagNames.Direction,
  TagNames.StationPlacement,
];

const ALL_TAG_NAME_OPTIONS_LABEL: { [key: string]: string } = {
  [TagNames.Direction]: 'Both Directions',
  [TagNames.StationPlacement]: 'All Station Placements',
  [TagNames.IndoorOutdoor]: 'Both Indoor and Outdoor',
  [TagNames.Language]: 'All Languages',
};

const ORDERED_TAG_NAMES: string[] = [
  TagNames.Direction,
  TagNames.StationPlacement,
  TagNames.IndoorOutdoor,
  TagNames.Language,
];

export const getGroupedOptions = (feedId: string) =>
  Object.values(getFeedTagOptions(feedId) ?? {})
    .filter((o) => !EXECLUDED_TAG_CATEROGIES.includes(o.tagName))
    .sort(
      (a, b) =>
        ORDERED_TAG_NAMES.indexOf(a.tagName) -
        ORDERED_TAG_NAMES.indexOf(b.tagName),
    )
    .map((to) => ({
      ...to,
      options: [
        {
          label: ALL_TAG_NAME_OPTIONS_LABEL[to.tagName],
          value: `${ALL_OPTIONS_PREFIX}${to.tagName}`,
        },
        ...to.options.map((o) => ({
          label: o.label,
          value: o.value,
          tagName: to.tagName,
        })),
      ],
    }));

export const getTargetingCriteriaFromOptions = (
  options: TargetingOption[],
  feedId?: string,
):
  | {
      [key: string]: string[];
    }
  | undefined => {
  if (!options.length) {
    return undefined;
  }

  const areAllOptionsSelected =
    feedId &&
    options.length ===
      getGroupedOptions(feedId).flatMap(({ options }) => options).length;

  if (areAllOptionsSelected) {
    return {};
  }

  const targetingCriteria: { [key: string]: string[] } = {};
  const tagNamesToSeparate = getTagNamesToSeparate();

  options.forEach(({ tagName, value }) => {
    if (!tagName) {
      return;
    }

    const shouldTargetUnspecified =
      TagNamesShouldTargetedByDefault.includes(tagName);

    if (tagNamesToSeparate.includes(tagName)) {
      const tagValueWithCriteria = tagValueAndCriteriaFactory({
        tagValue: value,
      });

      if (!tagValueWithCriteria) {
        return;
      }

      const { criteria } = tagValueWithCriteria;

      targetingCriteria[criteria] = [
        ...(targetingCriteria[criteria] ?? []),
        ADDED_TAG_VALUE,
      ];

      if (
        shouldTargetUnspecified &&
        !targetingCriteria[criteria]?.includes(DEFAULT_TAG_VALUE)
      ) {
        targetingCriteria[criteria].push(DEFAULT_TAG_VALUE);
      }

      return;
    }

    targetingCriteria[tagName] = [...(targetingCriteria[tagName] ?? []), value];

    if (
      shouldTargetUnspecified &&
      !targetingCriteria[tagName]?.includes(DEFAULT_TAG_VALUE)
    ) {
      targetingCriteria[tagName].push(DEFAULT_TAG_VALUE);
    }
  });

  return targetingCriteria;
};

export const getSelectedTagsFromTargetingCriteria = (
  targetingCriteria:
    | {
        [key: string]: string[];
      }
    | undefined,
  feedId: string,
) => {
  if (!targetingCriteria) {
    return [];
  }

  if (!Object.keys(targetingCriteria).length) {
    return (
      getGroupedOptions(feedId).flatMap(
        ({ options }) => options,
      ) as TargetingOption[]
    ).filter((o) => !!o?.tagName);
  }

  const options: TargetingOption[] = [];

  Object.entries(targetingCriteria ?? {}).forEach(([key, values]) => {
    const criteria = key as string;

    const tagValueWithCriteria = tagValueAndCriteriaFactory({
      criteria,
    });
    const isSeparatedCriteria = !!tagValueWithCriteria;

    if (isSeparatedCriteria) {
      const tagValue = tagValueWithCriteria?.tagValue as string;
      const tagName = getTagNamesToSeparate().find((c) =>
        criteria?.startsWith(TagNamesToSeparatePrefix[c]),
      );

      if (!tagName) {
        return;
      }

      options.push({
        label: getTagLabelFromValue(tagValue),
        value: tagValue,
        tagName,
      });

      return;
    }

    const tagName = key as string;
    options.push(
      ...values
        .filter((v) => v !== DEFAULT_TAG_VALUE)
        .map((value) => ({
          label: getTagLabelFromValue(value),
          value,
          tagName,
        })),
    );
  });

  return options;
};
