import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { loader } from 'graphql.macro';
import useQueryParams, { useQueryParam } from '../../../hooks/useQueryParams';

import * as S from './index.styled';

import { Users as UsersType, UsersVariables } from '../../../generated/Users';
import { FeedGroups as FeedGroupsType } from '../../../generated/FeedGroups';
import { UsersOrderBy } from '../../../generated/global-types';

import PageMeta from '../../common/PageMeta';
import PageHeading from '../../scaffolding/PageHeading';
import Loader from '../../common/Loader';

import { CurrentUser_currentUser_User as CurrentUser_currentUser } from '../../../generated/CurrentUser';
import UserTable from './UserTable';

const USERS_PER_PAGE = 50;

const UsersQuery = loader('../../../graphql/Users.gql');
const FeedGroupsQuery = loader('../../../graphql/FeedGroups.gql');

const paramsToSort = function paramsToSort(
  sort: string | null,
  dir: string | null,
): UsersOrderBy {
  if (sort === 'name') {
    return dir === 'desc' ? UsersOrderBy.NAME_DESC : UsersOrderBy.NAME_ASC;
  }
  if (sort === 'lastseen') {
    return dir === 'desc'
      ? UsersOrderBy.LAST_SEEN_AT_DESC
      : UsersOrderBy.LAST_SEEN_AT_ASC;
  }
  if (sort === 'isadmin') {
    return dir === 'desc'
      ? UsersOrderBy.IS_ADMIN_DESC
      : UsersOrderBy.IS_ADMIN_ASC;
  }
  if (sort && sort.length > 0) {
    return UsersOrderBy.NATURAL;
  }
  return UsersOrderBy.NAME_ASC;
};

const Users: React.FC<
  {
    currentUser?: CurrentUser_currentUser | null;
  } & { children?: React.ReactNode }
> = ({ currentUser }) => {
  const history = useHistory();
  const query = useQueryParams();
  const [page, setPage] = useQueryParam('page', '1');
  const sortParam = query.get('sort');
  const dirParam = query.get('dir');
  const sort = paramsToSort(sortParam, dirParam);
  let sortByFeed;
  let sortAsc;
  if (sort === UsersOrderBy.NATURAL && sortParam) {
    sortByFeed = sortParam;
    sortAsc = dirParam === 'asc';
  }

  // Page number param to integer
  const pageNumber = page ? parseInt(page, 10) : 1;
  const isFirstPage = pageNumber === 1;

  const offset = isFirstPage ? 0 : (pageNumber - 1) * USERS_PER_PAGE - 1;
  const perPage = isFirstPage ? USERS_PER_PAGE - 1 : USERS_PER_PAGE;

  // TODO: handle errors
  const { data: usersData, loading: usersLoading } = useQuery<
    UsersType,
    UsersVariables
  >(UsersQuery, {
    pollInterval: 5000,
    variables: {
      offset,
      perPage,
      orderBy: [sort],
      sortByFeed,
      sortAsc,
      selfId: currentUser?.id,
    },
  });

  const { data: feedGroupsData, loading: feedGroupsLoading } =
    useQuery<FeedGroupsType>(FeedGroupsQuery);

  const onSortChange = (
    sortBy?: UsersOrderBy,
    feed?: string,
    order?: string,
  ): void => {
    let search: string = '';
    if (feed) {
      if (sortParam !== feed) {
        search = `?sort=${feed}&dir=asc`;
      } else {
        search = `?sort=${feed}&dir=${order}`;
      }
    } else {
      switch (sortBy) {
        case UsersOrderBy.NAME_ASC:
          search = '?sort=name&dir=asc';
          break;
        case UsersOrderBy.NAME_DESC:
          search = '?sort=name&dir=desc';
          break;
        case UsersOrderBy.LAST_SEEN_AT_ASC:
          search = '?sort=lastseen&dir=asc';
          break;
        case UsersOrderBy.LAST_SEEN_AT_DESC:
          search = '?sort=lastseen&dir=desc';
          break;
        case UsersOrderBy.IS_ADMIN_ASC:
          search = '?sort=isadmin&dir=asc';
          break;
        case UsersOrderBy.IS_ADMIN_DESC:
          search = '?sort=isadmin&dir=desc';
          break;
        default:
          search = '';
      }
    }
    history.push({
      search,
    });
  };

  const count = usersData?.sortedUsers?.totalCount || 0;
  const feedGroups =
    feedGroupsData?.feedGroups?.nodes.map((group) => group.name) || [];
  const nodes = usersData?.sortedUsers?.nodes || [];
  const totalCount = usersData?.sortedUsers?.totalCount || 0;
  const users = isFirstPage && currentUser ? [currentUser, ...nodes] : nodes;

  return (
    <S.Container>
      <PageMeta title="User Management" />
      <PageHeading
        loading={usersLoading && feedGroupsLoading}
        title="User Management"
        breadcrumbs={['Settings']}
      />
      <S.PageContent>
        <Loader loading={(feedGroupsLoading || usersLoading) && count <= 0}>
          <UserTable
            onNextClick={() => setPage(`${pageNumber + 1}`)}
            onPrevClick={() => setPage(`${pageNumber - 1}`)}
            onSortClick={(
              sortBy?: UsersOrderBy,
              feed?: string,
              order?: string,
            ) => {
              onSortChange(sortBy, feed, order);
            }}
            sort={sort}
            sortParam={sortParam}
            dirParam={dirParam}
            feedGroups={feedGroups}
            page={pageNumber}
            perPage={USERS_PER_PAGE}
            totalCount={totalCount + 1}
            users={users}
            currentUser={currentUser}
          />
        </Loader>
      </S.PageContent>
    </S.Container>
  );
};

export default Users;
