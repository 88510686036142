/** @jsxImportSource @emotion/react */

import React from 'react';
import { isFeatureEnabled } from 'hooks/useFeatureFlag';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { useFeedId } from 'contexts/FeedId';
import { useCurrentUser } from 'contexts/CurrentUser';
import { FeedId } from 'types/feeds';
import { ScreenPreviewDisplayTypes } from 'utils/screen-preview';

import {
  CampaignStatus,
  PlannedWorkStatus,
  SchedulePriority,
  ScreenOrientation as DBScreenOrientation,
  TargetType,
  FeatureFlagName,
} from '../../../generated/global-types';
import {
  Screen_screen2_Screens2_alerts_ScreenMessagesConnection_nodes_ScreenMessage,
  Screen_screen2_Screens2_plannedWorks_PlannedWorksConnection_nodes_PlannedWork_plannedWorkScreenMessages_PlannedWorkScreenMessagesConnection_nodes_PlannedWorkScreenMessage as PWSCM,
  Screen_screen2_Screens2_plannedWorks_PlannedWorksConnection_nodes_PlannedWork as Screen_screen2_plannedWorks_nodes_plannedWorkScreenMessages_nodes,
  Screen_screen2_Screens2 as Screens2,
} from '../../../generated/Screen';
import { SyntheticScreen_syntheticScreen_SyntheticScreen as SyntheticScreen_syntheticScreen } from '../../../generated/SyntheticScreen';
import { CampaignRow } from './CampaignsOnScreen';
import InScreenHeading from './InScreenHeading';
import { PlannedWorkRow } from './PlannedWorksOnScreen';
import AlertRow from './AlertRow';

enum ScreenContentType {
  alert = 'alert',
  planned = 'planned',
  campaign = 'campaign',
}

// This creates a merged enum
const ContentStatus = { ...CampaignStatus, ...PlannedWorkStatus };
// This creates a named type
type ContentStatusEnum = CampaignStatus | PlannedWorkStatus;

type ScreenContentContainer = {
  type: ScreenContentType;
  priority: SchedulePriority;
  weight: number;
  status: ContentStatusEnum;
  screenContent: any;
};

const ScreenContentRow: React.FC<{
  screenContentContainer: ScreenContentContainer;
  screenTargetType: TargetType;
  weightingEnabled: boolean;
  totalWeight: number;
  screenOrientation: DBScreenOrientation;
  setIndex?: number;
}> = ({
  screenContentContainer,
  weightingEnabled,
  totalWeight,
  setIndex = 0,
  screenOrientation,
  screenTargetType,
}) => {
  const timeOnScreenPercentage =
    totalWeight > 0 ? (screenContentContainer.weight / totalWeight) * 100 : 0;
  const screenPreviewOrientation =
    screenOrientation === DBScreenOrientation.PORTRAIT
      ? ScreenPreviewDisplayTypes.PORTRAIT
      : ScreenPreviewDisplayTypes.LANDSCAPE;

  if (screenContentContainer.type === ScreenContentType.alert) {
    const alert =
      screenContentContainer.screenContent as Screen_screen2_Screens2_alerts_ScreenMessagesConnection_nodes_ScreenMessage;
    if (!alert.message) {
      return null;
    }
    return (
      <AlertRow
        key={`alert-${alert.message.eventId}`}
        alert={alert}
        weightingEnabled={weightingEnabled}
        timeOnScreenPercentage={timeOnScreenPercentage}
        screenOrientation={screenPreviewOrientation}
        screenTargetType={screenTargetType}
      />
    );
  }

  if (screenContentContainer.type === ScreenContentType.planned) {
    const screenContent = screenContentContainer.screenContent as {
      plannedWork: Screen_screen2_plannedWorks_nodes_plannedWorkScreenMessages_nodes;
      screenMessage: PWSCM;
    };
    return (
      <PlannedWorkRow
        key={`planned-work-${screenContent.plannedWork.id}-${screenContent.screenMessage.id}`}
        plannedWork={screenContent.plannedWork}
        screenMessage={screenContent.screenMessage}
        screenOrientation={screenPreviewOrientation}
        includeScreenContentType
        viewContentLabel="View Details"
        weightingEnabled={weightingEnabled}
        timeOnScreenPercentage={timeOnScreenPercentage}
      />
    );
  }

  if (screenContentContainer.type === ScreenContentType.campaign) {
    const campaignScreenContent = screenContentContainer.screenContent;
    return (
      <CampaignRow
        key={`campaign-${campaignScreenContent.campaign.id}`}
        campaignScreenContent={campaignScreenContent}
        highlightScreen={setIndex}
        includeScreenContentType
        viewContentLabel="View Details"
        weightingEnabled={weightingEnabled}
        timeOnScreenPercentage={timeOnScreenPercentage}
      />
    );
  }

  return null;
};

const getOrderedContent = (screenContentContainers: ScreenContentContainer[]) =>
  orderBy(screenContentContainers, ['weight'], ['desc']);

export const getSetIndex = (s: Screens2) => parseInt(s.criteria.set_index, 10);

const OrderedScreenContent: React.FC<{
  screenWithContent: SyntheticScreen_syntheticScreen | Screens2;
}> = ({ screenWithContent }) => {
  const feedId = useFeedId();
  const currentUser = useCurrentUser();

  const alerts = screenWithContent.alerts?.nodes ?? [];
  const campaigns = screenWithContent.campaignScreenContent?.nodes ?? [];
  const plannedWorks = screenWithContent.plannedWorks?.nodes ?? [];

  const plannedWorkEnabled =
    currentUser && feedId
      ? isFeatureEnabled(
          currentUser,
          FeatureFlagName.PLANNED_WORK,
          feedId as FeedId,
        )
      : false;
  const weightingEnabled =
    currentUser && feedId
      ? isFeatureEnabled(
          currentUser,
          FeatureFlagName.WEIGHTING_FREQUENCY,
          feedId as FeedId,
        )
      : false;

  const campaignsCount = uniq(campaigns.map((c) => c.id)).length;
  const plannedWorksCount = uniq(plannedWorks.map((pw) => pw.id)).length;
  const alertsCount = uniq(alerts.map((a) => a.id)).length;

  let screenContentContainers: ScreenContentContainer[] = [];

  screenContentContainers = getOrderedContent([
    ...campaigns.map((c) => {
      return {
        type: ScreenContentType.campaign,
        priority: c.priority,
        weight: c.weight,
        status: c.campaign.status as ContentStatusEnum,
        screenContent: c,
      };
    }),
    ...plannedWorks.flatMap((pw) => {
      return pw.plannedWorkScreenMessages.nodes
        .filter((pwsm) =>
          pwsm?.screenTargeting
            ?.flatMap((st) => st?.targetTypes ?? [])
            ?.includes(screenWithContent?.targetType),
        )
        .map((pwsm) => {
          return {
            type: ScreenContentType.planned,
            priority: pwsm.priority,
            weight: pwsm.weight,
            status: pw.status as ContentStatusEnum,
            screenContent: {
              plannedWork: pw,
              screenMessage: pwsm,
            },
          };
        });
    }),
    ...alerts.map((a) => {
      // @ts-ignore // Object cannot be null here
      return {
        type: ScreenContentType.alert,
        priority: a.priority,
        weight: a.weight,
        status: ContentStatus.LIVE,
        screenContent: a,
      };
    }),
  ]);

  /**
   * `screenContentContainers` is an array of all the formatted content for a screen.
   *
   * If there are any TAKEOVER or SCHEDULED content, we need to remove them from the
   * screenContentContainers array. This allows us to render the TAKEOVER, SCHEDULED and
   * normal content separately and in the correct order. Where we display the TAKEOVER
   * content first, then the normal content, and finally the SCHEDULED content.
   */

  const futureScheduledContent = screenContentContainers.filter(
    (sc) => sc.status === ContentStatus.SCHEDULED,
  );

  const takeOverScreenContent = screenContentContainers.filter(
    (sc) =>
      sc.priority === SchedulePriority.TAKEOVER &&
      sc.status !== ContentStatus.SCHEDULED,
  );

  if (takeOverScreenContent.length || futureScheduledContent.length) {
    screenContentContainers = getOrderedContent(
      screenContentContainers.filter(
        (sc) =>
          sc.priority !== SchedulePriority.TAKEOVER &&
          sc.status !== ContentStatus.SCHEDULED,
      ),
    );
  }

  const totalWeight = screenContentContainers.reduce((sum, scc) => {
    return sum + scc.weight;
  }, 0);

  const setIndex =
    screenWithContent.__typename === 'Screens2'
      ? getSetIndex(screenWithContent as Screens2)
      : undefined;

  return (
    <React.Fragment>
      <InScreenHeading
        campaignsCount={campaignsCount}
        plannedWorksCount={plannedWorksCount}
        plannedWorkEnabled={plannedWorkEnabled}
        alertsCount={alertsCount}
        showContentCount={false}
      />
      {takeOverScreenContent.map((scc) => (
        <ScreenContentRow
          screenContentContainer={scc}
          weightingEnabled={weightingEnabled}
          totalWeight={takeOverScreenContent.length}
          screenOrientation={screenWithContent?.orientation}
          screenTargetType={screenWithContent?.targetType as TargetType}
          setIndex={setIndex}
        />
      ))}
      {screenContentContainers.map((scc) => (
        <ScreenContentRow
          screenContentContainer={scc}
          weightingEnabled={weightingEnabled}
          totalWeight={takeOverScreenContent.length ? 0 : totalWeight}
          screenOrientation={screenWithContent.orientation}
          screenTargetType={screenWithContent.targetType as TargetType}
          setIndex={setIndex}
        />
      ))}
      {futureScheduledContent.map((scc) => (
        <ScreenContentRow
          screenContentContainer={scc}
          weightingEnabled={weightingEnabled}
          totalWeight={0}
          screenOrientation={screenWithContent.orientation}
          screenTargetType={screenWithContent.targetType as TargetType}
          setIndex={setIndex}
        />
      ))}
    </React.Fragment>
  );
};

export default OrderedScreenContent;
