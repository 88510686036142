/** @jsxImportSource @emotion/react */

import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import useRoutesWithStops from 'hooks/useRoutesWithStops';
import { useFeedId } from 'contexts/FeedId';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message_screenMessages_ScreenMessagesConnection_nodes_ScreenMessage_targeting_ScreenSelector as GetEventById_event_messages_nodes_screenMessages_nodes_targeting } from '../../../generated/GetEventById';
import ScreenTargeting from './ScreenTargeting';
import {
  RoutesAndStopsById,
  RoutesAndStopsByIdVariables,
} from '../../../generated/RoutesAndStopsById';

const RoutesAndStopsByIdsQuery = loader(
  '../../../graphql/RoutesAndStopsById.gql',
);

interface ScreenTargetingContainerProps {
  headerText?: string;
  targeting:
    | GetEventById_event_messages_nodes_screenMessages_nodes_targeting[]
    | undefined;
}

const ScreenTargetingContainer: React.FC<
  ScreenTargetingContainerProps & { children?: React.ReactNode }
> = ({ targeting, headerText }) => {
  const feedId = useFeedId();
  const routesWithStops = useRoutesWithStops(feedId);

  const { routeIds, stopIds } = targeting
    ? [...targeting]
        .sort((a, b) => {
          const route = routesWithStops.find(
            (route) => route.gtfsId === a.routeId,
          );
          return (
            (route?.stops.nodes.findIndex(
              (stop) => stop.gtfsId === a.entitySelector.stopId,
            ) ?? -1) -
            (route?.stops.nodes.findIndex(
              (stop) => stop.gtfsId === b.entitySelector.stopId,
            ) ?? -1)
          );
        })
        .reduce<{
          routeIds: string[];
          stopIds: string[];
        }>(
          (agg, cur) => {
            const { routeIds, stopIds } = agg;

            // Add all targeted routes
            if (!routeIds.find((id) => id === cur.routeId)) {
              routeIds.push(cur.routeId);
            }

            // Add all targeted stops not part of a borough
            if (
              cur.entitySelector.stopId &&
              !stopIds.find((id) => id === cur.entitySelector.stopId)
            ) {
              stopIds.push(cur.entitySelector.stopId);
            }

            return {
              routeIds,
              stopIds,
            };
          },
          { routeIds: [], stopIds: [] },
        )
    : { routeIds: [], stopIds: [] };

  // TODO: Handle errors
  const { loading, data } = useQuery<
    RoutesAndStopsById,
    RoutesAndStopsByIdVariables
  >(RoutesAndStopsByIdsQuery, {
    variables: {
      routeIds,
      stopIds,
    },
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (!data || !data.routes) {
    return <span>Unable to load targeting data.</span>;
  }

  return (
    <ScreenTargeting
      headerText={headerText}
      targeting={targeting}
      routesAndStopsData={data}
    />
  );
};

export default ScreenTargetingContainer;
