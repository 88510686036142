type Elem = ElemArray;

interface ElemArray extends Array<Elem | {}> {}

export default function removeTypenames(
  input: Elem | {} | null,
): Elem | null | Omit<{}, '__typename'> {
  if (Array.isArray(input)) {
    return input.map((i) => removeTypenames(i));
  }

  if (input !== null && typeof input === 'object' && !(input instanceof Date)) {
    return Object.keys(input).reduce((acc, key) => {
      if (key === '__typename') {
        return acc;
      }

      return {
        ...acc,
        // @ts-ignore
        [key]: removeTypenames(input[key]),
      };
    }, {});
  }

  return input;
}
