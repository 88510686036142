import { css } from '@emotion/react';
import theme, { ThemeType } from '../../../theme';

export const listbox = css`
  min-width: 200px;
`;

export const listboxButton = css`
  border: 2px solid #ccc;
  background: #fff;
  padding: 8px;
  cursor: pointer;
  display: block;
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    border: 4px solid transparent;
    border-top-color: currentColor;
    top: 16px;
    right: 8px;
  }
`;

export const form = css`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const section = (theme: ThemeType) => css`
  border-radius: 8px;
  box-shadow:
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  margin: ${theme.spacing.small} ${theme.spacing.large};

  &:first-of-type {
    margin-top: 0;
  }
`;

export const section2 = (theme: ThemeType) => css`
  padding: 32px 40px 40px;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.border};
  }
`;

export const section3 = (theme: ThemeType) => css`
  margin-bottom: ${theme.spacing.large};
  margin-left: 72px;
  margin-right: 40px;

  &.internal-notes__form .internal-notes__input-wrapper:not(:first-of-type) {
    margin-top: 24px;
  }
`;

export const section4 = css`
  display: flex;
  padding: 32px 0 32px 40px;
  border-top: 1px #dddddd solid;
`;

export const internalNotesToggle = css`
  padding: 32px 40px 24px;
`;

export const label = css`
  margin-bottom: 8px;
`;

export const textarea = css`
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 16px;
`;

export const inputWrapper = css`
  &.statusSection {
    label {
      margin-bottom: 8px;
    }
  }

  &.statusGroup {
    [data-reach-listbox-button] {
      border-left: none;
      box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
    }
  }

  &.noStatuses {
    [data-reach-listbox-button] {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &.hasStatuses {
    [data-reach-listbox-button] {
      border-right: none;
    }
  }

  > label,
  > span {
    display: block;
    font-weight: bold;
  }

  input + label {
    display: inline-block;
  }

  & [data-reach-listbox-button] {
    border-radius: 0px;
  }

  &:first-of-type [data-reach-listbox-button] {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  [data-state='expanded'] [data-reach-listbox-button] {
    border-radius: 4px 4px 0px 0px;
    border-bottom: none;
    box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
  }

  & [data-reach-listbox-input][data-state='expanded'],
  & [data-reach-listbox-input]:focus-within {
    [data-reach-listbox-button] {
      border: 1px solid ${theme.colors.accent3};
    }
  }

  & [data-reach-listbox-input][data-state='expanded'] {
    [data-reach-listbox-button] {
      border-bottom: 0;
    }
  }
`;

export const actions = css`
  display: flex;
  flex-direction: row-reverse;
  just-content: flex-start;

  > * {
    margin-left: 16px;
  }
`;

export const TargetingType = css`
  display: flex;
  justify-content: space-between;

  button {
    flex: 0 0 ${100 / 2 - 0.5}%;
    padding: 21px 0;
    font-size: ${theme.typography.sizes.large.fontSize};
    font-weight: ${theme.typography.weights.bold};
  }
`;
