import React, { useEffect } from 'react';
import { useRemirrorContext } from '@remirror/react';
import { EditorState, prosemirrorNodeToHtml as toHTML } from 'remirror';

/**
 * If the listener is used to mirror multiple fields, convert them
 * to HTML and concatenate them, separated by an empty paragraph.
 * @param states - An array of EditorStates
 * @returns string - HTML output
 */
export const mergeMessages = (states: EditorState[]): string => {
  return states
    .map((state) => toHTML(state.doc))
    .filter((html) => html !== '<p></p>')
    .join('<p></p>');
};

const MirrorListener: React.FC<{
  shouldMirror?: boolean;
  mirrorState?: EditorState | EditorState[];
}> = ({ shouldMirror = false, mirrorState }) => {
  const { setContent } = useRemirrorContext();

  useEffect(() => {
    if (mirrorState && shouldMirror) {
      setContent(
        Array.isArray(mirrorState)
          ? mergeMessages(mirrorState)
          : toHTML(mirrorState.doc),
        {
          triggerChange: true,
        },
      );
    }
  }, [shouldMirror, JSON.stringify(mirrorState), setContent]);
  return null;
};

export default MirrorListener;
