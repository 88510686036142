/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { startOfISOWeek } from 'date-fns';
import { loader } from 'graphql.macro';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { useFeedId } from 'contexts/FeedId';
import useLiveQuery from 'hooks/use-live-query';

import useQueryParams from 'hooks/useQueryParams';

import PageHeading from '../../scaffolding/PageHeading';
import Pagination from '../../common/Pagination';
import GeneratedLogItem from './generated-log-item';
import Loader from '../../common/skeletons/Table';

import * as S from './index.styled';

import {
  ClearedReportsVariables,
  ClearedReports as ClearedReportsType,
} from '../../../generated/ClearedReports';
import {
  ClearedReportVariables,
  ClearedReport as ClearedReportType,
} from '../../../generated/ClearedReport';
import {
  GenerateCurrentClearedReports as GenerateCurrentClearedReportsType,
  GenerateCurrentClearedReportsVariables,
} from '../../../generated/GenerateCurrentCleared';

const ClearedReportsQuery = loader('../../../graphql/ClearedReports.gql');
const GenerateCurrentClearedReport = loader(
  '../../../graphql/GenerateCurrentCleared.gql',
);
const ClearedReportQuery = loader('../../../graphql/ClearedReport.gql');

const MESSAGES_PER_PAGE = 50;

type ClearedReport = {
  id: string;
  signedS3Url: string | null;
  fromDate: Date;
  toDate: Date;
};

const GeneratedLogs: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const feedId = useFeedId();
  const queryParams = useQueryParams();
  const history = useHistory();
  const [generatedId, setGeneratedId] = useState(null);

  const currentFromDate = startOfISOWeek(new Date());
  const page = Number(queryParams.get('page') || 1);

  const { data, loading } = useQuery<
    ClearedReportsType,
    ClearedReportsVariables
  >(ClearedReportsQuery, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    variables: {
      feedId,
      offset: (page - 1) * MESSAGES_PER_PAGE,
      perPage: MESSAGES_PER_PAGE,
    },
  });

  const generatedReport = useLiveQuery<
    ClearedReportType,
    ClearedReportVariables
  >(ClearedReportQuery, {
    skip: !generatedId,
    variables: {
      id: generatedId,
    },
  });

  if (
    generatedReport.data?.clearedReport?.isBeingGenerated === false &&
    generatedReport.data?.clearedReport?.signedS3Url
  ) {
    window.location.href = generatedReport.data?.clearedReport?.signedS3Url;
    setGeneratedId(null);
  }
  const [generateReport] = useMutation<
    GenerateCurrentClearedReportsType,
    GenerateCurrentClearedReportsVariables
  >(GenerateCurrentClearedReport, {
    variables: { feedId },
  });

  const reports = data?.clearedReports?.nodes || [];
  const totalCount = data?.clearedReports?.totalCount || 0;

  const handleClick = (newPage: number) => {
    queryParams.set('page', encodeURIComponent(newPage));
    history.push(`${window.location.pathname}?${queryParams.toString()}`);
    window.scrollTo(0, 0);
  };

  const timestampToDate = (value: string) =>
    new Date(value.replace(/T.+/g, "$'"));

  const yearGroups: { year: number; reports: ClearedReport[] }[] = [];

  reports.forEach(({ id, signedS3Url, fromDate, toDate }) => {
    const clearedReport: ClearedReport = {
      id,
      signedS3Url,
      fromDate: timestampToDate(fromDate),
      toDate: timestampToDate(toDate),
    };

    const year = clearedReport.toDate.getFullYear();

    if (!yearGroups.length || yearGroups[yearGroups.length - 1].year !== year) {
      yearGroups.push({ year, reports: [] });
    }

    yearGroups[yearGroups.length - 1].reports.push(clearedReport);
  });

  const currentYear = new Date().getFullYear();
  const yearGroupDoesNotIncludeCurrentYear = !yearGroups.find(
    (y) => y.year === currentYear,
  );

  const currentCALItem = (
    <GeneratedLogItem
      key="current"
      fromDate={currentFromDate}
      s3Url={generatedReport.data?.clearedReport?.signedS3Url}
      current
      isBeingGenerated={generatedReport.data?.clearedReport?.isBeingGenerated}
      generateReport={async () => {
        const { data } = await generateReport();
        setGeneratedId(data?.generateCurrentClearedReports?.clearedReport?.id);
      }}
    />
  );

  return (
    <S.PageContainer>
      <PageHeading
        breadcrumbs={[
          'Messages',
          {
            label: 'Alerts',
            to: `/${feedId}`,
          },
          {
            label: 'Cleared',
            to: `/${feedId}/alerts/cleared`,
          },
        ]}
        title="Log History"
      />
      <S.BodyContainer>
        <Loader loading={loading}>
          {yearGroupDoesNotIncludeCurrentYear && (
            <React.Fragment key={currentYear}>
              <S.Time>{currentYear}</S.Time>
              <S.BodyRow>{currentCALItem}</S.BodyRow>
            </React.Fragment>
          )}
          {yearGroups.map(({ year, reports }) => (
            <React.Fragment key={year}>
              <S.Time>{year}</S.Time>
              <S.BodyRow>
                {year === currentYear && currentCALItem}
                {reports.map(({ id, signedS3Url, fromDate, toDate }) => (
                  <GeneratedLogItem
                    key={id}
                    fromDate={fromDate}
                    toDate={toDate}
                    s3Url={signedS3Url}
                  />
                ))}
              </S.BodyRow>
            </React.Fragment>
          ))}
          <Pagination
            currentPage={page}
            itemsPerPage={MESSAGES_PER_PAGE}
            totalItems={totalCount}
            onPrevClick={() => handleClick(page - 1)}
            onNextClick={() => handleClick(page + 1)}
          />
        </Loader>
      </S.BodyContainer>
    </S.PageContainer>
  );
};

export default GeneratedLogs;
