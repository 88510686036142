export const WEB_NO_MESSAGES_HEADING = 'All Clear!';
export const WEB_NO_MESSAGES_HEADING_FILTERS = 'No Results';

export const WEB_NO_MESSAGES_MESSAGE = 'There are no active alerts right now.';
export const WEB_NO_MESSAGES_CTA_LABEL = 'compose alert';
export const WEB_NO_MESSAGES_MESSAGE_FILTERS =
  'There are no active alerts that match the filters selected.';
export const WEB_NO_MESSAGES_CTA_LABEL_FILTERS = 'Compose a new Alert';

export const WEB_NO_CAMPAIGNS_MESSAGE =
  'There are no active campaigns right now.';
export const WEB_NO_CAMPAIGNS_CTA_LABEL = 'compose campaign';
export const WEB_NO_CAMPAIGNS_MESSAGE_FILTERS =
  'There are no active campaigns that match the filters selected.';
export const WEB_NO_CAMPAIGNS_CTA_LABEL_FILTERS = 'Compose a new Campaign';

export const WEB_NO_SCREENS_MESSAGE = 'There are no screens available.';
export const WEB_NO_SCREENS_MESSAGE_FILTERS =
  'There are no screens that match the filters selected.';

export const WEB_NO_ASSETS_HEADING = 'No Assets!';
export const WEB_NO_ASSETS_OR_FOLDERS_HEADING = 'No Assets or Folders!';
export const WEB_NO_ASSETS_HEADING_FILTERS = 'No Results';
export const WEB_NO_ASSETS_MESSAGE = 'There are no assets available.';
export const WEB_NO_ASSETS_OR_FOLDERS_MESSAGE =
  'There are no assets or folders available.';
export const WEB_NO_ASSETS_MESSAGE_FILTERS =
  'There are no assets that match the filters selected.';
export const WEB_NO_ASSETS_MESSAGE_IN_FOLDER =
  'There are no assets available in this folder.';

export const INDEFINITE_END_DATE_MESSAGE = 'Ongoing';
