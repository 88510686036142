/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import ConfirmModal from 'components/common/confirm-modal';
import alertIconSrc from 'images/icon_alert.svg';

interface ConfirmUnlockModalProps {
  onConfirm: () => void;
  onDismiss: () => void;
}

const ConfirmUnlockModal: React.FC<ConfirmUnlockModalProps> = ({
  onConfirm,
  onDismiss,
}) => (
  <ConfirmModal
    isOpen
    title={
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <div
          css={css`
            background-image: url('${alertIconSrc}');
            background-size: 14px;
            background-repeat: no-repeat;
            background-position: 3.4px 2px;
            background-color: #ffcf00;
            height: 20px;
            width: 20px;
            border-radius: 10px;
            margin-right: 8px;
          `}
        />
        No Content
      </div>
    }
    message="There are no campaigns to show on this screen once it is unlocked. Please note that clicking confirm will leave this screen with nothing to display."
    onConfirm={onConfirm}
    onDismiss={onDismiss}
  />
);

export default ConfirmUnlockModal;
