/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { ThemeType, ThemeSizes } from '../../theme';

export enum TStatusPillTypes {
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
  DRAFT = 'DRAFT',
  CLEARED = 'CLEARED',
  LIVE = 'LIVE',
  ERROR = 'ERROR',
  DEPLOYING = 'DEPLOYING',
  REMOVED = 'REMOVED',
  LIMITED = 'LIMITED',
  UNKNOWN = 'UNKNOWN',
  CLEARING = 'CLEARING',
  SCREENS_CLEARING = 'SCREENS_CLEARING',
}

export interface StatusPillProps {
  status: TStatusPillTypes;
  size?: ThemeSizes;
  textOverride?: string;
  className?: string;
}
const StatusPill: React.FC<StatusPillProps> = ({
  size = 'xxsmall',
  status,
  textOverride,
  className,
  ...props
}) => {
  let text = '';
  let backgroundColor = '';

  if (status === TStatusPillTypes.PUBLISHED) {
    text = 'Published';
    backgroundColor = '#E8D3FF';
  } else if (status === TStatusPillTypes.DRAFT) {
    text = 'Draft';
    backgroundColor = '#D3EBFF';
  } else if (status === TStatusPillTypes.SCHEDULED) {
    text = 'Scheduled';
    backgroundColor = '#D9B6FF';
  } else if (status === TStatusPillTypes.CLEARED) {
    text = 'Cleared';
    backgroundColor = '#EAEAEA';
  } else if (status === TStatusPillTypes.LIVE) {
    text = 'Live';
    backgroundColor = 'rgba(0,170,8,0.3)'; // #00AA08
  } else if (status === TStatusPillTypes.DEPLOYING) {
    text = 'Deploying';
    backgroundColor = '#EBD99D';
  } else if (status === TStatusPillTypes.LIMITED) {
    text = 'Limited';
    backgroundColor = '#F9F2B5';
  } else if (status === TStatusPillTypes.ERROR) {
    text = 'Error';
    backgroundColor = '#F1D1D1';
  } else if (status === TStatusPillTypes.REMOVED) {
    text = 'Cancelled';
    backgroundColor = '#EBD99D';
  } else if (status === TStatusPillTypes.UNKNOWN) {
    text = 'Unknown';
    backgroundColor = '#EAEAEA';
  } else if (status === TStatusPillTypes.CLEARING) {
    text = 'Clearing';
    backgroundColor = '#FFCF00';
  } else if (status === TStatusPillTypes.SCREENS_CLEARING) {
    text = 'Screens Clearing';
    backgroundColor = '#FFCF00';
  } else {
    return null;
  }

  if (textOverride) {
    text = textOverride;
  }

  return (
    <span
      className={className}
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes[size]};
        font-family: ${theme.typography.families.primary};
        border-radius: 16px;
        background-color: ${backgroundColor};
        color: ${theme.colors.black};
        padding: 4px 8px;
        font-weight: normal;
      `}
      {...props}
    >
      {text}
    </span>
  );
};

export default StatusPill;
