/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import InvalidFilenameModal from 'components/common/invalid-filename-modal';
import * as S from '../campaign-form/screen-uploader.styled';
import { ThemeType } from '../../../theme';
import { useUpload, UploadType } from '../../../hooks/useUpload';
import { ReactComponent as EditIcon } from '../../../images/icon_edit_sm.svg';
import { ReactComponent as UploadIcon } from '../../../images/icon_upload.svg';
import { isInvalidFilename } from '../../../utils/assets-helpers';

const MAX_SOCIAL_MEDIA_IMAGE_SIZE_BYTES = 5 * 10 ** 6; // 5mb
const MAX_SOCIAL_MEDIA_VIDEO_SIZE_BYTES = 512 * 10 ** 6; // 512mb

const validateFileSize = (file: File) => {
  if (file.type.startsWith('image/')) {
    return file.size <= MAX_SOCIAL_MEDIA_IMAGE_SIZE_BYTES;
  }
  if (file.type.startsWith('video/')) {
    return file.size <= MAX_SOCIAL_MEDIA_VIDEO_SIZE_BYTES;
  }

  return true;
};

const useValidatedDropzone = ({
  onDrop: handleDrop,
  onInvalidFilename: handleInvalidFilename,
}: {
  onDrop: (f: null | File) => void;
  onInvalidFilename: (filename: string) => void;
}) => {
  const onDrop = React.useCallback(
    // eslint-disable-next-line consistent-return
    async (files: File[]) => {
      if (files[0]) {
        const filename = files[0].name;

        if (isInvalidFilename(filename)) {
          return handleInvalidFilename(filename);
        }

        if (!validateFileSize(files[0])) {
          // eslint-disable-next-line no-alert
          return window.alert('File is too large');
        }

        handleDrop(files[0]);
      }
    },
    [handleDrop, handleInvalidFilename],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, video/mp4, video/quicktime',
  });

  return { getRootProps, getInputProps };
};

interface AwaitingFileProps {
  id: number;
  height: number;
  width: number;
  startUpload: (f: null | File) => void;
  onInvalidFilename: (filename: string) => void;
  multiplier?: number;
  scale?: number;
}
const AwaitingFile: React.FC<AwaitingFileProps> = ({
  id,
  startUpload,
  height,
  width,
  onInvalidFilename,
  multiplier,
  scale = 0.25,
}) => {
  const { getRootProps, getInputProps } = useValidatedDropzone({
    onDrop: startUpload,
    onInvalidFilename,
  });

  const fileInputId = `file__input_${id}`;
  return (
    <S.ScreenBox empty height={height} width={width} multiplier={multiplier}>
      <div
        css={(theme: ThemeType) => css`
          cursor: pointer;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: ${theme.colors['background-inset']};
        `}
        {...getRootProps()}
      >
        <input
          {...getInputProps({ id: fileInputId })}
          css={css`
            display: none;
          `}
        />
        <UploadIcon
          css={css`
            margin-bottom: calc(48px * ${scale});
          `}
          height={328 * scale}
          width={328 * scale}
        />
        <label // eslint-disable-line
          htmlFor={fileInputId}
          css={css`
            cursor: pointer;
            line-height: calc(4.6 * ${scale});
          `}
          onClick={(evt) => {
            evt.preventDefault();
          }}
        >
          <S.SmallText scale={scale} accent>
            Select file to upload
          </S.SmallText>
        </label>
      </div>
    </S.ScreenBox>
  );
};

const ProgressView: React.FC<{
  progress: number;
  height: number;
  width: number;
  multiplier?: number;
  children?: React.ReactNode;
}> = ({ progress, height, width, multiplier }) => (
  <S.ScreenBox empty height={height} width={width} multiplier={multiplier}>
    <S.SmallText
      css={(theme: ThemeType) => css`
        margin-bottom: ${theme.spacing.xsmall};
        ${theme.typography.sizes.xsmall}
      `}
    >
      Uploading...
    </S.SmallText>
    <progress
      max={100}
      value={progress}
      css={(theme: ThemeType) => css`
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 2px;
        border: none;
        height: 4px;
        background-color: ${theme.colors.light9};

        ::-webkit-progress-bar {
          background: ${theme.colors.light9};
          border-radius: 2px;
        }

        ::-webkit-progress-value {
          background: ${theme.colors.accent1};
          border-radius: 2px;
        }

        ::-moz-progress-bar {
          background: ${theme.colors.accent1};
          border-radius: 2px;
        }
      `}
    >
      {progress}%
    </progress>
  </S.ScreenBox>
);

const UploadedView: React.FC<
  {
    upload: UploadType;
    height: number;
    width: number;
    startUpload: (f: null | File) => void;
    multiplier?: number;
  } & { children?: React.ReactNode }
> = ({ upload, height, width, multiplier }) => {
  const { mimeType } = upload;

  const contents = (() => {
    if (mimeType.startsWith('image/')) {
      return (
        <div
          role="img"
          css={css`
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(${upload.signedS3Url});
          `}
        />
      );
    }
    if (mimeType.startsWith('video/')) {
      return (
        <video
          controls
          autoPlay
          loop
          muted
          css={css`
            height: 100%;
            width: 100%;
          `}
          src={upload.signedS3Url || undefined}
        />
      );
    }

    return null;
  })();

  return (
    <S.ScreenBox
      height={height}
      width={width}
      multiplier={multiplier}
      css={(theme: ThemeType) => css`
        position: relative;
        ${mimeType === 'application/zip'
          ? css`
              background-color: ${theme.colors['background-inset']};
            `
          : ''}
      `}
    >
      {contents}
    </S.ScreenBox>
  );
};

const SocialMediaAssetUploader: React.FC<
  {
    id: number;
    onChange: (id: number, u: UploadType | null) => void;
    height: number;
    width: number;
    defaultUpload?: UploadType;
    multiplier?: number;
  } & { children?: React.ReactNode }
> = ({ id, onChange, defaultUpload = null, height, width, multiplier }) => {
  const [invalidFilename, setInvalidFilename] = useState('');

  const { startUpload, uploading, progress, upload } = useUpload({
    defaultUpload,
  });

  useEffect(() => {
    onChange(id, upload);
  }, [id, onChange, upload]);

  const { getRootProps, getInputProps } = useValidatedDropzone({
    onDrop: startUpload,
    onInvalidFilename: setInvalidFilename,
  });

  const contents = (() => {
    if (uploading) {
      return (
        <ProgressView
          progress={progress}
          height={height}
          width={width}
          multiplier={multiplier}
        />
      );
    }
    if (upload) {
      return (
        <React.Fragment>
          <UploadedView
            startUpload={startUpload}
            upload={upload}
            height={height}
            width={width}
            multiplier={multiplier}
          />
          <S.ExtraInfo>
            <S.Filename>{(upload && upload.originalFilename) || ''}</S.Filename>
          </S.ExtraInfo>
          <button
            type="button"
            onClick={(evt) => {
              evt.stopPropagation();
              startUpload(null);
            }}
            css={css`
              text-align: left;
              margin-top: 4px;
              :focus {
                outline: none;
              }
            `}
            {...getRootProps()}
          >
            <EditIcon
              css={(theme: ThemeType) => css`
                color: ${theme.colors.accent1};
              `}
            />
            <S.SmallText
              css={(theme: ThemeType) => css`
                color: ${theme.colors.accent1};
                ${theme.typography.sizes.xsmall};
                margin-left: 5px;
              `}
            >
              Replace File
            </S.SmallText>
          </button>
          <input
            css={css`
              display: none;
              visibility: hidden;
            `}
            {...getInputProps()}
          />
        </React.Fragment>
      );
    }
    return (
      <AwaitingFile
        id={id}
        startUpload={startUpload}
        height={height}
        width={width}
        multiplier={multiplier}
        onInvalidFilename={setInvalidFilename}
      />
    );
  })();

  return (
    <S.Container height={height} width={width} multiplier={multiplier}>
      {contents}
      <InvalidFilenameModal
        isOpen={!!invalidFilename}
        onDismiss={() => setInvalidFilename('')}
        filename={invalidFilename}
      />
    </S.Container>
  );
};

export default SocialMediaAssetUploader;
