/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { css } from '@emotion/react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import useForm, { FormContext, useFormContext } from 'react-hook-form';
import {
  formatMessageDoc,
  LONG_GTFS_TEXT_REPLACEMENTS,
  FeedId,
} from '@mta-live-media-manager/shared';

import { getDefaultAffectedStations } from 'components/common/connected-route-range-stop-selector-group';
import { useTweetThreadState } from 'components/common/tweets/tweet-thread-input';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import useRoutesWithStops from 'hooks/useRoutesWithStops';
import {
  AlertMessageLeader,
  AlertWebAndApps,
  AlertEmailAndSubject,
  AlertFormSectionWrapper,
  AlertFormButtons,
  checkIfDraft,
} from 'components/common/alert-form';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';

import ToggleSection from 'ui-kit/toggle-section/toggle-section';
import { ALERTS_DEFAULT_DISP_FREQUENCY } from 'components/common/form-elements/display-frequency-selector';
import { EditorState, prosemirrorNodeToHtml as toHTML } from 'remirror';
import * as S from './index.styled';

import {
  GetEventById,
  GetEventByIdVariables,
  GetEventById_event_Event as GetEventById_event,
} from '../../../generated/GetEventById';
import {
  ScreenSelection,
  ScreenSelectionVariables,
  ScreenSelection_routes_RoutesConnection as ScreenSelection_routes,
} from '../../../generated/ScreenSelection';
import {
  GetTweetsByEventId,
  GetTweetsByEventIdVariables,
  GetTweetsByEventId_event_Event_messages_MessagesConnection_nodes_Message as GetTweetsByEventId_event_messages_nodes,
} from '../../../generated/GetTweetsByEventId';
import HR from '../../common/HR';

import UpdateHeading from './components/update-heading';
import UpdateMessageCard from './components/update-message-card';
import UpdateTweet from './components/UpdateTweet';
import UpdateScreens from './components/UpdateScreens';
import InternalLog from '../../common/internal-log/internal-log';
import {
  TImpact,
  ComposeFormData,
  ScreenUpdateMethods,
  ScreenData,
  AlertScreenTargeting,
} from '../../../types';
import Button from '../../common/Button';
import { useMessageCreation } from '../../../hooks';
import PageMeta from '../../common/PageMeta';
import {
  isTrain,
  isBus,
  isOutfront,
  isBridgeTunnel,
} from '../../../utils/feed-switches';
import { useFeedId } from '../../../contexts/FeedId';
import {
  screenTargetsFromEventTargeting,
  getScreenTargetsForAutoScreenTargeting,
  getAlertDefaultScreenTargetTypes,
} from '../../../utils/screen-targeting';
import ConfirmModal from '../../common/confirm-modal';
import ErrorModal from '../../common/error-modal';
import { useRoutesByFeedId } from '../../../contexts/Routes';
import BackOnlyPageHeading from '../../scaffolding/back-only-page.heading';
import { entitySelectorsToRouteMentions } from '../../../utils/route-mentions';
import { getMessageTypeDescriptionsByFeed } from '../../../utils/message-type-display';
import {
  GtfsEntitySelectorInput,
  MessageType,
  EmailMessageTypeCodes,
  EventStatus,
  TargetType,
  MessagesOrderBy,
  FeatureFlagName,
} from '../../../generated/global-types';
import NotFoundPage from '../../common/not-found';
import {
  SCREEN_TARGETING_NAME,
  WEB_IMPACTS_NAME,
  WEB_TARGETED_ROUTES_NAME,
  WEB_TARGETED_STOPS_NAME,
  TWEET_CUSTOMIZED_NAME,
  SCREEN_TARGETED_ROUTES_NAME,
  SCREEN_CUSTOMIZED_NAME,
  SCREEN_UPDATE_METHOD_NAME,
  WEB_BODY_NAME,
  WEB_EXPIRATION_NAME,
  EMAIL_SUBJECT_NAME,
  EMAIL_BODY_NAME,
  WEB_BODY_ADDITIONAL_NAME,
  AFFECTED_STOPS_NAME,
  AFFECTED_STOPS_EDITED_NAME,
  EMAIL_TYPES_NAME,
  WEIGHT_FREQUENCY_NAME,
  TWEETS_JOIN_SEPERATOR,
  SCREEN_TITLE_NAME,
  SCREEN_BODY_ADDITIONAL_NAME,
  SCREEN_BODY_NAME,
  INITIAL_SCREEN_CHOICE_NAME,
  SCREENS_NAME,
  SCREEN_TARGETING_EDITED,
  SAVE_LIVE_ALERT_AS_DRAFT_MESSAGE,
  SCREEN_IS_DUPLICATE_NAME,
  SCREEN_IS_CLEARING_NAME,
  SMS_BODY_NAME,
} from '../../../constants/alerts';
import {
  modifyIndexInArray,
  scrollToErrors,
} from '../../../utils/compose-helpers';
import Loader from '../../common/skeletons/PageWithContent';
import * as Layout from '../../common/layout';

const GetEventByIdQuery = loader('../../../graphql/GetEventById.gql');
const GetTweetsByEventIdQuery = loader(
  '../../../graphql/GetTweetsByEventId.gql',
);
const ScreenSelectionQuery = loader('../../../graphql/ScreenSelection.gql');

enum Confirmation {
  DISCARD_CHANGES = 'discardChanges',
  SAVE_LIVE_ALERT_AS_DRAFT = 'saveLiveAlertAsDraft',
}

const CANCEL_UPDATE_TITLE = 'Cancel Update';
const SAVE_LIVE_ALERT_AS_DRAFT_TITLE = 'Save Live Alert as a Draft';
const EMPTY_HTML_CONTENT = '<p></p>';

const PreviousEventWrapper: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const eventId = typeof id === 'string' ? parseInt(id, 10) : 0;
  const feedId = useFeedId();
  const isBusFeed = isBus(feedId);

  const { loading, data } = useQuery<GetEventById, GetEventByIdVariables>(
    GetEventByIdQuery,
    {
      variables: {
        id: eventId,
        includeClearingScreenMessages: true,
        orderBy: [MessagesOrderBy.CREATED_AT_DESC],
        first: 1,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const { loading: selectionLoading, data: selectionData } = useQuery<
    ScreenSelection,
    ScreenSelectionVariables
  >(ScreenSelectionQuery, {
    variables: {
      feedId: isOutfront(feedId) ? FeedId.NYCTSubway : feedId,
    },
    skip: isBusFeed,
  });

  const [loadTweets, { called, data: tweetsData }] = useLazyQuery<
    GetTweetsByEventId,
    GetTweetsByEventIdVariables
  >(GetTweetsByEventIdQuery, {
    variables: {
      id: eventId,
      orderBy: [MessagesOrderBy.CREATED_AT_DESC],
    },
  });

  // Load all tweets to show previous tweets if last message didn't have a tweet
  if (
    !called &&
    data?.event?.messages.totalCount &&
    data?.event?.messages.totalCount > 1
  ) {
    loadTweets();
  }

  if (!loading && !data?.event) {
    return <NotFoundPage />;
  }

  const messageTweets = tweetsData
    ? tweetsData?.event?.messages.nodes.filter(
        ({ tweets }) => !!tweets.nodes.length,
      )
    : data?.event?.messages.nodes.filter(({ tweets }) => !!tweets.nodes.length);

  const startWithAppsOpen =
    data?.event?.messages.nodes.some((m) => m.includeApps) || false;
  const startWithEmailOpen =
    data?.event?.messages.nodes.some((m) => m.subject) || false;
  const startWithTwitterOpen =
    data?.event?.messages.nodes.some((m) => m.tweets.nodes.length > 0) || false;
  const startWithScreensOpen =
    data?.event?.messages.nodes.some(
      (m) => m.screenMessages.nodes.length > 0,
    ) || false;

  const isLoading = loading || (selectionLoading && !isBusFeed);

  return (
    <Loader loading={isLoading}>
      {!isLoading &&
        (!!selectionData?.routes || feedId === FeedId.NYCTBus) &&
        !!data?.event && (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <Update
            event={data.event}
            screenSelectionRoutes={
              selectionData?.routes ?? ({} as ScreenSelection_routes)
            }
            startWithAppsOpen={startWithAppsOpen}
            startWithEmailOpen={startWithEmailOpen}
            startWithTwitterOpen={startWithTwitterOpen}
            startWithScreensOpen={startWithScreensOpen}
            messageTweets={messageTweets || []}
          />
        )}
    </Loader>
  );
};

interface UpdateProps {
  event: GetEventById_event;
  screenSelectionRoutes: ScreenSelection_routes;
  startWithAppsOpen: boolean;
  startWithEmailOpen: boolean;
  startWithTwitterOpen: boolean;
  startWithScreensOpen: boolean;
  messageTweets: GetTweetsByEventId_event_messages_nodes[];
}

/**
 * Add Screen: adds a new empty screen targeting item to the screens array.
 * Duplicate Screen: adds a new screen targeting item to the screens array with the same values as the last screen targeting item.
 */
const ScreensButtons: React.FC<{
  screensArray: any[];
}> = ({ screensArray }) => {
  const { watch, setValue, register } = useFormContext();
  const screens = watch(SCREENS_NAME) as ScreenData[];
  const webBody = watch(WEB_BODY_NAME) as EditorState;
  const webBodyAdditional = watch(WEB_BODY_ADDITIONAL_NAME) as EditorState;
  const feedId = useFeedId();
  const routesWithStops = useRoutesWithStops(feedId);
  const impacts = watch(WEB_IMPACTS_NAME);
  const affectedStops = watch(AFFECTED_STOPS_NAME);

  if (!screens.length) {
    register({ name: SCREENS_NAME });
  }

  return (
    <Layout.Row>
      <AlertFormSectionWrapper isGrayscale flex>
        <Button
          primary
          size="small"
          type="button"
          additionalStyles={css`
            margin-right: 12px;
          `}
          onClick={() => {
            const autoScreenTargeting = getScreenTargetsForAutoScreenTargeting(
              impacts,
              feedId,
              routesWithStops,
              affectedStops,
              true,
            );

            setValue(SCREENS_NAME, [
              ...screens,
              {
                [WEIGHT_FREQUENCY_NAME]: ALERTS_DEFAULT_DISP_FREQUENCY,
                [SCREEN_TITLE_NAME]: '',
                [SCREEN_TARGETING_NAME]: screens.length
                  ? [
                      {
                        options: [],
                        routeId: '',
                        layouts: getAlertDefaultScreenTargetTypes(feedId),
                        tags: undefined,
                      },
                    ]
                  : autoScreenTargeting,
                [SCREEN_BODY_ADDITIONAL_NAME]: webBodyAdditional,
                [SCREEN_BODY_NAME]: webBody,
                [INITIAL_SCREEN_CHOICE_NAME]: undefined,
                [SCREEN_CUSTOMIZED_NAME]: false,
              },
            ]);
            screensArray.push(
              screensArray[0]
                ? {
                    ...screensArray[0],
                    isClearing: false,
                  }
                : {
                    additionalInfo: webBodyAdditional?.doc?.textContent,
                    additionalInfoHtml:
                      webBodyAdditional && toHTML(webBodyAdditional.doc),
                    body: webBody.doc.textContent,
                    bodyHtml: toHTML(webBody.doc),
                    endAt: null,
                    priority: 'NORMAL',
                    publishAt: null,
                    targeting: [],
                    title: null,
                    weight: ALERTS_DEFAULT_DISP_FREQUENCY.weight,
                  },
            );
          }}
        >
          Add Screen
        </Button>
        {!!screensArray.length && (
          <Button
            primary
            size="small"
            type="button"
            onClick={() => {
              const lastScreenMsg = screens[screens.length - 1];
              const { screenBody, screenBodyAdditional } = lastScreenMsg;

              // To handle the case where user wants to duplicate a previous message
              // that has a screen update method as `keep` or `remove`.
              const lastScreensArrayMsg = screensArray[screens.length - 1];

              setValue(SCREENS_NAME, [
                ...screens,
                {
                  ...lastScreenMsg,
                  [SCREEN_UPDATE_METHOD_NAME]: undefined,
                  [SCREEN_CUSTOMIZED_NAME]: true,
                  [SCREEN_IS_DUPLICATE_NAME]: true,
                  [SCREEN_TARGETING_NAME]: lastScreenMsg?.screenTargeting?.map(
                    (st) => ({
                      ...st,
                      options: [],
                    }),
                  ),
                },
              ]);
              screensArray.push({
                ...lastScreensArrayMsg,
                additionalInfo:
                  screenBodyAdditional?.doc?.textContent ??
                  lastScreensArrayMsg.additionalInfo,
                additionalInfoHtml:
                  webBodyAdditional && screenBodyAdditional?.doc?.textContent
                    ? toHTML(screenBodyAdditional.doc)
                    : (lastScreensArrayMsg?.additionalInfoHtml ??
                      EMPTY_HTML_CONTENT),
                body: screenBody?.doc?.textContent ?? lastScreensArrayMsg.body,
                bodyHtml:
                  screenBody && screenBody?.doc?.textContent
                    ? toHTML(screenBody.doc)
                    : (lastScreensArrayMsg?.bodyHtml ?? EMPTY_HTML_CONTENT),
              });
            }}
          >
            Duplicate Screen
          </Button>
        )}
      </AlertFormSectionWrapper>
    </Layout.Row>
  );
};

const Update: React.FC<UpdateProps> = ({
  event,
  screenSelectionRoutes,
  startWithAppsOpen,
  startWithEmailOpen,
  startWithTwitterOpen,
  startWithScreensOpen,
  messageTweets,
}) => {
  const [
    {
      isConfirmationModalOpen,
      modalConfirmation,
      submitEvent: formSubmitEvent,
    },
    setFormConfirmationOptions,
  ] = useState<{
    isConfirmationModalOpen: boolean;
    modalConfirmation?: Confirmation;
    submitEvent?: React.FormEvent<HTMLFormElement>;
  }>({
    isConfirmationModalOpen: false,
  });
  const [showTwitter, setShowTwitter] = useState(startWithTwitterOpen);
  const [showScreens, setShowScreens] = useState(startWithScreensOpen);
  const [showWebAndApps, setShowWebAndApps] = useState(startWithAppsOpen);
  const [showEmailAndSms, setShowEmailAndSms] = useState(startWithEmailOpen);
  const [showInternalLog, setShowInternalLog] = useState(true);
  const [messageHtmlToCopy, setMessageHtmlToCopy] = useState('<p></p>');
  const [additionalMessageHtmlToCopy, setAdditionalMessageHtmlToCopy] =
    useState('<p></p>');
  const [surfaceErrors, setSurfaceErrors] = useState(false);
  const [screensHaveBeenToggled, setScreensHaveBeenToggled] =
    useState(startWithScreensOpen);
  const history = useHistory();
  const feedId = useFeedId();
  const { id } = useParams<{ id?: string }>();
  const allRoutes = useRoutesByFeedId(feedId);
  const showScreenTargeting = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
  );

  const isBusFeed = isBus(feedId);
  const isOutfrontFeed = isOutfront(feedId);
  const isBridgeTunnelFeed = isBridgeTunnel(feedId);

  const eventId = typeof id === 'string' ? parseInt(id, 10) : 0;
  const eventUrl = `/${feedId}/events/${eventId}`;

  const {
    createdAt,
    incidentStartAt,
    incidentEndAt,
    notifiedAt,
    notes,
    neededBy,
    status,
    messages: { nodes: previousMessages },
  } = event;

  const [previousMessage] = previousMessages;

  const {
    tweets,
    setText: setTweetText,
    setAttachments,
    setQuoteId,
    setReplyId,
    addTweet,
    removeTweet,
  } = useTweetThreadState();

  // TODO: [LMM-156] Handle multiple tweets and screen messages
  const {
    screenMessages: { nodes: screenMessages },
    clearingScreenMessages: { nodes: clearingScreenMessages } = { nodes: [] },
    entitySelectors,
    messageImpacts: prevMessageImpactsRaw,
    subject: prevEmailSubject,
    additionalInfo: prevMessageAdditionalInfo,
    body: prevMessageBody,
    emailBody: prevMessageEmailBody,
    smsBody: prevSmsBody,
    includeApps: prevIncludeApps,
    affectedStations: prevAffectedStations,
    endAt: prevEndAt,
  } = previousMessage;

  const previousTweets = messageTweets[0]?.tweets.nodes ?? [];
  const originalTweet =
    messageTweets[messageTweets.length - 1]?.tweets.nodes[0];

  const prevIsDraft = status === EventStatus.DRAFT;

  const messageTypeOptions = getMessageTypeDescriptionsByFeed(feedId);

  const prevMessageImpacts: TImpact[] = prevMessageImpactsRaw.nodes.map(
    (impact) => ({
      // Validate that the message type is currently active for alerts
      messageType: messageTypeOptions.some(
        (option) => option.value === impact.messageType,
      )
        ? impact.messageType
        : ('' as MessageType),
      routes: entitySelectorsToRouteMentions(
        impact.entitySelectors as GtfsEntitySelectorInput[],
        allRoutes,
      ),
      isAgencyWide: impact.isAgencyWide,
    }),
  );

  const [previousScreenMessages, setPreviousScreenMessages] = useState([
    ...clearingScreenMessages,
    ...screenMessages,
  ]);

  const previousMessageType =
    prevMessageImpacts.length > 1
      ? MessageType.MULTIPLE_CHANGES
      : prevMessageImpacts[0].messageType;

  const prevEmailText = prevEmailSubject ? prevMessageEmailBody : '';

  const shortNameForRouteId = (routeId: string): string | null => {
    const route = screenSelectionRoutes.nodes.find((r) => r.gtfsId === routeId);
    return (route && route.shortName) || (route && route.longName) || null;
  };

  // Reconstruct our internal representation of screen targeting from what's in the db.
  // This targeting is irrelevant if we’re dealing with the bus feed.
  const previousScreenMessagesTargeting =
    previousScreenMessages && !isBusFeed
      ? previousScreenMessages.map((v) =>
          screenTargetsFromEventTargeting(
            v.targeting,
            shortNameForRouteId,
            feedId,
            screenSelectionRoutes,
          ),
        )
      : [
          [
            {
              routeId: '',
              options: [],
              layouts: getAlertDefaultScreenTargetTypes(feedId),
              tags: undefined,
            },
          ],
        ];

  const initialWebTargetedRoutes = entitySelectorsToRouteMentions(
    entitySelectors,
    allRoutes,
  );

  const initialScreens = previousScreenMessages
    ? previousScreenMessages.map((v, i) => {
        return {
          [SCREEN_BODY_NAME]: undefined,
          [SCREEN_TARGETING_NAME]: previousScreenMessagesTargeting[i],
          [WEIGHT_FREQUENCY_NAME]: {
            priority: v.priority ?? ALERTS_DEFAULT_DISP_FREQUENCY.priority,
            weight: v.weight ?? ALERTS_DEFAULT_DISP_FREQUENCY.weight,
          },
          [INITIAL_SCREEN_CHOICE_NAME]: v.targeting.some(({ layouts }) =>
            layouts.includes(TargetType.DUP),
          )
            ? AlertScreenTargeting.SIDEWALK_SCREEN
            : AlertScreenTargeting.STATION_SCREEN,
          [SCREEN_UPDATE_METHOD_NAME]: ScreenUpdateMethods.KEEP,
          [SCREEN_TARGETING_EDITED]: false,
          [SCREEN_TITLE_NAME]: v.title ?? undefined,
          [SCREEN_IS_CLEARING_NAME]: v.isClearing ?? false,
        };
      })
    : [
        {
          [SCREEN_BODY_NAME]: undefined,
          [SCREEN_TARGETING_NAME]: [
            {
              routeId: '',
              options: [],
              layouts: getAlertDefaultScreenTargetTypes(feedId),
            },
          ],
          [WEIGHT_FREQUENCY_NAME]: {
            priority: ALERTS_DEFAULT_DISP_FREQUENCY.priority,
            weight: ALERTS_DEFAULT_DISP_FREQUENCY.weight,
          },
          [INITIAL_SCREEN_CHOICE_NAME]: undefined,
          [SCREEN_UPDATE_METHOD_NAME]: ScreenUpdateMethods.KEEP,
          [SCREEN_TARGETING_EDITED]: false,
        },
      ];

  const formMethods = useForm<ComposeFormData>({
    mode: 'onBlur',
    defaultValues: {
      [EMAIL_TYPES_NAME]: [EmailMessageTypeCodes.RTSCH],
      [WEB_IMPACTS_NAME]: prevMessageImpacts,
      [WEB_TARGETED_ROUTES_NAME]: initialWebTargetedRoutes,
      [WEB_TARGETED_STOPS_NAME]: [],
      [TWEET_CUSTOMIZED_NAME]: false,
      [SCREEN_TARGETED_ROUTES_NAME]: [],
      [SCREENS_NAME]: initialScreens as any,
      [AFFECTED_STOPS_NAME]: getDefaultAffectedStations(prevAffectedStations),
      // We only want to show the previous endAt date if the event was a draft
      [WEB_EXPIRATION_NAME]:
        prevIsDraft && prevEndAt ? new Date(prevEndAt) : undefined,
    },
  });

  const { watch, setValue } = formMethods;
  const screens = watch(SCREENS_NAME);

  const hasNoOutlets =
    !showTwitter && !showScreens && !showWebAndApps && !showEmailAndSms;
  const isDirty = formMethods.formState.dirty;
  if (surfaceErrors && Object.keys(formMethods.errors).length > 0) {
    scrollToErrors(formMethods.errors);
    setSurfaceErrors(false);
  }

  const {
    isSubmitting,
    onSubmit,
    isDraft,
    error: creationError,
    clearError: clearCreationError,
  } = useMessageCreation<ComposeFormData>({
    updatingEvent: event,
    onComplete: () => history.push(eventUrl),
    enabledOutlets: {
      twitter: showTwitter,
      screens: showScreens,
      email: showEmailAndSms,
      apps: showWebAndApps,
    },
  });

  const title = prevIncludeApps ? 'Update Web & Apps' : 'Add Web & Apps';
  const busTitle = prevIncludeApps
    ? 'Update Web, Apps, & Screens'
    : 'Add Web, Apps, & Screens';

  const routesWithStops = useRoutesWithStops(feedId);

  // Used for storing the alerts message and additional info text
  // to be concatinated into a single tweet. First empty string is
  // for the message and the second is for the additional info
  const autofilledTweetsText = React.useRef<[string, string]>(['', '']);

  const onScreenDelete = (index: number): void => {
    setValue(SCREENS_NAME, [
      ...screens.slice(0, index),
      ...screens.slice(index + 1),
    ]);
    setPreviousScreenMessages([
      ...previousScreenMessages.slice(0, index),
      ...previousScreenMessages.slice(index + 1),
    ]);
  };

  return (
    <S.UpdateContainer>
      <PageMeta title="Update Alert" />
      <Prompt
        when={isDirty && !isConfirmationModalOpen && !isSubmitting}
        message={DISCARD_CHANGES_MESSAGE}
      />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}${prevIsDraft ? '/alerts/draft' : ''}`,
          title: `Back to${prevIsDraft ? ' Draft' : ''} Alerts`,
        }}
      />
      <S.PreviousMessageContainer>
        <UpdateHeading {...previousMessage} />
        <UpdateMessageCard {...previousMessage} prevIsDraft={prevIsDraft} />
      </S.PreviousMessageContainer>
      <S.Form.Wrapper>
        <FormContext {...formMethods}>
          <form
            onSubmit={async (submitEvent) => {
              submitEvent.preventDefault();
              const isEventActive = event.status === EventStatus.CURRENT;
              const saveAsDraft = checkIfDraft(submitEvent);

              if (isEventActive && saveAsDraft) {
                // Validation is triggered manually because the form isn't submitted
                // until the confirmation modal is closed. This is to prevent the modal
                // from opening if there are validation errors.
                if (!(await formMethods.triggerValidation())) {
                  return;
                }

                setFormConfirmationOptions({
                  isConfirmationModalOpen: true,
                  modalConfirmation: Confirmation.SAVE_LIVE_ALERT_AS_DRAFT,
                  submitEvent,
                });
                return;
              }

              formMethods.handleSubmit((data) => onSubmit(data, saveAsDraft))(
                submitEvent,
              );
            }}
          >
            <S.Form.Content>
              <AlertMessageLeader
                additionalMessageName={WEB_BODY_ADDITIONAL_NAME}
                messageName={WEB_BODY_NAME}
                impactsName={WEB_IMPACTS_NAME}
                targetedRoutesName={WEB_TARGETED_ROUTES_NAME}
                targetedStopsName={WEB_TARGETED_STOPS_NAME}
                affectedStopsEditedName={AFFECTED_STOPS_EDITED_NAME}
                affectedStopsName={AFFECTED_STOPS_NAME}
                onMessageChange={(state) => {
                  if (!formMethods.getValues()[TWEET_CUSTOMIZED_NAME]) {
                    autofilledTweetsText.current = [
                      formatMessageDoc(state.doc, {
                        replacements: LONG_GTFS_TEXT_REPLACEMENTS,
                        bracketCustomIcons: true,
                        feedId,
                      }),
                      autofilledTweetsText.current[1],
                    ];

                    setTweetText(
                      0,
                      autofilledTweetsText.current
                        .filter(Boolean)
                        .join(TWEETS_JOIN_SEPERATOR),
                    );
                  }
                }}
                onAdditionalMessageChange={(state) => {
                  if (!formMethods.getValues()[TWEET_CUSTOMIZED_NAME]) {
                    autofilledTweetsText.current = [
                      autofilledTweetsText.current[0],
                      formatMessageDoc(state.doc, {
                        bracketCustomIcons: true,
                        replacements: LONG_GTFS_TEXT_REPLACEMENTS,
                        feedId,
                      }),
                    ];

                    setTweetText(
                      0,
                      autofilledTweetsText.current
                        .filter(Boolean)
                        .join(TWEETS_JOIN_SEPERATOR),
                    );
                  }
                }}
                onAdditionalMessageToggle={(enabled) => {
                  if (
                    !enabled &&
                    !formMethods.getValues()[TWEET_CUSTOMIZED_NAME]
                  ) {
                    autofilledTweetsText.current = [
                      autofilledTweetsText.current[0],
                      '',
                    ];

                    setTweetText(0, autofilledTweetsText.current[0]);
                  }
                }}
                onMessageCopy={() => {
                  setMessageHtmlToCopy(prevMessageBody.html);
                  setAdditionalMessageHtmlToCopy(
                    prevMessageAdditionalInfo?.html || '',
                  );
                }}
                initMessageHtml={messageHtmlToCopy}
                initAdditionalMessageHtml={additionalMessageHtmlToCopy}
                hasNoOutlets={hasNoOutlets}
                shouldAutoPopulateTargeting={false}
              />
              <HR />
              <AlertWebAndApps
                title={isBusFeed ? busTitle : title}
                helpText={
                  !showWebAndApps && prevIncludeApps && !prevIsDraft
                    ? 'Your previous message will remain active.'
                    : undefined
                }
                additionalMessageName={WEB_BODY_ADDITIONAL_NAME}
                expirationName={WEB_EXPIRATION_NAME}
                messageName={WEB_BODY_NAME}
                show={showWebAndApps}
                onToggle={(enabled) => setShowWebAndApps(enabled)}
              />
              <HR />
              <AlertEmailAndSubject
                title={
                  prevEmailSubject ? 'Update Email & SMS' : 'Add Email & SMS'
                }
                additionalMessageName={WEB_BODY_ADDITIONAL_NAME}
                emailBodyName={EMAIL_BODY_NAME}
                messageName={WEB_BODY_NAME}
                smsName={SMS_BODY_NAME}
                subjectName={EMAIL_SUBJECT_NAME}
                webImpactsName={WEB_IMPACTS_NAME}
                prevEmailText={prevEmailText || ''}
                prevEmailSubject={prevEmailSubject || ''}
                prevSmsBody={prevSmsBody}
                prevMessageImpacts={prevMessageImpacts}
                show={showEmailAndSms}
                onToggle={(enabled) =>
                  !isOutfrontFeed && setShowEmailAndSms(enabled)
                }
              />
              <HR />
              {!isBridgeTunnelFeed && (
                <UpdateTweet
                  feedId={feedId}
                  show={showTwitter}
                  onToggle={() =>
                    !isOutfrontFeed &&
                    setShowTwitter((prevShowTwitter) => !prevShowTwitter)
                  }
                  previousTweets={previousTweets}
                  originalTweet={originalTweet}
                  tweets={tweets}
                  setText={setTweetText}
                  setAttachments={setAttachments}
                  setQuoteId={setQuoteId}
                  setReplyId={setReplyId}
                  addTweet={addTweet}
                  removeTweet={removeTweet}
                  prevIsDraft={prevIsDraft}
                />
              )}
            </S.Form.Content>
            <S.Form.Content>
              {showScreenTargeting && isTrain(feedId) && (
                <ToggleSection
                  id="update-screens"
                  enabled={showScreens}
                  title={
                    previousScreenMessages[0] ? 'Update Screens' : 'Add Screens'
                  }
                  helpText={
                    !showScreens && previousScreenMessages[0] && !prevIsDraft
                      ? 'Your previous message will remain active.'
                      : undefined
                  }
                  internalCss={{
                    marginLeft: '40px',
                    marginTop: '30px',
                    marginBottom: '30px',
                  }}
                  onToggle={() => {
                    if (!screensHaveBeenToggled) {
                      setScreensHaveBeenToggled(true);

                      const impacts = formMethods.getValues()[WEB_IMPACTS_NAME];
                      const affectedStops =
                        formMethods.getValues()[AFFECTED_STOPS_NAME];

                      const autoScreenTargeting =
                        getScreenTargetsForAutoScreenTargeting(
                          impacts,
                          feedId,
                          routesWithStops,
                          affectedStops,
                        );

                      if (screens.length) {
                        formMethods.setValue(
                          SCREENS_NAME,
                          modifyIndexInArray({
                            index: 0,
                            value: {
                              ...screens[0],
                              [SCREEN_TARGETING_NAME]: autoScreenTargeting,
                            },
                            array: screens,
                          }),
                        );
                      }
                    }

                    setShowScreens((prevShowScreens) => !prevShowScreens);
                  }}
                >
                  {previousScreenMessages.map((v, i) => {
                    const key = `updatescreens-${i}-${v.publishAt}`;
                    return (
                      <S.Form.Row
                        key={key}
                        css={css`
                          flex-direction: column;
                        `}
                      >
                        <UpdateScreens
                          show={showScreens}
                          previousScreenTargeting={
                            previousScreenMessagesTargeting[i]
                          }
                          previousMessageType={previousMessageType}
                          previousScreenMessage={previousScreenMessages[i]}
                          previousMessageImpacts={prevMessageImpacts}
                          previousAuthorName={
                            previousMessage?.author?.name || ''
                          }
                          index={i}
                          onDelete={() => onScreenDelete(i)}
                          isClearing={v.isClearing}
                        />
                      </S.Form.Row>
                    );
                  })}
                  <HR />
                  <ScreensButtons screensArray={previousScreenMessages} />
                </ToggleSection>
              )}
            </S.Form.Content>
            <S.Form.Content>
              <S.Form.Row>
                <InternalLog
                  createdAt={createdAt}
                  neededBy={neededBy}
                  incidentStartAt={incidentStartAt}
                  incidentEndAt={incidentEndAt}
                  notifiedAt={notifiedAt}
                  notes={notes || ''}
                  onClick={() => setShowInternalLog(!showInternalLog)}
                  isOpen={showInternalLog}
                />
              </S.Form.Row>
            </S.Form.Content>
            <S.Form.ButtonContainer>
              <AlertFormButtons
                isSubmittingDraftAlert={isSubmitting && isDraft}
                isSubmittingActiveAlert={isSubmitting && !isDraft}
                onCancel={() => {
                  setFormConfirmationOptions({
                    isConfirmationModalOpen: true,
                    modalConfirmation: Confirmation.DISCARD_CHANGES,
                  });
                }}
                onSubmit={() => setSurfaceErrors(true)}
              />
            </S.Form.ButtonContainer>
          </form>
        </FormContext>
      </S.Form.Wrapper>
      <ConfirmModal
        isOpen={isConfirmationModalOpen}
        title={
          modalConfirmation === Confirmation.DISCARD_CHANGES
            ? CANCEL_UPDATE_TITLE
            : SAVE_LIVE_ALERT_AS_DRAFT_TITLE
        }
        message={
          modalConfirmation === Confirmation.DISCARD_CHANGES
            ? DISCARD_CHANGES_MESSAGE
            : SAVE_LIVE_ALERT_AS_DRAFT_MESSAGE
        }
        onDismiss={() =>
          setFormConfirmationOptions({ isConfirmationModalOpen: false })
        }
        onConfirm={() => {
          setFormConfirmationOptions({ isConfirmationModalOpen: false });
          if (modalConfirmation === Confirmation.DISCARD_CHANGES) {
            history.push(eventUrl);
            return;
          }
          if (formSubmitEvent) {
            formMethods.handleSubmit((data) => onSubmit(data, true))(
              formSubmitEvent,
            );
          }
        }}
        confirmText={
          modalConfirmation === Confirmation.DISCARD_CHANGES
            ? 'Confirm'
            : 'Save as draft'
        }
      />
      <ErrorModal
        isOpen={!!creationError}
        title="Message Failed to Post"
        onDismiss={() => clearCreationError()}
        message="Sorry, something went wrong while attempting to post this message."
        includeAssistanceMessage
      />
    </S.UpdateContainer>
  );
};

export default PreviousEventWrapper;
