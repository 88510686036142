/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { Collapse } from 'react-collapse';

import { useStale } from '../../contexts/Stale';
import { ThemeType } from '../../theme';

const StaleBanner: React.FC<{
  navOpen?: boolean;
  children?: React.ReactNode;
}> = ({ navOpen, children }) => {
  const { showIsStale } = useStale();
  const leftPadding = navOpen ? 258 : 72;

  return (
    <React.Fragment>
      <Collapse isOpened={showIsStale}>
        <div
          aria-hidden={!showIsStale}
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          css={css`
            display: flex;
            justify-content: center;
            padding: 18px;
            padding-left: ${leftPadding}px;
            transition: 0.3s all ease;
            width: 100%;
            background-color: #e8d3ff;

            & > div {
              margin-left: 12px;
            }

            & > div:first-of-type {
              margin-left: 0px;
            }
          `}
        >
          <div
            css={(theme: ThemeType) => css`
              font-weight: ${theme.typography.weights.bold};
            `}
          >
            New Version Available
          </div>
          <div>
            There is a new version of Mercury available. Please refresh the page
            to get the latest update!
          </div>
          <div>
            <a
              tabIndex={showIsStale ? 0 : -1}
              href={window.location.href}
              css={css`
                text-decoration: underline;
              `}
            >
              Refresh
            </a>
          </div>
        </div>
      </Collapse>
      <div
        css={css`
          position: relative;
          height: 100%;
          width: 100%;
        `}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default StaleBanner;
