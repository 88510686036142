/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import { ApolloError, ServerError } from '@apollo/client';
import Button from '../common/Button';
import { ReactComponent as CrocImage } from '../../images/croc.svg';
import Heading from '../common/Heading';
import { ThemeType } from '../../theme';

const { REACT_APP_REPORT_ISSUE_URL } = process.env;

type Props = { children?: ReactNode };
type State = { error: Error | null };

const getErrorMessages = (error: Error): string[] => {
  const errorMessages: string[] = [];

  if ('networkError' in error) {
    const networkError = (error as ApolloError).networkError as ServerError;
    const errors = networkError?.result;
    if (errors && Object.keys(errors).length > 0) {
      errorMessages.push(JSON.stringify(errors));
    }
  }

  if (error.message && error.message.length > 0) {
    errorMessages.push(JSON.stringify(error.message));
  }

  return errorMessages;
};

const ErrorPage: React.FC<{
  error: Error;
  children?: React.ReactNode;
}> = ({ error }) => {
  const errorMessageEls = getErrorMessages(error).map((message, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={idx}>{message}</div>
  ));

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20vh;
        list-style: none;
        details summary::-webkit-details-marker {
          display: none;
        }
      `}
    >
      <CrocImage width={96} height={92} />
      <Heading
        css={(theme: ThemeType) => css`
          margin-top: ${theme.spacing.xsmall};
          letter-spacing: 0.39px;
        `}
        level={1}
        size="xxlarge"
      >
        Something went wrong.
      </Heading>
      <p
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.medium};
          font-family: ${theme.typography.families.secondary};
          letter-spacing: 0.25px;
          color: ${theme.colors.dark2};
          margin-top: ${theme.spacing.xxsmall};
          margin-bottom: ${theme.spacing.medium};
        `}
      >
        Mercury is currently experiencing technical difficulties.
      </p>
      <a
        css={css`
          text-decoration: none;
        `}
        href={REACT_APP_REPORT_ISSUE_URL}
      >
        <Button
          css={css`
            min-width: 215px;
          `}
          as="div"
          primary
        >
          Report Issue
        </Button>
      </a>
      <details>
        <summary
          css={css`
            list-style: none;
            outline: none;
          `}
        >
          <Button
            css={css`
              cursor: pointer;
              text-align: center;
              margin-top: 20px;
            `}
            plain
            as="div"
            size="small"
          >
            <span
              css={css`
                text-transform: lowercase;
              `}
            >
              ⓘ
            </span>{' '}
            VIEW TECHNICAL DETAILS
          </Button>
        </summary>
        <div
          css={(theme: ThemeType) => css`
            ${theme.typography.sizes.xsmall};
            font-family: ${theme.typography.families.primary};
            color: ${theme.colors.dark2};
            margin-top: 8px;
            background-color: ${theme.colors.light1};
            border-radius: 7px;
            border: 1px solid #d8d8d8;
            padding: 14px 16px;
          `}
        >
          {errorMessageEls}
        </div>
      </details>
    </div>
  );
};

export default class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: any): void {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render(): JSX.Element {
    const { children } = this.props;
    const { error } = this.state;

    return (
      <React.Fragment>
        {error ? <ErrorPage error={error} /> : children}
      </React.Fragment>
    );
  }
}
