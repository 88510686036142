/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding: 0 ${theme.spacing.small};
      flex: 0 0 150px;
    `}
    {...props}
  />
);

export const Item: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      align-items: center;
      display: flex;
      flex-direction: row;
      font-family: ${theme.typography.families.primary};
      color: ${theme.colors.brand};
      margin-bottom: ${theme.spacing.xsmall};
    `}
    {...props}
  />
);
