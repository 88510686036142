import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';

import { useFeedId } from 'contexts/FeedId';
import { CommonUploadFragment } from 'generated/CommonUpload.fragment';
import { TransitDestination } from 'generated/global-types';
import { assetToZip } from 'utils/outfront-asset-factory';
import { normalizeMimeType } from 'utils/uppy';
import { CreateUpload, CreateUploadVariables } from '../generated/CreateUpload';

import { getFileDimensions } from '../utils/assets-helpers';

export interface TransitSettings {
  transitDestination?: TransitDestination;
  shouldZip?: boolean;
}

const CreateUploadMutation = loader('../graphql/CreateUpload.gql');

type UploadOptions = {
  defaultUpload?: CommonUploadFragment | null;
  transitSettings?: TransitSettings;
};

export const useUpload = (uploadOptions?: UploadOptions | null) => {
  const {
    defaultUpload,
    transitSettings: { transitDestination } = {
      transitDestination: undefined,
    },
  } = uploadOptions ?? {};

  const [file, setFile] = useState<null | File>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [variables, setVariables] = useState<null | CreateUploadVariables>(
    null,
  );
  const [zippedFileUrl, setZippedFileUrl] = useState('');
  const [progress, setProgress] = useState(0);
  const [preview, setPreview] = useState<null | string>(null);
  const [upload, setUpload] = useState<null | CommonUploadFragment>(
    defaultUpload ?? null,
  );

  // Skip zipping file if it's already zipped
  const shouldZip =
    uploadOptions?.transitSettings?.shouldZip &&
    file?.type !== 'application/zip';

  const feedId = useFeedId();

  const [createUpload, { data, loading, error: mutationError }] = useMutation<
    CreateUpload,
    CreateUploadVariables
  >(CreateUploadMutation);

  const { current: uppy } = useRef(
    Uppy({
      restrictions: { maxNumberOfFiles: 2 },
    }).use(AwsS3Multipart, {
      limit: 4,
      companionUrl: process.env.REACT_APP_UPPY_COMPANION_URL,
    }),
  );

  useEffect(() => {
    let fileId: null | string = null;
    let zippedFileId: null | string = null;
    if (file) {
      const uploadFiles = async () => {
        setUploading(true);
        if (/^(?:image|video)\/.*$/.test(file.type)) {
          setPreview(URL.createObjectURL(file));
        }
        fileId = uppy.addFile({
          name: file.name,
          type: normalizeMimeType(file.type),
          data: file,
          source: 'Local',
          isRemote: false,
        }) as unknown as string;

        if (shouldZip) {
          // Convert file to Buffer to be ingested in the adm-zip library
          // then convert the zip file buffer back to a File type to be uploaded using Uppy
          const zippedFileName = `${file.name}.zip`;
          const zippedFileBuffer = await assetToZip(file.name, file);
          const zippedFileBlob = new Blob([zippedFileBuffer]);
          const zippedFile = new File([zippedFileBlob], zippedFileName);

          zippedFileId = uppy.addFile({
            name: zippedFileName,
            type: 'application/zip',
            data: zippedFile,
            source: 'Local',
            isRemote: false,
          }) as unknown as string;
        }

        uppy.upload();
      };

      uploadFiles();
    }
    return () => {
      const uploadComplete = !!uppy.getState().totalProgress;

      if (fileId && uploadComplete) {
        uppy.removeFile(fileId);
      }

      if (shouldZip && zippedFileId && uploadComplete) {
        uppy.removeFile(zippedFileId);
      }

      setError(null);
      setVariables(null);
      setZippedFileUrl('');
      setUpload(null);
      setProgress(0);
    };
  }, [
    file,
    uppy,
    shouldZip,
    setUploading,
    setPreview,
    setError,
    setProgress,
    setVariables,
    setUpload,
  ]);

  useEffect(() => {
    const handleProgress = (progress: number) => {
      setUploading(true);
      setProgress(progress);
    };

    const handleError = (file: Uppy.UppyFile, error: any) => {
      setUploading(false);
      setProgress(0);
      setError(error);
      uppy.reset();
    };

    const handleComplete = async (
      {
        size,
        type: mimeType = 'application/octet-stream',
        extension,
      }: Uppy.UppyFile,
      { uploadURL: s3Url }: { uploadURL: string },
    ) => {
      // This function is triggered for each successfully uploaded file, we store the result of each
      // uploaded file by itself and consolidate the data when we want to create the upload row
      if (extension === 'zip' && shouldZip) {
        setZippedFileUrl(s3Url);
      } else {
        const dimensions = await getFileDimensions(file);
        setVariables({
          originalFilename: file?.name,
          s3Url,
          size,
          feedId,
          mimeType: normalizeMimeType(mimeType),
          width: dimensions.width,
          height: dimensions.height,
        });
      }
    };
    const handleResetOnComplete = () => {
      setProgress(100);
      uppy.reset();
    };

    uppy.on('progress', handleProgress);
    uppy.on('upload-success', handleComplete);
    uppy.on('upload-error', handleError);
    uppy.on('complete', handleResetOnComplete);

    return () => {
      uppy.off('progress', handleProgress);
      uppy.off('upload-success', handleComplete);
      uppy.off('upload-error', handleError);
      uppy.off('complete', handleResetOnComplete);
    };
  }, [
    uppy,
    file,
    uploadOptions,
    shouldZip,
    setProgress,
    setUploading,
    setError,
    setVariables,
    feedId,
  ]);

  useEffect(() => {
    if (variables && (!shouldZip || (shouldZip && zippedFileUrl))) {
      createUpload({
        variables: {
          ...variables,
          transitSettings: {
            ...(transitDestination
              ? {
                  transitDestination,
                }
              : {}),
            ...(shouldZip
              ? {
                  zippedMediaS3Url: zippedFileUrl,
                }
              : {}),
          },
        },
      });
    }
  }, [variables, zippedFileUrl, createUpload, shouldZip, transitDestination]);

  useEffect(() => {
    if (data?.createUpload?.upload) {
      setUploading(false);
      setProgress(0);
    }
  }, [setUploading, setProgress, data]);

  useEffect(() => {
    if (data?.createUpload?.upload) {
      setUpload(data?.createUpload?.upload);
    }
  }, [data]);

  const startUpload = useCallback(
    (newFile) => {
      setFile(newFile);
      setUpload(null);
    },
    [setFile, setUpload],
  );

  return {
    uploading: uploading || loading,
    error: error || mutationError,
    startUpload,
    progress,
    preview,
    upload,
  };
};

export type UploadType = CommonUploadFragment;
