/** @jsxImportSource @emotion/react */

import React from 'react';
import MultiRouteStopScreenSelector, {
  getAllowedTargetTypes,
  IScreenSelector,
  pwDefaultTargetTypes,
} from 'components/common/form-elements/multi-route-stop-screen-selector';
import SidewalkScreenSelector from 'components/common/campaign-form/sidewalk-screens-multiroute-selector';
import { getGroupedTargetingTypes } from 'utils/screen-targeting';
import { useFeedId } from 'contexts/FeedId';
import {
  TargetType,
  ContextualSelectorInput as ContextualSelector,
} from '../../../generated/global-types';

export interface IScreenSelectorContextualSelector {
  contextualSelectors: ContextualSelector[];
  targetTypes: TargetType[];
}

const FALLBACK_VALUE = {
  selector: {
    routes: [],
    stops: [],
  },
  targetTypes: [],
  tags: {},
};

const ScreensSelector: React.FC<
  {
    onChange: (updated: IScreenSelector[]) => void;
    value: IScreenSelector[];
    autofillScreenTargeting?: boolean;
    isEditing?: boolean;
  } & { children?: React.ReactNode }
> = ({ value, onChange, autofillScreenTargeting = true, isEditing }) => {
  const feedId = useFeedId();

  const allowedTargetTypes = getGroupedTargetingTypes({
    feedId,
    updated: getAllowedTargetTypes(feedId),
  });

  const checkIfAllSelected = ({ isDup = false }) =>
    value.every(
      ({ selector: { routes, stops }, targetTypes, tags }) =>
        (isDup || allowedTargetTypes.every((tt) => targetTypes.includes(tt))) &&
        routes.length === 1 &&
        !routes[0].hasAllStopsUnselected &&
        !stops.length &&
        tags &&
        !Object.keys(tags).length,
    );

  const noLinesSelected =
    !value.length || value.every(({ selector: { routes } }) => !routes.length);

  const handleAllAction = ({
    action,
    isDup = false,
  }: {
    action: 'select' | 'unselect';
    isDup?: boolean;
  }) => {
    const shouldSelect = action === 'select';
    const hasAllStopsUnselected = !shouldSelect;
    const targetTypes = (() => {
      if (isDup) {
        return [TargetType.DUP];
      }
      if (shouldSelect) {
        return allowedTargetTypes;
      }
      return [];
    })();
    onChange(
      value.map((s) => {
        const {
          selector: { routes },
        } = s;
        if (!routes.length) {
          return s;
        }
        return {
          selector: {
            routes: [
              {
                ...routes[0],
                hasAllStopsUnselected,
              },
            ],
            stops: [],
          },
          targetTypes,
          tags: shouldSelect ? {} : undefined,
        };
      }),
    );
  };

  const getCommonSelectAllActionProps = ({ isDup }: { isDup: boolean }) => ({
    enableSelectAllAction: true,
    areAllSelected: checkIfAllSelected({ isDup }),
    onSelectAll: () => handleAllAction({ action: 'select', isDup }),
    onUnselectAll: () => handleAllAction({ action: 'unselect', isDup }),
    noLinesSelected,
  });

  const handleChange = React.useCallback(
    (ss: IScreenSelector[]) => {
      onChange(ss);
    },
    [onChange],
  );

  const handleDUPChange = React.useCallback(
    (ss: IScreenSelector[]) => {
      const selectors = ss.map(({ selector, tags }) => {
        return {
          selector,
          tags,
          targetTypes: [TargetType.DUP],
        };
      });
      onChange(selectors);
    },
    [onChange],
  );

  const isDup =
    value.length &&
    value[0].targetTypes.length === 1 &&
    value[0].targetTypes.includes(TargetType.DUP);
  return (
    <React.Fragment>
      {!isDup ? (
        <MultiRouteStopScreenSelector
          value={
            value.length
              ? value
              : [
                  {
                    ...FALLBACK_VALUE,
                    targetTypes: pwDefaultTargetTypes(feedId),
                  },
                ]
          }
          onChange={handleChange}
          addText="Add Lines"
          autofillScreenTargeting={autofillScreenTargeting}
          isSingleRoute
          isEditing={isEditing}
          {...getCommonSelectAllActionProps({ isDup: false })}
        />
      ) : (
        <SidewalkScreenSelector
          onChange={handleDUPChange}
          value={value ?? [FALLBACK_VALUE]}
          isSingleRoute
          {...getCommonSelectAllActionProps({ isDup: true })}
        />
      )}
    </React.Fragment>
  );
};

export default ScreensSelector;
