/** @jsxImportSource @emotion/react */

import * as React from 'react';

import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import { Menu, MenuList, MenuButton } from '@reach/menu-button';
import InvalidFilenameModal from 'components/common/invalid-filename-modal';
import * as S from './blueprint.styled';
import { ThemeType } from '../../../theme';
import { useUpload, UploadType } from '../../../hooks/useUpload';
import Button from '../Button';
import ProgressBar from '../../../ui-kit/ProgressBar';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import { ReactComponent as IconNewtab } from '../../../images/icon_newtab_blue.svg';
import { ReactComponent as IconEllipsis } from '../../../images/icon_ellipsis.svg';
import { isInvalidFilename } from '../../../utils/assets-helpers';

const MAX_IMAGE_SIZE_BYTES = 10 * 10 ** 6; // 10mb
const MAX_PDF_SIZE_BYTES = 10 * 10 ** 6; // 10mb

const validateFileSize = (file: File) => {
  if (file.type.startsWith('image/')) {
    return file.size <= MAX_IMAGE_SIZE_BYTES;
  }

  if (file.type === 'application/pdf') {
    return file.size <= MAX_PDF_SIZE_BYTES;
  }

  return true;
};

const useValidatedDropzone = ({
  onDrop: handleDrop,
  onInvalidFilename: handleInvalidFilename,
}: {
  onDrop: (f: null | File) => void;
  onInvalidFilename: (filename: string) => void;
}) => {
  const onDrop = React.useCallback(
    // eslint-disable-next-line consistent-return
    async (files) => {
      const filename = files[0].name;

      if (isInvalidFilename(filename)) {
        return handleInvalidFilename(filename);
      }

      if (files[0]) {
        if (!validateFileSize(files[0])) {
          // eslint-disable-next-line no-alert
          return window.alert('File is too large');
        }

        handleDrop(files[0]);
      }
    },
    [handleDrop, handleInvalidFilename],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
  });

  return { getRootProps, getInputProps };
};

interface AwaitingFileProps {
  startUpload: (f: null | File) => void;
  onInvalidFilename: (filename: string) => void;
}
const AwaitingFile: React.FC<AwaitingFileProps> = ({
  startUpload,
  onInvalidFilename,
}) => {
  const { getRootProps, getInputProps } = useValidatedDropzone({
    onDrop: startUpload,
    onInvalidFilename,
  });
  return (
    <S.UploadBox>
      <div {...getRootProps()}>
        <input
          {...getInputProps({ id: 'file__input' })}
          css={css`
            display: none;
          `}
        />
        <button
          type="button"
          onClick={(evt) => {
            evt.preventDefault();
          }}
        >
          <S.Title>
            <PlusIcon
              css={css`
                margin-right: 3px;
              `}
            />
            Upload Station Blueprint
          </S.Title>

          <S.SubTitle
            css={css`
              margin-left: 12px;
            `}
          >
            Supported Image Files: PNG, JPEG, PDF
          </S.SubTitle>
        </button>
      </div>
    </S.UploadBox>
  );
};

const ProgressView: React.FC<{
  progress: number;
  children?: React.ReactNode;
}> = ({ progress }) => (
  <S.UploadBox>
    <S.Title lighter>Uploading...</S.Title>
    <ProgressBar progress={progress} />
  </S.UploadBox>
);

interface Props {
  existingBlueprint: String | null;
  onChange: (u: UploadType | null) => void;
}

export const BlueprintUploader: React.FC<Props> = ({
  existingBlueprint,
  onChange,
}) => {
  const { startUpload, uploading, progress, upload } = useUpload();

  const [invalidFilename, setInvalidFilename] = React.useState('');

  React.useEffect(() => {
    if (upload?.signedS3Url && upload?.signedS3Url !== existingBlueprint) {
      onChange(upload);
      startUpload(null);
    }
  }, [existingBlueprint, upload, onChange, startUpload]);

  const { getRootProps, getInputProps } = useValidatedDropzone({
    onDrop: startUpload,
    onInvalidFilename: setInvalidFilename,
  });

  const contents = (() => {
    if (uploading) {
      return <ProgressView progress={progress} />;
    }
    if (existingBlueprint) {
      return (
        <S.UploadBox
          css={css`
            display: flex;
          `}
        >
          <Button
            css={css`
              width: 205px;
            `}
            as="a"
            target="_blank"
            rel="noopener"
            href={existingBlueprint}
            size="xsmall"
          >
            Open Station Blueprint{' '}
            <IconNewtab
              css={css`
                margin-left: 8px;
              `}
            />
          </Button>

          <Menu>
            <S.UploadMenu>
              <MenuButton>
                <Button
                  as="a"
                  css={css`
                    min-width: 51px;
                  `}
                  size="xsmall"
                >
                  <IconEllipsis />
                </Button>
              </MenuButton>

              <MenuList
                css={css`
                  :focus {
                    outline: none;
                  }
                `}
              >
                <S.UploadMenuContainer>
                  <li
                    css={css`
                      margin-bottom: 16px;
                    `}
                  >
                    <button
                      type="button"
                      css={S.menuEltButtons}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        startUpload(null);
                      }}
                      {...getRootProps()}
                    >
                      <S.UploadMenuElt>
                        Replace
                        <p
                          css={(theme: ThemeType) => css`
                            ${theme.typography.sizes.small};
                            color: ${theme.colors.dark3};
                            margin: 4px 0;
                          `}
                        >
                          Supported: PNG, JPEG, PDF
                        </p>
                      </S.UploadMenuElt>
                    </button>
                    <input
                      css={css`
                        display: none;
                        visibility: hidden;
                      `}
                      {...getInputProps()}
                    />
                  </li>
                  <li>
                    <button
                      type="button"
                      css={S.menuEltButtons}
                      onClick={() => {
                        onChange(null);
                        startUpload(null);
                      }}
                    >
                      <S.UploadMenuElt>Delete</S.UploadMenuElt>
                    </button>
                  </li>
                </S.UploadMenuContainer>
              </MenuList>
            </S.UploadMenu>
          </Menu>
        </S.UploadBox>
      );
    }
    return (
      <AwaitingFile
        startUpload={startUpload}
        onInvalidFilename={setInvalidFilename}
      />
    );
  })();

  return (
    <React.Fragment>
      {contents}
      <InvalidFilenameModal
        isOpen={!!invalidFilename}
        onDismiss={() => setInvalidFilename('')}
        filename={invalidFilename}
      />
    </React.Fragment>
  );
};
