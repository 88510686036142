/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { ThemeType } from '../../theme';

interface RadioButtonProps {
  name: string;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  className?: string;
  id?: string;
  size?: number;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  checked,
  onChange,
  className,
  id,
  size = 15,
}) => (
  <input
    className={className}
    id={id}
    type="radio"
    name={name}
    checked={checked}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.checked)
    }
    css={(theme: ThemeType) => css`
      appearance: none;
      width: ${size}px;
      height: ${size}px;
      display: inline-block;
      position: relative;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid ${theme.colors.accent1};
      background-color: ${theme.colors.white};
      transition: ease all 0.3s;
      outline: none;

      &:checked:after {
        content: ' ';
        display: block;
        position: absolute;
        left: ${(size * 0.6) / 4}px;
        top: ${(size * 0.6) / 4}px;
        border-radius: 50%;
        width: ${size * 0.6}px;
        height: ${size * 0.6}px;
        background-color: ${theme.colors.accent1};
      }
    `}
  />
);

export default RadioButton;
