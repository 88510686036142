/** @jsxImportSource @emotion/react */

import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import { css } from '@emotion/react';

import { container } from '../../common/styles';
import { ThemeType } from '../../../theme';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <div css={container} {...props} />;

export const PageContent: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={(theme: ThemeType) => css`
      display: flex;
      padding: ${theme.spacing.large};
      height: 100%;
      flex-direction: column;
    `}
    {...props}
  />
);

export const textAlignCenter = css`
  text-align: center;
  text-transform: capitalize;
`;

export const ActiveButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = (props) => (
  <button
    type="button"
    css={(theme: ThemeType) => css`
      color: ${theme.colors.blue};
      text-transform: uppercase;
      font-weight: ${theme.typography.weights.bold};
      ${theme.typography.sizes.xsmall};

      &:disabled {
        cursor: default;
        opacity: 0.3;
      }
    `}
    {...props}
  />
);

export const DeactivatedButton: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <span
    css={(theme: ThemeType) => css`
      color: ${theme.colors.dark2};
      text-transform: uppercase;
      font-weight: ${theme.typography.weights.bold};
      opacity: 0.5;

      ${theme.typography.sizes.xsmall};
    `}
    {...props}
  />
);
