import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import {
  AllRoutesForStopsWithIds,
  AllRoutesForStopsWithIdsVariables,
} from '../generated/AllRoutesForStopsWithIds';

const AllRoutesForStopsWithIdsQuery = loader(
  '../graphql/AllRoutesForStopsWithIds.gql',
);

export enum Mode {
  intersection = 'intersection',
  union = 'union',
}

const union = (a: Set<string>, b: Set<string>): Set<string> => {
  const result: Set<string> = new Set(a);
  for (const el of Array.from(b)) {
    result.add(el);
  }
  return result;
};
const intersection = (a: Set<string>, b: Set<string>): Set<string> => {
  const result: Set<string> = new Set();
  for (const el of Array.from(b)) {
    if (a.has(el)) {
      result.add(el);
    }
  }
  return result;
};

const useRoutesForStops = (
  stops: string[],
  { mode = Mode.intersection }: { mode?: Mode } = {},
): string[] => {
  const query = useQuery<
    AllRoutesForStopsWithIds,
    AllRoutesForStopsWithIdsVariables
  >(AllRoutesForStopsWithIdsQuery, {
    skip: stops.length === 0,
    variables: { stopIds: stops },
  });

  if (stops.length === 0) {
    return [];
  }
  if (!query?.data?.stops?.nodes) {
    return [];
  }

  return Array.from(
    query.data.stops.nodes
      .map((s) => new Set(s.gtfsRouteIds) as Set<string>)
      .reduce(mode === Mode.intersection ? intersection : union),
  ).sort();
};

export default useRoutesForStops;
