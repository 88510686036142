import { css } from '@emotion/react';
import theme from '../../../../theme';

export const modalP = css`
  ${theme.typography.sizes.small};
  margin-top: 0;
  line-height: 1.2857142857;
`;

export const modalUl = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const modalLi = css`
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 20px;
  margin-bottom: 0.75rem;
`;

export const modalLabel = css`
  margin-left: 0.75rem;
`;

export const modalButtonContainer = css`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-left: 1rem;
  }
`;
