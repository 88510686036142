/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import type { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import alertIconSrc from 'images/icon_alert.svg';

import { ThemeType } from '../../../theme';
import { flexRow, flexCol } from '../styles';

export const Row: React.FC<any> = (props) => (
  <div
    css={(theme: ThemeType) => [
      flexRow,
      css`
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid ${theme.colors['border-dark']};
        background-color: #eaeaea;
      `,
    ]}
    {...props}
  />
);

export const SelectCol: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={[
      flexCol,
      css`
        flex: 0 1 100%;
        width: 100%;
        border-radius: 4px;
        background-color: white;
      `,
    ]}
    {...props}
  />
);

export const RemoveButtonBox: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      border-left: 1px solid ${theme.colors['border-dark']};
      color: red;
      padding: 16px 12px;
      text-align: center;
      flex: 0 0 36px;
    `}
    {...props}
  />
);

export const RemoveButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = (props) => (
  <button type="button" {...props} />
);

export const SelectContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      border-top: 1px solid ${theme.colors.border};

      .screen-selector__menu {
        border-top: 1px solid white;
        box-shadow:
          0 -1px 0 0 #fff,
          0 0 0 1px #aaa;
      }

      .screen-selector__group-heading {
        padding-left: 20px;
        color: #001840;
        text-transform: uppercase;
      }

      &:first-of-type {
        border-top: none;
      }
    `}
    {...props}
  />
);

export const AddScreens: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = (props) => (
  <button
    type="button"
    css={(theme: ThemeType) => css`
      text-transform: uppercase;
      color: ${theme.colors.accent1};
      font-family: ${theme.typography.families.primary};
      ${theme.typography.sizes.xsmall};
      font-weight: ${theme.typography.weights.bolder};
      cursor: pointer;

      outline: none;
      border-radius: 4px;
      &:focus-visible {
        box-shadow: 0 0 0 1px ${theme.colors.accent3};
      }
    `}
    {...props}
  />
);

export const Warning: React.FC<{
  title: string;
  message: string;
  children?: React.ReactNode;
}> = ({ title, message, children }) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px 20px;
      background-color: ${theme.colors['status-warning']};
      align-items: baseline;
      border-radius: 4px;
      margin-bottom: 15px;

      img {
        height: 13px;
        width: 16px;
        margin: auto;
      }
      .imgContainer {
        background-color: #ffcf00;
        display: inline-block;
        text-align: center;
        width: 25px;
        border-radius: 100%;
      }
      ul {
        padding-left: 20px;
      }
    `}
  >
    <div>
      <p>
        <span className="imgContainer">
          <img src={alertIconSrc} alt="alert icon" />
        </span>
        <b>{title}</b>
      </p>
      <p>{message}</p>
      {children}
    </div>
  </div>
);
