/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Heading from './Heading';
import { ReactComponent as QuestionIcon } from '../../images/question-mark.svg';

export const NotFound: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <div>
        <QuestionIcon role="presentation" aria-hidden="true" />
      </div>
      <div
        css={css`
          margin-left: 24px;
        `}
      >
        <Heading level={1} size="large">
          404 Error
        </Heading>
        <p
          css={css`
            margin: 0px;
          `}
        >
          Sorry, this page could not be found. Get back on track with any of the
          links on the left.
        </p>
      </div>
    </div>
  );
};

const NotFoundPage: React.FC<unknown & { children?: React.ReactNode }> = () => {
  return (
    <div
      css={css`
        margin: 64px;
      `}
    >
      <NotFound />
    </div>
  );
};

export default NotFoundPage;
