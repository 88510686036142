/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import pluralize from 'pluralize';
import {
  differenceInMinutes,
  differenceInHours,
  setHours,
  setMinutes,
} from 'date-fns';
import formatDate from 'date-fns/format';
import Datepicker from 'components/common/form-elements/datepicker';
import LabelledInput from 'components/common/form-elements/input-label';
import { ReactComponent as ClearIcon } from 'images/cross-blue.svg';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/time';
import theme, { ThemeType } from 'theme';
import Button from 'components/common/Button';
import {
  TIME_PATTERN_24_HRS,
  MUST_USE_24_HOUR_FORMAT,
  EXPIRY_CANNOT_BE_IN_THE_PAST,
} from 'constants/alerts';
import { insertColonToTime } from 'utils/alert-log';
import { getTimeString } from 'utils/date-helpers';
import { usePrevious } from 'hooks';

import StatusBanner from '../../status-banner';
import LiveTime from '../../LiveTime';
import Heading from '../../Heading';
import * as S from './ExpirationTimePicker.styled';

const TimeDisplay: React.FC<{
  title: string;
  displayCurrentTime?: boolean;
  value?: Date;
  disabled?: boolean;
  emptyValueText?: string;
  fromNow?: boolean;
  children?: React.ReactNode;
}> = ({
  title,
  displayCurrentTime = false,
  value,
  disabled,
  emptyValueText = 'N/A',
  fromNow = true,
}) => {
  let displayTime = '';
  if (value) {
    const diffInHours = differenceInHours(value, new Date());
    const diffInMinutes = differenceInMinutes(value, new Date());

    displayTime =
      diffInMinutes > 0
        ? `${
            diffInHours > 0 ? `${pluralize('hour', diffInHours, true)} ` : ''
          }${pluralize('min', diffInMinutes - diffInHours * 60, true)}`
        : '';
  }

  return (
    <S.TimeWrapper css={disabled && S.readOnly}>
      {/* forces time elements down to align with duration input */}
      <div />
      <div>
        <Heading
          level={6}
          size="medium"
          color="inherit"
          css={css`
            margin-bottom: 4px;
          `}
        >
          {title}
        </Heading>

        {displayCurrentTime ? ( // eslint-disable-line no-nested-ternary
          <S.Time
            css={(theme: ThemeType) => css`
              ${theme.typography.sizes.medium};
            `}
          >
            <LiveTime format={TIME_FORMAT} />
          </S.Time>
        ) : displayTime ? (
          <S.Time>
            {!value || fromNow
              ? displayTime
              : formatDate(new Date(value), DATE_FORMAT)}
          </S.Time>
        ) : (
          <S.NoTime>{emptyValueText}</S.NoTime>
        )}
      </div>
    </S.TimeWrapper>
  );
};

const overrideDateTime = (date: Date, timeString: string): Date => {
  const hoursAndMinutes = timeString.split(':');
  const withNewHours = setHours(new Date(date), Number(hoursAndMinutes[0]));
  const withNewMinutes = setMinutes(withNewHours, Number(hoursAndMinutes[1]));

  return withNewMinutes;
};

const ExpirationTimePicker: React.FC<
  {
    id: string;
    name?: string;
    onChange?: (date: Date | null) => void;
    className?: string;
    value?: Date;
    disabled?: boolean;
    shouldStack?: boolean;
  } & { children?: React.ReactNode }
> = ({ name, className, value, disabled, onChange, shouldStack, id }) => {
  const { register, watch, setValue, errors, triggerValidation, clearError } =
    useFormContext();
  const tempTimeStringRef = useRef('');
  const valueToUse = (name ? watch(name) : value) as Date | undefined;
  const [tempTimeString, setTempTimeString] = useState<string | null>(null);
  const previousValue = usePrevious(valueToUse);
  const [wasCleared, setWasCleared] = useState(false);

  // Resets `tempTimeString` when the components value gets cleared externally
  useEffect(() => {
    if (previousValue && valueToUse === null) {
      setTempTimeString(null);
    }
  }, [previousValue, valueToUse]);

  useEffect(() => {
    if (name) {
      register(
        { name },
        {
          validate: {
            dateInPast: (value) => {
              if (value && value < new Date()) {
                return EXPIRY_CANNOT_BE_IN_THE_PAST;
              }
              return undefined;
            },
            invalidTime: () => {
              if (
                tempTimeStringRef.current &&
                !TIME_PATTERN_24_HRS.test(tempTimeStringRef.current)
              ) {
                return MUST_USE_24_HOUR_FORMAT;
              }
              return undefined;
            },
          },
        },
      );
    }
  }, [name, register]);

  useEffect(() => {
    if (valueToUse && tempTimeString === null) {
      setTempTimeString(getTimeString(valueToUse));
    }
  }, [tempTimeString, valueToUse]);

  const timeDisplayEls = (
    <div
      css={css`
        display: flex;
        margin-top: ${shouldStack ? '24px' : '0px'};
      `}
    >
      <TimeDisplay
        title="Expiration"
        emptyValueText="Not Set"
        value={valueToUse}
        fromNow={false}
      />
      <div
        css={css`
          margin-left: 24px;
        `}
      />
      <TimeDisplay displayCurrentTime title="Current Time" fromNow />
      <div
        css={css`
          margin-left: 24px;
        `}
      />
      <TimeDisplay title="Duration" value={valueToUse} fromNow />
    </div>
  );

  return (
    <S.Container
      id={id}
      className={className}
      css={css`
        flex-direction: ${shouldStack ? 'column' : 'row'};
      `}
    >
      {!disabled && (
        <LabelledInput label="Expiration">
          <div
            css={css`
              position: relative;
              display: inline-flex;
              flex: 0 250px;
              height: 44px;
            `}
          >
            <div
              css={css`
                display: inline-flex;
                flex: 0 220px;
              `}
            >
              <Datepicker
                separateLabel
                css={[
                  css`
                    input {
                      max-height: 44px;
                      border-radius: 4px 0 0 4px;
                      border-right: transparent;
                    }

                    label {
                      margin-bottom: 12px;
                    }

                    flex-basis: 50%;
                  `,
                ]}
                selected={!wasCleared && !valueToUse ? new Date() : valueToUse}
                onChange={(newDate) => {
                  if (!newDate) {
                    return;
                  }

                  if (!tempTimeString) {
                    setTempTimeString('00:00');
                  }

                  if (!value) {
                    if (onChange) {
                      onChange(newDate);
                    }
                    if (name) {
                      setValue(name, newDate);
                    }
                    return;
                  }

                  const date = new Date(value.valueOf());

                  date.setFullYear(
                    newDate.getFullYear(),
                    newDate.getMonth(),
                    newDate.getDate(),
                  );

                  if (onChange) {
                    onChange(date);
                  }
                  if (name) {
                    setValue(name, newDate);
                  }
                }}
                placeholderText="mm/dd/yyyy"
                inputCss={css`
                  border: 1px solid ${theme.colors['border-dark']};
                  box-shadow: inset 0 0 4px ${theme.colors['border-dark']};

                  &:focus {
                    box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
                  }
                `}
              />
              <input
                value={tempTimeString ?? ''}
                onChange={(e) => {
                  const timeString = e.target.value;
                  setTempTimeString(timeString);
                  tempTimeStringRef.current = timeString;

                  if (TIME_PATTERN_24_HRS.test(timeString) && valueToUse) {
                    const newDate = overrideDateTime(valueToUse, timeString);

                    if (onChange) {
                      onChange(newDate);
                    }
                    if (name) {
                      setValue(name, newDate);
                      clearError(name);
                    }
                  }
                }}
                onBlur={(e) => {
                  const { value } = e.target as HTMLInputElement;
                  try {
                    const newValue = insertColonToTime(value);
                    const newDate = overrideDateTime(
                      valueToUse ?? new Date(),
                      newValue,
                    );
                    setTempTimeString(newValue);
                    tempTimeStringRef.current = newValue;

                    if (TIME_PATTERN_24_HRS.test(newValue)) {
                      if (onChange) {
                        onChange(newDate);
                      }
                      if (name) {
                        setValue(name, newDate);
                        clearError(name);
                      }
                    } else if (name) {
                      triggerValidation({ name });
                    }
                  } catch (error) {
                    if (name) {
                      triggerValidation({ name });
                    }
                  }
                }}
                maxLength={5}
                autoComplete="off"
                css={(theme) => css`
                  color: ${theme.colors.black};
                  ${theme.typography.sizes.small};
                  border: 0;
                  background: transparent;
                  width: 100%;
                  border-left: 1px solid transparent;
                  padding-left: ${theme.spacing.xsmall};
                  position: relative;
                  font-size: ${theme.typography.sizes.small.fontSize};
                  &::placeholder {
                    font-size: ${theme.typography.sizes.small.fontSize};
                  }
                  border-radius: 0 4px 4px 0;
                  box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
                  :not(:focus-within) {
                    border-left: 1px solid transparent;
                  }
                  flex-basis: 50%;
                  left: -1px;
                `}
                placeholder="hh:mm"
              />
            </div>
            {valueToUse && (
              <Button
                plain
                type="button"
                title="Clear search"
                onClick={() => {
                  if (onChange) {
                    onChange(null);
                  }
                  if (name) {
                    setValue(name, null);
                    clearError(name);
                  }

                  setWasCleared(true);
                  setTempTimeString('');
                }}
                css={css`
                  align-self: center;
                  width: 30px;
                  height: 30px;
                  margin-left: 10px;
                `}
              >
                <span
                  css={css`
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                  `}
                >
                  <ClearIcon
                    css={css`
                      position: relative;
                      top: 7px;
                    `}
                  />
                </span>
              </Button>
            )}
          </div>
        </LabelledInput>
      )}
      {name && (
        <StatusBanner
          as="label"
          htmlFor="email-types-input"
          status="error"
          hasIcon
          text={errors[name]?.message}
          isVisible={!!errors[name]?.message}
          css={css`
            margin-top: 12px;
          `}
        />
      )}
      {timeDisplayEls}
    </S.Container>
  );
};

export default ExpirationTimePicker;
