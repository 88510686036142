/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';

export const PageContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <section
    css={css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `}
    {...props}
  />
);

export const BodyContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: column;
      margin: ${theme.spacing.large};
    `}
    {...props}
  />
);

export const BodyRow: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      align-items: center;
      margin-bottom: 23px;
      background: white;
      box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      & div:first-of-type {
        border-top: 0;
      }
    `}
    {...props}
  />
);

export const TagPill: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      font-family: ${theme.typography.families.primary};
      font-weight: ${theme.typography.weights.bold};
      border-radius: 16px;
      background-color: #eaeaea;
      color: ${theme.colors.black};
      padding: 4px 8px;
      margin-left: 7px;
    `}
    {...props}
  />
);

export const Time: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <time
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.xlarge};
      font-family: ${theme.typography.families.primary};
      font-weight: ${theme.typography.weights.bold};
      border-radius: 16px;
      color: ${theme.colors.blue};
      margin-bottom: 11px;
    `}
    {...props}
  />
);
