import {
  FeedId,
  formatMessageDoc,
  FormatOptions,
  fromJSONToNode,
  GTFS_RT_TEXT_REPLACEMENTS,
  LONG_GTFS_TEXT_REPLACEMENTS,
} from '@mta-live-media-manager/shared';

// This is an older way of constructing an email body, prior to using rich text.
// When duplicating an older alert with plain-text email, this function is still
// useful for testing whether the email has been customized and for formatting
// EditorState as plain text.
export const constructPlainTextEmailBody = (
  feedId: FeedId,
  message: any,
  additionalMessage?: any,
  shouldParseMessages: boolean = false,
): string => {
  const isSubwayFeed = feedId === FeedId.NYCTSubway;
  const formattingOptions: FormatOptions = {
    feedId,
    replacements: GTFS_RT_TEXT_REPLACEMENTS,
    bracketRoutes: isSubwayFeed,
    bracketCustomIcons: true,
  };

  const messageDoc = shouldParseMessages
    ? fromJSONToNode(message?.schema, message?.doc)
    : message?.doc;
  const messageText = message
    ? formatMessageDoc(messageDoc, formattingOptions)
    : '';

  if (!additionalMessage) {
    return messageText;
  }

  const additionalMessageDoc = shouldParseMessages
    ? fromJSONToNode(additionalMessage?.schema, additionalMessage?.doc)
    : additionalMessage?.doc;
  const additionalMessageText = additionalMessage
    ? formatMessageDoc(additionalMessageDoc, formattingOptions)
    : '';

  return `${messageText}\n\n${additionalMessageText}`;
};

export const constructSMS = (feedId: FeedId, message: any) => {
  const formattingOptions = {
    feedId,
    replacements: LONG_GTFS_TEXT_REPLACEMENTS,
    bracketRoutes: false,
    bracketCustomIcons: true,
  };

  return message?.doc ? formatMessageDoc(message.doc, formattingOptions) : '';
};
