/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Button from '../Button';
import { input as inputCss } from '../styles';
import { ThemeType } from '../../../theme';
import { ReactComponent as ClearIcon } from '../../../images/cross-blue.svg';
import { ReactComponent as SearchIcon } from '../../../images/search.svg';

export interface ISearchInputProps extends React.HTMLProps<HTMLInputElement> {
  extraStyles?: SerializedStyles;
  // `ignoreLocation` is used when the search value isn't being used from the query params
  ignoreLocation?: boolean;
  onClear: () => void;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  extraStyles,
  ignoreLocation,
  onClear,
  value,
  ...rest
}) => {
  const location = useLocation();
  const hasValue = !!value;

  useEffect(() => {
    const { search } = location;

    if (!search && !ignoreLocation) {
      onClear();
    }
  }, [location, onClear, ignoreLocation]);

  return (
    <span
      css={(theme: ThemeType) => css`
        ${extraStyles}
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${theme.spacing.xxsmall} ${theme.spacing.small};
        background-color: ${theme.colors.white};
        border-radius: 4px;
        box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
        border: 1px solid ${theme.colors['border-dark']};

        &:focus-within {
          border: 1px solid ${theme.colors.accent3};
        }
      `}
    >
      <SearchIcon
        css={css`
          margin-right: 6px;
        `}
      />
      <input
        value={value}
        type="search"
        css={(theme: ThemeType) => css`
          ${inputCss};
          padding: 0px;
          color: ${theme.colors.accent6};
          font-weight: ${theme.typography.weights.normal};
          font-size: ${theme.typography.sizes.medium.fontSize};

          &::placeholder {
            color: ${theme.colors.dark3};
            opacity: 1;
          }

          box-shadow: unset;

          &:focus {
            outline: none;
            border: unset;
          }
        `}
        {...rest}
      />
      <Button
        plain
        type="button"
        title="Clear search"
        onClick={onClear}
        disabled={!hasValue}
        css={css`
          width: 30px;
          height: 30px;
        `}
      >
        <span
          css={(theme: ThemeType) => css`
            display: inline-block;
            width: 100%;
            height: 100%;
            border-radius: 50%;

            &:hover {
              background-color: ${hasValue ? theme.colors.dark8 : 'initial'};
            }
          `}
        >
          {hasValue && (
            <ClearIcon
              css={css`
                position: relative;
                top: 7px;
              `}
            />
          )}
        </span>
      </Button>
    </span>
  );
};

export default SearchInput;
