/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { ReactComponent as IconArrowBack } from '../../images/icon_arrow_back.svg';

import { ThemeType } from '../../theme';
import AppLink from '../common/app-link';

const BackOnlyPageHeading: React.FC<
  {
    back: {
      title: string;
      to: string;
    };
    title?: string;
    className?: string;
  } & { children?: React.ReactNode }
> = ({ back, title, children }) => {
  return (
    <header
      css={({ spacing }: ThemeType) => css`
        padding: ${spacing.large} ${spacing.large} ${spacing.xxsmall};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      `}
    >
      {title && (
        <VisuallyHidden>
          <h1>{title}</h1>
        </VisuallyHidden>
      )}
      <AppLink
        to={back.to}
        css={({ typography, colors }: ThemeType) => css`
          ${typography.sizes.xsmall};
          color: ${colors.accent1};
          display: flex;
          align-items: center;
          text-transform: uppercase;

          svg {
            fill: ${colors.accent1};
          }

          &:hover {
            color: ${colors.brand};
            svg {
              fill: ${colors.brand};
            }
          }

          border-radius: 4px;

          &:focus-visible {
            box-shadow: 0 0 0 1px ${colors.accent3};
            outline: none;
          }
        `}
      >
        <span
          css={({ typography, spacing }: ThemeType) => css`
            align-items: center;
            display: flex;
            font-size: ${typography.sizes.large.fontSize};
            line-height: 26px;
            margin-right: ${spacing.xxsmall};
          `}
        >
          <IconArrowBack width={10} height={10} />
        </span>
        <span
          css={({ typography }: ThemeType) => css`
            display: inline-block;
            font-weight: ${typography.weights.bolder};
            line-height: 26px;
          `}
        >
          {back.title}
        </span>
      </AppLink>
      {children}
    </header>
  );
};

export default BackOnlyPageHeading;
