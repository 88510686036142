import { loader } from 'graphql.macro';
import { ApolloClient } from '@apollo/client';
import Uppy, { UppyOptions } from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';

import {
  CreateUpload,
  CreateUploadVariables,
  CreateUpload_createUpload_CreateUploadPayload_upload_Upload as UploadRecord,
} from 'generated/CreateUpload';

const CreateUploadMutation = loader('../graphql/CreateUpload.gql');

// for uploads originating on windows machines.
export const normalizeMimeType = (mimeType: string) => {
  return mimeType === 'application/x-zip-compressed'
    ? 'application/zip'
    : mimeType;
};

export const upload = async (
  client: ApolloClient<object>,
  fs: File[],
  config: UppyOptions = { allowMultipleUploads: false },
): Promise<UploadRecord[]> => {
  if (fs.length === 0) {
    return [];
  }

  const uppy = Uppy(config).use(AwsS3Multipart, {
    limit: 4,
    companionUrl: process.env.REACT_APP_UPPY_COMPANION_URL,
  });

  fs.forEach((f) =>
    uppy.addFile({
      name: f.name,
      type: normalizeMimeType(f.type),
      data: f,
      source: 'Local',
      isRemote: false,
    }),
  );

  const { successful } = await uppy.upload();

  if (successful.length !== fs.length) {
    throw new Error('Failed to upload files'); // No partial success
  }

  const mutationResults = await Promise.all(
    successful.map((uf: Uppy.UploadedUppyFile<{}, {}>, idx: number) =>
      client.mutate<CreateUpload, CreateUploadVariables>({
        mutation: CreateUploadMutation,
        variables: {
          originalFilename: fs[idx]?.name,
          s3Url: uf.uploadURL,
          size: uf.size,
          mimeType: uf.type
            ? normalizeMimeType(uf.type)
            : 'application/octet-stream',
        },
      }),
    ),
  );

  const uploads = mutationResults
    .map((mr) => mr?.data?.createUpload?.upload)
    .filter(Boolean) as UploadRecord[];

  if (uploads.length !== fs.length) {
    throw new Error('Failed to upload files');
  }

  return uploads;
};
