import { useMemo } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

import {
  RouteRangeSelectorRouteStops,
  RouteRangeSelectorRouteStopsVariables,
} from 'generated/RouteRangeSelectorRouteStops';

import { routeGTFSIdsWithDupeNamedStops } from 'ui-kit/route-range-stop-selector/constants';

const RouteRangeSelectorRouteStopsQuery = loader(
  '../../graphql/RouteRangeSelectorRouteStops.gql',
);

export const useRouteRangeSelectorRouteStopsQuery = (gtfsRouteIds: string[]) =>
  useQuery<RouteRangeSelectorRouteStops, RouteRangeSelectorRouteStopsVariables>(
    RouteRangeSelectorRouteStopsQuery,
    {
      variables: { gtfsRouteIds },
    },
  );

export const useStopsByRoute = (data: any) =>
  useMemo(() => {
    if (!data || !data.routesConnection) {
      return {};
    }

    return Object.fromEntries(
      data.routesConnection.routes.map((r: any) => [
        r.id,
        r.stopsConnection.stops.reduce((stops: any, stop: any) => {
          if (routeGTFSIdsWithDupeNamedStops.includes(r.id)) {
            if (!stops.find((s: any) => s.label === stop.label)) {
              stops.push(stop);
            }
          } else {
            stops.push(stop);
          }

          return stops;
        }, []),
      ]),
    );
  }, [data]);
