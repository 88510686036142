import * as React from 'react';
import { AttachmentsType } from 'types/twitter-attachments';
import TweetInput from './tweet-input';
import useTweetInputState from './use-tweet-input-state';

export interface Tweet {
  text: string;
  attachments: AttachmentsType;
  quoteId: string | null;
  replyId: string | null;
}

export const UncontrolledTweetInput: React.FC<
  Omit<
    Omit<
      Omit<
        Omit<React.ComponentProps<typeof TweetInput>, 'text'>,
        'onTextChange'
      >,
      'attachments'
    >,
    'onAttachmentsChange'
  >
> & { children?: React.ReactNode } = (props) => {
  const {
    text,
    setText,
    attachments,
    setAttachments,
    quoteId,
    setQuoteId,
    replyId,
    setReplyId,
  } = useTweetInputState();
  return (
    <TweetInput
      {...props}
      text={text}
      onTextChange={setText}
      attachments={attachments}
      onAttachmentsChange={setAttachments}
      quoteId={quoteId}
      onQuoteIdChange={setQuoteId}
      replyId={replyId}
      onReplyIdChange={setReplyId}
    />
  );
};

export default TweetInput;
export { useTweetInputState };
