/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { DetailedHTMLProps, AnchorHTMLAttributes } from 'react';

import { ThemeType } from '../../../theme';

export const TweetLink: React.FC<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> & { children?: React.ReactNode } = ({ children, ...props }) => (
  <a
    css={(theme: ThemeType) => css`
      flex: 0 0 auto;
      display: block;
      max-width: 515px;
      width: 100%;
      border: 1px solid ${theme.colors.border};
      border-radius: 6px;
      padding: ${theme.spacing.medium};
      color: ${theme.colors.dark1};
      &:hover {
        border-color: ${theme.colors.light3};
      }
      position: relative;

      background-color: white;

      :not(:last-of-type) {
        margin-bottom: -32px;

        transform: scale(0.75);
        transform-origin: 0px 0px 0px;

        ::after {
          position: absolute;
          content: ' ';
          width: 1px;
          height: 57px;
          left: 25px;
          bottom: -57px;
          z-index: -1;
          background-color: ${theme.colors['border-dark']};
        }
      }
    `}
    {...props}
  >
    {children}
  </a>
);

export const Avatar: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      background-color: ${theme.colors.brand};
      border-radius: 50%;
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
    `}
    {...props}
  />
);

export const TweetHeading: {
  [key: string]: React.FC<any>;
} = {
  Container: (props) => (
    <div
      css={(theme: ThemeType) => css`
        flex: 1 1 100%;
        margin-left: ${theme.spacing.xsmall};
      `}
      {...props}
    />
  ),
  DisplayName: (props) => (
    <div
      css={(theme: ThemeType) => [
        theme.typography.sizes.medium,
        css`
          font-weight: ${theme.typography.weights.bold};
        `,
      ]}
      {...props}
    />
  ),
  UserName: (props) => (
    <div
      css={(theme: ThemeType) => [theme.typography.sizes.small]}
      {...props}
    />
  ),
};

export const TweetContent: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      padding: ${theme.spacing.medium} 0;

      span {
        word-break: break-all;
      }
    `}
    {...props}
  />
);

export const LiveTweetEmbedContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      position: relative;

      :not(:last-of-type) {
        transform: scale(0.75);
        transform-origin: bottom left;

        ::after {
          position: absolute;
          content: ' ';
          width: 1px;
          height: 13px;
          left: 25px;
          bottom: -13px;
          background-color: ${theme.colors['border-dark']};
        }
      }
    `}
    {...props}
  />
);

export const NotPosted: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      color: #707070;
      justify-self: flex-end;
      white-space: pre;
    `}
    {...props}
  >
    Not posted
  </span>
);
