/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import theme from 'theme';
import { FeedId } from '@mta-live-media-manager/shared';

import {
  ScreenOrientation,
  ScreenType,
  ScreensNormalizedCondition,
} from '../generated/global-types';

import { ReactComponent as IconSinglePortrait } from '../images/screens/icons/single_portrait.svg';
import { ReactComponent as IconSinglePortraitAd } from '../images/screens/icons/single_portrait_ad.svg';
import { ReactComponent as IconSinglePortraitCic } from '../images/screens/icons/single_portrait_cic.svg';
import { ReactComponent as IconSinglePortraitInfo } from '../images/screens/icons/single_portrait_info.svg';

import { ReactComponent as IconSingleLandscape } from '../images/screens/icons/single_landscape.svg';
import { ReactComponent as IconSingleLandscapeAd } from '../images/screens/icons/single_landscape_ad.svg';
import { ReactComponent as IconSingleLandscapeCic } from '../images/screens/icons/single_landscape_cic.svg';
import { ReactComponent as IconSingleLandscapeInfo } from '../images/screens/icons/single_landscape_info.svg';

import { ReactComponent as IconLeftOfDuoCic } from '../images/screens/icons/left_of_duo_cic.svg';
import { ReactComponent as IconRightOfDuoCic } from '../images/screens/icons/right_of_duo_cic.svg';

import { ReactComponent as IconTriptych } from '../images/screens/icons/triptych.svg';
import { ReactComponent as IconTriptychLeft } from '../images/screens/icons/triptych_left.svg';
import { ReactComponent as IconTriptychMiddle } from '../images/screens/icons/triptych_middle.svg';
import { ReactComponent as IconTriptychRight } from '../images/screens/icons/triptych_right.svg';

import { ReactComponent as IconTriptychAd } from '../images/screens/icons/triptych_ad.svg';
import { ReactComponent as IconTriptychLeftAd } from '../images/screens/icons/triptych_left_ad.svg';
import { ReactComponent as IconTriptychMiddleAd } from '../images/screens/icons/triptych_middle_ad.svg';
import { ReactComponent as IconTriptychRightAd } from '../images/screens/icons/triptych_right_ad.svg';

import { ReactComponent as IconPartnerRegionDUP } from '../images/screens/icons/dup_partner_region.svg';

const GenericLandscapeIcon: React.FC<{
  text: string;
  width?: number;
  children?: React.ReactNode;
}> = ({ text, width = 56 }) => {
  return (
    <div
      css={css`
        display: flex;
        background-color: #ccc;
        border-radius: 2px;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        width: ${width}px;
        height: ${width / 2}px;
        text-align: center;

        ${theme.typography.sizes.xxsmall}
      `}
    >
      {text}
    </div>
  );
};

const GenericPortraitIcon: React.FC<{
  text: string;
  height?: number;
  children?: React.ReactNode;
}> = ({ text, height = 56 }) => {
  return (
    <div
      css={css`
        display: flex;
        background-color: #ccc;
        border-radius: 2px;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        width: ${height / 2}px;
        height: ${height}px;

        ${theme.typography.sizes.xxsmall}
      `}
    >
      {text}
    </div>
  );
};

export default function getScreenIcon(
  screen: ScreensNormalizedCondition,
  height: number,
  feedId?: string,
): any {
  const { type, setKey, setIndex, orientation } = screen;

  if (type === ScreenType.SOLARI) {
    return orientation === ScreenOrientation.LANDSCAPE ? (
      <GenericLandscapeIcon text={feedId === FeedId.MNR ? 'Kiosk' : 'PIO-S'} />
    ) : (
      <GenericPortraitIcon text="CIC-S" />
    );
  }

  if (type === ScreenType.SOLARI_MARKETING) {
    return <GenericLandscapeIcon text="MKT-S" />;
  }

  if (type === ScreenType.FULTON) {
    return <GenericPortraitIcon text="FUL" />;
  }

  if (type === ScreenType.OTHER_MARKETING) {
    return <GenericLandscapeIcon text="Mkt" />;
  }

  if (type === ScreenType.DUP) {
    return <IconPartnerRegionDUP />;
  }

  if (!setKey && orientation !== 'LANDSCAPE') {
    if (type === ScreenType.AD) {
      return <IconSinglePortraitAd />;
    }
    if (type === ScreenType.CIC) {
      return <IconSinglePortraitCic />;
    }
    if (type === ScreenType.INFO) {
      return <IconSinglePortraitInfo />;
    }
    return <IconSinglePortrait height={height} />;
  }

  if (orientation === 'LANDSCAPE') {
    if (type === ScreenType.AD) {
      return <IconSingleLandscapeAd />;
    }
    if (type === ScreenType.CIC) {
      return <IconSingleLandscapeCic />;
    }
    if (type === ScreenType.INFO) {
      return <IconSingleLandscapeInfo />;
    }
    return <IconSingleLandscape />;
  }

  if (type === ScreenType.CIC) {
    return setIndex === 1 ? <IconRightOfDuoCic /> : <IconLeftOfDuoCic />;
  }

  if (type === ScreenType.AD) {
    if (setIndex === 0) {
      return <IconTriptychLeftAd />;
    }

    if (setIndex === 1) {
      return <IconTriptychMiddleAd />;
    }

    if (setIndex === 2) {
      return <IconTriptychRightAd />;
    }
    return <IconTriptychAd />;
  }

  if (setIndex === 0) {
    return <IconTriptychLeft height={height} />;
  }

  if (setIndex === 1) {
    return <IconTriptychMiddle height={height} />;
  }

  if (setIndex === 2) {
    return <IconTriptychRight height={height} />;
  }

  // TODO: If we return this icon, it means we're likely dealing with a screen in a >3 set
  return <IconTriptych height={height} />;
}
