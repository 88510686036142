/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Collapse } from 'react-collapse';
import { useFormContext } from 'react-hook-form';

import TweetThreadInput, {
  Tweet as TweetPatch,
} from 'components/common/tweets/tweet-thread-input';
import { isUploadFileContainer as fileIsStillUploading } from 'components/common/tweets/tweet-input/image-attachments';

import { TWEET_MAX_LENGTH, isValidTweet } from 'utils/twitter-helpers';
import FormHeader from '../../../common/form-elements/FormHeader';
import TwitterPreview from '../../../common/twitter/TwitterPreview';

import Heading from '../../../common/Heading';

import * as CS from '../../../common/common-forms.styled';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message_tweets_TweetsConnection_nodes_Tweet as TweetType } from '../../../../generated/GetEventById';
import {
  IDENTICAL_TWEET,
  TWITTER_MESSAGE_REQUIRED,
  TWITTER_MESSAGE_REQUIRED_THREAD,
  TWITTER_MESSAGE_TOO_LONG,
  TWITTER_ATTACHMENTS_STILL_UPLOADING,
} from '../../../../constants/error-messages';

import { FeedId } from '../../../../types/feeds';
import StatusBanner from '../../../common/status-banner';
import {
  TWEET_CUSTOMIZED_NAME,
  TWEETS_NAME,
} from '../../../../constants/alerts';

interface UpdateTweetProps {
  feedId: FeedId;
  show: boolean;
  onToggle: (toggle: boolean) => void;
  previousTweets: TweetType[];
  originalTweet?: TweetType;
  tweets: TweetPatch[];
  addTweet: () => void;
  removeTweet: (id: number) => void;
  setText: (id: number, newText: string) => void;
  setAttachments: (id: number, newAttachments: File[]) => void;
  setQuoteId: (id: number, newQuoteId: string) => void;
  setReplyId: (newReplyId: string) => void;
  prevIsDraft?: boolean;
}

const UpdateTweet: React.FC<UpdateTweetProps> = ({
  feedId,
  show,
  onToggle,
  previousTweets,
  originalTweet,
  tweets,
  addTweet,
  removeTweet,
  setText,
  setAttachments,
  setQuoteId,
  setReplyId,
  prevIsDraft = false,
}) => {
  const { register, setValue, clearError, errors } = useFormContext();
  const [touched, setTouched] = useState<boolean>(false);

  const error = errors[TWEETS_NAME];
  const hasError = !!error;
  const previousTweetBody = previousTweets ? previousTweets[0]?.body : '';
  const previousTweetId = previousTweets
    ? previousTweets[previousTweets.length - 1]?.twitterId
    : '';
  const originalTweetId = originalTweet ? originalTweet.twitterId : '';
  const tweetMaxLength = show ? TWEET_MAX_LENGTH : Number.MAX_SAFE_INTEGER;

  const customizesTweet = useCallback(
    (fn: (...args: any[]) => any) =>
      (...args: any[]) => {
        setTouched(true);
        setValue(TWEET_CUSTOMIZED_NAME, true);
        return fn(...args);
      },
    [setValue],
  );

  useEffect(() => {
    register({ name: TWEET_CUSTOMIZED_NAME });
    register(
      { name: TWEETS_NAME },
      {
        validate: {
          identical: (value: TweetPatch[]) => {
            if (
              show &&
              value[0]?.text.trim() === previousTweetBody?.trim() &&
              !prevIsDraft
            ) {
              return IDENTICAL_TWEET;
            }
            return undefined;
          },
          required: (value: TweetPatch[]) => {
            if (show && touched && value.some((t) => t.text.length === 0)) {
              return value.length === 1
                ? TWITTER_MESSAGE_REQUIRED
                : TWITTER_MESSAGE_REQUIRED_THREAD;
            }
            return undefined;
          },
          maxLength: (value: TweetPatch[]) => {
            if (
              show &&
              value.some((t) => !isValidTweet(t.text, tweetMaxLength))
            ) {
              return TWITTER_MESSAGE_TOO_LONG;
            }
            return undefined;
          },
          uploadingAttachments: (value: TweetPatch[]) => {
            if (
              show &&
              value.some((t) =>
                t.attachments.some((a) => fileIsStillUploading(a)),
              )
            ) {
              return TWITTER_ATTACHMENTS_STILL_UPLOADING;
            }
            return undefined;
          },
        },
      },
    );
  }, [register, previousTweetBody, show, touched, tweetMaxLength, prevIsDraft]);

  useEffect(() => {
    setValue(TWEETS_NAME, tweets, true);
  }, [setValue, tweets]);

  useEffect(() => {
    if (!show) {
      clearError(TWEETS_NAME);
      setValue(TWEET_CUSTOMIZED_NAME, false);
    }
  }, [show, clearError, setValue]);

  return (
    <section>
      <FormHeader
        title={previousTweets.length ? 'Update Twitter' : 'Add Twitter'}
        enabled={show}
        onToggle={(event: React.ChangeEvent<HTMLInputElement>) =>
          onToggle(event.target.checked)
        }
        toggle
      />
      <Collapse isOpened={show}>
        <CS.Container>
          {previousTweets.length ? (
            <Heading
              level={3}
              size="medium"
              css={css`
                margin-bottom: 4px;
              `}
            >
              Previous Tweet
            </Heading>
          ) : null}
          <div>
            {previousTweets.map((tweet) => (
              <TwitterPreview
                key={tweet.id}
                feedId={feedId}
                twitterId={tweet.twitterId}
                body={tweet.body}
                publishedAt={new Date(tweet.createdAt)}
                isDraft={prevIsDraft}
              />
            ))}
          </div>
          <CS.MainFieldContainer
            css={css`
              ${previousTweets.length ? 'margin-top: 24px' : ''}
            `}
          >
            <TweetThreadInput
              id={TWEETS_NAME}
              error={hasError}
              maxLength={TWEET_MAX_LENGTH}
              tweets={tweets}
              setText={customizesTweet(setText)}
              setAttachments={customizesTweet(setAttachments)}
              setQuoteId={customizesTweet(setQuoteId)}
              setReplyId={customizesTweet(setReplyId)}
              addTweet={customizesTweet(addTweet)}
              removeTweet={customizesTweet(removeTweet)}
              previousTweet={previousTweetId || ''}
              originalTweet={originalTweetId || ''}
              feedId={feedId}
            />
          </CS.MainFieldContainer>
          <StatusBanner
            htmlFor={TWEETS_NAME}
            status="error"
            isVisible={!!error}
            text={error?.message}
            css={css`
              margin-top: 22px;
            `}
          />
        </CS.Container>
      </Collapse>
    </section>
  );
};

export default UpdateTweet;
