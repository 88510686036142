/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { MnrRollingStockLine, Shop } from 'generated/global-types';
import uniq from 'lodash/uniq';
import React, { Fragment } from 'react';
import { useRoutesByFeedId } from 'contexts/Routes';
import Bullet, { BulletSize } from './Bullet';
import { SHOP_LABELS, SHOP_LINES } from './form-elements/shop-selector';
import { MNR_ROLLING_STOCK_LINES } from './form-elements/mnr-rolling-stock-line-selector';
import Heading from './Heading';
import { useFeedId } from 'contexts/FeedId';

const TrainTargetingDisplayGroup: React.FC<
  {
    trainTargeting: {
      shops?: Shop[];
      lines?: MnrRollingStockLine[];
      isTargetingAllLirrTrains?: boolean;
    };
    headerText?: string;
  } & { children?: React.ReactNode }
> = ({
  trainTargeting: { shops = [], lines = [], isTargetingAllLirrTrains },
  headerText,
}) => {
  const feedId = useFeedId();
  const allRoutes = useRoutesByFeedId(feedId);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 0 0 100%;
      `}
    >
      {headerText && <Heading level={4}>{headerText}</Heading>}
      {shops.length > 0 && (
        <Fragment>
          <div
            css={css`
              display: flex;
            `}
          >
            {uniq(shops.flatMap((s) => SHOP_LINES[s])).map((line) => (
              <Bullet
                key={line}
                size={BulletSize.xsmall}
                routeId={line}
                style={{ margin: '4px 1px' }}
              />
            ))}
            Trains
          </div>
          <div
            css={css`
              display: flex;
              margin-bottom: 8px;
            `}
          >
            {shops.map((s) => `${SHOP_LABELS[s]} Shop`).join(', ')}
          </div>
        </Fragment>
      )}
      {lines.length > 0 && (
        <div
          css={css`
            display: flex;
            margin-top: 4px;
            margin-bottom: 4px;
          `}
        >
          {lines
            .flatMap((s) => MNR_ROLLING_STOCK_LINES[s])
            .map((line) => (
              <Bullet
                key={line}
                size={BulletSize.xsmall}
                routeId={line}
                style={{ marginRight: '4px' }}
              />
            ))}
          Trains
        </div>
      )}
      {isTargetingAllLirrTrains && (
        <div
          css={css`
            margin-bottom: 4px;
            margin-top: 4px;
          `}
        >
          {allRoutes.map((route) => (
            <Bullet
              key={route.gtfsId}
              size={BulletSize.xsmall}
              routeId={route.gtfsId}
              style={{ marginRight: '4px' }}
            />
          ))}
          Trains
        </div>
      )}
    </div>
  );
};

export default TrainTargetingDisplayGroup;
