/** @jsxImportSource @emotion/react */

import * as React from 'react';
import useQueryParams from 'hooks/useQueryParams';
import { ASSET_TYPE_KEY, ASSET_TYPES } from 'constants/assets';

import SocialMediaAssetView from './social-media-asset';
import LockableAssetView from './lockable-asset';

const AssetView: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const query = useQueryParams();
  const uploadType = query.get(ASSET_TYPE_KEY);

  if (uploadType === ASSET_TYPES.SOCIAL_MEDIA) {
    return <SocialMediaAssetView />;
  }

  return <LockableAssetView />;
};

export default AssetView;
