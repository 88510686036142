/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState, useEffect, useMemo } from 'react';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'components/common/modal';
import Button from 'components/common/Button';
import Heading from 'components/common/Heading';
import Checkbox from 'components/common/Checkbox';
import Loader from 'components/common/Loader';
import Select from 'components/common/form-elements/Select';
import StatusBanner from 'components/common/status-banner';
import {
  EditableTagsStopScreens,
  EditableTagsStopScreensVariables,
  EditableTagsStopScreens_screens2s_Screens2sConnection_nodes_Screens2 as ScreenType,
} from 'generated/EditableTagsStopScreens';
import {
  UpdateScreensCustomCriteria,
  UpdateScreensCustomCriteriaVariables,
} from 'generated/UpdateScreensCustomCriteria';
import { getFeedTagOptions } from 'constants/screen-tags';
import {
  TAGS,
  initEmptyTagSelections,
  getScreensWithTagsFromScreens,
  areTagsRemoved,
  isTagChanged,
  getSelectComponents,
  mapTagsToSelections,
  getScreensCriteriaFromTags,
  getScreenWithTagsFromScreen,
  getTagLabelFromValue,
} from 'utils/screen-tags-helpers';
import { ScreenWithTags, TagSelections, OptionType } from 'types/screen-tags';
import { useFeedId } from 'contexts/FeedId';
import { ThemeType } from 'theme';

const UpdateScreensCustomCriteriaMutation = loader(
  '../../../graphql/UpdateScreensCustomCriteria.gql',
);
const EditableTagsStopScreensQuery = loader(
  '../../../graphql/EditableTagsStopScreens.gql',
);

const TagSelector: React.FC<{
  tag: OptionType;
  selectComponent: React.ReactNode;
  selectedOptions: { label: string; value: string }[];
  isChanged: boolean;
  disabled: boolean;
  onChange: (value: any, action: any) => void;
}> = ({
  isChanged,
  tag: { label, options },
  selectComponent,
  onChange,
  selectedOptions,
  disabled,
}) => (
  <React.Fragment>
    <div
      css={css`
        display: flex;
        margin-top: 10px;
      `}
    >
      {isChanged && (
        <div
          css={css`
            width: 8px;
            height: 8px;
            background-color: #00b300;
            border-radius: 50%;
            margin-right: 5px;
            margin-top: 7px;
            margin-left: -13px;
          `}
        />
      )}
      <Heading level={4}>{label}</Heading>
    </div>
    <Select
      components={selectComponent}
      isMulti
      isSearchable={false}
      isClearable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      placeholder={`Select ${label}`}
      onChange={onChange}
      options={options}
      value={selectedOptions}
      isDisabled={disabled}
      css={css`
        margin-top: 5px;
      `}
    />
  </React.Fragment>
);

const EditTagsModal: React.FC<{
  isOpen: boolean;
  stopId: string;
  onDismiss: () => void;
}> = ({ isOpen, stopId, onDismiss }) => {
  const feedId = useFeedId();

  const [screensWithTags, setScreensWithTags] = useState<ScreenWithTags[]>([]);
  const [selectedPreviousScreensWithTags, setSelectedPreviousScreensWithTags] =
    useState<ScreenWithTags[]>([]);

  const [selectedTags, setSelectedTags] = useState<TagSelections>(
    initEmptyTagSelections(),
  );
  const [conflictingTags, setConflictingTags] = useState<TagSelections>(
    initEmptyTagSelections(),
  );

  const [submitText, setSubmitText] = useState<string>('Update');

  const {
    loading: areScreensLoading,
    error,
    data,
  } = useQuery<EditableTagsStopScreens, EditableTagsStopScreensVariables>(
    EditableTagsStopScreensQuery,
    {
      variables: {
        stopId,
        feedId,
      },
      fetchPolicy: 'cache-and-network',
      skip: !isOpen,
    },
  );

  const screens = useMemo(() => data?.screens2s?.nodes ?? [], [data]);

  const [updateScreensCustomCriteria, { loading: isUpdateTagsLoading }] =
    useMutation<
      UpdateScreensCustomCriteria,
      UpdateScreensCustomCriteriaVariables
    >(UpdateScreensCustomCriteriaMutation, {
      refetchQueries: [
        {
          query: EditableTagsStopScreensQuery,
          variables: {
            stopId,
            feedId,
          },
        },
      ],
    });

  useEffect(() => {
    setSelectedTags(
      TAGS.reduce((acc: { [key: string]: string[] }, tag) => {
        acc[tag] = mapTagsToSelections(screensWithTags, tag);
        return acc;
      }, {}),
    );
    setConflictingTags(
      TAGS.reduce((acc: { [key: string]: string[] }, tag) => {
        acc[tag] = mapTagsToSelections(screensWithTags, tag).filter(
          (t) =>
            t?.length &&
            screensWithTags.some(({ tags }) =>
              tags.every(({ value }) => !value.includes(t)),
            ),
        );
        return acc;
      }, {}),
    );
    setSelectedPreviousScreensWithTags(
      getScreensWithTagsFromScreens(
        screens.filter(({ id: screenId }) =>
          screensWithTags.some(({ id }) => id === screenId),
        ),
      ),
    );
  }, [screensWithTags, screens]);

  const isScreenSelected = (screenId: string) =>
    screensWithTags.some(({ id }) => id === screenId);

  const handleSelectScreen = (screenId: string) => {
    if (isScreenSelected(screenId)) {
      setScreensWithTags(screensWithTags.filter(({ id }) => id !== screenId));
      return;
    }

    setScreensWithTags([
      ...screensWithTags,
      getScreenWithTagsFromScreen(
        screens.find(({ id }) => id === screenId) as ScreenType,
      ),
    ]);
  };

  const handleClearSelectedScreens = () => setScreensWithTags([]);

  const handleSelectAllScreens = () => {
    if (screensWithTags.length === screens.length) {
      return;
    }

    const savedScreensWithtags = getScreensWithTagsFromScreens(screens);

    setScreensWithTags(
      screens.map(({ id }) => {
        const savedTags = savedScreensWithtags.find((s) => s.id === id)?.tags;
        const selectedTags = screensWithTags.find((s) => s?.id === id)?.tags;
        // merge savedTags and selectedTags values having same tag name
        const mergedTags = savedTags?.reduce(
          (acc: { tagName: string; value: string[] }[], tag) => {
            const selectedTag = selectedTags?.find(
              ({ tagName }) => tagName === tag.tagName,
            );
            if (selectedTag) {
              acc.push({
                tagName: tag.tagName,
                value: [...tag.value, ...selectedTag.value].filter(
                  (value, index, self) => self.indexOf(value) === index,
                ),
              });
            } else {
              acc.push(tag);
            }
            return acc;
          },
          [],
        );
        return {
          id,
          tags: mergedTags,
        } as ScreenWithTags;
      }),
    );
  };

  const handleSelectTag = (
    selectedTagName: string,
    action: any,
    isSingle: Boolean,
  ) => {
    const conflicts = conflictingTags[selectedTagName];
    const selectedOption = action?.option?.value ?? null;
    const shouldRemoveTag =
      action?.action === 'deselect-option' &&
      !conflicts.includes(selectedOption);

    setScreensWithTags(
      screensWithTags.map((screen) => {
        const tags = screen.tags.map((t) => {
          const { tagName, value } = t;
          return tagName === selectedTagName
            ? {
                ...t,
                value: shouldRemoveTag
                  ? value.filter((tv) => tv !== selectedOption)
                  : Array.from(
                      isSingle
                        ? [selectedOption]
                        : new Set([...value, selectedOption]),
                    ),
              }
            : t;
        });

        const tagNotFound =
          !tags.find(({ tagName }) => tagName === selectedTagName) &&
          !shouldRemoveTag;

        if (tagNotFound) {
          tags.push({
            tagName: selectedTagName,
            value: [selectedOption],
          });
        }
        return { ...screen, tags };
      }) as ScreenWithTags[],
    );
  };

  const handleUpdate = async () => {
    setSubmitText('Updating');
    await updateScreensCustomCriteria({
      variables: {
        input: {
          screensCriteria: getScreensCriteriaFromTags(
            screensWithTags,
            screens.filter(({ id }) =>
              screensWithTags.some(({ id: swtId }) => swtId === id),
            ),
          ),
        },
      },
    });
    handleClearSelectedScreens();
    setSubmitText('Updated');
    setTimeout(() => setSubmitText('Update'), 3000);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => {
        onDismiss();
        handleClearSelectedScreens();
      }}
      title="Edit Tags on Screens"
      minWidth="900px"
    >
      {error && <div>{error.toString()}</div>}
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          css={css`
            width: 50%;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              padding-right: 25px;
            `}
          >
            <Heading level={3}>Screens</Heading>
            <div>
              <Button
                size="small"
                plain
                additionalStyles={css`
                  margin-right: 16px;
                `}
                onClick={handleClearSelectedScreens}
              >
                Clear
              </Button>
              <Button size="small" plain onClick={handleSelectAllScreens}>
                Select All
              </Button>
            </div>
          </div>
          {areScreensLoading ? (
            <div
              css={(theme) => css`
                padding: ${theme.spacing.xxlarge};
              `}
            >
              <Loader loading={areScreensLoading} />
            </div>
          ) : (
            <div
              css={css`
                margin-top: 14px;
                display: block;
                padding-right: 25px;
                max-height: 400px;
                overflow-y: scroll;
                overflow-x: hidden;
              `}
            >
              {screens.map(({ id, name }) => (
                <div
                  key={`screen-option-${id}`}
                  title={name as string}
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  `}
                >
                  <Checkbox
                    checked={isScreenSelected(id as string)}
                    onChange={() => handleSelectScreen(id as string)}
                    css={(theme: ThemeType) => css`
                      border-color: ${theme.colors.accent1};
                    `}
                  />
                  <Heading
                    level={4}
                    css={css`
                      margin-left: 8px;
                      margin-top: -1px;
                    `}
                  >
                    {name}
                  </Heading>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          css={css`
            width: 50%;
            border-left: 1px solid #ddd;
            padding-left: 25px;
          `}
        >
          <Heading level={3}>Tags</Heading>
          <div
            css={css`
              margin-top: 14px;
              display: block;
            `}
          >
            {(getFeedTagOptions(feedId) ?? []).map((tag) => {
              const { isSingle, tagName } = tag;
              return (
                <TagSelector
                  key={tagName}
                  isChanged={isTagChanged(
                    tagName,
                    selectedPreviousScreensWithTags,
                    screensWithTags,
                  )}
                  tag={tag}
                  selectComponent={
                    getSelectComponents(conflictingTags[tagName]) as any // double check this
                  }
                  onChange={(_, action) =>
                    handleSelectTag(tagName, action, isSingle)
                  }
                  selectedOptions={selectedTags[tagName].map((t) => ({
                    label: getTagLabelFromValue(t),
                    value: t,
                  }))}
                  disabled={!screensWithTags.length}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-top: 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <StatusBanner
          as="label"
          status="warning"
          hasIcon
          isVisible={areTagsRemoved(
            selectedPreviousScreensWithTags,
            selectedTags,
          )}
          text="This action will remove tags from previously tagged screens."
        />
        <div
          css={css`
            display: flex;
          `}
        >
          <Button
            size="mediumLong"
            onClick={() => {
              onDismiss();
              handleClearSelectedScreens();
            }}
          >
            Cancel
          </Button>
          <Button
            size="mediumLong"
            onClick={handleUpdate}
            primary
            disabled={isUpdateTagsLoading || !screensWithTags.length}
            css={css`
              margin-left: 10px;
            `}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditTagsModal;
