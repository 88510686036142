import React from 'react';

export const LinkIcon: React.FC<{
  fill: string;
  children?: React.ReactNode;
}> = ({ fill }) => (
  <svg version="1.1" width="20px" height="20px" viewBox="0 0 20 20">
    <title>Link</title>
    <g fill="none" fillRule="evenodd" strokeWidth="1">
      <g transform="translate(-512 -209)" fill={fill}>
        <g transform="translate(311 167)">
          <g transform="translate(1 28)">
            <g transform="translate(194 8)">
              <g>
                <g transform="translate(6 6)">
                  <path d="m7.5 8.625c0.41404 0 0.75 0.33596 0.75 0.75s-0.33596 0.75-0.75 0.75c-0.54161 0-1.0537 0.17708-1.4654 0.49984l-0.17011 0.14716-0.024336 0.026962-1.3122 1.3098c-0.41576 0.43595-0.65296 1.0315-0.65296 1.6412 0 1.3097 1.0653 2.375 2.375 2.375 0.54161 0 1.0537-0.17708 1.4643-0.49853l0.16955-0.14651 0.02608-0.029889 1.2812-1.2803 0.13054-0.1398c0.35498-0.42473 0.55341-0.97253 0.55341-1.53 0-0.41404 0.33596-0.75 0.75-0.75s0.75 0.33596 0.75 0.75c0 0.93934-0.34632 1.8562-0.96949 2.5656l-0.17833 0.18777-1.1789 1.1789-0.053808 0.060067c-0.73283 0.73025-1.7073 1.1327-2.7445 1.1327-2.1365 0-3.875-1.7385-3.875-3.875 0-0.93948 0.34673-1.8566 0.95661-2.554l0.21008-0.22038 0.014924-0.011549 1.1517-1.1513 0.048333-0.054996c0.73025-0.73025 1.7048-1.1329 2.7434-1.1329zm5.0941-2.2796c0.29257-0.29257 0.76796-0.29257 1.0605 0 0.26006 0.26006 0.28895 0.66457 0.086686 0.95673l-0.086686 0.10379-6.25 6.25c-0.1419 0.1419-0.33236 0.21911-0.52964 0.21911-0.19698 0-0.38732-0.077005-0.53089-0.21911-0.26006-0.26006-0.28895-0.66457-0.086686-0.95673l0.086686-0.10379 6.25-6.25zm1.1559-3.9704c2.1365 0 3.875 1.7385 3.875 3.875 0 0.9509-0.33829 1.849-0.96654 2.5638l-0.17849 0.1903-0.020326 0.01431-1.1638 1.1626-0.053493 0.059752c-0.71922 0.72181-1.7171 1.1343-2.7423 1.1343-0.41404 0-0.75-0.33596-0.75-0.75s0.33596-0.75 0.75-0.75c0.61011 0 1.2055-0.23745 1.6354-0.64822l0.043756-0.047673 1.2705-1.2679 0.12374-0.13304c0.35819-0.42702 0.55162-0.96192 0.55162-1.5282 0-1.3097-1.0653-2.375-2.375-2.375-0.53353 0-1.0562 0.18161-1.4653 0.50005l-0.16809 0.14472-0.025223 0.028433-1.3123 1.3107c-0.42269 0.44169-0.65406 1.022-0.65406 1.6411 0 0.41404-0.33596 0.75-0.75 0.75s-0.75-0.33596-0.75-0.75c0-0.95206 0.33831-1.8503 0.95994-2.5585l0.20657-0.21568 1.1656-1.1616 0.050706-0.056316c0.71901-0.72031 1.7173-1.1329 2.7422-1.1329z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ListIcon: React.FC<{
  fill: string;
  children?: React.ReactNode;
}> = ({ fill }) => (
  <svg version="1.1" width="20px" height="20px" viewBox="0 0 20 20">
    <title>List</title>
    <g fill="none" fillRule="evenodd" strokeWidth="1">
      <g transform="translate(-548 -209)" fill={fill}>
        <g transform="translate(311 167)">
          <g transform="translate(1 28)">
            <g transform="translate(230 8)">
              <g>
                <g transform="translate(6 6)">
                  <path d="m3.9173 13.856c0.78277 0 1.4173 0.63456 1.4173 1.4173s-0.63456 1.4173-1.4173 1.4173c-0.78277 0-1.4173-0.63456-1.4173-1.4173s0.63456-1.4173 1.4173-1.4173zm12.583 0.4175c0.55217 0 1 0.44783 1 1s-0.44783 1-1 1h-8.5512c-0.55217 0-1-0.44783-1-1s0.44783-1 1-1zm-12.583-5.7323c0.78277 0 1.4173 0.63456 1.4173 1.4173 0 0.78277-0.63456 1.4173-1.4173 1.4173-0.78277 0-1.4173-0.63456-1.4173-1.4173 0-0.78277 0.63456-1.4173 1.4173-1.4173zm12.583 0.41733c0.55217 0 1 0.44767 1 1 0 0.552-0.44783 1-1 1h-8.5512c-0.55217 0-1-0.448-1-1 0-0.55233 0.44783-1 1-1zm-12.583-5.7325c0.78277 0 1.4173 0.63456 1.4173 1.4173 0 0.78277-0.63456 1.4173-1.4173 1.4173-0.78277 0-1.4173-0.63456-1.4173-1.4173 0-0.78277 0.63456-1.4173 1.4173-1.4173zm12.583 0.41733c0.552 0 1 0.448 1 1 0 0.5525-0.44783 1-1 1h-8.5512c-0.55217 0-1-0.44767-1-1 0-0.55217 0.44783-1 1-1z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
