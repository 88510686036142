import React, { useContext, useEffect, useState } from 'react';

import authenticationClient from '../utils/authentication';
import {
  Session,
  AuthenticationContext as Context,
} from '../types/authentication';

const AuthenticationContext = React.createContext<Context>({
  session: authenticationClient.unauthenticatedSession(),
} as Context);

export type Authenticated = Exclude<Session, { isAuthenticated: false }>;

AuthenticationContext.displayName = 'AuthenticationContext';

export default function useAuthentication(): Context {
  return useContext(AuthenticationContext);
}

export const AuthenticationProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [session, setSession] = useState<Session>(authenticationClient.session);
  const [isFetching, setIsFetching] = useState<boolean>(
    authenticationClient.isFetching,
  );
  useEffect(() => {
    return authenticationClient.listen(() => {
      setSession(authenticationClient.session);
      setIsFetching(
        authenticationClient.isFetching &&
          !authenticationClient.session.isAuthenticated,
      );
    });
  }, []);

  const restore = async (bypassCache = false) => {
    await authenticationClient.restoreSession(bypassCache);
  };

  const login = async () => {
    await authenticationClient.login();
  };

  const logout = async (bypassCache = false) => {
    await authenticationClient.logout(bypassCache);
    window.location.reload();
  };

  useEffect(() => {
    restore(false);
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        session,
        isFetching,
        logout,
        login,
        restore,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
