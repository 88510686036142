/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';
import { ThemeType } from 'theme';
import alertIconSrc from 'images/icon_alert.svg';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={css`
      background-color: #f8f8f8;
      border-radius: 4px;
      padding: 24px;
      margin: 16px 0;

      display: flex;
      flex-direction: row;
    `}
    {...props}
  />
);

export const ViewDetail: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { children?: React.ReactNode }
> = (props) => (
  <button
    type="button"
    css={(theme: ThemeType) => `
      z-index: 10;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      text-transform: uppercase;
      ${theme.typography.sizes.xsmall};
      font-weight: ${theme.typography.weights.bolder};

      opacity: 0;
      transition: opacity 100ms ease;

      &:hover {
        opacity: 1;
      }
    `}
    {...props}
  />
);

export const Preview: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      position: relative;
      display: grid;
      border-radius: 4px;
      grid-auto-flow: column;
      align-items: center;
      grid-gap: 6px;
      flex: 270px 0 0;
      width: 270px;
      overflow: hidden;
    `}
    {...props}
  />
);

export const Details: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0 0 0 24px;
    `}
    {...props}
  />
);

export const Header: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <header
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `}
    {...props}
  />
);

export const Title: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => (
  <h4
    css={css`
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      font-size: 20px;
      line-height: 24px;
    `}
    {...props}
  >
    {children}
  </h4>
);

export const Durations: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <ul
    css={css`
      font-size: 14px;
      padding: 0;
      list-style: none;
    `}
    {...props}
  />
);

export const Warning: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <p
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px 12px;
      background-color: ${theme.colors['status-warning']};
      border-radius: 4px;

      ::before {
        content: '';
        background-image: url('${alertIconSrc}');
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: 3.4px 3px;
        background-color: #ffcf00;
        height: 16px;
        width: 16px;
        border-radius: 8px;
        margin-right: 8px;
      }
    `}
    {...props}
  />
);
