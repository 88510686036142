import React, { useContext, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FeedId, Feed } from '../types/feeds';

export { FeedId };

const { REACT_APP_DEPLOY_ENV } = process.env;

export const FEEDS: { [key in FeedId]: Feed } = {
  [FeedId.NYCTBus]: {
    id: FeedId.NYCTBus,
    otpId: 'MTA',
    name: 'NYCT Bus',
    longName: 'NYCT Bus',
  },
  [FeedId.NYCTSubway]: {
    id: FeedId.NYCTSubway,
    otpId: 'MTASBWY',
    name: 'NYCT Subway',
    longName: 'NYCT Subway',
  },
  [FeedId.MNR]: {
    id: FeedId.MNR,
    otpId: 'MNR',
    name: 'Metro North',
    longName: 'Metro-North Railroad',
  },
  [FeedId.LIRR]: {
    id: FeedId.LIRR,
    otpId: 'LI',
    name: 'LIRR',
    longName: 'Long Island Rail Road',
  },
  [FeedId.BT]: {
    id: FeedId.BT,
    otpId: 'BT',
    name: 'Bridges and Tunnels',
    longName: 'Bridges and Tunnels',
  },
  [FeedId.OutfrontQA]: {
    id: FeedId.OutfrontQA,
    otpId: 'OF-QA',
    name: 'OUTFRONT QA',
    longName: 'Outfront QA',
  },
  [FeedId.OutfrontDEV]: {
    id: FeedId.OutfrontDEV,
    otpId: 'OF-DEV',
    name: 'OUTFRONT DEV',
    longName: 'Outfront DEV',
  },
};

const outfrontFeedsCount = Object.keys(FeedId).filter((feedId) =>
  feedId.startsWith('Outfront'),
).length;

// We need to account for the Outfront agencies in UAT
export const feedsCount = () =>
  Object.keys(FeedId).length -
  (REACT_APP_DEPLOY_ENV !== 'uat' ? outfrontFeedsCount : 0);

export const useFeedIdInRoute = () => {
  const feedIds = Object.values(FeedId).join('|');
  const match = useRouteMatch(`/:feedId(${feedIds})`) as null | {
    params: { feedId: FeedId };
  };

  const feedId = match?.params?.feedId;
  return feedId;
};

// TODO: Find better defaults here
const FeedIdContext = React.createContext<FeedId>(FeedId.NYCTSubway);

interface Props {
  allowedFeedIds: FeedId[];
  children?: React.ReactNode;
}

export const FeedIdProvider: React.FC<Props> = ({
  allowedFeedIds,
  children,
}) => {
  const [activeFeedId, setActiveFeedId] = useState(allowedFeedIds[0]);
  const feedIdInRoute = useFeedIdInRoute();
  const feedId =
    feedIdInRoute && allowedFeedIds.some((id) => id === feedIdInRoute)
      ? feedIdInRoute
      : activeFeedId || allowedFeedIds[0];

  useEffect(() => {
    if (feedId !== activeFeedId) {
      setActiveFeedId(feedId);
    }
  }, [feedId, activeFeedId]);

  return (
    <FeedIdContext.Provider value={feedId}>{children}</FeedIdContext.Provider>
  );
};

export const useFeedId = () => useContext(FeedIdContext);
