/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { Collapse } from 'react-collapse';
import ChevronIcon from 'components/common/chevron-icon';
import theme from 'theme';

const MainContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      margin-bottom: ${theme.spacing.medium};
      position: relative;
      display: inline-block;
      width: 100%;
    `}
    {...props}
  />
);

const ToggleContainer: React.FC<
  {
    isLargeTitle?: boolean;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> & { children?: React.ReactNode } = ({ isLargeTitle, ...rest }) => (
  <button
    type="button"
    css={css`
      display: flex;
      align-items: center;
      cursor: pointer;
      ${isLargeTitle
        ? theme.typography.sizes.medium
        : theme.typography.sizes.small};
      font-weight: bold;
      outline: none;

      &:focus-visible {
        :after {
          content: '';
          position: absolute;
          top: -1px;
          bottom: -1px;
          left: -1px;
          right: -1px;
          border: 1px solid ${theme.colors.accent3};
          border-radius: 4px;
        }
      }
    `}
    {...rest}
  />
);

const CollapsableContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      margin-left: 24px;
      margin-right: 52px;
    `}
    {...props}
  />
);

const Collapsable: React.FC<
  {
    isOpen: boolean;
    title: string;
    isLargeTitle?: boolean;
    onClick: () => void;
  } & { children?: React.ReactNode }
> = ({ isOpen, onClick, title, isLargeTitle = false, children }) => (
  <MainContainer>
    <ToggleContainer isLargeTitle={isLargeTitle} onClick={() => onClick()}>
      <ChevronIcon
        direction={isOpen ? 'down' : 'right'}
        css={css`
          margin-right: 10px;
        `}
      />
      {title}
    </ToggleContainer>
    <Collapse isOpened={isOpen}>
      <CollapsableContainer>{children}</CollapsableContainer>
    </Collapse>
  </MainContainer>
);

export default Collapsable;
