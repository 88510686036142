import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Returns a cached instance of `URLSearchParams` computed from the current
 * value of `router.location.search`.
 */
export default function useQueryParams(): URLSearchParams {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Gets or sets a single value in URLSearchParams
 */
export function useQueryParam(
  paramName: string,
  defaultValue?: string,
): [string | undefined, (value?: string) => void] {
  const history = useHistory();
  const { search: initialSearch } = useLocation();
  const initalQueryParams = new URLSearchParams(initialSearch);
  const initalQueryValue = initalQueryParams.get(paramName) || defaultValue;
  return [
    initalQueryValue,
    (value?: string) => {
      const { search } = window.location;
      const queryParams = new URLSearchParams(search);
      if (value) {
        queryParams.set(paramName, value);
      } else {
        queryParams.delete(paramName);
      }
      history.push({
        search: queryParams.toString(),
      });
    },
  ];
}
