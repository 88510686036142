import React from 'react';
import {
  format as formatFn,
  addMinutes,
  differenceInMilliseconds,
  compareAsc,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

import { useDate as useTime } from '../../utils/clock';
import { TIME_FORMAT } from '../../constants/time';

const MAX_TIME_TO_COMPARE = 1000 * 60 * 60 * 72; // 72 hours

const pluralize: (word: string, count: number) => string = (word, count) =>
  count === 1 ? word : `${word}s`;

const buildDistanceString: (time: Date, timeToCompare: Date) => string = (
  time,
  timeToCompare,
) => {
  // Use ms for finer control
  const diff = differenceInMilliseconds(time, timeToCompare);

  const isPast = compareAsc(timeToCompare, time) < 0;
  const suffix = isPast ? ' ago' : '';
  const isIntervalLessThanOneMinute = diff < 60000 && diff > -60000;

  const days = Math.abs(differenceInDays(time, timeToCompare));
  const hours = Math.abs(differenceInHours(time, timeToCompare)) - days * 24;
  const minutes =
    Math.abs(differenceInMinutes(time, timeToCompare)) -
    (days * 60 * 24 + hours * 60);

  const pluralizedDaysString = pluralize('day', days);
  const pluralizedHoursString = pluralize('hour', hours);
  const pluralizedMinsString = pluralize('min', minutes);

  if (diff > MAX_TIME_TO_COMPARE) {
    return `More than 72 hours${suffix}`;
  }
  if (isIntervalLessThanOneMinute) {
    if (isPast) {
      return 'Just now';
    }
    return '1 min';
  }
  if (days || hours || minutes) {
    const prefix: string[] = [];

    if (days) {
      prefix.push(`${days} ${pluralizedDaysString}`);
    }

    if (hours) {
      prefix.push(`${hours} ${pluralizedHoursString}`);
    }

    if (minutes) {
      prefix.push(`${minutes} ${pluralizedMinsString}`);
    }

    return `${prefix.join(' ')}${suffix}`;
  }

  return '';
};

// This is a component for displaying live-updating times.
// It can be used in two ways:
// 1). As a wrapper around date-fns, where the formatting will be done for you
//     based on the props (see the commented props below).
// 2). As a simple way to hook into the global current time instantiated in /utils/clock.jsx.
//     Do this by passing it a child function taking a single `time` argument.
//     You will have to do any datetime formatting yourself when using this method.
//     ex. <LiveTime>{(time => <span>{dateFns.format(time)}</span>)</LiveTime>

const LiveTime: React.FC<
  {
    // date-fns compatible format for formatting time for display
    format?: string;
    // will be added to the current time to produce the displayed time
    minutesToAdd?: string;
    // if present and format === "fromNow", this time will be compared to the current time
    // using the buildDistanceString function defined above
    timeToCompare?: string | Date;
    // lets you define a React component to display using the live time value
    children?: (time: Date) => JSX.Element;
  } & { children?: React.ReactNode }
> = ({ format = TIME_FORMAT, minutesToAdd = '0', timeToCompare, children }) => {
  const time = useTime();

  if (children) {
    return <span>{children(time)}</span>;
  }

  if (format === 'fromNow' && timeToCompare) {
    const compareTime = new Date(timeToCompare);

    return <span>{buildDistanceString(time, compareTime)}</span>;
  }

  const withMinutesAdded = addMinutes(time, parseInt(minutesToAdd, 10));

  return <span>{formatFn(withMinutesAdded, format)}</span>;
};

export default LiveTime;
