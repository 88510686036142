/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';
import { ThemeType } from '../../../theme';

export const Container: React.FC<
  {
    height: number;
    width: number;
    multiplier?: number;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
> & { children?: React.ReactNode } = (props) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: calc(${props.width}px * ${props.multiplier ?? 0.3} - 4px);
      overflow: hidden;
    `}
    {...props}
  />
);

export const ScreenBox: React.FC<
  {
    height: number;
    width: number;
    empty?: boolean;
    multiplier?: number;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
> & { children?: React.ReactNode } = ({
  className,
  height,
  width,
  empty = false,
  multiplier = 0.3,
  ...props
}) => (
  <div
    className={className}
    css={(theme: ThemeType) => css`
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border: ${empty
        ? `2px dashed ${theme.colors.light9}`
        : `1px solid ${theme.colors.border}`};
      border-radius: 4px;
      overflow: hidden;

      height: calc(${height}px * ${multiplier} - 4px);
      max-width: calc(${width}px * ${multiplier} - 4px);
    `}
    {...props}
  />
);

export const Header: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => (
  <h4
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      color: ${theme.colors.black};
      font-weight: ${theme.typography.weights.bold};
      margin: 0 0 8px;
    `}
    {...props}
  >
    {children}
  </h4>
);

export const SmallText: React.FC<
  {
    accent?: boolean;
    scale?: number;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >
> & { children?: React.ReactNode } = ({
  accent = false,
  scale = 0.25,
  ...props
}) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      font-weight: ${theme.typography.weights.bolder};
      font-size: calc(56px * ${scale});
      color: ${accent ? theme.colors.accent1 : theme.colors.light9};
      text-align: center;
      text-transform: uppercase;
    `}
    {...props}
  />
);

export const ExtraInfo: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 8px;
    `}
    {...props}
  />
);

export const Filename: React.FC<{ children: string }> = ({
  children,
  ...props
}) => (
  <div
    title={children}
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      font-weight: ${theme.typography.weights.normal};
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    `}
    {...props}
  >
    {children}
  </div>
);

export const DownloadLink: React.FC<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & { children?: React.ReactNode }
> = ({ children, ...props }) => (
  <a
    css={(theme: ThemeType) => css`
      color: ${theme.colors.accent1};
      text-decoration: underline;
    `}
    {...props}
  >
    {children}
  </a>
);
