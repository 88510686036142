import _isEqual from 'lodash/isEqual';
import { RouteMention, StopMention } from 'types';
import {
  MentionType,
  ContextualSelectorInput,
  MnrRollingStockLine,
  Shop,
  TargetType,
  TrainTargetingInput as TrainTargeting,
  CampaignScreenSelectorInput,
} from 'generated/global-types';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route } from '../generated/RoutesByFeed';
import {
  IEntitySelector,
  ISelector,
} from 'components/common/form-elements/multi-route-stop-selector';
import { uniq } from 'lodash';
import { SHOP_LINES } from 'components/common/form-elements/shop-selector';
import { MNR_ROLLING_STOCK_LINES } from 'components/common/form-elements/mnr-rolling-stock-line-selector';
import { FeedId } from '@mta-live-media-manager/shared';
import {
  SingleCampaignView_campaign_Campaign_campaignScreenContents_CampaignScreenContentsConnection_nodes_CampaignScreenContent as CampaignScreenContent,
  SingleCampaignView_campaign_Campaign_campaignScreenContents_CampaignScreenContentsConnection_nodes_CampaignScreenContent_screenSelectors_CampaignScreenSelector as CampaignScreenSelector,
} from '../generated/SingleCampaignView';

export enum CampaignTargetingFormStages {
  TARGETING_TYPE_SELECTION = 'TARGETING_TYPE_SELECTION',
  STATION_SCREEN_SELECTION = 'STATION_SCREEN_SELECTION',
  STATION_UPLOAD_CONTENT_SELECTION = 'STATION_UPLOAD_CONTENT_SELECTION',
  TRAIN_SCREEN_SELECTION = 'TRAIN_SCREEN_SELECTION',
  TRAIN_UPLOAD_CONTENT_SELECTION = 'TRAIN_UPLOAD_CONTENT_SELECTION',
  SIDEWALK_UPLOAD_CONTENT_SELECTION = 'SIDEWALK_UPLOAD_CONTENT_SELECTION',
}

export const getInitalTargetingActiveStage = ({
  rollingStockEnabled = false,
  sidewalkTargetingEnabled = false,
  layouts,
  trainTargeting,
}: {
  rollingStockEnabled?: boolean;
  sidewalkTargetingEnabled?: boolean;
  layouts?: TargetType[];
  trainTargeting?: TrainTargeting | null;
}): CampaignTargetingFormStages => {
  const hasPreviousScreenTargeting = !!layouts?.length;
  const hasPreviousTrainTargeting = !!trainTargeting;

  if (rollingStockEnabled || sidewalkTargetingEnabled) {
    if (hasPreviousTrainTargeting) {
      return CampaignTargetingFormStages.TRAIN_UPLOAD_CONTENT_SELECTION;
    }

    if (layouts?.find((l) => l === TargetType.DUP)) {
      return CampaignTargetingFormStages.SIDEWALK_UPLOAD_CONTENT_SELECTION;
    }

    if (hasPreviousScreenTargeting) {
      return CampaignTargetingFormStages.STATION_UPLOAD_CONTENT_SELECTION;
    }

    return CampaignTargetingFormStages.TARGETING_TYPE_SELECTION;
  }

  if (hasPreviousScreenTargeting) {
    return CampaignTargetingFormStages.STATION_UPLOAD_CONTENT_SELECTION;
  }

  return CampaignTargetingFormStages.STATION_SCREEN_SELECTION;
};

/**
 * Takes a list of contextual selectors and transforms them into the shape needed for
 * for the MultiRouteStopSelector.
 * @param ess
 */
export const getSelectorsFromContextualSelectors = (
  selectors: ContextualSelectorInput[],
  allRoutes: RoutesByFeed_routes_RoutesConnection_nodes_Route[],
): IEntitySelector[] => {
  if (!allRoutes.length) {
    return [];
  }

  // Entity selectors that target an entire route will be consolidated into one selector.
  const wholeRoutesSelector: IEntitySelector = {
    routes: selectors
      .filter((s) => s.entitySelector.routeId)
      .map((s) => {
        const newRoute = allRoutes.find(
          (route) => route.gtfsId === s.entitySelector.routeId,
        );

        if (!newRoute) {
          return undefined;
        }

        const newRouteMention: RouteMention = {
          id: newRoute.gtfsId,
          name: newRoute.shortName || newRoute.longName || newRoute.gtfsId,
          routeId: newRoute.gtfsId,
          isAffected: false,
          meta: null,
          boroughs: null,
          relatedRouteIds: null,
          type: MentionType.ROUTE,
        };

        return newRouteMention;
      })
      .filter(Boolean) as RouteMention[],
    stops: [],
  };

  const stopMentionsByRoute: { [key: string]: StopMention[] } = {};
  selectors
    .filter((s) => s.entitySelector.stopId && s.context.gtfsRouteId)
    .forEach((s) => {
      if (!s.context.gtfsRouteId) {
        return;
      }

      if (!stopMentionsByRoute[s.context.gtfsRouteId]) {
        stopMentionsByRoute[s.context.gtfsRouteId] = [];
      }

      /*
        The StopSelector will transform these largely empty stop mentions
        using the data it fetches for targeted stops.
      */
      const stopId = s.entitySelector.stopId || '';
      const newStopMention: StopMention = {
        stopId,
        isAffected: false,
        id: stopId,
        name: stopId,
        boroughs: null,
        relatedRouteIds: null,
        meta: null,
        type: MentionType.STOP,
      };

      stopMentionsByRoute[s.context.gtfsRouteId] = [
        ...stopMentionsByRoute[s.context.gtfsRouteId],
        newStopMention,
      ];
    });

  const stopSelectors = Object.keys(stopMentionsByRoute).reduce((agg, key) => {
    const newRoute = allRoutes.find(
      (route) => route.gtfsId === key,
    ) as RoutesByFeed_routes_RoutesConnection_nodes_Route;

    const newRouteMention: RouteMention = {
      id: newRoute.gtfsId,
      name: newRoute.shortName || newRoute.longName || newRoute.gtfsId,
      routeId: newRoute.gtfsId,
      isAffected: false,
      meta: null,
      boroughs: null,
      relatedRouteIds: null,
      type: MentionType.ROUTE,
    };

    const newStopMentions = stopMentionsByRoute[key];

    return [
      ...agg,
      {
        routes: [newRouteMention],
        stops: newStopMentions,
      },
    ];
  }, [] as IEntitySelector[]);

  return wholeRoutesSelector.routes.length
    ? [wholeRoutesSelector, ...stopSelectors]
    : stopSelectors;
};

export const getTransformedScreenSelectors = (
  screenSelectors: CampaignScreenSelectorInput[],
  allRoutes: RoutesByFeed_routes_RoutesConnection_nodes_Route[],
): ISelector[] => {
  const combinedScreenSelectors: CampaignScreenSelectorInput[] = [];

  screenSelectors.forEach((screenSelector) => {
    const { tags, selectors } = screenSelector;

    const similarSelector = combinedScreenSelectors.find(
      (s) =>
        s.selectors?.every((s) => s?.entitySelector.routeId) &&
        selectors?.every((s) => s?.entitySelector.routeId) &&
        _isEqual(tags, s.tags),
    );

    if (!similarSelector) {
      const simliarRouteSelector = combinedScreenSelectors.find(
        (s) =>
          s.selectors?.length &&
          selectors?.length &&
          s.selectors[0]?.context.gtfsRouteId ===
            selectors[0]?.context.gtfsRouteId,
      );

      if (simliarRouteSelector) {
        simliarRouteSelector.selectors = [
          ...(simliarRouteSelector.selectors as ContextualSelectorInput[]),
          ...(selectors as ContextualSelectorInput[]),
        ];
      } else {
        combinedScreenSelectors.push(screenSelector);
      }
    } else {
      similarSelector.selectors = uniq([
        ...(similarSelector.selectors as ContextualSelectorInput[]),
        ...(selectors as ContextualSelectorInput[]),
      ]);
    }
  });

  return combinedScreenSelectors.map(({ tags, selectors }) => {
    const [selector] = getSelectorsFromContextualSelectors(
      selectors as ContextualSelectorInput[],
      allRoutes,
    );

    return {
      selector,
      tags,
    };
  });
};

export const getSelectorFromRouteId = (
  routeId: string,
): {
  context: {
    gtfsRouteId: string;
  };
  entitySelector: {
    routeId: string;
  };
} => ({
  context: {
    gtfsRouteId: routeId,
  },
  entitySelector: {
    routeId,
  },
});

export const getSelectorsFromTrainTargeting = ({
  shops,
  lines,
}: {
  shops?: Shop[];
  lines?: MnrRollingStockLine[];
}): ContextualSelectorInput[] => {
  if (shops?.length) {
    return uniq(shops.flatMap((s) => SHOP_LINES[s])).map((routeId) =>
      getSelectorFromRouteId(routeId),
    );
  }

  if (lines?.length) {
    return lines
      .flatMap((s) => MNR_ROLLING_STOCK_LINES[s])
      .map((routeId) => getSelectorFromRouteId(routeId));
  }

  return [];
};

export const getInitialPropsForTrainTargeting = (
  feedId: FeedId,
): {
  layouts?: TargetType[];
  activeStage: CampaignTargetingFormStages;
  trainTargeting?: {
    isTargetingAllLirrTrains: true;
  };
} => {
  if (feedId === FeedId.MNR) {
    return {
      layouts: [TargetType._3SM],
      activeStage: CampaignTargetingFormStages.TRAIN_UPLOAD_CONTENT_SELECTION,
    };
  }

  if (feedId === FeedId.LIRR) {
    return {
      layouts: [TargetType._3SM],
      trainTargeting: {
        isTargetingAllLirrTrains: true,
      },
      activeStage: CampaignTargetingFormStages.TRAIN_UPLOAD_CONTENT_SELECTION,
    };
  }

  return {
    activeStage: CampaignTargetingFormStages.TRAIN_SCREEN_SELECTION,
  };
};

// We filter out selectors targeting routes that have been removed from the CS OTP feed
export const getValidCampaignScreenContentSelectors = (
  campaignScreenContent: CampaignScreenContent[],
  allRoutes: RoutesByFeed_routes_RoutesConnection_nodes_Route[],
): CampaignScreenContent[] => {
  const allRouteIds = new Set(allRoutes?.map((r) => r.gtfsId));
  const campaignScreenContents = campaignScreenContent?.reduce<
    CampaignScreenContent[]
  >((allContent, csc) => {
    const validContent: CampaignScreenContent = {
      ...csc,
      screenSelectors: csc.screenSelectors.map((s) => ({
        ...s,
        selectors: s?.selectors?.filter((ss) =>
          allRouteIds.has(ss?.context.gtfsRouteId),
        ),
      })) as CampaignScreenSelector[],
    };

    if (validContent.screenSelectors.length || !!validContent.screenName) {
      allContent.push(validContent);
    }

    return allContent;
  }, []);

  return campaignScreenContents;
};
