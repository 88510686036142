/** @jsxImportSource @emotion/react */

import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { useTable } from 'react-table';
import { Link } from 'react-router-dom';
import { getLabelFromTargetTypes } from 'components/pages/Screen/in-screens-preview/helpers';
import { FeedId } from 'contexts/FeedId';
import { INDEFINITE_END_DATE_MESSAGE } from 'constants/empty-states';
import {
  contentTypePath,
  TLiveContentTableSortingTypes,
} from 'constants/live-content';
import * as styles from './live-content-table.styles';
import { LiveContents_filteredLiveContent_LiveContentsConnection_nodes_LiveContent as LiveContents_filteredLiveContent_nodes } from '../../../generated/LiveContent';
import { LiveContentType } from '../../../generated/global-types';
import { ReactComponent as EditIcon } from '../../../images/icon_edit_sm.svg';

import {
  TextCell,
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableData,
  BulletsCell,
  SortButton,
} from '../table-elements';
import AppLink from '../app-link';
import { BulletSize } from '../Bullet';
import Pagination from '../Pagination';

const DATE_FMT = 'MM/dd/yy';

export const COL_WIDTHS: { [key: string]: string } = {
  titleIdScreenTypeAndType: 'auto',
  routeIds: '130px',
  status: '130px',
  durations: '160px',
  weightAndPriority: '100px',
  entry: '64px',
};

const LiveContentTable: React.FC<
  {
    onPrevClick: () => void;
    onNextClick: () => void;
    onSortClick: ({
      isDesc,
      orderBy,
    }: {
      isDesc: boolean;
      orderBy: TLiveContentTableSortingTypes;
    }) => void;
    page?: number;
    feedId: FeedId;
    perPage?: number;
    isDesc?: boolean;
    liveContents: LiveContents_filteredLiveContent_nodes[];
    orderBy?: TLiveContentTableSortingTypes;
    totalCount: number;
  } & { children?: React.ReactNode }
> = ({
  page = 1,
  perPage = 5,
  orderBy = 'start',
  feedId,
  onNextClick,
  onPrevClick,
  onSortClick,
  isDesc = true,
  liveContents,
  totalCount,
}) => {
  const columns = useMemo(() => {
    const changeSortSettings = (newOrderBy: TLiveContentTableSortingTypes) => {
      const isSameType = orderBy === newOrderBy;
      onSortClick({
        orderBy: newOrderBy,
        isDesc: isSameType ? !isDesc : false,
      });
    };
    return [
      {
        accessor: 'titleIdScreenTypeAndType',
        Cell: ({ cell }: any) => {
          const { title, id, targetTypes, type, screenName } = cell.value;
          const link = contentTypePath(type);
          const singleScreenCampaignIdentifier =
            type === LiveContentType.CAMPAIGN && screenName
              ? 'Single Screen '
              : '';
          return (
            link && (
              <TextCell
                css={styles.headingHolder}
                hasLeftPadding={false}
                heading={
                  <div css={styles.heading}>
                    <AppLink
                      css={styles.appLink}
                      to={`/${feedId}/${link.pathName}/${id}`}
                    >
                      <div css={styles.titleContentType}>
                        <span
                          css={styles.contentType}
                        >{`${singleScreenCampaignIdentifier}${type}`}</span>
                        <span css={styles.title}>{title}</span>
                      </div>
                      <span css={styles.screens}>
                        {getLabelFromTargetTypes(targetTypes, feedId)}
                      </span>
                    </AppLink>
                  </div>
                }
                headingSize="medium"
              />
            )
          );
        },
        Header: () => {
          return (
            <SortButton
              css={styles.sortButton}
              title="Title"
              onClick={() =>
                changeSortSettings(TLiveContentTableSortingTypes.title)
              }
              isSorted={orderBy === TLiveContentTableSortingTypes.title}
              isSortedDesc={isDesc}
              width="auto"
            />
          );
        },
      },
      {
        accessor: 'routeIds',
        Cell: ({ cell }: any) => {
          return (
            <BulletsCell
              textAlign="center"
              maxWidth="130px"
              size={BulletSize.medium}
              routeIds={cell.value}
              limit={4}
            />
          );
        },
        Header: () => {
          return <div css={styles.alignCenter}>Line(s)</div>;
        },
      },
      {
        accessor: 'durations',
        Cell: ({ cell }: any) => {
          const { end, start } = cell.value;
          const dateText = `${format(start, DATE_FMT)} - ${
            typeof end === 'string' ? end : format(end, DATE_FMT)
          }`;
          return (
            <TextCell heading="" textAlign="center" descriptionSize="medium">
              {dateText}
            </TextCell>
          );
        },
        Header: () => {
          return (
            <div css={styles.durations}>
              <SortButton
                css={styles.sortButton}
                title="Start"
                onClick={() =>
                  changeSortSettings(TLiveContentTableSortingTypes.start)
                }
                isSorted={orderBy === TLiveContentTableSortingTypes.start}
                isSortedDesc={isDesc}
                width="auto"
              />
              <SortButton
                css={styles.sortButton}
                title="End"
                onClick={() =>
                  changeSortSettings(TLiveContentTableSortingTypes.end)
                }
                isSorted={orderBy === TLiveContentTableSortingTypes.end}
                isSortedDesc={isDesc}
                width="auto"
              />
            </div>
          );
        },
      },
      {
        accessor: 'entry',
        Cell: ({ cell }: any) => {
          const { id, type } = cell.value;
          const link = contentTypePath(type);
          return (
            link && (
              <Link
                css={styles.editLink}
                to={`/${feedId}/${link?.pathName}/${id}/${link.actionName}`}
                onClick={(e) => e.stopPropagation()}
              >
                <EditIcon css={styles.editIcon} />
              </Link>
            )
          );
        },
        Header: (): null => {
          return null;
        },
      },
    ];
  }, [isDesc, orderBy, onSortClick, feedId]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: (liveContents ?? []).map((c: any) => ({
        titleIdScreenTypeAndType: {
          title: c.title,
          id: c.entryId,
          targetTypes: c.targetTypes,
          type: c.liveContentType,
          screenName: c.screenName,
        },
        entry: {
          id: c.entryId,
          type: c.liveContentType,
        },
        routeIds: c.routeIds,
        status: c.status,
        weightAndPriority: {
          weight: c.weight,
          priority: c.priority,
        },
        durations: {
          start: new Date(c.durationStart),
          end: !c.durationEnd
            ? INDEFINITE_END_DATE_MESSAGE
            : new Date(c.durationEnd),
        },
        ...c,
      })),
    });
  return (
    <div css={styles.tableHolder}>
      <Table css={styles.table} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <TableHeaderCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableHeaderCell>
                );
              })}
            </tr>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow css={styles.tableRow} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const hasLeftBorder = !['titleIdScreenTypeAndType'].includes(
                    cell.column.id,
                  );
                  return (
                    <TableData
                      hasLeftBorder={hasLeftBorder}
                      width={COL_WIDTHS[cell.column.id]}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        currentPage={page}
        itemsPerPage={perPage}
        totalItems={totalCount}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
      />
    </div>
  );
};

export default LiveContentTable;
