/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { container } from '../styles';
import { ThemeType } from '../../../theme';

export const Row: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      max-width: 100%;
    `}
    {...props}
  />
);

export const MainColumn: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      min-width: 0;
    `}
    {...props}
  />
);

export const Page: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={(theme: ThemeType) => css`
      width: 100%;
      margin-bottom: ${theme.spacing.xxxlarge};
    `}
    {...props}
  />
);

export const SideBar: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      background-color: ${theme.colors.light6};
      flex: 0 0 300px;
    `}
    {...props}
  />
);

export const SubnavWrapper: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <nav
    role="navigation"
    css={(theme: ThemeType) => css`
      background: ${theme.colors.light4};
      width: 100%;
      height: 60px;
      display: flex;
      padding: 0 ${theme.spacing.large};
    `}
    {...props}
  />
);

export const SubnavContent: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={[
      container,
      css`
        display: flex;
        flex-direction: row;
        height: 100%;
        margin-left: -16px;
      `,
    ]}
    {...props}
  />
);

export const ContentWrapper: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
    `}
  >
    <div
      css={(theme: ThemeType) => css`
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        padding: 0 ${theme.spacing.large};
      `}
      {...props}
    />
  </div>
);
