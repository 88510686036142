import { css } from '@emotion/react';
import theme from '../../theme';

export const table = css`
  display: table;
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
`;

export const tableHeader = css`
  display: table-row;
`;

export const tableBody = css`
  display: table-row-group;
  font-size: 14px;
`;

export const tableHeaderCell = css`
  display: table-cell;
  box-sizing: border-box;
  padding: 12px 16px 12px;

  ${theme.typography.sizes.medium};
  font-family: ${theme.typography.families.primary};
  font-weight: ${theme.typography.weights.bold};
  color: ${theme.colors.accent1};
  text-align: left;
`;

export const tableCell = css`
  display: table-cell;
  box-sizing: border-box;
  padding: 16px;
  vertical-align: middle;
  position: relative;

  /*
    Doing this like this instead of with a border so the horizontal rules aren't
    covered by the vertical rules, oy
  */
  &:after {
    content: ' ';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 1px;
    border-right: 1px solid #f7f7f7;
  }

  &:last-of-type {
    border-right: 0;
  }
`;

export const tableRow = css`
  display: table-row;
  background: #fff;
  border-bottom: 1px solid #ddd;

  &:last-of-type {
    border-bottom: 0px;
    box-shadow: 0px 9px 2px -7px rgba(199, 199, 199, 0.9);
  }

  &:first-of-type > *:first-of-type {
    border-top-left-radius: 8px;
  }

  &:first-of-type > *:last-of-type {
    border-top-right-radius: 8px;
  }

  &:last-of-type > *:first-of-type {
    border-bottom-left-radius: 8px;
  }

  &:last-of-type > *:last-of-type {
    border-bottom-right-radius: 8px;
  }
`;
