import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { ReactComponent as PrevArrow } from '../../../images/icon_arrow_prev.svg';
import { ReactComponent as NextArrow } from '../../../images/icon_arrow_next.svg';

import * as S from './index.styled';

interface IProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  baseUrl?: string;
  basePaginatedUrl?: string;
  onNextClick?: (nextPage: number) => void;
  onPrevClick?: (prevPage: number) => void;
  nextLink?: string;
  prevLink?: string;
}

const PageButton: React.FC<
  {
    link?: string;
    icon: JSX.Element;
    onClick?: (page: number) => void;
    currentPage: number;
    disabled: boolean;
  } & { children?: React.ReactNode }
> = ({ link, icon, onClick = () => {}, currentPage, disabled }) =>
  link && !disabled ? (
    <Link to={link} onClick={() => onClick(currentPage)}>
      <S.PageButton>{icon}</S.PageButton>
    </Link>
  ) : (
    <S.PageButton disabled={disabled} onClick={() => onClick(currentPage)}>
      {icon}
    </S.PageButton>
  );

const Pagination: React.FC<IProps> = ({
  currentPage,
  nextLink,
  prevLink,
  baseUrl,
  basePaginatedUrl,
  totalItems,
  itemsPerPage,
  onNextClick = () => {},
  onPrevClick = () => {},
}) => {
  const firstItem = (currentPage - 1) * itemsPerPage + 1;
  const lastItem = Math.min(firstItem - 1 + itemsPerPage, totalItems);

  function itemCount(first: number, last: number) {
    if (first === last) {
      return last.toString();
    }
    return `${first} - ${last}`;
  }

  const totalPages = totalItems ? Math.ceil(totalItems / itemsPerPage) : 1;

  const useLinkNavigation =
    (!!nextLink || !!prevLink) && !!basePaginatedUrl && !!baseUrl;

  return (
    <S.Nav aria-label="Page Navigation">
      {useLinkNavigation && (
        <>
          {currentPage > totalPages && (
            <Redirect to={`${basePaginatedUrl}/${totalPages}`} />
          )}
          {currentPage === 1 && baseUrl && <Redirect to={baseUrl} />}
        </>
      )}
      <S.CurrentPage>
        {itemCount(firstItem, lastItem)} of {totalItems}
      </S.CurrentPage>
      <S.ButtonList>
        <S.ListItem>
          <PageButton
            disabled={currentPage === 1}
            icon={<PrevArrow width="7" height="12" />}
            link={prevLink}
            onClick={(page) => onPrevClick(page > 1 ? page - 1 : 0)}
            currentPage={currentPage}
          />
        </S.ListItem>
        <S.ListItem>
          <PageButton
            disabled={currentPage === totalPages}
            icon={<NextArrow width="7" height="12" />}
            link={nextLink}
            onClick={(page) => onNextClick(page < totalPages ? page + 1 : 0)}
            currentPage={currentPage}
          />
        </S.ListItem>
      </S.ButtonList>
    </S.Nav>
  );
};

export default Pagination;
