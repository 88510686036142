/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useCallback, useRef } from 'react';
import { startCase } from 'lodash';
import { ScreenUploader } from 'components/common/campaign-form/screen-uploader';
import ScreensAccordion from 'components/common/screens-accordion';
import { ThemeType } from 'theme';
import ScreensAvailabilityCount from 'components/common/screens-availability-count';
import { getIcon } from 'components/common/screen-layout-buttons';
import {
  Direction,
  TargetType,
  TransitDestination,
} from 'generated/global-types';
import {
  getLabelFromTargetType,
  getDimensionsFromTargetType,
} from 'components/pages/Screen/in-screens-preview/helpers';
import { IUseScreenFallbackCountResult } from 'hooks/use-screen-fallback-counts';
import { CommonUploadFragment } from 'generated/CommonUpload.fragment';

export type FallbackScreenAsset = {
  type: TargetType;
  direction: Direction | null;
  uploadId: string;
};

const FallbackScreen: React.FC<
  {
    fallbackScreen: IUseScreenFallbackCountResult;
    uploadedScreenAsset: FallbackScreenAsset[];
    onUploadChange: (ffa: FallbackScreenAsset[]) => void;
  } & { children?: React.ReactNode }
> = ({ fallbackScreen, uploadedScreenAsset, onUploadChange }) => {
  const uploadRef = useRef<CommonUploadFragment>();

  const [width, height] = getDimensionsFromTargetType(
    fallbackScreen.targetType,
  );
  const uploadDimensions: string = (() => {
    return `${width} x ${height}px`;
  })();

  const handleUpload = useCallback(
    (_id: any, currentUpload: any) => {
      if (currentUpload && uploadRef?.current?.id !== currentUpload.id) {
        const uploadedAssets = uploadedScreenAsset;
        const foundIndex = uploadedAssets.findIndex(
          (asset: FallbackScreenAsset) =>
            asset.type === fallbackScreen.targetType,
        );
        const uploadedAsset = {
          type: fallbackScreen.targetType,
          direction: fallbackScreen.direction,
          uploadId: currentUpload.id,
        };
        if (foundIndex === -1) {
          uploadedAssets.push(uploadedAsset);
        } else {
          uploadedAssets[foundIndex] = uploadedAsset;
        }
        onUploadChange([...uploadedAssets]);
        uploadRef.current = currentUpload;
      }
    },
    [uploadRef, fallbackScreen, uploadedScreenAsset, onUploadChange],
  );

  const isDUPScreen = TargetType.DUP === fallbackScreen.targetType;

  return (
    <div
      css={css`
        padding: 36px 45px;
        border-top: 1px #ccc solid;
      `}
    >
      <ScreensAccordion
        preview={getIcon(fallbackScreen.targetType)}
        label={`${getLabelFromTargetType(fallbackScreen.targetType)}${
          fallbackScreen.direction
            ? ` - ${startCase(fallbackScreen.direction.toLowerCase())}`
            : ''
        }`}
        availableCount={
          <ScreensAvailabilityCount
            numTargeted={fallbackScreen.totalCount}
            numLocked={fallbackScreen.lockedCount}
          />
        }
      >
        <p
          css={(theme: ThemeType) => css`
            ${theme.typography.sizes.small};
            line-height: 16px;
          `}
        >
          Supported Image Files: PNG, JPEG (10mb max)
          <br />
          Supported Video Files: Mp4, mov (50mb max)
          <br />
          Dimensions: {uploadDimensions}
        </p>
        <ScreenUploader
          id={fallbackScreen?.upload?.id}
          onChange={handleUpload}
          scale={isDUPScreen ? 0.17 : undefined}
          multiplier={isDUPScreen ? 0.5 : undefined}
          height={height}
          width={width}
          defaultUpload={fallbackScreen.upload}
          transitSettings={{
            shouldZip: isDUPScreen,
            transitDestination: TransitDestination.VLE,
          }}
        />
      </ScreensAccordion>
    </div>
  );
};

export default FallbackScreen;
