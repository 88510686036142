/** @jsxImportSource @emotion/react */

import React from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import { DATE_FORMAT } from '../../constants/time';

const EventStamp: React.FC<
  {
    date: Date;
    name?: string | null;
  } & { children?: React.ReactNode }
> = ({ date, name }) => {
  const dateText = format(new Date(date), DATE_FORMAT);

  const byUserText = name ? ` by ${name}` : '';

  return (
    <React.Fragment>
      <span
        css={css`
          text-transform: lowercase;
        `}
      >
        {dateText}
      </span>
      {byUserText}
    </React.Fragment>
  );
};

export default EventStamp;
