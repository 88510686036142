/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';

import pluralize from 'pluralize';
import { ScreenType } from 'generated/global-types';

import * as S from './targeted-screens.styled';

const TargetedScreens: React.FC<{
  type: ScreenType;
  totalCount?: number;
  hideScreenTypeHeader?: boolean;
  hideDetails?: boolean;
  children?: React.ReactNode;
}> = ({
  type,
  totalCount,
  hideScreenTypeHeader = false,
  hideDetails = false,
}) => (
  <div
    css={css`
      margin-bottom: 8px;
    `}
  >
    {!hideScreenTypeHeader && <strong>{ScreenType[type]}</strong>}
    {!hideDetails && (
      <S.ScreenCount>
        On {totalCount} {ScreenType[type]} {pluralize('Screen', totalCount)}
      </S.ScreenCount>
    )}
  </div>
);

export default TargetedScreens;
