/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import SkeletonLoader from './SkeletonLoader';

const PageHeadingSkeleton: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
      `}
    >
      <SkeletonLoader height="56">
        <rect x="0" y="0" rx="3" ry="3" width="80" height="12" />
        <rect x="0" y="20" rx="3" ry="3" width="200" height="27" />
      </SkeletonLoader>
      <SkeletonLoader width="160" height="56">
        <rect x="0" y="0" rx="3" ry="3" width="160" height="50" />
      </SkeletonLoader>
    </div>
  );
};

export default PageHeadingSkeleton;
