/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import {
  components,
  OptionProps,
  MenuProps,
  CSSObjectWithLabel,
} from 'react-select';
import {
  getNextGridItemProps,
  ArrowKeys,
  GridItemWidth,
  GridItemProps,
} from 'utils/dynamic-grid-helpers';

import QuickSelect, { IOnChangeQuickSelect } from './quick-select';
import Select, {
  getPlaceholderColor as getSelectPlaceholderColor,
  SELECT_OVERLAY_STYLES,
} from '../Select';
import Bullet, { BulletSize } from '../../Bullet';
import { useFeedId } from '../../../../contexts/FeedId';
import {
  getVehicleName,
  getStopType,
  isSubway,
  isBridgeTunnel,
} from '../../../../utils/feed-switches';

import Theme, { ThemeType } from '../../../../theme';
import { EntireRouteOption, RouteMention } from '../../../../types';
import Button from '../../Button';
import { FeedId } from '../../../../types/feeds';
import LineOption from './line-option';
import { useRoutesByFeedId } from '../../../../contexts/Routes';
import { routeToRouteMention } from '../../../../utils/route-mentions';
import RadioButton from 'components/common/RadioButton';

const MAX_SUBWAY_BULLETS = 6;
const MAX_TRAIN_BULLETS = 2;
const MAX_BRIDGE_TUNNEL_BULLETS = 1;

enum OptionSelectionKeys {
  Space = ' ',
  Enter = 'Enter',
}

export type RouteSelectorProps = {
  id?: string;
  routes: RouteMention | RouteMention[];
  onChange: IOnChangeQuickSelect;
  className?: string;
  classNamePrefix?: string;
  disabledRoutes?: RouteMention[];
  hasControlBoxShadow?: boolean;
  hasControlBorder?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  includeAllRoutes?: boolean;
  disabled?: boolean;
  selectProps?: {
    selectStyles?: any;
  };
  onRadioChange?: (isChecked: boolean) => void;
  enableQuickSelect?: boolean;
  isAgencyWide?: boolean;
  css?: SerializedStyles | ((theme: ThemeType) => SerializedStyles);
} & { children?: React.ReactNode };

const getMaxBullets = (feedId: FeedId) => {
  if (isSubway(feedId)) {
    return MAX_SUBWAY_BULLETS;
  }
  return isBridgeTunnel(feedId) ? MAX_BRIDGE_TUNNEL_BULLETS : MAX_TRAIN_BULLETS;
};

const Option: React.FC<
  {
    optionProps: OptionProps<any>;
    optionRefCallback: any;
    isFocused: boolean;
  } & { children?: React.ReactNode }
> = ({ optionProps, optionRefCallback = null, isFocused }) => {
  return (
    <components.Option {...optionProps}>
      <LineOption
        refCallback={optionRefCallback}
        focused={isFocused}
        selected={optionProps.isSelected}
        disabled={!optionProps.isSelected && optionProps.isDisabled}
        option={optionProps.data}
      />
    </components.Option>
  );
};

const Placeholder: React.FC<{ children?: React.ReactNode } & any> = (props) => {
  const lineOptions = props.getValue();
  return lineOptions.length ? null : <components.Placeholder {...props} />;
};

const ValueContainer: React.FC<{ children?: React.ReactNode } & any> = (
  props,
) => {
  const lineOptions = props.getValue() as EntireRouteOption[];
  let bullets: JSX.Element[] = [];
  let plusRemainingText: string = '';

  if (lineOptions.length > 0) {
    const numMaxBullets = getMaxBullets(lineOptions[0].feedId as FeedId);
    bullets = lineOptions.slice(0, numMaxBullets).map((lineOption) => {
      return (
        <Bullet
          key={lineOption.routeId}
          size={BulletSize.medium}
          routeId={lineOption.routeId}
          style={{ display: 'block', marginRight: '3px' }}
        />
      );
    });

    plusRemainingText =
      lineOptions.length > numMaxBullets
        ? ` + ${lineOptions.length - numMaxBullets} more`
        : '';
  }

  return (
    <components.ValueContainer {...props}>
      {bullets}
      {plusRemainingText}
      {props.children}
    </components.ValueContainer>
  );
};

const Menu: React.FC<
  {
    menuProps: MenuProps<any>;
    menuRefCallBack: any;
    enableQuickSelect: boolean;
    includeAllRoutes?: boolean;
    onRadioChange?: (isChecked: boolean) => void;
    isAgencyWide: boolean;
    onChange: IOnChangeQuickSelect;
  } & { children?: React.ReactNode }
> = ({
  menuProps: { children, setValue, isMulti, options, ...rest },
  menuRefCallBack = null,
  enableQuickSelect,
  isAgencyWide,
  onChange,
  includeAllRoutes = false,
  onRadioChange,
}) => {
  const { getValue } = rest;
  return (
    <components.Menu
      setValue={setValue}
      isMulti={isMulti}
      options={options}
      {...rest}
    >
      <div
        css={css`
          padding: 20px;

          h4 {
            margin: 16px 0 16px 0;
          }

          .and-or-search {
            display: flex;
            align-items: center;

            input:last-of-type {
              margin-left: 16px;
            }
            label {
              padding-left: 8px;
            }
          }

          font-weight: bold;
        `}
        ref={(el: HTMLDivElement) => {
          if (menuRefCallBack) {
            menuRefCallBack(el);
          }
        }}
      >
        {enableQuickSelect ? (
          <QuickSelect
            routes={getValue() as RouteMention[]}
            options={options as RouteMention[]}
            isAgencyWide={isAgencyWide}
            onChange={onChange}
          >
            {children}
          </QuickSelect>
        ) : (
          <React.Fragment>
            {children}
            <div
              css={css`
                display: flex;
                flex-direction: row;
                margin-top: 12px;
              `}
            >
              {isMulti && (
                <Button
                  primary
                  css={css`
                    margin-right: 8px;
                  `}
                  size="xsmall"
                  type="button"
                  onClick={() => {
                    setValue(options, 'select-option');
                  }}
                >
                  Select All
                </Button>
              )}
              <Button
                size="xsmall"
                type="button"
                onClick={() => {
                  setValue([], 'select-option');
                }}
              >
                Clear
              </Button>
            </div>
          </React.Fragment>
        )}
        {onRadioChange && (
          <>
            <h4>Search Type</h4>
            <div className="and-or-search">
              <RadioButton
                size={20}
                name="RoutesAND"
                checked={includeAllRoutes}
                onChange={onRadioChange}
              />
              <label htmlFor="routesAND">And</label>
              <RadioButton
                size={20}
                name="RoutesOR"
                checked={!includeAllRoutes}
                onChange={onRadioChange}
              />
              <label htmlFor="routesOR">Or</label>
            </div>
          </>
        )}
      </div>
    </components.Menu>
  );
};

const RouteSelector: React.FC<RouteSelectorProps> = ({
  id,
  routes,
  onChange,
  className,
  classNamePrefix,
  hasControlBorder = true,
  hasControlBoxShadow = false,
  isMulti = false,
  placeholder = '',
  disabled = false,
  includeAllRoutes,
  onRadioChange,
  disabledRoutes,
  selectProps = {
    selectStyles: {},
  },
  enableQuickSelect = false,
  isAgencyWide = false,
  ...rest
}) => {
  const feedId = useFeedId();
  const vehicleName = getVehicleName(feedId);
  const stopOrStationText = getStopType(feedId);
  const allRoutes = useRoutesByFeedId(feedId);

  const allRouteOptions =
    allRoutes?.map((route, index) => ({
      index,
      feedId,
      label: `All ${
        route.shortName || route.longName
      } ${vehicleName} ${stopOrStationText}`,
      bulletValue: route.gtfsId,
      key: route.gtfsId,
      routeId: route.gtfsId,
      onClear: () => onChange([]),
    })) || [];

  const allRouteIds = Array.isArray(routes)
    ? routes.map((route) => route.routeId)
    : [routes.routeId];
  const selectedRouteOptions = allRouteOptions.filter((route) =>
    allRouteIds.includes(route.routeId),
  );

  const itemsContainerWidth = React.useRef<number>(0);
  const optionsWidths = React.useRef<GridItemWidth[]>([]);

  const [focusedOptionProps, setFocusedOptionProps] =
    React.useState<GridItemProps>({
      index: 0,
      coords: {
        x: 0,
        y: 0,
      },
    });

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  return (
    <div className={className}>
      <Select
        id={id}
        classNamePrefix={classNamePrefix}
        isDisabled={disabled}
        isMulti={isMulti}
        isLoading={!allRoutes || !allRoutes.length}
        hasControlBoxShadow={hasControlBoxShadow}
        hasControlBorder={hasControlBorder}
        isSearchable={false}
        isClearable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMulti}
        controlShouldRenderValue={false}
        tabSelectsValue={false}
        placeholder={placeholder}
        options={allRouteOptions}
        getOptionValue={(option: EntireRouteOption) => option.key}
        isOptionDisabled={(option: EntireRouteOption) =>
          !!disabledRoutes?.find(
            (disabledRoutes) => disabledRoutes.routeId === option.routeId,
          )
        }
        value={selectedRouteOptions}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        onBlur={() => {
          optionsWidths.current = [];

          setFocusedOptionProps({
            index: 0,
            coords: {
              x: 0,
              y: 0,
            },
          });
        }}
        onKeyDown={(event: any) => {
          if (menuIsOpen) {
            if (Object.values(ArrowKeys).includes(event.key)) {
              event.preventDefault();
              setFocusedOptionProps(
                getNextGridItemProps(
                  event.key as ArrowKeys,
                  focusedOptionProps,
                  optionsWidths.current,
                  itemsContainerWidth.current,
                ),
              );
            } else if (Object.values(OptionSelectionKeys).includes(event.key)) {
              event.preventDefault();
              const route = selectedRouteOptions.find(
                (r) => r.index === focusedOptionProps.index,
              );
              const routeMentions = selectedRouteOptions
                .map((sr) => {
                  const route = allRoutes.find((r) => r.gtfsId === sr.routeId);

                  return route ? routeToRouteMention({ route }) : undefined;
                })
                .filter((mention): mention is RouteMention => !!mention);

              if (route) {
                onChange(
                  routeMentions.filter((rm) => rm.routeId !== route.routeId),
                );
              } else {
                const missingRoute = allRoutes.find(
                  (r) =>
                    r.gtfsId ===
                    allRouteOptions.find(
                      (ro) => ro.index === focusedOptionProps.index,
                    )?.routeId,
                );

                if (missingRoute) {
                  if (isMulti) {
                    onChange([
                      ...routeMentions,
                      routeToRouteMention({
                        route: missingRoute,
                      }) as RouteMention,
                    ]);
                  } else {
                    onChange([
                      routeToRouteMention({
                        route: missingRoute,
                      }) as RouteMention,
                    ]);
                  }
                }
              }
            }
          }
        }}
        // eslint-disable-next-line consistent-return
        onChange={(choices: EntireRouteOption | EntireRouteOption[] | null) => {
          if (!choices) {
            return onChange([]);
          }

          if (Array.isArray(choices)) {
            const routeMentions = choices
              .map((choice) => {
                const route = allRoutes.find(
                  (_route) => _route.gtfsId === choice.routeId,
                );

                return route ? routeToRouteMention({ route }) : undefined;
              })
              .filter((mention): mention is RouteMention => !!mention);
            return onChange(routeMentions);
          }

          const route = allRoutes.find(
            (_route) => _route.gtfsId === choices.routeId,
          );

          if (route) {
            return onChange([routeToRouteMention({ route })]);
          }
        }}
        components={{
          Option: (optionProps: OptionProps<any>) =>
            Option({
              optionProps,
              optionRefCallback: (el: HTMLDivElement) => {
                if (
                  el?.offsetWidth &&
                  !optionsWidths.current.find(
                    (o) => optionProps.data.index === o.index,
                  )
                ) {
                  optionsWidths.current.push({
                    index: optionProps.data.index,
                    width: el.offsetWidth + (isSubway(feedId) ? 6 : 10), // Option margin right
                  });
                }
              },
              isFocused: optionProps.data.index === focusedOptionProps.index,
            }),
          Placeholder,
          ValueContainer,
          Menu: (menuProps: MenuProps<any>) =>
            Menu({
              menuProps,
              enableQuickSelect,
              isAgencyWide,
              includeAllRoutes: Boolean(includeAllRoutes),
              onChange,
              onRadioChange,
              menuRefCallBack: (el: HTMLDivElement) => {
                if (
                  el?.offsetWidth &&
                  itemsContainerWidth.current !== el.offsetWidth
                ) {
                  itemsContainerWidth.current = el.offsetWidth - 40; // Options container padding
                }
              },
            }),
        }}
        styles={{
          menu: (provided: MenuProps) => ({
            ...provided,
            marginTop: '-1px',
            borderRadius: '0 0 4px 4px',
            boxShadow: 'none',
            border: `1px solid ${Theme.colors.accent3}`,
            borderTop: '1px solid #EAEAEA',
            zIndex: 100,
            ...SELECT_OVERLAY_STYLES.menu,
          }),
          menuList: (provided: {}) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingRight: '1px',
            paddingLeft: '1px',
          }),
          option: () => ({
            background: 'none',
            cursor: 'pointer',
            padding: 0,
            width: 'auto',
          }),
          valueContainer: (provided: CSSObjectWithLabel) => ({
            ...provided,
            display: 'flex',
            paddingLeft: Theme.spacing.small,
          }),
          placeholder: (
            provided: {},
            state: { isFocused: boolean; isDisabled: boolean },
          ) => ({
            ...provided,
            paddingLeft: 0,
            color: getSelectPlaceholderColor(state.isFocused, state.isDisabled),
          }),
          control: (provided: {}, state: { isFocused: boolean }) => {
            return {
              boxShadow: `inset 0 0 4px ${Theme.colors['border-dark']}`,
              ...(state.isFocused ? SELECT_OVERLAY_STYLES.control : {}),
            };
          },
          ...selectProps.selectStyles,
        }}
        {...rest}
      />
    </div>
  );
};
export default RouteSelector;
