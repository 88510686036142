/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { useHistory, Link } from 'react-router-dom';

import AppLink, { ApplinkStyles } from '../app-link';
import Button, { plainStyle as buttonPlainStyle } from '../Button';
import ImpactsDisplay, { IRawImpact } from './impacts-display';
import MessageDisplay from './MessageDisplay';
import PublishStatusList from './PublishStatusList';
import PublishTimes from '../publish-times/PublishTimes';
import { useFeedId } from '../../../contexts/FeedId';
import * as S from './message-card.styled';
import theme, { ThemeType } from '../../../theme';
import { centerRow } from '../styles';
import EventStamp from '../event-stamp';
import {
  GtfsEntitySelectorInput,
  PublishStatus,
} from '../../../generated/global-types';
import { DraftMessages_draftMessages_DraftMessagesConnection_nodes_DraftMessage_author_User as DraftMessages_draftMessages_nodes_author } from '../../../generated/DraftMessages';

export interface IMessageCardProps {
  className?: string;
  id?: number;
  eventId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  endAt?: Date;
  entitySelectors?: GtfsEntitySelectorInput[];
  taggedTrips?: string[];
  bodyHtml: string;
  onClear?: (id: number) => void;
  onDelete?: () => void;
  webStatus?: PublishStatus;
  screensStatus?: PublishStatus;
  emailSmsStatus?: PublishStatus;
  tweetsStatus?: PublishStatus;
  authorName?: string;
  hasUpdateLink?: boolean;
  isUpdate?: boolean;
  impacts?: IRawImpact[];
  impactsLimit?: number;
  author?: DraftMessages_draftMessages_nodes_author;
  isDraft?: boolean;
}

const MessageCard: React.FC<IMessageCardProps> = ({
  id,
  eventId,
  createdAt,
  updatedAt,
  endAt,
  taggedTrips,
  bodyHtml,
  onClear,
  onDelete,
  webStatus,
  screensStatus,
  emailSmsStatus,
  tweetsStatus,
  isUpdate,
  authorName,
  hasUpdateLink = true,
  impacts,
  impactsLimit,
  className,
  isDraft,
  author,
}) => {
  const feedId = useFeedId();
  const history = useHistory();
  const html = bodyHtml;
  const viewDetailLink = `/${feedId}/events/${eventId}`;

  const {
    location: { search: filters },
  } = history;

  const MessageCardButtons = () => (
    <S.LinksContainer>
      {isDraft ? (
        <React.Fragment>
          {onDelete && <S.PlainButton onClick={onDelete}>Delete</S.PlainButton>}
          <div css={[centerRow]}>
            {hasUpdateLink && (
              <Button
                type="button"
                size="small"
                onClick={() =>
                  history.push(`/${feedId}/events/${eventId}/update`)
                }
              >
                Update
              </Button>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            {isUpdate && (
              <S.ViewHistoryContainer>
                <S.HistoryCircle />
                <AppLink
                  to={viewDetailLink}
                  styleVariations={[ApplinkStyles.PLAIN_BUTTON]}
                  css={(theme: ThemeType) => css`
                    margin-top: 24px;
                    padding-left: 8px;
                    ${theme.typography.sizes.xsmall};
                  `}
                >
                  View History
                </AppLink>
              </S.ViewHistoryContainer>
            )}
          </div>
          <div css={[centerRow]}>
            {onClear && id && (
              <div
                css={css`
                  margin-top: 24px;
                  margin-left: 12px;
                `}
              >
                <Button type="button" size="small" onClick={() => onClear(id)}>
                  Clear
                </Button>
              </div>
            )}
            <div
              css={css`
                margin-top: 24px;
                margin-left: 12px;
              `}
            >
              {hasUpdateLink && (
                <AppLink
                  to={`/${feedId}/events/${eventId}/update`}
                  styleVariations={[
                    ApplinkStyles.PRIMARY_BUTTON,
                    ApplinkStyles.SMALL_BUTTON,
                  ]}
                >
                  Update
                </AppLink>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </S.LinksContainer>
  );

  const draftPublishTimesProps = {
    isCancelled: false,
    showAllActionsDates: false,
    clearedBy: author?.name,
    updatedAt: updatedAt === createdAt ? undefined : updatedAt,
    seperateUpdateCreateDates: true,
    verboseUpdateText: true,
    showLiveTime: {
      created: false,
      updated: false,
    },
  };

  return (
    <S.Container
      className={className}
      expires={endAt === null}
      isDraft={isDraft}
    >
      <S.Content>
        <S.ContentRow>
          <ImpactsDisplay
            impacts={impacts}
            trips={taggedTrips}
            limit={impactsLimit}
          />
          <S.DisplayContainer>
            <S.DisplayContainerInner>
              <MessageDisplay
                css={css`
                  background-color: #f8f8f8;
                  [data-bullet-display='generic'] {
                    background-color: #f8f8f8 !important;
                    padding: 0px !important;
                  }
                `}
                innerHtml={html}
              >
                <S.DisplayContentAfter>
                  <div
                    css={(theme: ThemeType) => css`
                      ${theme.typography.sizes.xsmall};
                      margin-right: 8px;
                    `}
                  >
                    {createdAt && (
                      <EventStamp
                        date={updatedAt ?? createdAt}
                        name={authorName}
                      />
                    )}
                  </div>
                  <Link
                    to={{
                      pathname: viewDetailLink,
                      state: { prevFilters: isDraft ? filters : '' },
                    }}
                    css={css`
                      ${theme.typography.sizes.xsmall};
                      ${buttonPlainStyle};
                      &:focus-visible {
                        outline: none;
                        box-shadow: unset;
                        :after {
                          content: '';
                          top: -2px;
                          bottom: -2px;
                          right: -2px;
                          left: -2px;
                          border: 1px solid ${theme.colors.accent3};
                          border-radius: 4px;
                          position: absolute;
                        }
                      }
                    `}
                  >
                    View Detail
                  </Link>
                </S.DisplayContentAfter>
              </MessageDisplay>
              <S.AfterMessageDisplayContainer>
                {isUpdate && <S.HistoryLine />}
                <MessageCardButtons />
              </S.AfterMessageDisplayContainer>
            </S.DisplayContainerInner>
          </S.DisplayContainer>
          <PublishStatusList
            webStatus={webStatus}
            emailSmsStatus={emailSmsStatus}
            tweetsStatus={tweetsStatus}
            screensStatus={screensStatus}
          />
          <PublishTimes
            css={(theme: ThemeType) => css`
              box-sizing: border-box;
              padding: 0 ${theme.spacing.small};
              flex: 0 0 180px;
              display: flex;
              flex-direction: column;
              border-left: 1px solid #f8f8f8;
            `}
            createdAt={createdAt}
            endAt={endAt}
            useNotApplicableEndAtFallback={webStatus === PublishStatus.INACTIVE}
            {...(isDraft ? draftPublishTimesProps : {})}
            updatedAt={updatedAt}
          />
        </S.ContentRow>
      </S.Content>
    </S.Container>
  );
};

export default MessageCard;
