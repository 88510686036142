/** @jsxImportSource @emotion/react */

import * as React from 'react';
import pluralize from 'pluralize';
import { SerializedStyles, css } from '@emotion/react';

import theme from 'theme';
import Bullet, { BulletSize } from 'components/common/Bullet';
import CustomTagsTargetingDisplay from 'components/common/custom-tags-targeting-display';

import { Terminals } from './constants';
import { CardinalDirection as Directionality } from 'generated/global-types';
import * as S from './route-range-stop-display.styled';
import { TagsSelection } from '../../types';

interface RouteRangeStopDisplayProps {
  directionality: Directionality | null;
  gtfsRouteId: string;
  stopCount: number;
  range?: string | null;
  screens?: string[] | null;
  tags?: TagsSelection | null;
  additionalStyles?: SerializedStyles;
}

export const buildRange = (
  directionality: Directionality | null,
  selectedNamedStops: { name: null | string }[],
) => {
  const [[first], last] = [
    selectedNamedStops,
    selectedNamedStops[selectedNamedStops.length - 1],
  ];
  if (!first || !last) {
    return undefined;
  }
  if (first.name === last.name) {
    return first.name;
  }

  const joiner = ' - ';

  const orderedNames =
    directionality === Directionality.SOUTH ||
    directionality === Directionality.WEST
      ? [last.name, first.name]
      : [first.name, last.name];

  return orderedNames.join(joiner);
};

const DirectionalityLabels = {
  [Directionality.NORTH]: 'Northbound',
  [Directionality.SOUTH]: 'Southbound',
  [Directionality.EAST]: 'Eastbound',
  [Directionality.WEST]: 'Westbound',
};

const RouteRangeStopDisplay: React.FC<RouteRangeStopDisplayProps> = ({
  directionality,
  gtfsRouteId,
  stopCount,
  range = stopCount === 0 ? 'No Stations' : 'All Stations',
  screens,
  tags,
  additionalStyles,
}) => {
  return (
    <S.Container css={additionalStyles}>
      <S.Direction>
        <Bullet size={BulletSize.small} routeId={gtfsRouteId} />
        {directionality
          ? DirectionalityLabels[directionality]
          : 'Both Directions'}
        {directionality !== null &&
          Terminals[gtfsRouteId]?.[directionality] && (
            <S.TerminalStation>
              {Terminals[gtfsRouteId]?.[directionality]}
            </S.TerminalStation>
          )}
      </S.Direction>
      <S.Stations>
        <span>{range}</span>
        <span>{pluralize('Stations', stopCount, true)}</span>
      </S.Stations>
      <S.Screens>
        <span>{screens?.join(', ')}</span>
      </S.Screens>
      <CustomTagsTargetingDisplay
        tags={tags ?? {}}
        extraStyles={css`
          ${theme.typography.sizes.xsmall};
        `}
      />
    </S.Container>
  );
};

export default RouteRangeStopDisplay;
