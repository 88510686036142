/** @jsxImportSource @emotion/react */

import { EditorComponent, Remirror, useRemirror } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';
import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  EditorState,
  EMPTY_PARAGRAPH_NODE,
  findTextNodes,
  prosemirrorNodeToHtml,
  RemirrorContentType,
  RemirrorEventListenerProps,
} from 'remirror';
import { RouteMention, StopMention, TripMention } from 'types';
import { FeedId } from '@mta-live-media-manager/shared';
import { remirrorExtensions } from './remirror-extensions';
import { isOutfront } from 'utils/feed-switches';
import {
  MentionSuggestions,
  MentionSuggestionsVariables,
} from 'generated/MentionSuggestions';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useFeedId } from 'contexts/FeedId';
import { uniq } from 'lodash';
import { MentionType } from 'generated/global-types';
import Suggestions, { getCustomIconMentions } from './suggestions';
import { handleSanitizationAndChange } from './manual-state-handlers';
import {
  mentionCss,
  separatorCss,
  editorWrapperCss,
} from './editor-styles.styled';
import RichEditingMenu from './rich-editing-menu';
import { getSuggestionProps, ISuggestion } from './editor-utils';
import EditorLabel from './editor-label';
import MirrorListener from './mirror-listener';
import classNames from 'classnames';

const MentionSuggestionsQuery = loader(
  '../../../../graphql/MentionSuggestions.gql',
);
export interface IEditorProps {
  onStateChange: (
    params: RemirrorEventListenerProps<Remirror.Extensions>,
  ) => void;
  className?: string;
  invalid?: boolean;
  shouldMirror?: boolean;
  id: string;
  initialContent?: RemirrorContentType;
  labelText?: string;
  maxLength?: {
    max: number;
    current: number;
  };
  mirrorState?: EditorState[];
  onBlur?: (params: RemirrorEventListenerProps<Remirror.Extensions>) => void;
  onChange?: (params: RemirrorEventListenerProps<Remirror.Extensions>) => void;
  onFocus?: (params: RemirrorEventListenerProps<Remirror.Extensions>) => void;
  relatedGtfsIds?: string[];
  shouldFormatOnPaste?: boolean;
  value?: EditorState;
  placeholder?: string;
  richEditing?: boolean;
  richEditingMenu?: boolean;
  showDetailMenu?: boolean;
  showSelectionControls?: boolean;
  showLinkButton?: boolean;
  showListButton?: boolean;
  enableLinkExtension?: boolean;
  altFeedsMentionable?: FeedId[];
  stationAlternativeEditor?: boolean;
}

export const getInitialContent = (
  initialHtml: string | undefined | null,
  initialContent: Readonly<EditorState> | undefined | null,
  { ignoreInitHtml }: { ignoreInitHtml?: boolean } = {},
) => {
  if (initialHtml && !ignoreInitHtml) {
    return initialHtml;
  }
  if (initialContent) {
    return prosemirrorNodeToHtml(initialContent?.doc);
  }
  return EMPTY_PARAGRAPH_NODE;
};

const Editor = forwardRef<any, IEditorProps>(
  (
    {
      onChange,
      relatedGtfsIds,
      altFeedsMentionable = [],
      showDetailMenu = false,
      id,
      maxLength,
      labelText,
      shouldMirror,
      mirrorState,
      className,
      placeholder,
      stationAlternativeEditor,
      ...props
    },
    ref,
  ) => {
    const [gtfsQuery, setGtfsQuery] = useState('');
    const feedId = useFeedId();
    const [isCrossAgencySuggestion, setIsCrossAgency] = useState(false);
    const outfrontFeed = isOutfront(feedId) ? [FeedId.NYCTSubway] : [];
    const [activeSuggestionIdx, setActiveSuggestionIdx] = useState(0);

    let feedIds = uniq([feedId, ...altFeedsMentionable, ...outfrontFeed]);
    if (isCrossAgencySuggestion) {
      feedIds = [
        FeedId.BT,
        FeedId.LIRR,
        FeedId.MNR,
        FeedId.NYCTBus,
        FeedId.NYCTSubway,
      ].filter((agency) => agency !== feedId);
    }
    const customSuggestions: ISuggestion[] = feedIds.flatMap((id) =>
      getCustomIconMentions(id, gtfsQuery || '', activeSuggestionIdx),
    );
    const { data: mentionData } = useQuery<
      MentionSuggestions,
      MentionSuggestionsVariables
    >(MentionSuggestionsQuery, {
      skip: !gtfsQuery,
      variables: {
        feedIds,
        // includeCrossAgencyRoutes: isCrossAgencySuggestion,
        query: gtfsQuery || '',
        preferringRouteIds: relatedGtfsIds || [],
      },
    });

    const suggestions: ISuggestion[] =
      mentionData?.mentionSuggestions?.nodes.map((suggestion) => {
        return getSuggestionProps(suggestion, isCrossAgencySuggestion);
      }) || [];
    const { manager, state, setState, getContext } = useRemirror({
      extensions: remirrorExtensions({ showDetailMenu }),
      content: props.initialContent || '<p></p>',
      selection: 'start',
      stringHandler: 'html',
    });
    useImperativeHandle(ref, () => getContext(), [getContext]);

    return (
      <div className="rm-editor-wrapper" css={editorWrapperCss}>
        <AllStyledComponent className="rm-theme">
          <Remirror
            manager={manager}
            state={state}
            placeholder={placeholder}
            onFocus={props.onFocus}
            onChange={(
              params: RemirrorEventListenerProps<Remirror.Extensions>,
            ) => {
              const newState = handleSanitizationAndChange(params);
              if (onChange) {
                onChange(newState);
              }
              props.onStateChange(params);
              setState(newState.state);
            }}
          >
            <div
              css={[mentionCss]}
              className={classNames(
                stationAlternativeEditor && 'alt-station-editor',
                'rm-editor-container',
                className,
              )}
            >
              <EditorLabel
                id={id}
                maxLength={maxLength}
                labelText={labelText}
              />
              <RichEditingMenu showDetailMenu={showDetailMenu} />
              <div css={separatorCss} />
              <Suggestions
                feedId={feedId}
                isCrossAgency={isCrossAgencySuggestion}
                items={[...customSuggestions, ...suggestions]}
                onChange={(query, activeIdx, suggestName) => {
                  setIsCrossAgency(suggestName === 'cross-agency');
                  setGtfsQuery(query);
                  setActiveSuggestionIdx(activeIdx);
                }}
              />
              <EditorComponent />
              <MirrorListener
                shouldMirror={shouldMirror}
                mirrorState={mirrorState}
              />
            </div>
          </Remirror>
        </AllStyledComponent>
      </div>
    );
  },
);

export const getMentionsByType = (state?: EditorState | null) => {
  if (!state) {
    return {
      routes: [],
      trips: [],
      stops: [],
    };
  }

  const textNodes = findTextNodes({
    node: state.doc,
  });

  const textNodesWithMentions = textNodes.filter((textNode) => {
    return (
      textNode.node.marks &&
      textNode.node.marks.length === 1 &&
      textNode.node.marks[0].type.name === 'mention'
    );
  });

  const routes: Set<RouteMention> = new Set();
  const trips: Set<TripMention> = new Set();
  const stops: Set<StopMention> = new Set();

  textNodesWithMentions.forEach((textNodeWithMention) => {
    const mentionMarkAttributes = textNodeWithMention.node.marks[0].attrs;
    const type = mentionMarkAttributes['data-bullet-type'];

    if (!type) {
      // ignore any invalid suggestions in markup
      return;
    }

    const boroughs = mentionMarkAttributes['data-bullet-boroughs'] || null;
    const relatedRouteIds =
      mentionMarkAttributes['data-bullet-route-names'] || null;
    const title = mentionMarkAttributes['data-bullet-title'];
    const meta = mentionMarkAttributes['data-bullet-meta'];
    const isCrossAgencyTag = mentionMarkAttributes['data-bullet-agency'];

    if (isCrossAgencyTag) {
      return;
    }

    // fallback to regular ID for existing mentions that do not have "data-bullet-id"
    const id =
      mentionMarkAttributes['data-bullet-id'] || mentionMarkAttributes.id;

    if (type === MentionType.ROUTE) {
      routes.add({
        id,
        boroughs,
        relatedRouteIds,
        name: title,
        type: MentionType.ROUTE,
        routeId: id,
        isAffected: true,
        meta: null,
      });
    } else if (type === MentionType.TRIP) {
      trips.add({
        id,
        boroughs,
        relatedRouteIds,
        name: title,
        type: MentionType.TRIP,
        tripId: id,
        isAffected: true,
        meta,
      });
    } else if (type === MentionType.STOP) {
      stops.add({
        id,
        boroughs,
        relatedRouteIds,
        name: title,
        type: MentionType.STOP,
        stopId: id,
        isAffected: true,
        meta: null,
      });
    }
  });

  return {
    routes: Array.from(routes),
    trips: Array.from(trips),
    stops: Array.from(stops),
  };
};

export default Editor;
