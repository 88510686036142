import { FeedId } from 'contexts/FeedId';
import { OptionType } from 'types/screen-tags';
import { isOutfront } from 'utils/feed-switches';

// Tag options
const NYCT_SBWY_ADA_OPTIONS = ['true', 'false'];
const NYCT_SBWY_DIRECTION_OPTIONS = ['northbound', 'southbound'];
const NYCT_SBWY_INDOOR_OUTDOOR_OPTIONS = ['indoor', 'outdoor'];
const NYCT_SBWY_LANGUAGE_OPTIONS = [
  'spanish',
  'chinese',
  'korean',
  'russian',
  'greek',
  'polish',
  'other_indic',
  'french_creole',
];
const NYCT_SBWY_STATION_PLACEMENT_OPTIONS = [
  'platform',
  'fare_control',
  'mezzanine',
  'GCT_static',
  'GCT_motion',
];

const getMappedOptions = (options: string[]) =>
  options.map((o) => ({
    label: o
      .split('_')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(' '),
    value: o,
  }));

export const REMOVED_TAG_VALUE = 'false'; // Deleted tags are set to false
export const ADDED_TAG_VALUE = 'true';
export const DEFAULT_TAG_VALUE = 'unknown';
export const TAG_OPTIONS_SEPARATOR = ', ';
export const CRITERIA_PREFIX = 'transitapi_';

export enum TagNames {
  ADA = 'transitapi_ada',
  Direction = 'transitapi_direction',
  IndoorOutdoor = 'transitapi_indoor_outdoor',
  Language = 'transitapi_language',
  StationPlacement = 'transitapi_station_placement',
}

// Tag names that should be splitted into "separate" criterias
// when sending to DB/OF with their corresponding prefix
export const TagNamesToSeparatePrefix: { [key: string]: string } = {
  [TagNames.Language]: `${CRITERIA_PREFIX}lep_`,
  [TagNames.Direction]: CRITERIA_PREFIX,
};

// Agencies that currently support custom taging
export const SUPPORTED_TAGS_AGENCIES = [
  FeedId.NYCTSubway,
  FeedId.OutfrontDEV,
  FeedId.OutfrontQA,
];

export const feedTagNameOptions: {
  [key: string]: OptionType[];
} = {
  [FeedId.NYCTSubway]: [
    {
      label: 'ADA',
      tagName: TagNames.ADA,
      options: getMappedOptions(NYCT_SBWY_ADA_OPTIONS),
      isSingle: true,
    },
    {
      label: 'Directionality',
      tagName: TagNames.Direction,
      options: getMappedOptions(NYCT_SBWY_DIRECTION_OPTIONS),
      isSingle: false,
    },
    {
      label: 'Indoor/Outdoor',
      tagName: TagNames.IndoorOutdoor,
      options: getMappedOptions(NYCT_SBWY_INDOOR_OUTDOOR_OPTIONS),
      isSingle: true,
    },
    {
      label: 'Languages',
      tagName: TagNames.Language,
      options: getMappedOptions(NYCT_SBWY_LANGUAGE_OPTIONS),
      isSingle: false,
    },
    {
      label: 'Station Placement',
      tagName: TagNames.StationPlacement,
      options: getMappedOptions(NYCT_SBWY_STATION_PLACEMENT_OPTIONS),
      isSingle: true,
    },
  ],
};

export const getFeedTagOptions = (feedId: string): OptionType[] => {
  if (isOutfront(feedId as FeedId)) {
    return feedTagNameOptions[FeedId.NYCTSubway];
  }
  return feedTagNameOptions[feedId];
};
