/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import pluralize from 'pluralize';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';

import useNamedStops from 'hooks/useNamedStops';
import { TagsSelection } from 'types';
import theme from 'theme';
import Bullet, { BulletSize } from 'components/common/Bullet';
import { TargetType, ContextualSelectorInput } from 'generated/global-types';
import { useFeedId } from 'contexts/FeedId';
import { getVehicleName } from 'utils/feed-switches';
import { getLabelFromTargetTypes } from 'components/pages/Screen/in-screens-preview/helpers';
import AppLink from 'components/common/app-link';
import { getScreenIconFromScreenDetais } from 'components/common/campaign-form/single-screen-targeting';
import CustomTagsTargetingDisplay from 'components/common/custom-tags-targeting-display';

import {
  GetScreenDetailsByName,
  GetScreenDetailsByNameVariables,
} from 'generated/GetScreenDetailsByName';

const GetScreenDetailsByNameQuery = loader(
  '../../../graphql/GetScreenDetailsByName.gql',
);

interface CampaignTargetingProps {
  selectors: ContextualSelectorInput[];
  targetTypes?: TargetType[];
  screenName?: string | null;
  tags?: TagsSelection;
}

export const CampaignTargeting: React.FC<CampaignTargetingProps> = ({
  selectors,
  targetTypes = [],
  screenName,
  tags = {},
  ...rest
}) => {
  const feedId = useFeedId();
  const vehicleName = getVehicleName(feedId);

  const isSingleScreenCampaign = !!screenName;

  const { data: screenDetailsData, loading: screenDetailsLoading } = useQuery<
    GetScreenDetailsByName,
    GetScreenDetailsByNameVariables
  >(GetScreenDetailsByNameQuery, {
    variables: { name: screenName ?? '' },
    skip: !isSingleScreenCampaign,
  });

  const wholeRouteIds: string[] = selectors
    .filter((s) => s.entitySelector.routeId)
    .map((s) => s.entitySelector.routeId || '');

  const stops: ContextualSelectorInput[] = selectors.filter(
    (s) => s.context.gtfsRouteId && s.entitySelector.stopId,
  );

  /*
   * Stops saved before the campaign targeting update will not have route information saved
   * on the selector. We'll display them using the legacy list.
   */
  const legacyStops: ContextualSelectorInput[] = selectors.filter(
    (s) => !s.context.gtfsRouteId && s.entitySelector.stopId,
  );

  const allStopIds: string[] = [
    ...stops.map((stop) => stop.entitySelector.stopId),
    ...legacyStops.map((stop) => stop.entitySelector.stopId),
  ].filter(Boolean) as string[];

  const namedStops = useNamedStops(allStopIds);

  const stopsByRouteId: {
    [key: string]: ContextualSelectorInput[];
  } = {};
  stops.forEach((stop) => {
    if (!stopsByRouteId[stop.context.gtfsRouteId]) {
      stopsByRouteId[stop.context.gtfsRouteId] = [];
    }

    stopsByRouteId[stop.context.gtfsRouteId] = [
      ...stopsByRouteId[stop.context.gtfsRouteId],
      stop,
    ];
  }, {});

  const groupedStopsByRouteId = groupBy(stopsByRouteId, (stops) => {
    const entitySelectors = stops.map((s) => s.entitySelector);
    const stopIds = entitySelectors.map((es) => es.stopId);
    return stopIds.join(',');
  });

  const uniqueTargetTypes = uniq(targetTypes);

  const wholeRoutesEl = wholeRouteIds.length ? (
    <li key={wholeRouteIds.join(',')}>
      <span>
        {wholeRouteIds.map((routeId) => (
          <Bullet
            key={routeId}
            routeId={routeId}
            size={BulletSize.small}
            style={{ marginRight: '2px' }}
          />
        ))}
        <span
          css={css`
            margin-left: 2px;
          `}
        >
          {pluralize.plural(vehicleName)}
        </span>
      </span>
      <span>All Stations</span>
      <span>{getLabelFromTargetTypes(uniqueTargetTypes, feedId)}</span>
      <CustomTagsTargetingDisplay tags={tags} />
    </li>
  ) : null;

  const groupedStopsEls = Object.keys(groupedStopsByRouteId).map((key) => {
    const selectors = groupedStopsByRouteId[key].flat();
    const uniqueRouteIds = uniq(
      selectors.map((selector) => selector.context.gtfsRouteId),
    );
    const uniqueStopIds = uniq(
      selectors.map((selector) => selector.entitySelector.stopId),
    );

    return (
      <li key={key}>
        <span>
          {uniqueRouteIds.map((routeId) =>
            routeId ? (
              <Bullet
                key={routeId}
                routeId={routeId}
                size={BulletSize.small}
                style={{ marginRight: '2px' }}
              />
            ) : null,
          )}{' '}
          {pluralize.plural(vehicleName)}
        </span>
        <span
          css={css`
            & > ::after {
              content: ', ';
              white-space: pre;
            }

            & > :last-of-type::after {
              content: '';
            }
          `}
        >
          {uniqueStopIds.map((stopId, idx) => {
            const namedStop = namedStops.find((s) => s.id === stopId);

            if (!namedStop) {
              return null;
            }

            return (
              <AppLink
                key={stopId || idx}
                css={css`
                  color: ${theme.colors.black};

                  &:hover {
                    opacity: 0.7;
                  }
                `}
                to={`/${feedId}/stops/${stopId}/screens`}
              >
                <span
                  css={css`
                    display: inline-block;
                  `}
                >
                  {namedStop.name}
                </span>
              </AppLink>
            );
          })}
        </span>
        <span>{getLabelFromTargetTypes(uniqueTargetTypes, feedId)}</span>
        <CustomTagsTargetingDisplay tags={tags} />
      </li>
    );
  });

  const legacyStopsItems = legacyStops.map((stop, idx) => {
    const namedStop = namedStops.find(
      (s) => s.id === stop.entitySelector.stopId,
    );

    if (!namedStop) {
      return null;
    }

    return (
      <li key={stop.entitySelector.stopId || idx}>
        <AppLink
          css={css`
            color: ${theme.colors.black};

            &:hover {
              opacity: 0.7;
            }
          `}
          to={`/${feedId}/stops/${stop.entitySelector.stopId}/screens`}
        >
          <span
            css={css`
              display: inline-block;
            `}
          >
            {namedStop.name}
          </span>
        </AppLink>
        <span>{getLabelFromTargetTypes(uniqueTargetTypes, feedId)}</span>
        <CustomTagsTargetingDisplay tags={tags} />
      </li>
    );
  });

  const singleScreen = (() => {
    const screenId = screenDetailsData?.screenDetails?.id;
    const screenType =
      screenDetailsData?.screenDetails?.deployType?.toLowerCase();
    return (
      <li key={screenName ?? 'single-screen'}>
        {getScreenIconFromScreenDetais(screenDetailsData?.screenDetails)}
        {!screenDetailsLoading && (
          <AppLink
            css={css`
              margin-top: 5px;
              text-decoration: underline;
              &:hover {
                opacity: 0.7;
              }
            `}
            to={`/${feedId}/screens/${screenId}?t=${screenType}`}
          >
            {screenDetailsData?.screenDetails?.actualName}
          </AppLink>
        )}
        <div
          css={css`
            margin-top: 20px;
          `}
        />
      </li>
    );
  })();

  return (
    <ul
      css={css`
        list-style: none;
        padding: 0;
        margin: 0;

        & > li {
          display: block;
          margin: 0;
          margin-bottom: 16px;
        }

        & > li > span {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
        }

        & > li:last-child {
          margin-bottom: 2px;
        }
      `}
      {...rest}
    >
      {isSingleScreenCampaign ? (
        <React.Fragment>{singleScreen}</React.Fragment>
      ) : (
        <React.Fragment>
          {wholeRoutesEl}
          {groupedStopsEls}
          {legacyStopsItems}
        </React.Fragment>
      )}
    </ul>
  );
};
