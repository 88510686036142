/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { Portal } from '@reach/portal';
import { useTooltip, TooltipPopup } from '@reach/tooltip';
import { ThemeType } from '../../theme';

const TRIANGLE_SIZE = 12;

const getPosition = (triggerRect: any, tooltipRect: any) => {
  const left = triggerRect.left + triggerRect.width - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;

  const position = {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - tooltipRect.height - TRIANGLE_SIZE - window.scrollY,
  };

  return position;
};

const TooltipTriangle: React.FC<{
  triggerRect: DOMRect;
  children?: React.ReactNode;
}> = ({ triggerRect }) => {
  return (
    <Portal>
      <div
        css={(theme: ThemeType) => css`
          position: absolute;
          z-index: 101;
          left: ${triggerRect.left + triggerRect.width - TRIANGLE_SIZE / 2}px;
          top: ${triggerRect.top - TRIANGLE_SIZE - window.scrollY}px;
          width: 10px;
          height: 10px;
          background: ${theme.colors.white};
          box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
        `}
      />
    </Portal>
  );
};

const Tooltip = ({
  children,
  isActive,
  label,
  width = 250,
}: {
  children: React.ReactElement;
  isActive: boolean;
  label: string;
  width?: number;
}) => {
  const [trigger, tooltip] = useTooltip();
  const { isVisible, triggerRect } = tooltip;

  if (!isActive) {
    return children;
  }

  const wrappedChildren = <span>{children}</span>;

  return (
    <React.Fragment>
      {React.cloneElement(wrappedChildren, trigger)}
      {isVisible && triggerRect && (
        <TooltipTriangle triggerRect={triggerRect} />
      )}
      <TooltipPopup
        {...tooltip}
        translate={undefined} // error in @reach/toolip type definitions v0.8.1
        label={label}
        position={getPosition}
        css={(theme: ThemeType) => css`
          z-index: 100;
          position: absolute;
          pointer-events: none;
          width: ${width}px;
          background: ${theme.colors.white};
          color: ${theme.colors.dark2};
          border: none;
          border-radius: 2px;
          box-shadow:
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12),
            0 1px 3px 0 rgba(0, 0, 0, 0.2);
          ${theme.typography.sizes.small};
          font-family: ${theme.typography.families.primary};
          text-align: center;
          padding: ${theme.spacing.small};
          white-space: pre-wrap;
        `}
      />
    </React.Fragment>
  );
};

export default Tooltip;
