/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { ThemeType } from 'theme';

import { ReactComponent as Clock } from '../../../images/white_clock.svg';
import { ReactComponent as Check } from '../../../images/green_check.svg';
import { ReactComponent as Warning } from '../../../images/icon_warning_black.svg';

export enum Channels {
  homepage = 'homepage',
  screens = 'screens',
}

export enum VisibilityIcons {
  LIVE = 'LIVE',
  CLOCK = 'CLOCK',
  WARNING = 'WARNING',
}

const ICONS: { [key in VisibilityIcons]: React.ReactNode } = {
  [VisibilityIcons.CLOCK]: <Clock />,
  [VisibilityIcons.LIVE]: <Check />,
  [VisibilityIcons.WARNING]: <Warning />,
};

const PlannedWorkVisibility: React.FC<{
  type: Channels;
  icon: VisibilityIcons | null;
}> = ({ type, icon }) => {
  const isHomepage = type === Channels.homepage;
  const showSymbol = icon !== null;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: ${isHomepage ? '-5px' : '0'};
      `}
    >
      <span
        css={css`
          margin-right: 5px;
          padding-top: 1px;
          padding-bottom: ${isHomepage && !showSymbol ? '24' : '1'}px;
        `}
      >
        {showSymbol && icon && ICONS[icon]}
      </span>
      <span
        css={({ typography }: ThemeType) => css`
          ${typography.sizes.small};
          line-height: 18px;
          ${!showSymbol &&
          css`
            margin-left: 12px;
            margin-top: 2.5px;
          `};
        `}
      >
        {isHomepage ? 'Homepage' : 'Screens'}
      </span>
    </div>
  );
};

export default PlannedWorkVisibility;
