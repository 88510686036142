/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ThemeType } from '../../../theme';

export const ImpactsDisplayContainer: React.FC<{
  isMultipleImpacts: boolean;
  children?: React.ReactNode;
}> = ({ isMultipleImpacts, ...rest }) => (
  <div
    css={css`
      box-sizing: border-box;
      flex: 0 0 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid #f8f8f8;
      padding: ${isMultipleImpacts ? '0px' : '24px 13px'};
    `}
    {...rest}
  />
);

export const AdditionalImpactsText: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={() => css`
      margin-top: 16px;
    `}
    {...props}
  />
);

export const RouteDisplayBulletList: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme) => css`
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      span {
        margin-right: ${theme.spacing.xxsmall};
        margin-bottom: ${theme.spacing.xxsmall};
      }
    `}
    {...props}
  />
);

export const AdditionalRoutesText: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.xsmall};
      color: ${theme.colors.black};
      text-align: center;
      text-transform: uppercase;
      margin-top: ${theme.spacing.xxsmall};
      font-family: ${theme.typography.families.primary};
    `}
    {...props}
  />
);
