/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { css } from '@emotion/react';
import {
  ListboxInput,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox';

import theme from 'theme';
import RouteSelector from 'components/common/form-elements/route-selector';
import BusRouteSelector from 'components/common/form-elements/route-selector/bus-route-selector';
import { useRoutesByFeedId } from 'contexts/Routes';
import { routeToRouteMention } from 'utils/route-mentions';
import { getPlannedWorkMessageTypeDescriptionsByFeed } from 'utils/message-type-display';
import { MESSAGE_TYPE_DESCRIPTIONS } from '@mta-live-media-manager/shared';
import { FeedId, useFeedId } from 'contexts/FeedId';
import { RouteMention } from 'types';
import Button from '../../common/Button';
import CrossIcon from '../../common/cross-icon';
import { ReactComponent as Plus } from '../../../images/plus.svg';
import { listboxList, listboxOption } from '../../common/styles';
import * as styles from './styles';
import { Status } from './types';
import StandardListboxButton from '../../../ui-kit/standard-listbox-button';

const statusContainer = css`
  display: flex;

  > * {
    flex: 1 1 50%;
  }
`;

const Statuses: React.FC<
  {
    value: Status[];
    onChange: (values: Status[]) => void;
  } & { children?: React.ReactNode }
> = ({ value: statuses, onChange }) => {
  const feedId = useFeedId();
  const allRoutes = useRoutesByFeedId(feedId);

  const statusOptions = getPlannedWorkMessageTypeDescriptionsByFeed(feedId);
  const [removedCount, setRemovedCount] = useState(0);

  useEffect(() => {
    // Check statuses to make sure they're currently valid for new messages and remove from list if they're not
    statuses.map(({ status }, index) => {
      if (
        status !== undefined &&
        !statusOptions.some((option) => option.value === status)
      ) {
        onChange(
          statuses.length <= 1
            ? [{} as Status]
            : statuses.filter((_value, i) => i !== index),
        );
      }
      return undefined;
    });
  }, []);

  return (
    <React.Fragment>
      {statuses.map(({ routeIds = [], status, isAgencyWide }, index) => {
        const key = `${index}-${removedCount}`;
        const linesElement = <label htmlFor={`lines${index}`}>Line(s)</label>;
        const statusElement = <label htmlFor={`status${index}`}>Status</label>;

        const linesLabel =
          index === 0 ? (
            linesElement
          ) : (
            <VisuallyHidden>{linesElement}</VisuallyHidden>
          );

        const statusLabel =
          index === 0 ? (
            statusElement
          ) : (
            <VisuallyHidden>{statusElement}</VisuallyHidden>
          );

        const routeSelectorProps = {
          id: `lines${index}`,
          css: css`
            > div > div {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              min-height: 49px;
            }
          `,
          isMulti: true,
          enableQuickSelect: true,
          routes: allRoutes
            .filter((r) => routeIds.includes(r.gtfsId))
            .map((r) => routeToRouteMention({ route: r })),
          isAgencyWide,
          onChange: (newValue: RouteMention[], isAgencyWide?: boolean) => {
            const newStatus = {
              routeIds: newValue.map((r) => r.routeId),
              status,
              isAgencyWide,
            };
            onChange(statuses.map((v, i) => (i === index ? newStatus : v)));
          },
        };

        return (
          <div key={key} css={statusContainer}>
            <div className="statusSection" css={styles.inputWrapper}>
              {linesLabel}
              {feedId === FeedId.NYCTBus ? (
                <BusRouteSelector {...routeSelectorProps} />
              ) : (
                <RouteSelector {...routeSelectorProps} />
              )}
            </div>
            <div
              className={`statusSection statusGroup ${
                statuses.length === 1 ? 'noStatuses' : 'hasStatuses'
              }`}
              css={css`
                ${styles.inputWrapper};
                [data-state='expanded'] [data-reach-listbox-button] {
                  border-radius: 0px 4px 0px 0px;
                }
              `}
            >
              {statusLabel}
              <ListboxInput
                id={`status${index}`}
                value={status || ''}
                css={styles.listbox}
                onChange={(newValue) => {
                  const newStatus = {
                    routeIds,
                    status: newValue,
                    isAgencyWide,
                  } as Status;
                  onChange(
                    statuses.map((v, i) => (i === index ? newStatus : v)),
                  );
                }}
              >
                <StandardListboxButton>
                  {status && MESSAGE_TYPE_DESCRIPTIONS[status]}
                </StandardListboxButton>
                <ListboxPopover>
                  <ListboxList
                    css={[
                      listboxList,
                      css`
                        [data-reach-listbox-option] {
                          ${theme.typography.sizes.medium};
                        }

                        [data-reach-listbox-option][aria-selected='true'] {
                          background-color: ${theme.colors[
                            'select-option-hover'
                          ]};
                        }
                      `,
                    ]}
                  >
                    {statusOptions.map(({ label, value }) => (
                      <ListboxOption
                        css={listboxOption}
                        value={value}
                        key={value}
                      >
                        {label}
                      </ListboxOption>
                    ))}
                  </ListboxList>
                </ListboxPopover>
              </ListboxInput>
            </div>
            {statuses.length > 1 && (
              <Button
                plain
                type="button"
                onClick={() => {
                  setRemovedCount(removedCount + 1);
                  onChange(statuses.filter((_value, i) => i !== index));
                }}
                title="Remove"
                css={css`
                  background-color: #eaeaea;
                  border-radius: 0 4px 4px 0;
                  border: 1px solid ${theme.colors['border-dark']};
                  flex: 0 0 48px;
                  align-self: flex-end;
                  margin-bottom: 12px;
                  padding: 16px 0;
                `}
              >
                <CrossIcon size="10px" aria-hidden="true" />
                <VisuallyHidden>Remove</VisuallyHidden>
              </Button>
            )}
          </div>
        );
      })}
      <Button plain type="button" onClick={() => onChange(statuses.concat({}))}>
        <Plus aria-hidden="true" /> Add status
      </Button>
    </React.Fragment>
  );
};

export default Statuses;
