/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import HR from 'components/common/HR';

import theme, { ThemeType } from '../../../theme';

import { Table, TableBody, TableData } from '../../common/table-elements';

const COL_WIDTHS: { [key: string]: string } = {
  label: '200px',
  value: '300px',
};

export const TagsHolder: React.FC<PropsWithChildren> = (props) => (
  <div
    css={css`
      margin-top: 24px;
      display: inline-block;
    `}
    {...props}
  />
);

export const TagsTable: React.FC<
  PropsWithChildren<{ isEditing?: boolean }>
> = ({ isEditing, ...rest }) => (
  <Table
    css={css`
      margin-bottom: 24px;
      margin-top: -20px;
      ${!isEditing &&
      css`
        th {
          display: inline-block;
          width: ${COL_WIDTHS.label};
        }
        tbody {
          display: block;
          max-height: 490px;
          overflow-y: scroll;
        }
      `}
    `}
    {...rest}
  />
);

export const TagsTableBody: React.FC<PropsWithChildren> = (props) => (
  <TableBody
    css={css`
      box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    `}
    {...props}
  />
);

export const TagsTableData: React.FC<PropsWithChildren> = (props) => (
  <TableData
    hasLeftBorder={false}
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      padding: 8px 20px;
      min-width: ${COL_WIDTHS.label};

      &:nth-of-type(even) {
        min-width: ${COL_WIDTHS.value};
      }
    `}
    {...props}
  />
);

export const ContentWrapper: React.FC<
  PropsWithChildren<{
    hideSeparator?: boolean;
  }>
> = ({ hideSeparator, ...rest }) => (
  <React.Fragment>
    <div
      css={css`
        padding: 40px;
      `}
      {...rest}
    />
    {!hideSeparator && <HR />}
  </React.Fragment>
);

export const DetailsWrapper: React.FC<PropsWithChildren> = (props) => (
  <React.Fragment>
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      `}
      {...props}
    />
    <HR />
  </React.Fragment>
);

export const Details: React.FC<PropsWithChildren> = (props) => (
  <div
    css={css`
      padding: 40px;
      padding-bottom: 32px;
    `}
    {...props}
  />
);

export const InternalNotes: React.FC<PropsWithChildren> = (props) => (
  <Details
    css={css`
      padding-top: 32px;
      background: ${theme.colors.dark8};
    `}
    {...props}
  />
);
