/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useCallback } from 'react';
import { ThemeType } from 'theme';
import Toggle from 'components/common/form-elements/Toggle';

interface ScreenLockToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ScreenLockToggle: React.FC<ScreenLockToggleProps> = ({
  checked,
  onChange,
}) => {
  const handleChange = useCallback(
    (evt) => onChange(evt.target.checked),
    [onChange],
  );
  return (
    <div
      css={css`
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 12px;
        grid-auto-columns: max-content;
        align-items: center;
        position: relative;
      `}
    >
      <Toggle
        id="toggle_screen_lock"
        css={css`
          display: flex;
          justify-content: center;
        `}
        checked={checked}
        onChange={handleChange}
      />
      <label
        htmlFor="toggle_screen_lock"
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.large}
          font-weight: ${theme.typography.weights.bold};
        `}
      >
        Lock Screen
      </label>
      <p
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.small}
          padding: 0;
          margin: 0;
        `}
      >
        When a screen is locked, it displays only selected content and nothing
        else.
      </p>
    </div>
  );
};

export default ScreenLockToggle;
