import { useApolloClient } from '@apollo/client';
import { useChainedCommands, useEditorEvent } from '@remirror/react';
import { getSuggestionProps } from 'components/common/form-elements/editor/editor-utils';
import { useFeedId } from 'contexts/FeedId';
import {
  GetMentionsForMatchers,
  GetMentionsForMatchersVariables,
} from 'generated/GetMentionsForMatchers';
import { loader } from 'graphql.macro';
import { useState, useCallback } from 'react';
import { validateUrl } from 'utils/validations';

const MentionSuggestionsQuery = loader('../graphql/GetMentionsForMatchers.gql');
// it's unclear to me that they also paste into the [1] format. I'll leave this here for now.

type PasteExtract = {
  type: 'text' | 'mention';
  value: string;
};

const extractPaste = (data: string) => {
  const re = /:([a-zA-z0-9-]*?):/g;
  let parsed: PasteExtract[] = [];
  let start = 0;
  const matches = data.matchAll(re);
  for (const match of matches) {
    const [mention, idx] = [match[0], match.index || 0];
    const end = idx ? idx - 1 : idx;

    parsed = parsed.concat(
      {
        type: 'text',
        value: data.substring(start, end + 1),
      },
      {
        type: 'mention',
        value: mention,
      },
    );

    start = idx + mention.length;
  }

  // capture the last possible substring, in case there's text after the last mention.
  parsed.push({ type: 'text', value: data.substring(start) });

  return parsed;
};

/*
  The flow for pasting contextual data should go like this:

  1. User pastes content that could possibly contain mention data
  2. Text gets split up, separating what is considered regular text, and what is mention-specific
  3. If no mentions were part of the content pasted, then we need to let the default paste event take over
  4. If there are mentions, query the backend for what the mentions represent, state is set to loading (so editor can be disabled I guess?)
  5. Use `ChainedCommands.insertText|createMention` (https://remirror.io/docs/hooks/use-chained-commands) while iterating through the fragments
  6. loading has finished

  This could use an error state but I don't believe there is one set for suggestions.
*/
export const usePaste = () => {
  const feedId = useFeedId();
  const c = useChainedCommands();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [clipboardData, setClipboardData] = useState<PasteExtract[]>([]);

  const pasteHandler = useCallback(
    (event: ClipboardEvent) => {
      if (validateUrl(event.clipboardData?.getData('text') || '')) {
        return;
      }
      //
      let data = event.clipboardData?.getData('text/html') || '';
      // if the source of data is from within Mercury, use default behavior
      if (data && !data.includes('data-bullet')) {
        // stop normal behavior, since this will include slack-like textual data
        // avoid the slack html (which might be a better solution?)
        data = event.clipboardData?.getData('text') || '';
        setIsLoading(true);
        // break up the string into fragments, and then analyze each one
        const stringFragments = extractPaste(data);
        setClipboardData(stringFragments);

        // if we're just pasting a bunch of text then let default behavior take over
        if (stringFragments.every(({ type }) => type === 'text')) {
          return;
        }
        event.preventDefault();

        client
          .query<GetMentionsForMatchers, GetMentionsForMatchersVariables>({
            variables: {
              matchers: stringFragments
                .filter((f) => f.type === 'mention')
                .map((f) => f.value),
              feedId,
            },
            query: MentionSuggestionsQuery,
          })
          .then((res) => {
            const data = res.data?.mentions?.nodes || [];
            for (const fragment of stringFragments) {
              if (fragment.type === 'text') {
                c.insertText(fragment.value);
              } else {
                const mention = data.find((m) => m.matcher === fragment.value);
                if (mention) {
                  c.createMention(
                    getSuggestionProps({
                      ...mention,
                      feedId,
                    } as unknown as any),
                  );
                }
              }
            }
            c.run();
          })
          .finally(() => {
            // this might be unnecessary, but lag times might increase on prod.
            setIsLoading(false);
          });
      }
    },
    [clipboardData, extractPaste, setIsLoading],
  );
  useEditorEvent('paste', pasteHandler);

  return isLoading;
};
