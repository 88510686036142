/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState } from 'react';
import theme from 'theme';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { UploadType } from 'hooks/useUpload';
import getScreenIcon from 'utils/get-screen-icon';

import {
  GtfsEntitySelectorInput,
  MentionType,
  SchedulePriority,
  ScreenType,
  TargetType,
  TransitDestination,
  ScreenOrientation,
  FeatureFlagName,
} from '../../../generated/global-types';
import { GetScreenDetailsByName_screenDetails_ScreensNormalized as IScreenDetails } from '../../../generated/GetScreenDetailsByName';
import { CommonUploadFragment } from 'generated/CommonUpload.fragment';
import { RouteMention, StopMention } from '../../../types';
import { CAMPAIGN_UPLOAD_REQUIRED } from '../../../constants/error-messages';
import { getDimensionsFromTargetType } from '../../pages/Screen/in-screens-preview/helpers';
import LockableContentModal from '../../pages/Screen/ScreenLocking/LockableContentModal';
import { ScreenUploader } from './screen-uploader';
import Button from '../Button';
import StatusBanner from '../status-banner';
import DisplayFrequencySelector, {
  DispFreqMessageType,
  getDispFreqOption,
} from '../form-elements/display-frequency-selector';
import { IEntitySelector } from '../form-elements/multi-route-stop-selector';
import TrashIcon from '../trash-icon';
import { ReactComponent as EditIcon } from '../../../images/icon_edit_sm.svg';
import * as S from './index.styled';

enum SingleScreenTargetingFormStages {
  UPLOAD_TYPE_SELECTION = 'UPLOAD_TYPE_SELECTION',
  FILE_UPLOAD = 'FILE_UPLOAD',
}

export interface ISingleScreenTargeting {
  weightFrequency: {
    priority: SchedulePriority;
    weight: number;
  };
  uploads: { [k: string]: UploadType };
}

const SCREEN_UPLOADS_PAGE = 3;

export const getScreenIconFromScreenDetais = (
  screenDetails?: IScreenDetails | null,
) =>
  getScreenIcon(
    {
      type: screenDetails?.type as ScreenType,
      setKey: screenDetails?.setLength === 0 ? null : 'key',
      setIndex: screenDetails?.setIndex,
      orientation: screenDetails?.orientation as ScreenOrientation,
    },
    50,
  );

export const getISelectorFromEntitySelectors = (
  entitySelectors: GtfsEntitySelectorInput[],
): IEntitySelector => {
  const stops: StopMention[] = [];
  const routes: RouteMention[] = [];

  if (entitySelectors.length) {
    entitySelectors.forEach((selector: any) => {
      if (selector?.stopId) {
        stops.push({
          stopId: selector.stopId,
          isAffected: false,
          id: selector.stopId,
          name: selector.stopId,
          boroughs: null,
          relatedRouteIds: null,
          meta: null,
          type: MentionType.STOP,
        });
      }
      if (selector?.routeId) {
        routes.push({
          id: selector.routeId,
          name: '',
          routeId: selector.routeId,
          isAffected: false,
          meta: null,
          boroughs: null,
          relatedRouteIds: null,
          type: MentionType.ROUTE,
        });
      }
    });
  }

  return { stops, routes };
};

const SingleScreenTargeting: React.FC<
  {
    screenDetails: IScreenDetails;
    screenPosition: number;
    targeting: ISingleScreenTargeting;
    showErrors?: boolean;
    onChange: (updated: ISingleScreenTargeting) => void;
  } & { children?: React.ReactNode }
> = ({
  screenDetails,
  targeting,
  screenPosition,
  showErrors = false,
  onChange,
}) => {
  const weightingEnabled = useFeatureFlag(FeatureFlagName.WEIGHTING_FREQUENCY);

  const { targetType, id, deployType } = screenDetails;
  const { weightFrequency, uploads } = targeting;
  const currentUploads = uploads ?? {};

  const [isLockableContentModalOpen, setIsLockableContentModalOpen] =
    useState(false);
  const [stage, setStage] = useState<SingleScreenTargetingFormStages>(
    Object.keys(currentUploads).length
      ? SingleScreenTargetingFormStages.FILE_UPLOAD
      : SingleScreenTargetingFormStages.UPLOAD_TYPE_SELECTION,
  );

  const [width, height] = getDimensionsFromTargetType(targetType as TargetType);
  const isDup = targetType === TargetType.DUP;

  const resetUploadSelection = () => {
    onChange({
      ...targeting,
      uploads: {},
    });
    setStage(SingleScreenTargetingFormStages.UPLOAD_TYPE_SELECTION);
  };

  const defaultUpload = currentUploads[screenPosition] ?? null;

  return (
    <div>
      {stage !== SingleScreenTargetingFormStages.UPLOAD_TYPE_SELECTION && (
        <span
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            plain
            aria-label="Remove"
            onClick={resetUploadSelection}
            css={css`
              svg g {
                fill: ${theme.colors.accent1};
              }
              &:hover {
                svg g {
                  fill: ${theme.colors.brand};
                }
              }
            `}
          >
            <TrashIcon />
          </Button>
        </span>
      )}
      {stage === SingleScreenTargetingFormStages.UPLOAD_TYPE_SELECTION && (
        <div
          css={css`
            width: 60%;
            display: flex;
          `}
        >
          <Button
            primary
            type="button"
            onClick={() => setIsLockableContentModalOpen(true)}
            additionalStyles={css`
              margin-right: 12px;
            `}
            size="small"
          >
            Attach From Locked Screens
          </Button>
          <Button
            primary
            type="button"
            onClick={() =>
              setStage(SingleScreenTargetingFormStages.FILE_UPLOAD)
            }
            size="small"
          >
            Upload From Computer
          </Button>
        </div>
      )}
      {stage === SingleScreenTargetingFormStages.FILE_UPLOAD && (
        <div
          css={css`
            margin-top: -25px;
          `}
        >
          <S.SupportedDimentions dimensions={`${width} x ${height}px`} />
          <ScreenUploader
            id={screenPosition}
            height={height}
            width={width}
            transitSettings={{
              shouldZip: isDup && defaultUpload?.mimeType !== 'application/zip',
              transitDestination: TransitDestination.VLE,
            }}
            screenUploadsPerPage={SCREEN_UPLOADS_PAGE}
            defaultUpload={defaultUpload}
            isEditable={false}
            onChange={(_, u: CommonUploadFragment | null) => {
              if (u && currentUploads[screenPosition]?.id !== u?.id) {
                onChange({
                  ...targeting,
                  uploads: {
                    [screenPosition]: u,
                  },
                });
              }
            }}
            {...(isDup ? { scale: 0.17, multiplier: 0.5 } : {})}
          />
          {defaultUpload && (
            <button
              type="button"
              onClick={resetUploadSelection}
              css={css`
                text-align: left;
                margin-top: 4px;
                :focus {
                  outline: none;
                }
              `}
            >
              <EditIcon
                css={css`
                  color: ${theme.colors.accent1};
                `}
              />
              <span
                css={css`
                  ${theme.typography.sizes.xsmall};
                  font-weight: ${theme.typography.weights.bolder};
                  color: ${theme.colors.accent1};
                  text-align: center;
                  text-transform: uppercase;
                  margin-left: 5px;
                `}
              >
                Replace File
              </span>
            </button>
          )}
          <StatusBanner
            status="error"
            isVisible={showErrors && !Object.keys(currentUploads).length}
            text={CAMPAIGN_UPLOAD_REQUIRED}
            css={css`
              margin-top: 12px;
              transition: margin-top 0.3s ease;
            `}
          />
          {weightingEnabled && (
            <DisplayFrequencySelector
              messageType={DispFreqMessageType.campaign}
              onChange={({ priority, weight }) => {
                onChange({
                  ...targeting,
                  weightFrequency: {
                    priority,
                    weight,
                  },
                });
              }}
              value={getDispFreqOption({
                messageType: DispFreqMessageType.campaign,
                priority: weightFrequency.priority,
                weight: weightFrequency.weight,
              })}
              competingContentParams={{
                screen: {
                  id: id as string,
                  deployType: deployType?.toLowerCase() as string,
                },
              }}
            />
          )}
        </div>
      )}
      {isLockableContentModalOpen && (
        <LockableContentModal
          isConfirmDisabled={false}
          onSelectUpload={(upload) => {
            onChange({
              ...targeting,
              uploads: {
                [screenPosition]: upload,
              },
            });
            setStage(SingleScreenTargetingFormStages.FILE_UPLOAD);
            setIsLockableContentModalOpen(false);
          }}
          onDismiss={() => setIsLockableContentModalOpen(false)}
          allowedDimensions={{
            width,
            height,
          }}
        />
      )}
    </div>
  );
};

export default SingleScreenTargeting;
