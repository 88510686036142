/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import { ThemeType } from '../../theme';

interface NumberIputProps {
  className?: string;
  id?: string;
  onChange?: (value: number) => void;
  disabled?: boolean;
  value: number;
  minValue: number;
  maxValue: number;
}

const NumberInput: React.FC<NumberIputProps> = ({
  id,
  className,
  onChange = () => {},
  value,
  minValue,
  maxValue,
  disabled = false,
}) => (
  <input
    className={className}
    id={id}
    css={(theme: ThemeType) => css`
      appearance: none;
      width: 60px;
      height: 40px;
      position: relative;
      border: 1px solid ${theme.colors.border};
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      background-color: ${theme.colors.white};
      transition: background-color ease 0.3s;

      &:disabled {
        background-color: ${theme.colors.border};
        cursor: default;
      }
    `}
    disabled={disabled}
    type="number"
    min={minValue}
    max={maxValue}
    value={value}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(parseInt(event.target.value, 10))
    }
  />
);

export default NumberInput;
