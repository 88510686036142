import React from 'react';

import { getFlattenedGtfsKey } from '@mta-live-media-manager/shared';
import { FeedId, useFeedId } from '../../contexts/FeedId';
import {
  useBulletGenerator,
  Bullet as BulletData,
} from '../../hooks/useBulletGenerator';
import { isSubway, isTrain, isBus } from '../../utils/feed-switches';

export enum BulletSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge',
  xxxlarge = 'xxxlarge',
}

type BulletProps = {
  routeId: string;
  feedId?: FeedId;
  size?: BulletSize;
  style?: React.CSSProperties;
  overrideBulletData?: OverrideBulletData;
} & { children?: React.ReactNode };

const Bullet: React.FC<BulletProps> = ({
  routeId = '',
  size = BulletSize.medium,
  style = {},
  feedId = useFeedId(),
  overrideBulletData,
  ...rest
}) => {
  let bulletData = overrideBulletData;
  const generateBullets = useBulletGenerator(feedId);

  if (!bulletData) {
    const bullets = generateBullets([routeId]);
    bulletData = bullets && bullets[0];
  }

  const routeName = bulletData.name;
  return isSubway(feedId) ? (
    <abbr
      data-bullet-route-id={getFlattenedGtfsKey(routeId)}
      data-bullet-size={size}
      style={style}
      {...rest}
    >
      {routeName?.toUpperCase()}
    </abbr>
  ) : (
    <span
      data-bullet-route-id={getFlattenedGtfsKey(routeId)}
      data-bullet-size={size}
      style={{
        ...style,
        backgroundColor: `#${bulletData.color}`,
        color: `#${bulletData.textColor}`,
      }}
      title={routeName}
      {...rest}
    >
      {routeName}
    </span>
  );
};

export const TinyBullet: React.FC<BulletProps> = ({
  routeId,
  overrideBulletData = {},
}) => {
  const feedId = overrideBulletData?.feedId ?? useFeedId();

  if (isBus(feedId)) {
    return null;
  }

  const bulletStyles =
    !isSubway(feedId) && isTrain(feedId)
      ? {
          fontSize: 0,
          margin: '2px 4px',
          marginLeft: '0px',
          padding: '5px',
          verticalAlign: 'top',
        }
      : {
          marginRight: '2px',
          marginBottom: '2px',
        };

  return (
    <Bullet
      routeId={routeId}
      size={BulletSize.xsmall}
      style={bulletStyles}
      feedId={feedId}
    />
  );
};

type OverrideBulletData = Partial<BulletData> & {
  feedId?: FeedId;
};

export default Bullet;
