/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

import {
  INTERNAL_LOG_NOTES,
  INTERNAL_LOG_NOTIFIED_AT,
  INTERNAL_LOG_INCIDENT_START_AT,
  INTERNAL_LOG_INCIDENT_END_AT,
  INTERNAL_LOG_NEEDED_BY,
} from '../../../constants/alerts';
import {
  UpdateEvent,
  UpdateEventVariables,
} from '../../../generated/UpdateEvent';
import InternalLog from '../../common/internal-log/internal-log';
import ErrorModal from '../../common/error-modal';

const UpdateEventMutation = loader('../../../graphql/UpdateEvent.gql');
const GetEventByIdQuery = loader('../../../graphql/GetEventById.gql');

interface IInternalLogFormData {
  [INTERNAL_LOG_INCIDENT_START_AT]: any;
  [INTERNAL_LOG_INCIDENT_END_AT]: any;
  [INTERNAL_LOG_NOTIFIED_AT]: any;
  [INTERNAL_LOG_NOTES]: string;
  [INTERNAL_LOG_NEEDED_BY]: any;
}

const InternalLogForm: React.FC<{
  incidentStartAt: any;
  incidentEndAt: any;
  notifiedAt: any;
  notes: string;
  createdAt: any;
  eventId: number;
  neededBy: any;
  children?: React.ReactNode;
}> = ({
  incidentStartAt,
  incidentEndAt,
  neededBy,
  notifiedAt,
  notes,
  createdAt,
  eventId,
}) => {
  const [updateEvent] = useMutation<UpdateEvent, UpdateEventVariables>(
    UpdateEventMutation,
  );
  const [open, setOpen] = useState(true);
  const formMethods = useForm<IInternalLogFormData>({});
  const [error, setError] = useState<Error | null>();

  const onSubmit = async (formData: any) => {
    try {
      const eventData = {
        incidentStartAt: formData[INTERNAL_LOG_INCIDENT_START_AT],
        incidentEndAt: formData[INTERNAL_LOG_INCIDENT_END_AT],
        notifiedAt: formData[INTERNAL_LOG_NOTIFIED_AT],
        notes: formData[INTERNAL_LOG_NOTES],
        neededBy: formData[INTERNAL_LOG_NEEDED_BY],
      };

      await updateEvent({
        variables: {
          incidentStartAt: eventData.incidentStartAt || incidentStartAt,
          incidentEndAt: eventData.incidentEndAt || incidentEndAt,
          notifiedAt: eventData.notifiedAt || notifiedAt,
          notes: eventData.notes,
          neededBy: eventData.neededBy || neededBy,
          id: eventId,
        },
        refetchQueries: [
          {
            query: GetEventByIdQuery,
            variables: { id: eventId, includeClearingScreenMessages: false },
          },
        ],
      });
      setOpen(!open);
    } catch (e) {
      setError(e as Error);
      setOpen(true);
    }
  };

  return (
    <React.Fragment>
      <FormContext {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <InternalLog
            createdAt={createdAt}
            incidentStartAt={incidentStartAt}
            incidentEndAt={incidentEndAt}
            notifiedAt={notifiedAt}
            neededBy={neededBy}
            notes={notes}
            onClick={() => setOpen(!open)}
            isOpen={open}
            singleEvent
          />
        </form>
      </FormContext>
      <ErrorModal
        isOpen={!!error}
        title="Editing Message Failed"
        onDismiss={() => {
          if (error) {
            setError(null);
          }
        }}
        message="Sorry, something went wrong while attempting to edit this message."
        includeAssistanceMessage
      />
    </React.Fragment>
  );
};

export default InternalLogForm;
