/** @jsxImportSource @emotion/react */

import { durationToString } from 'components/common/duration-list';
import { TStatusPillTypes } from 'components/common/status-pill';
import * as S from '../asset-view/index.styled';

export enum ScheduleModalState {
  CANCEL = 'CANCEL',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

type IGetAssetStatus = {
  hasScheduledAsset: boolean;
  activeScreensForDefault: number;
  activeScreensForSchedule?: number;
};

// what are the states for both at any given time
// active scheduled = cleared default
// active = now sits between start and end

// planned scheduled & screens= live default
// note: INACTIVE assets cannot be SCHEDULED
// no scheduled & no screens = INACTIVE default
// no scheduled & screens = LIVE default

export type WarningInfo = {
  endAt?: string | Date;
  startAt?: string | Date;
  unscheduled?: boolean;
  title: string;
};

type IScheduleDuration = WarningInfo & {
  isScheduled: boolean;
  isWarning?: boolean;
};

export const ScheduleDuration = ({
  startAt,
  endAt,
  isScheduled,
  title,
  isWarning = false,
  unscheduled = false,
}: IScheduleDuration) => {
  if (!startAt || !endAt) {
    return null;
  }
  let assetMsg = 'plays';
  if (unscheduled) {
    assetMsg = 'will not play';
  } else if (isWarning) {
    assetMsg = 'will play';
  }

  const startTime = durationToString(new Date(startAt), true);
  const endTime = durationToString(new Date(endAt), true);
  const desc = isScheduled ? (
    <span>
      The &quot;{title}&quot; asset {assetMsg} from{' '}
      <strong>
        {startTime} — {endTime}
      </strong>
    </span>
  ) : (
    <span>
      The &quot;{title}&quot; asset plays until <strong>{startTime}</strong> and
      resumes playing again on <strong>{endTime}</strong>
    </span>
  );

  return (
    <>
      {!isWarning && <S.Label>Duration</S.Label>}
      <S.Description className="sched-desc">{desc}</S.Description>
    </>
  );
};

export const getAssetStatus: (params: IGetAssetStatus) => {
  defaultScreen: TStatusPillTypes;
  scheduledScreen?: TStatusPillTypes;
} = ({
  hasScheduledAsset,
  activeScreensForDefault,
  activeScreensForSchedule,
}) => {
  if (hasScheduledAsset) {
    const scheduledScreenActive = Boolean(activeScreensForSchedule);
    return {
      scheduledScreen: scheduledScreenActive
        ? TStatusPillTypes.LIVE
        : TStatusPillTypes.SCHEDULED,
      defaultScreen: !scheduledScreenActive
        ? TStatusPillTypes.LIVE
        : TStatusPillTypes.SCHEDULED,
    };
  }

  return {
    defaultScreen: activeScreensForDefault
      ? TStatusPillTypes.LIVE
      : TStatusPillTypes.PUBLISHED,
  };
  // we need to represent what state the default asset and what the scheduled asset is in.
  // If a scheduled asset is LIVE,
  // asset is LIVE
};
