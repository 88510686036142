import {
  LiveContentsOrderBy,
  LiveContentType,
} from '../generated/global-types';

export type LinkType = {
  pathName: string;
  actionName: string;
};

export interface SortOptions {
  [key: string]: [LiveContentsOrderBy, LiveContentsOrderBy];
}

export enum TLiveContentTableSortingTypes {
  end = 'end',
  start = 'start',
  title = 'title',
}

export const LIVE_CONTENT_SORT_OPTIONS: SortOptions = {
  end: [
    LiveContentsOrderBy.DURATION_END_DESC,
    LiveContentsOrderBy.DURATION_END_ASC,
  ],
  start: [
    LiveContentsOrderBy.DURATION_START_DESC,
    LiveContentsOrderBy.DURATION_START_ASC,
  ],
  title: [LiveContentsOrderBy.TITLE_DESC, LiveContentsOrderBy.TITLE_ASC],
  default: [
    LiveContentsOrderBy.DURATION_START_DESC,
    LiveContentsOrderBy.DURATION_START_ASC,
  ],
};

export const contentTypePath = (
  contentType: LiveContentType,
): LinkType | null => {
  switch (contentType) {
    case LiveContentType.ALERT:
      return {
        pathName: 'events',
        actionName: 'update',
      };
    case LiveContentType.CAMPAIGN:
      return {
        pathName: 'campaigns',
        actionName: 'edit',
      };
    case LiveContentType.PLANNED:
      return {
        pathName: 'planned-work',
        actionName: 'edit',
      };
    default:
      return null;
  }
};
