import { FeedId } from '@mta-live-media-manager/shared';
import { Borough } from '../generated/global-types';
import { getStopType } from './feed-switches';

export const BOROUGH_ABBREVIATIONS = {
  [Borough.MANHATTAN]: 'MANH',
  [Borough.BROOKLYN]: 'BKLYN',
  [Borough.QUEENS]: 'QNS',
  [Borough.BRONX]: 'BX',
  [Borough.STATEN_ISLAND]: 'SI',
};

export const BOROUGH_LABELS = {
  [Borough.MANHATTAN]: 'Manhattan',
  [Borough.BROOKLYN]: 'Brooklyn',
  [Borough.QUEENS]: 'Queens',
  [Borough.BRONX]: 'The Bronx',
  [Borough.STATEN_ISLAND]: 'Staten Island',
};

export const BOROUGH_INLINE_LABELS = {
  ...BOROUGH_LABELS,
  [Borough.BRONX]: 'Bronx',
};

export const boroughOptionLabel = (borough: Borough, feedId: FeedId): string =>
  `All ${BOROUGH_INLINE_LABELS[borough]} ${getStopType(feedId)}s`;
