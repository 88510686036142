import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useFeedId, FEEDS } from '../../contexts/FeedId';

const { REACT_APP_DEPLOY_ENV } = process.env;

type Props = {
  description?: string;
  lang?: string;
  meta?: JSX.IntrinsicElements['meta'][];
  title: string;
  baseTitle?: string;
};

const PageMeta: React.FC<Props> = ({
  title,
  lang = 'en',
  meta = [],
  description = '',
  baseTitle = '',
}) => {
  const feedId = useFeedId();
  const feedString = (FEEDS[feedId] && `${FEEDS[feedId].longName}`) || '';

  let prefix = '';
  if (REACT_APP_DEPLOY_ENV === 'staging') {
    prefix = '[STG] ';
  } else if (REACT_APP_DEPLOY_ENV === 'uat') {
    prefix = '[UAT] ';
  } else if (REACT_APP_DEPLOY_ENV === 'local') {
    prefix = '[DEV] ';
  }

  const fullTitle = `${prefix}${title} · ${baseTitle || feedString}`;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={fullTitle}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        // {
        //   name: "twitter:creator",
        //   content: "MTA"
        // },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        ...meta,
      ]}
    />
  );
};

export default PageMeta;
