/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';

import AlertScreenPreview from 'ui-kit/alert-screen-preview/alert-screen-preview';
import {
  MessageType,
  SchedulePriority,
  TargetType,
} from 'generated/global-types';
import { ScreenPreviewDisplayTypes } from 'utils/screen-preview';

import WeightPriorityPill from 'components/common/weight-priority-pill';
import MessageDisplay from '../../common/message/MessageDisplay';

// Styled components
import Heading from '../../common/Heading';
import { flexRow } from '../../common/styles';
import * as S from './ScreenPreview.styled';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as RoutesByFeed_routes_nodes } from '../../../generated/RoutesByFeed';
import ScreenTargetingContainer from '../../common/screen-targeting-container';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message_screenMessages_ScreenMessagesConnection_nodes_ScreenMessage_targeting_ScreenSelector as GetEventById_event_messages_nodes_screenMessages_nodes_targeting } from '../../../generated/GetEventById';

const ScreenPreview: React.FC<{
  additionalInfoHtml: string | null;
  title: string | null;
  bodyHtml: string;
  publishAt: string;
  endAt: string;
  messageType: string;
  affectedRoutes: RoutesByFeed_routes_nodes[];
  allRoutesAffected: boolean;
  screenTargeting: GetEventById_event_messages_nodes_screenMessages_nodes_targeting[];
  displayType?: ScreenPreviewDisplayTypes;
  weight: number;
  priority: SchedulePriority;
  blurContent?: boolean;
}> = ({
  additionalInfoHtml,
  title,
  bodyHtml,
  messageType,
  affectedRoutes,
  screenTargeting,
  displayType,
  publishAt,
  weight,
  priority,
  blurContent,
}) => (
  <div
    css={css`
      ${flexRow}
      ${blurContent && 'opacity: 0.5;'}
    `}
  >
    <S.Container>
      <S.InfoBox>
        <Heading
          level={4}
          size="medium"
          css={css`
            margin-bottom: 12px;
          `}
        >
          Message
        </Heading>
        <MessageDisplay
          innerHtml={bodyHtml}
          css={css`
            background-color: #f8f8f8;
            padding: 24px;
            max-width: 624px;
          `}
          title={title ?? undefined}
        />
        {additionalInfoHtml && (
          <MessageDisplay
            innerHtml={additionalInfoHtml}
            css={css`
              margin-top: 8px;
              background-color: #f8f8f8;
              padding: 24px;
              max-width: 624px;
            `}
          />
        )}
      </S.InfoBox>
      <ScreenTargetingContainer targeting={screenTargeting} />

      {!screenTargeting.some((st) => st.layouts.includes(TargetType.DUP)) && (
        <React.Fragment>
          <Heading level={4}>Display Frequency</Heading>
          <WeightPriorityPill priority={priority} weight={weight} />
        </React.Fragment>
      )}
    </S.Container>
    <div
      css={css`
        flex: 0 0
          ${displayType === ScreenPreviewDisplayTypes.PORTRAIT ? 250 : 375}px;
      `}
    >
      <Heading
        level={4}
        size="medium"
        css={css`
          margin-bottom: 12px;
        `}
      >
        Screen Preview
      </Heading>
      <AlertScreenPreview
        messageType={messageType as MessageType}
        title={title ?? undefined}
        content={bodyHtml}
        additionalContent={additionalInfoHtml || ''}
        affectedRoutes={affectedRoutes}
        displayType={displayType}
        publishAt={publishAt}
      />
    </div>
  </div>
);

export default ScreenPreview;
