/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import theme from 'theme';

interface HtmlPreviewProps {
  className?: string;
  hasColor?: boolean;
  html?: string;
  isSingleLine?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

const HtmlPreview: React.FC<HtmlPreviewProps> = ({
  className,
  hasColor,
  html,
  isSingleLine,
  title,
  subTitle,
  ...rest
}) => {
  if (!html) {
    return null;
  }

  return (
    <div className={className} {...rest}>
      {!!title && (
        <div
          css={css`
            ${theme.typography.sizes.medium};
            font-weight: ${theme.typography.weights.bold};
            margin-bottom: 8px;
          `}
        >
          {title}
        </div>
      )}
      {!!subTitle && (
        <div
          css={css`
            ${theme.typography.sizes.small};
            margin-bottom: 8px;
          `}
        >
          {subTitle}
        </div>
      )}
      <div
        css={css`
          background-color: ${hasColor ? '#e7eff6' : '#f8f8f8'};
          border-radius: 4px;
          padding: 16px 24px;
          min-height: ${isSingleLine ? '1rem' : '120px'};

          p {
            ${theme.typography.sizes.medium};
            color: ${theme.colors.black};
            margin: 0px;
            margin-top: 8px;
            min-height: 1px;
            overflow-wrap: break-word;

            [data-bullet-route-id^='mtasbwy-'] {
              vertical-align: middle;
              width: ${theme.typography.sizes.large.fontSize};
              height: ${theme.typography.sizes.large.fontSize};

              & + [data-bullet-route-id^='mtasbwy-'] {
                margin-left: 1px;
                margin-right: 1px;
              }
            }

            [data-bullet-display='generic'] {
              background-color: ${hasColor ? '#e7eff6' : '#f8f8f8'} !important;
              padding: 0px !important;
            }

            [data-bullet-route-id^='li'],
            [data-bullet-route-id^='mta-'],
            [data-bullet-route-id^='njt'],
            [data-bullet-route-id^='mnr'],
            [data-bullet-route-id^='bt'] {
              font-size: 85%;
            }

            [data-bullet-display^='generic'] {
              ${theme.typography.sizes.medium};

              & + [data-bullet-display^='generic'] {
                margin-left: 1px;
                margin-right: 1px;
              }
            }
          }

          p:empty {
            margin-top: 8px;
          }

          p:first-of-type {
            margin-top: 0px;
          }
        `}
        dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
      />
    </div>
  );
};

export default HtmlPreview;
