/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import Heading from 'components/common/Heading';
import Button from 'components/common/Button';
import RemainingCharacters from './form-elements/RemainingCharacters';

export const HEADLINE_MAX_CHAR_LENGTH = 160;

const MessageHeadline: React.FC<{
  onMessageCopy?: () => void;
  currentNumberOfChars: number;
  children?: React.ReactNode;
}> = ({ onMessageCopy, currentNumberOfChars }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <Heading
        level={2}
        size="medium"
        css={css`
          margin-bottom: 8px;
        `}
      >
        Headline
      </Heading>
      <div
        css={css`
          display: flex;
          column-gap: 0.625rem;
          align-items: center;
        `}
      >
        {onMessageCopy && (
          <Button plain type="button" onClick={onMessageCopy}>
            Copy from previous message
          </Button>
        )}
        <RemainingCharacters
          maxLength={{
            max: HEADLINE_MAX_CHAR_LENGTH,
            current: currentNumberOfChars,
          }}
        />
      </div>
    </div>
  );
};

export default MessageHeadline;
