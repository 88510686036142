/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import useShouldShowSkeleton from './hooks/useShouldShowSkeleton';
import Table from './components/Table';
import SkeletonLoader from './components/SkeletonLoader';
import { LoaderComponent } from './types';

const PageWithTable: LoaderComponent = ({ loading, after, children }) => {
  const shouldShowSkeleton = useShouldShowSkeleton(loading, { after });
  return (
    <React.Fragment>
      {shouldShowSkeleton ? (
        <div
          css={css`
            width: 100%;
            border-radius: 4px;
          `}
        >
          <SkeletonLoader height="55">
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="55" />
          </SkeletonLoader>
          <div
            css={css`
              margin-top: 20px;
              margin-bottom: 20px;
            `}
          >
            <SkeletonLoader height="25">
              <rect x="0" y="0" rx="4" ry="4" width="100%" height="25" />
            </SkeletonLoader>
          </div>
          <Table />
          <div
            css={css`
              text-align: right;
              margin-top: 20px;
            `}
          >
            <SkeletonLoader width="170" height="40">
              <rect x="0" y="0" rx="4" ry="4" width="170" height="40" />
            </SkeletonLoader>
          </div>
        </div>
      ) : (
        children
      )}
    </React.Fragment>
  );
};

export default PageWithTable;
