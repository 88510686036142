/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import Button from './Button';
import { ReactComponent as Revert } from '../../images/icon_rollback.svg';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as Pencil } from '../../images/icon_edit_sm.svg';

const ActionButton: React.FC<
  {
    onClick: () => void;
    children: React.ReactNode;
    title?: string;
  } & { children?: React.ReactNode }
> = ({ onClick, title, children }) => {
  return (
    <Button
      plain
      type="button"
      onClick={onClick}
      size="small"
      aria-label={title}
      css={css`
        margin-left: 13px;
      `}
    >
      {children}
    </Button>
  );
};

interface TGetAction {
  shouldCommit: boolean;
  title: string;
  onClick: () => void;
  commitIcon?: React.ReactNode;
  revertIcon?: React.ReactNode;
}

const getAction = ({
  shouldCommit,
  onClick,
  title,
  commitIcon = <PlusIcon />,
  revertIcon = <Revert />,
}: TGetAction): React.ReactNode => (
  <ActionButton title={title} onClick={onClick}>
    {shouldCommit ? revertIcon : commitIcon} {title}
  </ActionButton>
);

export const EditRestoreAction: React.FC<
  {
    showScreensEdit: boolean;
    onEdit: () => void;
    onUndo: () => void;
  } & { children?: React.ReactNode }
> = ({ showScreensEdit, onEdit, onUndo }) => {
  const getActionParams = {
    shouldCommit: showScreensEdit,
    title: showScreensEdit
      ? 'Restore Stations Affected'
      : 'Edit Station & Screen',
    onClick: showScreensEdit ? onEdit : onUndo,
    commitIcon: <Pencil />,
  };

  return <React.Fragment>{getAction(getActionParams)}</React.Fragment>;
};

export const SelectUnselectAllAction: React.FC<
  {
    areAllSelected: boolean;
    onSelectAll: () => void;
    onUnselectAll: () => void;
    hideAction?: boolean;
  } & { children?: React.ReactNode }
> = ({ areAllSelected, onSelectAll, onUnselectAll, hideAction = false }) => {
  if (hideAction) {
    return null;
  }

  const getActionParams = {
    shouldCommit: areAllSelected,
    title: areAllSelected
      ? 'Unselect Target All Stations'
      : 'Target all stations and screens',
    onClick: areAllSelected ? onUnselectAll : onSelectAll,
  };

  return <React.Fragment>{getAction(getActionParams)}</React.Fragment>;
};
