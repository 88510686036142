import { EmailMessageTypeCodes } from 'generated/global-types';
import { FeedId } from 'types/feeds';

export const NYCT_SUBWAY_EMAIL_TYPES = [
  EmailMessageTypeCodes.RTSCH,
  EmailMessageTypeCodes.PLSVC,
  EmailMessageTypeCodes.CONST,
  EmailMessageTypeCodes.LIELE,
  EmailMessageTypeCodes.NEWRS,
  EmailMessageTypeCodes.OTHER,
];

export const NYCT_BUS_EMAIL_TYPES = [
  EmailMessageTypeCodes.RTSCH,
  EmailMessageTypeCodes.PLSVC,
  EmailMessageTypeCodes.LIELE,
  EmailMessageTypeCodes.NEWRS,
  EmailMessageTypeCodes.OTHER,
];

export const MNR_EMAIL_TYPES = [
  EmailMessageTypeCodes.RTSCH,
  EmailMessageTypeCodes.PLSVC,
  EmailMessageTypeCodes.LIELE,
  EmailMessageTypeCodes.NEWRS,
  EmailMessageTypeCodes.OTHER,
];

export const LIRR_EMAIL_TYPES = [
  EmailMessageTypeCodes.RTSCH,
  EmailMessageTypeCodes.PLSVC,
  EmailMessageTypeCodes.LIELE,
  EmailMessageTypeCodes.NEWRS,
  EmailMessageTypeCodes.OTHER,
];

export const BT_EMAIL_TYPES = [
  EmailMessageTypeCodes.RTSCH,
  EmailMessageTypeCodes.PLSVC,
  EmailMessageTypeCodes.LIELE,
  EmailMessageTypeCodes.NEWRS,
  EmailMessageTypeCodes.OTHER,
];

export const EMAIL_TYPES_BY_FEED: {
  [key in FeedId]: EmailMessageTypeCodes[];
} = {
  [FeedId.NYCTSubway]: NYCT_SUBWAY_EMAIL_TYPES,
  [FeedId.NYCTBus]: NYCT_BUS_EMAIL_TYPES,
  [FeedId.MNR]: MNR_EMAIL_TYPES,
  [FeedId.LIRR]: LIRR_EMAIL_TYPES,
  [FeedId.BT]: BT_EMAIL_TYPES,
  [FeedId.OutfrontQA]: NYCT_SUBWAY_EMAIL_TYPES,
  [FeedId.OutfrontDEV]: NYCT_SUBWAY_EMAIL_TYPES,
};

export const EMAIL_TYPE_LABELS: {
  [key in EmailMessageTypeCodes]: string;
} = {
  [EmailMessageTypeCodes.RTSCH]: 'Real-time Service Alerts',
  [EmailMessageTypeCodes.PLSVC]: 'Advisories',
  [EmailMessageTypeCodes.CONST]: 'Construction',
  [EmailMessageTypeCodes.LIELE]: 'Elevator',
  [EmailMessageTypeCodes.NEWRS]: 'Reminders',
  [EmailMessageTypeCodes.OTHER]: 'Other',
};
