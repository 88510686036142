/** @jsxImportSource @emotion/react */

import React from 'react';
import { ListboxButton } from '@reach/listbox';
import { css } from '@emotion/react';
import { filterInput } from '../components/common/styles';
import ChevronIcon from '../components/common/chevron-icon';
import theme from '../theme';

const style = css`
  ${filterInput}
  position: relative;
  cursor: pointer;
  color: ${theme.colors.black};
  svg {
    transition: transform 0.2s ease-in-out;
  }

  [data-expanded] {
    svg {
      transform: rotate(180deg);
    }
  }

  &:focus {
    box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
  }

  box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
`;

const caret = css`
  position: absolute;
  right: 1rem;
`;

const StandardListboxButton: React.FC<{
  disabled?: boolean;
  children?: React.ReactNode;
}> = ({ disabled, children, ...rest }) => (
  <ListboxButton
    arrow={
      <ChevronIcon
        css={caret}
        size="12px"
        color={disabled ? theme.colors.dark3 : theme.colors.accent1}
      />
    }
    css={style}
    {...rest}
  >
    {children || <React.Fragment>&nbsp;</React.Fragment>}
  </ListboxButton>
);

export default StandardListboxButton;
