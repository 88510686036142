import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import {
  LIVE_CONTENT_SORT_OPTIONS,
  SortOptions,
  TLiveContentTableSortingTypes,
} from 'constants/live-content';
import { useFeedId } from '../contexts/FeedId';
import { useQueryParam } from './useQueryParams';

import { LiveContentsOrderBy, TargetType } from '../generated/global-types';
import { LiveContents, LiveContentsVariables } from '../generated/LiveContent';

const LIVE_CONTENTS_PER_PAGE = 20;

const sortOptions = LIVE_CONTENT_SORT_OPTIONS;

const QUERY_PARAM_KEYS = {
  direction: 'dir',
  orderBy: 'order',
  page: 'page',
};

const LiveContentsQuery = loader('../graphql/LiveContent.gql');
const paramsToSort = function paramsToSort(
  sortOptions: SortOptions,
  sort?: TLiveContentTableSortingTypes,
  dir?: string,
): LiveContentsOrderBy {
  if (!sort || !dir) {
    return sortOptions.default[0];
  }
  const dirIndex = dir === 'desc' ? 0 : 1;
  const selection = sortOptions[sort];
  return selection ? selection[dirIndex] : sortOptions.default[0];
};
export default function useLiveContentQuery() {
  const feedId = useFeedId();
  const [routesQuery] = useQueryParam('routes');
  const [targetsQuery] = useQueryParam('targets');
  const [searchQuery] = useQueryParam('search');

  // Pagination
  const [page, setPage] = useQueryParam(QUERY_PARAM_KEYS.page, '1');
  // Sorting
  const [orderBy, setOrderBy] = useQueryParam(QUERY_PARAM_KEYS.orderBy, 'id');
  const [direction, setDirection] = useQueryParam(
    QUERY_PARAM_KEYS.direction,
    'desc',
  );

  const pageNum = page ? parseInt(page, 10) : 1;

  const query = useQuery<LiveContents, LiveContentsVariables>(
    LiveContentsQuery,
    {
      variables: {
        feedId,
        offset: LIVE_CONTENTS_PER_PAGE * (pageNum - 1),
        perPage: LIVE_CONTENTS_PER_PAGE,
        orderBy: [
          paramsToSort(
            sortOptions,
            orderBy as TLiveContentTableSortingTypes,
            direction,
          ),
          LiveContentsOrderBy.DURATION_START_DESC,
        ],
        routeIds: routesQuery?.split(','),
        targetTypes: targetsQuery?.split(',') as TargetType[],
        searchStr: searchQuery,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const hasFilter = !!routesQuery || !!targetsQuery || !!searchQuery;

  return {
    query,
    hasFilter,
    pageNum,
    perPage: LIVE_CONTENTS_PER_PAGE,
    orderBy,
    direction,
    setPage,
    setOrderBy,
    setDirection,
  };
}
