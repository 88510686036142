/** @jsxImportSource @emotion/react */
import React from 'react';

import LiveContent from '../../common/live-content';

const LiveContentDashboard: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  return <LiveContent pageTitle="Live Content" />;
};

export default LiveContentDashboard;
