/** @jsxImportSource @emotion/react */

import { DetailedHTMLProps, HTMLAttributes, ButtonHTMLAttributes } from 'react';
import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';

const NAV_WIDTH = 240;

export const Container: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
> & { children?: React.ReactNode } = (props) => (
  <header
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: column;
      position: fixed;
      left: 0;
      top: 0;
      width: 54px;
      height: 100%;
      z-index: 10;
      background-color: ${theme.colors.accent3};
      overflow: hidden;

      .nav-open & {
        width: ${NAV_WIDTH}px;
      }

      .nav-transition & {
        transition: width 0.3s ease-out;
      }
    `}
    {...props}
  />
);

export const InnerContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      justify-content: space-between;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
      transform: translateX(-30px);
      width: ${NAV_WIDTH}px;

      .nav-open & {
        pointer-events: auto;
        transform: translateX(0);
        opacity: 1;
      }

      .nav-transition & {
        transition:
          opacity 0.3s,
          transform 0.3s ease-in-out;
      }
    `}
    {...props}
  />
);

export const LogoContainer: React.FC<{
  env: string;
  children?: React.ReactNode;
}> = ({ env, ...rest }) => (
  <div
    css={css`
      position: absolute;
      top: ${env === 'staging' || env === 'uat' || env === 'local'
        ? '46px'
        : '14px'};
      left: 14px;
      width: 24px;
      z-index: 50;
      pointer-events: auto;

      svg {
        width: 100%;
        height: auto;
      }

      .nav-open & {
        pointer-events: none;
      }
    `}
    {...rest}
  />
);

export const Environment: React.FC<{
  color: string;
  children?: React.ReactNode;
}> = ({ color, ...rest }) => (
  <div
    css={({ colors, typography }: ThemeType) => css`
      ${typography.sizes.xsmall};
      background-color: ${color};
      flex: 0 0 32px;
      line-height: 32px;
      overflow: hidden;
      color: ${colors.brand};
      text-transform: uppercase;
      font-weight: ${typography.weights.bolder};
      padding: 0 15px;
      width: 240px;
    `}
    {...rest}
  />
);

export const Nav: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
> & { children?: React.ReactNode } = (props) => (
  <nav
    css={css`
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    `}
    {...props}
  />
);

export const NavToggleButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & { children?: React.ReactNode } = ({ type, ...rest }) => (
  <button
    css={css`
      background-color: transparent;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      right: 9px;
      height: 36px;
      width: 36px;
      z-index: 10;
      margin: 0;
      overflow: hidden;
      padding: 0;
      transform: rotate(0deg);
      transition:
        opacity 0.2s,
        background-color 0.2s;

      svg {
        display: block;
        fill: #fff;
        width: 20px;
        height: 20px;
        margin: 8px;
        transition: opacity 0.2s;
        opacity: 0.6;
      }

      &:hover,
      &:focus {
        background-color: #364663;
      }

      .nav-open & {
        opacity: 0;
        transform: rotate(180deg);

        &:focus {
          opacity: 1;
        }
      }

      .nav-open header:hover & {
        opacity: 1;
      }
    `}
    type="button"
    {...rest}
  />
);

export const Spacer: React.FC<
  unknown & { children?: React.ReactNode }
> = () => (
  <div
    css={css`
      flex: 1;
    `}
  />
);

export const SendFeedback: React.FC<
  React.PropsWithoutRef<JSX.IntrinsicElements['a']> & {
    children?: React.ReactNode;
  }
> = (props) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      align-self: flex-start;
      color: ${theme.colors.white};
      margin: 0;
      display: block;
      padding: 9px 16px;
      font-family: ${theme.typography.families.primary};
    `}
    {...props}
  />
);
