/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useRef, useState, useEffect } from 'react';

import { ThemeType } from '../../../theme';

import * as S from './InputLabelBorder.styled';

const InputLabelBorder: React.FC<{
  label: React.ReactNode;
  children: React.ReactNode;
  invalid?: boolean;
  fill?: boolean;
}> = ({ label, children, invalid, fill = false, ...rest }) => {
  const [labelWidth, setLabelWidth] = useState(0);
  const labelRef: { current: HTMLLabelElement | null } = useRef(null);

  useEffect(() => {
    if (labelRef.current instanceof HTMLLabelElement) {
      setLabelWidth(labelRef.current.offsetWidth + 8);
    }
  }, [label]);

  const child = React.Children.only(children);

  const hasErrorState = invalid;

  return (
    <div
      css={(theme: ThemeType) => css`
        ${fill
          ? `
          width: 100%;
          height: 100%;
        `
          : ''}
        &:focus-within {
          label {
            color: ${theme.colors.brand};
          }
          fieldset {
            border-color: ${theme.colors.brand};
          }
        }
      `}
      {...rest}
    >
      <div
        css={css`
          position: relative;
          margin-top: 11px;
          ${fill ? 'width: 100%; height: 100%;' : ''}
        `}
      >
        {label ? (
          <label
            ref={labelRef}
            css={(theme: ThemeType) => css`
              ${theme.typography.sizes.xsmall};
              color: ${hasErrorState
                ? theme.colors['status-error']
                : theme.colors.dark3};
              font-family: ${theme.typography.families.primary};
              top: 0;
              left: 0;
              position: absolute;
              transform: translate(14px, -50%);
            `}
          >
            {label}
          </label>
        ) : null}
        {child}
        <S.Fieldset aria-hidden invalid={invalid}>
          {label && labelWidth ? (
            <S.Legend width={labelWidth}>
              <span>&#8203;</span>
            </S.Legend>
          ) : null}
        </S.Fieldset>
      </div>
    </div>
  );
};

export default InputLabelBorder;
