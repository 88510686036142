/** @jsxImportSource @emotion/react */

import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { css } from '@emotion/react';

import Theme, { ThemeType } from '../../../theme';
import CrossIcon from '../cross-icon';
import CheckIcon from '../check-icon';

const Toggle: React.FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> & { children?: React.ReactNode } = ({ checked, ...rest }) => (
  <React.Fragment>
    <input
      css={(theme: ThemeType) => css`
        appearance: none;
        width: 56px;
        height: 32px;
        display: flex;
        border-radius: 18px;
        overflow: hidden;
        border: none;
        cursor: pointer;
        background-color: ${checked
          ? theme.colors.accent1
          : theme.colors['border-dark']};
        transition: background-color ease 0.3s;
        :focus-visible {
          outline: none;
          ::after {
            position: absolute;
            content: '';
            top: -2px;
            bottom: -2px;
            right: -2px;
            left: -2px;
            border: 1px solid ${theme.colors.accent3};
            border-radius: 4px;
          }
        }
      `}
      checked={checked}
      type="checkbox"
      {...rest}
    />
    <div
      aria-hidden="true"
      css={(theme: ThemeType) => css`
        position: absolute;
        z-index: 3;
        pointer-events: none;
        width: 24px;
        height: 24px;
        background: ${theme.colors.white};
        left: 4px;
        top: 4px;
        border-radius: 50%;
        transform: ${checked ? 'translateX(24px)' : ''};
        transition:
          right cubic-bezier(0, -0.01, 0, 1) 0.3s,
          transform cubic-bezier(0, -0.01, 0, 1) 0.3s;
      `}
      {...rest}
    >
      <div
        css={css`
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        `}
      >
        {!checked && (
          <CrossIcon color={Theme.colors['border-dark']} size="12px" />
        )}
        {checked && <CheckIcon color={Theme.colors.accent1} size="13px" />}
      </div>
    </div>
  </React.Fragment>
);

export default Toggle;
