/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useEffect, useState } from 'react';
import { useActive, useCurrentSelection, useCommands } from '@remirror/react';
import Select from 'components/common/form-elements/Select';

import { ReactComponent as UndoIcon } from 'images/icon_undo.svg';
import { ReactComponent as RedoIcon } from 'images/icon_redo.svg';
import { ListIcon, LinkIcon } from './icons';

import {
  richEditingMenuCss,
  richEditingMenuButtonCss,
  separatorCss,
  customSelectStyles,
} from './inner-editor.styled';

import HyperlinkEditor from './hyperlink-editor';

const options = [
  { label: 'Normal Text', value: 0 },
  { label: 'Heading 2', value: 2 },
  { label: 'Heading 3', value: 3 },
  { label: 'Heading 4', value: 4 },
  { label: 'Heading 5', value: 5 },
];
type RichEditingMenuProps = {
  showDetailMenu: boolean;
};

const RichEditingMenu: React.FC<RichEditingMenuProps> = ({
  showDetailMenu,
}) => {
  const active = useActive();
  const [activeHeading, setActiveHeading] = useState<number>(0);
  const [showHyperlinkEditor, toggleHyperlinkEditor] = useState(false);
  const commands = useCommands();
  const { empty, from, to } = useCurrentSelection();
  const linkMenuBtnEnabled = !empty || (active.link && active.link());

  useEffect(() => {
    if (empty && showHyperlinkEditor) {
      toggleHyperlinkEditor(false);
    }
  }, [empty, showHyperlinkEditor, toggleHyperlinkEditor]);

  return (
    <div className="rm-rich-menu" css={richEditingMenuCss}>
      {showDetailMenu && (
        <>
          <HyperlinkEditor
            onSubmit={() => toggleHyperlinkEditor(false)}
            enabled={showHyperlinkEditor}
            from={from}
            to={to}
          />
          <Select
            hasControlBorder={false}
            hasControlBoxShadow={false}
            styles={customSelectStyles}
            options={options}
            isSearchable={false}
            defaultValue={options[activeHeading]}
            onChange={(update: any) => {
              commands.toggleHeading({ level: update.value });
              setActiveHeading(update.value);
            }}
          />
          <button
            css={richEditingMenuButtonCss}
            type="button"
            disabled={!linkMenuBtnEnabled}
            style={{
              order: '3',
              opacity: linkMenuBtnEnabled ? '1.0' : '0.3',
            }}
            onClick={() => {
              toggleHyperlinkEditor(!showHyperlinkEditor);
            }}
          >
            <LinkIcon fill="black" />
          </button>
          <button
            css={richEditingMenuButtonCss}
            style={{
              opacity: activeHeading > 0 ? '0.3' : '1',
              backgroundColor: 'transparent',
              order: '4',
            }}
            disabled={activeHeading > 2}
            type="button"
            onClick={commands.toggleBulletList}
          >
            <ListIcon fill="black" />
          </button>
        </>
      )}
      <button
        css={css`
          ${richEditingMenuButtonCss}
          margin-left: 10px;
          font-weight: 700;
          order: ${showDetailMenu ? '2' : '-1'};
        `}
        type="button"
        onClick={() => commands.toggleBold()}
      >
        B
      </button>
      <div
        css={separatorCss}
        style={{
          order: showDetailMenu ? '5' : 'initial',
        }}
      />
      <button
        css={richEditingMenuButtonCss}
        type="button"
        disabled={!commands?.undo?.enabled()}
        style={{
          order: showDetailMenu ? '6' : 'initial',
        }}
        onClick={commands.undo}
      >
        <UndoIcon />
      </button>
      <button
        css={richEditingMenuButtonCss}
        type="button"
        style={{
          order: showDetailMenu ? '7' : 'initial',
        }}
        onClick={commands.redo}
      >
        <RedoIcon />
      </button>
    </div>
  );
};

export default RichEditingMenu;
