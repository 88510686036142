/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { container, flexRow } from '../../common/styles';
import { ThemeType } from '../../../theme';
import Heading from '../../common/Heading';

const EventPageContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={(theme) => [
      container,
      css`
        max-width: 1044px;
        margin: 0 0 ${theme.spacing.xlarge};
      `,
    ]}
    {...props}
  />
);

const EventWrapper: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={css`
      padding: 8px 0px;
    `}
    {...props}
  />
);

export const EventPage = {
  Container: EventPageContainer,
  Wrapper: EventWrapper,
};

const EventContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => [
      css`
        margin: 0px 32px;
        margin-top: 16px;
        background-color: ${theme.colors.white};
        border-radius: 8px;
        box-shadow:
          0 1px 1px 0 rgba(0, 0, 0, 0.14),
          0 1px 3px 0 rgba(0, 0, 0, 0.2);
      `,
    ]}
    {...props}
  />
);

const EventUpdatedAt: React.FC<any> = (props) => (
  <time
    css={(theme: ThemeType) => css`
      color: ${theme.colors.brand};
      display: block;
      font-weight: ${theme.typography.weights.bold};
      white-space: nowrap;
      ${theme.typography.sizes.xlarge};
    `}
    {...props}
  />
);

const EventUpdatedDate: React.FC<any> = (props) => (
  <time
    css={(theme: ThemeType) => [
      theme.typography.sizes.xsmall,
      css`
        color: ${theme.colors.brand};
      `,
    ]}
    {...props}
  />
);

export const Event = {
  Container: EventContainer,
  UpdatedAt: EventUpdatedAt,
  UpdatedDate: EventUpdatedDate,
};

const MessageContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      width: 100%;
    `}
    {...props}
  />
);

const MessageHeaderContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => [
      flexRow,
      css`
        justify-content: space-between;
        align-items: center;
        padding: 40px 32px;
        border-bottom: 1px solid ${theme.colors.border};
      `,
    ]}
    {...props}
  />
);

const MessageContents: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={[
      flexRow,
      css`
        justify-content: space-between;
        align-items: center;
        padding: 32px 40px;
      `,
    ]}
    {...props}
  />
);

const MessagePreviewTitle: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ children }) => (
  <Heading
    level={4}
    size="medium"
    css={css`
      margin-bottom: 12px;
    `}
  >
    {children}
  </Heading>
);

export const Message = {
  Container: MessageContainer,
  HeaderContainer: MessageHeaderContainer,
  Contents: MessageContents,
  PreviewTitle: MessagePreviewTitle,
};

export const ErrorContent: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1 1 100%;
      line-height: 32px;
    `}
    {...props}
  />
);
