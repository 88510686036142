// This type is generated by GraphQL and this file is generated by the web package.
// Do not edit this file directly!
export var MessageType;
(function (MessageType) {
    MessageType["ARRIVAL_INFORMATION_OUTAGE"] = "ARRIVAL_INFORMATION_OUTAGE";
    MessageType["BOARDING_CHANGE"] = "BOARDING_CHANGE";
    MessageType["BUSES_DETOURED"] = "BUSES_DETOURED";
    MessageType["BUSING"] = "BUSING";
    MessageType["CANCELLATIONS"] = "CANCELLATIONS";
    MessageType["CROWDING"] = "CROWDING";
    MessageType["DELAYS"] = "DELAYS";
    MessageType["DELAYS_AND_CANCELLATIONS"] = "DELAYS_AND_CANCELLATIONS";
    MessageType["DETOUR"] = "DETOUR";
    MessageType["DETOURS"] = "DETOURS";
    MessageType["ESSENTIAL_SERVICE"] = "ESSENTIAL_SERVICE";
    MessageType["EXPECT_DELAYS"] = "EXPECT_DELAYS";
    MessageType["EXPRESS_TO_LOCAL"] = "EXPRESS_TO_LOCAL";
    MessageType["EXTRA_SERVICE"] = "EXTRA_SERVICE";
    MessageType["HOLIDAY_SERVICE"] = "HOLIDAY_SERVICE";
    MessageType["INFORMATION_OUTAGE"] = "INFORMATION_OUTAGE";
    MessageType["LOCAL_TO_EXPRESS"] = "LOCAL_TO_EXPRESS";
    MessageType["MULTIPLE_CHANGES"] = "MULTIPLE_CHANGES";
    MessageType["MULTIPLE_IMPACTS"] = "MULTIPLE_IMPACTS";
    MessageType["NO_MIDDAY_SERVICE"] = "NO_MIDDAY_SERVICE";
    MessageType["NO_OVERNIGHT_SERVICE"] = "NO_OVERNIGHT_SERVICE";
    MessageType["NO_SCHEDULED_SERVICE"] = "NO_SCHEDULED_SERVICE";
    MessageType["NO_WEEKEND_SERVICE"] = "NO_WEEKEND_SERVICE";
    MessageType["ON_OR_CLOSE"] = "ON_OR_CLOSE";
    MessageType["PART_SUSPENDED"] = "PART_SUSPENDED";
    MessageType["PLANNED_BOARDING_CHANGE"] = "PLANNED_BOARDING_CHANGE";
    MessageType["PLANNED_BUSES_DETOURED"] = "PLANNED_BUSES_DETOURED";
    MessageType["PLANNED_DETOUR"] = "PLANNED_DETOUR";
    MessageType["PLANNED_EXPRESS_TO_LOCAL"] = "PLANNED_EXPRESS_TO_LOCAL";
    MessageType["PLANNED_EXTRA_TRANSFER"] = "PLANNED_EXTRA_TRANSFER";
    MessageType["PLANNED_LOCAL_TO_EXPRESS"] = "PLANNED_LOCAL_TO_EXPRESS";
    MessageType["PLANNED_MULTIPLE_CHANGES"] = "PLANNED_MULTIPLE_CHANGES";
    MessageType["PLANNED_MULTIPLE_IMPACTS"] = "PLANNED_MULTIPLE_IMPACTS";
    MessageType["PLANNED_PART_SUSPENDED"] = "PLANNED_PART_SUSPENDED";
    MessageType["PLANNED_REROUTE"] = "PLANNED_REROUTE";
    MessageType["PLANNED_STATIONS_SKIPPED"] = "PLANNED_STATIONS_SKIPPED";
    MessageType["PLANNED_STOPS_SKIPPED"] = "PLANNED_STOPS_SKIPPED";
    MessageType["PLANNED_SUBSTITUTE_BUSES"] = "PLANNED_SUBSTITUTE_BUSES";
    MessageType["PLANNED_SUSPENDED"] = "PLANNED_SUSPENDED";
    MessageType["PLANNED_TRAINS_REROUTED"] = "PLANNED_TRAINS_REROUTED";
    MessageType["PLANNED_WORK"] = "PLANNED_WORK";
    MessageType["REDUCED_SERVICE"] = "REDUCED_SERVICE";
    MessageType["REROUTE"] = "REROUTE";
    MessageType["SATURDAY_SCHEDULE"] = "SATURDAY_SCHEDULE";
    MessageType["SERVICE_CHANGE"] = "SERVICE_CHANGE";
    MessageType["SEVERE_DELAYS"] = "SEVERE_DELAYS";
    MessageType["SHUTTLE_BUSES_DETOURED"] = "SHUTTLE_BUSES_DETOURED";
    MessageType["SLOW_SPEEDS"] = "SLOW_SPEEDS";
    MessageType["SOME_DELAYS"] = "SOME_DELAYS";
    MessageType["SOME_REROUTES"] = "SOME_REROUTES";
    MessageType["SPECIAL_EVENT"] = "SPECIAL_EVENT";
    MessageType["SPECIAL_NOTICE"] = "SPECIAL_NOTICE";
    MessageType["SPECIAL_SCHEDULE"] = "SPECIAL_SCHEDULE";
    MessageType["STATIONS_SKIPPED"] = "STATIONS_SKIPPED";
    MessageType["STATION_NOTICE"] = "STATION_NOTICE";
    MessageType["STOPS_SKIPPED"] = "STOPS_SKIPPED";
    MessageType["SUBSTITUTE_BUSES"] = "SUBSTITUTE_BUSES";
    MessageType["SUNDAY_SCHEDULE"] = "SUNDAY_SCHEDULE";
    MessageType["SUSPENDED"] = "SUSPENDED";
    MessageType["TRAINS_REROUTED"] = "TRAINS_REROUTED";
    MessageType["WEEKDAY_SCHEDULE"] = "WEEKDAY_SCHEDULE";
    MessageType["WEEKDAY_SERVICE"] = "WEEKDAY_SERVICE";
    MessageType["WEEKEND_SCHEDULE"] = "WEEKEND_SCHEDULE";
    MessageType["WEEKEND_SERVICE"] = "WEEKEND_SERVICE";
})(MessageType || (MessageType = {}));
export default MessageType;
