/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { HTMLAttributes } from 'react';
import { ThemeType } from 'theme';

export const Container: React.FC<
  HTMLAttributes<HTMLDivElement> & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    `}
    {...props}
  />
);

export const TimeWrapper: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ ...props }) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: ${theme.colors.black};
      font-family: ${theme.typography.families.primary};
    `}
    {...props}
  />
);

export const readOnly = () => css`
  margin-left: 0;
`;

const timeStyles = (theme: ThemeType) => [
  theme.typography.sizes.medium,
  css`
    color: inherit;
  `,
];

export const Time: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <time css={timeStyles} {...props} />;

export const NoTime: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <span css={timeStyles} {...props} />;
