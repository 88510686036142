import { DocumentNode, DefinitionNode } from 'graphql';

export const asSubscription = (document: DocumentNode): DocumentNode => ({
  ...document,
  definitions: document.definitions.map((d: DefinitionNode) => {
    if (d.kind === 'OperationDefinition') {
      return {
        ...d,
        operation: 'subscription',
      };
    }
    return d;
  }) as readonly DefinitionNode[],
});
