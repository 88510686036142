/* eslint-disable global-require */
import { css } from '@emotion/react';
import Theme from './index';

const fonts = {
  ProximaNovaBold: {
    woff: require('../fonts/proxima-nova-bold/proximanova-bold-webfont.woff'),
    woff2: require('../fonts/proxima-nova-bold/proximanova-bold-webfont.woff2'),
  },
  ProximaNovaBoldItalic: {
    woff: require('../fonts/proxima-nova-bold-italic/proximanova-boldit-webfont.woff'),
    woff2: require('../fonts/proxima-nova-bold-italic/proximanova-boldit-webfont.woff2'),
  },
  ProximaNovaExtraBold: {
    woff: require('../fonts/proxima-nova-extra-bold/proximanova-extrabold-webfont.woff'),
    woff2: require('../fonts/proxima-nova-extra-bold/proximanova-extrabold-webfont.woff2'),
  },
  ProximaNovaExtraBoldItalic: {
    woff: require('../fonts/proxima-nova-extra-bold-italic/proximanova-extraboldit-webfont.woff'),
    woff2: require('../fonts/proxima-nova-extra-bold-italic/proximanova-extraboldit-webfont.woff2'),
  },
  ProximaNovaRegular: {
    woff: require('../fonts/proxima-nova-regular/proximanova-regular-webfont.woff'),
    woff2: require('../fonts/proxima-nova-regular/proximanova-regular-webfont.woff2'),
  },
  ProximaNovaRegularItalic: {
    woff: require('../fonts/proxima-nova-regular-italic/proximanova-regularit-webfont.woff'),
    woff2: require('../fonts/proxima-nova-regular-italic/proximanova-regularit-webfont.woff2'),
  },
};

// All bullet icons
export const icons: { [s: string]: string } = {
  'mtasbwy-1': require('../images/bullets/1.svg').default,
  'mtasbwy-2': require('../images/bullets/2.svg').default,
  'mtasbwy-3': require('../images/bullets/3.svg').default,
  'mtasbwy-4': require('../images/bullets/4.svg').default,
  'mtasbwy-5': require('../images/bullets/5.svg').default,
  'mtasbwy-6': require('../images/bullets/6.svg').default,
  'mtasbwy-6x': require('../images/bullets/6d.svg').default,
  'mtasbwy-7': require('../images/bullets/7.svg').default,
  'mtasbwy-7x': require('../images/bullets/7d.svg').default,
  'mtasbwy-a': require('../images/bullets/a.svg').default,
  'mtasbwy-b': require('../images/bullets/b.svg').default,
  'mtasbwy-c': require('../images/bullets/c.svg').default,
  'mtasbwy-d': require('../images/bullets/d.svg').default,
  'mtasbwy-e': require('../images/bullets/e.svg').default,
  'mtasbwy-f': require('../images/bullets/f.svg').default,
  'mtasbwy-fx': require('../images/bullets/fx.svg').default,
  'mtasbwy-fs': require('../images/bullets/fs.svg').default,
  'mtasbwy-g': require('../images/bullets/g.svg').default,
  'mtasbwy-gs': require('../images/bullets/s.svg').default,
  'mtasbwy-h': require('../images/bullets/h.svg').default,
  'mtasbwy-j': require('../images/bullets/j.svg').default,
  'mtasbwy-l': require('../images/bullets/l.svg').default,
  'mtasbwy-m': require('../images/bullets/m.svg').default,
  'mtasbwy-n': require('../images/bullets/n.svg').default,
  'mtasbwy-q': require('../images/bullets/q.svg').default,
  'mtasbwy-r': require('../images/bullets/r.svg').default,
  'mtasbwy-s': require('../images/bullets/s.svg').default,
  'mtasbwy-si': require('../images/bullets/sir.svg').default,
  'mtasbwy-t': require('../images/bullets/t.svg').default,
  'mtasbwy-w': require('../images/bullets/w.svg').default,
  'mtasbwy-z': require('../images/bullets/z.svg').default,
};

// Custom icons
export const customIcons: { [s: string]: string } = {
  ada: require('../images/icon_ada.svg').default,
  shuttle_bus: require('../images/icon_bus.svg').default,
  airplane: require('../images/airplane.svg').default,
};

const GlobalStyle = css`
  @font-face {
    font-display: swap;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: ${Theme.typography.weights.normal};
    src:
      url(${fonts.ProximaNovaRegular.woff2}) format('woff2'),
      url(${fonts.ProximaNovaRegular.woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: ${Theme.typography.weights.bold};
    src:
      url(${fonts.ProximaNovaBold.woff2}) format('woff2'),
      url(${fonts.ProximaNovaBold.woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: ${Theme.typography.weights.bolder};
    src:
      url(${fonts.ProximaNovaExtraBold.woff2}) format('woff2'),
      url(${fonts.ProximaNovaExtraBold.woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Proxima Nova';
    font-style: italic;
    font-weight: ${Theme.typography.weights.normal};
    src:
      url(${fonts.ProximaNovaRegularItalic.woff2}) format('woff2'),
      url(${fonts.ProximaNovaRegularItalic.woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Proxima Nova';
    font-style: italic;
    font-weight: ${Theme.typography.weights.bold};
    src:
      url(${fonts.ProximaNovaBoldItalic.woff2}) format('woff2'),
      url(${fonts.ProximaNovaBoldItalic.woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Proxima Nova';
    font-style: italic;
    font-weight: ${Theme.typography.weights.bolder};
    src:
      url(${fonts.ProximaNovaExtraBoldItalic.woff2}) format('woff2'),
      url(${fonts.ProximaNovaExtraBoldItalic.woff}) format('woff');
  }

  html {
    box-sizing: border-box;
    height: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    ${Theme.typography.sizes.medium};
    display: flex;
    min-height: 100%;
    width: 100%;
    font-family: ${Theme.typography.families.primary};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    width: 100%;
  }

  body {
    background-color: #f8f8fd;
  }

  a {
    text-decoration: none;
  }

  button {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  [data-bullet-route-id^='mtasbwy-'] {
    height: 16px;
    width: 16px;
    height: auto;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    /* workaround for bullet cursor position beginning/ending a block */
    text-align: center;
    color: transparent;
    caret-color: black;
  }

  [data-bullet-route-id^='mta-'],
  [data-bullet-route-id^='li'],
  [data-bullet-route-id^='njt'],
  [data-bullet-route-id^='mnr'],
  [data-bullet-route-id^='bt'] {
    ${Theme.typography.sizes.small};
    border-radius: 1px;
    color: #ffffff;
    font-weight: ${Theme.typography.weights.bolder};
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    font-family: ${Theme.typography.families.primary};
    white-space: nowrap;
  }

  [data-bullet-route-id^='li'],
  [data-bullet-route-id^='njt'],
  [data-bullet-route-id^='mnr'],
  [data-bullet-route-id^='bt'] {
    text-transform: uppercase;
  }

  [data-bullet-route-id^='mta-'][data-bullet-size='small'],
  [data-bullet-route-id^='li'][data-bullet-size='small'],
  [data-bullet-route-id^='njt'][data-bullet-size='small'],
  [data-bullet-route-id^='mnr'][data-bullet-size='small'],
  [data-bullet-route-id^='bt'][data-bullet-size='small'] {
    ${Theme.typography.sizes.xsmall};
    padding: 2px 4px;
  }

  [data-bullet-route-id^='mta-'][data-bullet-size='medium'],
  [data-bullet-route-id^='li'][data-bullet-size='medium'],
  [data-bullet-route-id^='njt'][data-bullet-size='medium'],
  [data-bullet-route-id^='mnr'][data-bullet-size='medium'],
  [data-bullet-route-id^='bt'][data-bullet-size='medium'] {
    ${Theme.typography.sizes.small};
  }

  [data-bullet-route-id^='mta-'][data-bullet-size='xlarge'],
  [data-bullet-route-id^='li'][data-bullet-size='xlarge'],
  [data-bullet-route-id^='njt'][data-bullet-size='xlarge'],
  [data-bullet-route-id^='mnr'][data-bullet-size='xlarge'],
  [data-bullet-route-id^='bt'][data-bullet-size='xlarge'] {
    ${Theme.typography.sizes.medium}
  }

  [data-bullet-route-id^='mta-'][data-bullet-size='xxlarge'],
  [data-bullet-route-id^='li'][data-bullet-size='xxlarge'],
  [data-bullet-route-id^='njt'][data-bullet-size='xxlarge'],
  [data-bullet-route-id^='mnr'][data-bullet-size='xxlarge'],
  [data-bullet-route-id^='bt'][data-bullet-size='xxlarge'] {
    ${Theme.typography.sizes.large}
  }

  ${Object.keys(icons).map(
    (key) => `
    [data-bullet-route-id='${key}'] {
      background-image: url('${icons[key]}');
    }
  `,
  )}

  ${Object.keys(customIcons).map(
    (key) => `
    [data-bullet-display^='${key}'] {
      background-image: url('${customIcons[key]}');
    }
  `,
  )}

  [data-bullet-route-id^='mtasbwy-'][data-bullet-size='xsmall'] {
    width: 14px;
    height: 14px;
  }

  [data-bullet-route-id^='mtasbwy-'][data-bullet-size='small'] {
    height: 16px;
    width: 16px;
  }

  [data-bullet-route-id^='mtasbwy-'][data-bullet-size='medium'] {
    width: 20px;
    height: 20px;
  }
  h2 {
    [data-bullet-route-id^='mtasbwy-'][data-bullet-size='medium'] {
      width: 28px;
      height: 28px;
    }
  }
  h3 {
    [data-bullet-route-id^='mtasbwy-'][data-bullet-size='medium'] {
      width: 23px;
      height: 23px;
    }
  }

  [data-bullet-route-id^='mtasbwy-'][data-bullet-size='large'] {
    height: 24px;
    width: 24px;
  }

  [data-bullet-route-id^='mtasbwy-'][data-bullet-size='xlarge'] {
    height: 36px;
    width: 36px;
  }

  [data-bullet-route-id^='mtasbwy-'][data-bullet-size='xxlarge'] {
    height: 48px;
    width: 48px;
  }

  [data-bullet-route-id^='mtasbwy-'][data-bullet-size='xxxlarge'] {
    height: 64px;
    width: 64px;
  }

  [data-bullet-display^='generic'] {
    ${Theme.typography.sizes.small};
    background: #eaeaea;
    font-weight: ${Theme.typography.weights.bold};
    color: ${Theme.colors.black};
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
  }

  [data-bullet-agency^='nyct-bus'] {
    color: ${Theme.colors.accent3};
    background-color: ${Theme.colors.light9};
    font-weight: ${Theme.typography.weights.bold};
  }

  [data-bullet-display^='generic'][data-bullet-type^='ROUTE'],
  [data-bullet-display^='generic'][data-bullet-type^='STOP'] {
    display: inline-block;
  }

  [data-bullet-display^='ada'],
  [data-bullet-display^='airplane'],
  [data-bullet-display^='shuttle_bus'] {
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 0;
    vertical-align: top;
  }

  .ReactCollapse--collapse {
    transition: height 0.3s;
  }

  [data-reach-popover] {
    z-index: 10;
  }
`;

export default GlobalStyle;
