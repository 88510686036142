import { css } from '@emotion/react';
import theme from '../../../theme';

export const filters = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.small};
  margin-top: ${theme.spacing.small};
  > div:not(:first-of-type) {
    margin-left: 1rem;
  }
`;

export const selectContainer = css`
  flex: 1 1;
`;

export const select = css`
  background-color: ${theme.colors.white};
`;

export const options = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const optionLabel = css`
  ${theme.typography.sizes.medium};
  font-family: ${theme.typography.families.primary};
`;

export const checkbox = css`
  margin-right: 8px;
`;

export const selectedvalues = css`
  font-weight: ${theme.typography.weights.bold};
  color: #0f61a9;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 450px;
`;

export const button = css`
  padding: 12px 8px;
  margin-left: 1rem;
`;
