/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import Theme from '../../../theme';

type MaxLength = {
  max: number;
  current: number;
};

export const remainingIsInvalid = (maxLength: MaxLength): boolean => {
  const remaining =
    maxLength && maxLength.max > 0
      ? maxLength.max - maxLength.current
      : undefined;

  return remaining ? remaining < 0 : false;
};

const RemainingCharacters: React.FC<{
  htmlFor?: string;
  maxLength: MaxLength;
}> = ({ htmlFor, maxLength }) => {
  const remaining =
    maxLength && maxLength.max > 0
      ? maxLength.max - maxLength.current
      : undefined;
  const isRemainingInvalid = remainingIsInvalid(maxLength);
  const characterText =
    typeof remaining === 'number' && Math.abs(remaining) === 1
      ? 'character'
      : 'characters';
  const remainingText = `${Math.abs(remaining || 0)} ${characterText} ${
    isRemainingInvalid ? 'over limit' : ''
  }`;

  return (
    <label
      htmlFor={htmlFor}
      css={css`
        align-self: center;
        ${Theme.typography.sizes.small};
        font-weight: 200;
        color: ${isRemainingInvalid ? Theme.colors['status-error'] : 'inherit'};
      `}
    >
      {remainingText}
    </label>
  );
};

export default RemainingCharacters;
