/** @jsxImportSource @emotion/react */

import React, { Fragment, Children } from 'react';
import { css } from '@emotion/react';

import { ThemeType } from '../../../theme';

import { input as inputCSS } from '../styles';
import CommonHeading from '../Heading';

export const CampaignComposeContainer: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > & { children?: React.ReactNode }
> = (props) => (
  <form
    css={css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `}
    {...props}
  />
);

export const Content: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      border-radius: 8px;
      box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      margin: ${theme.spacing.small} ${theme.spacing.large};

      &:first-of-type {
        margin-top: 0;
      }
    `}
    {...props}
  />
);

export const Section: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={(theme: ThemeType) => css`
      padding: 20px 40px 20px;
      &:not(:last-of-type) {
        border-bottom: 1px solid ${theme.colors.border};
      }
    `}
    {...props}
  />
);

export const MainHeading: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <CommonHeading
    css={(theme: ThemeType) => css`
      margin-top: ${theme.typography.sizes.xxxsmall.fontSize};
    `}
    level={1}
    {...props}
  />
);

export const SubHeading: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <CommonHeading level={2} {...props} />;

export const Buttons: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <section
    css={() => css`
      display: flex;
      flex-direction: row-reverse;

      & > * {
        margin-left: 12px;
      }
    `}
    {...props}
  />
);

export const Input: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { children?: React.ReactNode }
> = (props) => (
  <input
    css={(theme: ThemeType) => css`
      ${inputCSS}
      box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
    `}
    {...props}
  />
);

export const Label: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <CommonHeading
    level={4}
    css={css`
      margin-bottom: 8px;
    `}
    {...props}
  />
);

export const NonInputField: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      margin-top: 24px;
      width: 100%;
    `}
    {...props}
  />
);

export const NoRoutesMessage: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div {...props}>
    <p
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.medium};
        font-size: 1.125rem; // 18px
        margin: 0px;
      `}
    >
      Please select line(s) above to determine screen types available.
    </p>
  </div>
);

export const NoScreensAvailable: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div {...props}>
    <CommonHeading
      level={3}
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.large}
      `}
    >
      No Screens
    </CommonHeading>
    <p
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.medium};
        font-size: 1.125rem; // 18px
        margin: 0px;
      `}
    >
      No screen types availabled for selected line(s).
    </p>
  </div>
);

export const TargetingType: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;

      button {
        flex: 0 0 ${100 / Children.toArray(props.children).length - 0.5}%;
      }
    `}
    {...props}
  />
);

export const DurationHeading: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <CommonHeading
    level={4}
    css={css`
      margin-top: 24px;
      margin-bottom: 12px;
    `}
    {...props}
  />
);

export const SupportedDimentions: React.FC<{
  dimensions: string;
  joinFileFormats?: boolean;
  children?: React.ReactNode;
}> = ({ dimensions, joinFileFormats }) => (
  <p
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      line-height: 16px;
    `}
  >
    {joinFileFormats ? (
      <Fragment>
        Supported File Formats: PNG, JPEG (10MB max) or MP4, MOV (50MB max)
      </Fragment>
    ) : (
      <Fragment>
        Supported Image Files: PNG, JPEG (10mb max)
        <br />
        Supported Video Files: MP4, MOV (50mb max)
      </Fragment>
    )}
    <br />
    Dimensions: {dimensions}
  </p>
);
