/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { EventMessages_MessagesConnection_nodes_Message_affectedStations_AffectedStation as AffectedStation } from 'generated/event-messages.fragment';
import {
  RouteRangeStopDisplayGroup,
  useRouteRangeSelectorRouteStopsQuery,
  useStopsByRoute,
} from 'ui-kit/route-range-stop-selector';

interface ConnectedRouteRangeStopDisplayGroupProps {
  gtfsRouteIds: string[];
  affectedStations: null | (AffectedStation | null)[];
}

const ConnectedRouteRangeStopDisplayGroup: React.FC<
  ConnectedRouteRangeStopDisplayGroupProps & { children?: React.ReactNode }
> = ({ gtfsRouteIds, affectedStations }) => {
  const { data, error, loading } =
    useRouteRangeSelectorRouteStopsQuery(gtfsRouteIds);
  const stopsByRoute = useStopsByRoute(data);

  const likelyDirection =
    affectedStations !== null && affectedStations.length > 0
      ? (affectedStations[0]?.direction ?? null)
      : null;
  const gtfsStopIds = (affectedStations ?? []).map(
    (aff: any) => aff.entitySelector.stopId,
  );

  if (error) {
    throw error;
  }
  if (loading) {
    return null;
  }

  return (
    <RouteRangeStopDisplayGroup
      css={css`
        margin: 12px 0 0;
      `}
      gtfsRouteIds={gtfsRouteIds}
      stopsByRoute={stopsByRoute}
      directionality={likelyDirection}
      gtfsStopIds={gtfsStopIds}
    />
  );
};

export default ConnectedRouteRangeStopDisplayGroup;
