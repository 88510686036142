/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { Fragment } from 'react';
import {
  OptionProps,
  ValueContainerProps,
  components,
  PlaceholderProps,
  SelectOptionActionMeta,
} from 'react-select';
import uniq from 'lodash/uniq';
import theme from 'theme';
import { Shop } from 'generated/global-types';

import Bullet, { BulletSize } from '../Bullet';
import Checkbox, { CheckboxSize } from '../Checkbox';
import Select from './Select';

// A division includes numbered lines
// B division includes lettered lines
enum Division {
  A = 'A',
  B = 'B',
}

enum ShopOptionType {
  ALL_SHOPS_OPTION = 'ALL_SHOPS_OPTION',
  ALL_DIVISION_OPTION = 'ALL_DIVISION_OPTION',
  SHOP_OPTION = 'SHOP_OPTION',
}

type ShopOption = {
  label: string;
  value: string;
  optionType: ShopOptionType;
  division?: Division;
  lines?: string[];
};

type DivisionGroup = {
  label: string;
  value: string;
  division: Division;
  options: ShopOption[];
};

// We hardcode the shop options here along with their route ids, because we own
// that info from Outfront, it's not fetched from any GTFS specific data source
const ALL_SHOPS_OPTION: ShopOption = {
  label: 'All Shops, All Lines',
  value: 'all-shops-option',
  optionType: ShopOptionType.ALL_SHOPS_OPTION,
};

const ALL_DIVISION_OPTIONS: { [key in Division]: ShopOption } = {
  [Division.A]: {
    label: 'All A Division',
    value: 'all-a-division-option',
    optionType: ShopOptionType.ALL_DIVISION_OPTION,
    division: Division.A,
  },
  [Division.B]: {
    label: 'All B Division',
    value: 'all-b-division-option',
    optionType: ShopOptionType.ALL_DIVISION_OPTION,
    division: Division.B,
  },
};

export const SHOP_LINES: {
  [key in Shop]: string[];
} = {
  [Shop._240_ST]: ['MTASBWY:1'],
  [Shop._239_ST]: ['MTASBWY:2', 'MTASBWY:5'],
  [Shop.LIVONIA]: ['MTASBWY:3', 'MTASBWY:GS'],
  [Shop.JEROME]: ['MTASBWY:4'],
  [Shop.E_180_ST]: ['MTASBWY:2', 'MTASBWY:5'],
  [Shop.PELHAM]: ['MTASBWY:6'],
  [Shop.CORONA]: ['MTASBWY:7'],
  [Shop.PITKIN]: ['MTASBWY:A', 'MTASBWY:H'],
  [Shop.CONEY_ISLAND]: [
    'MTASBWY:B',
    'MTASBWY:G',
    'MTASBWY:N',
    'MTASBWY:Q',
    'MTASBWY:W',
    'MTASBWY:FS',
  ],
  [Shop._207_ST]: ['MTASBWY:C'],
  [Shop.CONCOURSE]: ['MTASBWY:D'],
  [Shop.JAMAICA]: ['MTASBWY:E', 'MTASBWY:F', 'MTASBWY:R'],
  [Shop.EAST_NEW_YORK]: ['MTASBWY:J', 'MTASBWY:Z', 'MTASBWY:L', 'MTASBWY:M'],
};

export const SHOP_LABELS: {
  [key in Shop]: string;
} = {
  [Shop._240_ST]: '240 St',
  [Shop._239_ST]: '239 St',
  [Shop.LIVONIA]: 'Livonia',
  [Shop.JEROME]: 'Jerome',
  [Shop.E_180_ST]: 'E 180 St',
  [Shop.PELHAM]: 'Pelham',
  [Shop.CORONA]: 'Corona',
  [Shop.PITKIN]: 'Pitkin',
  [Shop.CONEY_ISLAND]: 'Coney Island',
  [Shop._207_ST]: '207 St',
  [Shop.CONCOURSE]: 'Concourse',
  [Shop.JAMAICA]: 'Jamaica',
  [Shop.EAST_NEW_YORK]: 'East New York',
};

export const DIVISION_SHOP_OPTIONS: {
  [key in Division]: ShopOption[];
} = {
  [Division.A]: [
    {
      label: SHOP_LABELS[Shop._240_ST],
      value: Shop._240_ST,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.A,
      lines: SHOP_LINES[Shop._240_ST],
    },
    {
      label: SHOP_LABELS[Shop._239_ST],
      value: Shop._239_ST,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.A,
      lines: SHOP_LINES[Shop._239_ST],
    },
    {
      label: SHOP_LABELS[Shop.LIVONIA],
      value: Shop.LIVONIA,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.A,
      lines: SHOP_LINES[Shop.LIVONIA],
    },
    {
      label: SHOP_LABELS[Shop.JEROME],
      value: Shop.JEROME,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.A,
      lines: SHOP_LINES[Shop.JEROME],
    },
    {
      label: SHOP_LABELS[Shop.E_180_ST],
      value: Shop.E_180_ST,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.A,
      lines: SHOP_LINES[Shop.E_180_ST],
    },
    {
      label: SHOP_LABELS[Shop.PELHAM],
      value: Shop.PELHAM,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.A,
      lines: SHOP_LINES[Shop.PELHAM],
    },
    {
      label: SHOP_LABELS[Shop.CORONA],
      value: Shop.CORONA,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.A,
      lines: SHOP_LINES[Shop.CORONA],
    },
  ],
  [Division.B]: [
    {
      label: SHOP_LABELS[Shop.PITKIN],
      value: Shop.PITKIN,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.B,
      lines: SHOP_LINES[Shop.PITKIN],
    },
    {
      label: SHOP_LABELS[Shop.CONEY_ISLAND],
      value: Shop.CONEY_ISLAND,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.B,
      lines: SHOP_LINES[Shop.CONEY_ISLAND],
    },
    {
      label: SHOP_LABELS[Shop._207_ST],
      value: Shop._207_ST,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.B,
      lines: SHOP_LINES[Shop._207_ST],
    },
    {
      label: SHOP_LABELS[Shop.CONCOURSE],
      value: Shop.CONCOURSE,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.B,
      lines: SHOP_LINES[Shop.CONCOURSE],
    },
    {
      label: SHOP_LABELS[Shop.JAMAICA],
      value: Shop.JAMAICA,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.B,
      lines: SHOP_LINES[Shop.JAMAICA],
    },
    {
      label: SHOP_LABELS[Shop.EAST_NEW_YORK],
      value: Shop.EAST_NEW_YORK,
      optionType: ShopOptionType.SHOP_OPTION,
      division: Division.B,
      lines: SHOP_LINES[Shop.EAST_NEW_YORK],
    },
  ],
};

const SHOP_SELECTOR_OPTIONS: (ShopOption | DivisionGroup)[] = [
  ALL_SHOPS_OPTION,
  {
    label: 'A Division',
    value: 'a-division-group',
    division: Division.A,
    options: [ALL_DIVISION_OPTIONS.A, ...DIVISION_SHOP_OPTIONS.A],
  },
  {
    label: 'B Division',
    value: 'b-division-group',
    division: Division.B,
    options: [ALL_DIVISION_OPTIONS.B, ...DIVISION_SHOP_OPTIONS.B],
  },
];

const SELECTABLE_OPTIONS = [
  ALL_SHOPS_OPTION,
  ALL_DIVISION_OPTIONS.A,
  ...DIVISION_SHOP_OPTIONS.A,
  ALL_DIVISION_OPTIONS.B,
  ...DIVISION_SHOP_OPTIONS.B,
];

export const SHOPS = [...DIVISION_SHOP_OPTIONS.A, ...DIVISION_SHOP_OPTIONS.B];
const SHOP_IDS = SHOPS.map((o) => o.value);

const SELECT_COMPONENTS = {
  Option: (props: OptionProps<any>) => {
    const {
      innerProps,
      isSelected,
      data: { label, lines },
    } = props;

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div {...innerProps} style={props.getStyles('option', props) as any}>
          <Checkbox
            css={css`
              margin-right: 8px;
            `}
            size={CheckboxSize.medium}
            checked={isSelected}
            onChange={() => {}}
          />
          <span
            css={css`
              ${theme.typography.sizes.medium};
              font-family: ${theme.typography.families.primary};
            `}
          >
            {label}
          </span>
          {lines?.map((routeId: string) => (
            <Bullet
              key={routeId}
              size={BulletSize.xsmall}
              routeId={routeId}
              style={{ margin: '4px 4px' }}
            />
          ))}
        </div>
      </div>
    );
  },
  ValueContainer: (props: ValueContainerProps<any>) => {
    const targetedLines: string[] = uniq(
      props
        .getValue()
        .filter((o: ShopOption) => o.optionType === ShopOptionType.SHOP_OPTION)
        .flatMap((o: ShopOption) => o.lines)
        .sort(),
    ) as string[];
    return (
      <components.ValueContainer {...props}>
        {targetedLines.map((routeId) => (
          <Bullet
            key={routeId}
            size={BulletSize.xsmall}
            routeId={routeId}
            style={{ margin: '4px 4px' }}
          />
        ))}
        {props.children}
      </components.ValueContainer>
    );
  },
  Placeholder: (props: PlaceholderProps<any>) => {
    const selectedOptions = props.getValue();
    return selectedOptions.length ? null : (
      <components.Placeholder {...props} />
    );
  },
};

const getSelectedOptions = (value: Shop[]): ShopOption[] => {
  const selectedOptions: ShopOption[] = [];
  const selectedShopIds = new Set<string>(value);

  if (SHOP_IDS.every((id) => selectedShopIds.has(id))) {
    selectedOptions.push(ALL_SHOPS_OPTION);
  }

  Object.keys(DIVISION_SHOP_OPTIONS).forEach((divi) => {
    const division = divi as Division;

    if (
      DIVISION_SHOP_OPTIONS[division].every((divisionOption) =>
        selectedShopIds.has(divisionOption.value),
      )
    ) {
      selectedOptions.push(ALL_DIVISION_OPTIONS[division]);
    }
  });

  selectedOptions.push(
    ...SELECTABLE_OPTIONS.filter((o) => selectedShopIds.has(o.value)),
  );

  return selectedOptions;
};

const getShopSelectorOptions = (
  newSelectedOptions: ShopOption[],
  actionMeta: SelectOptionActionMeta<ShopOption>,
): ShopOption[] => {
  const newOptions: ShopOption[] = [];
  const { action, option } = actionMeta;

  if (action === 'select-option') {
    if (option?.optionType === ShopOptionType.ALL_SHOPS_OPTION) {
      newOptions.push(...SELECTABLE_OPTIONS);
    } else if (option?.division) {
      if (option?.optionType === ShopOptionType.ALL_DIVISION_OPTION) {
        newOptions.push(
          ...newSelectedOptions.filter((o) => o.division !== option.division),
          ALL_DIVISION_OPTIONS[option.division],
          ...DIVISION_SHOP_OPTIONS[option.division],
        );
      } else {
        newOptions.push(...newSelectedOptions);

        // Check if the all division options should be selected
        const divisionShopsKeys = DIVISION_SHOP_OPTIONS[option.division].map(
          (o) => o.value,
        );
        const selectedDivisionShops = new Set(
          newOptions
            .filter(
              (o) =>
                o.optionType === ShopOptionType.SHOP_OPTION &&
                o.division === option.division,
            )
            .map((o) => o.value),
        );

        if (divisionShopsKeys.every((s) => selectedDivisionShops.has(s))) {
          newOptions.push(ALL_DIVISION_OPTIONS[option.division]);
        }
      }
    }

    // Check if the all shops option should be selected
    if (option?.optionType !== ShopOptionType.ALL_SHOPS_OPTION) {
      const allSelectedShops = newOptions.filter(
        (s) => s.optionType === ShopOptionType.SHOP_OPTION,
      );

      if (allSelectedShops.length === SHOPS.length) {
        newOptions.push(ALL_SHOPS_OPTION);
      }
    }
  } else if (action === 'deselect-option') {
    // Check if all of a division's shop options should be deselected
    if (option?.optionType === ShopOptionType.ALL_DIVISION_OPTION) {
      newOptions.push(
        ...newSelectedOptions.filter(
          (o) =>
            !(
              o.division === option?.division ||
              o.optionType === ShopOptionType.ALL_DIVISION_OPTION
            ),
        ),
      );
    } else if (option?.optionType === ShopOptionType.SHOP_OPTION) {
      // Deselect a shop option as well as its division
      newOptions.push(
        ...newSelectedOptions.filter(
          (o) =>
            !(
              o.value === option.value ||
              o.optionType === ShopOptionType.ALL_SHOPS_OPTION ||
              (o.division &&
                o.optionType === ShopOptionType.ALL_DIVISION_OPTION &&
                o.division === option.division)
            ),
        ),
      );
    }
  }

  return newOptions;
};

const ShopSelector: React.FC<
  {
    value: Shop[];
    onChange: (shops: Shop[]) => void;
  } & { children?: React.ReactNode }
> = ({ value, onChange }) => {
  const selectedOptions = getSelectedOptions(value);

  return (
    <Fragment>
      <label
        css={css`
          ${theme.typography.sizes.small};
          font-weight: ${theme.typography.weights.bold};
        `}
        htmlFor="shop-selector"
      >
        Line(s)
      </label>
      <Select
        id="shop-selector"
        css={css`
          margin-bottom: 16px;
          margin-top: 8px;
          width: 62%;
        `}
        isMulti
        isSearchable={false}
        isClearable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}
        placeholder="Select Lines(s)"
        options={SHOP_SELECTOR_OPTIONS}
        components={SELECT_COMPONENTS}
        value={selectedOptions}
        onChange={(
          newSelectedOptions: ShopOption[],
          actionMeta: SelectOptionActionMeta<ShopOption>,
        ) => {
          onChange(
            getShopSelectorOptions(newSelectedOptions, actionMeta)
              .filter((o) => o.optionType === ShopOptionType.SHOP_OPTION)
              .map((o) => o.value as Shop),
          );
        }}
      />
    </Fragment>
  );
};

export default ShopSelector;
