import React from 'react';

const ContentSectionSkeleton: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  return (
    <React.Fragment>
      <rect x="0" y="0" rx="4" ry="4" width="615" height="35" />
      <rect x="0" y="70" rx="4" ry="4" width="180" height="18" />
      <rect x="0" y="100" rx="4" ry="4" width="100%" height="140" />
      <rect x="0" y="280" rx="4" ry="4" width="545" height="18" />
      <rect x="0" y="310" rx="4" ry="4" width="100%" height="60" />
    </React.Fragment>
  );
};

export default ContentSectionSkeleton;
