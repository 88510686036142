/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import MessageCard from '../../../common/message/message-card';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message as GetEventById_event_messages_nodes } from '../../../../generated/GetEventById';
import { GtfsEntitySelectorInput } from '../../../../generated/global-types';

const UpdateMessageCard: React.FC<
  GetEventById_event_messages_nodes & {
    prevIsDraft?: boolean;
  }
> & { children?: React.ReactNode } = ({
  id,
  eventId,
  createdAt,
  endAt,
  entitySelectors,
  taggedTrips,
  bodyHtml,
  webStatus,
  screensStatus,
  emailSmsStatus,
  tweetsStatus,
  author,
  messageImpacts,
  prevIsDraft = false,
}) => {
  const impacts = messageImpacts
    ? messageImpacts.nodes.map((node) => ({
        messageType: node.messageType,
        entitySelectors: node.entitySelectors as GtfsEntitySelectorInput[],
      }))
    : undefined;

  return (
    <MessageCard
      css={css`
        border-radius: 0px 0px 4px 4px;
      `}
      id={id || undefined}
      eventId={eventId || undefined}
      createdAt={createdAt}
      endAt={endAt}
      hasUpdateLink={false}
      entitySelectors={entitySelectors}
      taggedTrips={taggedTrips}
      bodyHtml={bodyHtml}
      webStatus={webStatus}
      screensStatus={screensStatus}
      emailSmsStatus={emailSmsStatus}
      tweetsStatus={tweetsStatus}
      isUpdate={false}
      authorName={author && author.name ? author.name : undefined}
      impacts={impacts}
      isDraft={prevIsDraft}
    />
  );
};

export default UpdateMessageCard;
