/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useHistory, useLocation } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';
import ErrorModal from 'components/common/error-modal';
import {
  GENERIC_SUBMIT_ERROR,
  GENERIC_SUBMIT_NETWORK_ERROR,
} from 'constants/error-messages';
import * as Sentry from '@sentry/browser';

import BackOnlyPageHeading from '../../scaffolding/back-only-page.heading';
import PageMeta from '../../common/PageMeta';
import Button from '../../common/Button';
import { useFeedId } from '../../../contexts/FeedId';
import CampaignForm, {
  FormValues as CampaignFormValues,
  DefaultValues as CampaignFormDefaultValues,
} from '../../common/campaign-form';
import { BackPageState } from '../Screen/single-screen-campaign';

import * as S from './index.styled';

import {
  CreateCampaign,
  CreateCampaignVariables,
} from '../../../generated/CreateCampaign';

const CreateCampaignMutation = loader('../../../graphql/CreateCampaign.gql');

const CampaignCompose: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const history = useHistory();
  const location = useLocation<{
    defaultValues?: CampaignFormDefaultValues;
    state?: BackPageState;
  }>();
  const feedId = useFeedId();

  const { backTitle, backPath } = (location.state ?? {}) as BackPageState;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorModalSettings, setErrorModalSettings] = useState({
    isOpen: false,
    isNetworkError: false,
  });

  const [createCampaign] = useMutation<CreateCampaign, CreateCampaignVariables>(
    CreateCampaignMutation,
    {
      onError: (error: ApolloError) => {
        if (error) {
          setErrorModalSettings({
            isOpen: true,
            isNetworkError: !!error.networkError?.name,
          });
        }
        throw error;
      },
    },
  );

  const defaultValues = location.state?.defaultValues;

  const handleSubmit =
    ({ draft }: { draft?: boolean } = {}) =>
    async ({ ...restFV }: CampaignFormValues) => {
      setIsSubmitting(true);
      try {
        const { data } = await createCampaign({
          variables: {
            createCampaignInput: { ...restFV, draft },
          },
        });
        setIsSubmitting(false);

        if (data?.createCampaign?.campaign?.id) {
          const {
            createCampaign: { campaign },
          } = data;

          history.push(`/${feedId}/campaigns/${campaign.id}`, {
            numScreensTargeted:
              campaign.targetedScreensCount +
              campaign.targetedSyntheticScreensCount,
            status: campaign.status,
          });
          return;
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      setIsSubmitting(false);
    };

  return (
    <div style={{ width: '100%' }}>
      <PageMeta title="Compose Campaign" />
      <BackOnlyPageHeading
        back={{
          to: backPath ?? `/${feedId}/campaigns`,
          title: backTitle ?? 'Back to Campaigns',
        }}
      />
      <CampaignForm
        feedId={feedId}
        heading="Compose Campaign"
        defaultValues={defaultValues}
      >
        {/* @ts-ignore */}
        {({ makeSubmitHandler, form }) => {
          return (
            <React.Fragment>
              {form}

              <S.Buttons
                css={css`
                  margin: 16px 32px;
                `}
              >
                <Button
                  primary
                  type="button"
                  disabled={isSubmitting}
                  onClick={makeSubmitHandler(handleSubmit({ draft: false }))}
                >
                  Publish
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={makeSubmitHandler(handleSubmit({ draft: true }))}
                >
                  Save as draft
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    if (
                      window.confirm(
                        `${DISCARD_CHANGES_MESSAGE} This campaign will not be saved.`,
                      )
                    ) {
                      history.push(`/${feedId}/campaigns`);
                    }
                  }}
                >
                  Cancel
                </Button>
              </S.Buttons>
            </React.Fragment>
          );
        }}
      </CampaignForm>
      <ErrorModal
        isOpen={errorModalSettings.isOpen}
        title="Campaign Failed to Post"
        onDismiss={() =>
          setErrorModalSettings({
            isOpen: false,
            isNetworkError: false,
          })
        }
        message={
          errorModalSettings.isNetworkError
            ? GENERIC_SUBMIT_NETWORK_ERROR
            : GENERIC_SUBMIT_ERROR
        }
        includeAssistanceMessage
      />
    </div>
  );
};

export default CampaignCompose;
