/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { css } from '@emotion/react';
import Button from '../../../common/Button';
import { ReactComponent as CrocImage } from '../../../../images/croc.svg';
import Heading from '../../../common/Heading';
import { ThemeType } from '../../../../theme';
import PageMeta from '../../../common/PageMeta';
import { Container, Text } from '../index.styled';

const { REACT_APP_CONTACT_ADMIN_URL } = process.env;

const PermissionsNeeded: React.FC<
  unknown & { children?: React.ReactNode }
> = () => (
  <Container>
    <PageMeta title="Unauthorized User" baseTitle="Mercury" />
    <CrocImage width={96} height={92} />
    <Heading
      css={(theme: ThemeType) => css`
        margin-top: ${theme.spacing.xsmall};
        letter-spacing: 0.39px;
      `}
      level={1}
      size="xxlarge"
    >
      Permissions Needed
    </Heading>
    <Text>You currently don&apos;t have permissions to see this.</Text>

    <a
      css={css`
        text-decoration: none;
      `}
      href={REACT_APP_CONTACT_ADMIN_URL}
    >
      <Button as="div" primary>
        Contact Admin
      </Button>
    </a>
    <Redirect to="/" />
  </Container>
);

export default PermissionsNeeded;
