/** @jsxImportSource @emotion/react */

import React from 'react';

const CrossIcon: React.FC<{
  className?: string;
  color?: string;
  size?: string;
  children?: React.ReactNode;
}> = ({ className, color = '#246EB9', size = '15px' }) => {
  return (
    <div className={className}>
      <svg width={size} height={size} viewBox="0 0 15 15">
        <path
          d="M14.752 14.735a.846.846 0 01-.617.243.947.947 0 01-.617-.243L7.5 8.715l-6.018 6.02A.87.87 0 01.865 15a.947.947 0 01-.617-.243.865.865 0 010-1.212l6.04-6.042-6.04-6.02a.865.865 0 010-1.213.865.865 0 011.212 0L7.5 6.29 13.518.248a.865.865 0 011.212 0c.33.33.33.882 0 1.213L8.712 7.503l6.04 6.02c.33.33.33.882 0 1.212z"
          fill={color}
          fillRule="nonzero"
        />
      </svg>
    </div>
  );
};

export default CrossIcon;
