/** @jsxImportSource @emotion/react */

import { ScreenDeployTypes } from 'generated/global-types';
import useQueryParams from 'hooks/useQueryParams';

import OutfrontScreen from './outfront-screen';
import SyntheticScreen from './synthetic-screen';

const Screens: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const queryParams = useQueryParams();

  if (queryParams.get('t')?.toUpperCase() === ScreenDeployTypes.SYNTHETIC) {
    return <SyntheticScreen />;
  }

  return <OutfrontScreen />;
};

export default Screens;
