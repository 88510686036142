/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import CommonHeading from '../../../common/Heading';
import Button from '../../../common/Button';
import { ReactComponent as Plus } from '../../../../images/plus.svg';
import { StationAlternativeInput } from '../../../../generated/global-types';

import StationAlternativesEditor from './station-alternatives-editor';

const AddStationAlternative: React.FC<{
  value: StationAlternativeInput[];
  index?: number;
  onChange: Function;
  children?: React.ReactNode;
}> = ({ value, index = 0, onChange }) => {
  const handleClick = () => {
    const newValue = [
      ...value.slice(0, index + 1),
      {},
      ...value.slice(index + 1),
    ];

    onChange(newValue as StationAlternativeInput[]);
  };

  return (
    <Button type="button" plain onClick={() => handleClick()}>
      <Plus /> Add station alternative
    </Button>
  );
};

const StationAlternatives: React.FC<{
  value: StationAlternativeInput[];
  onChange: Function;
  children?: React.ReactNode;
}> = ({ value, onChange }) => {
  const [removedCount, setRemovedCount] = useState(0);
  const altStationCount = value.length;

  return (
    <div
      css={css`
        margin-top: 24px;
      `}
    >
      {altStationCount > 0 && (
        <CommonHeading level={4}>Station Alternative</CommonHeading>
      )}
      {value.map(({ contextualSelector, notes }, index) => {
        const key = `${contextualSelector?.context?.gtfsRouteId}${index}${removedCount}`;
        return (
          <React.Fragment key={key}>
            <StationAlternativesEditor
              value={value}
              contextualSelector={contextualSelector}
              notes={notes}
              index={index}
              removedCount={removedCount}
              setRemovedCount={setRemovedCount}
              onChange={onChange}
            />
            <AddStationAlternative
              index={index}
              value={value}
              onChange={onChange}
            />
          </React.Fragment>
        );
      })}
      {!altStationCount && (
        <AddStationAlternative value={value} onChange={onChange} />
      )}
    </div>
  );
};

export default StationAlternatives;
