/** @jsxImportSource @emotion/react */

import React from 'react';
import ConfirmModal from 'components/common/confirm-modal';
import {
  PLANNED_PARTIAL_DURATION,
  PLANNED_SAVE_LIVE_AS_DRAFT,
} from 'constants/error-messages';

// `Confirmation` is a type that is used to define the different types of confirmations that can be shown
// in the modal. It is used to define the different types of messages/buttons that can be shown in the modal.
// The `MODAL_PROPS` object is used to define the different types of messages and buttons that can be shown
// in the modal based on the `Confirmation` type.
export enum Confirmation {
  PARTIAL_DURATIONS = 'partialDurations',
  DATES_EXPIRED = 'datesExpired',
  SAVE_LIVE_PW_AS_DRAFT = 'saveLivePwAsDraft',
  RESTORE_CHANGES = 'restoreChanges',
}

const MODAL_PROPS = {
  [Confirmation.PARTIAL_DURATIONS]: {
    title: 'Remove Duration',
    message: PLANNED_PARTIAL_DURATION,
    confirmText: 'Confirm',
    dismissText: 'Cancel',
  },
  [Confirmation.SAVE_LIVE_PW_AS_DRAFT]: {
    title: 'Save Planned Work as a Draft',
    message: PLANNED_SAVE_LIVE_AS_DRAFT,
    confirmText: 'Save as Draft',
    dismissText: 'Go Back',
  },
  [Confirmation.RESTORE_CHANGES]: {
    title: 'Restore Changes',
    message:
      'It looks like you have some previously unsaved changes. Would you like to restore them?',
    confirmText: 'Restore',
    dismissText: 'Dismiss',
  },
};

const PlannedWorkConfirmModal: React.FC<{
  isOpen: boolean;
  // We accept `confirmation` prop as undefined because we don't want to show the modal if there
  // is no confirmation to show. This is handled here rather than handling it in every parent component.
  confirmation?: Confirmation;
  onConfirm: () => void;
  onDismiss: () => void;
}> = ({ isOpen, confirmation, onConfirm, onDismiss }) => {
  if (!confirmation) {
    return null;
  }

  const modalProps = MODAL_PROPS[confirmation];

  return (
    <ConfirmModal
      {...modalProps}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    />
  );
};

export default PlannedWorkConfirmModal;
