/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import useShouldShowSkeleton from './hooks/useShouldShowSkeleton';
import ContentSection from './components/ContentSection';
import SkeletonLoader from './components/SkeletonLoader';
import Divider from './components/Divider';
import { LoaderComponent } from './types';

const PageWithContent: LoaderComponent = ({ loading, after, children }) => {
  const shouldShowSkeleton = useShouldShowSkeleton(loading, { after });
  return (
    <React.Fragment>
      {shouldShowSkeleton ? (
        <div
          css={(theme) => css`
            width: 100%;
            padding: ${theme.spacing.large};
          `}
        >
          <SkeletonLoader height="25" width="210">
            <rect x="0" y="0" rx="4" ry="4" width="210" height="25" />
          </SkeletonLoader>
          <div
            css={(theme) => css`
              border-radius: 4px;
              margin-top: 15px;
              background-color: ${theme.colors.white};
            `}
          >
            <div
              css={css`
                padding: 45px 50px;
                height: 130px;
              `}
            >
              <SkeletonLoader height="50" width="260">
                <rect x="0" y="0" rx="4" ry="4" width="260" height="50" />
              </SkeletonLoader>
            </div>
            <Divider />
            <div
              css={css`
                padding: 45px 50px;
              `}
            >
              <SkeletonLoader height="350">
                <ContentSection />
              </SkeletonLoader>
            </div>
            <Divider />
            <div
              css={css`
                padding: 45px 50px;
              `}
            >
              <SkeletonLoader height="350">
                <ContentSection />
              </SkeletonLoader>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </React.Fragment>
  );
};

export default PageWithContent;
