/** @jsxImportSource @emotion/react */

import React from 'react';

const CheckIcon: React.FC<{
  className?: string;
  color?: string;
  size?: string;
  children?: React.ReactNode;
}> = ({ className, color = '#246EB9', size = '15px' }) => {
  return (
    <div className={className}>
      <svg width={size} height={size} viewBox="0 0 14 10">
        <defs>
          <polygon points="-0.0006 -0.000800080008 15.9994 -0.000800080008 15.9994 16.0008001 -0.0006 16.0008001" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-169.000000, -76.000000)">
            <g transform="translate(0.000000, 56.000000)">
              <g transform="translate(168.000000, 17.000000)">
                <mask fill={color} />
                <path
                  d="M5.08775,12.4121 L2.08775,9.4121 C1.97075,9.2951 1.97075,9.1049 2.08775,8.9873 C2.20475,8.8709 2.39495,8.8709 2.51195,8.9873 L5.29955,11.7755 L13.48715,3.5873 C13.60415,3.4709 13.79435,3.4709 13.91135,3.5873 C14.02835,3.7049 14.02835,3.8951 13.91135,4.0121 L5.51135,12.4121 C5.45255,12.4709 5.37575,12.4997 5.29895,12.4997 C5.22335,12.4997 5.14655,12.4703 5.08775,12.4121 Z"
                  stroke={color}
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CheckIcon;
