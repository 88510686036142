/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import theme from '../../../theme';
import ChevronIcon from '../chevron-icon';

export const SELECT_OVERLAY_STYLES = {
  menu: {
    ':after': {
      content: '""',
      boxShadow: `inset 0 0 4px ${theme.colors['border-dark']}`,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 4,
      pointerEvents: 'none',
    },
    ':before': {
      content: '""',
      backgroundColor: theme.colors.white,
      height: '1px',
      position: 'absolute',
      top: '1px',
      left: '3px',
      right: '3px',
      zIndex: 4,
      pointerEvents: 'none',
    },
  },
  control: {
    ':after': {
      content: '""',
      backgroundColor: theme.colors.white,
      height: '1px',
      position: 'absolute',
      bottom: '2px',
      left: '3px',
      right: '3px',
      zIndex: 4,
      pointerEvents: 'none',
    },
  },
};

const DropdownIndicator: React.FC<any> = ({ selectProps: { menuIsOpen } }) => (
  <ChevronIcon
    size="12px"
    direction={menuIsOpen ? 'up' : 'down'}
    css={css`
      margin-right: 16px;
    `}
  />
);

export const getPlaceholderColor = (
  isFocused: boolean,
  isDisabled: boolean,
) => {
  if (isFocused) {
    return theme.colors.accent1;
  }

  if (isDisabled) {
    return theme.colors.light3;
  }

  return '#707070';
};

const lmmStyles = {
  placeholder: (
    provided: {},
    props: { isFocused: boolean; hasValue: boolean; isDisabled: boolean },
  ) => ({
    ...provided,
    fontSize: theme.typography.sizes.medium.fontSize,
    lineHeight: theme.typography.sizes.medium.lineHeight,
    fontFamily: theme.typography.families.primary,
    fontWeight: theme.typography.weights.normal,
    color: getPlaceholderColor(props.isFocused, props.isDisabled),
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
  }),
  singleValue: (provided: {}) => {
    return {
      ...provided,
      color: '#0F61A9',
      fontSize: theme.typography.sizes.medium.fontSize,
      lineHeight: theme.typography.sizes.medium.lineHeight,
      fontFamily: theme.typography.families.primary,
      fontWeight: theme.typography.weights.bold,
    };
  },
  dropdownIndicator: (provided: {}) => ({
    ...provided,
    color: theme.colors.accent1,
    '&:hover': {
      color: theme.colors.accent1,
    },
  }),
  option: (provided: {}, state: any) => {
    return {
      ...provided,
      color: theme.colors.black,
      backgroundColor:
        state.isFocused || state.isSelected ? '#E7EFF6' : theme.colors.white,
      fontSize: theme.typography.sizes.medium.fontSize,
      lineHeight: theme.typography.sizes.medium.lineHeight,
      fontFamily: theme.typography.families.primary,
      fontWeight: theme.typography.weights.normal,
      position: 'relative',
      display: 'flex',
      paddingLeft: '20px',
    };
  },
  menuList: (provided: {}) => {
    return {
      ...provided,
      padding: 0,
      borderTop: '1px solid #EAEAEA',
      '> div:first-of-type': {
        marginTop: '8px',
      },
    };
  },
  menu: (provided: {}) => {
    return {
      ...provided,
      overflow: 'hidden',
      zIndex: 2,
      marginTop: '-1px',
      borderRadius: '0 0 4px 4px',
      border: `1px solid ${theme.colors.accent3}`,
      borderTop: 'unset',
      ...SELECT_OVERLAY_STYLES.menu,
    };
  },
  group: (provided: {}) => {
    return {
      ...provided,
      background: '#CECECE',
      paddingBottom: 0,
      paddingTop: 0,
    };
  },
  groupHeading: (provided: {}) => {
    return {
      ...provided,
      background: theme.colors.border,
      fontSize: theme.typography.sizes.medium.fontSize,
      lineHeight: theme.typography.sizes.medium.lineHeight,
      fontFamily: theme.typography.families.primary,
      fontWeight: theme.typography.weights.bold,
      color: theme.colors.black,
      textTransform: 'capitalize',
      paddingBottom: '8px',
      paddingTop: '8px',
      marginBottom: 0,
    };
  },
  valueContainer: (provided: {}) => {
    return {
      ...provided,
      paddingLeft: theme.spacing.small,
      display: 'flex',
    };
  },
};

interface ICustomSelectProps {
  hasControlBorder?: boolean;
  hasControlBoxShadow?: boolean;
  isCreatable?: boolean;
}

const Select: React.FC<ICustomSelectProps & any> = ({
  hasControlBorder = true,
  hasControlBoxShadow = true,
  components = {},
  placeholder = '',
  styles,
  isCreatable = false,
  ...rest
}) => {
  const controlStyles = (
    controlProvided: {},
    controlProps: {
      isFocused: boolean;
      hasValue: boolean;
      isDisabled: boolean;
      menuIsOpen: boolean;
    },
  ) => {
    let controlBorderRadius = '4px';

    if (controlProps.menuIsOpen) {
      controlBorderRadius = '4px 4px 0px 0px';
    }

    return {
      ...controlProvided,
      backgroundColor: 'inherit',
      backgroundClip: 'padding-box',
      borderRadius: controlBorderRadius,
      borderStyle: hasControlBorder ? 'solid' : 'none',
      borderColor: hasControlBorder
        ? theme.colors['border-dark']
        : theme.colors.white,
      boxShadow: !hasControlBoxShadow
        ? 'none'
        : `inset 0 0 4px ${theme.colors['border-dark']}`,
      minHeight: '44px',
      opacity: controlProps.isDisabled ? 0.5 : 1,
      '&:hover': {
        borderColor: controlProps.isFocused ? theme.colors.accent3 : '',
      },
      ...(controlProps.isFocused
        ? {
            border: `1px solid ${theme.colors.accent3}`,
            ...SELECT_OVERLAY_STYLES.control,
          }
        : {}),
      ...(controlProps.menuIsOpen
        ? {
            border: `1px solid ${theme.colors.accent3}`,
            borderBottom: '#EAEAEA',
          }
        : {}),
    };
  };

  const sharedProps = {
    components: Object.assign(components, {
      DropdownIndicator,
      IndicatorSeparator: () => null,
    }),
    styles: {
      ...lmmStyles,
      ...styles,
      control: styles?.control
        ? (provided: {}, state: any) => {
            return {
              ...controlStyles(provided, state),
              ...styles.control(provided, state),
            };
          }
        : controlStyles,
      container: styles?.container
        ? (provided: {}, state: any) => {
            return {
              ...styles.container(provided, state),
            };
          }
        : (provided: {}) => {
            return {
              ...provided,
            };
          },
    },
    placeholder,
    ...rest,
  };

  if (isCreatable) {
    return <CreatableSelect {...sharedProps} />;
  }

  return <ReactSelect {...sharedProps} />;
};

export default Select;
