/** @jsxImportSource @emotion/react */

import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import { isAfter } from 'date-fns';

import Heading from '../Heading';
import LiveTime from '../LiveTime';
import { useDate as useNow } from '../../../utils/clock';

import * as S from './PublishTimes.styled';
import { ThemeSizes } from '../../../theme';
import EventStamp from '../event-stamp';

const PublishTimeDisplay: React.FC<
  {
    date: Date | null;
    label: string;
    stampDate?: Date | null;
    authorName?: string | null;
    dateSize?: ThemeSizes;
    labelSize?: ThemeSizes;
    labelPos: 'horizontal' | 'vertical';
    useNotApplicableFallback?: boolean;
    verboseUpdateText?: boolean;
    showLiveTime?: boolean;
    showDate?: boolean;
  } & { children?: React.ReactNode }
> = ({
  date,
  label,
  verboseUpdateText,
  stampDate,
  authorName,
  labelPos = 'vertical',
  labelSize = 'small',
  dateSize = 'medium',
  useNotApplicableFallback = false,
  showLiveTime = true,
  showDate = true,
}) => {
  const Container =
    labelPos === 'horizontal'
      ? S.SingleTimeContainerHorizontal
      : S.SingleTimeContainerVertical;
  return (
    <div
      css={css`
        opacity: ${useNotApplicableFallback && !date ? 0.5 : 1};
      `}
    >
      <Container>
        <Heading
          css={css`
            ${labelPos === 'vertical' ? 'margin-bottom: 4px;' : ''}
          `}
          level={6}
          size={labelSize}
        >
          {`${label}: `}
        </Heading>
        {date && (
          <S.PublishTime dateTime={date.toISOString()} size={dateSize}>
            {showLiveTime && <LiveTime format="fromNow" timeToCompare={date} />}
            {verboseUpdateText && stampDate && showDate && (
              <React.Fragment>
                {showLiveTime && <span>, </span>}
                <EventStamp date={stampDate} name={authorName} />
              </React.Fragment>
            )}
          </S.PublishTime>
        )}
        {!date && (
          <S.TimeNotSet size={dateSize}>
            {useNotApplicableFallback ? 'N/A' : 'Not Set'}
          </S.TimeNotSet>
        )}
      </Container>
    </div>
  );
};

type ShowDate = {
  created?: boolean;
  updated?: boolean;
  expired?: boolean;
};
interface PublishTimesProps {
  className?: string;
  clearedAt?: Date | null;
  clearedBy?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
  dateSize?: ThemeSizes;
  endAt?: Date | null;
  labelSize?: ThemeSizes;
  authorName?: string;
  updatedByName?: string | null;
  verboseUpdateText?: boolean;
  labelPos?: 'horizontal' | 'vertical';
  useNotApplicableEndAtFallback?: boolean;
  isCancelled?: boolean;
  isCleared?: boolean;
  showLiveTime?: ShowDate;
  showDate?: ShowDate;
  seperateUpdateCreateDates?: boolean;
  showAllActionsDates?: boolean;
  createdAtLabel?: string;
}

const PublishTimes: React.FC<PublishTimesProps> = ({
  className,
  createdAt,
  endAt,
  clearedAt,
  clearedBy,
  updatedAt,
  labelSize,
  dateSize,
  authorName,
  verboseUpdateText = false,
  labelPos = 'vertical',
  useNotApplicableEndAtFallback = false,
  isCancelled = false,
  isCleared = false,
  seperateUpdateCreateDates = false,
  showAllActionsDates = false,
  createdAtLabel,
  showLiveTime = {
    created: true,
    updated: true,
    expired: true,
  },
  showDate = {
    expired: true,
  },
  updatedByName,
}) => {
  const now = useNow();
  const endDate = endAt ? new Date(endAt) : null;
  const clearedDate = clearedAt ? new Date(clearedAt) : endDate;

  const updateDate = updatedAt ? new Date(updatedAt) : null;
  const createdDate = createdAt ? new Date(createdAt) : null;
  const expired = !!(endDate && isAfter(now, endDate));

  if (showAllActionsDates) {
    return (
      <div className={className}>
        <PublishTimeDisplay
          verboseUpdateText
          label={createdAtLabel ?? 'Created'}
          date={createdDate}
          labelSize={labelSize}
          dateSize={dateSize}
          stampDate={createdAt}
          showLiveTime={showLiveTime.created}
          labelPos={labelPos}
          authorName={authorName}
        />
        {!isCancelled && updatedByName && (
          <PublishTimeDisplay
            label="Last Updated"
            date={updateDate}
            labelSize={labelSize}
            dateSize={dateSize}
            stampDate={updatedAt}
            authorName={updatedByName}
            labelPos={labelPos}
            verboseUpdateText
          />
        )}
        {isCleared && clearedBy && clearedDate && (
          <PublishTimeDisplay
            verboseUpdateText
            label="Cleared"
            date={clearedDate}
            labelSize={labelSize}
            dateSize={dateSize}
            stampDate={clearedDate}
            labelPos={labelPos}
            authorName={clearedBy}
          />
        )}
        {isCancelled && (
          <PublishTimeDisplay
            label="Cancelled"
            date={updateDate ?? createdDate}
            labelSize={labelSize}
            dateSize={dateSize}
            stampDate={updatedAt ?? createdAt}
            authorName={updatedByName ?? authorName}
            labelPos={labelPos}
            verboseUpdateText
          />
        )}
        {!isCancelled && !clearedBy && (
          <PublishTimeDisplay
            label={expired ? 'Expired' : 'Expiring In'}
            date={endDate}
            labelSize={labelSize}
            dateSize={dateSize}
            stampDate={endDate}
            verboseUpdateText
            labelPos={labelPos}
            showDate={showDate.expired}
            useNotApplicableFallback={useNotApplicableEndAtFallback}
          />
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      {seperateUpdateCreateDates ? (
        <Fragment>
          <PublishTimeDisplay
            label={isCancelled ? 'Removed' : (createdAtLabel ?? 'Created At')}
            date={createdDate}
            labelSize={labelSize}
            dateSize={dateSize}
            stampDate={createdAt}
            authorName={authorName}
            labelPos={labelPos}
            verboseUpdateText={verboseUpdateText}
            showLiveTime={showLiveTime.created}
          />
          {!isCancelled && updatedAt && (
            <PublishTimeDisplay
              label="Last Updated"
              date={updateDate}
              labelSize={labelSize}
              dateSize={dateSize}
              stampDate={updatedAt}
              authorName={updatedByName}
              labelPos={labelPos}
              verboseUpdateText={verboseUpdateText}
              showLiveTime={showLiveTime.updated}
            />
          )}
        </Fragment>
      ) : (
        <PublishTimeDisplay
          label={isCancelled ? 'Removed' : 'Last Updated'}
          date={updateDate ?? createdDate}
          labelSize={labelSize}
          dateSize={dateSize}
          stampDate={updatedAt ?? createdAt}
          authorName={updatedByName ?? authorName}
          labelPos={labelPos}
          verboseUpdateText={verboseUpdateText}
        />
      )}
      {!isCancelled && !clearedBy && (
        <PublishTimeDisplay
          label={expired ? 'Expired' : 'Expiring In'}
          date={endDate}
          labelSize={labelSize}
          dateSize={dateSize}
          labelPos={labelPos}
          stampDate={endDate}
          useNotApplicableFallback={useNotApplicableEndAtFallback}
          verboseUpdateText={verboseUpdateText}
          showLiveTime={showLiveTime.expired}
        />
      )}
      {isCleared && clearedDate && (
        <PublishTimeDisplay
          verboseUpdateText
          label="Cleared"
          date={clearedDate}
          labelSize={labelSize}
          dateSize={dateSize}
          stampDate={clearedDate}
          labelPos={labelPos}
          authorName={clearedAt ? clearedBy : ''}
          showLiveTime={showLiveTime.expired}
        />
      )}
    </div>
  );
};

export default PublishTimes;
