type GridItemCoords = {
  x: number;
  y: number;
};

export type GridItemProps = {
  index: number;
  coords: GridItemCoords;
};

export type GridItemWidth = {
  index: number;
  width: number;
};

export enum ArrowKeys {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
}

// Determines a grid item's coordinates based on its index in a
// list of grid item widths and the width of its container element
export const getGridItemCoords = (
  gridItemIndex: number,
  gridItemWidths: GridItemWidth[],
  containerWidth: number,
): GridItemCoords => {
  if (gridItemIndex < 0 || gridItemIndex > gridItemWidths.length - 1) {
    return {
      x: -1,
      y: -1,
    };
  }

  let x = -1;
  let y = 0;
  let rowWidthAccumulator = 0;

  const sortedWidths = gridItemWidths
    .sort((w1, w2) => w1.index - w2.index)
    .map((w) => w.width);

  for (let i = 0; i <= gridItemIndex; i++) {
    x++;
    rowWidthAccumulator += sortedWidths[i];

    if (rowWidthAccumulator > containerWidth) {
      rowWidthAccumulator = sortedWidths[i];
      y++;
      x = 0;
    }
  }

  return {
    x,
    y,
  };
};

// Determines the next grid item coordinates from a list
// of possibly differently sized grid items, which entails
// possibly having a different nunber of items per row
export const getNextGridItemProps = (
  direction: ArrowKeys,
  focusedGridItem: GridItemProps,
  gridItemWidths: GridItemWidth[],
  containerWidth: number,
): GridItemProps => {
  if (direction === ArrowKeys.ArrowRight) {
    const nextIndexCoords = getGridItemCoords(
      focusedGridItem.index + 1,
      gridItemWidths,
      containerWidth,
    );

    // Targets element directly right of the currently focused item
    if (nextIndexCoords.y === focusedGridItem.coords.y) {
      return {
        index: focusedGridItem.index + 1,
        coords: {
          x: nextIndexCoords.x,
          y: focusedGridItem.coords.y,
        },
      };
    }

    // Targets element at the beginning of the row of the currently
    // focused item after reaching the end of the row
    if (
      nextIndexCoords.y > focusedGridItem.coords.y ||
      nextIndexCoords.y === -1
    ) {
      return {
        index: focusedGridItem.index - focusedGridItem.coords.x,
        coords: {
          x: 0,
          y: focusedGridItem.coords.y,
        },
      };
    }
  }

  if (direction === ArrowKeys.ArrowLeft) {
    const previousIndexCoords = getGridItemCoords(
      focusedGridItem.index - 1,
      gridItemWidths,
      containerWidth,
    );

    // Targets element directly left of the currently focused item
    if (previousIndexCoords.y === focusedGridItem.coords.y) {
      return {
        index: focusedGridItem.index - 1,
        coords: {
          x: previousIndexCoords.x,
          y: previousIndexCoords.y,
        },
      };
    }

    // Targets element at the end of the row of the currently
    // focused item after reaching the start of the row
    if (
      previousIndexCoords.y < focusedGridItem.coords.y ||
      previousIndexCoords.y === -1
    ) {
      let hasNotReachedCorrectItem = true;
      let correctItemIndex = focusedGridItem.index;
      let correctItemCoords = {
        x: focusedGridItem.coords.x,
        y: focusedGridItem.coords.y,
      };

      while (hasNotReachedCorrectItem) {
        const possibleCorrectItemCoords = getGridItemCoords(
          correctItemIndex + 1,
          gridItemWidths,
          containerWidth,
        );

        if (
          correctItemCoords.y === -1 ||
          possibleCorrectItemCoords.y !== correctItemCoords.y
        ) {
          hasNotReachedCorrectItem = false;
        } else {
          correctItemIndex++;
          correctItemCoords = {
            x: possibleCorrectItemCoords.x,
            y: possibleCorrectItemCoords.y,
          };
        }
      }

      return {
        index: correctItemIndex,
        coords: {
          x: correctItemCoords.x,
          y: correctItemCoords.y,
        },
      };
    }
  }

  if (direction === ArrowKeys.ArrowDown) {
    let hasNotReachedCorrectItem = true;
    let correctItemIndex = focusedGridItem.index;
    let correctItemCoords = {
      x: focusedGridItem.coords.x,
      y: focusedGridItem.coords.y,
    };

    while (hasNotReachedCorrectItem) {
      correctItemIndex++;
      let possibleCorrectItemCoords = getGridItemCoords(
        correctItemIndex,
        gridItemWidths,
        containerWidth,
      );

      if (possibleCorrectItemCoords.x === -1) {
        correctItemIndex = -1;
      } else {
        // Targets element at the same 'x' as the currently focused item,
        // either directly beneath it in the grid or in the first row of items
        if (possibleCorrectItemCoords.x === focusedGridItem.coords.x) {
          hasNotReachedCorrectItem = false;
          correctItemCoords = {
            x: possibleCorrectItemCoords.x,
            y: possibleCorrectItemCoords.y,
          };
        }

        // Targets the last element of the row beneath the focused item
        // in case the upper has more elements than the one underneath it
        if (
          possibleCorrectItemCoords.x === 0 &&
          possibleCorrectItemCoords.y > focusedGridItem.coords.y + 1
        ) {
          hasNotReachedCorrectItem = false;
          correctItemIndex--;
          possibleCorrectItemCoords = getGridItemCoords(
            correctItemIndex - 1,
            gridItemWidths,
            containerWidth,
          );
          correctItemCoords = {
            x: possibleCorrectItemCoords.x,
            y: possibleCorrectItemCoords.y,
          };
        }
      }
    }

    return {
      index: correctItemIndex,
      coords: {
        x: correctItemCoords.x,
        y: correctItemCoords.y,
      },
    };
  }

  if (direction === ArrowKeys.ArrowUp) {
    let hasNotReachedCorrectItem = true;
    let correctItemIndex = focusedGridItem.index;
    let correctItemCoords = {
      x: focusedGridItem.coords.x,
      y: focusedGridItem.coords.y,
    };

    while (hasNotReachedCorrectItem) {
      correctItemIndex--;
      const possibleCorrectItemCoords = getGridItemCoords(
        correctItemIndex,
        gridItemWidths,
        containerWidth,
      );

      if (possibleCorrectItemCoords.x === -1) {
        correctItemIndex = gridItemWidths.length;
      } else if (possibleCorrectItemCoords.x === focusedGridItem.coords.x) {
        // Targets element at the same 'x' as the currently focused item, either directly above
        // it in the grid or in the last row of the grid having the same number of items
        hasNotReachedCorrectItem = false;
        correctItemCoords = {
          x: possibleCorrectItemCoords.x,
          y: possibleCorrectItemCoords.y,
        };
      }
    }

    return {
      index: correctItemIndex,
      coords: {
        x: correctItemCoords.x,
        y: correctItemCoords.y,
      },
    };
  }

  return {
    index: -1,
    coords: {
      x: -1,
      y: -1,
    },
  };
};
