/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import { flexRow, flexCol } from '../../common/styles';
import Theme, { ThemeType } from '../../../theme';
import ChevronIcon from '../../common/chevron-icon';
import { ReactComponent as ClockIcon } from '../../../images/white_clock.svg';

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      ${flexCol};
      position: relative;
      flex: 1 0 auto;
      border-top: 1px solid ${theme.colors.border};
    `}
    {...props}
  />
);

export const InactiveContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      border-top: 1px solid ${theme.colors.border};
      padding: 32px 40px;
    `}
    {...props}
  />
);

export const InactiveContentContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
    {...props}
  />
);

export const ToggleButton: React.FC<
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { canOpen?: boolean }
> & { children?: React.ReactNode } = ({ canOpen, ...props }) => (
  <button
    type="button"
    onMouseDown={(e) => {
      e.preventDefault();
    }}
    css={(theme: ThemeType) => [
      flexRow,
      css`
        color: ${theme.colors.brand};
        padding: 32px 40px;
        justify-content: space-between;
        align-items: center;
        font-family: ${theme.typography.families.primary};

        cursor: ${canOpen ? 'pointer' : 'default'};
      `,
    ]}
    {...props}
  />
);

export const IndicatorArrow: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <ChevronIcon
    direction={isOpen ? 'down' : 'right'}
    css={css`
      margin-right: 12px;
    `}
  />
);

export const StatusContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      position: relative;
      top: 2px;
      margin-right: 8px;
    `}
    {...props}
  />
);

export const Spacer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      flex: 1;
    `}
    {...props}
  />
);

export const Subtitle: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      color: ${theme.colors.black};
      padding-left: 12px;
    `}
    {...props}
  />
);

export const NumberSent: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      color: #6e6e6e;
      margin-left: 15px;
    `}
    {...props}
  />
);

export const PublishGroup: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      color: #6e6e6e;
      &:last-of-type {
        &::after {
          content: '';
        }
      }
      &::after {
        content: ', ';
      }
    `}
    {...props}
  />
);

export const PublishGroups: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      padding-left: 15px;
      text-align: left;
      max-width: 500px;
    `}
    {...props}
  />
);

type TPublishDateVariants = 'error' | 'inactive';
export const PublishDate: React.FC<
  { variant?: TPublishDateVariants } & { children?: React.ReactNode }
> = ({ variant, ...rest }) => {
  let color = Theme.colors.black;

  if (variant && variant === 'error') {
    color = Theme.colors['status-error'];
  } else if (variant && variant === 'inactive') {
    color = '#707070';
  }

  return (
    <span
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.small};
        color: ${color};
        justify-self: flex-end;
        white-space: pre;
      `}
      {...rest}
    />
  );
};

export const ExpirationDate: React.FC<{
  expired: boolean;
  children?: React.ReactNode;
}> = ({ expired, ...props }) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      color: ${Theme.colors.black};
      justify-self: flex-end;
      white-space: pre;
      margin-left: 30px;
    `}
  >
    <ClockIcon
      css={css`
        margin-right: 5px;
        margin-bottom: -1px;
      `}
    />
    {expired ? 'Expired' : 'Expiring in'}: {props.children}
  </span>
);

export const PreviewContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      align-content: center;
      padding: 0px 40px 32px 68px;
    `}
    {...props}
  />
);
