import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { NamedStops, NamedStopsVariables } from '../generated/NamedStops';

const NamedStopsQuery = loader('../graphql/NamedStops.gql');

interface NamedStopType {
  name: string | null;
  id: string;
}

const useNamedStops = (stopIds: string[]): NamedStopType[] => {
  const query = useQuery<NamedStops, NamedStopsVariables>(NamedStopsQuery, {
    variables: { stopIds },
  });

  const result = query?.data?.stops?.nodes ?? [];

  return stopIds.map((stopId) => {
    return {
      name: result.find((s) => s.gtfsId === stopId)?.name || null,
      id: stopId,
    };
  });
};

export default useNamedStops;
