/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { components, OptionProps, PlaceholderProps } from 'react-select';
import pluralize from 'pluralize';
import {
  TargetingOption,
  TargetingOptionType,
} from 'utils/stop-selector-helpers';

import theme from 'theme';
import { getStopType, getVehicleName } from 'utils/feed-switches';
import { BOROUGH_INLINE_LABELS } from 'utils/boroughs';
import { useFeedId } from 'contexts/FeedId';
import Checkbox, { CheckboxSize } from 'components/common/Checkbox';
import Bullet, { BulletSize } from 'components/common/Bullet';

import { flexRow } from '../styles';

interface CustomOptionProps {
  option: TargetingOption;
  description?: React.ReactNode;
  [key: string]: any;
}

const OptionText: React.FC<{
  color?: string;
  children?: React.ReactNode;
}> = ({ color = 'accent1', ...rest }) => (
  <span
    css={css`
      ${theme.typography.sizes.medium};
      color: ${theme.colors[color]};
      font-family: ${theme.typography.families.primary};
    `}
    {...rest}
  />
);

const CustomOption: React.FC<CustomOptionProps> = ({
  option,
  isSelected,
  isValue,
  color = 'accent1',
  screenCounts,
  description,
  ...props
}) => {
  const feedId = useFeedId();
  const vehicleName = getVehicleName(feedId);
  const stopOrStationText = getStopType(feedId);

  const typeOrVehicleText = (() => {
    if (
      option.borough &&
      option.optionType === TargetingOptionType.ALL_BOROUGH_OPTION
    ) {
      return `${BOROUGH_INLINE_LABELS[option.borough]} `;
    }

    if (isValue) {
      return '';
    }

    return `${vehicleName} `;
  })();

  return (
    <div
      css={[
        flexRow,
        css`
          align-items: center;
          justify-content: space-between;
          & [data-bullet-route-id] {
            margin-right: 4px;
            margin-left: 4px;
          }
        `,
      ]}
      {...props}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {!isValue && (
          <Checkbox
            css={css`
              margin-right: 8px;
            `}
            size={CheckboxSize.medium}
            checked={isSelected}
            onChange={() => {}}
          />
        )}
        {new Set([
          TargetingOptionType.ALL_BOROUGH_OPTION,
          TargetingOptionType.ALL_ROUTE_OPTION,
        ]).has(option.optionType) ? (
          <div>
            <OptionText color={color}>All </OptionText>
            {!isValue && (
              <Bullet
                size={BulletSize.xsmall}
                routeId={option.bulletValue ?? ''}
                style={{ margin: '4px 0px' }}
              />
            )}{' '}
            <OptionText color={color}>{typeOrVehicleText}</OptionText>
            <OptionText color={color}>{`${stopOrStationText}s`}</OptionText>
          </div>
        ) : (
          <OptionText color={color}>{option.label}</OptionText>
        )}
      </div>
      <div
        css={css`
          ${theme.typography.sizes.small};
          color: #707070;
        `}
      >
        {description}
      </div>
    </div>
  );
};

const StopValueContainer: React.FC<{ children?: React.ReactNode } & any> = ({
  children,
  ...props
}) => {
  const feedId = useFeedId();
  const stopOrStationText = getStopType(feedId);
  const stopOptions: readonly TargetingOption[] = props.getValue();

  const pluralizedStationsOrStops = pluralize(
    stopOrStationText,
    stopOptions.filter((o) => o.optionType === TargetingOptionType.STOP_OPTION)
      .length,
    true,
  );

  const descriptionEl =
    stopOptions.length > 1 ? (
      <div
        css={css`
          ${theme.typography.sizes.small};
          color: #707070;
        `}
      >
        {pluralizedStationsOrStops}
      </div>
    ) : null;

  const isAllOptionsSelected = stopOptions.find(
    (o) => o.optionType === TargetingOptionType.ALL_ROUTE_OPTION,
  );

  const boroughOptions = new Set(stopOptions.map((o) => o.borough));

  const isMultipleSelection =
    stopOptions.length > 1 &&
    (boroughOptions.size > 1 ||
      (boroughOptions.size === 1 &&
        !stopOptions.find(
          ({ optionType }) =>
            optionType === TargetingOptionType.ALL_BOROUGH_OPTION,
        )));

  return (
    <components.ValueContainer {...props}>
      <div
        css={css`
          display: flex;
          width: 99%;
        `}
      >
        {stopOptions.length > 0 &&
          (isMultipleSelection ? (
            <div
              css={[
                flexRow,
                css`
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                `,
              ]}
            >
              <OptionText>
                {isAllOptionsSelected
                  ? `All ${stopOrStationText}s`
                  : `Multiple ${stopOrStationText}s Selected`}
              </OptionText>
              {descriptionEl}
            </div>
          ) : (
            <CustomOption
              isValue
              option={stopOptions[0]}
              description={descriptionEl}
              css={css`
                width: 100%;
              `}
            />
          ))}
      </div>
      {children}
    </components.ValueContainer>
  );
};

export default {
  Option: (props: OptionProps<any>) => {
    // TODO: Also fix me
    const showDescription =
      (props.selectProps as any).showScreensAvailable &&
      !props.data.screensAvailable &&
      props.data.optionType === TargetingOptionType.STOP_OPTION;
    return (
      <div {...props.innerProps}>
        <CustomOption
          isSelected={props.isSelected}
          option={props.data}
          style={props.getStyles('option', props)}
          color="black"
          description={showDescription ? 'No Screens Available' : ''}
        />
      </div>
    );
  },
  Placeholder: (props: PlaceholderProps<any>) => {
    const stopOptions: readonly TargetingOption[] = props.getValue();
    return stopOptions.length ? null : <components.Placeholder {...props} />;
  },
  ValueContainer: StopValueContainer,
};
