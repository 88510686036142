/** @jsxImportSource @emotion/react */

import React, { useMemo, useState } from 'react';
import { useTable, Column } from 'react-table';
import { css } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useToast, Toast, ToastLevel } from 'ui-kit/toast';
import Checkbox from '../../common/Checkbox';
import { ReactComponent as SortIcon } from '../../../images/icon_sort.svg';
import { ReactComponent as SwitchIcon } from '../../../images/icon_switch.svg';
import {
  transformDataToTableStructure,
  getLastSeenSortIconClass,
  getNameSortIconClass,
  getIsAdminSortIconClass,
  getFeedSortIconClass,
  TableStructure,
  COL_WIDTHS,
} from './helpers';

import {
  Table,
  TextCell,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableData,
} from '../../common/table-elements';

import {
  Users_sortedUsers_UsersConnection_nodes_User_feeds_UserFeedGroupGrantsConnection_nodes_UserFeedGroupGrant as Users_sortedUsers_nodes_feeds_nodes,
  Users_sortedUsers_UsersConnection_nodes_User as Users_sortedUsers_nodes,
} from '../../../generated/Users';
import { UsersOrderBy } from '../../../generated/global-types';
import Pagination from '../../common/Pagination';
import { TStatusPillTypes } from '../../common/status-pill';
import { ThemeType } from '../../../theme';
import * as S from './index.styled';

import {
  DeleteUserFeedGroupGrant,
  DeleteUserFeedGroupGrantVariables,
} from '../../../generated/DeleteUserFeedGroupGrant';
import {
  CreateUserFeedGroupGrant,
  CreateUserFeedGroupGrantVariables,
} from '../../../generated/CreateUserFeedGroupGrant';
import {
  DeactivateUser,
  DeactivateUserVariables,
} from '../../../generated/DeactivateUser';
import {
  ToggleAdminUserAccess,
  ToggleAdminUserAccessVariables,
} from '../../../generated/ToggleAdminUserAccess';

const DeleteFeedGroupGrantMutation = loader(
  '../../../graphql/DeleteUserFeedGroupGrant.gql',
);
const CreateFeedGroupGrantMutation = loader(
  '../../../graphql/CreateUserFeedGroupGrant.gql',
);
const DeactivateUserMutation = loader('../../../graphql/DeactivateUser.gql');

const ToggleAdminUserAccessMutation = loader(
  '../../../graphql/ToggleAdminUserAccess.gql',
);

const { REACT_APP_DEPLOY_ENV } = process.env;
const OUTFRONT_FEED_GROUP_NAME = 'OUTFRONT';

const UserTable: React.FC<
  {
    onPrevClick: () => void;
    onNextClick: () => void;
    onSortClick: (sortBy?: UsersOrderBy, feed?: string, order?: string) => void;
    sort: UsersOrderBy;
    sortParam: string | null;
    dirParam: string | null;
    page?: number;
    perPage?: number;
    totalCount: number;
    search?: string;
    status?: TStatusPillTypes;
    users: Users_sortedUsers_nodes[];
    feedGroups?: string[];
    showEdit?: boolean;
    currentUser?: Users_sortedUsers_nodes | null | undefined;
  } & { children?: React.ReactNode }
> = ({
  page = 1,
  perPage = 8,
  totalCount,
  sort,
  sortParam,
  dirParam,
  onSortClick,
  onNextClick,
  onPrevClick,
  users,
  feedGroups = [],
  currentUser,
}) => {
  const { shouldShowToast, showToast, hideToast } = useToast();
  const [toastText, setToastText] = useState('');
  const [deleteUserFeedGrant] = useMutation<
    DeleteUserFeedGroupGrant,
    DeleteUserFeedGroupGrantVariables
  >(DeleteFeedGroupGrantMutation);

  const disableOutfrontAgencies = REACT_APP_DEPLOY_ENV !== 'uat';

  const [createUserFeedGrant] = useMutation<
    CreateUserFeedGroupGrant,
    CreateUserFeedGroupGrantVariables
  >(CreateFeedGroupGrantMutation, {
    onCompleted: (data) => {
      const user = users.find(
        (user) => user.id === data.createUserFeedGroupGrant?.user?.id,
      );

      if (user && user?.feeds.nodes.length === 1) {
        setToastText(`${user.name} is now reactivated`);
        showToast();
      }
    },
  });

  const [deactivateUser] = useMutation<DeactivateUser, DeactivateUserVariables>(
    DeactivateUserMutation,
    {
      onCompleted: (data) => {
        const user = users.find(
          (user) => user.id === data.deactivateUser?.user?.id,
        );
        if (user) {
          setToastText(`${user.name} is now deactivated`);
          showToast();
        }
      },
    },
  );

  const [toggleAdminUserAccess] = useMutation<
    ToggleAdminUserAccess,
    ToggleAdminUserAccessVariables
  >(ToggleAdminUserAccessMutation, {
    onCompleted: (data) => {
      const user = users.find(
        (user) => user.id === data?.toggleAdminUserAccess?.user?.id,
      );
      const updatedRoleText = data?.toggleAdminUserAccess?.user
        ?.usersAccessByUserId?.isAdmin
        ? 'admin'
        : 'author';
      if (user) {
        setToastText(`${user.name} is now an ${updatedRoleText}`);
        showToast();
      }
    },
  });

  const SortButton: React.FC<
    {
      sortOptions: UsersOrderBy[];
      currentSort: UsersOrderBy;
      onSortClick: (sort: UsersOrderBy) => void;
    } & { children?: React.ReactNode }
  > = ({ sortOptions, currentSort, onSortClick, children }) => (
    <S.SortButton
      onClick={() => {
        const currentSortIndex = sortOptions.indexOf(currentSort);
        if (currentSortIndex > -1) {
          const nextIndex =
            currentSortIndex >= sortOptions.length - 1
              ? 0
              : currentSortIndex + 1;
          onSortClick(sortOptions[nextIndex]);
        } else {
          onSortClick(sortOptions[0]);
        }
      }}
    >
      {children}
    </S.SortButton>
  );

  const data = useMemo(
    () => transformDataToTableStructure(users, feedGroups, currentUser),
    [users, feedGroups, currentUser],
  );

  const columns = useMemo(() => {
    const onRemoveUserFeedGrant = (
      feedGroupName: string,
      id: number,
      userFeedGroups: Users_sortedUsers_nodes_feeds_nodes['feedGroupName'][],
    ) => {
      deleteUserFeedGrant({
        variables: {
          feedGroupName,
          userId: id,
        },
        optimisticResponse: {
          deleteUserFeedGroupGrant: {
            __typename: 'DeleteUserFeedGroupGrantPayload',
            user: {
              __typename: 'User',
              id,
              usersAccessByUserId: {
                __typename: 'UsersAccess',
                isActive:
                  userFeedGroups.filter((fg) => fg !== feedGroupName).length >
                  0,
              },
              userFeedGroupGrants: {
                __typename: 'UserFeedGroupGrantsConnection',
                nodes: userFeedGroups
                  .filter((fg) => fg !== feedGroupName)
                  .map((fg) => ({
                    __typename: 'UserFeedGroupGrant',
                    feedGroupName: fg,
                  })),
              },
            },
          },
        },
      });
    };

    const onAddUserFeedGroupGrant = (
      feedGroupName: string,
      id: number,
      userFeedGroups: Users_sortedUsers_nodes_feeds_nodes['feedGroupName'][],
    ) => {
      createUserFeedGrant({
        variables: {
          feedGroupName,
          userId: id,
        },
        optimisticResponse: {
          createUserFeedGroupGrant: {
            __typename: 'CreateUserFeedGroupGrantPayload',
            user: {
              __typename: 'User',
              id,
              usersAccessByUserId: {
                __typename: 'UsersAccess',
                isActive: true,
              },
              userFeedGroupGrants: {
                __typename: 'UserFeedGroupGrantsConnection',
                nodes: [...userFeedGroups, feedGroupName].map((fg) => ({
                  __typename: 'UserFeedGroupGrant',
                  feedGroupName: fg,
                })),
              },
            },
          },
        },
      });
    };

    const onDeactivateUser = (id: number) => {
      deactivateUser({
        variables: {
          userId: id,
        },
        optimisticResponse: {
          deactivateUser: {
            __typename: 'DeactivateUserPayload',
            user: {
              __typename: 'User',
              id,
              usersAccessByUserId: {
                __typename: 'UsersAccess',
                isActive: false,
              },
              userFeedGroupGrants: {
                __typename: 'UserFeedGroupGrantsConnection',
                nodes: [],
              },
            },
          },
        },
      });
    };

    const onToggleAdminUserAccess = (id: number, currentlyAdmin: boolean) => {
      toggleAdminUserAccess({
        variables: {
          userId: id,
        },
        optimisticResponse: {
          toggleAdminUserAccess: {
            __typename: 'ToggleAdminUserAccessPayload',
            user: {
              __typename: 'User',
              id,
              usersAccessByUserId: {
                __typename: 'UsersAccess',
                isAdmin: !currentlyAdmin,
              },
            },
          },
        },
      });
    };

    const columnArray: Column<TableStructure>[] = [
      {
        accessor: 'info',
        Cell: ({
          cell: { value },
        }: {
          cell: {
            value: TableStructure['info'];
          };
        }) => {
          const info = (
            <div>
              {value.name}{' '}
              {value.isSelf && (
                <span
                  css={({ typography }: ThemeType) => css`
                    ${typography.sizes.xsmall};
                    border-radius: 12px;
                    background-color: #dddddd;
                    padding: 0 6px;
                  `}
                >
                  You
                </span>
              )}
            </div>
          );
          return (
            <TextCell heading={info}>
              <span
                css={({ typography }: ThemeType) => css`
                  ${typography.sizes.small};
                `}
              >
                <S.UserLink href={`mailto:${value.email}`}>
                  {value.email}
                </S.UserLink>
              </span>
            </TextCell>
          );
        },
        Header: () => {
          return (
            <div>
              Name{' '}
              <SortButton
                onSortClick={onSortClick}
                currentSort={sort}
                sortOptions={[UsersOrderBy.NAME_ASC, UsersOrderBy.NAME_DESC]}
              >
                <SortIcon className={getNameSortIconClass(sort)} />
              </SortButton>
            </div>
          );
        },
      },
      {
        accessor: 'access',
        Cell: ({
          cell: { value },
        }: {
          cell: {
            value: TableStructure['access'];
          };
        }) => {
          const isAdmin = !!value?.role?.isAdmin;
          const userId = value?.userId;
          const roleCanBeUpdated = value?.role?.isActive && !value?.isSelf;

          return (
            <div css={S.textAlignCenter}>
              <button
                title={`Switch to ${isAdmin ? 'Author' : 'Admin'}`}
                type="button"
                css={(theme: ThemeType) => css`
                  ${theme.typography.sizes.small};
                  border-radius: 12px;
                  background-color: ${isAdmin
                    ? 'rgba(0, 170, 8, 0.3)'
                    : theme.colors.light10};
                  padding: 4px 12px;
                `}
                disabled={!roleCanBeUpdated}
                onClick={() => {
                  if (userId && roleCanBeUpdated) {
                    onToggleAdminUserAccess(userId, isAdmin);
                  }
                }}
              >
                {isAdmin ? 'Admin' : 'Author'}
                {roleCanBeUpdated && (
                  <SwitchIcon
                    css={css`
                      vertical-align: top;
                      margin-left: 2px;
                    `}
                  />
                )}
              </button>
            </div>
          );
        },
        Header: () => (
          <div css={S.textAlignCenter}>
            Role{' '}
            <SortButton
              onSortClick={onSortClick}
              currentSort={sort}
              sortOptions={[
                UsersOrderBy.IS_ADMIN_ASC,
                UsersOrderBy.IS_ADMIN_DESC,
              ]}
            >
              <SortIcon className={getIsAdminSortIconClass(sort)} />
            </SortButton>
          </div>
        ),
      },
      {
        accessor: 'lastSeenAt',
        Cell: ({
          cell: { value },
        }: {
          cell: { value: TableStructure['lastSeenAt'] };
        }) => (
          <div
            css={({ typography }: ThemeType) => css`
              ${typography.sizes.small};
              text-align: center;
            `}
          >
            {value}
          </div>
        ),
        Header: () => (
          <div css={S.textAlignCenter}>
            Last Seen{' '}
            <SortButton
              onSortClick={onSortClick}
              currentSort={sort}
              sortOptions={[
                UsersOrderBy.LAST_SEEN_AT_ASC,
                UsersOrderBy.LAST_SEEN_AT_DESC,
              ]}
            >
              <SortIcon className={getLastSeenSortIconClass(sort)} />
            </SortButton>
          </div>
        ),
      },
    ];

    feedGroups.forEach((feedGroup) => {
      // We're removing Outfront agencies usage for environments other than uat
      if (feedGroup === OUTFRONT_FEED_GROUP_NAME && disableOutfrontAgencies) {
        return;
      }
      columnArray.push({
        accessor: feedGroup,
        Cell: ({ cell: { value } }: { cell: { value: any } }) => {
          return (
            <div css={S.textAlignCenter}>
              <Checkbox
                disabled={value.isDisabled}
                checked={value.isSelected}
                onChange={(checked: boolean) => {
                  if (checked) {
                    return onAddUserFeedGroupGrant(
                      feedGroup,
                      value.user.id,
                      value.userFeeds,
                    );
                  }
                  if (value.userFeeds.length === 1) {
                    return onDeactivateUser(value.user.id);
                  }
                  return onRemoveUserFeedGrant(
                    feedGroup,
                    value.user.id,
                    value.userFeeds,
                  );
                }}
              />
            </div>
          );
        },
        Header: () => {
          return (
            <div css={S.textAlignCenter}>
              {feedGroup}{' '}
              <S.SortButton
                onClick={() => {
                  const order = dirParam || 'desc';
                  const flip = order === 'desc' ? 'asc' : 'desc';
                  onSortClick(undefined, feedGroup, flip);
                }}
              >
                <SortIcon
                  className={getFeedSortIconClass(
                    feedGroup,
                    sortParam,
                    dirParam,
                  )}
                />
              </S.SortButton>
            </div>
          );
        },
      });
    });

    columnArray.push({
      accessor: 'actions',
      Cell: ({
        cell: { value },
      }: {
        cell: {
          value: TableStructure['actions'];
        };
      }) => {
        const isActive = value.user.access?.isActive;
        return (
          <div css={S.textAlignCenter}>
            {!isActive && <S.Deactivated>Deactivated</S.Deactivated>}
            {!value.isSelf && isActive && (
              <S.ShowDeactivateButton
                onClick={() => onDeactivateUser(value.user.id)}
                disabled={value.isSelf}
              >
                Deactivate
              </S.ShowDeactivateButton>
            )}
          </div>
        );
      },
    });

    return columnArray;
  }, [
    feedGroups,
    onSortClick,
    sort,
    createUserFeedGrant,
    deactivateUser,
    toggleAdminUserAccess,
    deleteUserFeedGrant,
    dirParam,
    sortParam,
    disableOutfrontAgencies,
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div
      css={css`
        max-width: 1000px;
      `}
    >
      <Toast
        shouldShow={shouldShowToast}
        onDismiss={hideToast}
        level={ToastLevel.info}
      >
        {toastText}
      </Toast>
      <Table
        css={css`
          width: 100%;
          margin-bottom: 1rem;
        `}
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <TableHeaderCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableHeaderCell>
                );
              })}
            </tr>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const hasLeftBorder = !['info'].includes(cell.column.id);

                  return (
                    <TableData
                      css={css`
                        width: ${COL_WIDTHS[cell.column.id]};
                      `}
                      hasLeftBorder={hasLeftBorder}
                      isActive={row.values.access.isActive}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        currentPage={page}
        itemsPerPage={perPage}
        totalItems={totalCount}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
      />
    </div>
  );
};

export default UserTable;
