import { Options } from 'react-select';
import {
  PlannedWorkStatus,
  MessageType,
  SearchPlannedWorksOrderBy,
} from '../generated/global-types';
import { TStatusPillTypes } from '../components/common/status-pill';

export const DURATION_NAME = 'duration';
export const MESSAGE_TYPE_NAME = 'messageType';
export const MAIN_MESSAGE_BODY_NAME = 'mainMessageBody';
export const MAIN_MESSAGE_CUSTOMIZED = 'mainMessageCustomized';
export const ADDL_MESSAGE_BODY_NAME = 'addlMessageBody';
export const ADDL_MESSAGE_CUSTOMIZED = 'addlMessageCustomized';
export const TARGETED_ROUTES_NAME = 'targetedRoutes';
export const TARGETED_STOPS_NAME = 'targetedStops';
export const PLANNED_WORK_LOCAL_SAVE_KEY = 'pw-local-save';

export const SCREEN_SIZE_PORTRAIT = {
  height: 1920,
  width: 1080,
};

export const SCREEN_SIZE_LANDSCAPE = {
  height: 1080,
  width: 1920,
};

export const SCREEN_SIZE_DUP = {
  height: 216,
  width: 1920,
};

interface BasePWOption {
  label: string;
  value: string;
}

interface PWStatusOption extends BasePWOption {
  data: {
    status: PlannedWorkStatus;
    statusPill: TStatusPillTypes;
  };
}

export type PWStatusOptions = Options<PWStatusOption>;

export const PLANNED_WORK_STATUSES: PWStatusOptions = [
  {
    value: 'cleared',
    label: 'Cleared',
    data: {
      status: PlannedWorkStatus.CLEARED,
      statusPill: TStatusPillTypes.CLEARED,
    },
  },
  {
    value: 'draft',
    label: 'Draft',
    data: {
      status: PlannedWorkStatus.DRAFT,
      statusPill: TStatusPillTypes.DRAFT,
    },
  },
  {
    value: 'live',
    label: 'Live',
    data: {
      status: PlannedWorkStatus.LIVE,
      statusPill: TStatusPillTypes.LIVE,
    },
  },
  {
    value: 'published',
    label: 'Published',
    data: {
      status: PlannedWorkStatus.PUBLISHED,
      statusPill: TStatusPillTypes.PUBLISHED,
    },
  },
  {
    value: 'removed',
    label: 'Cancelled',
    data: {
      status: PlannedWorkStatus.REMOVED,
      statusPill: TStatusPillTypes.REMOVED,
    },
  },
  {
    value: 'clearing',
    label: 'Clearing',
    data: {
      status: PlannedWorkStatus.CLEARING,
      statusPill: TStatusPillTypes.SCREENS_CLEARING,
    },
  },
];

export const ACTIVE_PLANNED_WORK_STATUSES = [
  PlannedWorkStatus.LIVE,
  PlannedWorkStatus.PUBLISHED,
];

export interface PlannedWorkCategoryOption extends BasePWOption {
  data: { category: MessageType };
}
export interface PlannedWorkLiveOption extends BasePWOption {
  data: { live: 'HOMEPAGE' | 'SCREENS' };
}

export type PWLiveOptions = Options<PlannedWorkLiveOption>;

export const PLANNED_WORK_LIVE_OPTIONS: PWLiveOptions = [
  {
    value: 'homepage',
    label: 'Homepage',
    data: { live: 'HOMEPAGE' },
  },
  {
    value: 'screens',
    label: 'Screens',
    data: { live: 'SCREENS' },
  },
];

export interface SortOptions {
  [key: string]: [SearchPlannedWorksOrderBy, SearchPlannedWorksOrderBy];
}

export const PLANNED_WORK_SORT_OPTIONS: SortOptions = {
  lines: [
    SearchPlannedWorksOrderBy.ENTITY_SELECTORS_DESC,
    SearchPlannedWorksOrderBy.ENTITY_SELECTORS_ASC,
  ],
  category: [
    SearchPlannedWorksOrderBy.MESSAGE_TYPE_DESC,
    SearchPlannedWorksOrderBy.MESSAGE_TYPE_ASC,
  ],
  status: [
    SearchPlannedWorksOrderBy.STATUS_DESC,
    SearchPlannedWorksOrderBy.STATUS_ASC,
  ],
  end: [SearchPlannedWorksOrderBy.END_DESC, SearchPlannedWorksOrderBy.END_ASC],
  start: [
    SearchPlannedWorksOrderBy.START_DESC,
    SearchPlannedWorksOrderBy.START_ASC,
  ],
  default: [
    SearchPlannedWorksOrderBy.LAST_MODIFIED_AT_DESC,
    SearchPlannedWorksOrderBy.LAST_MODIFIED_AT_ASC,
  ],
};
