/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';

import Modal from './modal';
import Button from './Button';

const AssistanceMessage: React.FC<
  unknown & { children?: React.ReactNode }
> = () => (
  <React.Fragment>
    For assistance, call MTA IT at{' '}
    <strong>
      <a
        css={css`
          color: black;
        `}
        href="tel:+16462528888,,6,1"
      >
        646-252-8888
      </a>
    </strong>
    , select <strong>Option 6</strong>, then <strong>Option 1</strong>.
  </React.Fragment>
);

const ErrorModal: React.FC<
  {
    isOpen: boolean;
    title: string;
    message: React.ReactNode;
    dismissText?: string;
    onDismiss: () => void;
    includeAssistanceMessage?: boolean;
  } & { children?: React.ReactNode }
> = ({
  isOpen,
  title,
  message,
  dismissText = 'Go Back',
  onDismiss,
  includeAssistanceMessage = true,
}) => (
  <Modal
    isOpen={isOpen}
    title={
      <span
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {title}
      </span>
    }
    onDismiss={onDismiss}
  >
    {message && <React.Fragment>{message} </React.Fragment>}
    {includeAssistanceMessage && <AssistanceMessage />}
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
      `}
    >
      <Button
        type="button"
        css={css`
          height: 44px;
          width: 172px;
          line-height: 0px;
        `}
        onClick={onDismiss}
        primary
      >
        {dismissText}
      </Button>
    </div>
  </Modal>
);

export default ErrorModal;
