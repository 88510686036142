/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';

import React, { useEffect, useState } from 'react';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';
import dateFormat from 'date-fns/format';
import 'react-datepicker/dist/react-datepicker.css';

import InputLabelBorder from './InputLabelBorder';
import { input as inputCss, filterInput } from '../styles';
import theme from '../../../theme';

import { ReactComponent as LeftArrowIcon } from '../../../images/datepicker-left-arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../../images/datepicker-right-arrow.svg';

const reactDatepickerCssOverrides = () => css`
  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__header {
    background-color: ${theme.colors.white};
    border: none;
    padding-top: 0px;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 8px;
  }

  .react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 8px;
  }

  .react-datepicker__day-name {
    color: #9d9d9d;
    margin: ${theme.spacing.xxsmall};
  }

  .react-datepicker__month {
    margin: 0px;
  }

  .react-datepicker__day {
    color: ${theme.colors.dark2};
    margin: ${theme.spacing.xxsmall};
    border-radius: 0px;

    &:hover {
      background-color: rgba(33, 150, 243, 0.2);
    }

    &--disabled {
      opacity: 0.2;

      &:hover {
        background-color: white;
      }
    }

    &--selected,
    &--keyboard-selected {
      outline: none;
      background-color: ${theme.colors.accent4};
      color: ${theme.colors.white};

      &:hover,
      &:focus {
        background-color: ${theme.colors.brand};
      }
    }

    &--today {
      font-weight: ${theme.typography.weights.normal};
    }

    &.react-datepicker__day--outside-month {
      color: rgb(74, 74, 74, 0.2);

      &:hover {
        color: ${theme.colors.dark2};
      }

      &--disabled {
        &:hover {
          color: rgb(74, 74, 74, 0.2);
        }
      }
    }
  }
`;

export const DatepickerContainer: React.FC<
  {
    className: string;
    children: React.ReactNode[];
  } & { children?: React.ReactNode }
> = ({ className, children }) => {
  return (
    <div
      className={className}
      css={css`
        ${theme.typography.sizes.medium};
        padding: ${theme.spacing.medium};
        background: ${theme.colors.white};
        border-color: ${theme.colors.white};
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        font-family: ${theme.typography.families.primary};
        color: ${theme.colors.dark2};

        &.react-datepicker--time-only {
          padding: ${theme.spacing.xsmall};
        }
      `}
    >
      {children}
    </div>
  );
};

const headerButtonCss = css`
  display: flex;
  align-items: center;
  height: 30px;
  &:hover {
    opacity: 0.5;
  }
  outline: none;
  border-radius: 4px;
  &:focus-visible {
    box-shadow: 0 0 0 1px ${theme.colors.accent3};
  }
`;

export const DatepickerHeader: React.FC<
  {
    date: Date;
    changeYear(year: number): void;
    changeMonth(month: number): void;
    decreaseMonth(): void;
    increaseMonth(): void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  } & { children?: React.ReactNode }
> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const monthAndYear = dateFormat(date, 'MMMM yyyy');

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        padding: ${theme.spacing.xsmall};
        padding-top: 0px;
      `}
    >
      <div
        css={css`
          ${theme.typography.sizes.large};
          font-weight: ${theme.typography.weights.bold};
        `}
      >
        {monthAndYear}
      </div>
      <div
        css={css`
          padding-left: ${theme.spacing.xsmall};
          display: flex;
          flex-direction: row;
          outline: none;
        `}
      >
        <button
          type="button"
          title="Previous month"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          css={[
            headerButtonCss,
            css`
              opacity: ${prevMonthButtonDisabled ? '0.2' : '1'};
            `,
          ]}
        >
          <LeftArrowIcon aria-hidden="true" />
        </button>
        <button
          css={[
            headerButtonCss,
            css`
              opacity: ${nextMonthButtonDisabled ? '0.2' : '1'};
              margin-left: 12px;
            `,
          ]}
          type="button"
          title="Next month"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <RightArrowIcon aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

interface IDatepickerInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'label'> {
  label: React.ReactNode;
  separateLabel: boolean;
  invalid?: boolean;
  onClick?: () => void;
  value?: string;
  additionalInputCss?: SerializedStyles;
}

// react-datepicker provides the value/onClick
const DatepickerInput = React.forwardRef<
  HTMLInputElement,
  IDatepickerInputProps
>(
  (
    {
      label,
      invalid,
      value,
      onClick,
      additionalInputCss,
      separateLabel,
      ...rest
    },
    ref,
  ) => {
    const [separateLabelId, setSeparateLabelId] = useState('datepicker-input');

    useEffect(() => {
      setSeparateLabelId(
        `datepicker-input-${Math.floor(Math.random() * 10000)}`,
      );
    }, []);

    const input = (
      <input
        type="text"
        css={css`
          ${inputCss};
          ${separateLabel ? filterInput : null}
          ${theme.typography.sizes.small};
          ${additionalInputCss};
          color: ${theme.colors.black};
          &:placeholder {
            opacity: 1;
          }
        `}
        value={value}
        onClick={onClick}
        {...rest}
        ref={ref}
        id={separateLabel ? separateLabelId : undefined}
      />
    );

    if (label) {
      if (separateLabel) {
        return (
          <React.Fragment>
            <label
              css={css`
                ${theme.typography.sizes.small};
                font-weight: ${theme.typography.weights.bold};
                display: inline-block;
                margin-bottom: 8px;
              `}
              htmlFor={separateLabelId}
            >
              {label}
            </label>
            {input}
          </React.Fragment>
        );
      }

      return (
        <InputLabelBorder label={label} invalid={invalid}>
          {input}
        </InputLabelBorder>
      );
    }

    return input;
  },
);

const Datepicker: React.FC<
  {
    label?: React.ReactNode;
    className?: string;
    invalid?: boolean;
    separateLabel?: boolean;
    inputCss?: SerializedStyles;
  } & Omit<
    ReactDatePickerProps,
    | 'showPopperArrow'
    | 'calendarContainer'
    | 'customInput'
    | 'renderCustomHeader'
  >
> & { children?: React.ReactNode } = ({
  label,
  invalid,
  className,
  separateLabel = false,
  inputCss,
  ...rest
}) => {
  return (
    <div className={className} css={reactDatepickerCssOverrides}>
      <ReactDatepicker
        {...rest}
        showPopperArrow={false}
        calendarContainer={DatepickerContainer}
        customInput={
          <DatepickerInput
            label={label}
            invalid={invalid}
            separateLabel={separateLabel}
            additionalInputCss={inputCss}
          />
        }
        renderCustomHeader={DatepickerHeader}
      />
    </div>
  );
};

export default Datepicker;
