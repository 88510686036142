/** @jsxImportSource @emotion/react */

import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { Menu, MenuList, MenuItem, MenuButton } from '@reach/menu-button';
import { useHistory } from 'react-router-dom';

import { useFeedId } from 'contexts/FeedId';
import { ButtonList } from 'components/scaffolding/PageHeading';
import Button from 'components/common/Button';
import { FeatureFlagName, PlannedWorkStatus } from 'generated/global-types';
import { ReactComponent as IconEllipsis } from 'images/icon_ellipsis.svg';
import { ThemeType } from 'theme';

import {
  PublishPlannedWork,
  PublishPlannedWorkVariables,
} from 'generated/PublishPlannedWork';
import {
  RemovePlannedWork,
  RemovePlannedWorkVariables,
} from 'generated/RemovePlannedWork';
import {
  ReactivatePlannedWork,
  ReactivatePlannedWorkVariables,
} from 'generated/ReactivatePlannedWork';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { PotentiallyUnboundedDuration } from '@mta-live-media-manager/shared';

const PublishPlannedWorkMutation = loader(
  '../../../graphql/PublishPlannedWork.gql',
);
const RemovePlannedWorkMutation = loader(
  '../../../graphql/RemovePlannedWork.gql',
);
const ReactivatePlannedWorkMutation = loader(
  '../../../graphql/ReactivatePlannedWork.gql',
);

const usePlannedWorkMutations = (plannedWorkId: number) => {
  const [onPublish] = useMutation<
    PublishPlannedWork,
    PublishPlannedWorkVariables
  >(PublishPlannedWorkMutation, {
    variables: { plannedWorkId },
  });
  const [onRemove] = useMutation<RemovePlannedWork, RemovePlannedWorkVariables>(
    RemovePlannedWorkMutation,
    {
      variables: { plannedWorkId },
    },
  );
  const [onReactivate] = useMutation<
    ReactivatePlannedWork,
    ReactivatePlannedWorkVariables
  >(ReactivatePlannedWorkMutation, {
    variables: { plannedWorkId },
  });

  return { onPublish, onRemove, onReactivate };
};

interface PlannedWorkActionsProps {
  plannedWorkId: number;
  status: PlannedWorkStatus;
  canBeReactivated: boolean;
  onClear: () => void;
  durations: PotentiallyUnboundedDuration[];
  canReclearScreens: boolean;
  onReclearScreens: () => void;
}

type Action = {
  label: string;
  onSelect: () => void;
  loading?: boolean;
};

const a = (
  label: string,
  onSelect: () => void,
  loading: boolean = false,
): Action => ({
  label,
  onSelect,
  loading,
});

type EncodedActions = (Action | Action[])[];

enum PlannedWorkActionLabels {
  PUBLISH = 'Publish',
  CLEAR = 'Clear',
  DUPLICATE = 'Duplicate',
  EDIT = 'Edit',
  CANCEL_ALERT = 'Cancel Alert',
  REACTIVATE = 'Reactivate',
  RECLEAR_SCREENS = 'Reclear Screens',
}

const useActions = ({
  onClear,
  status,
  canBeReactivated,
  plannedWorkId,
  canReclearScreens,
  onReclearScreens,
}: PlannedWorkActionsProps): EncodedActions => {
  const feedId = useFeedId();
  const history = useHistory();
  const { onPublish, onRemove, onReactivate } =
    usePlannedWorkMutations(plannedWorkId);
  const shouldNotShowPublishButton = useFeatureFlag(
    FeatureFlagName.PLANNED_WORK_DISABLE_PUBLISH_BUTTON,
  );
  const [loading, setLoading] = React.useState(false);

  // Action definitions
  const clear = a(PlannedWorkActionLabels.CLEAR, onClear);
  const duplicate = a(PlannedWorkActionLabels.DUPLICATE, () => {
    history.push({
      pathname: `/${feedId}/planned-work/compose`,
      search: `duplicate=${plannedWorkId}`,
    });
  });
  const edit = a(PlannedWorkActionLabels.EDIT, () => {
    history.push(`/${feedId}/planned-work/${plannedWorkId}/edit`);
  });
  const publish = a(
    PlannedWorkActionLabels.PUBLISH,
    async () => {
      setLoading(true);
      await onPublish();
      setLoading(false);
    },
    shouldNotShowPublishButton,
  );
  const remove = a(PlannedWorkActionLabels.CANCEL_ALERT, async () => {
    setLoading(true);
    await onRemove();
    setLoading(false);
  });
  const reactivate = a(PlannedWorkActionLabels.REACTIVATE, async () => {
    setLoading(true);
    await onReactivate();
    setLoading(false);
  });
  const reclearScreens = a(
    PlannedWorkActionLabels.RECLEAR_SCREENS,
    onReclearScreens,
  );

  const currentStatus = loading ? PlannedWorkStatus.LOADING : status;

  const clearedAndRemovedActions = canBeReactivated
    ? [reactivate, duplicate]
    : [duplicate];

  const loadingAction = a(PlannedWorkStatus.LOADING, () => {}, true);

  return {
    [PlannedWorkStatus.CLEARED]: clearedAndRemovedActions,
    [PlannedWorkStatus.DRAFT]: [publish, [edit, duplicate, remove]],
    [PlannedWorkStatus.LIVE]: [
      clear,
      [edit, duplicate, ...(canReclearScreens ? [reclearScreens] : [])],
    ],
    [PlannedWorkStatus.PUBLISHED]: [
      remove,
      [edit, duplicate, ...(canReclearScreens ? [reclearScreens] : [])],
    ],
    [PlannedWorkStatus.REMOVED]: clearedAndRemovedActions,
    [PlannedWorkStatus.LOADING]: [loadingAction],
    [PlannedWorkStatus.CLEARING]: [
      duplicate,
      ...(canReclearScreens ? [[reclearScreens]] : []),
    ],
    [PlannedWorkStatus.UNKNOWN]: [],
  }[currentStatus];
};

const PlannedWorkActions: React.FC<PlannedWorkActionsProps> = ({
  plannedWorkId,
  onClear,
  canBeReactivated,
  status,
  durations,
  canReclearScreens,
  onReclearScreens,
}) => {
  const actions = useActions({
    plannedWorkId,
    onClear,
    canBeReactivated,
    status,
    durations,
    canReclearScreens,
    onReclearScreens,
  });

  return (
    <ButtonList
      css={css`
        display: flex;
        flex-direction: row-reverse;
      `}
    >
      {actions.map((action, idx) => {
        if (Array.isArray(action)) {
          return (
            <Menu key={action.map((a) => a.label).join(',')}>
              <MenuButton
                css={css`
                  width: 56px;
                  height: 51px;
                `}
              >
                <Button
                  as="div"
                  css={css`
                    height: 100%;
                    width: 100%;
                    min-width: 0;
                    padding: 0;
                  `}
                  title="More options"
                >
                  <IconEllipsis aria-hidden />
                </Button>
              </MenuButton>
              <MenuList
                css={css`
                  margin: 10px 0 0 0;
                  padding: 0;
                  list-style: none;
                  border: 1px solid #aaaaaa;
                  border-radius: 4px;
                  background-color: #ffffff;
                  outline-color: #aaaaaa;
                  box-shadow:
                    inset 0 0 4px 0 #aaaaaa,
                    0 1px 3px 0 rgba(0, 0, 0, 0.2),
                    0 1px 1px 0 rgba(0, 0, 0, 0.14);
                  &[data-reach-menu-list] {
                    position: relative;
                    right: ${status === PlannedWorkStatus.DRAFT
                      ? '70px'
                      : '50px'};
                    ${canReclearScreens && 'right: 98px;'}
                  }
                `}
              >
                {action.map(({ label, onSelect }) => (
                  <MenuItem
                    key={label}
                    onSelect={onSelect}
                    css={(theme: ThemeType) => css`
                      padding: 10px 19px;
                      margin: 4px 0;
                      cursor: pointer;
                      &[data-selected] {
                        background-color: ${theme.colors[
                          'select-option-hover'
                        ]};
                      }
                    `}
                  >
                    {label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          );
        }
        return (
          <Fragment key={action.label}>
            <Button
              key={action.label}
              primary={idx === 0}
              type="button"
              disabled={action.loading}
              css={css`
                margin-left: 12px;
              `}
              onClick={() => {
                action.onSelect();
              }}
            >
              {action.label}
            </Button>
          </Fragment>
        );
      })}
    </ButtonList>
  );
};

export default PlannedWorkActions;
