/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

import {
  CreateScreenLock,
  CreateScreenLockVariables,
} from 'generated/CreateScreenLock';
import {
  UpdateScreenLock,
  UpdateScreenLockVariables,
} from 'generated/UpdateScreenLock';
import {
  ClearScreenLock,
  ClearScreenLockVariables,
} from 'generated/ClearScreenLock';

import { ThemeType } from '../../../../theme';
import { ReactComponent as EditIcon } from '../../../../images/icon_edit_sm.svg';

import ScreenLockToggle from './ScreenLockToggle';
import LockableContentModal from './LockableContentModal';
import ConfirmUnlockModal from './ConfirmUnlockModal';
import { Screen_screen2_Screens2_currentScreenLock_ScreenLock } from 'generated/Screen';

const CreateScreenLockMutation = loader(
  '../../../../graphql/CreateScreenLock.gql',
);

const UpdateScreenLockMutation = loader(
  '../../../../graphql/UpdateScreenLock.gql',
);

const ClearScreenLockMutation = loader(
  '../../../../graphql/ClearScreenLock.gql',
);

const useCreateScreenLock = () => {
  const [mutate, { loading: isCreateScreenLockLoading }] = useMutation<
    CreateScreenLock,
    CreateScreenLockVariables
  >(CreateScreenLockMutation);
  const createScreenLock = React.useCallback(
    (variables: { screenName: string; uploadId: string }) =>
      mutate({ variables }),
    [mutate],
  );

  return { createScreenLock, isCreateScreenLockLoading };
};

const useUpdateScreenLock = () => {
  const [mutate, { loading: isUpdateScreenLockLoading }] = useMutation<
    UpdateScreenLock,
    UpdateScreenLockVariables
  >(UpdateScreenLockMutation);
  const updateScreenLock = React.useCallback(
    (variables: { screenName: string; uploadId: string }) =>
      mutate({ variables }),
    [mutate],
  );

  return { updateScreenLock, isUpdateScreenLockLoading };
};

const useClearScreenLock = (screenName: string) => {
  const [clearScreenLock] = useMutation<
    ClearScreenLock,
    ClearScreenLockVariables
  >(ClearScreenLockMutation, { variables: { screenName } });

  return clearScreenLock;
};

interface ScreenLockingProps {
  screenName: string;
  currentScreenLock?: Screen_screen2_Screens2_currentScreenLock_ScreenLock | null;
  needsConfirmationBeforeUnlocking: boolean;
  updateContent?: boolean;
  allowZipfiles?: boolean;
  screenDimensions: {
    width: number;
    height: number;
  };
}

const ScreenLocking: React.FC<ScreenLockingProps> = ({
  screenName,
  currentScreenLock,
  needsConfirmationBeforeUnlocking,
  updateContent,
  allowZipfiles = true,
  screenDimensions,
}) => {
  const checked = Boolean(currentScreenLock);

  const { createScreenLock, isCreateScreenLockLoading } = useCreateScreenLock();
  const { updateScreenLock, isUpdateScreenLockLoading } = useUpdateScreenLock();
  const clearScreenLock = useClearScreenLock(screenName);

  const [currentlyOpenModal, setCurrentlyOpenModal] = React.useState<
    'lockable-content' | 'confirm' | null
  >(null);

  const closeModal = React.useCallback(
    () => setCurrentlyOpenModal(null),
    [setCurrentlyOpenModal],
  );
  const openLockableContentModal = React.useCallback(
    () => setCurrentlyOpenModal('lockable-content'),
    [setCurrentlyOpenModal],
  );
  const openConfirmModal = React.useCallback(
    () => setCurrentlyOpenModal('confirm'),
    [setCurrentlyOpenModal],
  );

  const handleToggleChange = React.useCallback(
    (c) => {
      if (c) {
        openLockableContentModal();
      } else if (needsConfirmationBeforeUnlocking) {
        openConfirmModal();
      } else {
        clearScreenLock();
      }
    },
    [
      openLockableContentModal,
      needsConfirmationBeforeUnlocking,
      openConfirmModal,
      clearScreenLock,
    ],
  );

  const handleSelectUpload = React.useCallback(
    async (uploadId: string) => {
      await createScreenLock({ screenName, uploadId });
      closeModal();
    },
    [screenName, createScreenLock, closeModal],
  );

  const handleSelectUploadOnUpdate = React.useCallback(
    async (uploadId: string) => {
      await updateScreenLock({ screenName, uploadId });
      closeModal();
    },
    [screenName, updateScreenLock, closeModal],
  );

  const handleConfirmUnlock = React.useCallback(() => {
    clearScreenLock();
    closeModal();
  }, [clearScreenLock, closeModal]);

  return (
    <React.Fragment>
      {updateContent ? (
        <button
          type="button"
          onClick={(evt) => {
            evt.stopPropagation();
            openLockableContentModal();
          }}
          css={css`
            text-align: left;
            margin-top: 4px;
          `}
        >
          <EditIcon
            css={(theme: ThemeType) => css`
              color: ${theme.colors.accent1};
            `}
          />
          <span
            css={(theme: ThemeType) => css`
              ${theme.typography.sizes.xsmall};
              font-weight: ${theme.typography.weights.bolder};
              color: ${theme.colors.accent1};
              text-transform: uppercase;
              margin-left: 5px;
            `}
          >
            Replace File
          </span>
        </button>
      ) : (
        <ScreenLockToggle checked={checked} onChange={handleToggleChange} />
      )}

      {currentlyOpenModal === 'lockable-content' && (
        <LockableContentModal
          isConfirmDisabled={
            isUpdateScreenLockLoading || isCreateScreenLockLoading
          }
          onSelectUpload={({ id: uploadId }) => {
            if (updateContent) {
              handleSelectUploadOnUpdate(uploadId);
              return;
            }
            handleSelectUpload(uploadId);
          }}
          onDismiss={closeModal}
          allowZipfiles={allowZipfiles}
          allowedDimensions={screenDimensions}
        />
      )}
      {currentlyOpenModal === 'confirm' && (
        <ConfirmUnlockModal
          onConfirm={handleConfirmUnlock}
          onDismiss={closeModal}
        />
      )}
    </React.Fragment>
  );
};

export default ScreenLocking;
