/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import theme from 'theme';

export const buttons = css`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, 200px);
`;

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 160px;
  width: 200px;
  background-color: white;
  padding: 24px 0 0;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  transform: box-shadow 100ms ease;
  outline: none; // conflicts with selected state

  &:hover,
  &:focus {
    box-shadow:
      0 1px 8px 0 rgba(0, 0, 0, 0.2),
      0 1px 2px 0 rgba(0, 0, 0, 0.14);
  }

  &:hover:not(:focus) {
    border: 1px solid ${theme.colors.accent3};
  }

  &:disabled {
    opacity: 0.8;
  }
`;

export const containerSelected = css`
  border: 2px solid #0f61a9;
`;

export const containerDisabled = css`
  opacity: 50%;
`;

export const icon = css`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
`;

export const description = css`
  display: block;
  text-transform: uppercase;
  color: #0f61a9;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  padding: 8px 0 4px;
  margin: 0;
`;

export const orientation = css`
  display: block;
  color: black;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`;

export const availableCount = css`
  display: block;
  color: #707070;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`;

export const messageWrapper = css`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const loadingContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
`;
