/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useEffect, useRef, useState } from 'react';
import Penpal, {
  IConnectionObject,
  ConnectionMethods,
  AsyncMethodReturns,
} from 'penpal';
import { sortedRoutes } from '@mta-live-media-manager/shared';

import { Feed } from '../../types/feeds';
import { useFeedId } from '../../contexts/FeedId';

import { ThemeType } from '../../theme';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as RoutesByFeed_routes_nodes } from '../../generated/RoutesByFeed';

// Same as in the screen app
// todo: move these to a shared location after we remove TAv1 and the API dependancies in the screen app
interface AlertScreenContentProps {
  feedId: Feed['id'];
  title: string;
  content: string;
  additionalContent: string;
  messageType: string;
  affectedRoutes: RoutesByFeed_routes_nodes[];
  allRoutesAffected: boolean;
  publishAt: string;
  isLandscape: boolean;
  isDup: boolean;
  isUpdate: boolean;
}

interface ScreenPreviewMethods {
  renderAlert: (value: AlertScreenContentProps) => void;
}

const fillParentCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: rgb(248, 248, 248);
`;

const { REACT_APP_SCREEN_APP_URL } = process.env;

const ScreenPreview: React.FC<
  {
    additionalContent?: string;
    allRoutesAffected?: boolean;
    content?: string;
    className?: string;
    messageType?: string;
    affectedRoutes?: RoutesByFeed_routes_nodes[];
    publishAt?: string;
    isLandscape?: boolean;
    isDup?: boolean;
    isUpdate?: boolean;
    title?: string;
  } & { children?: React.ReactNode }
> = ({
  className,
  publishAt = new Date().toString(),
  isLandscape = false,
  isDup = false,
  isUpdate = false,
  messageType = '',
  title = '',
  content = '',
  additionalContent = '',
  affectedRoutes = [],
  allRoutesAffected = false,
}) => {
  const sortedAffectedRoutes = sortedRoutes(affectedRoutes);
  const feedId = useFeedId();
  const [error, setError] = useState<false | string>(false);
  const [penpalChild, setPenpalChild] = useState<null | AsyncMethodReturns<
    ConnectionMethods<{}> & ScreenPreviewMethods,
    'renderAlert'
  >>(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const currentIframe = containerRef.current;
    let connection: null | IConnectionObject<
      ConnectionMethods & ScreenPreviewMethods
    > = null;
    let componentMounted = true;
    if (currentIframe && REACT_APP_SCREEN_APP_URL) {
      connection = Penpal.connectToChild({
        url: REACT_APP_SCREEN_APP_URL,
        appendTo: currentIframe,
      });

      connection.promise
        .then((child) => componentMounted && setPenpalChild(child))
        .catch(
          () =>
            componentMounted &&
            setError(
              'Sorry, we encountered an issue with the screen preview. Please refresh to try again.',
            ),
        );
    }

    return () => {
      componentMounted = false;
      if (connection) {
        connection.destroy();
      }
    };
  }, [containerRef]);

  useEffect(() => {
    // Here we are calling our "renderAlert" function in the screen app via postmessage
    if (penpalChild && feedId) {
      penpalChild.renderAlert({
        feedId,
        messageType,
        title,
        content,
        additionalContent,
        isLandscape,
        isUpdate,
        isDup,
        publishAt,
        allRoutesAffected,
        affectedRoutes: sortedAffectedRoutes,
      });
    }
  }, [
    messageType,
    feedId,
    penpalChild,
    title,
    content,
    additionalContent,
    sortedAffectedRoutes,
    allRoutesAffected,
    isLandscape,
    isUpdate,
    isDup,
    publishAt,
  ]);

  return (
    <div
      className={className}
      css={css`
        width: 100%;
        position: relative;
        height: auto;
        background-color: #f8f8f8;
        border: 1px solid #ddd;

        &::before {
          content: ' ';
          display: block;
          padding-bottom: ${isLandscape ? 56.25 : 177.77777778}%;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 0;
          width: 100%;
          height: 100%;
        }

        :hover .quick-view {
          display: block !important;
        }
      `}
    >
      {error && (
        <div
          css={[
            fillParentCSS,
            css`
              background-color: #f8f8f8;
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;
              padding: 1rem;
              z-index: 2;
            `,
          ]}
        >
          <p
            css={(theme: ThemeType) => css`
              color: ${theme.colors.dark2};
              text-align: center;
            `}
          >
            {error}
          </p>
        </div>
      )}
      <div css={fillParentCSS} ref={containerRef} />
    </div>
  );
};

export default ScreenPreview;
