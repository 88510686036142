/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import BaseSearchInput, {
  ISearchInputProps,
} from '../form-elements/search-input';
import { ThemeType } from '../../../theme';

export const PageContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <section
    css={css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `}
    {...props}
  />
);

export const BodyContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: column;
      margin: ${theme.spacing.large};
    `}
    {...props}
  />
);

export const BodyRow: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
    `}
    {...props}
  />
);

export const BodyColumn: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <div {...props} />;

export const BodyRowTitle: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <BodyColumn
    css={css`
      margin-right: 20px;
      width: 85px;
    `}
    {...props}
  />
);

export const BodyColumnFill: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <BodyColumn
    css={css`
      flex: 1;
    `}
    {...props}
  />
);

export const RowTitleText: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      color: ${theme.colors.dark2};
      font-family: ${theme.typography.families.primary};
      font-weight: ${theme.typography.weights.bold};
      text-transform: uppercase;
    `}
    {...props}
  />
);

export const SearchInput: React.FC<ISearchInputProps> = (props) => (
  <BaseSearchInput css={() => css``} {...props} />
);

export const SelectContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={() => css`
      flex: 0 1 100%;
    `}
    {...props}
  />
);

export const clearButtonContainer = () => css`
  flex: 0 1 64px;
`;

export const clearButton = () => css`
  padding: 12px 8px;
  min-width: 100%;
`;
