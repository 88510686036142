/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  ChangeEvent,
  FormEvent,
} from 'react';
import Button from 'components/common/Button';
import { useAttrs, useChainedCommands } from '@remirror/react';
import theme from 'theme';

const HyperlinkEditor: React.FC<{
  enabled: boolean;
  from: number;
  to: number;
  onSubmit: () => void;
}> = ({ enabled, from, to, onSubmit }) => {
  const ref = useRef<HTMLInputElement>(null);
  const chain = useChainedCommands();
  const attrs = useAttrs();

  const [title, setTitle] = useState('');
  const onTitleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
    },
    [setTitle],
  );

  const [h, setHref] = useState('');
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setHref(e.target.value);
    },
    [setHref],
  );

  useEffect(() => {
    if (enabled) {
      const link = attrs.link();
      setTitle((link?.title as string) || '');
      setHref((link?.href as string) || '');
    } else {
      setHref('');
      setTitle('');
    }
  }, [enabled]);

  const onRemove = useCallback(
    (e) => {
      e.preventDefault();
      setTitle('');
      setHref('');
      chain.removeLink().focus().run();
      onSubmit();
    },
    [setTitle, setHref],
  );

  const submitHref = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!h) {
        chain.removeLink();
      } else {
        chain.updateLink(
          {
            target: '_blank',
            href: h,
            title,
            auto: true,
          },
          { from, to },
        );
      }

      setHref('');
      setTitle('');

      chain.focus().run();
      onSubmit();
    },
    [h, title],
  );

  return (
    // @ts-ignore
    <dialog open={enabled} id="dlg-link" onClose={submitHref}>
      <form
        onSubmit={submitHref}
        method="dialog"
        id="link-pop"
        css={css`
          width: 320px;
          height: 190px;
          padding-left: 20px;
          padding-top: 20px;
          background: ${theme.colors.white};
          border: 1px solid ${theme.colors.border};
          border-radius: 4px;
          box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 0.5);
          overflow: hidden;
        `}
      >
        <input
          ref={ref}
          css={css`
            padding: 10px 20px;
            border: 1px solid ${theme.colors.border};
            border-radius: 5px;
            width: 278px;
            margin-bottom: 20px;
          `}
          placeholder="Link target"
          maxLength={300}
          type="url"
          onChange={onChange}
          value={h}
        />
        <input
          css={css`
            padding: 10px 20px;
            border: 1px solid ${theme.colors.border};
            border-radius: 5px;
            width: 278px;
            margin-bottom: 20px;
          `}
          onChange={onTitleChange}
          placeholder="Title (optional)"
          maxLength={300}
          value={title}
          type="text"
        />
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            css={css`
              margin-right: 8px;
            `}
            type="button"
            formMethod="dialog"
            size="small"
            onClick={onRemove}
          >
            Remove
          </Button>
          <Button
            css={css`
              margin-right: 20px;
            `}
            type="submit"
            size="small"
            primary
          >
            Apply
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default HyperlinkEditor;
