import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { sortedRoutes } from '@mta-live-media-manager/shared';
import {
  RoutesWithStops,
  RoutesWithStopsVariables,
  RoutesWithStops_routes_RoutesConnection_nodes_Route as RoutesWithStops_routes_nodes,
} from '../generated/RoutesWithStops';

const RoutesWithStopsQuery = loader('../graphql/RoutesWithStops.gql');

const useRoutesWithStops = (feedId: string): RoutesWithStops_routes_nodes[] => {
  const query = useQuery<RoutesWithStops, RoutesWithStopsVariables>(
    RoutesWithStopsQuery,
    {
      variables: { feedId },
    },
  );

  return sortedRoutes(query?.data?.routes?.nodes.filter(Boolean) || []);
};

export default useRoutesWithStops;
