/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { DetailedHTMLProps, FieldsetHTMLAttributes } from 'react';

import { ThemeType } from '../../../theme';

export const Fieldset: React.FC<
  {
    invalid?: boolean;
  } & DetailedHTMLProps<
    FieldsetHTMLAttributes<HTMLFieldSetElement>,
    HTMLFieldSetElement
  >
> & { children?: React.ReactNode } = ({ invalid, ...rest }) => (
  <fieldset
    css={(theme: ThemeType) => css`
      top: -5px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      padding-left: 8px;
      position: absolute;
      border-style: solid;
      pointer-events: none;
      border-color: ${invalid
        ? theme.colors['status-error']
        : theme.colors.dark3};
      border-width: ${invalid ? '2px' : '1px'};
      border-radius: 4px;
    `}
    {...rest}
  />
);

export const Legend: React.FC<{
  width?: number;
  children?: React.ReactNode;
}> = ({ width, ...props }) => (
  <legend
    css={css`
      padding: 0;
      text-align: left;
      transition: width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      line-height: 11px;
      width: ${width}px;
    `}
    {...props}
  />
);

export const MaxLength: React.FC<{
  hasError?: boolean;
  children?: React.ReactNode;
}> = ({ hasError, ...props }) => (
  <div
    css={(theme: ThemeType) => [
      theme.typography.sizes.xsmall,
      css`
        display: flex;
        font-family: ${theme.typography.families.primary};
        color: ${hasError ? theme.colors['status-error'] : theme.colors.light3};
        padding-top: ${theme.spacing.xxsmall};
        justify-content: flex-end;
      `,
    ]}
    {...props}
  />
);
