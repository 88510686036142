/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useFeedId } from 'contexts/FeedId';
import { useParams, useHistory } from 'react-router-dom';
import PageMeta from 'components/common/PageMeta';
import Button from 'components/common/Button';
import AssetsView from 'components/common/social-media-assets/Assets';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import formatDate from 'date-fns/format';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import useQueryParams from 'hooks/useQueryParams';
import * as Sentry from '@sentry/browser';

import { Toast, useToast, ToastLevel } from 'ui-kit/toast';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { DATE_FORMAT } from 'constants/time';

import { GetFolderById, GetFolderByIdVariables } from 'generated/GetFolderById';
import { DeleteFolder, DeleteFolderVariables } from 'generated/DeleteFolder';
import { UpdateFolder, UpdateFolderVariables } from 'generated/UpdateFolder';
import { SearchableSocialMediaUploads_searchSocialMediaUploads_SearchSocialMediaUploadsResult_results_Upload as SocialMediaUploadType } from 'generated/SearchableSocialMediaUploads';

import NoMessages from '../../common/NoMessages';
import * as Well from '../../common/well';
import Loader from '../../common/skeletons/PageWithContent';
import Modal from '../../common/modal';
import Pagination from '../../common/Pagination';

import {
  WEB_NO_ASSETS_HEADING,
  WEB_NO_ASSETS_MESSAGE_IN_FOLDER,
} from '../../../constants/empty-states';

import * as S from '../asset-view/index.styled';
import { FeatureFlagName } from 'generated/global-types';

const GetFolderByIdQuery = loader('../../../graphql/GetFolderById.gql');

const DeleteFolderMutation = loader('../../../graphql/DeleteFolder.gql');

const UpdateFolderMutation = loader('../../../graphql/UpdateFolder.gql');

const setPage = (history: any, page: string | number) => {
  const query = new URLSearchParams(history.location.search);
  query.set('page', page as string);
  history.push({ ...history.location, search: query.toString() });
  window.scrollTo(0, 0);
};

const PAGE_SIZE = 12;

const FolderView: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const feedId = useFeedId();
  const queryParams = useQueryParams();
  const pageParam = queryParams.get('page') || '';
  const page = Number.isNaN(parseInt(pageParam, 10))
    ? 1
    : parseInt(pageParam, 10);
  const offset = (page - 1) * PAGE_SIZE;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const enableAssetsLibrary = useFeatureFlag(FeatureFlagName.ASSETS_CRUD);

  const { shouldShowToast, showToast, hideToast } = useToast();
  const [toastText, setToastText] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { loading: isFolderLoading, data } = useQuery<
    GetFolderById,
    GetFolderByIdVariables
  >(GetFolderByIdQuery, {
    variables: {
      id,
      offset,
      perPage: PAGE_SIZE,
    },
    fetchPolicy: 'cache-and-network',
  });

  const folder = data?.socialMediaFolder;
  const uploads =
    folder?.socialMediaUploadsByFolderId?.nodes.map(({ upload }) => ({
      ...upload,
    })) ?? [];

  const areUploadsAvailable = uploads.length > 0;

  const [folderName, setFolderName] = useState(folder?.name);

  useEffect(() => {
    if (folder) {
      setFolderName(folder.name);
    }
  }, [folder]);

  const [updateFolder, { loading: isUpdateFolderLoading }] = useMutation<
    UpdateFolder,
    UpdateFolderVariables
  >(UpdateFolderMutation, {
    refetchQueries: [
      {
        query: GetFolderByIdQuery,
        variables: { id, offset, perPage: PAGE_SIZE },
      },
    ],
    onError: (error: ApolloError) => {
      throw error;
    },
  });

  const [deleteFolderMutation, { loading: isDeleteFolderLoading }] =
    useMutation<DeleteFolder, DeleteFolderVariables>(DeleteFolderMutation, {
      onError: (error: ApolloError) => {
        throw error;
      },
    });

  const editFolder = async () => {
    try {
      if (!folderName?.length) {
        return;
      }
      await updateFolder({
        variables: {
          id,
          name: folderName,
        },
      });
      setIsEditModalOpen(false);
      setToastText('Folder updated successfully');
      showToast();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const deleteFolder = async () => {
    try {
      await deleteFolderMutation({
        variables: {
          id,
        },
      });
      history.push(`/${feedId}/assets/social-media`);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <PageMeta title="View Folder" />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}/assets/social-media`,
          title: 'Back to social media',
        }}
      />
      <Toast
        shouldShow={shouldShowToast}
        onDismiss={hideToast}
        level={ToastLevel.success}
      >
        {toastText}
      </Toast>
      <Loader loading={isFolderLoading}>
        <Well.Container
          css={css`
            margin-top: 0;
          `}
        >
          <Well.Section>
            <S.Header>
              <S.Info>
                <S.Title>{folder?.name}</S.Title>
                <S.EditedLast>
                  {folder?.updatedAt && (
                    <React.Fragment>
                      <strong>Last Updated:</strong>{' '}
                      {formatDate(new Date(folder?.updatedAt), DATE_FORMAT)}
                      {folder?.updater && ` by ${folder?.updater?.name}`}
                    </React.Fragment>
                  )}
                </S.EditedLast>
              </S.Info>
              <S.Buttons>
                <React.Fragment>
                  <S.Button primary onClick={() => setIsEditModalOpen(true)}>
                    Edit
                  </S.Button>
                  <S.Button
                    disabled={isDeleteFolderLoading || !!uploads.length}
                    onClick={async () => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete this folder?',
                        )
                      ) {
                        deleteFolder();
                      }
                    }}
                  >
                    Delete
                  </S.Button>
                </React.Fragment>
              </S.Buttons>
            </S.Header>
          </Well.Section>
        </Well.Container>
        <Well.Container>
          <Well.Section>
            <S.Header>
              <S.Info>
                <S.Title
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  Uploads
                </S.Title>
              </S.Info>
            </S.Header>
          </Well.Section>
          <Well.Section>
            {areUploadsAvailable ? (
              <AssetsView
                title={null}
                assets={uploads as SocialMediaUploadType[]}
                thumbnailWidth="281px"
                thumbnailHeight="159px"
                onAssetClick={(asset) => {
                  if (enableAssetsLibrary) {
                    history.push(
                      `/${feedId}/assets/${asset?.id}?type=social-media`,
                    );
                  }
                }}
              />
            ) : (
              <NoMessages
                css={css`
                  margin-top: 20px;
                `}
                heading={WEB_NO_ASSETS_HEADING}
                message={WEB_NO_ASSETS_MESSAGE_IN_FOLDER}
              />
            )}
          </Well.Section>
        </Well.Container>
        {areUploadsAvailable && (
          <div
            css={css`
              padding-right: 32px;
              padding-bottom: 32px;
            `}
          >
            <Pagination
              currentPage={page}
              itemsPerPage={PAGE_SIZE}
              totalItems={folder?.socialMediaUploadsByFolderId?.totalCount ?? 0}
              onPrevClick={() => setPage(history, page - 1)}
              onNextClick={() => setPage(history, page + 1)}
            />
          </div>
        )}
      </Loader>
      <Modal
        isOpen={isEditModalOpen}
        title="Edit Folder"
        onDismiss={() => setIsEditModalOpen(false)}
      >
        <form>
          <S.Input
            id="folder-name"
            name="folder-name"
            type="text"
            placeholder="Folder Name"
            value={folderName}
            onChange={(evt) => setFolderName(evt.target.value)}
            required
            autoFocus
          />
          <div
            css={css`
              margin-top: 25px;
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button
              css={css`
                width: 160px;
                margin-right: 15px;
              `}
              size="medium"
              onClick={() => setIsEditModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              css={css`
                width: 160px;
              `}
              primary
              size="medium"
              disabled={isUpdateFolderLoading}
              onClick={async (e) => {
                e.preventDefault();
                editFolder();
              }}
            >
              Update
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default FolderView;
