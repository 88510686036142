/** @jsxImportSource @emotion/react */

import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTable, Column } from 'react-table';
import { css } from '@emotion/react';
import { format } from 'date-fns';

import Loader from 'components/common/skeletons/PageWithTable';
import { INDEFINITE_END_DATE_MESSAGE } from 'constants/empty-states';
import { MESSAGE_TYPE_DESCRIPTIONS } from '@mta-live-media-manager/shared';
import { isBridgeTunnel } from 'utils/feed-switches';
import {
  CellSpacer,
  Table,
  SortButton,
  TextCell,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableData,
  BulletsCell,
} from '../table-elements';
import PlannedWorkVisibility, {
  Channels,
  VisibilityIcons,
} from './PlannedWorkVisibility';

import Pagination from '../Pagination';

import StatusPill, { TStatusPillTypes } from '../status-pill';

import { FeedId } from '../../../types/feeds';
import { ReactComponent as EditIcon } from '../../../images/icon_edit_sm.svg';
import theme, { ThemeType } from '../../../theme';
import { PlannedWorks_searchPlannedWorks_SearchPlannedWorksResult_results_DashboardPlannedWork as PlannedWorks_searchPlannedWorks_results } from '../../../generated/PlannedWorks';
import { PlannedWorkStatus } from '../../../generated/global-types';

import { PLANNED_WORK_STATUSES } from '../../../constants/planned-work';

export enum TPlannedWorkTableSortingTypes {
  lines = 'lines',
  category = 'category',
  status = 'status',
  end = 'end',
  start = 'start',
}

const MAX_LENGTH = 120;

const COL_WIDTHS: { [key: string]: string } = {
  lines: '130px',
  category: 'auto',
  status: '130px',
  live: '110px',
  duration: '160px',
  id: '50px',
};

const BT_COL_WIDTHS: { [key: string]: string } = {
  lines: '300px',
  category: 'auto',
  status: '100px',
  live: '110px',
  duration: '150px',
  id: '50px',
};

interface TableStructure {
  lines: string[];
  category: {
    header: string;
    body: string;
  };
  status: {
    status: TStatusPillTypes;
    textOverride?: string;
  };
  live: {
    screensAreClearing?: boolean;
    homepage?: boolean | null;
    screens?: boolean | null;
  };
  duration: {
    start: Date;
    end: Date | string;
  };
  id?: number | null;
}

const transformMessagesToTableStructure = (
  messages: PlannedWorks_searchPlannedWorks_results[],
): TableStructure[] =>
  messages.map(
    ({
      id,
      entitySelectors,
      status,
      header,
      start,
      end,
      messageType,
      liveOnScreens,
      liveOnHomepage,
    }) => ({
      lines:
        entitySelectors
          ?.filter((r) => !!r?.routeId)
          .map((r) => r?.routeId || '') || [],
      category: {
        header:
          messageType && messageType.length > 0
            ? messageType
                .map((mt) => {
                  return mt ? MESSAGE_TYPE_DESCRIPTIONS[mt] : '';
                })
                .join(', ')
            : '',
        body:
          header.length > MAX_LENGTH
            ? `${header.substring(0, header.lastIndexOf(' ', MAX_LENGTH))}…`
            : header,
      },
      status: {
        status: PLANNED_WORK_STATUSES.find(
          (opt) => opt?.data?.status === status,
        )?.data.statusPill as TStatusPillTypes,
        textOverride: PLANNED_WORK_STATUSES.find(
          (opt) => opt?.data?.status === status,
        )?.label,
      },
      live: {
        screensAreClearing: status === PlannedWorkStatus.CLEARING,
        homepage: liveOnHomepage,
        screens: liveOnScreens,
      },
      duration: {
        start: new Date(start),
        end: end ? new Date(end) : INDEFINITE_END_DATE_MESSAGE,
      },
      id,
    }),
  );

const PlannedWorkTable: React.FC<
  {
    onPrevClick: () => void;
    onNextClick: () => void;
    onSortClick: ({
      isDesc,
      orderBy,
    }: {
      isDesc: boolean;
      orderBy: TPlannedWorkTableSortingTypes;
    }) => void;
    isDesc?: boolean;
    orderBy?: TPlannedWorkTableSortingTypes;
    page?: number;
    perPage?: number;
    search?: string;
    status?: TStatusPillTypes;
    feedId: FeedId;
    messages: PlannedWorks_searchPlannedWorks_results[];
    totalCount: number;
    showEdit?: boolean;
    showVisibleOn?: boolean;
    loading?: boolean;
  } & { children?: React.ReactNode }
> = ({
  page = 1,
  perPage = 5,
  isDesc = true,
  orderBy = 'lines',
  onSortClick,
  onNextClick,
  onPrevClick,
  feedId,
  messages,
  totalCount,
  showEdit = true,
  showVisibleOn = true,
  loading = false,
}) => {
  const {
    location: { search: filters },
  } = useHistory();
  const data = useMemo(
    () => transformMessagesToTableStructure(messages),
    [messages],
  );

  const columns = useMemo(() => {
    const changeSortSettings = (newOrderBy: TPlannedWorkTableSortingTypes) => {
      const isSameType = orderBy === newOrderBy;
      onSortClick({
        orderBy: newOrderBy,
        isDesc: isSameType ? !isDesc : false,
      });
    };
    const columnArray: Column<TableStructure>[] = [
      {
        accessor: 'lines',
        Cell: ({
          cell: { value },
        }: {
          cell: {
            value: TableStructure['lines'];
          };
        }) => {
          return (
            <BulletsCell
              maxWidth={isBridgeTunnel(feedId) ? '300px' : '150px'}
              textAlign="center"
              routeIds={value}
              limit={4}
            />
          );
        },
        Header: () => {
          return (
            <SortButton
              title="Line(s)"
              textAlign="center"
              onClick={() =>
                changeSortSettings(TPlannedWorkTableSortingTypes.lines)
              }
              isSorted={orderBy === TPlannedWorkTableSortingTypes.lines}
              isSortedDesc={isDesc}
            />
          );
        },
      },
      {
        accessor: 'category',
        Cell: ({
          cell: { value },
        }: {
          cell: {
            value: TableStructure['category'];
          };
        }) => (
          <TextCell
            css={css`
              margin-top: 8px;
            `}
            heading={value.header}
            headingSize="small"
          >
            <span
              css={({ typography }: ThemeType) => css`
                ${typography.sizes.small};
                font-family: ${typography.families.primary};
                margin-top: 5px;
                margin-bottom: 7px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
              `}
            >
              {value.body}
            </span>
          </TextCell>
        ),
      },
      {
        accessor: 'status',
        Cell: ({
          cell: { value },
        }: {
          cell: { value: TableStructure['status'] };
        }) => (
          <CellSpacer textAlign="center">
            <StatusPill {...value} size="small" />
          </CellSpacer>
        ),
        Header: () => (
          <SortButton
            title="State"
            textAlign="center"
            onClick={() =>
              changeSortSettings(TPlannedWorkTableSortingTypes.status)
            }
            isSorted={orderBy === TPlannedWorkTableSortingTypes.status}
            isSortedDesc={isDesc}
          />
        ),
      },
    ];

    if (showVisibleOn) {
      columnArray.push({
        accessor: 'live',
        Cell: ({
          cell: {
            value: { homepage, screens, screensAreClearing },
          },
        }: {
          cell: { value: TableStructure['live'] };
        }) => {
          const homepageIcon = (() => {
            if (homepage === null || screensAreClearing) {
              return null;
            }
            return homepage ? VisibilityIcons.LIVE : VisibilityIcons.CLOCK;
          })();
          const screensIcon = (() => {
            if (screensAreClearing) {
              return VisibilityIcons.WARNING;
            }
            if (screens === null) {
              return null;
            }
            return screens ? VisibilityIcons.LIVE : VisibilityIcons.CLOCK;
          })();

          return (
            <CellSpacer>
              <PlannedWorkVisibility
                type={Channels.homepage}
                icon={homepageIcon}
              />
              {!isBridgeTunnel(feedId) && (
                <PlannedWorkVisibility
                  type={Channels.screens}
                  icon={screensIcon}
                />
              )}
            </CellSpacer>
          );
        },
        Header: () => {
          return <>Visible On</>;
        },
      });
    }

    columnArray.push({
      accessor: 'duration',
      Cell: ({ cell }: { cell: { value: TableStructure['duration'] } }) => {
        const { end, start } = cell.value;
        const fmt = 'MM/dd/yy';
        const dateText = `${format(start, fmt)} - ${
          typeof end === 'string' ? end : format(end, fmt)
        }`;
        return (
          <TextCell
            textAlign="center"
            textWrap={false}
            heading={dateText}
            headingSize="small"
          />
        );
      },
      Header: () => {
        return (
          <div
            css={css`
              width: 100%;
              display: flex;
              flex-direction: flex-row;
            `}
          >
            <SortButton
              css={css`
                padding-right: 4px;
              `}
              title="Start"
              onClick={() =>
                changeSortSettings(TPlannedWorkTableSortingTypes.start)
              }
              isSorted={orderBy === TPlannedWorkTableSortingTypes.start}
              isSortedDesc={isDesc}
              width="auto"
            />
            -
            <SortButton
              css={css`
                padding-left: 4px;
              `}
              title="End"
              onClick={() =>
                changeSortSettings(TPlannedWorkTableSortingTypes.end)
              }
              isSorted={orderBy === TPlannedWorkTableSortingTypes.end}
              isSortedDesc={isDesc}
              width="auto"
            />
          </div>
        );
      },
    });

    if (showEdit) {
      columnArray.push({
        accessor: 'id',
        Cell: ({ cell }: any) => (
          <Link
            css={({ colors }: ThemeType) => css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 50px;
              color: ${colors.dark2};

              &:hover {
                color: ${colors.brand};
              }
            `}
            to={`/${feedId}/planned-work/${cell.value}/edit`}
            onClick={(e) => e.stopPropagation()}
          >
            <EditIcon
              css={(theme: ThemeType) => css`
                color: ${theme.colors.accent1};
                transform: scale(1.5);
              `}
            />
          </Link>
        ),
        Header: () => <span />,
      });
    }
    return columnArray;
  }, [isDesc, orderBy, onSortClick, feedId, showEdit, showVisibleOn]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Loader loading={loading}>
        <React.Fragment>
          <Table
            css={css`
              width: 100%;
              table-layout: fixed;
              margin-bottom: 1rem;
            `}
            {...getTableProps()}
          >
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    const width = isBridgeTunnel(feedId)
                      ? BT_COL_WIDTHS[column.id]
                      : COL_WIDTHS[column.id];
                    return (
                      <TableHeaderCell
                        css={css`
                          width: ${width};
                        `}
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </TableHeaderCell>
                    );
                  })}
                </tr>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      const hasLeftBorder = !['lines'].includes(cell.column.id);
                      const width = isBridgeTunnel(feedId)
                        ? BT_COL_WIDTHS[cell.column.id]
                        : COL_WIDTHS[cell.column.id];

                      // This is to avoid having nested `<a>` tags caused by `<AppLink>` and `<Link>` used in this component
                      const isEditColumn = cell.column.id === 'id';

                      return (
                        <TableData
                          css={css`
                            width: ${width};
                          `}
                          hasLeftBorder={hasLeftBorder}
                          {...cell.getCellProps()}
                        >
                          {isEditColumn ? (
                            cell.render('Cell')
                          ) : (
                            <Link
                              css={css`
                                color: ${theme.colors.black};
                              `}
                              to={{
                                pathname: `/${feedId}/planned-work/${row.original.id}`,
                                state: { prevFilters: filters },
                              }}
                            >
                              {cell.render('Cell')}
                            </Link>
                          )}
                        </TableData>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Pagination
            currentPage={page}
            itemsPerPage={perPage}
            totalItems={totalCount}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
          />
        </React.Fragment>
      </Loader>
    </div>
  );
};

export default PlannedWorkTable;
