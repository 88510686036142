/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Collapse } from 'react-collapse';
import AppLink, { IAppLinkProps } from '../../common/app-link';
import theme from '../../../theme';

const baseListStyles = css`
  margin: 0;
  list-style: none;
`;

const baseNavLinkStyles = css`
  color: ${theme.colors.white};
  display: inline-block;
  padding: 8px ${theme.spacing.small};
  width: 100%;

  &.active {
    background-color: #183c70;
    font-weight: ${theme.typography.weights.bold};
  }

  &:hover {
    background-color: #2a61a4;
  }
`;

export const NavList: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <ul
    css={css`
      ${baseListStyles};
      ${theme.typography.sizes.medium};
      color: ${theme.colors.white};
      font-family: ${theme.typography.families.primary};
      padding: 0;
    `}
    {...props}
  />
);

export const Heading: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
}) => (
  <h3
    css={css`
      ${theme.typography.sizes.small};
      text-transform: uppercase;
      color: ${theme.colors.light1};
      margin: 0;
      opacity: 0.7;
      padding: ${theme.spacing.xsmall} ${theme.spacing.small}
        ${theme.spacing.xxsmall};
    `}
  >
    {children}
  </h3>
);

export const SecondaryList: React.FC<{
  show: boolean;
  children?: React.ReactNode;
}> = ({ children, show }) => (
  <Collapse isOpened={show}>
    <ul
      css={[
        baseListStyles,
        css`
          padding: 0;
        `,
      ]}
    >
      {children}
    </ul>
  </Collapse>
);

export const ListItem: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
}) => (
  <li
    css={css`
      padding: 0;
    `}
  >
    {children}
  </li>
);

export const NavLinkL1: React.FC<IAppLinkProps> = (props) => (
  <AppLink isNavLink css={baseNavLinkStyles} {...props} />
);

export const NavLinkL2: React.FC<IAppLinkProps> = (props) => (
  <AppLink
    isNavLink
    css={[
      baseNavLinkStyles,
      css`
        ${theme.typography.sizes.small};
        padding: ${theme.spacing.xxsmall} ${theme.spacing.small}
          ${theme.spacing.xxsmall} ${theme.spacing.large};
      `,
    ]}
    {...props}
  />
);

export const LocalScreensWarning: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ children }) => (
  <p
    css={css`
      background: #fff;
      color: #000;
      padding: 0.5rem;
      border-radius: 0.5rem;
      margin: 1rem;
    `}
  >
    {children}
  </p>
);
