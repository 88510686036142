/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';
import Toggle from './Toggle';

import * as S from './FormHeader.styled';
import Heading from '../Heading';

const FormHeader: React.FC<
  {
    title: string;
    toggle?: boolean;
    enabled?: boolean;
    onToggle?: ((...args: any[]) => any) &
      ((event: React.ChangeEvent<HTMLInputElement>) => void);
  } & { children?: React.ReactNode }
> = ({ title, toggle, enabled, onToggle }) => (
  <S.FormHeaderContainer>
    {toggle ? <Toggle onChange={onToggle} checked={enabled} /> : null}
    <Heading
      level={3}
      size="large"
      css={css`
        margin-left: ${toggle ? '12px' : '0'};
      `}
    >
      {title}
    </Heading>
  </S.FormHeaderContainer>
);

export default FormHeader;
