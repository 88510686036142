/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

// Styled components
import { ThemeType } from '../../../theme';

const truncate = (str: string) =>
  str.length > 65 ? `${str.substring(0, 62)}…` : str;

const SMSPreview: React.FC<{
  body: string;
  children?: React.ReactNode;
}> = ({ body }) => (
  <div>
    <div
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.medium};
        font-family: ${theme.typography.families.secondary};
        background-color: #f8f8f8;
        border-radius: 12px 12px 12px 0;
        padding: 32px;
        width: 50%;
        max-width: 320px;
        white-space: pre-wrap;
      `}
    >
      <p
        css={css`
          margin: 0px;
        `}
      >
        {truncate(body.trim()).replace(/\\n/g, '\n')}
        <br />
        <br />
        HELP for options. STOP to quit.
      </p>
    </div>
    <div
      css={() => css`
        width: 0;
        height: 0;
        border-left: 17px solid #f8f8f8;
        border-bottom: 17px solid transparent;
      `}
    />
  </div>
);

export default SMSPreview;
