/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';

import {
  SCREEN_SIZE_PORTRAIT,
  SCREEN_SIZE_LANDSCAPE,
} from 'constants/planned-work';
import { GetPlannedWorkById_plannedWork_PlannedWork_plannedWorkScreenMessages_PlannedWorkScreenMessagesConnection_nodes_PlannedWorkScreenMessage_upload_Upload as GetPlannedWorkById_plannedWork_plannedWorkScreenMessages_nodes_upload } from 'generated/GetPlannedWorkById';

import { ScreenBox } from '../../common/campaign-form/screen-uploader.styled';

// Styled components
import Heading from '../../common/Heading';

const PlannedWorkScreenPreview: React.FC<
  {
    upload?: GetPlannedWorkById_plannedWork_plannedWorkScreenMessages_nodes_upload | null;
    isLandscape?: boolean;
  } & { children?: React.ReactNode }
> = ({ upload, isLandscape }) => {
  const screenSize = isLandscape ? SCREEN_SIZE_LANDSCAPE : SCREEN_SIZE_PORTRAIT;
  const contents = (() => {
    if (upload && upload?.mimeType.startsWith('image/')) {
      return (
        <div
          role="img"
          css={css`
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(${upload?.signedS3Url});
          `}
        />
      );
    }
    if (upload && upload?.mimeType.startsWith('video/')) {
      return (
        <video
          controls
          autoPlay
          loop
          muted
          css={css`
            height: 100%;
            width: 100%;
          `}
          src={upload?.signedS3Url || undefined}
        />
      );
    }

    return null;
  })();

  return (
    <ScreenBox
      height={screenSize.height}
      width={screenSize.width}
      multiplier={isLandscape ? 0.2 : 0.3}
    >
      {contents}
    </ScreenBox>
  );
};

const ScreenPreview: React.FC<
  {
    upload?: GetPlannedWorkById_plannedWork_plannedWorkScreenMessages_nodes_upload | null;
    isLandscape?: boolean;
  } & { children?: React.ReactNode }
> = ({ upload, isLandscape }) => (
  <div
    css={css`
      flex: 0 0 ${isLandscape ? 380 : 270}px;
    `}
  >
    <Heading
      level={4}
      size="medium"
      css={css`
        margin-bottom: 12px;
      `}
    >
      Screen Preview
    </Heading>
    <PlannedWorkScreenPreview upload={upload} isLandscape={isLandscape} />
  </div>
);

export default ScreenPreview;
