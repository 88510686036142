/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState, PropsWithChildren as PWC } from 'react';
import { Collapse } from 'react-collapse';
import { format, isAfter } from 'date-fns';

import { EMAIL_TYPE_LABELS } from 'constants/email';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message as GetEventById_event_messages_nodes } from 'generated/GetEventById';
import LiveTime from 'components/common/LiveTime';
import { useNow } from 'utils/clock';
import Heading from '../../common/Heading';
import StatusPill, { TStatusPillTypes } from '../../common/status-pill';
import * as S from './PublishItem.styled';

import {
  EmailMessageTypeCodes,
  PublishStatus,
} from '../../../generated/global-types';

import { PublishStatusIcon } from '../../common/status-icon';
import { DATE_FORMAT } from '../../../constants/time';

const InactivePublishItem: React.FC<
  {
    name: React.ReactNode;
    removedDate?: Date;
  } & { children?: React.ReactNode }
> = ({ name, removedDate }) => {
  const removedText = removedDate
    ? format(new Date(removedDate), DATE_FORMAT)
    : '';
  return (
    <S.InactiveContainer>
      <S.InactiveContentContainer>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            opacity: 0.5;
          `}
        >
          <S.StatusContainer>
            <PublishStatusIcon size={20} status={PublishStatus.INACTIVE} />
          </S.StatusContainer>
          <Heading level={3}>{name}</Heading>
        </div>
        <S.PublishDate variant="inactive">
          {removedDate ? `Removed: ${removedText}` : 'Not posted'}
        </S.PublishDate>
      </S.InactiveContentContainer>
      <S.Spacer />
    </S.InactiveContainer>
  );
};

const PublishItem: React.FC<
  PWC<{
    publishedAt?: Date;
    publishedAtVerb?: string;
    name: React.ReactNode;
    status: PublishStatus;
    numSent?: number | null;
    removedDate?: Date;
    subtitle?: string;
    initIsOpen?: boolean;
    eventMessages?: GetEventById_event_messages_nodes[];
    currentMessageNumber?: number;
    singleItem?: boolean;
    endAt?: string;
    addClearingStatusPill?: boolean;
  }>
> = ({
  name,
  status,
  publishedAt,
  publishedAtVerb = 'Posted',
  numSent,
  removedDate,
  subtitle,
  initIsOpen = false,
  children,
  eventMessages,
  currentMessageNumber = 1,
  singleItem = false,
  endAt,
  addClearingStatusPill = false,
}) => {
  const publishedAtText = publishedAt
    ? format(new Date(publishedAt), DATE_FORMAT)
    : '';

  const now = useNow();
  const endDate = endAt ? new Date(endAt) : null;
  const expired = !!(endDate && isAfter(now, endDate));
  const [open, setOpen] = useState(initIsOpen);

  const currentMessage = eventMessages?.slice()?.reverse()?.[
    currentMessageNumber - 1
  ];

  const sortedEmailTypeLabels =
    (currentMessage?.emailTypes as EmailMessageTypeCodes[])
      ?.map((et) => EMAIL_TYPE_LABELS[et])
      ?.sort() ?? [];

  const messageEmailStats = currentMessage?.emailSmsPostedAt
    ? {
        id: currentMessage.id,
        emailTypeLabel: `${sortedEmailTypeLabels[0]}${
          sortedEmailTypeLabels.length > 1
            ? ` +${sortedEmailTypeLabels.length - 1} more`
            : ''
        }`,
        numEmailSmsSent: currentMessage.numEmailSmsSent?.toLocaleString(),
      }
    : null;

  const canOpen =
    status === PublishStatus.SUCCESS ||
    status === PublishStatus.ERROR ||
    status === PublishStatus.WARNING;

  return status !== PublishStatus.INACTIVE ? (
    <S.Container
      css={css`
        ${singleItem ? 'border-top: 0' : ''};
      `}
    >
      <S.ToggleButton
        canOpen={canOpen}
        onClick={() => canOpen && setOpen(!open)}
      >
        {canOpen && <S.IndicatorArrow isOpen={open} />}
        <S.StatusContainer>
          <PublishStatusIcon size={20} status={status} />
        </S.StatusContainer>
        <Heading level={3}>{name}</Heading>
        {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        {addClearingStatusPill && (
          <StatusPill
            css={css`
              margin-left: 16px;
            `}
            status={TStatusPillTypes.CLEARING}
            size="small"
          />
        )}
        {numSent && !eventMessages && (
          <S.NumberSent>{numSent.toLocaleString()} recipients</S.NumberSent>
        )}
        {messageEmailStats && (
          <S.PublishGroups>
            <S.PublishGroup key={messageEmailStats.id}>
              {messageEmailStats.emailTypeLabel}{' '}
              {messageEmailStats.numEmailSmsSent && (
                <React.Fragment>
                  ({messageEmailStats.numEmailSmsSent} recipients)
                </React.Fragment>
              )}
            </S.PublishGroup>
          </S.PublishGroups>
        )}
        <S.Spacer />
        {status === PublishStatus.SUCCESS && (
          <S.PublishDate>
            {publishedAtText
              ? `${publishedAtVerb}: ${publishedAtText}`
              : 'Not posted'}
          </S.PublishDate>
        )}
        {status === PublishStatus.ERROR && (
          <S.PublishDate variant="error">Failed to post</S.PublishDate>
        )}
        {endAt !== undefined && (
          <S.ExpirationDate expired={expired}>
            {endAt ? (
              <LiveTime format="fromNow" timeToCompare={endAt} />
            ) : (
              'Not Set'
            )}
          </S.ExpirationDate>
        )}
      </S.ToggleButton>
      <Collapse isOpened={open}>
        <S.PreviewContainer>{children}</S.PreviewContainer>
      </Collapse>
    </S.Container>
  ) : (
    <InactivePublishItem removedDate={removedDate} name={name} />
  );
};

export default PublishItem;
