const TITLE_NAME = 'title';
const DURATIONS_SOURCE_NAME = 'durationsSource';
const SELECTORS_NAME = 'selectors';
const SCREEN_TARGETING_UPLOADS_LAYOUTS_NAME = 'uploadsAndLayouts';
const SCREEN_TARGETING_TYPE_NAME = 'targetingTypeSelection';

export const CampaignErrorKeys = {
  title: TITLE_NAME,
  durationsSource: DURATIONS_SOURCE_NAME,
};

export const ScreenTargetingErrorKeys = {
  selectors: SELECTORS_NAME,
  uploadsAndLayouts: SCREEN_TARGETING_UPLOADS_LAYOUTS_NAME,
  targetingTypeSelection: SCREEN_TARGETING_TYPE_NAME,
};

export type ICampaignErrorKey = keyof typeof CampaignErrorKeys;

export type ICampaignError = {
  [key in ICampaignErrorKey]?: string;
};

export type IScreenTargetingErrorKey = keyof typeof ScreenTargetingErrorKeys;

export type IScreenTargetingError = {
  [key in IScreenTargetingErrorKey]?: string;
};

export type MessageError = {
  campaign: ICampaignError;
  screens: IScreenTargetingError[];
};
