/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import CrossIcon from 'components/common/cross-icon';
import Button from 'components/common/Button';
import theme from 'theme';

import Editor, { IEditorProps } from './index';

interface OptionalEditorProps
  extends Omit<IEditorProps, 'maxLength' | 'error' | 'invalid'> {
  onToggle: (enabled: boolean) => void;
  isOpen?: boolean;
}

/*
 * An editor that is openable via a plain button, and is also
 * dismissable via a close button.
 * Usually this is utilized for the "Additional Info" field, and as such,
 * needs only minor adjustments to appearance and functionality.
 */
const OptionalEditor: React.FC<OptionalEditorProps> = ({
  labelText,
  id = 'optional-editor',
  className = 'optional-editor',
  onToggle,
  isOpen,
  ...editorProps
}) => {
  if (!isOpen) {
    return (
      <div
        css={css`
          margin-top: 8px;
        `}
      >
        <Button
          plain
          type="button"
          onClick={() => {
            onToggle(true);
          }}
        >
          + Add Details
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          margin-top: 8px;
        `}
      >
        <div
          css={css`
            flex: 1;
            position: relative;
          `}
        >
          <Editor
            labelText={labelText}
            id={id}
            className={className}
            {...editorProps}
          />
          <div
            css={css`
              position: absolute;
              top: 56px;
              right: 17px;
            `}
          >
            <button
              css={css`
                width: 100%;
                height: 100%;
                outline: none;
                border: 1px solid transparent;
                border-radius: 4px;

                &:focus {
                  border-color: ${theme.colors.accent3};
                }
              `}
              type="button"
              aria-label="Remove Details"
              onClick={() => {
                onToggle(false);
              }}
            >
              <CrossIcon size="8px" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionalEditor;
