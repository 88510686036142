/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import {
  GtfsEntitySelectorInput,
  PublishStatus,
} from '../../../generated/global-types';
import { IRawImpact } from '../../common/message/impacts-display';
import MessageCard from '../../common/message/message-card';
import { DraftMessages_draftMessages_DraftMessagesConnection_nodes_DraftMessage as DraftMessages_draftMessages_nodes } from '../../../generated/DraftMessages';

type DraftMessagesType = Omit<DraftMessages_draftMessages_nodes, '__typename'>;

const DraftMessage: React.FC<
  DraftMessagesType & {
    onDelete: () => void;
  }
> & { children?: React.ReactNode } = ({
  id,
  eventId,
  createdAt,
  updatedAt,
  impacts: rawImpacts,
  entitySelectors,
  bodyHtml,
  includeApps,
  includeEmailSms,
  includeScreens,
  includeTwitter,
  onDelete,
  author,
  taggedTrips,
}) => {
  const impacts = rawImpacts
    ? rawImpacts
        .map((impact) => {
          return {
            messageType: impact?.messageType,
            entitySelectors:
              impact?.entitySelectors as GtfsEntitySelectorInput[],
          };
        })
        .filter(
          (impact): impact is IRawImpact =>
            !!impact.messageType && !!impact.entitySelectors,
        )
    : undefined;

  const webStatus = includeApps ? PublishStatus.DRAFT : PublishStatus.INACTIVE;
  const screensStatus = includeScreens
    ? PublishStatus.DRAFT
    : PublishStatus.INACTIVE;
  const emailSmsStatus = includeEmailSms
    ? PublishStatus.DRAFT
    : PublishStatus.INACTIVE;
  const tweetsStatus = includeTwitter
    ? PublishStatus.DRAFT
    : PublishStatus.INACTIVE;

  return (
    <MessageCard
      isDraft
      css={css`
        margin-bottom: 22px;
        box-shadow:
          0 1px 1px 0 rgba(0, 0, 0, 0.14),
          0 1px 3px 0 rgba(0, 0, 0, 0.2);
      `}
      id={id}
      eventId={eventId}
      createdAt={createdAt}
      updatedAt={updatedAt}
      entitySelectors={entitySelectors}
      taggedTrips={taggedTrips}
      bodyHtml={bodyHtml}
      webStatus={webStatus}
      screensStatus={screensStatus}
      emailSmsStatus={emailSmsStatus}
      tweetsStatus={tweetsStatus}
      authorName={author && author.name ? author.name : undefined}
      impacts={impacts}
      impactsLimit={2}
      onDelete={onDelete}
      author={author ?? undefined}
    />
  );
};

export default DraftMessage;
