import { FeedId } from '../types/feeds';

const {
  REACT_APP_NYCT_SUBWAY_TWITTER_USERNAME,
  REACT_APP_NYCT_BUS_TWITTER_USERNAME,
  REACT_APP_MNR_TWITTER_USERNAME,
  REACT_APP_LIRR_TWITTER_USERNAME,

  REACT_APP_NYCT_SUBWAY_TWITTER_DISPLAY_NAME,
  REACT_APP_NYCT_BUS_TWITTER_DISPLAY_NAME,
  REACT_APP_MNR_TWITTER_DISPLAY_NAME,
  REACT_APP_LIRR_TWITTER_DISPLAY_NAME,

  REACT_APP_PROTECTED_TWEETS,
} = process.env;

if (
  !REACT_APP_NYCT_SUBWAY_TWITTER_USERNAME ||
  !REACT_APP_NYCT_BUS_TWITTER_USERNAME ||
  !REACT_APP_MNR_TWITTER_USERNAME ||
  !REACT_APP_LIRR_TWITTER_USERNAME ||
  !REACT_APP_NYCT_SUBWAY_TWITTER_DISPLAY_NAME ||
  !REACT_APP_NYCT_BUS_TWITTER_DISPLAY_NAME ||
  !REACT_APP_MNR_TWITTER_DISPLAY_NAME ||
  !REACT_APP_LIRR_TWITTER_DISPLAY_NAME
) {
  throw new Error('Missing environment variable');
}

export const PROTECTED_TWEETS = REACT_APP_PROTECTED_TWEETS !== 'false';

export const TWITTER_USERNAMES: { [key in FeedId]: string } = {
  [FeedId.MNR]: REACT_APP_MNR_TWITTER_USERNAME,
  [FeedId.NYCTBus]: REACT_APP_NYCT_BUS_TWITTER_USERNAME,
  [FeedId.LIRR]: REACT_APP_LIRR_TWITTER_USERNAME,
  [FeedId.NYCTSubway]: REACT_APP_NYCT_SUBWAY_TWITTER_USERNAME,
  [FeedId.BT]: '',
  [FeedId.OutfrontQA]: '',
  [FeedId.OutfrontDEV]: '',
};

export const TWITTER_DISPLAY_NAMES: { [key in FeedId]: string } = {
  [FeedId.MNR]: REACT_APP_MNR_TWITTER_DISPLAY_NAME,
  [FeedId.NYCTBus]: REACT_APP_NYCT_BUS_TWITTER_DISPLAY_NAME,
  [FeedId.LIRR]: REACT_APP_LIRR_TWITTER_DISPLAY_NAME,
  [FeedId.NYCTSubway]: REACT_APP_NYCT_SUBWAY_TWITTER_DISPLAY_NAME,
  [FeedId.BT]: '',
  [FeedId.OutfrontQA]: '',
  [FeedId.OutfrontDEV]: '',
};
