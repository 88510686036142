import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import useLocalForage from 'hooks/useLocalForage';
import useAuthentication from 'contexts/Authentication';

const ReferrerRedirect: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
}) => {
  const {
    session: { isAuthenticated },
  } = useAuthentication();
  const { state: locationState } = useLocation();
  const locationFrom = (locationState as { from?: Location })?.from;
  const referrer = `${locationFrom?.pathname ?? ''}${
    locationFrom?.search ?? ''
  }`;
  const [loginReferrer, setLoginReferrer, { initialLoad, updating }] =
    useLocalForage<string>('loginReferrer');

  useEffect(() => {
    if (referrer && !isAuthenticated && loginReferrer !== referrer) {
      setLoginReferrer(referrer);
    }

    return () => {
      if (isAuthenticated && !!loginReferrer) {
        setLoginReferrer(undefined, false);
      }
    };
  }, [
    locationState,
    isAuthenticated,
    referrer,
    loginReferrer,
    setLoginReferrer,
  ]);

  if (!isAuthenticated || initialLoad || updating) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const to = loginReferrer ?? referrer ?? '/';
  return <Redirect to={to} />;
};

export default ReferrerRedirect;
