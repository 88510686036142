import MessageType from './message-type';
export var FeedId;
(function (FeedId) {
    FeedId["NYCTBus"] = "nyct-bus";
    FeedId["NYCTSubway"] = "nyct-subway";
    FeedId["MNR"] = "mnr";
    FeedId["LIRR"] = "lirr";
    FeedId["BT"] = "bridges-tunnels";
    FeedId["OutfrontQA"] = "outfront-qa";
    FeedId["OutfrontDEV"] = "outfront-dev";
})(FeedId || (FeedId = {}));
// MessageType values in the database are in kebab-case, while MessageType
// values from GraphQL (and the MessageType enum, above) are in
// SCREAMING_SNAKE_CASE. This function converts the former to the latter.
export const databaseToGQLEnum = (input) => {
    const output = input.toUpperCase().replace(/-/g, '_');
    if (!Object(MessageType)[output]) {
        throw new Error(`Invalid message type "${input}"`);
    }
    return output;
};
export const MESSAGE_TYPE_DESCRIPTIONS = {
    [MessageType.ESSENTIAL_SERVICE]: 'Essential Service',
    [MessageType.NO_SCHEDULED_SERVICE]: 'No Scheduled Service',
    [MessageType.WEEKEND_SERVICE]: 'Weekend Service',
    [MessageType.WEEKDAY_SERVICE]: 'Weekday Service',
    [MessageType.SUNDAY_SCHEDULE]: 'Sunday Schedule',
    [MessageType.SATURDAY_SCHEDULE]: 'Saturday Schedule',
    [MessageType.HOLIDAY_SERVICE]: 'Holiday Service',
    [MessageType.BUSING]: 'Busing',
    [MessageType.PLANNED_DETOUR]: 'Planned - Detour',
    [MessageType.EXTRA_SERVICE]: 'Extra Service',
    [MessageType.SPECIAL_SCHEDULE]: 'Special Schedule',
    [MessageType.PLANNED_WORK]: 'Planned Work',
    [MessageType.ON_OR_CLOSE]: 'On or Close',
    [MessageType.SLOW_SPEEDS]: 'Slow Speeds',
    [MessageType.SOME_DELAYS]: 'Some Delays',
    [MessageType.SPECIAL_EVENT]: 'Special Event',
    [MessageType.CROWDING]: 'Crowding',
    [MessageType.STATIONS_SKIPPED]: 'Stations Skipped',
    [MessageType.DELAYS]: 'Delays',
    [MessageType.EXPRESS_TO_LOCAL]: 'Express to Local',
    [MessageType.SOME_REROUTES]: 'Some Reroutes',
    [MessageType.LOCAL_TO_EXPRESS]: 'Local to Express',
    [MessageType.DETOURS]: 'Detours',
    [MessageType.SERVICE_CHANGE]: 'Service Change',
    [MessageType.TRAINS_REROUTED]: 'Trains Rerouted',
    [MessageType.PART_SUSPENDED]: 'Part Suspended',
    [MessageType.MULTIPLE_IMPACTS]: 'Multiple Impacts',
    [MessageType.SUSPENDED]: 'Suspended',
    [MessageType.BOARDING_CHANGE]: 'Boarding Change',
    [MessageType.EXPECT_DELAYS]: 'Expect Delays',
    [MessageType.BUSES_DETOURED]: 'Buses Detoured',
    [MessageType.PLANNED_BOARDING_CHANGE]: 'Planned - Boarding Change',
    [MessageType.PLANNED_STATIONS_SKIPPED]: 'Planned - Stations Skipped',
    [MessageType.PLANNED_EXPRESS_TO_LOCAL]: 'Planned - Express to Local',
    [MessageType.PLANNED_LOCAL_TO_EXPRESS]: 'Planned - Local to Express',
    [MessageType.PLANNED_BUSES_DETOURED]: 'Planned - Buses Detoured',
    [MessageType.PLANNED_TRAINS_REROUTED]: 'Planned - Trains Rerouted',
    [MessageType.PLANNED_PART_SUSPENDED]: 'Planned - Part Suspended',
    [MessageType.PLANNED_MULTIPLE_IMPACTS]: 'Planned - Multiple Impacts',
    [MessageType.NO_MIDDAY_SERVICE]: 'No Midday Service',
    [MessageType.NO_OVERNIGHT_SERVICE]: 'No Overnight Service',
    [MessageType.NO_WEEKEND_SERVICE]: 'No Weekend Service',
    [MessageType.STATION_NOTICE]: 'Station Notice',
    [MessageType.PLANNED_SUSPENDED]: 'Planned - Suspended',
    [MessageType.WEEKDAY_SCHEDULE]: 'Weekday Schedule',
    [MessageType.WEEKEND_SCHEDULE]: 'Weekend Schedule',
    [MessageType.REDUCED_SERVICE]: 'Reduced Service',
    [MessageType.SUBSTITUTE_BUSES]: 'Substitute Buses',
    [MessageType.PLANNED_SUBSTITUTE_BUSES]: 'Planned - Substitute Buses',
    [MessageType.MULTIPLE_CHANGES]: 'Multiple Changes',
    [MessageType.PLANNED_MULTIPLE_CHANGES]: 'Planned - Multiple Changes',
    [MessageType.ARRIVAL_INFORMATION_OUTAGE]: 'Arrival Information Outage',
    [MessageType.SHUTTLE_BUSES_DETOURED]: 'Shuttle Buses Detoured',
    [MessageType.INFORMATION_OUTAGE]: 'Information Outage',
    [MessageType.SPECIAL_NOTICE]: 'Special Notice',
    [MessageType.PLANNED_EXTRA_TRANSFER]: 'Planned - Extra Transfer',
    [MessageType.PLANNED_STOPS_SKIPPED]: 'Planned - Stops Skipped',
    [MessageType.PLANNED_REROUTE]: 'Planned - Reroute',
    [MessageType.CANCELLATIONS]: 'Cancellations',
    [MessageType.DELAYS_AND_CANCELLATIONS]: 'Delays and Cancellations ',
    [MessageType.STOPS_SKIPPED]: 'Stops Skipped',
    [MessageType.SEVERE_DELAYS]: 'Severe Delays',
    [MessageType.DETOUR]: 'Detour',
    [MessageType.REROUTE]: 'Reroute',
};
export var MessageTypeCategory;
(function (MessageTypeCategory) {
    MessageTypeCategory["SERVICE_ALERT"] = "SERVICE_ALERT";
    MessageTypeCategory["PLANNED_WORK"] = "PLANNED_WORK";
})(MessageTypeCategory || (MessageTypeCategory = {}));
// Currently active statuses only that new messages are supposed to use
// The order of keys in this object must match the priority of these statuses in mercury-gtfs-realtime.proto
// Ignoring the compiler error here about missing message types because we don't always want to include all message types
// @ts-ignore
export const MESSAGE_TYPE_ASSIGNMENTS = {
    [MessageType.NO_SCHEDULED_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.INFORMATION_OUTAGE]: {
        [FeedId.NYCTSubway]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontDEV]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
    },
    [MessageType.STATION_NOTICE]: {
        [FeedId.NYCTSubway]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontDEV]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
    },
    [MessageType.SPECIAL_NOTICE]: {
        [FeedId.NYCTSubway]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontDEV]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
    },
    [MessageType.WEEKDAY_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.WEEKEND_SCHEDULE]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.SATURDAY_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.SUNDAY_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.EXTRA_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.BOARDING_CHANGE]: {
        [FeedId.NYCTSubway]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontDEV]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
    },
    [MessageType.SPECIAL_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.EXPECT_DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.REDUCED_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_EXPRESS_TO_LOCAL]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_EXTRA_TRANSFER]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_STOPS_SKIPPED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_DETOUR]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.PLANNED_REROUTE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_SUBSTITUTE_BUSES]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.PLANNED_PART_SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.SERVICE_CHANGE]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.PLANNED_WORK]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.SOME_DELAYS]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.EXPRESS_TO_LOCAL]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.CANCELLATIONS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.DELAYS_AND_CANCELLATIONS]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.STOPS_SKIPPED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.SEVERE_DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.DETOUR]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.REROUTE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.SUBSTITUTE_BUSES]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.PART_SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
};
// Archived statuses used for searching cleared messages in UI
// These overwrite the active statuses, so must include historical uses as well
// Only includes statuses that have been deleted or have a status that has been deleted
// @ts-ignore
export const ARCHIVED_MESSAGE_TYPE_ASSIGNMENTS = {
    [MessageType.NO_MIDDAY_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.NO_OVERNIGHT_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.NO_WEEKEND_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.WEEKEND_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.EXTRA_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.SPECIAL_EVENT]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_BOARDING_CHANGE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_WORK]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_STATIONS_SKIPPED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.SLOW_SPEEDS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.PLANNED_LOCAL_TO_EXPRESS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_BUSES_DETOURED]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.PLANNED_TRAINS_REROUTED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.PLANNED_MULTIPLE_CHANGES]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [MessageType.ARRIVAL_INFORMATION_OUTAGE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.SHUTTLE_BUSES_DETOURED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.SOME_DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.SOME_REROUTES]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.LOCAL_TO_EXPRESS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.BUSES_DETOURED]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.SERVICE_CHANGE]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [MessageType.TRAINS_REROUTED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.MULTIPLE_CHANGES]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [MessageType.PLANNED_SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
};
export const getMessageTypesByFeedAndCategory = (feedId, category) => Object.keys(MESSAGE_TYPE_ASSIGNMENTS).filter(k => MESSAGE_TYPE_ASSIGNMENTS[k][feedId].includes(category));
export const getClearedMessageTypesByFeedAndCategory = (feedId, category) => {
    const combinedMessageTypes = {
        ...MESSAGE_TYPE_ASSIGNMENTS,
        ...ARCHIVED_MESSAGE_TYPE_ASSIGNMENTS,
    };
    return Object.keys(combinedMessageTypes).filter(k => combinedMessageTypes[k][feedId].includes(category));
};
