/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export const FormHeaderContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <header
    css={css`
      align-items: center;
      display: inline-flex;
      flex-direction: row;
      margin: 40px 40px 24px 40px;
      position: relative;
    `}
    {...props}
  />
);
