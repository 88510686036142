/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { container } from '../../common/styles';
import { ThemeType } from '../../../theme';
import BaseSearchInput, {
  ISearchInputProps,
} from '../../common/form-elements/search-input';
import { MessageListContainer } from '../alert-dashboard/index.styled';

export { MessageListContainer };

export const Container: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <div css={container} {...props} />;

export const AlertsFilters: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 24px ${theme.spacing.large};
    `}
    {...props}
  />
);

export const SelectContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={() => css`
      flex: 0 1 100%;
    `}
    {...props}
  />
);

export const clearButtonContainer = (theme: ThemeType) => css`
  flex: 0 1 100%;
  padding-left: ${theme.spacing.xsmall};
`;

export const clearButton = () => css`
  padding: 12px 8px;
  min-width: 100%;
`;

export const select = (theme: ThemeType) => css`
  background-color: ${theme.colors.white};
`;

export const leftSpace = (theme: ThemeType) => css`
  padding-left: ${theme.spacing.xsmall};
`;

export const SearchInput: React.FC<ISearchInputProps> = (props) => (
  <BaseSearchInput {...props} />
);

export const SearchContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      flex: 0 1 100%;
      padding: ${theme.spacing.large};
      margin-bottom: -44px;
    `}
    {...props}
  />
);
