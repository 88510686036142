/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ThemeType } from '../../../theme';

export const ScreenCount: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      color: ${theme.colors.dark3};
      padding-left: 12px;
    `}
    {...props}
  />
);
