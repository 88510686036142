import React from 'react';
import {
  components,
  OptionProps,
  PlaceholderProps,
  ValueContainerProps,
  MenuProps,
} from 'react-select';
import {
  getNextGridItemProps,
  ArrowKeys,
  GridItemWidth,
  GridItemProps,
} from 'utils/dynamic-grid-helpers';
import theme from 'theme';

import Select, { SELECT_OVERLAY_STYLES } from '../form-elements/Select';
import Bullet, { BulletSize } from '../Bullet';

// Styles
import * as CS from './common.styled';

// Types
import { EntireRouteOption } from '../../../types';

enum OptionSelectionKeys {
  Space = ' ',
  Enter = 'Enter',
}

interface LineOptionProps {
  option: EntireRouteOption;
  selected: boolean;
  disabled: boolean;
  focused: boolean;
}

const LineOption: React.FC<LineOptionProps> = ({
  option,
  selected,
  disabled,
  focused,
}) => (
  <CS.LineOption
    feedId={option.feedId}
    selected={selected}
    disabled={disabled}
    focused={focused}
  >
    <Bullet
      size={BulletSize.large}
      routeId={option.bulletValue}
      style={{ display: 'block' }}
    />
  </CS.LineOption>
);

const Option = ({
  optionProps: { isSelected, isDisabled, data, ...props },
  optionRefCallback,
  isFocused,
}: {
  optionProps: OptionProps<any>;
  optionRefCallback: any;
  isFocused: boolean;
}) => (
  // @ts-ignore
  <div
    ref={(el) => {
      if (optionRefCallback) {
        optionRefCallback(el);
      }
    }}
    {...props.innerProps}
  >
    <LineOption
      selected={isSelected}
      disabled={!isSelected && isDisabled}
      option={data}
      focused={isFocused}
    />
  </div>
);

const Menu: React.FC<
  {
    menuProps: MenuProps<any>;
    menuRefCallBack: any;
  } & { children?: React.ReactNode }
> = ({ menuProps: { children, ...rest }, menuRefCallBack = null }) => (
  <components.Menu {...rest}>
    <div
      ref={(el: HTMLDivElement) => {
        if (menuRefCallBack) {
          menuRefCallBack(el);
        }
      }}
    >
      {children}
    </div>
  </components.Menu>
);

const selectComponents = {
  Placeholder: (props: PlaceholderProps<any>) => {
    const lineOptions = props.getValue();
    return lineOptions.length ? null : <components.Placeholder {...props} />;
  },
  ValueContainer: (props: ValueContainerProps<any>) => {
    const lineOptions = props.getValue();
    return lineOptions[0] && lineOptions[0].routeId ? (
      <components.ValueContainer {...props}>
        <Bullet
          size={BulletSize.medium}
          routeId={lineOptions[0].routeId}
          style={{ display: 'block', marginLeft: '12px' }}
        />
        {props.children}
      </components.ValueContainer>
    ) : (
      <components.ValueContainer {...props} />
    );
  },
};

type ExtendedRouteOption = EntireRouteOption & {
  index: number;
};

const RouteSelector: React.FC<
  {
    routeId: string;
    selectedRouteOptions: ExtendedRouteOption[];
    allRouteOptions: ExtendedRouteOption[];
    onChange: (choice: ExtendedRouteOption) => void;
    classNamePrefix?: string;
  } & { children?: React.ReactNode }
> = ({
  allRouteOptions,
  selectedRouteOptions,
  routeId,
  onChange,
  classNamePrefix,
}) => {
  const itemsContainerWidth = React.useRef<number>(0);
  const optionsWidths = React.useRef<GridItemWidth[]>([]);

  const [focusedOptionProps, setFocusedOptionProps] =
    React.useState<GridItemProps>({
      index: 0,
      coords: {
        x: 0,
        y: 0,
      },
    });

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  return (
    <Select
      classNamePrefix={classNamePrefix}
      hasControlBorder={false}
      isSearchable={false}
      isClearable={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      placeholder="Lines"
      options={allRouteOptions}
      isOptionDisabled={(option: EntireRouteOption) =>
        !!selectedRouteOptions.find(
          (selectedOptions) => selectedOptions.routeId === option.routeId,
        )
      }
      getOptionValue={(option: EntireRouteOption) => option.key}
      value={allRouteOptions.find(
        (routeOption) => routeOption.routeId === routeId,
      )}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      onBlur={() => {
        optionsWidths.current = [];

        setFocusedOptionProps({
          index: 0,
          coords: {
            x: 0,
            y: 0,
          },
        });
      }}
      onKeyDown={(event: any) => {
        if (Object.values(ArrowKeys).includes(event.key)) {
          event.preventDefault();
          setFocusedOptionProps(
            getNextGridItemProps(
              event.key as ArrowKeys,
              focusedOptionProps,
              optionsWidths.current,
              itemsContainerWidth.current,
            ),
          );
        } else if (Object.values(OptionSelectionKeys).includes(event.key)) {
          event.preventDefault();
          const route = allRouteOptions.find(
            (r) => r.index === focusedOptionProps.index,
          );

          if (route) {
            onChange(route);
          }
        }
      }}
      onChange={(route: ExtendedRouteOption) => {
        onChange(route);
      }}
      components={{
        ...selectComponents,
        Option: (optionProps: OptionProps<any>) =>
          Option({
            optionProps,
            optionRefCallback: (el: HTMLDivElement) => {
              if (
                el?.offsetWidth &&
                !optionsWidths.current.find(
                  (o) => optionProps.data.index === o.index,
                )
              ) {
                optionsWidths.current.push({
                  index: optionProps.data.index,
                  width: el.offsetWidth + 4, // Option margins
                });
              }
            },
            isFocused: optionProps.data.index === focusedOptionProps.index,
          }),
        Menu: (menuProps: MenuProps<any>) =>
          Menu({
            menuProps,
            menuRefCallBack: (el: HTMLDivElement) => {
              if (
                el?.offsetWidth &&
                itemsContainerWidth.current !== el.offsetWidth
              ) {
                itemsContainerWidth.current = el.offsetWidth - 16; // Options container padding
              }
            },
          }),
      }}
      styles={{
        menuList: (provided: {}) => ({
          ...provided,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '8px',
        }),
        option: (provided: {}) => ({
          ...provided,
          background: 'none',
          cursor: 'pointer',
          padding: 0,
          width: 'auto',
        }),
        control: (provided: {}, state: { isFocused: boolean }) => {
          return {
            boxShadow: 'unset',
            borderRadius: '4px 0 0 0',
            ...(state.isFocused
              ? {
                  ...SELECT_OVERLAY_STYLES.control,
                  zIndex: '2',
                }
              : { borderBottom: '1px solid transparent' }),
          };
        },
        menu: (provided: {}) => ({
          ...provided,
          marginTop: '-1px',
          borderRadius: '0 0 4px 4px',
          boxShadow: 'none',
          border: `1px solid ${theme.colors.accent3}`,
          borderTop: '1px solid #EAEAEA',
          zIndex: 100,
          ...SELECT_OVERLAY_STYLES.menu,
        }),
      }}
    />
  );
};

export default RouteSelector;
