/** @jsxImportSource @emotion/react */

import { SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { TagsSelection } from 'types';
import { useFeedId } from 'contexts/FeedId';
import { SUPPORTED_TAGS_AGENCIES } from 'constants/screen-tags';

import {
  ALL_OPTIONS_PREFIX,
  getGroupedOptions,
  getSelectedTagsFromTargetingCriteria,
} from './form-elements/custom-tags-selector/utils';
import { FeatureFlagName } from 'generated/global-types';

const CustomTagsTargetingDisplay: React.FC<{
  tags: TagsSelection;
  extraStyles?: SerializedStyles;
}> = ({ tags, extraStyles }) => {
  const feedId = useFeedId();

  const isCustomTargetingEnabled = useFeatureFlag(
    FeatureFlagName.CUSTOM_TARGETING,
  );

  if (!isCustomTargetingEnabled || !SUPPORTED_TAGS_AGENCIES.includes(feedId)) {
    return null;
  }

  const allTags = getGroupedOptions(feedId)
    .flatMap((o) => o.options)
    .filter((o) => !o.value?.startsWith(ALL_OPTIONS_PREFIX));
  const tagLabels = getSelectedTagsFromTargetingCriteria(tags, feedId).map(
    ({ label }) => label,
  );
  const areAllTagsSelected = tagLabels.length === allTags.length;

  return (
    <span css={extraStyles}>
      {areAllTagsSelected ? 'All Tags' : tagLabels.join(', ')}
    </span>
  );
};

export default CustomTagsTargetingDisplay;
