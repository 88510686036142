/** @jsxImportSource @emotion/react */

import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

import { ThemeType } from '../../theme';

export enum CheckboxSize {
  small = 'small',
  medium = 'medium',
}

const dimensionsForSize = (size: CheckboxSize): string => {
  switch (size) {
    case CheckboxSize.small:
      return '15px';
    default:
      return '20px';
  }
};

const checkIconForSize = (size: CheckboxSize): string => {
  switch (size) {
    case CheckboxSize.small:
      return `
        left: 5px;
        top: 2px;
        width: 5px;
        height: 9px;
        border-width: 0 2px 2px 0;
      `;
    default:
      return `
        left: 6px;
        top: 2px;
        width: 7px;
        height: 13px;
        border-width: 0 2px 2px 0;
      `;
  }
};

interface CheckboxProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  onChange?: (isChecked: boolean) => void;
  size?: CheckboxSize;
  customCheckMarkStyles?: SerializedStyles | null;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  className,
  checked = false,
  size = CheckboxSize.medium,
  onChange = () => {},
  disabled = false,
  customCheckMarkStyles,
}) => (
  <input
    className={className}
    id={id}
    css={(theme: ThemeType) => css`
      appearance: none;
      width: ${dimensionsForSize(size)};
      height: ${dimensionsForSize(size)};
      display: inline-block;
      position: relative;
      border: 1px solid ${theme.colors.border};
      border-radius: 2px;
      cursor: pointer;
      background-color: ${theme.colors.white};
      transition: background-color ease 0.3s;

      &:checked {
        background-color: ${theme.colors.accent1};
        border: none;
      }

      &:checked:after {
        ${customCheckMarkStyles ??
        css`
          content: ' ';
          display: block;
          position: absolute;
          border: solid white;
          transform: rotate(45deg);
          ${checkIconForSize(size)}
        `}
      }

      &:disabled {
        background-color: ${theme.colors.border};
        cursor: default;
      }
    `}
    disabled={disabled}
    checked={checked}
    type="checkbox"
    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.checked)
    }
  />
);

export default Checkbox;
