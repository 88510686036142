/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';

import {
  getLabelFromTargetTypes,
  getScreenSubtitle,
} from 'components/pages/Screen/in-screens-preview/helpers';
import { useFeedId } from 'contexts/FeedId';
import useRoutesWithStops from 'hooks/useRoutesWithStops';
import { RoutesWithStops_routes_RoutesConnection_nodes_Route_stops_OrderedStopsConnection_nodes_OrderedStop as RoutesWithStops_routes_nodes_stops_nodes } from 'generated/RoutesWithStops';
import Heading from '../Heading';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message_screenMessages_ScreenMessagesConnection_nodes_ScreenMessage_targeting_ScreenSelector as GetEventById_event_messages_nodes_screenMessages_nodes_targeting } from '../../../generated/GetEventById';
import { Borough, TargetType } from '../../../generated/global-types';
import Bullet, { BulletSize } from '../Bullet';
import * as US from '../../pages/alert-update/components/update.styled';
import { getVehicleName } from '../../../utils/feed-switches';
import { flexRow, centerRow } from '../styles';
import { BOROUGH_INLINE_LABELS } from '../../../utils/boroughs';
import { RoutesAndStopsById } from '../../../generated/RoutesAndStopsById';
import { FeedId } from '../../../types/feeds';
import { TagsSelection } from '../../../types';
import CustomTagsTargetingDisplay from '../custom-tags-targeting-display';
import Theme from '../../../theme';

type TargetSectionMemberType = {
  name: string;
  screens: string;
  borough?: Borough;
  stopId?: string;
};

type TargetSingleType = {
  routeId: string;
  feedId: FeedId;
  stops: Array<TargetSectionMemberType>;
};

type TargetSectionType = {
  routeIds: string[];
  feedId: FeedId;
  stops: Array<Partial<RoutesWithStops_routes_nodes_stops_nodes> | undefined>;
  screens: any;
  tags: TagsSelection;
};

const TargetSection: React.FC<TargetSectionType> = ({
  routeIds,
  feedId,
  stops,
  screens,
  tags,
}) => (
  <US.Target.Section>
    <span
      css={css`
        flex-wrap: wrap;
        ${centerRow}
      `}
    >
      {routeIds.map((routeId) => (
        <Bullet
          key={routeId}
          size={BulletSize.small}
          routeId={routeId}
          css={{ marginRight: 2, marginBottom: 2 }}
        />
      ))}
      <US.Target.Label css={{ marginLeft: 2 }}>
        {getVehicleName(feedId)}
      </US.Target.Label>
    </span>
    <US.Target.List>
      <US.Target.Label>
        {stops.map((stop) => stop?.name).join(', ')}
      </US.Target.Label>
      <US.Target.Screens>{screens}</US.Target.Screens>
      <CustomTagsTargetingDisplay
        tags={tags}
        extraStyles={css`
          display: block;
          margin-top: 4px;
          ${Theme.typography.sizes.small}
        `}
      />
    </US.Target.List>
  </US.Target.Section>
);

interface ScreenTargetingProps {
  targeting?: GetEventById_event_messages_nodes_screenMessages_nodes_targeting[];
  routesAndStopsData: RoutesAndStopsById;
  headerText?: string;
}

const ScreenTargeting: React.FC<ScreenTargetingProps> = ({
  targeting,
  routesAndStopsData,
  headerText = 'Displays At',
}) => {
  const feedId = useFeedId();
  const allRoutesWithStops = useRoutesWithStops(feedId);

  if (!targeting) {
    return null;
  }

  const sortedTargeting = targeting
    ? [...targeting].sort((a, b) => {
        const route = allRoutesWithStops.find(
          (route) => route.gtfsId === a.routeId,
        );
        return (
          (route?.stops.nodes.findIndex(
            (stop) => stop.gtfsId === a.entitySelector.stopId,
          ) ?? -1) -
          (route?.stops.nodes.findIndex(
            (stop) => stop.gtfsId === b.entitySelector.stopId,
          ) ?? -1)
        );
      })
    : [];

  const routeIds = sortedTargeting.reduce<string[]>((agg, cur) => {
    if (!agg.find((id) => id === cur.routeId)) {
      return [...agg, cur.routeId];
    }
    return agg;
  }, []);
  const { routes: searchRoutes, stops: searchStops } = routesAndStopsData;

  if (!searchRoutes) {
    return null;
  }

  const screenNameForType = (screenTypes: TargetType[] = []): string => {
    if (!screenTypes.length) {
      return 'Screens';
    }

    return getLabelFromTargetTypes(screenTypes, feedId);
  };

  const selectedBoroughs = new Set(sortedTargeting.map((t) => t.borough));
  const selectedRoutes = new Set(sortedTargeting.map((t) => t.routeId));
  const targetSingles = routeIds.reduce<Array<TargetSingleType>>(
    (agg, routeId) => {
      const route = searchRoutes.nodes.find((r) => r.gtfsId === routeId);
      if (!route || (!route.shortName && !route.longName)) {
        return agg;
      }

      let stops = sortedTargeting.reduce<Array<TargetSectionMemberType>>(
        (stopsAggregator, target) => {
          if (target.entitySelector.stopId && target.routeId === routeId) {
            if (
              target.borough &&
              !stopsAggregator.find((m) => m.borough === target.borough)
            ) {
              return [
                ...stopsAggregator,
                {
                  name: `All ${BOROUGH_INLINE_LABELS[target.borough]} Stations`,
                  screens: screenNameForType(target.layouts),
                  borough: target.borough,
                  stopId: target.entitySelector.stopId,
                },
              ];
            }
            if (
              target.borough &&
              !stopsAggregator.find((m) => m.borough === target.borough)
            ) {
              const stop =
                searchStops &&
                searchStops.nodes.find(
                  (s) => s.gtfsId === target.entitySelector.stopId,
                );

              if (stop && stop.name) {
                return [
                  ...stopsAggregator,
                  {
                    name: stop.name,
                    screens: screenNameForType(target.layouts),
                    stopId: target.entitySelector.stopId,
                  },
                ];
              }
            }

            if (!target.borough) {
              const stop =
                searchStops &&
                searchStops.nodes.find(
                  (s) => s.gtfsId === target.entitySelector.stopId,
                );

              if (stop && stop.name) {
                return [
                  ...stopsAggregator,
                  {
                    name: stop.name,
                    screens: screenNameForType(target.layouts),
                    stopId: target.entitySelector.stopId,
                  },
                ];
              }
            }
          }

          return stopsAggregator;
        },
        [],
      );

      // If no stops, we're targeting the entire route, so add a member to represent those screens
      if (
        stops.length === 0 &&
        selectedBoroughs.size === 1 &&
        selectedRoutes.size === 1
      ) {
        const routeTarget = sortedTargeting.find((t) => t.routeId === routeId);
        if (routeTarget) {
          stops = [
            {
              name: 'All Stations',
              screens: screenNameForType(routeTarget.layouts),
            },
          ];
        }
      }
      return [
        ...agg,
        {
          routeId,
          feedId: route.feedId as FeedId,
          stops,
        },
      ];
    },
    [],
  );

  const groupedSections: any = groupBy(targetSingles, (target) => {
    const names = target.stops.map((stop) => stop.borough ?? stop.name);
    const targetTypes = uniq(target.stops.map((stop) => stop.screens));
    return `${targetTypes.join(',')}-${names.join(',')}`;
  });

  const tempRouteIds: string[] = [];
  Object.keys(groupedSections).forEach((key) => {
    // sections.push(groupedSections[key] as);
    tempRouteIds.push(...groupedSections[key].map((target) => target.routeId));
  });

  const { screens } = targeting.reduce<{
    screens: TargetType[];
  }>(
    (selections, selector) => {
      selections.screens.push(...selector.layouts);
      return selections;
    },
    {
      screens: [],
    },
  );

  const combinedTags = targeting.reduce<TagsSelection>(
    (agg, cur) => ({
      ...agg,
      ...cur.tags,
    }),
    {},
  );

  return (
    <div>
      <div css={[flexRow]}>
        <Heading
          css={css`
            margin-bottom: 8px;
          `}
          level={4}
        >
          {headerText}
        </Heading>
      </div>

      {Object.keys(groupedSections).map((key) => {
        return (
          <TargetSection
            key={key}
            routeIds={groupedSections[key].map((s) => s.routeId)}
            feedId={feedId}
            stops={groupedSections[key][0].stops}
            screens={getScreenSubtitle({ targetTypes: uniq(screens) })}
            tags={combinedTags}
          />
        );
      })}
    </div>
  );
};

export default ScreenTargeting;
