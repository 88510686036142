/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';

import theme, { ThemeType } from 'theme';
import idGen from 'utils/id-gen';
import Modal from 'components/common/modal';
import { useUpload, UploadType } from 'hooks/useUpload';
import { ReactComponent as CrossIcon } from 'images/cross.svg';
import ProgressBar from 'ui-kit/ProgressBar';
import {
  AttachmentsType,
  UploadFileContainer,
} from 'types/twitter-attachments';
import { validateFileDimensions } from '../../../../utils/validations';
import AttachAssetFromLibraryModal from './attach-asset-from-library';

const ALLOWED_FILE_TYPES = '.jpg,.jpeg,.png,image/jpeg,image/png,video/mp4';

interface Props {
  attachments: AttachmentsType;
  onChange: (as: AttachmentsType) => void;
}

const MAX_SIZE = 5 * 1000000; // 5MB in bytes

const TWITTER_IMG_MIN_WIDTH = 4;
const TWITTER_IMG_MAX_WIDTH = 8192;

const TWITTER_IMG_MIN_HEIGHT = 4;
const TWITTER_IMG_MAX_HEIGHT = 8192;

const attachmentContainer = css`
  height: 115px;
  width: 200px;
  margin-right: 8px;
  border-radius: 4px;
  overflow: hidden;

  position: relative;
`;

export const isUploadFileContainer = (
  attachment: any,
): attachment is UploadFileContainer => attachment.file instanceof File;

const AttachmentUploader = ({
  uploadFileContainer,
  onUploadComplete,
  onRemoveAttachment,
}: {
  uploadFileContainer: UploadFileContainer;
  onUploadComplete: (upload: UploadType) => void;
  onRemoveAttachment: () => void;
}) => {
  const { startUpload, uploading, progress, upload, error } = useUpload({
    defaultUpload: null,
  });

  const { file } = uploadFileContainer;

  useEffect(() => {
    if (!uploading && !upload?.id && file) {
      startUpload(file);
    }
  }, [uploading, upload, file, startUpload]);

  useEffect(() => {
    if (file instanceof File && upload?.id) {
      onUploadComplete(upload);
    }
  }, [upload, file, onUploadComplete]);

  useEffect(() => {
    if (file instanceof File && upload?.id) {
      onUploadComplete(upload);
    }
  }, [upload, file, onUploadComplete]);

  useEffect(() => {
    if (error) {
      onRemoveAttachment();
    }
  }, [error, onRemoveAttachment]);

  return (
    <div
      css={css`
        ${attachmentContainer};

        background-color: ${theme.colors['background-inset']};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          font-size: ${theme.typography.sizes.xsmall};
          font-weight: 800;
          margin-bottom: 8px;
        `}
      >
        UPLOADING...
      </div>
      <ProgressBar
        progress={progress}
        additionalStyles={css`
          margin-left: 0;
        `}
      />
    </div>
  );
};

const UploadPreview = ({
  upload,
  onRemoveAttachment,
}: {
  upload: UploadType;
  onRemoveAttachment: () => void;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return upload?.signedS3Url ? (
    <React.Fragment>
      <Modal
        title="View Image"
        onDismiss={() => setModalIsOpen(false)}
        isOpen={modalIsOpen}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <img
            aria-label="Preview of twitter image attachment"
            alt=""
            src={upload.signedS3Url}
            css={css`
              max-height: 100%;
              max-width: 100%;
            `}
          />
        </div>
      </Modal>
      <div css={attachmentContainer}>
        <button
          aria-label="Open Attachment"
          type="button"
          onClick={() => setModalIsOpen(true)}
          css={css`
            box-sizing: border-box;
            background-image: url(${upload.signedS3Url});
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          `}
        />
        <div
          css={css`
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            background-color: rgba(0, 8, 22, 0.8);
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px 12px;
            overflow: hidden;
          `}
        >
          <div
            css={(theme: ThemeType) => css`
              ${theme.typography.sizes.small};
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {upload?.originalFilename}
          </div>
          <button
            aria-label="Remove Attachment"
            css={css`
              box-sizing: border-box;
              cursor: pointer;
              height: 10px;
              width: 10px;
              margin: 0;
              padding: 0;
              background: none;
              border: none;
              stroke: white;
            `}
            type="button"
            onClick={() => onRemoveAttachment()}
          >
            <CrossIcon height={10} width={10} />
          </button>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

const ImageAttachments: React.FC<Props> = ({ attachments, onChange }) => {
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const id = useMemo(() => idGen('image_attachments__file_upload_input__'), []);

  const onAddAttachment = async (a: File) => {
    const wrongDimensions = !(await validateFileDimensions(a, {
      minHeight: TWITTER_IMG_MIN_HEIGHT,
      minWidth: TWITTER_IMG_MIN_WIDTH,
      maxHeight: TWITTER_IMG_MAX_HEIGHT,
      maxWidth: TWITTER_IMG_MAX_WIDTH,
    }));

    if (a.size > MAX_SIZE) {
      window.alert(`${a.name} is too large. File may be no larger than 5MB`);
    } else if (wrongDimensions) {
      window.alert(
        `File does not have the right dimensions. (Min: ${TWITTER_IMG_MIN_WIDTH}x${TWITTER_IMG_MIN_HEIGHT}, Max: ${TWITTER_IMG_MAX_WIDTH}x${TWITTER_IMG_MAX_HEIGHT})`,
      );
    } else if (attachments.length < 4) {
      onChange([
        ...attachments,
        {
          file: a,
          fileKey: `added-on-${new Date()}`,
        },
      ]);
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    evt,
  ) => {
    const fileEvent = evt;
    const file: File | null = fileEvent.target?.files?.[0] ?? null;
    if (file) {
      onAddAttachment(file);
      // HTML file inputs cannot be controlled, this is to reset the file input
      // in case the user would like to upload the same file more than once
      fileEvent.target.value = '';
    }
  };

  const attachmentCommonCss = {
    containerLabel: (theme: ThemeType) => css`
      box-sizing: border-box;
      height: 115px;
      width: 115px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      ${theme.typography.sizes.xsmall};
      color: ${theme.colors.accent1};
      font-weight: ${theme.typography.weights.bolder};
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      padding: 10px;
      border: 1px solid ${theme.colors.accent1};
      border-radius: 4px;
      position: relative;

      &:focus-within {
        box-shadow: 0 0 2px ${theme.colors.accent1};
      }
    `,
    textLabel: (theme: ThemeType) => css`
      ${theme.typography.sizes.xsmall};
      color: ${theme.colors.accent1};
      font-weight: ${theme.typography.weights.bolder};
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      cursor: pointer;
      margin-right: 12px;
      border-radius: 4px;

      &:focus-within {
        text-shadow: 0 0 2px ${theme.colors.accent1};
      }
    `,
    input: css`
      cursor: pointer;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    `,
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
      `}
    >
      {attachments.length === 0 && (
        <React.Fragment>
          <label css={attachmentCommonCss.textLabel}>
            Attach From Library
            <input
              id={id}
              css={attachmentCommonCss.input}
              onClick={() => setIsAttachmentModalOpen(true)}
            />
          </label>
          <label css={attachmentCommonCss.textLabel}>
            Upload From Computer
            <input
              id={id}
              css={attachmentCommonCss.input}
              type="file"
              multiple={false}
              accept={ALLOWED_FILE_TYPES}
              onChange={handleInputChange}
            />
          </label>
          <label
            htmlFor={id}
            css={(theme: ThemeType) => css`
              ${theme.typography.sizes.small};
              color: #707070;
              padding: 0;
            `}
          >
            Supported Media Files: PNG, JPEG, MP4 (5MB max)
          </label>
        </React.Fragment>
      )}

      {attachments.map((a, attachmentIndex) => {
        const onRemoveAttachment = () => {
          onChange(attachments.filter((_, idx) => idx !== attachmentIndex));
        };

        if (isUploadFileContainer(a)) {
          return (
            <AttachmentUploader
              key={a.fileKey}
              onRemoveAttachment={onRemoveAttachment}
              onUploadComplete={(upload) => {
                onChange([
                  ...attachments.slice(0, attachmentIndex),
                  upload,
                  ...attachments.slice(attachmentIndex + 1),
                ]);
              }}
              uploadFileContainer={a}
            />
          );
        }

        const attachmentKey = `attachment-${attachmentIndex}-${a.id}`;

        return (
          <UploadPreview
            key={attachmentKey}
            upload={a}
            onRemoveAttachment={onRemoveAttachment}
          />
        );
      })}

      {attachments.length > 0 && attachments.length < 4 && (
        <React.Fragment>
          <label css={attachmentCommonCss.containerLabel}>
            <div
              css={(theme: ThemeType) => css`
                ${theme.typography.sizes.xlarge};
              `}
            >
              +
            </div>
            <div>Attach From Library</div>
            <input
              css={attachmentCommonCss.input}
              onClick={() => setIsAttachmentModalOpen(true)}
            />
          </label>
          <label css={attachmentCommonCss.containerLabel}>
            <div
              css={(theme: ThemeType) => css`
                ${theme.typography.sizes.xlarge};
              `}
            >
              +
            </div>
            <div>Upload From Computer</div>
            <input
              css={attachmentCommonCss.input}
              type="file"
              multiple={false}
              accept={ALLOWED_FILE_TYPES}
              onChange={handleInputChange}
            />
          </label>
        </React.Fragment>
      )}
      <AttachAssetFromLibraryModal
        attachments={attachments}
        isOpen={isAttachmentModalOpen}
        onDismiss={() => setIsAttachmentModalOpen(false)}
        onAttachAssets={onChange}
      />
    </div>
  );
};

export default ImageAttachments;
