import { css } from '@emotion/react';
import theme from '../../theme';

export const container = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`;

export const pageContent = css`
  display: flex;
  padding: ${theme.spacing.large};
  flex: 1 1 100%;
`;

export const contentPadding = css`
  padding: ${theme.spacing.large};
`;

export const whiteBorderBox = css`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.border};
  border-radius: 6px;
`;

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;

export const centerRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const flexCol = css`
  display: flex;
  flex-direction: column;
`;

export const input = [
  theme.typography.sizes.medium,
  css`
    display: flex;
    width: 100%;
    height: 100%;
    border: 0;
    resize: none;
    font-family: ${theme.typography.families.primary};
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 4px;

    box-shadow: inset 0 0 4px ${theme.colors['border-dark']};

    &:focus {
      outline: none;
      border: 1px solid ${theme.colors.accent3};
    }
  `,
];

export const filterInput = css`
  ${input};

  box-sizing: border-box;
  padding: ${theme.spacing.xsmall} ${theme.spacing.small};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors['border-dark']};
  border-radius: 4px;
  box-shadow: 0 0 2px 0 ${theme.colors['border-dark']} inset;
  color: ${theme.colors.accent6};
  font-weight: ${theme.typography.weights.normal};
  ${theme.typography.sizes.medium};
  margin-bottom: ${theme.spacing.xsmall};

  &:focus {
    box-shadow: 0 0 0 2px ${theme.colors.accent1};
  }

  &::placeholder {
    color: ${theme.colors.dark3};
    opacity: 1;
  }
`;

export const listboxList = css`
  border: 1px solid ${theme.colors.accent3};
  border-top: 1px solid #eaeaea;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fff;
  padding: 0;
  list-style: none;
  margin: 0;
  max-height: 10rem;
  overflow-y: auto;
  outline: none;

  li:first-of-type {
    margin-top: 8px;
  }

  box-shadow: inset 0 0 4px ${theme.colors['border-dark']};

  [data-reach-listbox-option] {
    ${theme.typography.sizes.small};
    display: block;
    white-space: nowrap;
    cursor: pointer;
    padding: 8px 12px;
  }

  [data-reach-listbox-option][aria-selected='true'] {
    background-color: ${theme.colors['select-option-hover']};
  }
`;

export const listboxOption = css`
  &:hover {
    background-color: ${theme.colors['select-option-hover']};
  }
`;
