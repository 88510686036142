/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';

import pluralize from 'pluralize';

import { CSSObjectWithLabel } from 'react-select';
import useScreenLayoutCounts from 'hooks/use-screen-layout-counts';
import { TargetType } from 'generated/global-types';
import { RouteMention, TagsSelection } from 'types';
import {
  getLabelFromTargetTypes,
  getLabelFromTargetType,
  ALL_CIC,
} from 'components/pages/Screen/in-screens-preview/helpers';
import Button from 'components/common/Button';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import MultiSelect, { MultiSelectOption } from 'ui-kit/multi-select';
import { useFeedId, FeedId } from 'contexts/FeedId';

import {
  SingleScreenIcon,
  LeftScreenIcon,
  RightScreenIcon,
  SingleLandscapeScreenIcon,
  TripleScreenIcon,
} from 'components/common/screens-icons';

import { groupedPIOs, groupedMKTs, groupedCICs } from 'constants/screens';
import {
  BaseSelector,
  IEntitySelector,
  emptySelector,
} from '../multi-route-stop-selector';
import CustomTagsSelector from '../custom-tags-selector';
import { isRailRoad } from 'utils/feed-switches';

export interface IScreenSelector {
  selector: IEntitySelector;
  targetTypes: TargetType[];
  tags?: TagsSelection;
}

const getScreenIcon = (targetType: TargetType): React.ReactNode => {
  switch (targetType) {
    case TargetType.SINGLE_CIC:
    case TargetType.SINGLE_AD_PORTRAIT:
    case TargetType.SINGLE_INFO_PORTRAIT:
    case TargetType.SOLARI_PORTRAIT:
    case ALL_CIC:
      return <SingleScreenIcon />;
    case TargetType.DUO_CIC_LEFT:
      return <LeftScreenIcon />;
    case TargetType.DUO_CIC_RIGHT:
      return <RightScreenIcon />;
    case TargetType.TRIPTYCH_AD:
      return <TripleScreenIcon />;
    default:
      return <SingleLandscapeScreenIcon />;
  }
};

const changeSelector = (
  screenSelector: IScreenSelector,
  selector: IEntitySelector,
) => ({
  ...screenSelector,
  selector,
});
const changeTargetTypes = (
  screenSelector: IScreenSelector,
  targetTypes: TargetType[],
) => ({
  ...screenSelector,
  targetTypes,
});

export const getAllowedTargetTypes = (feedId: FeedId): TargetType[] => {
  if (feedId === FeedId.LIRR) {
    return [
      ALL_CIC,
      TargetType.SOLARI_PORTRAIT,
      TargetType.SINGLE_MKT_LANDSCAPE,
      TargetType.SINGLE_PIO_LANDSCAPE,
      TargetType.SINGLE_STA_LANDSCAPE,
      TargetType.SINGLE_AD_LANDSCAPE,
      TargetType.TRIPTYCH_AD,
    ];
  }

  if (feedId === FeedId.MNR) {
    return [
      ALL_CIC,
      TargetType.SOLARI_PORTRAIT,
      TargetType.SINGLE_AD_PORTRAIT,
      TargetType.SINGLE_AD_LANDSCAPE,
      TargetType.TRIPTYCH_AD,
      TargetType.SINGLE_INFO_PORTRAIT,
      TargetType.SINGLE_INFO_LANDSCAPE,
      TargetType.OTHER_MARKETING_LANDSCAPE,
    ];
  }

  return [
    ALL_CIC,
    TargetType.SOLARI_PORTRAIT,
    TargetType.SINGLE_AD_PORTRAIT,
    TargetType.TRIPTYCH_AD,
  ];
};

export const LANDSCAPE_TARGET_TYPES = [
  TargetType.SINGLE_INFO_LANDSCAPE,
  TargetType.SINGLE_MKT_LANDSCAPE,
  TargetType.SINGLE_PIO_LANDSCAPE,
  TargetType.SINGLE_STA_LANDSCAPE,
  TargetType.SINGLE_AD_LANDSCAPE,
  TargetType.OTHER_MARKETING_LANDSCAPE,
];
export const PORTRAIT_TARGET_TYPES = [
  TargetType.SINGLE_INFO_PORTRAIT,
  TargetType.SINGLE_AD_PORTRAIT,
  TargetType.SINGLE_CIC,
  TargetType.DUO_CIC_LEFT,
  TargetType.DUO_CIC_RIGHT,
  TargetType.SINGLE_AD_PORTRAIT,
  TargetType.TRIPTYCH_AD,
  TargetType.SOLARI_PORTRAIT,
];

function chop<T>(arr: T[], idx: number): [T[], T[]] {
  return [arr.slice(0, idx), arr.slice(idx)];
}

const Selector: React.FC<
  {
    hideLabels: boolean;
    removeable: boolean;
    disabledRoutes: RouteMention[];
    screenSelector: IScreenSelector;
    isSingleRoute?: boolean;
    isEditing?: boolean;
    onRemove: () => void;
    onChange: (screenSelector: IScreenSelector) => void;
    enableSelectAllAction?: boolean;
    areAllSelected?: boolean;
    noLinesSelected?: boolean;
    onSelectAll?: () => void;
    onUnselectAll?: () => void;
  } & { children?: React.ReactNode }
> = ({
  hideLabels,
  removeable,
  disabledRoutes,
  screenSelector,
  onChange,
  onRemove,
  isSingleRoute = false,
  isEditing = false,
  enableSelectAllAction = false,
  areAllSelected = false,
  noLinesSelected = false,
  onSelectAll,
  onUnselectAll,
}) => {
  const feedId = useFeedId();
  const screenSelectorDisabled = screenSelector.selector.routes.length <= 0;

  const screenSelectorRoutes = screenSelector?.selector?.routes?.filter(
    (r) => !r.hasAllStopsUnselected,
  );
  const screenSelectorStops = screenSelector?.selector?.stops;
  const stopIds =
    screenSelectorRoutes.length === 1
      ? screenSelectorStops.map((stops) => stops.stopId)
      : [];

  const routeIds = stopIds.length
    ? []
    : screenSelectorRoutes.map((routes) => routes.routeId);

  const { screenLayoutCounts: screenLayoutsCounts } = useScreenLayoutCounts({
    routeIds,
    stopIds,
  });

  const screenCountByType = useCallback(
    (screenType: TargetType) => {
      let targetType = screenType;
      if (groupedPIOs.includes(screenType)) {
        targetType = TargetType.SOLARI_LANDSCAPE;
      } else if (groupedMKTs.includes(screenType)) {
        targetType = TargetType.OTHER_MARKETING_LANDSCAPE;
      }

      return (
        screenLayoutsCounts.find((counts) => counts.targetType === targetType)
          ?.totalCount || 0
      );
    },
    [screenLayoutsCounts],
  );

  const allowedTargetTypes = getAllowedTargetTypes(feedId);
  const screenOptions = allowedTargetTypes.map((tt) => ({
    value: tt,
    label: getLabelFromTargetType(tt, feedId),
    description:
      screenCountByType(tt) !== null &&
      pluralize('Screen', screenCountByType(tt), true),
    icon: getScreenIcon(tt),
  }));

  const renderValueContainer = useCallback(
    (options, { OptionText }) => {
      const values: TargetType[] = options.map(
        (o: MultiSelectOption) => o.value,
      );
      const isAllTargetTypes = allowedTargetTypes.every((tt) =>
        values.includes(tt),
      );

      const computedScreenLayoutsCounts = screenLayoutsCounts
        ? screenLayoutsCounts
            .map((screenLayout) => {
              let { targetType, totalCount } = screenLayout;
              if (groupedPIOs.includes(screenLayout?.targetType)) {
                targetType = TargetType.SINGLE_PIO_LANDSCAPE;
                totalCount = screenCountByType(screenLayout?.targetType);
              } else if (groupedMKTs.includes(screenLayout?.targetType)) {
                targetType = TargetType.SINGLE_MKT_LANDSCAPE;
                totalCount = screenCountByType(screenLayout?.targetType);
              }

              return screenLayout?.targetType && values.includes(targetType)
                ? totalCount
                : 0;
            })
            .reduce((a, b) => Number(a) + Number(b), 0)
        : 0;

      const screensCountText =
        computedScreenLayoutsCounts !== null &&
        pluralize('Screen', computedScreenLayoutsCounts, true);

      if (isAllTargetTypes && feedId !== FeedId.LIRR) {
        return (
          <OptionText
            description={screensCountText}
            color={screenSelectorDisabled ? 'light3' : 'accent1'}
          >
            All CIC and Single ADV Screens
          </OptionText>
        );
      }

      const [show, others] = chop(values, 3);

      return (
        <OptionText
          description={screensCountText}
          color={screenSelectorDisabled ? 'light3' : 'accent1'}
        >
          {getLabelFromTargetTypes(show, feedId)}
          {others.length > 0 && <span>, +{others.length} more</span>}
        </OptionText>
      );
    },
    [
      screenSelectorDisabled,
      screenLayoutsCounts,
      feedId,
      allowedTargetTypes,
      screenCountByType,
    ],
  ) as React.ComponentProps<typeof MultiSelect>['renderValueContainer'];

  const selectedTargetTypes = [
    ...screenSelector.targetTypes.filter((t) => !groupedCICs.includes(t)),
    ...(groupedCICs.every((t) => screenSelector.targetTypes.includes(t))
      ? [ALL_CIC]
      : []),
  ];

  return (
    <BaseSelector
      removeable={removeable}
      hideLabels={hideLabels}
      disabledRoutes={disabledRoutes}
      selector={screenSelector.selector}
      onRemove={onRemove}
      showScreensAvailable
      onChange={(selector) =>
        onChange(changeSelector(screenSelector, selector))
      }
      headerText="Select Station & Screen"
      isSingleRoute={isSingleRoute}
      isEditing={isEditing}
      enableSelectAllAction={enableSelectAllAction}
      areAllSelected={areAllSelected}
      noLinesSelected={noLinesSelected}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
    >
      <MultiSelect
        disabled={screenSelectorDisabled}
        placeholder="Screen types"
        renderValueContainer={renderValueContainer}
        value={selectedTargetTypes}
        options={screenOptions}
        onChange={(targetTypes: TargetType[]) => {
          onChange(changeTargetTypes(screenSelector, targetTypes));
        }}
        styles={{
          valueContainer: (provided: CSSObjectWithLabel) => {
            return {
              ...provided,
              display: 'flex',
            };
          },
          control: (
            provided: {},
            state: { menuIsOpen: boolean; isFocused: boolean },
          ) => {
            return {
              borderRadius: `0 0 ${state.menuIsOpen ? '0 0' : '4px 4px'}`,
              boxShadow: 'unset',
              ...(state.isFocused
                ? {
                    zIndex: '2',
                  }
                : {
                    borderTop: '1px solid #dddddd',
                  }),
            };
          },
        }}
      />
      <CustomTagsSelector
        value={screenSelector.tags}
        onChange={(tags) => onChange({ ...screenSelector, tags })}
      />
    </BaseSelector>
  );
};

export const pwDefaultTargetTypes = (feedId: FeedId) => {
  if (feedId === FeedId.NYCTSubway) {
    return getAllowedTargetTypes(feedId);
  }

  if (isRailRoad(feedId)) {
    return [
      ALL_CIC,
      TargetType.SINGLE_PIO_LANDSCAPE,
      TargetType.SINGLE_CIC,
      TargetType.SOLARI_PORTRAIT,
      TargetType.SINGLE_STA_LANDSCAPE,
    ];
  }

  return [];
};

const emptyScreenSelector = (
  feedId: FeedId,
  autofillScreenTargeting = true,
): IScreenSelector => ({
  ...emptySelector(),
  targetTypes: autofillScreenTargeting ? pwDefaultTargetTypes(feedId) : [],
});

const addEmptyScreenSelector = (
  feedId: FeedId,
  screenSelectors: IScreenSelector[],
  autofillScreenTargeting?: boolean,
) => [...screenSelectors, emptyScreenSelector(feedId, autofillScreenTargeting)];

type Props = {
  value: IScreenSelector[];
  onChange: (screenSelectors: IScreenSelector[]) => void;
  className?: string | undefined;
  addText?: string;
  isSingleRoute?: boolean;
  autofillScreenTargeting?: boolean;
  isEditing?: boolean;
  enableSelectAllAction?: boolean;
  areAllSelected?: boolean;
  noLinesSelected?: boolean;
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
};

const MultiRouteStopScreenSelector: React.FC<Props> = ({
  value,
  className,
  onChange,
  addText = 'Add Screens',
  isSingleRoute = false,
  autofillScreenTargeting = true,
  isEditing = false,
  enableSelectAllAction = false,
  areAllSelected = false,
  noLinesSelected = false,
  onSelectAll = () => {},
  onUnselectAll = () => {},
}) => {
  const feedId = useFeedId();
  const [numRemoved, setNumRemoved] = useState(0);
  const areRemovable = value.length > 1;

  const selectorEls = value.map((screenSelector, idx) => {
    const key = `${idx}-${numRemoved}`;
    const areLabelsHidden = idx > 0;

    const otherSelectors: IScreenSelector[] = value.filter((_, i) => i !== idx);
    const disabledRoutes: RouteMention[] = otherSelectors.flatMap(
      (s) => s.selector.routes,
    );

    otherSelectors.forEach((s) => {
      s.selector.routes.forEach((r) => {
        if (!disabledRoutes.find((dr) => dr.routeId === r.routeId)) {
          disabledRoutes.push(r);
        }
      });
    });

    return (
      <Selector
        key={key}
        hideLabels={areLabelsHidden}
        removeable={areRemovable}
        disabledRoutes={disabledRoutes}
        screenSelector={screenSelector}
        isSingleRoute={isSingleRoute}
        isEditing={isEditing}
        onChange={(n) =>
          onChange(value.map((existing, jdx) => (jdx === idx ? n : existing)))
        }
        onRemove={() => {
          setNumRemoved(numRemoved + 1);
          onChange(value.filter((_, jdx) => idx !== jdx));
        }}
        enableSelectAllAction={enableSelectAllAction}
        areAllSelected={areAllSelected}
        noLinesSelected={noLinesSelected}
        onSelectAll={onSelectAll}
        onUnselectAll={onUnselectAll}
      />
    );
  });
  return (
    <div className={className}>
      {selectorEls}
      <Button
        plain
        type="button"
        onClick={() => {
          onChange(
            addEmptyScreenSelector(feedId, value, autofillScreenTargeting),
          );
        }}
      >
        <PlusIcon /> {addText}
      </Button>
    </div>
  );
};

export default MultiRouteStopScreenSelector;
