/** @jsxImportSource @emotion/react */

import React from 'react';

import { CampaignStatus } from 'generated/global-types';

import CampaignPage from '../../common/campaign-page';

const CampaignDashboard: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  return (
    <CampaignPage
      pageTitle="Campaigns"
      excludedStatus={CampaignStatus.CLEARED}
    />
  );
};

export default CampaignDashboard;
