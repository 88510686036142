import { css } from '@emotion/react';
import theme from '../../../theme';

export const filters = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.small};
  margin-top: ${theme.spacing.small};
  > div:not(:first-of-type) {
    margin-left: 1rem;
  }
`;

export const selectContainer = css`
  flex: 1 1;
`;

export const select = css`
  background-color: ${theme.colors.white};
`;

export const button = css`
  padding: 12px 8px;
  width: 211px;
`;

export const datePicker = css`
  border: 1px solid ${theme.colors['border-dark']};
  box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
  border-radius: 4px;
  height: 44px;
  font-size: 1rem;

  &:focus {
    box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
  }
`;
