/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  OptionProps,
  ValueContainerProps,
  components,
  PlaceholderProps,
} from 'react-select';
import pluralize from 'pluralize';
import _uniqBy from 'lodash/uniqBy';
import _isEqual from 'lodash/isEqual';

import { SUPPORTED_TAGS_AGENCIES } from 'constants/screen-tags';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import theme from 'theme';
import { TagsSelection } from 'types';
import { useFeedId } from 'contexts/FeedId';
import { Option } from 'components/common/ScreenSelector/common';
import Select from 'components/common/form-elements/Select';
import {
  getTargetingCriteriaFromOptions,
  ALL_OPTIONS_PREFIX,
  getGroupedOptions,
  getSelectedTagsFromTargetingCriteria,
  TargetingOption,
} from './utils';
import { FeatureFlagName } from 'generated/global-types';

const selectComponents = {
  Option: (props: OptionProps<any>) => {
    const option = props.data;

    return (
      <div {...props.innerProps}>
        <Option
          color="black"
          isSelected={props.isSelected}
          option={option}
          style={props.getStyles('option', props)}
        />
      </div>
    );
  },
  ValueContainer: (props: ValueContainerProps<any>) => {
    const options: string[] = props
      .getValue()
      .filter((o: any) => !o.value.startsWith(ALL_OPTIONS_PREFIX))
      .map((o: any) => o.label);
    const flattenedSelectedOptions = (props?.options ?? [])
      ?.flatMap((o) => o?.options ?? [])
      ?.filter((o) => !o?.value?.startsWith(ALL_OPTIONS_PREFIX));
    const areAllOptionsSelected =
      options.length === flattenedSelectedOptions.length;

    return (
      <components.ValueContainer {...props}>
        <div
          css={(theme) => css`
            display: flex;
            width: 99%;
            justify-content: space-between;
            align-items: baseline;
            color: ${theme.colors.accent1};
          `}
        >
          <div
            css={css`
              padding-left: 12px;
            `}
          >
            {options.length <= 3
              ? `${options.join(', ')}`
              : `${options.slice(0, 3).join(', ')}${
                  options.length > 3 ? ` +${options.length - 3} more` : ''
                }`}
          </div>
          <div
            css={css`
              margin-left: 16px;
              color: #707070;
              ${theme.typography.sizes.small};
            `}
          >
            {areAllOptionsSelected
              ? 'All Selected'
              : pluralize('Tag', options.length, true)}
          </div>
        </div>
        {props.children}
      </components.ValueContainer>
    );
  },
  Placeholder: (props: PlaceholderProps<any>) => {
    const selectedOptions = props.getValue();
    return selectedOptions.length ? null : (
      <components.Placeholder {...props} />
    );
  },
};

interface TagSelection {
  label: string;
  value: string;
  tagName: string;
}

interface CustomTagsSelectorProps {
  value: TagsSelection | undefined;
  classNamePrefix?: string;
  onChange: (tagsCriteria: { [key: string]: string[] } | undefined) => void;
}

const CustomTagsSelector: React.FC<CustomTagsSelectorProps> = ({
  value,
  classNamePrefix,
  onChange,
}) => {
  const feedId = useFeedId();
  const allGroupedOptions = useMemo(() => getGroupedOptions(feedId), [feedId]);
  const flattenedOptions = useMemo(
    () =>
      allGroupedOptions
        .flatMap((o) => o.options)
        .filter((o) => !o.value?.startsWith(ALL_OPTIONS_PREFIX)),
    [allGroupedOptions],
  );
  const [isTouched, setIsTouched] = useState(false);
  const selectedTags = getSelectedTagsFromTargetingCriteria(value, feedId);

  const defaultSelectedOptions = getSelectedTagsFromTargetingCriteria(
    value === undefined ? {} : value,
    feedId,
  );

  const [selectedOptions, setSelectedOptions] = useState(
    isTouched ? selectedTags : defaultSelectedOptions,
  );

  const checkIfAllSelected = useCallback(() => {
    allGroupedOptions.forEach(({ tagName, options }) => {
      const allTagNameOption = options.find(
        ({ value }) => value === `${ALL_OPTIONS_PREFIX}${tagName}`,
      );
      const selectedTagNameOptions = selectedOptions.filter(
        (o) => o.tagName === tagName,
      );

      const areAllOptionsForTagNameSelected =
        selectedTagNameOptions.length === options.length - 1 &&
        !selectedTagNameOptions.includes(allTagNameOption as TagSelection);

      const newSelectedOptions = _uniqBy(
        (areAllOptionsForTagNameSelected
          ? [...selectedOptions, allTagNameOption]
          : selectedOptions.filter(
              ({ value }) => value !== `${ALL_OPTIONS_PREFIX}${tagName}`,
            )) as TagSelection[],
        'value',
      );

      if (!_isEqual(newSelectedOptions, selectedOptions)) {
        setSelectedOptions(newSelectedOptions);
      }
    });
  }, [selectedOptions, allGroupedOptions]);

  useEffect(() => checkIfAllSelected(), [selectedOptions, checkIfAllSelected]);

  // TODO: this implementation is quite inelegant and requires a significant refactoring effort.
  // Handle the case of `Select All` and `Deselect All` options, using
  // `UNSELECT TARGET ALL STATIONS` and `TARGET ALL STATIONS AND SCREENS`
  // bulk actions.
  useEffect(() => {
    if (selectedTags.length || (selectedOptions.length && isTouched)) {
      if (!isTouched) {
        setIsTouched(true);
      }
      const flattenedSelectedOptions = selectedOptions.filter(
        (o) => !o.value.startsWith(ALL_OPTIONS_PREFIX),
      );

      if (
        !selectedTags.length &&
        flattenedOptions.length === flattenedSelectedOptions.length
      ) {
        setSelectedOptions([]);
      } else if (
        selectedTags.length === flattenedOptions.length &&
        flattenedSelectedOptions.length !== flattenedOptions.length
      ) {
        setSelectedOptions(selectedTags);
      }
    }
  }, [selectedTags, selectedOptions, flattenedOptions, isTouched]);

  const handleSelect = (_: any, { action, option }: any) => {
    const selectedOption = option;
    const shouldRemoveOption = action === 'deselect-option';

    const { value } = selectedOption;
    const tagName = option.tagName ?? value.replace(ALL_OPTIONS_PREFIX, '');

    const allTagNameOptions =
      (allGroupedOptions.find((t) => t.tagName === tagName)
        ?.options as TargetingOption[]) ?? [];

    let newSelectedOptions;

    if (value.startsWith(ALL_OPTIONS_PREFIX)) {
      newSelectedOptions = (
        shouldRemoveOption
          ? selectedOptions.filter(
              (o) =>
                o.tagName !== tagName &&
                o.value !== `${ALL_OPTIONS_PREFIX}${tagName}`,
            )
          : [...selectedOptions, ...allTagNameOptions]
      ).filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value),
      );
    } else {
      newSelectedOptions = shouldRemoveOption
        ? selectedOptions.filter((o) => o.value !== value)
        : [...selectedOptions, selectedOption];
    }

    setSelectedOptions(newSelectedOptions);

    const flattenedSelectedOptions = newSelectedOptions
      .flatMap((o) => (o.options ? o.options : o))
      .filter((o) => !o?.value?.startsWith(ALL_OPTIONS_PREFIX));
    const areAllOptionsSelected =
      flattenedSelectedOptions.length === flattenedOptions.length;

    onChange(
      areAllOptionsSelected
        ? {}
        : getTargetingCriteriaFromOptions(newSelectedOptions, feedId),
    );
  };

  return (
    <Select
      isMulti
      classNamePrefix={classNamePrefix}
      hasControlBorder={false}
      isSearchable={false}
      isClearable={false}
      options={allGroupedOptions}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      placeholder="Targeting"
      components={selectComponents}
      value={selectedOptions}
      onChange={handleSelect}
      styles={{
        control: (state: { menuIsOpen: boolean }) => {
          return {
            boxShadow: 'unset',
            borderRadius: `0 0 0 ${state.menuIsOpen ? '0' : '4px'}`,
          };
        },
      }}
    />
  );
};

const CustomTagsSelectorWrapper: React.FC<CustomTagsSelectorProps> = (
  props,
) => {
  const feedId = useFeedId();
  const isCustomTargetingEnabled = useFeatureFlag(
    FeatureFlagName.CUSTOM_TARGETING,
  );

  if (!isCustomTargetingEnabled || !SUPPORTED_TAGS_AGENCIES.includes(feedId)) {
    return null;
  }

  return <CustomTagsSelector {...props} />;
};

export default CustomTagsSelectorWrapper;
