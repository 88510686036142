/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { isSubway, isBus } from '../../../../utils/feed-switches';
import Theme from '../../../../theme';
import { FeedId } from '../../../../types/feeds';
import Bullet, { BulletSize } from '../../Bullet';
import { EntireRouteOption } from '../../../../types';

export const LineOptionBulletWrapper: React.FC<{
  disabled: boolean;
  focused: boolean;
  feedId: FeedId;
  selected: boolean;
  refCallback?: any;
  children?: React.ReactNode;
}> = ({ selected, focused, disabled, feedId, refCallback = null, ...rest }) => {
  let optionPadding: string = '0px';
  if (isSubway(feedId)) {
    optionPadding = '2px';
  } else if (selected) {
    optionPadding = '3px';
  }

  return (
    <div
      ref={(el: HTMLDivElement) => {
        if (refCallback) {
          refCallback(el);
        }
      }}
      css={css`
        cursor: ${disabled ? 'default' : 'pointer'};
        flex: 0 0 auto;
        height: auto;
        padding: ${optionPadding};
        margin: 0px ${isSubway(feedId) ? '6px 12px' : '10px 7px'} 0px;
        border-radius: ${isSubway(feedId) ? '50%' : '4px'};
        opacity: ${disabled ? 0.3 : 1};
        border: 2px solid ${selected ? Theme.colors.accent3 : 'transparent'};

        position: relative;

        ${!isBus(feedId)
          ? `
        &:after {
          content: '';
          position: absolute;

          ${
            focused && selected
              ? `
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;

          border: 1px solid
            ${Theme.colors.accent3};`
              : `
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
  
            border: 1px solid ${
              focused || selected ? Theme.colors.accent3 : 'transparent'
            };`
          }

          border-radius: ${isSubway(feedId) ? '50%' : '4px'};
        }
        `
          : ''}
      `}
      {...rest}
    />
  );
};

interface LineOptionProps {
  disabled: boolean;
  focused: boolean;
  option: EntireRouteOption;
  selected: boolean;
  refCallback?: any;
}

const LineOption: React.FC<LineOptionProps> = ({
  option,
  selected,
  disabled,
  focused,
  refCallback = null,
}) => (
  <LineOptionBulletWrapper
    refCallback={refCallback}
    feedId={option.feedId as FeedId}
    focused={focused}
    selected={selected}
    disabled={disabled}
  >
    <Bullet size={BulletSize.large} routeId={option.bulletValue} />
  </LineOptionBulletWrapper>
);

export default LineOption;
