/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';
import WeightPriorityPill from 'components/common/weight-priority-pill';
import {
  TargetType,
  ContextualSelectorInput,
  FeatureFlagName,
} from 'generated/global-types';
import { SingleCampaignView_campaign_Campaign_clearingCampaignScreenContent_CampaignScreenContentsConnection_nodes_CampaignScreenContent as ScreenContent } from 'generated/SingleCampaignView';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import ScreensAvailabilityCount from 'components/common/screens-availability-count';
import TrainTargetingDisplayGroup from 'components/common/train-targeting-display-group';
import { FeedId } from '@mta-live-media-manager/shared';
import { useFeedId } from 'contexts/FeedId';
import Accordion from 'components/common/accordion';
import TargetingAccordionLabel from 'components/common/screen-targeting-accordion-label';
import { getSelectorsFromContextualSelectors } from 'utils/campaign-helpers';
import { useRoutesByFeedId } from 'contexts/Routes';
import { DATE_FORMAT } from 'constants/time';
import { format } from 'date-fns';
import theme from 'theme';
import { ScreenSpectacularsFooter } from '../../common/campaign-form/screen-spectaculars';
import CampaignScreenPreview from '../Screen/in-screens-preview/in-screen-preview';
import {
  getLabelFromTargetTypes,
  getDimensionsFromTargetType,
  COVES_TARGET_TYPES,
  getCoveMultiplier,
} from '../Screen/in-screens-preview/helpers';
import * as Well from '../../common/well';
import * as S from './index.styled';
import { CampaignTargeting } from './campaign-targeting';

const SCREEN_UPLOADS_PAGE = 3;

const CampaignScreenTargetingView: React.FC<
  ScreenContent & {
    index: number;
  }
> = ({
  screenSelectors,
  targetTypes,
  targetedScreensCount,
  targetedScreensLockedCount,
  targetedSyntheticScreensCount,
  campaignScreenContentUploads,
  weight,
  priority,
  trainTargeting,
  createdAt,
  updatedAt,
  index,
  screenName,
  isClearing,
}) => {
  const feedId = useFeedId();
  const allRoutes = useRoutesByFeedId(feedId);
  const [screenUploadsPage, setScreenUploadsPage] = React.useState<number>(1);

  const weightingEnabled = useFeatureFlag(FeatureFlagName.WEIGHTING_FREQUENCY);

  const spectacularScreensLayout = !!targetTypes?.includes(
    TargetType.SPECTACULAR_AD,
  );

  const totalTargetedScreens =
    (targetedSyntheticScreensCount ?? 0) + (targetedScreensCount ?? 0);

  const screenUploadsTotal = targetedScreensCount ?? 0;
  const uploadNodes = campaignScreenContentUploads?.nodes ?? [];
  const uploads = campaignScreenContentUploads.nodes.map((cscu) => cscu.upload);

  // Campaigns uploads pagination
  const lastUpload = screenUploadsPage * SCREEN_UPLOADS_PAGE;
  const firstUpload = lastUpload - SCREEN_UPLOADS_PAGE;
  const paginatedUploads = uploadNodes.slice(firstUpload, lastUpload);

  const screenContentUploads = spectacularScreensLayout
    ? paginatedUploads
    : uploadNodes;

  const [spectacularWidth] = getDimensionsFromTargetType(
    TargetType.SPECTACULAR_AD,
  );

  const contextualSelectors = screenSelectors.flatMap(
    (s) => s?.selectors,
  ) as ContextualSelectorInput[];

  const selectors = !trainTargeting
    ? getSelectorsFromContextualSelectors(contextualSelectors, allRoutes)
    : [];

  const { selectedRouteIds, selectedStopIds } = selectors.reduce<{
    selectedRouteIds: string[];
    selectedStopIds: string[];
  }>(
    (selections, selector) => {
      selections.selectedRouteIds.push(...selector.routes.map((r) => r.id));
      selections.selectedStopIds.push(...selector.stops.map((r) => r.id));
      return selections;
    },
    {
      selectedRouteIds: [],
      selectedStopIds: [],
    },
  );

  return (
    <Well.Section>
      <Accordion
        label={
          <TargetingAccordionLabel
            routeIds={selectedRouteIds}
            stopIds={selectedStopIds}
            trainTargeting={trainTargeting}
            screenNumber={index + 1}
          />
        }
        subtitle={getLabelFromTargetTypes(targetTypes, feedId)}
        isInitiallyOpen={false}
        helperItem={
          <span
            css={css`
              font-size: ${theme.typography.sizes.small.fontSize};
              font-weight: ${theme.typography.weights.normal};
            `}
          >
            Posted: {format(new Date(updatedAt ?? createdAt), DATE_FORMAT)}
          </span>
        }
        blurContent={isClearing}
        addClearingStatusPill={isClearing}
      >
        {spectacularScreensLayout ? (
          <React.Fragment>
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              {campaignScreenContentUploads.nodes.map((cscu, _i, all) => (
                <div
                  key={cscu.upload?.id}
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <CampaignScreenPreview
                    key={cscu.upload?.id}
                    targetType={targetTypes[0]}
                    setIndex={cscu.setIndex}
                    totalScreens={all.length}
                    upload={cscu.upload}
                    showScreenCount
                    scale={0.3}
                    css={css`
                      ${cscu.setIndex % SCREEN_UPLOADS_PAGE !== 0 &&
                      'margin-right:32px;'}
                      width: calc(${spectacularWidth}px * 0.3 - 4px);
                    `}
                  />
                </div>
              ))}
            </div>
            <ScreenSpectacularsFooter
              uploads={uploads}
              uploadsAvailable
              screenUploadsTotal={screenUploadsTotal}
              screenUploadsPage={screenUploadsPage}
              setScreenUploadsPage={setScreenUploadsPage}
              screenUploadsPerPage={SCREEN_UPLOADS_PAGE}
            />
          </React.Fragment>
        ) : (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
            `}
          >
            {!trainTargeting ? (
              <React.Fragment>
                <S.Label>Displays At</S.Label>
                <CampaignTargeting
                  selectors={contextualSelectors ?? []}
                  targetTypes={targetTypes}
                  screenName={screenName}
                  tags={
                    screenSelectors.reduce(
                      (agg, cur) => ({ ...agg, ...cur?.tags }),
                      {},
                    ) ?? {}
                  }
                />
              </React.Fragment>
            ) : (
              <TrainTargetingDisplayGroup
                headerText={
                  feedId === FeedId.NYCTSubway
                    ? 'Will Display On'
                    : 'Displays At'
                }
                trainTargeting={{
                  shops: trainTargeting?.shops ?? [],
                  lines: trainTargeting?.lines ?? [],
                  isTargetingAllLirrTrains:
                    trainTargeting?.isTargetingAllLirrTrains ?? false,
                }}
              />
            )}

            {!screenName && (
              <ScreensAvailabilityCount
                numTargeted={totalTargetedScreens}
                numLocked={targetedScreensLockedCount ?? 0}
                isCove={
                  targetTypes[0] && COVES_TARGET_TYPES.has(targetTypes[0])
                }
                coveMultiplier={getCoveMultiplier(targetTypes[0])}
                screensLabel={getLabelFromTargetTypes(targetTypes, feedId)}
              />
            )}

            <div
              css={css`
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
              `}
            >
              {screenContentUploads.map((cscu, _i, all) => (
                <CampaignScreenPreview
                  key={cscu.upload?.id}
                  targetType={targetTypes[0]}
                  setIndex={cscu.setIndex}
                  totalScreens={all.length}
                  upload={cscu.upload}
                  css={css`
                    margin-right: 32px;
                    margin-bottom: 8px;
                  `}
                  showScreenCount={COVES_TARGET_TYPES.has(targetTypes[0])}
                  scale={targetTypes[0] === TargetType.DUP ? 0.55 : undefined}
                  zipContentsScale={
                    targetTypes[0] === TargetType.DUP ? 0.17 : undefined
                  }
                />
              ))}
            </div>
          </div>
        )}
        {weightingEnabled && targetTypes[0] !== TargetType.DUP && (
          <React.Fragment>
            <S.Label>Display Frequency</S.Label>
            <div
              css={css`
                display: flex;
                flex-direction: row;
              `}
            >
              <WeightPriorityPill priority={priority} weight={weight} />
            </div>
          </React.Fragment>
        )}
      </Accordion>
    </Well.Section>
  );
};

export default CampaignScreenTargetingView;
