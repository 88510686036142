export const getFileDimensions = async (
  file: File | null,
): Promise<{ width: number | undefined; height: number | undefined }> => {
  if (!file) {
    return { width: undefined, height: undefined };
  }
  if (file.type.startsWith('image/')) {
    return new Promise((resolve) => {
      let img: null | HTMLImageElement = new Image();
      if (img) {
        img.onload = () => {
          resolve({ width: img?.width, height: img?.height });
          img = null;
        };
        img.onerror = () => resolve({ width: undefined, height: undefined });
        img.src = window.URL.createObjectURL(file);
      } else {
        resolve({ width: undefined, height: undefined });
      }
    });
  }
  if (file.type.startsWith('video/')) {
    return new Promise((resolve) => {
      let video: null | HTMLVideoElement = document.createElement('video');
      if (video) {
        video.onloadedmetadata = () => {
          resolve({ width: video?.videoWidth, height: video?.videoHeight });
          video = null;
        };
        video.onerror = () => resolve({ width: undefined, height: undefined });
        video.src = window.URL.createObjectURL(file);
      } else {
        resolve({ width: undefined, height: undefined });
      }
    });
  }
  return { width: undefined, height: undefined }; // No checking for zip files
};

// The http header of the sent file contains the filename which causes an error if it contains an invalid character (non-ANSI chars)
// It's checked using the headerCharRegex in https://github.com/nodejs/node/blob/v15.x/lib/_http_common.js
// We can also add other characters to this regex if we would like to not allow them anymore
export const invalidFilenameRegex = /[^\t\x20-\x7e\x80-\xff]/;

export const isInvalidFilename = (fileName: string): boolean => {
  return invalidFilenameRegex.test(fileName);
};

export const getAssetPreviewScale = (width: number, height: number): number => {
  const maxWidth = 600;
  const maxHeight = 450;
  const ratio = Math.min(maxWidth / width, maxHeight / height);
  return ratio;
};
