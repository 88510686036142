/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  OptionProps,
  components,
  ValueContainerProps,
  PlaceholderProps,
} from 'react-select';
import theme, { ThemeType } from 'theme';
import { ScreenWithTags, TagSelections, TagType } from 'types/screen-tags';
import { EditableTagsStopScreens_screens2s_Screens2sConnection_nodes_Screens2 as ScreenType } from 'generated/EditableTagsStopScreens';
import Checkbox, { CheckboxSize } from 'components/common/Checkbox';
import {
  TagNames,
  feedTagNameOptions,
  TagNamesToSeparatePrefix,
  REMOVED_TAG_VALUE,
  ADDED_TAG_VALUE,
  DEFAULT_TAG_VALUE,
  TAG_OPTIONS_SEPARATOR,
} from 'constants/screen-tags';

const getBooleanTagNames = () =>
  Object.values(feedTagNameOptions).flatMap((o) =>
    o
      .filter(
        ({ options }) =>
          options.length === 2 &&
          options.find(({ value }) => value === ADDED_TAG_VALUE) &&
          options.find(({ value }) => value === REMOVED_TAG_VALUE),
      )
      .map(({ tagName }) => tagName),
  );

const getAllTagNames = (): string[] => Object.values(TagNames);

export const getTagNamesToSeparate = (): string[] =>
  Object.keys(TagNamesToSeparatePrefix);

// Generates "separated" criteria to tag values and vice versa
// ex: 1. transitapi_lep_spanish -> spanish
//     2. northbound -> transitapi_northbound
export const tagValueAndCriteriaFactory = ({
  criteria,
  tagValue,
}: {
  criteria?: string;
  tagValue?: string;
}) => {
  if (!criteria && !tagValue) {
    return null;
  }

  if (criteria && tagValue) {
    return { criteria, tagValue };
  }

  const allTagValues = Object.values(feedTagNameOptions).flatMap((o) =>
    o.flatMap(({ options, tagName }) =>
      options.map((to) => ({ ...to, tagName })),
    ),
  );

  if (criteria) {
    const isBoolean = getBooleanTagNames().includes(criteria);
    const isTagName = getAllTagNames().includes(criteria);

    if (isBoolean || isTagName) {
      return null;
    }

    return {
      criteria,
      tagValue:
        allTagValues.find(({ value }) => criteria.includes(value))?.value ??
        DEFAULT_TAG_VALUE,
    };
  }

  if (!tagValue) {
    return null;
  }

  const optionFromTagValue = allTagValues.find(
    ({ value }) => value === tagValue,
  );

  const tagName = optionFromTagValue?.tagName as string;

  if (!optionFromTagValue || !getTagNamesToSeparate().includes(tagName)) {
    return null;
  }

  return {
    criteria: `${TagNamesToSeparatePrefix[tagName]}${tagValue}`,
    tagValue,
  };
};

const getTagSelectionFromScreenWithTags = (
  screens: ScreenWithTags[],
  tag: string,
): string[] =>
  screens
    .reduce((acc: string[], screen) => {
      const screenTags = screen.tags
        .filter(({ tagName }) => tagName === tag)
        .flatMap(({ value }) => value);
      if (screenTags.length) {
        acc.push(...screenTags);
      }
      return acc;
    }, [])
    .filter(Boolean);

export const TAGS = Object.values(TagNames) as string[];

export const initEmptyTagSelections = () =>
  TAGS.reduce((acc: TagSelections, tag) => {
    acc[tag] = [];
    return acc;
  }, {});

export const getTagLabelFromValue = (value: string) =>
  value
    .split('_')
    .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join(' ');

export const getEditableTagsCriteria = (criteria: {
  [key: string]: string;
}) => {
  const allTagNames = getAllTagNames();
  return Object.keys(criteria).filter((key) => {
    const tagValueWithCriteria = tagValueAndCriteriaFactory({
      criteria: key,
    });
    const isSeparatedCriteria =
      !!tagValueWithCriteria &&
      tagValueWithCriteria.tagValue !== DEFAULT_TAG_VALUE;
    const isTagName = allTagNames.includes(key);
    return isSeparatedCriteria || isTagName;
  });
};

export const getScreenWithTagsFromScreen = (
  screen: ScreenType,
): ScreenWithTags => {
  const tags: TagType[] = [];
  const criteria = screen.customCriteria ?? {};

  const allTagNames = getAllTagNames();
  const booleanTagNames = getBooleanTagNames();
  const TagNamesToSeparate = getTagNamesToSeparate();

  Object.entries(criteria).forEach(([key, value]) => {
    const tagValue = value as string;

    const tagValueWithCriteria = tagValueAndCriteriaFactory({
      criteria: key,
    });
    const isSeparatedCriteria =
      !!tagValueWithCriteria &&
      tagValueWithCriteria.tagValue !== DEFAULT_TAG_VALUE;
    const isBoolean = booleanTagNames.includes(key);

    if (isSeparatedCriteria && tagValue === ADDED_TAG_VALUE) {
      const separatedTagName = TagNamesToSeparate.find((c) =>
        key.startsWith(TagNamesToSeparatePrefix[c]),
      );

      if (!separatedTagName) {
        return;
      }

      const values = tags.find(({ tagName }) => tagName === separatedTagName);
      const tagValue = tagValueWithCriteria?.tagValue as string;

      if (!values) {
        tags.push({
          tagName: separatedTagName,
          value: [tagValue],
        });
      } else {
        values.value.push(tagValue);
      }
    } else if (allTagNames.includes(key)) {
      tags.push({
        tagName: key,
        value: tagValue === REMOVED_TAG_VALUE && !isBoolean ? [] : [tagValue],
      });
    }
  });

  return {
    id: screen?.id as string,
    tags,
  };
};

export const getScreensWithTagsFromScreens = (
  screens: ScreenType[],
): ScreenWithTags[] => screens.map((s) => getScreenWithTagsFromScreen(s));

export const getScreenCriteriaFromTags = (
  screenWithTags: ScreenWithTags,
  savedScreen?: ScreenType,
): { id: string; criteria: { [key: string]: string } } => {
  const { id, tags } = screenWithTags;
  const criteria: { [key: string]: string } = {};

  const TagNamesToSeparate = getTagNamesToSeparate();

  tags.forEach(({ tagName, value }) => {
    if (TagNamesToSeparate.includes(tagName)) {
      value.forEach((v) => {
        criteria[
          tagValueAndCriteriaFactory({ tagValue: v })?.criteria as string
        ] = ADDED_TAG_VALUE;
      });
    } else {
      criteria[tagName] = !value.length ? REMOVED_TAG_VALUE : value[0];
    }
  });

  // Include removed/deleted tags and set their values as "false"
  Object.entries(savedScreen?.customCriteria ?? {}).forEach(([key, value]) => {
    if (!criteria[key] && value !== REMOVED_TAG_VALUE) {
      criteria[key] = REMOVED_TAG_VALUE;
    }
  });

  return {
    id,
    criteria,
  };
};

export const getScreensCriteriaFromTags = (
  screensWithTags: ScreenWithTags[],
  savedScreens?: ScreenType[],
): { id: string; criteria: { [key: string]: string } }[] =>
  screensWithTags.map((screen) =>
    getScreenCriteriaFromTags(
      screen,
      savedScreens?.find(({ id }) => id === screen.id),
    ),
  );

export const mapTagsToSelections = (
  screensWithTags: ScreenWithTags[],
  tag: string,
): string[] =>
  screensWithTags
    .reduce((acc: string[], screen) => {
      const screenTags = screen.tags.find(({ tagName }) => tagName === tag);
      if (screenTags) {
        acc.push(...screenTags.value);
      }
      return acc;
    }, [])
    .filter((value, index, self) => !!value && self.indexOf(value) === index);

export const areTagsRemoved = (
  savedScreensWithTags: ScreenWithTags[],
  selectedTags: TagSelections,
): boolean =>
  savedScreensWithTags.some(({ tags }) =>
    tags.some(
      ({ tagName, value }) =>
        !selectedTags[tagName]?.length &&
        value.length &&
        value.every((v) => v !== REMOVED_TAG_VALUE),
    ),
  );

export const isTagChanged = (
  tag: string,
  savedScreensWithTags: ScreenWithTags[],
  selectedScreensWithTags: ScreenWithTags[],
): boolean =>
  getTagSelectionFromScreenWithTags(savedScreensWithTags, tag)
    .sort()
    .join(TAG_OPTIONS_SEPARATOR) !==
  getTagSelectionFromScreenWithTags(selectedScreensWithTags, tag)
    .sort()
    .join(TAG_OPTIONS_SEPARATOR);

export const getSelectComponents = (conflictingTags?: string[]) => ({
  Option: (props: OptionProps<any>) => {
    const {
      innerProps,
      isSelected,
      data: { label, value },
    } = props;
    const isConflicting = conflictingTags?.includes(value);
    return (
      <div
        {...innerProps}
        style={props.getStyles('option', props) as any}
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <span
          css={css`
            ${theme.typography.sizes.medium};
            font-family: ${theme.typography.families.primary};
          `}
        >
          {label}
        </span>
        <Checkbox
          size={CheckboxSize.medium}
          checked={isSelected}
          onChange={() => {}}
          customCheckMarkStyles={
            isConflicting
              ? css`
                  content: '-';
                  font-size: 35px;
                  color: #fff;
                  position: absolute;
                  left: 50%;
                  top: 30%;
                  -webkit-transform: translate(-50%, -50%);
                  -moz-transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                `
              : null
          }
          css={(theme: ThemeType) => css`
            border-color: ${theme.colors.accent1};
          `}
        />
      </div>
    );
  },
  ValueContainer: (props: ValueContainerProps<any>) => {
    const selectedTags: string[] = props.getValue().map((p: any) => p.label);
    return (
      <components.ValueContainer
        {...props}
        css={css`
          font-size: 1rem;
        `}
      >
        {selectedTags.length <= 2
          ? `${selectedTags.join(TAG_OPTIONS_SEPARATOR)}`
          : `${selectedTags.slice(0, 2).join(TAG_OPTIONS_SEPARATOR)}${
              selectedTags.length > 2 ? ` +${selectedTags.length - 2} more` : ''
            }`}
        {props.children}
      </components.ValueContainer>
    );
  },
  Placeholder: (props: PlaceholderProps<any>) => {
    const selectedOptions = props.getValue();
    return selectedOptions.length ? null : (
      <components.Placeholder {...props} />
    );
  },
});
