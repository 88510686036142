/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import theme from 'theme';
import { ReactComponent as IconNewtab } from 'images/icon_newtab_blue.svg';
import { plainStyle as buttonPlainStyle } from 'components/common/Button';
import { useFeedId } from 'contexts/FeedId';
import {
  FeatureFlagName,
  SchedulePriority,
  TargetType,
} from 'generated/global-types';
import { NON_AD_SCREEN_OPTIONS } from 'constants/screens';
import { TRAIN_TARGET_TYPES } from 'components/pages/Screen/in-screens-preview/helpers';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import InputLabel from './input-label';
import Select from './Select';

export type DispFreqOption = {
  value: any;
  label: string;
  weight: number;
  priority: SchedulePriority;
};

export enum DispFreqMessageType {
  alert = 'alert',
  plannedwork = 'plannedwork',
  campaign = 'campaign',
}

const DISP_FREQ_SUBLABELS: {
  [key in DispFreqMessageType]: string;
} = {
  [DispFreqMessageType.alert]:
    'The default display frequency for Alerts is five times in a full cycle of screen content.',
  [DispFreqMessageType.plannedwork]:
    'The default display frequency for Planned Works is three times in a full cycle of screen content.',
  [DispFreqMessageType.campaign]:
    'The default display frequency for Campaigns is once in a full cycle of screen content.',
};

const DISP_FREQUENCIES = {
  NORMAL_ONE: {
    value: `${SchedulePriority.NORMAL}-1`,
    label: '1x (One time per cycle)',
    priority: SchedulePriority.NORMAL,
    weight: 1,
  },
  NORMAL_TWO: {
    value: `${SchedulePriority.NORMAL}-2`,
    label: '2x (Two times per cycle)',
    priority: SchedulePriority.NORMAL,
    weight: 2,
  },
  NORMAL_THREE: {
    value: `${SchedulePriority.NORMAL}-3`,
    label: '3x (Three times per cycle)',
    priority: SchedulePriority.NORMAL,
    weight: 3,
  },
  NORMAL_FOUR: {
    value: `${SchedulePriority.NORMAL}-4`,
    label: '4x (Four times per cycle)',
    priority: SchedulePriority.NORMAL,
    weight: 4,
  },
  NORMAL_FIVE: {
    value: `${SchedulePriority.NORMAL}-5`,
    label: '5x (Five times per cycle)',
    priority: SchedulePriority.NORMAL,
    weight: 5,
  },
  TAKEOVER: {
    value: `${SchedulePriority.TAKEOVER}-1`,
    label: 'Takeover (Overrides content cycle)',
    priority: SchedulePriority.TAKEOVER,
    weight: 1,
  },
};

const DISP_FREQ_OPTIONS: {
  [key in DispFreqMessageType]: DispFreqOption[];
} = {
  [DispFreqMessageType.alert]: [
    DISP_FREQUENCIES.NORMAL_ONE,
    DISP_FREQUENCIES.NORMAL_TWO,
    DISP_FREQUENCIES.NORMAL_THREE,
    DISP_FREQUENCIES.NORMAL_FOUR,
    { ...DISP_FREQUENCIES.NORMAL_FIVE, label: '5x (Default)' },
    DISP_FREQUENCIES.TAKEOVER,
  ],
  [DispFreqMessageType.plannedwork]: [
    DISP_FREQUENCIES.NORMAL_ONE,
    DISP_FREQUENCIES.NORMAL_TWO,
    DISP_FREQUENCIES.NORMAL_THREE,
    { ...DISP_FREQUENCIES.NORMAL_FOUR, label: '4x (Default)' },
    DISP_FREQUENCIES.NORMAL_FIVE,
    DISP_FREQUENCIES.TAKEOVER,
  ],
  [DispFreqMessageType.campaign]: [
    { ...DISP_FREQUENCIES.NORMAL_ONE, label: '1x (Default)' },
    DISP_FREQUENCIES.NORMAL_TWO,
    DISP_FREQUENCIES.NORMAL_THREE,
    DISP_FREQUENCIES.NORMAL_FOUR,
    DISP_FREQUENCIES.NORMAL_FIVE,
    DISP_FREQUENCIES.TAKEOVER,
  ],
};

export const ALERTS_DEFAULT_DISP_FREQUENCY = DISP_FREQUENCIES.NORMAL_FIVE;

export const getDispFreqOption = ({
  priority,
  weight,
  messageType,
}: {
  priority: SchedulePriority;
  weight: number;
  messageType: DispFreqMessageType;
}) => {
  return DISP_FREQ_OPTIONS[messageType].find(
    (o) => o.value === `${priority}-${weight}`,
  );
};

const getCompetingContentQueryParams = (
  {
    screenTypes,
    lines,
  }: {
    screenTypes?: string[];
    lines?: string[];
  },
  feedId: string,
): string => {
  const selectedScreenTypes = screenTypes?.includes(TargetType.NON_AD)
    ? NON_AD_SCREEN_OPTIONS[feedId]
    : screenTypes;
  return `?${
    selectedScreenTypes?.length
      ? `targets=${encodeURIComponent(selectedScreenTypes.join(','))}`
      : ''
  }${lines?.length ? `&routes=${encodeURIComponent(lines.join(','))}` : ''}`;
};

const MainContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <div {...props} />;

const DisplayFrequencySelector: React.FC<
  {
    id?: string;
    messageType: DispFreqMessageType;
    onChange: (option: DispFreqOption) => void;
    value: any;
    disabled?: boolean;
    competingContentParams?: {
      screenTypes?: string[];
      lines?: string[];
      screen?: {
        id: string;
        deployType: string;
      };
    };
    styles?: {};
  } & { children?: React.ReactNode }
> = ({
  id = 'display-frequency',
  messageType,
  onChange,
  disabled = false,
  value,
  competingContentParams = {},
  styles = {},
}) => {
  const feedId = useFeedId();

  const liveContentEnabled = useFeatureFlag(FeatureFlagName.LIVE_CONTENT);

  const shouldShowProperties = messageType !== DispFreqMessageType.plannedwork;

  // We can check for any train targeting present in the targeting types as targeting
  // for trains is selected without being coupled with other station or sidewalk screens
  const shouldShowCompetingContentButton =
    !competingContentParams.screenTypes?.some((st) =>
      TRAIN_TARGET_TYPES.has(st as TargetType),
    );

  const { screen } = competingContentParams;
  const competingContentUrl = `/${feedId}/screens/${
    screen
      ? `${screen.id}?t=${screen.deployType}`
      : `live${getCompetingContentQueryParams(competingContentParams, feedId)}`
  }`;

  return (
    <MainContainer
      css={css`
        margin-top: ${shouldShowProperties ? theme.spacing.medium : 0};
      `}
    >
      <InputLabel
        htmlFor={id}
        label={shouldShowProperties ? 'Display Frequency' : ''}
        subLabel={shouldShowProperties ? DISP_FREQ_SUBLABELS[messageType] : ''}
      >
        <Select
          id={id}
          styles={styles}
          css={css`
            max-width: 320px;
          `}
          isDisabled={disabled}
          options={DISP_FREQ_OPTIONS[messageType]}
          isSearchable={false}
          onChange={(option: DispFreqOption) => {
            onChange(option);
          }}
          value={value}
        />
      </InputLabel>
      {shouldShowProperties &&
        shouldShowCompetingContentButton &&
        liveContentEnabled && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={competingContentUrl}
            css={[
              buttonPlainStyle,
              css`
                font-size: ${theme.typography.sizes.xsmall.fontSize};
              `,
            ]}
          >
            <IconNewtab /> VIEW COMPETING CONTENT
          </a>
        )}
    </MainContainer>
  );
};

export default DisplayFrequencySelector;
