import { BlobReader, ZipReader } from '@zip.js/zip.js';

export const validateFileDimensions = async (
  file: File,
  {
    minHeight,
    minWidth,
    maxHeight,
    maxWidth,
  }: {
    minHeight: number;
    minWidth: number;
    maxHeight: number;
    maxWidth: number;
  },
) => {
  if (file.type.startsWith('image/')) {
    return new Promise((resolve) => {
      let img: null | HTMLImageElement = new Image();
      if (img) {
        img.onload = () => {
          const hasCorrectHeight =
            !!img &&
            img.naturalHeight >= minHeight &&
            img.naturalHeight <= maxHeight;

          const hasCorrectWidth =
            !!img &&
            img.naturalWidth >= minWidth &&
            img.naturalWidth <= maxWidth;

          img = null;
          resolve(hasCorrectHeight && hasCorrectWidth);
        };
        img.onerror = () => resolve(false);
        img.src = window.URL.createObjectURL(file);
      } else {
        resolve(false);
      }
    });
  }
  if (file.type.startsWith('video/')) {
    return new Promise((resolve) => {
      let video: null | HTMLVideoElement = document.createElement('video');
      if (video) {
        video.onloadedmetadata = () => {
          const hasCorrectHeight =
            !!video &&
            video.videoHeight >= minHeight &&
            video.videoHeight <= maxHeight;

          const hasCorrectWidth =
            !!video &&
            video.videoWidth >= minWidth &&
            video.videoWidth <= maxWidth;

          video = null;
          resolve(hasCorrectHeight && hasCorrectWidth);
        };
        video.onerror = () => resolve(false);
        video.src = window.URL.createObjectURL(file);
      } else {
        resolve(false);
      }
    });
  }
  return true; // No checking for zip files
};

export const validateZipContent = async (file: File) => {
  if (file.type === 'application/zip') {
    const blob = new BlobReader(file);
    const jsZip = new ZipReader(blob);
    const entries = await jsZip.getEntries();
    return Boolean(entries.find((entry) => entry.filename === 'template.json'));
  }

  return true;
};

export const validateUrl = (link: string): boolean => {
  try {
    const url = new URL(link);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    // ignore error
  }
  return false;
};
