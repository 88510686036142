import { FeedId } from '@mta-live-media-manager/shared';

export enum ScreenPreviewDisplayTypes {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
  DUAL = 'DUAL',
  DUP = 'DUP',
}

export const getSuggestedDisplayType = (feedId: FeedId) => {
  if (feedId === FeedId.MNR || feedId === FeedId.LIRR) {
    return ScreenPreviewDisplayTypes.DUAL;
  }

  return ScreenPreviewDisplayTypes.PORTRAIT;
};
