/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { PropsWithChildren } from 'react';
import Select, {
  DropdownIndicatorProps,
  Options,
  StylesConfig,
} from 'react-select';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { FEEDS, useFeedId } from '../../../contexts/FeedId';
import theme from '../../../theme';
import { ReactComponent as DropdownArrow } from '../../../images/icon_dropdown.svg';

import { FeedId } from '../../../types/feeds';

import { SETTINGS_PAGE_URL } from '../../../constants/page-urls';

const CheckIcon = require('../../../images/icon_check.svg');
const SettingsIcon = require('../../../images/icon_settings.svg');

const feedSortOrder = [
  'nyct-subway',
  'nyct-bus',
  'lirr',
  'mnr',
  'bridges-tunnels',
  'outfront-qa',
  'outfront-dev',
];

function getFeedLabel(feedId: FeedId): string {
  return FEEDS[feedId].longName || FEEDS[feedId].name;
}

const customStyles: StylesConfig = {
  control: (provided, props) => {
    const focusedStyles = props.isFocused
      ? {
          backgroundColor: '#032761',
          borderColor: 'hsl(0, 0%, 80%)',
        }
      : {};
    return {
      ...provided,
      width: '100%',
      height: '100%',
      borderRadius: 0,
      border: 0,
      boxShadow: 'none',
      backgroundColor: theme.colors.accent3,
      cursor: 'pointer',
      borderColor: 'hsl(0, 0%, 80%)',
      '&:hover': {
        backgroundColor: '#011f52',
        borderColor: 'hsl(0, 0%, 80%)',
      },
      ...focusedStyles,
    };
  },
  container: (provided) => ({
    ...provided,
    width: '100%',
    height: '53px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0 12px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: theme.typography.families.secondary,
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.colors.white,
    paddingLeft: '36px',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#364663',
    borderRadius: 0,
    border: 0,
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.2)',
    marginTop: 0,
  }),
  menuList: () => ({
    padding: '6px 0 0',
  }),
  option: (provided, props: any) => {
    const settingsOption = props?.data?.value === SETTINGS_PAGE_URL;

    const optionBackground = settingsOption
      ? `url(${SettingsIcon}) center right 16px no-repeat ${
          props.isFocused ? '#0F61A9' : ''
        }`
      : props.isSelected &&
        `url(${CheckIcon}) center right 16px no-repeat ${
          props.isFocused ? '#0F61A9' : ''
        }`;

    const settingsOptionStyles = settingsOption
      ? {
          borderTop: '1px solid rgba(255, 255, 255, 0.2)',
          marginTop: '10px',
          padding: '16px 12px 16px 16px',
        }
      : {};

    const focusedStyles = props.isFocused
      ? {
          backgroundColor: '#0F61A9',
        }
      : {};

    return {
      ...provided,
      backgroundColor: 'transparent',
      color: theme.colors.white,
      cursor: 'pointer',
      fontFamily: theme.typography.families.secondary,
      fontSize: theme.typography.sizes.small.fontSize,
      fontWeight: props.isSelected ? 600 : 500,
      padding: '6px 12px 6px 16px',
      background: optionBackground || '',
      '&:hover': focusedStyles,
      ...focusedStyles,
      ...settingsOptionStyles,
    };
  },
};

const DropdownIndicator: React.FC<
  PropsWithChildren<DropdownIndicatorProps<Options<{ menuIsOpen?: boolean }>>>
> = ({ selectProps: { menuIsOpen } }) => (
  <DropdownArrow
    css={css`
      transform: rotate(${menuIsOpen ? 180 : 0}deg);
      transition: transform 0.2s ease-in-out;
    `}
  />
);

const NullComponent: React.FC<any> = () => null;

const SelectBox: React.FC<{
  feeds: FeedId[];
  disabled: boolean;
  showSettings: boolean;
}> = ({ feeds, disabled, showSettings }) => {
  const history = useHistory();
  const feedId = useFeedId();

  const matchSettingsPage = useRouteMatch({
    path: `/${SETTINGS_PAGE_URL}`,
    exact: true,
  });

  const options: { value: string; label: string }[] = feeds
    .map((feed) => ({ value: feed, label: getFeedLabel(feed) }))
    .sort(
      (a, b) => feedSortOrder.indexOf(a.value) - feedSortOrder.indexOf(b.value),
    );

  if (showSettings) {
    options.push({ value: SETTINGS_PAGE_URL, label: 'Settings' });
  }

  const currentOption = options.find(
    (opt) => opt.value === (matchSettingsPage ? SETTINGS_PAGE_URL : feedId),
  );

  if (options.length < 2) {
    return (
      <h1
        css={css`
          align-self: center;
          font-family: ${theme.typography.families.secondary};
          font-size: 15px;
          font-weight: bold;
          line-height: 18px;
          color: ${theme.colors.white};
          padding-left: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        {options[0]?.label}
      </h1>
    );
  }

  return (
    <Select
      components={{
        DropdownIndicator,
        Placeholder: NullComponent,
      }}
      isSearchable={false}
      value={currentOption as any}
      options={options as any}
      styles={customStyles as any}
      onChange={(opt: any) => {
        const urlElements = history.location.pathname.split('/');
        const page = urlElements[2];

        const alertsUrl =
          !page || page === 'events' || page === 'compose' || page === 'alerts';

        if (alertsUrl || opt.value === SETTINGS_PAGE_URL) {
          history.push(`/${opt.value}`);
        } else {
          history.push(`/${opt.value}/${page.replace('stops', 'screens')}`);
        }
      }}
      isDisabled={disabled}
      tabIndex={disabled ? -1 : undefined}
    />
  );
};

const AgencySelect: React.FC<{
  feeds: FeedId[];
  isOpen: boolean;
  isAdmin: boolean;
}> = ({ feeds, isOpen, isAdmin }) => {
  return (
    <div
      css={css`
        align-self: stretch;
        flex: 0 0 54px;
        display: flex;
        border-bottom: 1px solid #364663;
      `}
    >
      <SelectBox feeds={feeds} disabled={!isOpen} showSettings={isAdmin} />
    </div>
  );
};

export default AgencySelect;
