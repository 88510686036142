/** @jsxImportSource @emotion/react */

import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

import { StopMention } from 'types';
import { MentionType, Borough } from 'generated/global-types';
import { BOROUGH_LABELS, boroughOptionLabel } from 'utils/boroughs';
import { getVehicleName, getStopType, isOutfront } from 'utils/feed-switches';
import {
  TargetingOption,
  TargetingOptionType,
  BoroughGroup,
  isBoroughGroup,
  getTargetingOptions,
  getStopSelectorOptions,
  getKeysFromOptions,
} from 'utils/stop-selector-helpers';
import { usePrevious } from 'hooks/use-previous';
import { ActionMeta } from 'react-select';

import useBulkSelect from './use-bulk-select';
import Select from '../form-elements/Select';
import { useFeedId, FeedId } from '../../../contexts/FeedId';
import SelectComponents from './select-components';

import {
  StopSelectorOptions,
  StopSelectorOptionsVariables,
  StopSelectorOptions_routes_RoutesConnection_nodes_Route as StopSelectorOptions_routes_nodes,
} from '../../../generated/StopSelectorOptions';

const StopSelectorOptionsQuery = loader(
  '../../../graphql/StopSelectorOptions.gql',
);

const stopOptionToStopMention = (stop: TargetingOption): StopMention => {
  return {
    isAffected: false,
    id: stop.stopId || '',
    name: stop.label || '',
    stopId: stop.stopId || '',
    boroughs: null,
    relatedRouteIds: null,
    meta: null,
    type: MentionType.STOP,
  };
};

const generateOptions = ({
  stopsByRoute,
  vehicleName,
  feedId,
  stopOrStationText,
}: {
  feedId: FeedId;
  vehicleName: string;
  stopOrStationText: string;
  stopsByRoute?: StopSelectorOptions_routes_nodes;
}): (TargetingOption | BoroughGroup)[] => {
  if (!stopsByRoute) {
    return [];
  }

  const routeId = stopsByRoute.gtfsId;
  const stopsWithoutBoroughs: TargetingOption[] = [];
  const stopsByBorough = {} as {
    [key in Borough]: TargetingOption[];
  };

  stopsByRoute.orderedStopsByDirection.nodes.forEach((stop) => {
    const stopOption: TargetingOption = {
      key: stop.gtfsStopId,
      label: stop.name || stop.gtfsStopId,
      stopId: stop.gtfsStopId,
      borough: stop.borough,
      screensAvailable: stop.screensAvailable,
      feedId,
      optionType: TargetingOptionType.STOP_OPTION,
    };

    if (!stop.borough) {
      stopsWithoutBoroughs.push(stopOption);
    } else {
      if (!stopsByBorough[stop.borough]) {
        stopsByBorough[stop.borough] = [];
      }

      stopsByBorough[stop.borough].push(stopOption);
    }
  });

  const boroughGroups: BoroughGroup[] = Object.keys(stopsByBorough).map(
    (boroughKey) => {
      const borough = boroughKey as Borough;

      return {
        key: borough,
        label: BOROUGH_LABELS[borough],
        borough,
        bulletValue: routeId,
        options: [
          {
            key: borough,
            label: boroughOptionLabel(borough, feedId),
            borough,
            feedId,
            bulletValue: routeId,
            optionType: TargetingOptionType.ALL_BOROUGH_OPTION,
          },
          ...stopsByBorough[borough],
        ],
      };
    },
  );

  const allStopsOption: TargetingOption = {
    key: `${routeId}-all`,
    label: `All ${
      stopsByRoute.shortName || stopsByRoute.longName
    } ${vehicleName} ${stopOrStationText}`,
    bulletValue: routeId,
    routeId,
    feedId,
    optionType: TargetingOptionType.ALL_ROUTE_OPTION,
  };

  return [allStopsOption, ...boroughGroups, ...stopsWithoutBoroughs];
};

const getSelectedOptions = ({
  options,
  value,
}: {
  options: (TargetingOption | BoroughGroup)[];
  value: StopMention[];
}): TargetingOption[] => {
  const selectedOptions: TargetingOption[] = [];
  const stopOptions = getTargetingOptions({ options });
  const selectedStopsIds = new Set(value.map((s) => s.stopId));
  const allStopIds = getKeysFromOptions({
    options,
    onlyStops: true,
  });
  const boroughGroupOptions = options.filter((option) =>
    isBoroughGroup(option),
  ) as BoroughGroup[];

  if (allStopIds.every((id) => selectedStopsIds.has(id))) {
    selectedOptions.push(options[0] as TargetingOption);
  }

  if (boroughGroupOptions.length) {
    boroughGroupOptions.forEach((bg) => {
      const boroughStopKeys = getKeysFromOptions({
        options,
        borough: bg.borough,
        onlyStops: true,
      });

      if (boroughStopKeys.every((key) => selectedStopsIds.has(key))) {
        selectedOptions.push(bg.options[0]);
      }
    });
  }

  selectedOptions.push(
    ...stopOptions.filter((o) => selectedStopsIds.has(o.key)),
  );

  return selectedOptions;
};

interface Props {
  classNamePrefix?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  hasControlBorder?: boolean;
  hasControlBoxShadow?: boolean;
  showScreensAvailable?: boolean;
  placeholder?: string;
  routeId?: string;
  isCampaign?: boolean;
  isEditing?: boolean;
  onChange: (stops: StopMention[], hasAllRoutesUnselected: boolean) => void;
  value: StopMention[];
  selectProps?: {
    selectStyles?: any;
  };
  hasAllStopsUnselected?: boolean;
}

const StopSelector: React.FC<Props> = ({
  classNamePrefix,
  routeId,
  value,
  placeholder,
  onChange,
  isDisabled,
  showScreensAvailable,
  isCampaign,
  isEditing,
  selectProps = {
    selectStyles: {},
  },
  hasAllStopsUnselected,
  ...rest
}) => {
  const feedId = useFeedId();
  const vehicleName = getVehicleName(feedId);
  const stopOrStationText = getStopType(feedId);
  const previousRouteId = usePrevious(routeId);
  const wasPreviouslyDisabled = usePrevious(isDisabled);

  // Select all lines when composing a campaign or when all lines were previously selected
  // `hasAllStopsUnselected` is used in case the component was re-rendered and we needed to
  // reinitialize the state
  const [areAllStopsSelected, setAllStopsAreSelected] = useState(
    !value.length && (isCampaign || isEditing || !hasAllStopsUnselected),
  );

  // Unselest all lines when route changes while composing a message that is not a campaign
  useEffect(() => {
    if (
      !isCampaign &&
      ((previousRouteId && previousRouteId !== routeId) ||
        (!isDisabled && wasPreviouslyDisabled)) &&
      value.length
    ) {
      setAllStopsAreSelected(false);
      onChange([], !isCampaign);
    } else if (!value.length) {
      setAllStopsAreSelected(!hasAllStopsUnselected);
    }
  }, [
    routeId,
    previousRouteId,
    onChange,
    isDisabled,
    wasPreviouslyDisabled,
    isCampaign,
    value.length,
    hasAllStopsUnselected,
  ]);

  const query = useQuery<StopSelectorOptions, StopSelectorOptionsVariables>(
    StopSelectorOptionsQuery,
    {
      variables: {
        feedId: isOutfront(feedId) ? FeedId.NYCTSubway : feedId,
        routeId,
      },
    },
  );

  const stopsByRoute = query?.data?.routes?.nodes?.[0];
  const options = generateOptions({
    stopsByRoute,
    feedId,
    stopOrStationText,
    vehicleName,
  });

  const { handleKeyDown, handleBulkSelect } = useBulkSelect({
    options,
  });

  const selectedOptions = areAllStopsSelected
    ? getTargetingOptions({ options })
    : getSelectedOptions({ options, value });

  return (
    <Select
      isMulti
      classNamePrefix={classNamePrefix}
      isLoading={query.loading}
      options={options}
      value={isDisabled ? [] : selectedOptions}
      getOptionValue={(option: TargetingOption) => option.key}
      isDisabled={isDisabled}
      showScreensAvailable={showScreensAvailable}
      isClearable={false}
      isSearchable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      placeholder={
        isDisabled || areAllStopsSelected
          ? 'All Stations'
          : `No ${stopOrStationText}s Selected`
      }
      components={SelectComponents}
      onChange={(
        newSelectedOptions: TargetingOption[],
        event: ActionMeta<TargetingOption>,
      ) => {
        const optionsToBulkSelect = handleBulkSelect({
          selectedOptions: newSelectedOptions,
          event,
        });

        const stopSelectorOptions = uniqBy(
          [
            ...getStopSelectorOptions(
              newSelectedOptions,
              event.option as any,
              options,
              event.action as any,
            ),
            ...optionsToBulkSelect,
          ],
          'key',
        );

        if (
          stopSelectorOptions.find(
            (o) => o.optionType === TargetingOptionType.ALL_ROUTE_OPTION,
          )
        ) {
          setAllStopsAreSelected(true);
          return onChange([], false);
        }

        setAllStopsAreSelected(false);

        const stopMentions = stopSelectorOptions
          .reduce<StopMention[]>((stopMentions, selectedStop) => {
            if (selectedStop.optionType === TargetingOptionType.STOP_OPTION) {
              stopMentions.push(stopOptionToStopMention(selectedStop));
            }

            return stopMentions;
          }, [])
          .sort(
            (a, b) =>
              options.findIndex((o) => o.key === a.id) -
              options.findIndex((o) => o.key === b.id),
          );

        return onChange(stopMentions, !stopMentions.length);
      }}
      onKeyDown={handleKeyDown}
      styles={{
        control: () => {
          return {
            borderRadius: '0',
          };
        },
        ...selectProps.selectStyles,
      }}
      {...rest}
    />
  );
};

export default StopSelector;
