/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import { ThemeType } from 'theme';
import { useFeedId } from 'contexts/FeedId';
import {
  POST_FAILED_TWITTER,
  POST_FAILED_TWITTER_DUPLICATE,
  TWITTER_DUPLICATE_HTTP_STATUS_CODE,
  TWITTER_POST_FAILED_TRY_NATIVELY,
  TWITTER_POST_POSSIBLE_SUCCESS,
  TWITTER_SERVICE_UNAVAILABLE_HTTP_STATUS_CODE,
} from 'constants/error-messages';
import { GetEventById_event_Event_messages_MessagesConnection_nodes_Message_tweets_TweetsConnection_nodes_Tweet as TweetType } from 'generated/GetEventById';
import { PublishStatus } from 'generated/global-types';

import PublishItem from './PublishItem';
import TwitterPreview from '../../common/twitter/TwitterPreview';
import StatusBanner from '../../common/status-banner';
import Button from '../../common/Button';

import * as S from './index.styled';

const SUCCESS_STATUSES = [PublishStatus.SUCCESS, PublishStatus.DRAFT];
const FAILURE_STATUSES = [PublishStatus.ERROR, PublishStatus.WARNING];

const getFailedTwitterStatus = (
  tweets: TweetType[],
): {
  status: 'warning' | 'error';
  message: string;
  canRepost: boolean;
} | null => {
  const tweetWithError = tweets?.find((tweet) => tweet?.error);
  // To handle different error structures.
  const twitterError = tweetWithError?.error?.code
    ? tweetWithError?.error
    : (tweetWithError?.error?.error ?? tweetWithError?.error);

  if (!twitterError) {
    return null;
  }

  const statusCode = twitterError?.code
    ? parseInt(twitterError?.code, 10)
    : null;
  const status =
    statusCode === TWITTER_SERVICE_UNAVAILABLE_HTTP_STATUS_CODE
      ? 'warning'
      : 'error';
  const canRepost = statusCode
    ? ![
        TWITTER_SERVICE_UNAVAILABLE_HTTP_STATUS_CODE,
        TWITTER_DUPLICATE_HTTP_STATUS_CODE,
      ].includes(statusCode)
    : false;
  const errorMsg = (() => {
    if (statusCode === TWITTER_DUPLICATE_HTTP_STATUS_CODE) {
      return POST_FAILED_TWITTER_DUPLICATE;
    }
    return statusCode
      ? `A connection error has occurred (HTTP Status Code: ${statusCode}) due to an issue with the Twitter API. ${
          statusCode === TWITTER_SERVICE_UNAVAILABLE_HTTP_STATUS_CODE
            ? TWITTER_POST_POSSIBLE_SUCCESS
            : TWITTER_POST_FAILED_TRY_NATIVELY
        }`
      : (twitterError?.detail ?? POST_FAILED_TWITTER);
  })();

  return {
    status,
    message: errorMsg,
    canRepost,
  };
};

const TwitterStatus: React.FC<{
  tweets: TweetType[];
  isDraft: boolean;
  status: PublishStatus;
  onRetry: (tweetId: number) => void;
}> = ({ tweets, isDraft, status, onRetry }) => {
  const feedId = useFeedId();
  const failedTwitterStatus = getFailedTwitterStatus(tweets);

  return (
    <PublishItem
      name="Twitter"
      publishedAt={
        !isDraft
          ? tweets && tweets[0] && new Date(tweets[0].publishedAt)
          : undefined
      }
      status={status === PublishStatus.DRAFT ? PublishStatus.SUCCESS : status}
    >
      {SUCCESS_STATUSES.includes(status) && (
        <div>
          <S.Message.PreviewTitle>Tweet Preview</S.Message.PreviewTitle>
          {tweets.map((tweet) => (
            <TwitterPreview
              key={tweet.id}
              feedId={feedId}
              twitterId={tweet.twitterId}
              body={tweet.body}
              publishedAt={new Date(tweet.publishedAt)}
              isDraft={isDraft}
            />
          ))}
        </div>
      )}
      {FAILURE_STATUSES.includes(status) && failedTwitterStatus && (
        <StatusBanner
          isVisible
          transition={false}
          status={failedTwitterStatus.status}
        >
          <S.ErrorContent
            css={css`
              line-height: 20px;
            `}
          >
            <span
              css={(theme: ThemeType) => css`
                color: ${theme.colors.black};
                margin-left: 10px;
              `}
            >
              {failedTwitterStatus.message}
            </span>
            {failedTwitterStatus.canRepost && (
              <Button size="small" onClick={() => onRetry(tweets[0]?.id)}>
                Repost
              </Button>
            )}
          </S.ErrorContent>
        </StatusBanner>
      )}
    </PublishItem>
  );
};

export default TwitterStatus;
