// eslint-disable-next-line max-classes-per-file
import Amplify, { Hub } from '@aws-amplify/core';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { Session, CurrentUser } from '../types/authentication';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_AWS_COGNITO_WEB_CLIENT_ID,
  REACT_APP_AWS_COGNITO_DOMAIN_NAME,
  REACT_APP_AWS_COGNITO_IDP_NAME,
  REACT_APP_AWS_COGNITO_SIGN_IN_URL,
  REACT_APP_AWS_COGNITO_SIGN_OUT_URL,
} = process.env;

const AWS_COGNITO_URL = 'https://cognito-idp.us-east-1.amazonaws.com';
const MISSING_OR_INVALID_JWT_TOKEN_MESSAGE =
  'Failed to restore session: Missing or invalid JWT in session storage';

abstract class GenericAuthenticationClient {
  isFetching: boolean = true;

  listeners: (() => void)[] = [];

  session: Session = {
    isAuthenticated: false,
    errorMessage: null,
    currentUser: null,
  };

  constructor() {
    this.listen = this.listen.bind(this);
  }

  listen(listener: () => void): () => void {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  dispatch() {
    this.listeners.forEach((l) => l());
  }

  async fetch<T>(fn: () => Promise<T>): Promise<T> {
    this.isFetching = true;
    this.dispatch();
    try {
      return await fn();
    } finally {
      this.isFetching = false;
      this.dispatch();
    }
  }
}

export class DevelopmentAuthenticationClient extends GenericAuthenticationClient {
  static SESSION_STORAGE_KEY: string = 'lmm-development-only-jwt';

  token: null | string = null;

  unauthenticatedSession(errorMessage?: string): Session.Unauthenticated {
    this.session = {
      currentUser: null,
      isAuthenticated: false,
      errorMessage: errorMessage ?? null,
    };

    // We don't want to log checking for local JWT token as it happens on each page refresh
    if (errorMessage && errorMessage !== MISSING_OR_INVALID_JWT_TOKEN_MESSAGE) {
      console.error(errorMessage); // eslint-disable-line no-console
    }

    return this.session;
  }

  private authenticatedSession(currentUser: CurrentUser): Session {
    this.session = {
      isAuthenticated: true,
      errorMessage: null,
      currentUser,
    };
    return this.session;
  }

  async login() {
    return this.fetch<Session>(async () => {
      const jwt = window.prompt('Enter JWT'); // eslint-disable-line no-alert
      if (jwt) {
        this.token = jwt;
        window.sessionStorage.setItem(
          DevelopmentAuthenticationClient.SESSION_STORAGE_KEY,
          jwt,
        );
        const [, content] = jwt.split('.');
        const payload = JSON.parse(
          window.decodeURIComponent(window.atob(content)),
        );
        return this.authenticatedSession({
          sub: payload.sub as string,
          email: payload.email as string,
          name: payload.name as string,
        });
      }
      return this.unauthenticatedSession('JWT not entered');
    });
  }

  async logout() {
    return this.fetch<Session>(async () => {
      this.token = null;
      window.sessionStorage.removeItem(
        DevelopmentAuthenticationClient.SESSION_STORAGE_KEY,
      );
      return this.unauthenticatedSession();
    });
  }

  async getSessionWithRefresh() {
    return this.fetch(async () => {
      return this.token;
    });
  }

  async restoreSession(): Promise<Session> {
    return this.fetch(async () => {
      const jwt = sessionStorage.getItem(
        DevelopmentAuthenticationClient.SESSION_STORAGE_KEY,
      );
      if (jwt) {
        this.token = jwt;
        const [, content] = jwt.split('.');
        try {
          const payload = JSON.parse(
            window.decodeURIComponent(window.atob(content)),
          );
          return this.authenticatedSession({
            sub: payload.sub as string,
            email: payload.email as string,
            name: payload.name as string,
          });
        } catch (error) {
          console.error(error); // eslint-disable-line no-console
        }
      }
      this.token = null;
      return this.unauthenticatedSession(MISSING_OR_INVALID_JWT_TOKEN_MESSAGE);
    });
  }
}

type HubCapsule = {
  channel: string;
  payload: HubPayload;
  source: string;
  patternInfo?: string[];
};

type HubPayload = {
  event: string;
  data?: any;
  message?: string;
};

export class AuthenticationClient extends GenericAuthenticationClient {
  private readonly identityProviderName: string;

  constructor() {
    super();
    Amplify.configure({
      Auth: {
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_AWS_COGNITO_WEB_CLIENT_ID,
        oauth: {
          domain: REACT_APP_AWS_COGNITO_DOMAIN_NAME,
          scope: ['phone', 'email', 'profile', 'openid'],
          redirectSignIn: REACT_APP_AWS_COGNITO_SIGN_IN_URL,
          redirectSignOut: REACT_APP_AWS_COGNITO_SIGN_OUT_URL,
          responseType: 'code',
        },
      },
    });

    this.identityProviderName = REACT_APP_AWS_COGNITO_IDP_NAME as string;
    this.authListener = this.authListener.bind(this);
    Hub.listen('auth', this.authListener);
  }

  authListener(data: HubCapsule) {
    const { payload } = data;
    switch (payload.event) {
      case 'signIn':
        this.restoreSession(true);
        break;
      case 'signOut':
        break;
      case 'signIn_failure':
        break;
      case 'cognitoHostedUI':
        // this event is triggered after "signIn"
        break;
      default:
    }
  }

  unauthenticatedSession(errorMessage?: string): Session.Unauthenticated {
    this.session = {
      currentUser: null,
      isAuthenticated: false,
      errorMessage: !errorMessage ? null : errorMessage,
    };
    return this.session;
  }

  private authenticatedSession(cognitoUser: CognitoUser): Session {
    this.session = {
      isAuthenticated: true,
      errorMessage: null,
      currentUser: this.transformCognitoUser(cognitoUser),
    };
    return this.session;
  }

  async login() {
    // eslint-disable-next-line consistent-return
    return this.fetch<Session | undefined>(async () => {
      try {
        await Auth.federatedSignIn({
          customProvider: this.identityProviderName,
        });
      } catch (err) {
        return this.unauthenticatedSession(err as any);
      }
    });
  }

  async logout(bypassCache = false) {
    // eslint-disable-next-line consistent-return
    return this.fetch<Session | undefined>(async () => {
      try {
        const session = await this.restoreSession(bypassCache);
        if (session.isAuthenticated) {
          await Auth.signOut();
        }
      } catch (err) {
        return this.unauthenticatedSession(err as any);
      }
    });
  }

  async getSessionWithRefresh() {
    return this.fetch<string | null>(async () => {
      try {
        const checkCognito =
          await AuthenticationClient.verifyCognitoRefreshToken();
        if (checkCognito) {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const cognitoSession = await cognitoUser.signInUserSession;
          return cognitoSession.getIdToken().getJwtToken() as string;
        }
        this.unauthenticatedSession('Invalid refresh token');
        return null;
      } catch (err) {
        this.unauthenticatedSession('Invalid refresh token');
        return null;
      }
    });
  }

  async restoreSession(bypassCache = false): Promise<Session> {
    return this.fetch<Session>(async () => {
      try {
        const cognitoSession = await Auth.currentSession();
        if (!cognitoSession.isValid()) {
          return this.unauthenticatedSession('Invalid Session');
        }
        const cognitoUser = await Auth.currentAuthenticatedUser({
          bypassCache,
        });

        return this.authenticatedSession(cognitoUser);
      } catch (err) {
        return this.unauthenticatedSession(`Failed to restore session: ${err}`);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private transformCognitoUser(user: CognitoUser): CurrentUser {
    type payloadType = any | undefined;
    const signInUserSession = user.getSignInUserSession();
    const payload = signInUserSession?.getIdToken().payload;
    if (typeof payload === 'undefined') {
      throw Error('IdToken has undefined payload');
    }
    const { sub, email, name } = payload as payloadType;
    return {
      sub,
      email,
      name,
    };
  }

  static async verifyCognitoRefreshToken(): Promise<boolean> {
    const cognitoSession = await Auth.currentSession();
    if (cognitoSession.isValid()) {
      return fetch(AWS_COGNITO_URL, {
        method: 'POST',
        body: JSON.stringify({
          ClientId: REACT_APP_AWS_COGNITO_WEB_CLIENT_ID,
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          AuthParameters: {
            REFRESH_TOKEN: cognitoSession.getRefreshToken().getToken(),
          },
        }),
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'x-amz-target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        },
      }).then((response) => {
        return response.ok;
      });
    }
    return false;
  }
}

const client =
  process.env.NODE_ENV === 'development'
    ? new DevelopmentAuthenticationClient()
    : new AuthenticationClient();

export default client;
