/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';
import Heading from 'components/common/Heading';
import { Collapse } from 'react-collapse';

import theme from 'theme';

// TODO: replace with ui-kit/toggle when it is feature ready
import Toggle from '../../components/common/form-elements/Toggle';

interface ToggleSectionProps {
  id: string;
  title: string;
  children?: React.ReactNode;
  className?: string;
  enabled?: boolean;
  helpText?: string;
  level?: number;
  onToggle?: (enabled: boolean) => void;
  internalCss?: any;
}

const ToggleSection: React.FC<ToggleSectionProps> = ({
  id,
  title,
  helpText,
  children,
  className,
  enabled = false,
  level = 3,
  onToggle,
  internalCss,
}) => {
  return (
    <section className={className}>
      <div
        css={css`
          align-items: center;
          display: inline-flex;
          position: relative;
          margin-left: ${internalCss?.marginLeft};
          margin-top: ${internalCss?.marginTop};
          margin-bottom: ${internalCss?.marginBottom};
        `}
      >
        {onToggle && (
          <Toggle
            id={`${id}-toggle`}
            aria-labelledby={`${id}-heading`}
            onChange={(e) => onToggle(e.target.checked)}
            checked={enabled}
            css={css`
              display: flex;
              margin-right: 12px;
            `}
          />
        )}
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Heading id={`${id}-heading`} level={level} size="large">
            {title}
          </Heading>
          {helpText && (
            <div
              css={css`
                ${theme.typography.sizes.small};
                margin-left: 12px;
              `}
            >
              {helpText}
            </div>
          )}
        </div>
      </div>
      <Collapse isOpened={enabled}>{children}</Collapse>
    </section>
  );
};

export default ToggleSection;
