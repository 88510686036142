/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { TripMention as TripType } from 'types';
import theme, { ThemeType } from 'theme';
import { BulletSize } from './Bullet';

export const PlainTripsMention: React.FC<{ trips: TripType[] }> = ({
  trips,
}) =>
  trips.length ? (
    <p
      css={css`
        size: 12px;
        line-height: 14px;
        margin-top: 10px;
        margin-left: 1px;
        font-style: italic;
      `}
    >
      Tagged Trip(s):{' '}
      {trips
        .map(({ meta }) => {
          return `#${meta.shortName} has a scheduled arrival time of ${meta.lastStop.time}.`;
        })
        .join(', ')}
    </p>
  ) : null;

const TripMention: React.FC<
  { tripName: string; size?: BulletSize } & { children?: React.ReactNode }
> = ({ tripName, size = BulletSize.xlarge }) => {
  const font =
    size === BulletSize.small
      ? theme.typography.sizes.xsmall
      : theme.typography.sizes.small;
  const padding =
    size === BulletSize.small ? 'padding: 2px 4px' : 'padding: 4px 8px';

  return (
    <span
      css={(theme: ThemeType) => css`
        ${font};
        ${padding};
        color: ${theme.colors.white};
        font-weight: ${theme.typography.weights.bold};
        text-align: center;
        margin: 0 3px 1.5px 0;
        vertical-align: top;
        background-color: #4a4a4a;
        border-radius: 2px;
        line-height: 1.4375;
      `}
    >
      {tripName}
    </span>
  );
};

export default TripMention;
