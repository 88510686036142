const GENERAL_NAME = 'general';
const BODY_NAME = 'body';
const DURATIONS_SOURCE_NAME = 'durationsSource';
const HEADER_NAME = 'header';
const STATION_ALTERNATVIES_NAME = 'stationAlternatives';
const IMPACTS_NAME = 'impacts';
const SCREEN_TARGETING_MESSAGE_HEADLINE_NAME = 'messageHeadline';
const SCREEN_TARGETING_MESSAGE_TITLE_NAME = 'messageTitle';
const SCREEN_TARGETING_SCREENS_NAME = 'screens';
const SCREEN_TARGETING_UPLOAD_NAME = 'uploadId';
const SCREEN_TARGETING_TYPE_NAME = 'targetingTypeSelection';
const SCREEN_TARGETING_NO_SCREEN_WEEK_PARAMS_NAME = 'screenWeekParams';

export const PlannedWorkErrorKeys = {
  general: GENERAL_NAME,
  body: BODY_NAME,
  durationsSource: DURATIONS_SOURCE_NAME,
  header: HEADER_NAME,
  impacts: IMPACTS_NAME,
  stationAlternatives: STATION_ALTERNATVIES_NAME,
};

export const ScreenTargetingErrorKeys = {
  targetingTypeSelection: SCREEN_TARGETING_TYPE_NAME,
  uploadId: SCREEN_TARGETING_UPLOAD_NAME,
  screenWeekParams: SCREEN_TARGETING_NO_SCREEN_WEEK_PARAMS_NAME,
  messageTitle: SCREEN_TARGETING_MESSAGE_TITLE_NAME,
  messageHeadline: SCREEN_TARGETING_MESSAGE_HEADLINE_NAME,
  screens: SCREEN_TARGETING_SCREENS_NAME,
};
