import {
  ZipWriter,
  BlobWriter,
  TextReader,
  Data64URIReader,
} from '@zip.js/zip.js';
import fallbackPortrait from 'images/outfront/fallback-portrait.png';

type AssetRenderParams = {
  filename: string;
};

const templateJSON = JSON.stringify({
  templates: [
    {
      displayName: 'MTA Screen App',
      preview: 'fallback-portrait.png',
      indexTemplate: 'index.html',
      previewOrientation: 'portrait',
    },
  ],
});

export const renderAssetToHtml = ({ filename }: AssetRenderParams) =>
  `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="utf-8">
      <title>MTA Main Template</title>
      <meta name="viewport" content="width=1080">
      <style>
        * {
          margin: 0;
          padding: 0;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      </style>
    </head>
  <body>
    <img src="${filename}">
  </body>
</html>
  `;

export const assetToZip = async (nameOfFile: string, file: File) => {
  const html = renderAssetToHtml({ filename: nameOfFile });
  const zip = new ZipWriter(new BlobWriter('application/zip'));
  await Promise.all([
    zip.add('index.html', new TextReader(html)),
    zip.add('fallback-portrait.png', new Data64URIReader(fallbackPortrait)),
    zip.add(nameOfFile, file.stream()),
    zip.add('template.json', new TextReader(templateJSON)),
  ]);

  return zip.close();
};
