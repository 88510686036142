import { FeedId } from 'types/feeds';
import { CardinalDirection as Directionality } from 'generated/global-types';

enum RouteGTFSIds {
  SIX_X = 'MTASBWY:6X',
  SEVEN_X = 'MTASBWY:7X',
  F_X = 'MTASBWY:FX',
}

export const routeGTFSIdsWithDupeNamedStops: string[] = [
  RouteGTFSIds.SIX_X,
  RouteGTFSIds.SEVEN_X,
  RouteGTFSIds.F_X,
];

type FeedDirectionOptions = { label: string; value: null | Directionality }[];
export const DirectionOptions: {
  [feedId in FeedId]: FeedDirectionOptions;
} = {
  [FeedId.NYCTBus]: [],
  [FeedId.NYCTSubway]: [
    { label: 'Both Directions', value: null },
    { label: 'Northbound', value: Directionality.NORTH },
    { label: 'Southbound', value: Directionality.SOUTH },
  ],
  [FeedId.MNR]: [
    { label: 'Both Directions', value: null },
    { label: 'Northbound', value: Directionality.NORTH },
    { label: 'Southbound', value: Directionality.SOUTH },
  ],
  [FeedId.LIRR]: [
    { label: 'Both Directions', value: null },
    { label: 'Eastbound', value: Directionality.EAST },
    { label: 'Westbound', value: Directionality.WEST },
  ],
  [FeedId.BT]: [],
  [FeedId.OutfrontQA]: [
    { label: 'Both Directions', value: null },
    { label: 'Eastbound', value: Directionality.EAST },
    { label: 'Westbound', value: Directionality.WEST },
  ],
  [FeedId.OutfrontDEV]: [
    { label: 'Both Directions', value: null },
    { label: 'Eastbound', value: Directionality.EAST },
    { label: 'Westbound', value: Directionality.WEST },
  ],
};

export const Terminals: {
  [gtfsRouteId: string]:
    | {
        [Directionality.NORTH]?: string;
        [Directionality.SOUTH]?: string;
        [Directionality.EAST]?: string;
        [Directionality.WEST]?: string;
      }
    | undefined;
} = {
  // * Subway
  //  * SI Northbound: St George
  //  * SI Southbound: Tottenville
  //  * H Northbound: Broad Channel
  //  * H Southbound: Rockaway Park/Rockaway Blvd (During summer months)
  'MTASBWY:1': {
    [Directionality.NORTH]: '242 St',
    [Directionality.SOUTH]: 'South Ferry',
  },
  'MTASBWY:2': {
    [Directionality.NORTH]: 'Wakefield-241 St',
    [Directionality.SOUTH]: 'Flatbush Av/New Lots Av',
  },
  'MTASBWY:3': {
    [Directionality.NORTH]: 'Harlem-148 St',
    [Directionality.SOUTH]: 'New Lots Av/Times Square',
  },
  'MTASBWY:4': {
    [Directionality.NORTH]: 'Woodlawn/Burnside',
    [Directionality.SOUTH]: 'Utica Av/New Lots Av',
  },
  'MTASBWY:5': {
    [Directionality.NORTH]: 'Dyre Av/Nereid Av/E 180',
    [Directionality.SOUTH]: 'Utica Av/Flatbush Av/E 180',
  },
  'MTASBWY:5X': {
    [Directionality.NORTH]: 'Dyre Av',
    [Directionality.SOUTH]: 'Flatbush Ave',
  },
  'MTASBWY:6': {
    [Directionality.NORTH]: 'Pelham Bay Park/Parkchester',
    [Directionality.SOUTH]: 'Brooklyn Bridge',
  },
  'MTASBWY:6X': {
    [Directionality.NORTH]: 'Pelham Bay Park',
    [Directionality.SOUTH]: 'Brooklyn Bridge',
  },
  'MTASBWY:7': {
    [Directionality.NORTH]: 'Flushing-Main St/111 Street',
    [Directionality.SOUTH]: '34 St-Hudson Yds',
  },
  'MTASBWY:7X': {
    [Directionality.NORTH]: 'Flushing-Main St',
    [Directionality.SOUTH]: '34 St-Hudson Yds',
  },
  'MTASBWY:A': {
    [Directionality.NORTH]: 'Inwood-207 St',
    [Directionality.SOUTH]: 'Far Rockaway/Lefferts Blvd/Rockaway Park',
  },
  'MTASBWY:B': {
    [Directionality.NORTH]: '145 St/Bedford Park Blvd',
    [Directionality.SOUTH]: 'Brighton Beach',
  },
  'MTASBWY:C': {
    [Directionality.NORTH]: '168 St',
    [Directionality.SOUTH]: 'Euclid Av',
  },
  'MTASBWY:D': {
    [Directionality.NORTH]: 'Norwood-205 St',
    [Directionality.SOUTH]: 'Coney Island-Stillwell Av',
  },
  'MTASBWY:E': {
    [Directionality.NORTH]: 'Jamaica',
    [Directionality.SOUTH]: 'World Trade Center',
  },
  'MTASBWY:F': {
    [Directionality.NORTH]: 'Jamaica',
    [Directionality.SOUTH]: 'Coney Island',
  },
  'MTASBWY:G': {
    [Directionality.NORTH]: 'Court Sq-23 St',
    [Directionality.SOUTH]: 'Church Av',
  },
  'MTASBWY:J': {
    [Directionality.NORTH]: 'Jamaica',
    [Directionality.SOUTH]: 'Broad St',
  },
  'MTASBWY:L': {
    [Directionality.NORTH]: '8 Av',
    [Directionality.SOUTH]: 'Rockaway/Myrtle-Wyckoff',
  },
  'MTASBWY:M': {
    [Directionality.NORTH]: '71 Av/Essex Street/Myrtle Ave',
    [Directionality.SOUTH]: 'Metropolitan Av',
  },
  'MTASBWY:N': {
    [Directionality.NORTH]: 'Astoria',
    [Directionality.SOUTH]: 'Coney Island',
  },
  'MTASBWY:Q': {
    [Directionality.NORTH]: '96 St',
    [Directionality.SOUTH]: 'Coney Island',
  },
  'MTASBWY:R': {
    [Directionality.NORTH]: '96 St/71 Av',
    [Directionality.SOUTH]: 'Bay Ridge-95 St',
  },
  'MTASBWY:Z': {
    [Directionality.NORTH]: 'Jamaica',
    [Directionality.SOUTH]: 'Broad St',
  },
  'MTASBWY:W': {
    [Directionality.NORTH]: 'Astoria',
    [Directionality.SOUTH]: 'South Ferry',
  },

  // MNR
  'MNR:1': {
    [Directionality.NORTH]: 'Poughkeepsie',
    [Directionality.SOUTH]: 'Grand Central Terminal',
  },
  // MNR:3
  //  TODO: There's no listed New Haven Northbound terminal
  //  * New Haven Southbound: Grand Central Terminal
  'MNR:5': {
    [Directionality.NORTH]: 'Danbury',
    [Directionality.SOUTH]: 'Grand Central Terminal',
  },
  'MNR:4': {
    [Directionality.NORTH]: 'New Canaan',
    [Directionality.SOUTH]: 'Grand Central Terminal',
  },
  'MNR:6': {
    [Directionality.NORTH]: 'Waterbury',
    [Directionality.SOUTH]: 'Grand Central Terminal',
  },
  'MNR:50': {
    [Directionality.NORTH]: 'Spring Valley',
    [Directionality.SOUTH]: 'Pearl River/Penn Station',
  },
  'MNR:51': {
    [Directionality.NORTH]: 'Port Jervis',
    [Directionality.SOUTH]: 'Suffern/Penn Station',
  },
  'MNR:2': {
    [Directionality.NORTH]: 'Southeast/Wassaic',
    [Directionality.SOUTH]: 'Grand Central Terminal',
  },
  'MNR:wassaic': {
    [Directionality.NORTH]: 'Wassaic',
    [Directionality.SOUTH]: 'Patterson/Grand Central Terminal',
  },
  // * LIRR
  'LI:1': {
    // Babylon
    [Directionality.EAST]: 'Babylon',
    [Directionality.WEST]: 'Penn Station',
  },
  'LI:12': {
    // City Zone
    [Directionality.EAST]: 'Jamaica',
    [Directionality.WEST]: 'Atlantic Terminal/Long Island City/Penn Station',
  },
  'LI:7': {
    // Far Rockaway
    [Directionality.EAST]: 'Far Rockaway',
    [Directionality.WEST]: 'Penn Station',
  },
  'LI:2': {
    // Hempstead
    [Directionality.EAST]: 'Hempstead',
    [Directionality.WEST]: 'Penn Station',
  },
  'LI:6': {
    // Long Beach
    [Directionality.EAST]: 'Long Beach',
    [Directionality.WEST]: 'Long Beach',
  },
  'LI:5': {
    // Montauk
    [Directionality.EAST]: 'Montauk',
    [Directionality.WEST]: 'Penn Station',
  },
  'LI:3': {
    // Oyster Bay
    [Directionality.EAST]: 'Oyster Bay',
    [Directionality.WEST]: 'Penn Station',
  },
  'LI:10': {
    // Post Jefferson
    [Directionality.EAST]: 'Port Jefferson',
    [Directionality.WEST]: 'Penn Station',
  },
  'LI:9': {
    // Port Washington
    [Directionality.EAST]: 'Port Washington',
    [Directionality.WEST]: 'Penn Station',
  },
  'LI:4': {
    // Ronkonkoma
    [Directionality.EAST]: 'Ronkonkoma',
    [Directionality.WEST]: 'Penn Station',
  },
  'LI:8': {
    // West Hempstead
    [Directionality.EAST]: 'West Hempstead',
    [Directionality.WEST]: 'Penn Station',
  },
};
