/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';

import { useFeatureFlag, isFeatureEnabled } from 'hooks/useFeatureFlag';
import { useCurrentUser } from 'contexts/CurrentUser';
import PageMeta from 'components/common/PageMeta';
import HR from 'components/common/HR';
import Loader from 'components/common/skeletons/PageWithContent';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import Bullet, { BulletSize } from 'components/common/Bullet';
import getScreenIcon from 'utils/get-screen-icon';
import { BOROUGH_LABELS } from 'utils/boroughs';

import {
  SyntheticScreen as SyntheticScreenType,
  SyntheticScreenVariables,
} from 'generated/SyntheticScreen';
import {
  UpdateSyntheticScreenNotes,
  UpdateSyntheticScreenNotesVariables,
} from 'generated/UpdateSyntheticScreenNotes';
import { FeedId } from '@mta-live-media-manager/shared';

import * as S from './index.styled';
import ScreenLocking from './ScreenLocking';
import SingleScreenCampaign from './single-screen-campaign';
import LockedContent from './LockedContent';
import {
  BlueprintButton,
  ScreenPageHeader,
  InternalNotesEditor,
  NoteSaveState,
  PageWrapper,
  ContentWrapper,
  useNext24hrs,
} from './shared';
import OrderedScreenContent from './OrderedScreenContent';
import { FeatureFlagName } from 'generated/global-types';

const SyntheticScreenQuery = loader('../../../graphql/SyntheticScreen.gql');
const UpdateSyntheticScreenNotesMutation = loader(
  '../../../graphql/UpdateSyntheticScreenNotes.gql',
);

const SyntheticScreen: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const { feedId, id: screenId } = useParams<{ id: string; feedId: FeedId }>();
  const { start, end } = useNext24hrs();
  const currentUser = useCurrentUser();

  const plannedWorkEnabled =
    currentUser && feedId
      ? isFeatureEnabled(
          currentUser,
          FeatureFlagName.PLANNED_WORK,
          feedId as FeedId,
        )
      : false;
  const alertsEnabled =
    currentUser && feedId
      ? isFeatureEnabled(
          currentUser,
          FeatureFlagName.SERVICE_ALERTS,
          feedId as FeedId,
        ) &&
        isFeatureEnabled(
          currentUser,
          FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
          feedId as FeedId,
        )
      : false;

  const { loading, error, data } = useQuery<
    SyntheticScreenType,
    SyntheticScreenVariables
  >(SyntheticScreenQuery, {
    variables: {
      timeframe: {
        start: { value: start, inclusive: true },
        end: { value: end, inclusive: true },
      },
      id: screenId || '',
      plannedWorkEnabled,
      alertsEnabled,
    },
  });

  const [updateSyntheticScreenNotes] = useMutation<
    UpdateSyntheticScreenNotes,
    UpdateSyntheticScreenNotesVariables
  >(UpdateSyntheticScreenNotesMutation);

  const [noteValue, setNoteValue] = useState<string>('');
  const [noteSaveState, setNoteSaveState] = useState<NoteSaveState>(
    NoteSaveState.none,
  );

  const screenLockingEnabled = useFeatureFlag(
    FeatureFlagName.SCREEN_LOCKING_UI,
  );
  const singleScreenCampaignsEnabled = useFeatureFlag(
    FeatureFlagName.ENABLE_SINGLE_SCREEN_CAMPAIGNS,
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNoteSaveState(NoteSaveState.saving);
    try {
      await updateSyntheticScreenNotes({
        variables: {
          id: screenId || '',
          notes: noteValue || '',
        },
      });
      setNoteSaveState(NoteSaveState.success);
    } catch (e) {
      Sentry.captureException(e);
      setNoteSaveState(NoteSaveState.error);
    } finally {
      setTimeout(() => {
        setNoteSaveState(NoteSaveState.none);
      }, 1000);
    }
  };

  if (loading) {
    return (
      <div
        css={css`
          width: 100%;
        `}
      >
        <Loader loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div>Error {error.toString()}</div>;
  }

  if (!data || !data.syntheticScreen) {
    return <div>Error: not found</div>;
  }

  const { syntheticScreen: screen } = data;
  const stop = screen.stops.nodes[0];
  const blueprint: string | null = stop?.blueprintUpload?.signedS3Url || null;
  const borough = stop?.boroughs?.[0] || null;

  const hasActiveContent =
    screen.campaignScreenContent.nodes.length ||
    screen?.plannedWorks?.nodes.length ||
    screen?.alerts?.nodes.length;

  return (
    <PageWrapper>
      <PageMeta title={screen.name} />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}/stops/${stop.gtfsId}/screens`,
          title: 'Back to Station',
        }}
      />
      <ContentWrapper>
        <ScreenPageHeader
          name={screen.name}
          stopName={stop.name ?? ''}
          routeIds={stop.routes.nodes.map((r) => r.gtfsId)}
        />
        <HR />
        <Loader loading={loading && !data}>
          <div>
            <S.DetailsWrapper>
              <S.Details>
                <div>{getScreenIcon(screen, 50, feedId)}</div>
                <p>
                  <b>Station:</b> {stop.name}
                </p>
                <p>
                  <b>Borough:</b> {borough && BOROUGH_LABELS[borough]}
                </p>
                <p>
                  <b>Lines:</b>{' '}
                  {stop.routes.nodes.map((n) => (
                    <React.Fragment key={n.gtfsId}>
                      <Bullet
                        routeId={n.gtfsId}
                        size={BulletSize.small}
                        style={{
                          marginTop: '3px',
                        }}
                      />{' '}
                    </React.Fragment>
                  ))}
                </p>
                {blueprint && <BlueprintButton href={blueprint} />}
              </S.Details>
              <S.InternalNotes>
                <InternalNotesEditor
                  defaultValue={screen.notes || ''}
                  value={noteValue}
                  state={noteSaveState}
                  onChange={(text) => setNoteValue(text)}
                  onSubmit={onSubmit}
                />
              </S.InternalNotes>
            </S.DetailsWrapper>
            {screenLockingEnabled && (
              <S.ContentWrapper>
                <ScreenLocking
                  screenName={screen.name}
                  screenDimensions={{
                    width: screen?.width,
                    height: screen?.height,
                  }}
                  currentScreenLock={screen.currentScreenLock}
                  needsConfirmationBeforeUnlocking={!hasActiveContent}
                  allowZipfiles={false}
                />
              </S.ContentWrapper>
            )}
            {singleScreenCampaignsEnabled && (
              <S.ContentWrapper>
                <SingleScreenCampaign screen={screen} />
              </S.ContentWrapper>
            )}
            <S.ContentWrapper hideSeparator>
              {screen.currentScreenLock ? (
                <React.Fragment>
                  <LockedContent
                    screenLock={screen.currentScreenLock.upload}
                    height={screen.height}
                    width={screen.width}
                  />
                  <ScreenLocking
                    screenName={screen.name}
                    screenDimensions={{
                      width: screen?.width,
                      height: screen?.height,
                    }}
                    currentScreenLock={screen.currentScreenLock}
                    needsConfirmationBeforeUnlocking={!hasActiveContent}
                    updateContent
                    allowZipfiles={false}
                  />
                </React.Fragment>
              ) : (
                <OrderedScreenContent screenWithContent={screen} />
              )}
            </S.ContentWrapper>
          </div>
        </Loader>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default SyntheticScreen;
