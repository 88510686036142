/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import {
  ListboxInput,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox';
import RouteSelector from 'ui-kit/route-selector';
import StandardListboxButton from '../../../ui-kit/standard-listbox-button';
import { listboxList } from '../../common/styles';
import * as styles from './styles';
import { StopSelector as StopSelectorType } from './types';
import useRoutesWithStops from '../../../hooks/useRoutesWithStops';
import { useFeedId } from '../../../contexts/FeedId';
import theme from '../../../theme';

const StopSelector: React.FC<{
  value: StopSelectorType;
  onChange: Function;
  children?: React.ReactNode;
}> = ({ value: { routeGtfsId, stopGtfsId }, onChange }) => {
  const feedId = useFeedId();
  const routes = useRoutesWithStops(feedId);

  const stops =
    (routes ?? []).find((r) => r.gtfsId === routeGtfsId)?.stops?.nodes || [];
  const label = stops.find((s) => s.gtfsId === stopGtfsId)?.name;

  const disabled = !routeGtfsId;
  return (
    <React.Fragment>
      <RouteSelector
        css={css`
          margin-bottom: -1px;
          & > [data-reach-listbox-button] {
            margin: 0;
            border-radius: 4px 0 0 0;
            border-bottom: none;
            border-left: none;
            border-right: none;
          }
        `}
        value={routeGtfsId}
        placeholder="Select Line"
        onChange={(newValue) =>
          onChange({
            routeGtfsId: newValue,
            stopGtfsId,
          })
        }
      />
      <ListboxInput
        css={css`
          ${styles.listbox}
          & > [data-reach-listbox-button] {
            margin: 0;
            border-radius: 0 0 0 4px;
            border-bottom: none;
            border-left: none;
            border-right: none;
            border-top: none;
            cursor: ${disabled ? 'default' : 'pointer'};
          }

          [data-reach-listbox-button] {
            box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
          }

          &[data-state='expanded'],
          &:focus-within {
            [data-reach-listbox-button] {
              box-shadow:
                0 0 0 1px ${theme.colors.accent3},
                inset 0 0 4px ${theme.colors['border-dark']};
              z-index: 1;
              div {
                color: ${theme.colors.accent1};
              }
            }
          }

          &[data-state='expanded'] {
            [data-reach-listbox-button] {
              border-bottom: 0;
              border-radius: unset;
            }
          }
        `}
        disabled={disabled}
        value={stopGtfsId || ''}
        onChange={(newValue) =>
          onChange({
            routeGtfsId,
            stopGtfsId: newValue,
          })
        }
      >
        <StandardListboxButton disabled={disabled}>
          <div
            css={css`
              color: ${disabled ? theme.colors.dark3 : '#707070'};
            `}
          >
            {label || 'Select Station'}
          </div>
        </StandardListboxButton>
        <ListboxPopover>
          <ListboxList css={listboxList}>
            {stops.map((stop) => (
              <ListboxOption key={stop.gtfsId} value={stop.gtfsId}>
                {stop.name}
              </ListboxOption>
            ))}
          </ListboxList>
        </ListboxPopover>
      </ListboxInput>
    </React.Fragment>
  );
};

export default StopSelector;
