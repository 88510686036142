import { useMemo } from 'react';
import { DocumentNode } from 'graphql';
import {
  useQuery,
  useApolloClient,
  useSubscription,
  SubscriptionHookOptions,
  OperationVariables,
} from '@apollo/client';
import { useConnectionStatus } from 'contexts/GraphQL';
import { asSubscription } from '../utils/graphql';

type UseLiveQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  options: SubscriptionHookOptions<TData, TVariables>,
) => {
  data?: TData;
  loading: boolean;
  error?: Error;
};

const useLiveQuery: UseLiveQuery = (document, options) => {
  const client = useApolloClient();

  const { data: cached, previousData } = useQuery(document, {
    ...options,
    fetchPolicy: 'cache-only',
  });

  const subscriptionDocument = useMemo(
    () => asSubscription(document),
    [document],
  );
  // @ts-ignore
  const {
    loading,
    error,
    // @ts-ignore
  } = useSubscription(subscriptionDocument, {
    ...options,
    // eslint-disable-next-line consistent-return
    onData: (arg) => {
      const { data } = arg;
      client.writeQuery({
        query: document,
        variables: options.variables,
        data: data.data,
      });
      if (options.onData) {
        return options.onData(arg);
      }

      return data;
    },
  });

  const { subscriptionClientConnected } = useConnectionStatus();
  return {
    data: typeof cached === 'undefined' ? previousData : cached,
    loading: !cached && !previousData && loading,
    error: !subscriptionClientConnected ? new Error('Connection Error') : error,
  };
};

export default useLiveQuery;
