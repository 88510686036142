import { css } from '@emotion/react';

import theme from '../../../../theme';

export const container = css`
  display: flex;
  margin: 12px 0 12px;
  border: 1px solid ${theme.colors['border-dark']};
  border-radius: 4px;
  background: ${theme.colors['label-background']};
  position: relative;

  .flexSection {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
  }

  label,
  .labelText {
    height: 44px;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0 1.25rem;
    margin: 0;
  }

  .duplicationButton {
    position: absolute;
    right: 16px;
    top: 14px;
  }
`;

export const leftField = css`
  border: 1px solid ${theme.colors.light9};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: ${theme.colors.white};
  overflow: hidden;
`;

export const editorField = css`
  display: block;
  flex-grow: 1;
  border-right: none;
  border-bottom: none;
  border: 1px solid ${theme.colors.light9};
  background: ${theme.colors.white};

  .remirror-editor {
    border-radius: 0 0 4px 0;
    min-height: 92px;
    box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
  }
`;

export const removeBtn = css`
  position: absolute;
  right: 16px;
  top: 14px;
`;
