import { FeedId, useFeedId } from 'contexts/FeedId';
import { CurrentUserFragment as CurrentUserType } from 'generated/CurrentUser.fragment';
import { FeatureFlagName as Feature } from 'generated/global-types';
import { useCurrentUser } from 'contexts/CurrentUser';

export const isFeatureEnabled = (
  user: CurrentUserType,
  feature: Feature,
  feed: FeedId,
): boolean => user.featureFlags?.[feature]?.[feed] ?? false;

export const useFeatureFlag = (feature: Feature): boolean => {
  const currentUser = useCurrentUser();
  const feedId = useFeedId();

  const value = currentUser
    ? isFeatureEnabled(currentUser, feature, feedId)
    : false;
  if (process.env.REACT_APP_LOG_FEATURE_FLAGS === 'true') {
    // eslint-disable-next-line no-console
    console.log(
      `${feature} evaluated to ${value} for user ${currentUser?.email} in feed ${feedId}`,
    );
  }

  return value;
};
