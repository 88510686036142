/** @jsxImportSource @emotion/react */

import { css, SerializedStyles, jsx } from '@emotion/react';
import React from 'react';

import theme from '../../theme';

const {
  typography,
  colors: { accent1, accent3, brand, border, light2, white },
} = theme;

const baseTextStyle = css`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: ${typography.weights.bolder};
  font-family: ${typography.families.primary};
`;

export const baseStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 4px;
  ${baseTextStyle};
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus-visible {
    box-shadow: 0 0 0 2px ${accent3};
  }
`;

const disabledBase = css`
  cursor: default;
  opacity: 0.5;
  outline: none;
`;

const largeStyle = [
  typography.sizes.medium,
  css`
    padding: 12px 15px;
    min-width: 160px;
  `,
];

const mediumStyle = [
  typography.sizes.medium,
  css`
    padding: 10px 15px;
    min-width: 120px;
  `,
];

const mediumLongStyle = [
  typography.sizes.medium,
  css`
    height: 40px;
    min-width: 140px;
  `,
];

const smallStyle = [
  typography.sizes.xsmall,
  css`
    padding: 6px;
    min-height: 32px;
    min-width: 100px;
  `,
];

const xSmallStyle = [
  typography.sizes.xsmall,
  css`
    padding: 6px;
    min-height: 32px;
    min-width: 80px;
  `,
];

const xxSmallStyle = [
  typography.sizes.xsmall,
  css`
    padding: 5px 10px;
    min-width: 0;
  `,
];

export const primaryStyle = css`
  color: ${white};
  background-color: ${accent1};
  border-color: ${accent1};

  &:hover {
    background-color: ${brand};
    border-color: ${brand};
    outline: ${brand} auto 1px;
  }

  &:disabled {
    ${disabledBase};
    background-color: ${border};
    border-color: ${border};
    color: ${light2};
  }
`;

export const secondaryStyle = css`
  color: ${accent1};
  background-color: ${white};
  border-color: ${accent1};

  &:hover {
    color: ${brand};
    border-color: ${brand};
    outline: ${brand} auto 1px;
  }

  &:disabled {
    ${disabledBase};
    color: ${light2};
    border-color: ${light2};
  }
`;

export const plainStyle = css`
  ${baseTextStyle};
  color: ${accent1};

  &:hover {
    color: ${brand};
  }

  &:disabled {
    ${disabledBase};
    color: ${light2};
  }

  outline: none;
  border-radius: 4px;
  &:focus-visible {
    box-shadow: 0 0 0 1px ${accent3};
  }
`;

export const styles = {
  large: largeStyle,
  medium: mediumStyle,
  mediumLong: mediumLongStyle,
  small: smallStyle,
  xsmall: xSmallStyle,
  xxsmall: xxSmallStyle,
};

const Button: React.FC<
  {
    as?: React.ElementType<any>;
    color?: string;
    primary?: boolean;
    plain?: boolean;
    size?: 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall' | 'mediumLong';
    additionalStyles?: SerializedStyles;
  } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { [k: string]: any }
> & { children?: React.ReactNode } = ({
  as = 'button',
  plain,
  primary = false,
  size = 'large',
  children,
  additionalStyles,
  ...rest
}) => {
  if (plain) {
    return jsx(as, {
      css: [typography.sizes.xsmall, plainStyle, additionalStyles],
      children,
      ...rest,
    });
  }

  return jsx(as, {
    css: [
      baseStyle,
      styles[size],
      primary ? primaryStyle : secondaryStyle,
      additionalStyles,
    ],
    children,
    ...rest,
  });
};

export default Button;
