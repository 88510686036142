import { Borough } from 'generated/global-types';
import { SetValueAction } from 'react-select';

export enum TargetingOptionType {
  ALL_ROUTE_OPTION = 'ALL_ROUTE_OPTION',
  ALL_BOROUGH_OPTION = 'ALL_BOROUGH_OPTION',
  STOP_OPTION = 'STOP_OPTION',
}

export type TargetingOption = {
  key: string;
  label: string;
  optionType: TargetingOptionType;
  feedId: string;
  bulletValue?: string;
  borough?: Borough | null;
  routeId?: string;
  stopId?: string;
  screensAvailable?: boolean;
};

export type BoroughGroup = {
  key: string;
  label: string;
  borough: Borough;
  options: TargetingOption[];
};

export function isBoroughGroup(obj: any): obj is BoroughGroup {
  return obj.borough !== undefined && obj.options !== undefined;
}

// Returns a list of the options that can be ticked inside a stop selector
export const getTargetingOptions = ({
  options,
  borough,
}: {
  options: (BoroughGroup | TargetingOption)[];
  borough?: Borough | null;
}): TargetingOption[] =>
  options.reduce<TargetingOption[]>((options, option) => {
    if (!borough || option.borough === borough) {
      if (isBoroughGroup(option)) {
        options.push(...option.options);
      } else {
        options.push(option);
      }
    }

    return options;
  }, []);

export const getKeysFromOptions = ({
  options,
  borough,
  onlyStops,
}: {
  options: (BoroughGroup | TargetingOption)[];
  borough?: Borough | null;
  onlyStops?: boolean;
}): string[] =>
  options.reduce<string[]>((options, option) => {
    if (!borough || option.borough === borough) {
      if (isBoroughGroup(option)) {
        let boroughOptions = option.options;

        if (onlyStops) {
          boroughOptions = boroughOptions.filter(
            (o) => o.optionType === TargetingOptionType.STOP_OPTION,
          );
        }

        options.push(...boroughOptions.map((o) => o.key));
      } else if (
        !onlyStops ||
        (onlyStops && option.optionType === TargetingOptionType.STOP_OPTION)
      ) {
        options.push(option.key);
      }
    }

    return options;
  }, []);

/**
 * @param newSelectedOptions The list of newly selected options returned from the react-select inside the stop selectors.
 * @param selectedOption Selected option, wether selected or unselected.
 * @param allOptions A list of all stop options, including the all boroughs and the all stops options.
 * @param actionType Select or deselect actions.
 * @returns The options that should be ticked in stop selector.
 */
export const getStopSelectorOptions = (
  newSelectedOptions: TargetingOption[],
  selectedOption: TargetingOption,
  allOptions: (TargetingOption | BoroughGroup)[],
  actionType: SetValueAction,
): TargetingOption[] => {
  const newOptions: TargetingOption[] = [];

  if (actionType === 'select-option') {
    if (selectedOption.optionType === TargetingOptionType.ALL_ROUTE_OPTION) {
      newOptions.push(...getTargetingOptions({ options: allOptions }));
    } else if (
      selectedOption.optionType === TargetingOptionType.ALL_BOROUGH_OPTION
    ) {
      newOptions.push(
        ...newSelectedOptions.filter(
          (o) => o.borough !== selectedOption.borough,
        ),
        ...getTargetingOptions({
          options: allOptions,
          borough: selectedOption.borough,
        }),
      );
    } else {
      newOptions.push(...newSelectedOptions);

      // Check if the all borough options should be selected
      if (selectedOption.borough) {
        const boroughGroup = allOptions.find(
          (o) => isBoroughGroup(o) && o.borough === selectedOption.borough,
        ) as BoroughGroup;
        const boroughStops = getKeysFromOptions({
          options: boroughGroup.options,
          borough: selectedOption.borough,
          onlyStops: true,
        });
        const selectedBoroughOptions = new Set(
          getKeysFromOptions({
            options: newOptions,
            borough: selectedOption.borough,
            onlyStops: true,
          }),
        );

        if (boroughStops.every((key) => selectedBoroughOptions.has(key))) {
          // The first option in the borough group is the all boroughs option
          newOptions.push(boroughGroup.options[0]);
        }
      }
    }

    // Check if the all lines option should be selected
    if (selectedOption.optionType !== TargetingOptionType.ALL_ROUTE_OPTION) {
      const allStops = getKeysFromOptions({
        options: allOptions,
        onlyStops: true,
      });
      const allSelectedStops = getKeysFromOptions({
        options: newOptions,
        onlyStops: true,
      });

      if (allStops.length === allSelectedStops.length) {
        // The first option is the all stations option
        newOptions.push(allOptions[0] as TargetingOption);
      }
    }
  } else if (actionType === 'deselect-option') {
    // Check if all of a borough's stop options should be deselected
    if (selectedOption.optionType === TargetingOptionType.ALL_BOROUGH_OPTION) {
      newOptions.push(
        ...newSelectedOptions.filter(
          (o) =>
            !(
              o.borough === selectedOption.borough ||
              o.optionType === TargetingOptionType.ALL_ROUTE_OPTION
            ),
        ),
      );
    } else if (selectedOption.optionType === TargetingOptionType.STOP_OPTION) {
      // Deselect a stop option as well as its borough
      newOptions.push(
        ...newSelectedOptions.filter(
          (o) =>
            !(
              o.key === selectedOption.key ||
              o.optionType === TargetingOptionType.ALL_ROUTE_OPTION ||
              (o.borough &&
                o.optionType === TargetingOptionType.ALL_BOROUGH_OPTION &&
                o.borough === selectedOption.borough)
            ),
        ),
      );
    }
  }

  return newOptions;
};
