/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useFormContext } from 'react-hook-form';
import _flatMap from 'lodash/flatMap';
import _uniq from 'lodash/uniq';
import { useParams } from 'react-router-dom';
import { ActionMeta } from 'react-select';
import { sortedRoutes } from '@mta-live-media-manager/shared';
import DisplayFrequencySelector, {
  ALERTS_DEFAULT_DISP_FREQUENCY,
  DispFreqMessageType,
  DispFreqOption,
  getDispFreqOption,
} from 'components/common/form-elements/display-frequency-selector';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import {
  useRouteRangeSelectorRouteStopsQuery,
  useStopsByRoute,
  RouteRangeStopDisplayGroup,
} from 'ui-kit/route-range-stop-selector';
import {
  AFFECTED_STOPS_NAME,
  CONFLICT_MESSAGE,
  SCREENS_NAME,
  SCREEN_TARGETING_EDITED,
  SCREEN_TARGETING_NAME,
  SCREEN_UPDATE_METHOD_NAME,
  WEIGHT_FREQUENCY_NAME,
} from 'constants/alerts';
import {
  TargetingOption,
  TargetingOptionType,
  BoroughGroup,
  getStopSelectorOptions,
} from 'utils/stop-selector-helpers';
import { getScreenSubtitle } from 'components/pages/Screen/in-screens-preview/helpers';
import { modifyIndexInArray } from 'utils/compose-helpers';
import { NON_AD_SCREEN_OPTIONS } from 'constants/screens';
import theme from '../../../theme';
import {
  ScreenSelection,
  ScreenSelectionVariables,
  ScreenSelection_routes_RoutesConnection_nodes_Route as OrderedRoute,
} from '../../../generated/ScreenSelection';
import { ScreenData, ScreenTarget, ScreenUpdateMethods } from '../../../types';
import {
  Borough,
  FeatureFlagName,
  TargetType,
} from '../../../generated/global-types';
import { BOROUGH_LABELS, boroughOptionLabel } from '../../../utils/boroughs';
import {
  defaultTargetForRouteId,
  findRoute,
  changeTargetRoute,
  removeTargetRoute,
  getAlertDefaultScreenTargetTypes,
  getGroupedTargetingTypes,
} from '../../../utils/screen-targeting';
import { useFeedId } from '../../../contexts/FeedId';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import { ReactComponent as CrossIcon } from '../../../images/cross.svg';

import * as S from './index.styled';
import {
  getStopType,
  getVehicleName,
  isOutfront,
} from '../../../utils/feed-switches';
import { FeedId } from '../../../types/feeds';
import RouteSelector from './RouteSelector';
import StopSelector from './StopSelector';
import ScreenTypeSelector, { COMBINED_CICS } from './ScreenTypeSelector';
import CustomTagsSelector from '../form-elements/custom-tags-selector';
import StatusBanner from '../status-banner';
import Heading from '../Heading';
import {
  EditRestoreAction,
  SelectUnselectAllAction,
} from '../selector-actions';
import Button from '../Button';
import TargetingAccordionLabel from '../screen-targeting-accordion-label';

const ScreenSelectionQuery = loader('../../../graphql/ScreenSelection.gql');

const optionsForRoute = (
  route: OrderedRoute,
  feedId: FeedId,
): (TargetingOption | BoroughGroup)[] => {
  const bulletValue = route.gtfsId;

  const stopsWithoutBoroughs: TargetingOption[] = [];
  const stopsByBorough = {} as {
    [key in Borough]: TargetingOption[];
  };

  route.orderedStopsByDirection.nodes.forEach((stop) => {
    const stopOption: TargetingOption = {
      key: stop.gtfsId,
      label: stop.name || stop.gtfsId,
      stopId: stop.gtfsId,
      borough: stop.borough,
      screensAvailable: stop.screensAvailable,
      feedId,
      optionType: TargetingOptionType.STOP_OPTION,
    };

    if (!stop.borough) {
      stopsWithoutBoroughs.push(stopOption);
    } else {
      if (!stopsByBorough[stop.borough]) {
        stopsByBorough[stop.borough] = [];
      }

      stopsByBorough[stop.borough].push(stopOption);
    }
  });

  const boroughGroups: BoroughGroup[] = Object.keys(stopsByBorough).map(
    (boroughKey) => {
      const borough = boroughKey as Borough;

      return {
        key: borough,
        label: BOROUGH_LABELS[borough],
        borough,
        bulletValue,
        options: [
          {
            key: borough,
            label: boroughOptionLabel(borough, feedId),
            borough,
            feedId,
            optionType: TargetingOptionType.ALL_BOROUGH_OPTION,
          },
          ...stopsByBorough[borough],
        ],
      };
    },
  );

  return [
    ...(defaultTargetForRouteId(
      route.gtfsId,
      route.shortName || route.longName || '',
      feedId,
    ).options as TargetingOption[]),
    ...boroughGroups,
    ...stopsWithoutBoroughs,
  ];
};

const stationsFromScreenTargeting = (
  screenTargeting: ScreenTarget[],
  stopsByRoute: { [gtfsRouteId: string]: string[] },
) => {
  return _flatMap(
    _flatMap(
      screenTargeting ?? [],
      (option: BoroughGroup) => option.options ?? [],
    ),
    (stop: any) =>
      stop.stopId ??
      _flatMap(
        stop.stops ?? stopsByRoute[stop.routeId],
        (opt) => opt.stopId ?? opt.gtfsStopId,
      ),
  );
};

const gtfsRouteIdsFromScreenTargeting = (screenTargeting: ScreenTarget[]) => {
  return _uniq(screenTargeting.map((target: ScreenTarget) => target?.routeId));
};

const layoutsFromScreenTargeting = (targets: ScreenTarget[]) => {
  return targets.reduce(
    (
      screensByRoute: {
        [gtfsRouteId: string]: TargetType[];
      },
      option: ScreenTarget,
    ) => {
      if (option.routeId) {
        return option.layouts
          ? {
              ...screensByRoute,
              [option.routeId]: option.layouts,
            }
          : {
              ...screensByRoute,
              [option.routeId]: [TargetType.NON_AD],
            };
      }
      return screensByRoute;
    },
    {},
  );
};

interface ScreenConflict {
  screen: ScreenTarget[];
  index: number;
  routeId: string;
}

const ScreenSelector: React.FC<
  {
    onRemoveScreenTarget?: (routeId: string) => void;
    index: number;
    isSidewalkLocked: boolean;
    isUpdating?: boolean;
  } & { children?: React.ReactNode }
> = ({
  onRemoveScreenTarget = () => {},
  index,
  isSidewalkLocked,
  isUpdating,
}) => {
  const feedId = useFeedId();
  const { id } = useParams<{ id: string }>();
  const vehicleName = getVehicleName(feedId);
  const stopOrStationText = getStopType(feedId);
  const weightingEnabled = useFeatureFlag(FeatureFlagName.WEIGHTING_FREQUENCY);
  const [showScreensEdit, setShowScreensEdit] = React.useState(true);
  const [conflictingRoutes, setConflictingRoutes] = React.useState<
    Array<Array<ScreenConflict>>
  >([]);

  const { error, data } = useQuery<ScreenSelection, ScreenSelectionVariables>(
    ScreenSelectionQuery,
    {
      variables: {
        feedId: isOutfront(feedId) ? FeedId.NYCTSubway : feedId,
      },
    },
  );

  const { watch, setValue, clearError, errors, register } = useFormContext();

  const screenTargetingKey = `${SCREEN_TARGETING_NAME}_${index}`;

  useEffect(() => {
    if (weightingEnabled) {
      register({ name: WEIGHT_FREQUENCY_NAME });
    }
  }, [register, weightingEnabled]);

  const defaultLayouts = isSidewalkLocked
    ? [TargetType.DUP]
    : getAlertDefaultScreenTargetTypes(feedId);

  const screens = watch(SCREENS_NAME) as ScreenData[];
  const currentScreenTargeting = screens[index][SCREEN_TARGETING_NAME] ?? [
    {
      routeId: '',
      options: [],
      layouts: defaultLayouts,
      tags: {},
    },
  ];
  const affectedStops = watch(AFFECTED_STOPS_NAME) as any;
  const gtfsRouteIds = gtfsRouteIdsFromScreenTargeting(currentScreenTargeting);
  const tags = currentScreenTargeting.reduce(
    (agg, cur) => ({ ...agg, ...cur.tags }),
    {},
  );

  const [originalScreenTargeting, setOriginalScreenTargeting] = React.useState(
    {},
  );
  const { data: stops } = useRouteRangeSelectorRouteStopsQuery(gtfsRouteIds);
  const stopsByRoute = useStopsByRoute(stops);
  const {
    [index]: { [SCREEN_TARGETING_EDITED]: screenTargetingEdited },
  } = screens;

  const targetedScreensLayout = layoutsFromScreenTargeting(
    currentScreenTargeting,
  );
  const gtfsStopIds = stationsFromScreenTargeting(
    currentScreenTargeting,
    stopsByRoute,
  );

  useEffect(() => {
    if (!screenTargetingEdited) {
      setOriginalScreenTargeting(currentScreenTargeting);
      if (currentScreenTargeting[0]?.routeId && isUpdating) {
        setShowScreensEdit(false);
      }
    }
    if (
      currentScreenTargeting[0]?.routeId === '' &&
      currentScreenTargeting.length === 1
    ) {
      setShowScreensEdit(true);
    }
  }, [screenTargetingEdited, currentScreenTargeting, isUpdating]);

  const checkConflictingRoutes = useCallback(() => {
    const {
      [index]: { [SCREEN_TARGETING_NAME]: thisScreenTargeting },
    } = screens;

    const conflictingRoutesArray: Array<Array<ScreenConflict>> = [];

    if (screens.length > 1) {
      thisScreenTargeting.forEach((target) => {
        const currentTargetingStops = new Set(
          target.options
            .filter((st) => st.optionType === TargetingOptionType.STOP_OPTION)
            .map((st) => st.stopId),
        );

        const tempConflictingRoutes: Array<ScreenConflict> = [];
        tempConflictingRoutes.push({
          screen: currentScreenTargeting,
          index,
          routeId: target.routeId,
        });
        screens.forEach((screenData, i) => {
          if (
            i !== index &&
            target.routeId &&
            screenData[SCREEN_UPDATE_METHOD_NAME] !==
              ScreenUpdateMethods.REMOVE &&
            screenData[SCREEN_TARGETING_NAME].some((st) => {
              const hasConflictingStops = st.options?.some((o) =>
                currentTargetingStops.has(o.stopId),
              );

              const hasConflictingScreenTargeting =
                (st.layouts[0] === TargetType.NON_AD &&
                  NON_AD_SCREEN_OPTIONS[feedId].some((targetType) =>
                    target.layouts.includes(targetType),
                  )) ||
                (target.layouts[0] === TargetType.NON_AD &&
                  NON_AD_SCREEN_OPTIONS[feedId].some((targetType) =>
                    st.layouts.includes(targetType),
                  )) ||
                st.layouts.some((layout) => target.layouts.includes(layout));

              return hasConflictingStops && hasConflictingScreenTargeting;
            })
          ) {
            tempConflictingRoutes.push({
              screen: screenData[SCREEN_TARGETING_NAME],
              index: i,
              routeId: target.routeId,
            });
          }
        });
        if (tempConflictingRoutes.length) {
          conflictingRoutesArray.push(tempConflictingRoutes);
        }
      });
      setConflictingRoutes(conflictingRoutesArray);
    } else if (conflictingRoutes.length) {
      // If we only have one screen, and there are conflicting routes (from a previous screen),
      // clear the conflicting routes. We check conflicting routes length to avoid doing a
      // useless setState.
      setConflictingRoutes([]);
    }
  }, [
    screens,
    currentScreenTargeting,
    index,
    feedId,
    conflictingRoutes.length,
  ]);

  useEffect(() => {
    checkConflictingRoutes();
  }, [screens, currentScreenTargeting, index, checkConflictingRoutes]);

  if (error) {
    return <div>Error {error.message}</div>;
  }

  if (!data || !data.routes || !screens) {
    return null;
  }

  const isErrorMessageVisible = errors
    ? errors[screenTargetingKey]?.type === screenTargetingKey
    : false;

  const nodes = sortedRoutes(data.routes.nodes);

  const onChangeOptions = (
    options: TargetingOption[],
    actionMeta: ActionMeta<TargetingOption>,
    idx: number,
    allOptions: (TargetingOption | BoroughGroup)[],
    routeId: string,
  ) => {
    if (!routeId) {
      return;
    }

    const {
      [index]: { [SCREEN_TARGETING_NAME]: oldScreenTargeting },
    } = screens;
    if (!oldScreenTargeting) {
      return;
    }

    const newOptions = getStopSelectorOptions(
      options,
      actionMeta.option as any,
      allOptions,
      actionMeta.action as any,
    );

    clearError(screenTargetingKey);

    setValue(
      SCREENS_NAME,
      modifyIndexInArray({
        index,
        array: screens,
        value: {
          ...screens[index],
          [SCREEN_TARGETING_NAME]: changeTargetRoute(oldScreenTargeting, idx, {
            routeId,
            options: newOptions,
            layouts: oldScreenTargeting?.[0]?.layouts ?? defaultLayouts,
            tags: oldScreenTargeting?.[0]?.tags ?? undefined,
          }),
          [SCREEN_TARGETING_EDITED]: true,
        },
      }),
    );
  };

  const onChangeRoute = (newRouteId: string, idx: number) => {
    const {
      [index]: { [SCREEN_TARGETING_NAME]: oldScreenTargeting },
    } = screens;
    const oldLayouts = oldScreenTargeting?.[idx]?.layouts;
    const oldTags = oldScreenTargeting?.[idx]?.tags;

    clearError(screenTargetingKey);
    setValue(
      SCREENS_NAME,
      modifyIndexInArray({
        index,
        array: screens,
        value: {
          ...screens[index],
          [SCREEN_TARGETING_NAME]: changeTargetRoute(oldScreenTargeting, idx, {
            options: [],
            routeId: newRouteId,
            layouts: oldLayouts ?? defaultLayouts,
            tags: oldTags,
          }),
          [SCREEN_TARGETING_EDITED]: true,
        },
      }),
    );
  };

  const removeScreenTarget = (routeId: string) => {
    setValue(
      SCREENS_NAME,
      modifyIndexInArray({
        index,
        array: screens,
        value: {
          ...screens[index],
          [SCREEN_TARGETING_NAME]: removeTargetRoute(
            currentScreenTargeting,
            routeId,
          ),
          [SCREEN_TARGETING_EDITED]: true,
        },
      }),
    );
    onRemoveScreenTarget(routeId);
  };

  const undoScreenTargetingOption = () => {
    setValue(
      SCREENS_NAME,
      modifyIndexInArray({
        index,
        array: screens,
        value: {
          ...screens[index],
          [SCREEN_TARGETING_EDITED]: false,
        },
      }),
    );
    if (originalScreenTargeting) {
      setValue(
        SCREENS_NAME,
        modifyIndexInArray({
          index,
          array: screens,
          value: {
            ...screens[index],
            [SCREEN_TARGETING_NAME]: originalScreenTargeting,
          },
        }),
      );
    }
    setShowScreensEdit(true);
  };

  const editScreenTargetingOption = () => {
    setShowScreensEdit(true);
  };

  const allRouteOptions = nodes.map((route, index) => ({
    index,
    feedId,
    label: `All ${
      route.shortName || route.longName
    } ${vehicleName} ${stopOrStationText}`,
    bulletValue: route.gtfsId,
    key: route.gtfsId,
    routeId: route.gtfsId,
  }));

  const selectedRouteOptions = allRouteOptions.filter((route) =>
    currentScreenTargeting.find(({ routeId }) => route.routeId === routeId),
  );

  const numUnselected: number =
    allRouteOptions.length - selectedRouteOptions.length;

  const weightFrequency = screens[index][
    WEIGHT_FREQUENCY_NAME
  ] as DispFreqOption;

  const solveConflictForTarget = ({
    correctIndex,
  }: {
    correctIndex: number;
  }) => {
    // Stops to keep
    const otherTargetingStops = new Set(
      screens[correctIndex][SCREEN_TARGETING_NAME].flatMap((st) =>
        st.options
          .filter((o) => o.optionType === TargetingOptionType.STOP_OPTION)
          .map((o) => {
            return o.key;
          }),
      ),
    );

    setValue(
      SCREENS_NAME,
      screens.map((v, i) => {
        const filteredRoutes: ScreenTarget[] = [];
        if (i !== correctIndex) {
          v[SCREEN_TARGETING_NAME].forEach((target) => {
            const stopsToRemove = target.options.filter(
              (o) =>
                o.optionType === TargetingOptionType.STOP_OPTION &&
                otherTargetingStops.has(o.key),
            );

            const boroughsToRemove = new Set(
              stopsToRemove.map((o) => o.borough),
            );

            filteredRoutes.push({
              routeId: target.routeId,
              options: stopsToRemove.length
                ? target.options?.filter(
                    (o) =>
                      (o.optionType === TargetingOptionType.STOP_OPTION &&
                        !otherTargetingStops.has(o.key)) ||
                      (o.optionType ===
                        TargetingOptionType.ALL_BOROUGH_OPTION &&
                        !boroughsToRemove.has(o.key as Borough)),
                  )
                : target.options,
              layouts: target.layouts,
            });
          });

          return {
            ...v,
            [SCREEN_TARGETING_NAME]: filteredRoutes,
          };
        }
        return v;
      }),
    );
  };

  const SelectorAction = () => {
    if (!currentScreenTargeting[0]?.routeId) {
      return null;
    }

    if (!!id && !showScreensEdit) {
      return (
        <EditRestoreAction
          showScreensEdit={showScreensEdit}
          onEdit={editScreenTargetingOption}
          onUndo={undoScreenTargetingOption}
        />
      );
    }

    const {
      [index]: { [SCREEN_TARGETING_NAME]: oldScreenTargeting },
    } = screens;

    const areAllselected = currentScreenTargeting.every(
      ({ routeId, options, layouts, tags }) =>
        !!routeId &&
        layouts.length === 1 &&
        (layouts[0] === TargetType.NON_AD || layouts[0] === TargetType.DUP) &&
        options.some(
          ({ optionType }) =>
            optionType === TargetingOptionType.ALL_ROUTE_OPTION,
        ) &&
        tags &&
        !Object.keys(tags).length,
    );
    const noLinesSelected = currentScreenTargeting.every(
      ({ routeId }) => !routeId,
    );
    const handleAllAction = ({ action }: { action: 'select' | 'unselect' }) => {
      clearError(screenTargetingKey);
      const shouldSelect = action === 'select';
      setValue(
        SCREENS_NAME,
        modifyIndexInArray({
          index,
          array: screens,
          value: {
            ...screens[index],
            [SCREEN_TARGETING_NAME]: oldScreenTargeting.map((st) => {
              const { routeId, layouts: targetingLayouts } = st;
              if (!routeId) {
                return st;
              }
              const options = (() => {
                if (!shouldSelect) {
                  return [];
                }
                const route = nodes.find(({ gtfsId }) => gtfsId === routeId);
                if (!route) {
                  return [];
                }
                return optionsForRoute(route, feedId).flatMap(
                  (s) => (s as BoroughGroup).options ?? s,
                );
              })();
              const layouts = (() => {
                const isDup =
                  targetingLayouts.length === 1 &&
                  targetingLayouts[0] === TargetType.DUP;
                if (isDup) {
                  return [TargetType.DUP];
                }
                if (shouldSelect) {
                  return [TargetType.NON_AD];
                }
                return defaultLayouts;
              })();
              return {
                routeId,
                options,
                layouts,
                tags: shouldSelect ? {} : undefined,
              };
            }),
            [SCREEN_TARGETING_EDITED]: true,
          },
        }),
      );
    };

    return (
      <SelectUnselectAllAction
        areAllSelected={areAllselected}
        onSelectAll={() => handleAllAction({ action: 'select' })}
        onUnselectAll={() => handleAllAction({ action: 'unselect' })}
        hideAction={noLinesSelected}
      />
    );
  };

  return (
    <div id={screenTargetingKey}>
      <Heading
        level={3}
        css={css`
          margin-top: 24px;
          margin-bottom: 8px;
        `}
        size="medium"
        id={SCREEN_TARGETING_NAME}
      >
        Station & Screen
        <SelectorAction />
      </Heading>
      {showScreensEdit ? (
        <React.Fragment>
          {currentScreenTargeting.map(({ routeId, tags }, idx) => {
            const route = nodes.find((node) => node.gtfsId === routeId);
            const options = route ? optionsForRoute(route, feedId) : [];
            const routeInScreenTargeting = findRoute(
              currentScreenTargeting,
              routeId,
            );
            return (
              <S.Row key={route ? route.gtfsId : idx}>
                <S.SelectCol
                  css={css`
                    box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                  `}
                >
                  <S.SelectContainer>
                    <RouteSelector
                      classNamePrefix="screen-selector"
                      routeId={routeId}
                      allRouteOptions={allRouteOptions}
                      selectedRouteOptions={selectedRouteOptions}
                      onChange={({ routeId: newRouteId }) =>
                        onChangeRoute(newRouteId, idx)
                      }
                    />
                  </S.SelectContainer>
                  <S.SelectContainer>
                    <StopSelector
                      classNamePrefix="screen-selector"
                      options={options}
                      routeId={routeId}
                      routeInScreenTargeting={routeInScreenTargeting}
                      onChange={(newOptions, actionMeta) =>
                        onChangeOptions(
                          newOptions,
                          actionMeta,
                          idx,
                          options,
                          routeId,
                        )
                      }
                      showScreensAvailable
                    />
                  </S.SelectContainer>
                  <S.SelectContainer>
                    <ScreenTypeSelector
                      classNamePrefix="screen-selector"
                      feedId={feedId}
                      routeId={routeId}
                      screenTargeting={currentScreenTargeting}
                      isSidewalkLocked={isSidewalkLocked}
                      onChange={(layouts: TargetType[]) => {
                        const {
                          [index]: {
                            [SCREEN_TARGETING_NAME]: oldScreenTargeting,
                          },
                        } = screens;

                        const target = findRoute(oldScreenTargeting, routeId);

                        if (target) {
                          clearError(screenTargetingKey);
                          setValue(
                            SCREENS_NAME,
                            modifyIndexInArray({
                              index,
                              array: screens,
                              value: {
                                ...screens[index],
                                [SCREEN_TARGETING_NAME]: changeTargetRoute(
                                  oldScreenTargeting,
                                  idx,
                                  {
                                    ...target,
                                    layouts: getGroupedTargetingTypes({
                                      updated: layouts,
                                      feedId,
                                      groupedCICs: COMBINED_CICS[feedId],
                                    }),
                                  },
                                ),
                                [SCREEN_TARGETING_EDITED]: true,
                              },
                            }),
                          );
                        }
                      }}
                    />
                  </S.SelectContainer>
                  <S.SelectContainer>
                    <CustomTagsSelector
                      value={tags}
                      classNamePrefix="screen-tags-selector"
                      onChange={(
                        tags: { [key: string]: string[] } | undefined,
                      ) => {
                        const {
                          [index]: {
                            [SCREEN_TARGETING_NAME]: oldScreenTargeting,
                          },
                        } = screens;

                        const target = findRoute(oldScreenTargeting, routeId);

                        if (target) {
                          setValue(
                            SCREENS_NAME,
                            modifyIndexInArray({
                              index,
                              array: screens,
                              value: {
                                ...screens[index],
                                [SCREEN_TARGETING_NAME]: changeTargetRoute(
                                  oldScreenTargeting,
                                  idx,
                                  {
                                    ...target,
                                    tags,
                                  },
                                ),
                                [SCREEN_TARGETING_EDITED]: true,
                              },
                            }),
                          );
                        }
                      }}
                    />
                  </S.SelectContainer>
                </S.SelectCol>
                <S.RemoveButtonBox>
                  {Object.keys(screens[index][SCREEN_TARGETING_NAME]).length >
                    1 && (
                    <Button
                      plain
                      type="button"
                      aria-label="Remove target route"
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        removeScreenTarget(routeId);
                      }}
                    >
                      <CrossIcon
                        css={css`
                          stroke: ${theme.colors.accent1};
                        `}
                      />
                    </Button>
                  )}
                </S.RemoveButtonBox>
              </S.Row>
            );
          })}
          {numUnselected > 0 && (
            <S.AddScreens
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setValue(
                  SCREENS_NAME,
                  modifyIndexInArray({
                    index,
                    array: screens,
                    value: {
                      ...screens[index],
                      [SCREEN_TARGETING_NAME]: [
                        ...currentScreenTargeting,
                        {
                          routeId: '',
                          options: [],
                          layouts: defaultLayouts,
                        },
                      ],
                      [SCREEN_TARGETING_EDITED]: true,
                    },
                  }),
                );
              }}
            >
              <PlusIcon /> add lines
            </S.AddScreens>
          )}
          <StatusBanner
            css={css`
              margin-top: 16px;
            `}
            status="error"
            text="Please select a Station & Screen"
            isVisible={isErrorMessageVisible}
            hasIcon
          />
        </React.Fragment>
      ) : (
        <RouteRangeStopDisplayGroup
          css={css`
            margin: 12px 0;
          `}
          gtfsRouteIds={gtfsRouteIds}
          stopsByRoute={stopsByRoute}
          gtfsStopIds={gtfsStopIds}
          directionality={affectedStops.directionality}
          screens={targetedScreensLayout}
          tags={tags}
          additionalStyles={css`
            margin-top: 12px;
            max-width: 400px;
            justify-content: space-between;
            &:first-of-type {
              margin-top: 0;
            }
            > div {
              margin-top: 4px;
              &:first-of-type {
                margin-top: 0;
              }
            }
          `}
        />
      )}
      {conflictingRoutes.length > 0 &&
        conflictingRoutes.map((conflicts, index) => {
          const key = `conflict-warning-${index}`;
          return (
            conflicts.length > 1 && (
              <S.Warning
                key={key}
                title="Conflicting Messages"
                message={CONFLICT_MESSAGE}
              >
                <ul>
                  {conflicts
                    .sort((a, b) => a.index - b.index)
                    .map((route) => {
                      const { routeIds, stopsIds } = route.screen.reduce<{
                        routeIds: string[];
                        stopsIds: string[];
                      }>(
                        (selections, selector) => {
                          selections.routeIds.push(selector.routeId);
                          selector.options.forEach((o) => {
                            if (o.stopId) {
                              selections.stopsIds.push(o.stopId);
                            }
                          });
                          return selections;
                        },
                        {
                          routeIds: [],
                          stopsIds: [],
                        },
                      );

                      return (
                        <li key={`conflict-${route.index}-${route.routeId}`}>
                          <Button
                            plain
                            style={{ color: 'initial', lineHeight: 'initial' }}
                            type="button"
                            onClick={() =>
                              solveConflictForTarget({
                                correctIndex: route.index,
                              })
                            }
                          >
                            <TargetingAccordionLabel
                              routeIds={routeIds}
                              stopIds={stopsIds}
                              screenNumber={index + 1}
                            />

                            <div
                              css={css`
                                font-weight: 400;
                                display: inline-block;
                              `}
                            >
                              &nbsp;
                              {getScreenSubtitle({
                                targetTypes: route.screen.flatMap(
                                  (v) => v.layouts,
                                ),
                              })}
                            </div>
                          </Button>
                        </li>
                      );
                    })}
                </ul>
              </S.Warning>
            )
          );
        })}

      {weightingEnabled && !isSidewalkLocked && (
        <DisplayFrequencySelector
          messageType={DispFreqMessageType.alert}
          onChange={(option) => {
            setValue(
              SCREENS_NAME,
              modifyIndexInArray({
                index,
                array: screens,
                value: {
                  ...screens[index],
                  [WEIGHT_FREQUENCY_NAME]: {
                    priority: option.priority,
                    weight: option.weight,
                  },
                },
              }),
            );
          }}
          value={getDispFreqOption({
            messageType: DispFreqMessageType.alert,
            priority:
              weightFrequency?.priority ??
              ALERTS_DEFAULT_DISP_FREQUENCY.priority,
            weight:
              weightFrequency?.weight ?? ALERTS_DEFAULT_DISP_FREQUENCY.weight,
          })}
          competingContentParams={{
            screenTypes: _uniq(
              currentScreenTargeting.flatMap((st) => st?.layouts ?? []),
            ),
            lines: _uniq(
              currentScreenTargeting.map((st) => st?.routeId).filter(Boolean),
            ),
          }}
        />
      )}
    </div>
  );
};

export default ScreenSelector;
