/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';
import { ThemeType } from '../../../theme';

export const UploadBox: React.FC<
  {
    empty?: boolean;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
> = ({ ...props }) => <div {...props} />;

export const Title: React.FC<
  { lighter?: boolean } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >
> & { children?: React.ReactNode } = ({ lighter = false, ...props }) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.xsmall};
      font-weight: ${theme.typography.weights.bolder};
      color: ${lighter ? theme.colors.light9 : theme.colors.accent1};
      text-transform: uppercase;
    `}
    {...props}
  />
);

export const SubTitle: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > & { children?: React.ReactNode }
> = ({ ...props }) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
      color: ${theme.colors.dark3};
    `}
    {...props}
  />
);

export const UploadMenu: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { children?: React.ReactNode }
> = (props) => (
  <div
    css={css`
      display: flex;
      margin-left: 16px;
    `}
    {...props}
  />
);

export const UploadMenuContainer: React.FC<
  {
    lighter?: boolean;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  >
> & { children?: React.ReactNode } = ({ ...props }) => (
  <ul
    css={(theme: ThemeType) => css`
      border: 1px solid ${theme.colors['border-dark']};
      border-radius: 4px;
      box-shadow:
        inset 0 0 1px 0 ${theme.colors['border-dark']},
        0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14);
      background-color: ${theme.colors.white};
      box-sizing: border-box;
      list-style: none;
      margin: 8px 0 0;
      padding: 16px 19px;
      width: 212px;
    `}
    {...props}
  />
);

export const UploadMenuElt: React.FC<
  {
    lighter?: boolean;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >
> & { children?: React.ReactNode } = ({ ...props }) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      font-weight: ${theme.typography.weights.normal};
      color: ${theme.colors.black};
    `}
    {...props}
  />
);

export const menuEltButtons = css`
  width: 100%;
  text-align: left;
`;
