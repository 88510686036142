/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import {
  FeatureFlagName,
  PublishStatus as PublishStatusType,
} from '../../../generated/global-types';
import * as S from './PublishStatusList.styled';
import { useFeedId } from '../../../contexts/FeedId';
import { isTrain, isBus, isBridgeTunnel } from '../../../utils/feed-switches';
import { ThemeType } from '../../../theme';
import { PublishStatusIcon } from '../status-icon';

interface PublishStatusListProps {
  webStatus?: PublishStatusType;
  screensStatus?: PublishStatusType;
  emailSmsStatus?: PublishStatusType;
  tweetsStatus?: PublishStatusType;
}

const PublishStatus: React.FC<
  {
    label: string;
    status: PublishStatusType | null;
  } & { children?: React.ReactNode }
> = ({ label, status }) => (
  <S.Item>
    {status && (
      <div>
        <PublishStatusIcon status={status} size={12} />
      </div>
    )}
    <div
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.small};
        font-family: ${theme.typography.families.primary};
        color: ${theme.colors.black};
        margin-left: ${theme.spacing.xsmall};
      `}
    >
      {label}
    </div>
  </S.Item>
);

const PublishStatusList: React.FC<PublishStatusListProps> = ({
  webStatus,
  screensStatus,
  emailSmsStatus,
  tweetsStatus,
}) => {
  const feedId = useFeedId();
  const isBusFeed = isBus(feedId);
  const isBridgeTunnelFeed = isBridgeTunnel(feedId);
  const screensTargetable = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
  );
  return (
    <S.Container>
      {webStatus && (
        <PublishStatus
          label={isBusFeed ? 'Web, Apps, & Screens' : 'Web & Apps'}
          status={webStatus}
        />
      )}
      {emailSmsStatus && (
        <PublishStatus label="Email & SMS" status={emailSmsStatus} />
      )}
      {tweetsStatus && !isBridgeTunnelFeed && (
        <PublishStatus label="Twitter" status={tweetsStatus} />
      )}
      {isTrain(feedId) && screensTargetable && screensStatus && (
        <PublishStatus label="Screens" status={screensStatus} />
      )}
    </S.Container>
  );
};

export default PublishStatusList;
