/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';
import { useHistory } from 'react-router-dom';
import Shiitake from 'shiitake';
import AppLink from '../../common/app-link';

const TruncatableText: React.FC<
  {
    text: string;
    feedId: string | null;
    eventId: number | null;
  } & { children?: React.ReactNode }
> = ({ text, feedId, eventId }) => {
  const history = useHistory();
  return (
    <div
      css={(theme) => css`
        margin-top: ${theme.spacing.xxsmall};
        padding-bottom: ${theme.spacing.xsmall};
        color: ${theme.colors.black};
        font-family: ${theme.typography.families.primary};
        font-size: 14px;
        line-height: 17px;
        position: relative;
        word-break: break-all;
      `}
    >
      {/* @ts-ignore */}
      <Shiitake lines={3}>{text}</Shiitake>
      <AppLink
        css={(theme) => css`
          color: ${theme.colors.accent1};
          text-decoration: underline;
        `}
        to={{
          pathname: `/${feedId}/events/${eventId}`,
          state: { prevFilters: history.location.search },
        }}
      >
        Details
      </AppLink>
    </div>
  );
};

export default TruncatableText;
