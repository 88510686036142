/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';
import { invalidFilenameRegex } from 'utils/assets-helpers';
import { ThemeType } from 'theme';
import Modal from './modal';
import Button from './Button';

const invalidCharacterStyle = css`
  background-color: #f1d1d1;
`;

const InvalidFilenameModal: React.FC<
  {
    isOpen: boolean;
    filename: string;
    onDismiss: () => void;
  } & { children?: React.ReactNode }
> = ({ isOpen, filename, onDismiss }) => {
  return (
    <Modal title="Invalid File Name" isOpen={isOpen} onDismiss={onDismiss}>
      The file you&apos;ve selected has an invalid file name due to it
      containing special characters. Please rename the file or select a
      different one. Check below for the invalid characters.
      <div
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.small};
          font-family: ${theme.typography.families.primary};
          color: ${theme.colors.dark2};
          margin-top: 24px;
          background-color: ${theme.colors.light1};
          border-radius: 7px;
          border: 1px solid #d8d8d8;
          padding: 14px 16px;
        `}
      >
        {filename.split('').map((l, index) => {
          const key = `${index}-${l}`;
          return (
            <span
              key={key}
              css={invalidFilenameRegex.test(l) ? invalidCharacterStyle : null}
            >
              {l}
            </span>
          );
        })}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        `}
      >
        <Button type="button" primary onClick={onDismiss}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default InvalidFilenameModal;
